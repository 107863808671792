import React from "react";
import { Button, Tile } from "../../../core";


const Tile61 = ( ) =>{
    return (
        <>
        <Tile rowSpan={13} colSpan={4} className="  shadow-none   p-0 ">
            <div className=" flex flex-col   h-full mb-1 p-5 text-[#3A3541]  ">
                <div className=" flex  flex-col">
                    <div className="flex flex-row justify-between w-full  ">
                        <div className={`text-xl leading-8   font-medium`}>Weekly Overview</div>
                        <i className="fa-solid fa-ellipsis-vertical text-center justify-center items-start flex"></i>   
                    </div>
                </div>
                <div className="grow border my-4 mx-6"></div>
                <div className="flex flex-col  justify-center">
                        <div className="flex flex-row items-center justify-center pr-6 ">
                            <div className="flex flex-row items-center text-2xl leading-5 mr-4">
                            45%
                            </div>
                            <div className=" text-sm leading-5 font-regular text-[#3A3541] opacity-50">
                            Your sales performance is 45% 😎 <br/> better compared to last month
                            </div>
                        </div>
                        <div className="mt-4 ">
                            <Button block >DETAILS</Button>
                        </div>
                </div>           
            </div>  
        </Tile>
        </>
    )
}

export default Tile61




