import React, { FC, ReactNode } from "react";
import ReactECharts, { ReactEChartsProps } from '../core/ReactECharts';

interface ILineChart {
  chartOptions?: any;
  centreValue?: ReactNode | ReactNode[]
}

const LineChart:FC<ILineChart> = ({
  chartOptions,
  centreValue=<></>
}) => {

  console.log({_inside:chartOptions})

  const options: ReactEChartsProps = {
    // option: option,
    option: {
      animation: true,
      animationDelay: 10,
      animationDuration: 500,
      dataset: {
        // source: chartOptions?.source ? chartOptions?.source : [
        //     ["time", "Backup", "Change"],
        //     ["6/1/2012 00:00 am", 4, -2],
        //     ["6/1/2012 01:00 pm", 2, 1],
        //     ["6/1/2012 02:00 pm", 3, 2],
        //     ["6/1/2012 03:00 pm", 5, 0],
        // ],
        source: chartOptions?.dataset?.source ? chartOptions?.dataset?.source : []
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        // triggerOn: 'none',
        formatter: function(params: any) {
          return (
            `
              <div style="text-align: left">
                <strong>${params[0].data[0]}</strong><br>
                <i class="fa-solid fa-circle" style="font-size: 12px; color: #ff5e0e"></i> <strong>${params[0].dimensionNames[1]}:</strong> ${params[0].data[1]} MB<br>
                <i class="fa-solid fa-circle" style="font-size: 12px; opacity: 0.5; color: #ff5e0e"></i> <strong>${params[0].dimensionNames[2]}:</strong> ${params[0].data[2]} MB
              </div>
            `
          );
        },
        ...chartOptions.tooltip
      },
      legend: {
        show: true,
        data: chartOptions?.legend?.data ? chartOptions?.legend?.data : [], // ["Backup"] chartOptions?.legend?.data
        orient: 'horizontal', // vertical | horizontal
        left: 'center', // <number> | left | right | center
        right: 0,
        top: 'top', // <number> | top | bottom | center
        bottom: 0,
        radius: 0,
        // data: ['A', 'B', 'C'] // should be matching with name field of series[n].data[n].name,
        // ...chartOptions.legend
      },
      grid: {
        left: "5%",
        right: "0%",
        top: "25%",
        bottom: "25%",
      },
      xAxis: {
        type: "category",
        // axisLabel: { show: true },
        // axisLine: { show: true, onZero: false },
        // splitLine: { show: true },
        // axisTick: { show: true },
        // min: 0,
        // max: 3,
        // data: ['6/1/2012 00:00 am', '6/1/2012 01:00 pm', '6/1/2012 02:00 pm', '6/1/2012 03:00 pm', '6/1/2012 04:00 pm'],
      },
      yAxis: {
        type: "value",
        // axisLabel: { show: true },
        // axisLine: { show: true, onZero: false },
        // splitLine: { show: true },
        // axisTick: { show: false },
        // min: 0,
        // max: 15,
      },
      series: chartOptions?.series ? chartOptions?.series : [
        // {
        //   name: "Backup",
        //   id: 'line1',
        //   type: "line",
        //   stack: "total",
        //   smooth: true,
        //   symbolSize: 20,
        //   label: {
        //     show: true,
        //     opacity: 1,
        //     // rotate: 90,
        //     // color: '#ffffff',
        //     // formatter: (val: any)=>{
        //     //   return Array.isArray(val?.data) ? `${val?.data[1]} MB` : `${val?.data} MB`;
        //     // },
        //     // fontSize: 16,
        //     // rich: {
        //     //   name: {}
        //     // }
        //   },
        //   itemStyle: {
        //     color: '#ff5e0e',
        //     opacity: 1
        //   },
        //   lineStyle: {
        //     color: '#ff5e0e',
        //     opacity: 1,
        //     width: 4,
        //     type: 'dashed'
        //   }
        //   // data: [
        //   //   [15, 0],
        //   //   [-50, 10],
        //   //   [-56.5, 20],
        //   //   [-46.5, 30],
        //   //   [-22.1, 40]
        //   // ]
        // },
      ],
      toolbox: {
        show: true,
        feature: {
          mark: { show: false },
          dataZoom: {
            show: true,
            yAxisIndex: 'none'
          },
          dataView: { show: false, readOnly: false },
          Change: { show: false },
          saveAsImage: { show: false },
          magicType: { type: ['line', 'bar'] }, // ['line', 'bar', 'stack']
          myTool1: {
            show: false,
            title: 'custom extension method 1',
            icon: 'path://M432.45,595.444c0,2.177-4.661,6.82-11.305,6.82c-6.475,0-11.306-4.567-11.306-6.82s4.852-6.812,11.306-6.812C427.841,588.632,432.452,593.191,432.45,595.444L432.45,595.444z M421.155,589.876c-3.009,0-5.448,2.495-5.448,5.572s2.439,5.572,5.448,5.572c3.01,0,5.449-2.495,5.449-5.572C426.604,592.371,424.165,589.876,421.155,589.876L421.155,589.876z M421.146,591.891c-1.916,0-3.47,1.589-3.47,3.549c0,1.959,1.554,3.548,3.47,3.548s3.469-1.589,3.469-3.548C424.614,593.479,423.062,591.891,421.146,591.891L421.146,591.891zM421.146,591.891',
            onclick: function (){
                alert('myToolHandler1')
            }
          },
          myTool2: {
              show: false,
              title: 'custom extension method',
              icon: 'image://https://echarts.apache.org/en/images/favicon.png',
              onclick: function (){
                  alert('myToolHandler2')
              }
          }
        },
      },
      dataZoom: [
        {
          show: true,
          type: 'slider',
          xAxisIndex: 0,
          filterMode: 'none',
          // minSpan: 0,
          // maxSpan: 11,
          // minValueSpan: 0,
          // maxValueSpan: 11,
          // disabled: false,
        },
      ],
    },
    style: {
    },
    settings: {
      lazyUpdate: true,
      notMerge: true,
      replaceMerge: [],
      silent: true,
      // transition: "linear"
    },
    loading: false,
    theme: "light",
    height: '100%',
    width: '100%'
  }

  return (
    <div className="relative w-full h-full">
      <ReactECharts {...options} />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            {centreValue}
            {/* <>
              <div className="text-2xl font-semibold">20%</div>
              <div className="text-base font-medium">ABC</div>
            </> */}
          </div>
    </div>
  )
}

export default LineChart