/* eslint-disable react/jsx-pascal-case */
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import Tile5x4_ChartText from "../../../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText";
import { ITableControllerProps } from "../../../components/core/Table/Table";
import { getProtectedAgents, originalDisabledReasons } from "../../../utils/agentUtils";

interface IDisabledDevicesTile {
    endpointQuota: number;
    endpointData: any;
    setSelectedData: Dispatch<SetStateAction<string>>
    setTableController: Dispatch<SetStateAction<ITableControllerProps>>
    scrollToBottom: ()=>void;
}

const DisabledDevicesTile:FC<IDisabledDevicesTile> = ({
    endpointQuota=0,
    endpointData,
    setSelectedData,
    setTableController,
    scrollToBottom
}) => {

    const [showMessage, setShowMessage] = useState(false);

    const handleMouseEnter = () => {
      setShowMessage(true);
    };
  
    const handleMouseLeave = () => {
      setShowMessage(false);
    };

    // console.log({endpointData})

    return (
        <>
            <div className="tile relative lg:col-span-4 md:col-span-6 col-span-12 row-span-5 hover:touch-pinch-zoom h-[150px] rounded-lg bg-orange-light-component-background shadow hover:shadow-md p-5 border cursor-pointer overflow-hidden"
                onClick={()=>{
                    setSelectedData('Disabled')
                    setTableController((prevState)=>{
                        let newState = prevState;
                        newState.data = (getProtectedAgents(endpointData) || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos !== null });
                        return newState;
                    })
                    scrollToBottom()
                }}
                
            >
                <div className="flex h-full items-center text-[#3A3541]">
                    <div className="grow h-full">
                        <div className="text-xl leading-8 font-medium text-[#3A3541] mb-5 whitespace-nowrap">
                            <div className="flex items-center">Disabled Devices</div>
                        </div>
                        <div className="text-xs leading-5 font-normal text-gray-400 tracking-widest"></div>
                        <div className="flex flex-row text-[#3A3541] items-center">
                            <div className="text-xl leading-8 font-medium pr-2">
                                <em className="fa-duotone fa-ban text-5xl text-[#ff5e0e]"></em>
                            </div>
                        </div>
                    </div>
                    <div className="relative w-[114px] h-[114px] flex justify-center items-center">
                        <div className="absolute text-xl font-medium top-[32px] w-full text-center"></div>
                        <div className="absolute text-xs font-normal top-[60px] w-full text-center"></div>
                    </div>
                    <div className="text-7xl text-[#ff5e0e] w-28 h-28 flex items-end mb-8 text-right rounded-md absolute whitespace-nowrap right-4">
                        <div className="flex items-end absolute right-2">{(endpointData?.installed || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos !== null }).length}</div>
                    </div>
                </div>
                <div className={`absolute top-0 left-0 right-0 bottom-0 z-10 ${ showMessage ? 'bg-white' : '' }`}>
                    <div className="absolute top-0 right-0 px-3 py-1 bg-[#ff5e0e] text-white cursor-pointer rounded-tr-md" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                        <i className="fa-solid fa-circle-info "/>
                    </div>
                    { showMessage && <div className="flex h-full items-center justify-center align-middle gap-4">
                        <table className="text-sm">
                            <thead>
                                <thead>
                                    <div className="flex bg-slate-200 pl-2">
                                        <div className="border-y text-left font-bold w-24">Reason</div>
                                        <div className="border-y text-left font-bold w-14">Count</div>
                                        <div className="border-y text-left font-bold w-40">Reason</div>
                                        <div className="border-y text-left font-bold w-14">Count</div>
                                    </div>
                                </thead>
                                <tbody>
                                    <div className="flex pl-2">
                                        <div className="border-b w-24">Paused</div>
                                        <div className="border-b text-center w-14">{(getProtectedAgents(endpointData) || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos !== null && originalDisabledReasons[ep?.agent_profile?.BlockListInfos?.Reason] === 'Paused' }).length}</div>
                                        <div className="border-b w-40">Manually Disabled <span className="text-orange-500">*</span></div>
                                        <div className="border-b text-center w-14">{(getProtectedAgents(endpointData) || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos !== null && originalDisabledReasons[ep?.agent_profile?.BlockListInfos?.Reason] === 'Manually Disabled' }).length}</div>
                                    </div>
                                    <div className="flex bg-slate-100 pl-2">
                                        <div className="border-b w-24">On Vacation</div>
                                        <div className="border-b text-center w-14">{(getProtectedAgents(endpointData) || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos !== null && originalDisabledReasons[ep?.agent_profile?.BlockListInfos?.Reason] === 'On Vacation' }).length}</div>
                                        <div className="border-b w-40">Keep Only Last Version <span className="text-orange-500">*</span></div>
                                        <div className="border-b text-center w-14">{(getProtectedAgents(endpointData) || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos !== null && originalDisabledReasons[ep?.agent_profile?.BlockListInfos?.Reason] === 'Keep Only Last Version' }).length}</div>
                                    </div>
                                    <div className="flex pl-2">
                                    <div className="border-b  w-24">Archive <span className="text-orange-500">*</span></div>
                                        <div className="border-b text-center w-14">{(getProtectedAgents(endpointData) || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos !== null && originalDisabledReasons[ep?.agent_profile?.BlockListInfos?.Reason] === 'Archive' }).length}</div>
                                        <div className="border-b w-40"></div>
                                        <div className="border-b text-center w-14"></div>
                                    </div>
                                    <div className="flex pl-2 mt-2">
                                        <div className="flex grow text-orange-500 justify-end text-xs">* No license occupied</div>
                                    </div>
                                </tbody>
                            </thead>
                        </table>
                        {/* <div>
                            Occupied: {(getProtectedAgents(endpointData) || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos === null }).length} <br/>
                            On Vacation: {(getProtectedAgents(endpointData) || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos !== null && originalDisabledReasons[ep?.agent_profile?.BlockListInfos?.Reason] === 'On Vacation' }).length} <br/>
                            Paused: {(getProtectedAgents(endpointData) || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos !== null && originalDisabledReasons[ep?.agent_profile?.BlockListInfos?.Reason] === 'Paused' }).length} <br/>
                        </div>
                        <div>
                            Archive: {(getProtectedAgents(endpointData) || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos !== null && originalDisabledReasons[ep?.agent_profile?.BlockListInfos?.Reason] === 'Archive' }).length} <br/>
                            Keep Only Last Version: {(getProtectedAgents(endpointData) || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos !== null && originalDisabledReasons[ep?.agent_profile?.BlockListInfos?.Reason] === 'Keep Only Last Version' }).length} <br/>
                            Manually Disabled: {(getProtectedAgents(endpointData) || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos !== null && originalDisabledReasons[ep?.agent_profile?.BlockListInfos?.Reason] === 'Manually Disabled' }).length} <br/>
                        </div> */}
                    </div> }                   
                </div>
            </div>
        </>
    )
}

export default DisabledDevicesTile;
