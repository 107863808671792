import React, { FC } from "react"
import { Tile } from "../../../components/core";
import { lastConnectionFormatter, originalDisabledReasons } from "../../../utils/agentUtils";
import Placeholder from "../../../components/core/Placeholder/Placeholder";

interface IDeviceDetails {
    endpointProfile: any;
    detailOpen: boolean;
    DetailOpenHandler: ()=>void;
    scrollRef: any;
}

const DeviceDetails:FC<IDeviceDetails> = ({ 
    endpointProfile,
    detailOpen,
    DetailOpenHandler,
    scrollRef
}) => {
    return (
        <>
            <Tile rowSpan={detailOpen ? 20 : 13} className={`${detailOpen ? 'h-[780px]' : 'h-[390px]'} w-full`} >
                <div className="flex flex-col py-6 mx-6">
                    <div className="flex flex-col items-center justify-center align-middle text-center">
                        {
                            endpointProfile?.endpoint?.agent_profile?.ServerInfos?.Alerts === undefined ?
                                <i className="fa-regular fa-laptop w-48 h-36 p-4 text-7xl text-yellow-500 flex justify-center items-center rounded-md"></i> :
                                (
                                    endpointProfile?.endpoint?.agent_profile?.AdminInfos?.Deletion === true ? 
                                        <i className="fa-regular fa-laptop w-48 h-36 p-4 text-7xl text-red-500 flex justify-center items-center rounded-md"></i> :
                                    (
                                        endpointProfile?.endpoint?.agent_profile?.ServerInfos?.Alerts?.Alert === true ? 
                                        <i className="fa-regular fa-laptop w-48 h-36 p-4 text-7xl text-red-500 flex justify-center items-center rounded-md"></i> : 
                                        ( endpointProfile?.endpoint?.agent_profile?.BlockListInfos !== null ? 
                                            <i className="fa-regular fa-laptop w-48 h-36 p-4 text-7xl text-violet-600 flex justify-center items-center rounded-md"></i> : 
                                            <i className="fa-regular fa-laptop w-48 h-36 p-4 text-7xl text-green-600 flex justify-center items-center rounded-md"></i>
                                        )
                                    )
                                )
                        } 
                        <span className="text-[#3A3541] font-medium mt-4">{endpointProfile?.endpoint?.agent_profile?.AdminInfos?.Name || <Placeholder />}</span>
                            {
                                endpointProfile?.endpoint?.agent_profile?.ServerInfos?.Alerts === undefined ?
                                    <small className="px-4 py-1 rounded-md bg-yellow-600 text-yellow-600 bg-opacity-10 mt-1 font-normal">Pending Installation</small> :
                                    (
                                        endpointProfile?.endpoint?.agent_profile?.AdminInfos?.Deletion === true ? 
                                            <small className="px-4 py-1 rounded-md bg-red-500 text-red-500 bg-opacity-10 mt-1 font-normal">Deleted</small> :
                                        (
                                            endpointProfile?.endpoint?.agent_profile?.ServerInfos?.Alerts?.Alert === true ? 
                                            <small className="px-4 py-1 rounded-md bg-red-500 text-red-500 bg-opacity-10 mt-1 font-normal">Unprotected</small> : 
                                            ( endpointProfile?.endpoint?.agent_profile?.BlockListInfos !== null ? 
                                                <>
                                                    <small className="px-4 py-1 rounded-md bg-violet-600 text-violet-600 bg-opacity-10 mt-1 font-normal">Disabled</small>
                                                    <small>({originalDisabledReasons[endpointProfile?.endpoint?.agent_profile?.BlockListInfos?.Reason]})</small>
                                                </> : 
                                                <small className="px-4 py-1 rounded-md bg-green-600 text-green-600 bg-opacity-10 mt-1 font-normal">Protected</small>
                                            )
                                        )
                                    )
                            }
                    </div>
                    <div className="flex flex-row mt-6 border-t py-4 items-center">
                        <div className="text-gray-700 font-semibold cursor-pointer" onClick={DetailOpenHandler} >Detail<i className={`fa-solid ${detailOpen === true ?"fa-angle-up":"fa-angle-down"} text-gray-700 ml-2`}></i> </div>
                        <div className="flex grow"></div>
                        {/* <Link to="/endpoint/user/profile">
                            <Button variant="primary" size="sm" disabled={true}>View User</Button>
                        </Link> */}
                    </div>
                    <div className={`border-b pb-4  text-base font-medium text-gray-700 w-full space-y-1 mt-4 ${detailOpen===true? "block" : "hidden"}`}>
                        <div className="flex flex-col justify-start items-start text-left w-full  ">
                            Device Id
                            <span className=" text-[#FF5E0E] mb-1 font-normal">{endpointProfile?.endpoint?.agent_profile?.AdminInfos?.ID || <Placeholder />}</span>
                        </div>
                        <div className="flex flex-col justify-start items-start text-left w-full  ">
                            OS
                            <span className=" text-[#FF5E0E] mb-1 font-normal">{endpointProfile?.endpoint?.agent_profile?.AgentInfos?.System || <Placeholder />}</span>
                        </div>
                        <div className="flex flex-col justify-start items-start text-left w-full  ">
                            Protection Policy
                            <span className=" text-[#FF5E0E] mb-1 font-normal underline cursor-pointer"
                                onClick={()=>{
                                    scrollRef.current?.scrollIntoView({ behavior: "smooth" })
                                }}
                            >{endpointProfile?.endpoint?.agent_profile?.AdminInfos?.DataProfile?.Name || <Placeholder />}</span>
                        </div>
                        <div className="flex flex-col justify-start items-start text-left w-full">
                            Strategy
                            <span className=" text-[#FF5E0E] mb-1 font-normal underline cursor-pointer"
                                onClick={()=>{
                                    scrollRef.current?.scrollIntoView({ behavior: "smooth" })
                                }}
                            >{endpointProfile?.endpoint?.agent_profile?.AdminInfos?.ServiceProfile?.Name || <Placeholder />}</span>
                        </div>
                        <div className="flex flex-col justify-start items-start text-left w-full ">
                            Last Connection
                            <span className=" text-[#FF5E0E] mb-1 font-normal">{lastConnectionFormatter(endpointProfile?.endpoint?.agent_profile?.NetworkInfos?.LastConnectionTime || null)}</span>
                        </div>
                        <div className="flex flex-col justify-start items-start text-left w-full ">
                            Last Session Started
                            <span className=" text-[#FF5E0E] mb-1 font-normal">{lastConnectionFormatter(endpointProfile?.endpoint?.agent_profile?.NetworkInfos?.LastStartedSession || null)}</span>
                        </div>
                        <div className="flex flex-col justify-start items-start text-left w-full ">
                            Last Session Finished
                            <span className=" text-[#FF5E0E] mb-1 font-normal">{lastConnectionFormatter(endpointProfile?.endpoint?.agent_profile?.NetworkInfos?.LastCompletedSession || null)}</span>
                        </div>
                    </div>
                </div>
            </Tile>
        </>
    )
}

export default DeviceDetails;

