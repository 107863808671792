import { ComponentProps, Dispatch, FC, ReactNode, SetStateAction, useEffect, useState } from "react";
import { Type } from "typescript";
import Button from "../../../components/core/Button/Button";
import { ITableControllerProps } from "../../../components/core/Table/Table";

interface Props extends ComponentProps<"button">  {
   
    className?: string;
    options?: Array<object>;
    endpointData: any;
    selectedData: string;
    setSelectedData: Dispatch<SetStateAction<string>>;
    setTableController: Dispatch<SetStateAction<ITableControllerProps>>;
    getProtectedAgents: any;
    /**
     * Optional click handler
     */
    onClick?: () => void;
}

/**
 * Dropdown Component
 * @param param0 
 * @returns 
 */
const Dropdown:FC<Props> = ({
    className='',
    options=[],
    onClick=()=>{},
    endpointData,
    selectedData,
    setSelectedData,
    setTableController,
    getProtectedAgents,
    ...rest
}: Props) => {

    const [quickActionNavState, setQuickActionNavState] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<string>("Select Filter");

    useEffect(()=>{
        setSelectedOption(`${selectedData} Device(s)`)
    },[selectedData])

    return(
        <>
        {/* <div>
            <select className="py-1.5 px-2">
                {options && options.map((option:any,index:number)=>(
                 <option className="mx-4 py-2 bg-white text-orange-600">{option.option}</option>
                ))}
            </select>
        </div> */}
        <div className='relative flex tracking-wider ml-4'>
                    <Button 
                        className={`border border-slate-100 shadow-md rounded-md font-medium w-10 md:w-44 text-start hidden md:block`}
                        solid={false}
                        onClick={()=>{
                            setQuickActionNavState((prevState)=>{ return !prevState })
                        }}
                    >
                        <div className="flex items-center">
                            <div>
                                {selectedOption}
                                
                            </div>
                            <i className="fa-regular fa-angle-down ml-10 hidden md:block"/>
                        </div>
                    </Button>
                    <i className="fa-solid fa-solid fa-bars-filter block md:hidden text-[#ff5e0e]" onClick={()=>{
                            setQuickActionNavState((prevState)=>{ return !prevState })
                    }} />
                    {quickActionNavState &&
                        <div className="absolute right-0 top-full w-[250px] flex flex-col bg-white rounded-lg shadow-md z-50">
                            <span className='relative group'>
                                <div className="flex border-b border-slate-100 px-3 py-2 hover:bg-slate-100 whitespace-nowrap cursor-pointer capitalize tracking-wider group items-center"
                                    onClick={()=>{
                                        setSelectedData('All')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.data = endpointData?.endpoints || [];
                                            return newState;
                                        })
                                        setQuickActionNavState(false)
                                        setSelectedOption("All Devices")

                                    }}
                                >
                                    <i className="fa-regular text-orange-600 fa-tv w-8"/>
                                    <span>All Devices</span>
                                    <div className="grow"></div>
                                    {/* <i className="fa-regular text-orange-600 fa-angle-right"/> */}
                                </div>
                            </span>
                            <div className="border-b border-slate-100 flex px-3 py-2 hover:bg-slate-100 whitespace-nowrap cursor-pointer items-center"
                                onClick={()=>{
                                    setSelectedData('Protected')
                                    setTableController((prevState)=>{
                                        let newState = {...prevState};
                                        newState.data = (getProtectedAgents(endpointData) || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos === null });;
                                        return newState;
                                    })
                                    setQuickActionNavState(false)
                                    setSelectedOption("Protected Devices")

                                }}
                            >
                                <i className="fa-regular fa-shield-check text-orange-600 w-8"/>
                                <span>Protected</span>
                            </div>
                            <div className="border-b border-slate-100 flex px-3 py-2 hover:bg-slate-100 whitespace-nowrap cursor-pointer items-center"
                                onClick={()=>{
                                    setSelectedData('Unprotected')
                                    setTableController((prevState)=>{
                                        let newState = {...prevState};
                                        newState.data = endpointData?.unprotected || [];
                                        return newState;
                                    })
                                    setQuickActionNavState(false)
                                    setSelectedOption("Unprotected Devices")

                                }}
                            >
                                <i className="fa-regular fa-shield-xmark text-orange-600 w-8"/>
                                <span>Unprotected</span>
                            </div>
                            <div className="border-b border-slate-100 flex px-3 py-2 hover:bg-slate-100 whitespace-nowrap cursor-pointer items-center"
                                onClick={()=>{
                                    setSelectedData('Installed')
                                    setTableController((prevState)=>{
                                        let newState = prevState;
                                        newState.data = endpointData?.installed || [];
                                        return newState;
                                    })
                                    setQuickActionNavState(false)
                                    setSelectedOption("Installed Devices")

                                }}
                            >
                                <i className="fa-regular fa-box-circle-check text-orange-600 w-8"/>
                                <span>Installed</span>
                            </div>
                            <div className="border-b border-slate-100 flex px-3 py-2 hover:bg-slate-100 whitespace-nowrap cursor-pointer items-center"
                                onClick={()=>{
                                    setSelectedData('Pending Installation')
                                    setTableController((prevState)=>{
                                        let newState = prevState;
                                        newState.data = endpointData?.pending_installation || [];
                                        return newState;
                                    })
                                    setQuickActionNavState(false)
                                    setSelectedOption("Pending Installation")

                                }}
                            >
                                <i className="fa-regular fa-hourglass-clock text-orange-600 w-8"/>
                                <span>Pending Installation</span>
                            </div>
                            <div className="border-b border-slate-100 flex px-3 py-2 hover:bg-slate-100 whitespace-nowrap cursor-pointer items-center"
                                onClick={()=>{
                                    setSelectedData('Deleted')
                                    setTableController((prevState)=>{
                                        let newState = {...prevState};
                                        newState.data = endpointData?.deleted || [];
                                        return newState;
                                    })
                                    setQuickActionNavState(false)
                                    setSelectedOption("Deleted Devices")

                                }}
                            >
                                <i className="fa-regular text-orange-600 fa-trash w-8"/>
                                <span>Deleted</span>
                            </div>
                            <div className="border-b border-slate-100 flex px-3 py-2 hover:bg-slate-100 whitespace-nowrap cursor-pointer items-center"
                                onClick={()=>{
                                    setSelectedData('Disabled')
                                    setTableController((prevState)=>{
                                        let newState = {...prevState};
                                        newState.data = (endpointData?.endpoints || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos !== null });
                                        return newState;
                                    })
                                    setQuickActionNavState(false)
                                    setSelectedOption("Disabled Devices")

                                }}
                            >
                                <i className="fa-regular text-orange-600 fa-ban w-8"/>
                                <span>Disabled</span>
                            </div>
                            <div className="border-b border-slate-100 flex px-3 py-2 hover:bg-slate-100 whitespace-nowrap cursor-pointer items-center"
                                onClick={()=>{
                                    setSelectedData('License Occupied')
                                    setTableController((prevState)=>{
                                        let newState = {...prevState};
                                        newState.data = (endpointData?.installed || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos === null });
                                        return newState;
                                    })
                                    setQuickActionNavState(false)
                                    setSelectedOption("License Occupied")

                                }}
                            >
                                <i className="fa-regular fa-badge-check text-orange-600 w-8"/>
                                <span>License Occupied</span>
                            </div>
                            <div className="border-b border-slate-100 flex px-3 py-2 hover:bg-slate-100 whitespace-nowrap cursor-pointer items-center"
                                onClick={()=>{
                                    setSelectedData('Active Today')
                                    setTableController((prevState)=>{
                                        let newState = {...prevState};
                                        newState.data = (endpointData?.installed || []).filter((ep:any)=>{ 
                                            let currentDate: string = (new Date()).toISOString().split('T')[0];
                                            let subjectDate: string = (new Date(ep?.agent_profile?.NetworkInfos?.LastConnectionTime)).toISOString().split('T')[0]
                                            return currentDate === subjectDate
                                        });
                                        return newState;
                                    })
                                    setQuickActionNavState(false)
                                    setSelectedOption("Active Today")

                                }}
                            >
                                <i className="fa-regular text-orange-600 fa-bolt w-8"/>
                                <span>Active Today</span>
                            </div>
                        </div>
                    }
                </div>
        </>
    )
}

export default Dropdown;
