/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useContext, useEffect, useRef, useState} from "react";
import { toast } from "react-toastify";
// import { Link, useNavigate } from "react-router-dom";
import { Accordion, AccordionItem, Breadcrumb, Button,Tile } from "../../components/core";
import Table, { ITableColumnItem, ITableControllerProps } from "../../components/core/Table/Table";
import { SideDrawerContext } from "../../contexts";
import { getAPICall } from "../../services/APILayer/axiosMethodCalls";
import { formatSize } from "../../utils/backupFileUtils";
// import { formatBytes } from "../../utils/agentUtils";
import { getFormattedDateTime } from "../../utils/format";
import { generateCsv } from "../../utils/reportingUtil";
import { formatBytes } from "../../utils/agentUtils";
import { SortWithLastProtectedData, SortWithMailId, SortWithName, SortWithProtectedDataSize, SortWithStatus } from "../../utils/Sorting/exchangeOneDrive";

const OneDriveDashboard = () =>{

    const [ activeTabs, setActiveTabs ] = useState<string>('mailbox');
    const [ msSetupStatus, setMsSetupStatus ] = useState<boolean>(false);
    const [ ms365App, setMs365App ] = useState<any>({});
    const [ oneDriveUsers, setOneDriveUsers ] = useState<any[]>([]);
    const [ csvReportData, setCsvReportData ] = useState<any[]>([]);

    const getMyMS365App = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/ms365/apps/me`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{

        if( Object.keys( ms365App ).length > 0 ) {
            return;
        }

        const fetchMyMS365App = async () => {
            getMyMS365App()
                .then((data)=>{
                    // console.log({ms365App:data});
                    setMs365App(data)
                })
                .catch((error)=>{ console.log(error) })
                .finally()
        }
        fetchMyMS365App();
    },[])

    useEffect(()=>{
        if( 
            Object.keys(ms365App).length < 1 ||
            oneDriveUsers.length < 1 
        ) { return; }

        let csvData: any[] = []
        oneDriveUsers.map((calenderUser:any)=>{
            csvData.push({
                product: 'One Drive',
                name: calenderUser?.profile?.displayName || "",
                email: calenderUser?.profile?.userPrincipalName || "",
                status: Object.keys(calenderUser?.data_profile || {}).length > 0 ? ( calenderUser?.protected ? 'Protected' : 'Unprotected' ) : 'Not Configured',
                protectedDataSize: `${formatSize(calenderUser?.protected_data_size_bytes).value} ${formatSize(calenderUser?.protected_data_size_bytes).unit}`,
                lastProtectedDate: calenderUser?.last_protection_date ? getFormattedDateTime(calenderUser?.last_protection_date || '') : 'NA',
            })
        })

        setCsvReportData(csvData)

        // console.log({csvData})

    },[ms365App, oneDriveUsers])

    useEffect(()=>{
        // if no data
        if( Object.keys( ms365App ).length < 1 ) {
            return;
        }

        // if Tina is not setup yet
        if( Object.keys( ms365App?.ms365_app || {} ).length < 1 ) {
            return;
        }

        // if tina is setup but waiting for backend team to approve
        if( ms365App?.ms365_app?.isEnabled === false ) {
            return;
        }

        // approved
        if( ms365App?.ms365_app?.isEnabled === true ) {
            setOneDriveUsers(ms365App?.ms365_app?.users?.onedrive_users?.user_list || []);
        }

    },[ms365App]);

    const downloadCsvHandler = () => {
        console.log({csvReportData})
        let tempStr:string = `"Product","Name","Email","Status","Protected Data","Last Protected"\n`
        let csvData = generateCsv(csvReportData, tempStr);
        console.log(csvData)
        // let handle = window.open(`data:text/csv;charset=utf-8,${csvData}`, 'mozillaWindow', 'popup')
        // if( !handle ) {
        //     alert('popup blocked')
        // }

        console.log(`data:text/csv;charset=utf-8,${csvData}`)

        setTimeout(()=>{    
            const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvData}`);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "report.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },50)
    }
    
    return(
        <>
            <div className={`p-6 grow flex flex-col`}>
                    <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Microsoft 365 &mdash; OneDrive</h1>
                    <Breadcrumb arrBreadcrumb={[
                        { icon: 'fa-solid fa-bell-on', link: '', name: 'Home' },
                        { icon: '', link: '', name: 'Microsoft 365' },
                        { icon: '', link: '', name: 'OneDrive' }
                    ]} />
                    <div className={`grow flex flex-col`}> 
                        <div className="grid grid-cols-5 gap-6 w-full ">
                            <Tile colSpan={5} className="p-4 mb-10 pb-10 md:pb-4 overflow-hidden h-[900px] md:h-[720px]">
                                <div className="grid col-span-5 md:col-span-3 lg:col-span-4 gap">
                                    <div className="md:col-span-1 lg:col-span-1 gap-6 pb-3 mb-4 border-b border-gray-400">
                                        <div className="text-slate-600 text-xl font-medium items-center flex">
                                            <div>Microsoft 365 &mdash; OneDrive</div>
                                            <div className="grow"></div>
                                            <div className="mr-4 px-4 py-1 text-sm border text-[#ffffff] bg-[#ff5e0e] font-light rounded-md cursor-pointer" onClick={()=>{
                                                        downloadCsvHandler()
                                                    }}>Report
                                                <em 
                                                    className="fa-solid fa-download ml-2 font-medium"
                                                ></em>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="w-full flex flex-col md:flex-row items-center justify-start align-middle text-center mb-6 gap-2">
                                        <Button className={`border ${activeTabs === 'mailbox' ? 'bg-opacity-100 text-[#ffffff] hover:text-[#ff5e0e] hover:bg-opacity-90' : 'bg-opacity-0 text-[#FF5E0E]'} bg-[#FF5E0E] border-none shadow-md rounded-md font-semibold w-full lg:w-1/4`} solid={false}
                                            onClick={()=>{
                                                setActiveTabs('mailbox')
                                            }}
                                        >Mailbox</Button>
                                        <Button className={`border ${activeTabs === 'contact' ? 'bg-opacity-100 text-[#ffffff] hover:bg-opacity-90 hover:text-[#ff5e0e]' : 'bg-opacity-0 text-[#FF5E0E]'} bg-[#FF5E0E] border-none shadow-md rounded-md font-semibold w-full lg:w-1/4`} solid={false}
                                            onClick={()=>{
                                                setActiveTabs('contact')
                                            }}
                                        >Contacts</Button>
                                        <Button className={`border ${activeTabs === 'calendar' ? 'bg-opacity-100 text-[#ffffff] hover:bg-opacity-90 hover:text-[#ff5e0e]' : 'bg-opacity-0 text-[#FF5E0E]'} bg-[#FF5E0E] border-none shadow-md rounded-md font-semibold w-full lg:w-1/4`} solid={false}
                                            onClick={()=>{
                                                setActiveTabs('calendar')
                                            }}
                                        >Calendar</Button>
                                    </div> */}
                                    <div className="px-2 h-[550px] md:h-[600px] mt-4 pr-4 pb-10">
                                        { msSetupStatus === false && activeTabs==='mailbox' && <OneDrive oneDriveUsers={oneDriveUsers} ms365App={ms365App}/> }
                                    </div>
                                </div>
                            </Tile>
                        </div>
                    </div>
                    {/* {data &&  sideDrawerContext.setFirstDrawerChild(<LevelOneDrawerComponent data={data} />)} */}
            </div>
        </>
    )
}

export default OneDriveDashboard

interface IOneDrive {
    oneDriveUsers: any[];
    ms365App: any;
}

const OneDrive:FC<IOneDrive> = ({oneDriveUsers, ms365App}) => {
    const navRaf = useRef<null | HTMLDivElement>(null);

    const sideDrawerContext = useContext(SideDrawerContext);

    const [tableController, setTableController] = useState<ITableControllerProps>({
        data: [],
        loader: false,
        currentPage: 1,
        offset: 0,
        limit: 5,
        totalRecords: 10,
        maxVisiblePaginationButtons: 5,
        searchTerm: '',
        filter: {},
        sorting: {
            column: '',
            direction: null
        }
    });

    const [tableColumns, setTableColumns] = useState<ITableColumnItem[]>([
        {
            id: 1,
            name: 'Name',
            column: '',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            className: 'w-[150px] pl-4',
            visibility: true,
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 2,
            name: 'Mail Id',
            column: '',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            className: 'w-[250px] pl-4',
            visibility: true,
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 3,
            name: 'Status',
            column: '',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            className: 'w-[150px] pl-4',
            visibility: true,
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 4,
            name: 'Protected Data Size',
            column: '',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            className: 'w-[150px] pl-4',
            visibility: true,
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 5,
            name: 'Last Protected date',
            column: '',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            className: 'w-[150px] pl-4',
            visibility: true,
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        }
    ]);

    // const [totalProtectedUsers, setTotalProtectedUsers] = useState<number>(0);

    useEffect(()=>{
        if( (oneDriveUsers || []).length < 1 ) {
            return;
        }

        setTableController((prevState:any)=>{
            let newState = {...prevState}
            newState.data = oneDriveUsers;
            return newState;
        })

    },[oneDriveUsers])

    const actionButtonGenerator = (row: object) => {
        return <>
            <button aria-label='view'
                className="w-[150px]"
                onClick={()=>{
                    sideDrawerContext.setFirstDrawerChild(<LevelOneDrawerComponent product="OneDrive" userProfile={row} ms365App={ms365App} />)
                    sideDrawerContext.setLevelOneDrawerVisibility(true)
                }}
            >
                <em className='fa-regular fa-eye text-[#FF5E0E] opacity-80'></em>
            </button>
        </>
    }

    const tableDataFormatter = (rowObj: any, format?: string | undefined) => {

        let returningVal = null;

        switch (format) {
            case 'Name':
                returningVal = (
                    <>
                        <div className="w-[150px]">{rowObj?.profile?.displayName || ''}</div>
                    </>
                );
                break;

            case 'Mail Id':
                returningVal=(
                    <>
                        <div className="w-[250px] ml-2">{rowObj?.profile?.mail || ''}</div>
                    </>
                );
                break;
            
            case 'Status':
                returningVal=(
                    <>
                        {
                            Object.keys(rowObj?.data_profile || {}).length > 0 ?
                            (
                                rowObj?.protected ? <span className="text-green-600 bg-green-100 px-3 py-1 rounded-md w-[150px] ml-5">Protected</span> : <span className="text-red-500 bg-red-100 px-3 py-1 rounded-md w-[150px] ml-5">Unprotected</span>
                            ) :
                            <span className="text-yellow-600 bg-yellow-100 px-3 py-1 rounded-md w-[150px] ml-5">Not Configured</span>
                        }
                    </>
                );
                break;

            case 'Protected Data Size':
                returningVal=(
                    <>
                        <div className="w-[150px] ml-8">{formatSize(rowObj?.protected_data_size_bytes).value} {formatSize(rowObj?.protected_data_size_bytes).unit}</div>
                    </>
                );
                break;
                
            case 'Last Protected date':
                returningVal=(
                    <>
                        <div className="w-[150px] ml-10">{rowObj?.last_protection_date ? getFormattedDateTime(rowObj?.last_protection_date || '') : 'NA'}</div>
                    </>
                );
            break;

            default:
                returningVal = rowObj;
                break;
        }
        return returningVal;
    }

    const handleDataSorting = (column:any) => {

        let columnName = column?.name;
        let tableData = [...tableController?.data];
        let returningValue: {[key: string]: any}[] = []

        console.log({columnName, tableData, column, returningValue})

        switch (columnName) {
            case 'Name':
                let sortedByDeviceId = SortWithName( column?.sorting?.direction, tableData )
                if( sortedByDeviceId )
                    returningValue = sortedByDeviceId
                // returningValue = tableData
                break;

            case 'Mail Id':
                let sortedHostnameResult = SortWithMailId( column?.sorting?.direction, tableData )
                if( sortedHostnameResult )
                    returningValue = sortedHostnameResult
                // returningValue = tableData
                break;
                
            case 'Status':
                let sortedOSResult = SortWithStatus( column?.sorting?.direction, tableData )
                if( sortedOSResult )
                    returningValue = sortedOSResult
                // returningValue = tableData
                break;
            
            case 'Protected Data Size':
                let sortedDeviceStatusResult = SortWithProtectedDataSize( column?.sorting?.direction, tableData )
                if( sortedDeviceStatusResult )
                    returningValue = sortedDeviceStatusResult
                // returningValue = tableData
                break;
        
            case 'Last Protected date':
                let sortedLastConnectionResult = SortWithLastProtectedData( column?.sorting?.direction, tableData )
                if( sortedLastConnectionResult )
                    returningValue = sortedLastConnectionResult
                // returningValue = tableData
                break;
        
            default:
                break;
        }

        setTableController((previousState:ITableControllerProps)=>{
            let newState = {...previousState}
            newState.data = returningValue
            return newState
        })

    }

    return (
        <>
            <div className="grid grid-cols-12 w-full mb-10 gap-6">
                <Tile colSpan={3} rowSpan={4} className="w-full p-4 col-span-12 md:col-span-6 lg:col-span-3 relative border">
                    <div className="text-[#FF5E0E] font-semibold text-xl">Total Users</div>
                    <i className="fa-duotone fa-users text-[#FF5E0E] text-3xl absolute bottom-4 left-6 xl:block md:hidden"></i>
                    <div className="grow"></div>
                    <div className="text-[#FF5E0E] text-4xl font-medium absolute bottom-6  right-6 whitespace-nowrap">
                        {(ms365App?.ms365_app?.users?.onedrive_users?.user_list || []).length}
                    </div>
                </Tile>
                <Tile colSpan={3} rowSpan={4} className="w-full p-4 col-span-12 md:col-span-6 lg:col-span-3 relative border">
                    <div className="text-[#FF5E0E] font-semibold text-xl">Protected Data Size</div>
                    <i className="fa-duotone fa-server text-[#FF5E0E] text-3xl absolute bottom-4 left-6 xl:block md:hidden">
                    <i className="fa-solid fa-shield-check absolute z-30 text-lg -bottom-1 -right-2"></i>
                    </i>
                    <div className="grow"></div>
                    <div className="text-[#FF5E0E] text-4xl font-medium absolute bottom-6  right-6 whitespace-nowrap">
                        {formatSize(ms365App?.ms365_app?.users?.onedrive_users?.protected_data_size_bytes || 0).value}
                        <span className="text-base ml-1">{formatSize(ms365App?.ms365_app?.users?.onedrive_users?.protected_data_size_bytes || 0).unit}</span>
                    </div>
                </Tile>
                <Tile colSpan={3} rowSpan={4} className="w-full p-4 col-span-12 md:col-span-6 lg:col-span-3 relative border">
                    <div className="text-[#FF5E0E] font-semibold text-xl">Protected User</div>
                    <i className="fa-duotone fa-cloud-arrow-up text-[#FF5E0E] text-3xl absolute bottom-4 left-6 xl:block md:hidden">
                        <i className="fa-solid fa-shield-check absolute z-30 text-lg -bottom-1 -right-2"></i>
                    </i>
                    <div className="grow"></div>
                    <div className="text-[#FF5E0E] text-4xl font-medium absolute bottom-6  right-6 whitespace-nowrap">
                        {(ms365App?.ms365_app?.users?.onedrive_users?.protected_users || 0)}
                    </div>
                </Tile>
                <Tile colSpan={3} rowSpan={4} className="w-full p-4 col-span-12 md:col-span-6 lg:col-span-3 relative border">
                    <div className="text-[#FF5E0E] font-semibold text-xl">Unprotected User</div>
                    <i className="fa-duotone fa-window-restore text-[#FF5E0E] text-3xl absolute bottom-4 left-6 xl:block md:hidden">
                        <i className="fa-solid fa-shield-exclamation absolute z-30 text-lg -bottom-1 -right-2"></i>
                    </i>
                    <div className="grow"></div>
                    <div className="text-[#FF5E0E] text-4xl font-medium absolute bottom-6  right-6 whitespace-nowrap" >
                        {(ms365App?.ms365_app?.users?.onedrive_users?.user_list || []).length - (ms365App?.ms365_app?.users?.onedrive_users?.protected_users || 0)}
                    </div>
                </Tile>
            </div>
            <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div className="flex flex-col md:flex-row gap-4 w-full mb-10">
                        <div className="absolute left-0 right-0 bottom-0 top-[650px] md:top-[360px] lg:top-[210px] w-auto p-6 min-h-[500px]">
                            <Table
                                navRef={navRaf}
                                actionButtonGenerator={actionButtonGenerator}
                                columns={tableColumns}
                                setColumns={setTableColumns}
                                formatData={tableDataFormatter}
                                showActionCell={true}
                                showActionHeaderCell={true}
                                showColumnManagementMenu={false}
                                // switchHandler={handleSwitch}
                                tableController={tableController}
                                dataSortingHandler={handleDataSorting}
                                tableId="Mailbox"
                            />
                        </div>
                    </div>
            </div>
        </>
    )
}

interface ILevelOneDrawerComponent {
    product: string;
    userProfile: any;
    ms365App: any;
}

const LevelOneDrawerComponent:FC<ILevelOneDrawerComponent> = ({product, userProfile, ms365App}) => { 

    console.log({userProfile},{ms365App})

    const sideDrawer = useContext(SideDrawerContext);

    const [outlookUser, setOutlookUser] = useState<any[]>([]);
    const [contactUser, setContactUser] = useState<any[]>([]);
    const [calenderUser, setCalendarUser] = useState<any[]>([]);
    const [oneDriveUser, setOneDriveUser] = useState<any[]>([]);

    useEffect(()=>{
        let tempOutlookUser: any[] = (ms365App?.ms365_app?.users?.outlook_users?.user_list || []).filter((u:any)=>{ return u?.profile?.mail === userProfile?.profile?.mail })
        let tempContactUser: any[] = (ms365App?.ms365_app?.users?.contact_users?.user_list || []).filter((u:any)=>{ return u?.profile?.mail === userProfile?.profile?.mail })
        let tempCalenderUser: any[] = (ms365App?.ms365_app?.users?.calendar_users?.user_list || []).filter((u:any)=>{ return u?.profile?.mail === userProfile?.profile?.mail })
        let tempOneDriveUser: any[] = (ms365App?.ms365_app?.users?.onedrive_users?.user_list || []).filter((u:any)=>{ return u?.profile?.mail === userProfile?.profile?.mail })

        console.log({tempOutlookUser},{tempContactUser},{tempCalenderUser},{tempOneDriveUser})

        setOutlookUser(tempOutlookUser);
        setContactUser(tempContactUser);
        setCalendarUser(tempCalenderUser);
        setOneDriveUser(tempOneDriveUser);

    },[])

    return (
        <>
            <div className="h-full flex flex-col">
                <div className="title w-full flex gap-4 px-6 top-0 pt-10 border-b pb-10 bg-slate-100 items-center">
                    {/* Icon */}
                    <i className="fa-regular fa-user text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i>

                    {/* title */}
                    <div className="flex flex-col">
                        <div className="text-xl font-bold text-slate-700">
                            {userProfile?.profile?.displayName || ''}
                            <span className="font-light ml-2">|</span>
                            <span className="text-lg font-medium ml-2 text-[#FF5E0E]">{ 
                                formatSize((outlookUser[0]?.protected_data_size_bytes || 0) + (contactUser[0]?.protected_data_size_bytes || 0) + (calenderUser[0]?.protected_data_size_bytes || 0) + (oneDriveUser[0]?.protected_data_size_bytes || 0)).value
                            } {
                                formatSize((outlookUser[0]?.protected_data_size_bytes || 0) + (contactUser[0]?.protected_data_size_bytes || 0) + (calenderUser[0]?.protected_data_size_bytes || 0) + (oneDriveUser[0]?.protected_data_size_bytes || 0)).unit
                            }</span>
                        </div>
                        {/* Sub title */}
                        <div className="block text-slate-500">
                            {/* <Link to="/endpoint/devices"> */}
                                <span>{userProfile?.profile?.mail || ''}</span>
                                {/* <em className="fa-solid fa-link ml-2 text-sm"></em> */}
                            {/* </Link> */}
                        </div>
                    </div>
                </div>
                <div className="data grow w-full p-4 overflow-y-scroll">
                    <Accordion className="rounded-lg overflow-hidden">
                        <AccordionItem className="text-sm w-full" maxHeight='h-fit' title={
                            <span className="flex">
                                <img src="/assets/img/logo/ms365/MS_Outlook.svg" alt="MS OutLook" className="w-5 h-5 inline mr-2" />
                                <div className="min-w-max"> MailBox</div>
                                { Object.keys(outlookUser[0]?.data_profile || {}).length < 1 ?
                                    <span className="font-light text-xs px-2 py-[1px] border border-opacity-40 border-yellow-400 text-yellow-600 bg-yellow-100 max-w-min rounded-md ml-4 whitespace-nowrap">Not Configured</span> :
                                    (
                                        outlookUser[0]?.protected === true ? 
                                        <span className="font-light text-xs px-2 py-[1px] border border-opacity-40 border-green-600 text-green-700 bg-green-100 max-w-min rounded-md ml-4 whitespace-nowrap">Protected</span> : 
                                        <span className="font-light text-xs px-2 py-[1px] border border-opacity-40 border-red-400 text-red-500 bg-red-100  rounded-md ml-4 whitespace-nowrap">Unprotected</span>
                                    )
                                }
                                {
                                    ( Object.keys(outlookUser[0]?.data_profile || {}).length > 0 ) ?
                                    <span className="ml-4 text-xs">({formatSize(outlookUser[0]?.protected_data_size_bytes).value} {formatSize(outlookUser[0]?.protected_data_size_bytes).unit})</span> :
                                    <span className="ml-4 text-xs">(NA)</span>
                                }
                            </span>
                        }
                        state={product === 'MailBox' ? true : false}
                        >
                            <div className="overflow-hidden border">
                                <div className="text-sm" >
                                    <span className=" text-slate-500 flex justify-start items-center py-2 px-4 bg-slate-50 w-full">
                                        <em className="fa-regular fa-calendar-pen mr-4 text-[#ff5e0e]"></em>
                                        <span className="font-medium">Schedule(s)</span>
                                    </span>
                                    <div className="overflow-x-scroll customScroll max-h-52 h-44 bg-white">
                                        <div className={`thead bg-white border-y p-2 flex flex-row items-center w-[480px]`}>
                                            <div className="row text-gray-500 text-sm flex font-medium pl-2">
                                                <div className="cell w-28">Backup Type</div>
                                                <div className="cell w-44 whitespace-nowrap">Comment</div>
                                            </div>
                                        </div>
                                        <div className={`tbody max-h-48 bg-white flex flex-col text-slate-600 w-[480px]`}>
                                            {
                                                (outlookUser[0]?.data_profile?.strategyList || []).length > 0 ?
                                                (outlookUser[0]?.data_profile?.strategyList || []).map((strategyName:string)=>{
                                                    let strategy = (ms365App?.ms365_app?.strategyList || []).find((strategy:any)=>{ return strategy.name === strategyName });

                                                    if( strategy ) {
                                                        return (
                                                            <>
                                                                {strategy?.fullScheduleActive && <div className="row text-sm font-sm flex border-b w-full py-2 px-4 hover:bg-slate-100 whitespace-nowrap">
                                                                    <div className="cell text-slate-500 w-28">Full</div>
                                                                    <div className="cell text-slate-500 w-44">
                                                                        {strategy?.fullScheduleActive === true && strategy?.fullScheduleName }
                                                                        {/* {strategy?.fullScheduleActive === false && 'NA' } */}
                                                                    </div>
                                                                </div>}
                                                                {strategy?.incrScheduleActive && <div className="row text-sm font-sm flex border-b w-full py-2 px-4 hover:bg-slate-100 whitespace-nowrap">
                                                                    <div className="cell text-slate-500 w-28">Incremental</div>
                                                                    <div className="cell text-slate-500 w-44">
                                                                        {strategy?.incrScheduleActive === true && strategy?.incrScheduleName }
                                                                        {/* {strategy?.incrScheduleActive === false && 'NA' } */}
                                                                    </div>
                                                                </div>}
                                                            </>
                                                        )
                                                    }

                                                }) :

                                                <div className="row text-sm font-sm flex border-b max-h-52 h-44 w-full p-2 hover:bg-slate-100 whitespace-nowrap">
                                                    <div className="cell w-full text-center text-slate-500">No Schedule Found</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full items-center justify-center align-middle p-4 bg-slate-50">
                                <div 
                                    className="w-full"
                                    onClick={()=>{
                                        // LevelTwoDrawerComponent
                                        sideDrawer.setSecondDrawerChild(<LevelTwoDrawerComponent type='MailBox' userProfile={outlookUser} ms365App={ms365App} />);
                                        sideDrawer.setLevelTwoDrawerVisibility(true)
                                    }}
                                    
                                >
                                    <Button size='sm' solid={false} block={true} className="bg-white rounded-r-none">View Logs</Button>
                                </div>
                                <div className="w-full">
                                    <Button size='sm' solid={false} block={true} className="bg-white rounded-l-none border-l-0" onClick={()=>toast.info('For secure data restore. Please contact the support team.')}>Data Restore</Button>
                                </div>
                            </div>  
                        </AccordionItem>
                        <AccordionItem className="text-sm w-full" maxHeight='h-fit' title={
                            <span className="flex">
                                <img src="/assets/img/logo/ms365/MS_Contacts.svg" alt="MS OutLook" className="w-5 h-5 inline mr-2" />
                                <div className="min-w-max"> Contact</div>s
                                { Object.keys(contactUser[0]?.data_profile || {}).length < 1 ?
                                    <span className="font-light text-xs px-2 py-[1px] border border-opacity-40 border-yellow-400 text-yellow-600 bg-yellow-100 max-w-min rounded-md ml-4 whitespace-nowrap">Not Configured</span> :
                                    (
                                        contactUser[0]?.protected === true ? 
                                        <span className="font-light text-xs px-2 py-[1px] border border-opacity-40 border-green-500 text-green-700 bg-green-100 max-w-min rounded-md ml-4 whitespace-nowrap">Protected</span> : 
                                        <span className="font-light text-xs px-2 py-[1px] border border-opacity-40 border-red-400 text-red-500 bg-red-100 max-w-min rounded-md ml-4 whitespace-nowrap">Unprotected</span>
                                    )
                                }
                                { 
                                    (Object.keys(contactUser[0]?.data_profile || {}).length > 0 ) ?
                                    <span className="ml-4 text-xs">({formatSize(contactUser[0]?.protected_data_size_bytes).value} {formatSize(contactUser[0]?.protected_data_size_bytes).unit})</span> :
                                    <span className="ml-4 text-xs">(NA)</span>
                                }
                            </span>
                        }
                        state={product === 'Contact' ? true : false}
                        >
                            <div className="overflow-hidden border">
                                <div className="text-sm" >
                                    <span className=" text-slate-500 flex justify-start items-center py-2 px-4 bg-slate-50 w-full">
                                        <em className="fa-regular fa-calendar-pen mr-4 text-[#ff5e0e]"></em>
                                        <span className="font-medium">Schedule(s)</span>
                                    </span>
                                    <div className="overflow-x-scroll customScroll max-h-52 h-44 bg-white">
                                        <div className={`thead bg-slate-50 border-y p-2 flex flex-row items-center w-[480px]`}>
                                            <div className="row text-gray-800 text-sm flex pl-2">
                                                <div className="cell w-28">Backup Type</div>
                                                <div className="cell w-44 whitespace-nowrap">Comment</div>
                                            </div>
                                        </div>
                                        <div className={`tbody max-h-48 bg-white flex flex-col text-slate-600 w-[480px]`}>
                                            {
                                                (contactUser[0]?.data_profile?.strategyList || []).length > 0 ?
                                                (contactUser[0]?.data_profile?.strategyList || []).map((strategyName:string)=>{
                                                    let strategy = (ms365App?.ms365_app?.strategyList || []).find((strategy:any)=>{ return strategy.name === strategyName });

                                                    if( strategy ) {
                                                        return (
                                                            <>
                                                                {strategy?.fullScheduleActive && <div className="row text-sm font-sm flex border-b w-full py-2 px-4 hover:bg-slate-100 whitespace-nowrap">
                                                                    <div className="cell text-slate-500 w-28">Full</div>
                                                                    <div className="cell text-slate-500 w-44">
                                                                        {strategy?.fullScheduleActive === true && strategy?.fullScheduleName }
                                                                        {/* {strategy?.fullScheduleActive === false && 'NA' } */}
                                                                    </div>
                                                                </div>}
                                                                {strategy?.incrScheduleActive && <div className="row text-sm font-sm flex border-b w-full py-2 px-4 hover:bg-slate-100 whitespace-nowrap">
                                                                    <div className="cell text-slate-500 w-28">Incremental</div>
                                                                    <div className="cell text-slate-500 w-44">
                                                                        {strategy?.incrScheduleActive === true && strategy?.incrScheduleName }
                                                                        {/* {strategy?.incrScheduleActive === false && 'NA' } */}
                                                                    </div>
                                                                </div>}
                                                            </>
                                                        )
                                                    }

                                                }) :

                                                <div className="row text-sm font-sm flex border-b max-h-52 h-44 w-full p-2 hover:bg-slate-100 whitespace-nowrap">
                                                    <div className="cell w-full text-center text-slate-500">No Schedule Found</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full items-center justify-center align-middle p-4 bg-slate-50">
                                <div 
                                    className="w-full"
                                    onClick={()=>{
                                        // LevelTwoDrawerComponent
                                        sideDrawer.setSecondDrawerChild(<LevelTwoDrawerComponent type="Contact" userProfile={contactUser} ms365App={ms365App} />);
                                        sideDrawer.setLevelTwoDrawerVisibility(true)
                                    }}
                                >
                                    <Button size='sm' solid={false} block={true} className="bg-white rounded-r-none">View Logs</Button>
                                </div>
                                <div className="w-full">
                                    <Button size='sm' solid={false} block={true} className="bg-white rounded-l-none border-l-0" onClick={()=>toast.info('For secure data restore. Please contact the support team.')}>Data Restore</Button>
                                </div>
                            </div>
                        </AccordionItem>
                        <AccordionItem className="text-sm w-full" maxHeight='h-fit' title={
                            <span className="flex">
                                <img src="/assets/img/logo/ms365/MS_Calendar.svg" alt="MS OutLook" className="w-5 h-5 inline mr-2" />
                                <div className="min-w-max"> Calende</div>r
                                { Object.keys(calenderUser[0]?.data_profile || {}).length < 1 ?
                                    <span className="font-light text-xs px-2 py-[1px] border border-opacity-40 border-yellow-400 text-yellow-600 bg-yellow-100 max-w-min rounded-md ml-4 whitespace-nowrap">Not Configured</span> :
                                    (
                                        calenderUser[0]?.protected === true ? 
                                        <span className="font-light text-xs px-2 py-[1px] border border-opacity-40 border-green-500 text-green-700 bg-green-100 max-w-min rounded-md ml-4 whitespace-nowrap">Protected</span> : 
                                        <span className="font-light text-xs px-2 py-[1px] border border-opacity-40 border-red-400 text-red-500 bg-red-100 max-w-min rounded-md ml-4 whitespace-nowrap">Unprotected</span>
                                    )
                                }
                                {
                                    Object.keys(calenderUser[0]?.data_profile || {}).length > 0 ?
                                    <span className="ml-4 text-xs">({formatSize(calenderUser[0]?.protected_data_size_bytes).value} {formatSize(calenderUser[0]?.protected_data_size_bytes).unit})</span> :
                                    <span className="ml-4 text-xs">(NA)</span>
                                }
                            </span>
                        }
                        state={product === 'Calendar' ? true : false}
                        >
                            <div className="overflow-hidden border">
                                <div className="text-sm" >
                                    <span className=" text-slate-500 flex justify-start items-center py-2 px-4 bg-slate-50 w-full">
                                        <em className="fa-regular fa-calendar-pen mr-4 text-[#ff5e0e]"></em>
                                        <span className="font-medium">Schedule(s)</span>
                                    </span>
                                    <div className="overflow-x-scroll customScroll max-h-52 h-44 bg-white">
                                        <div className={`thead bg-slate-50 border-y p-2 flex flex-row items-center w-[480px]`}>
                                            <div className="row text-gray-800 text-sm flex pl-2">
                                                <div className="cell w-28">Backup Type</div>
                                                <div className="cell w-44 whitespace-nowrap">Comment</div>
                                            </div>
                                        </div>
                                        <div className={`tbody max-h-48 bg-white flex flex-col text-slate-600 w-[480px]`}>
                                            {
                                                (calenderUser[0]?.data_profile?.strategyList || []).length > 0 ?
                                                (calenderUser[0]?.data_profile?.strategyList || []).map((strategyName:string)=>{
                                                    let strategy = (ms365App?.ms365_app?.strategyList || []).find((strategy:any)=>{ return strategy.name === strategyName });

                                                    if( strategy ) {
                                                        return (
                                                            <>
                                                                {strategy?.fullScheduleActive && <div className="row text-sm font-sm flex border-b w-full py-2 px-4 hover:bg-slate-100 whitespace-nowrap">
                                                                    <div className="cell text-slate-500 w-28">Full</div>
                                                                    <div className="cell text-slate-500 w-44">
                                                                        {strategy?.fullScheduleActive === true && strategy?.fullScheduleName }
                                                                        {/* {strategy?.fullScheduleActive === false && 'NA' } */}
                                                                    </div>
                                                                </div>}
                                                                {strategy?.incrScheduleActive && <div className="row text-sm font-sm flex border-b w-full py-2 px-4 hover:bg-slate-100 whitespace-nowrap">
                                                                    <div className="cell text-slate-500 w-28">Incremental</div>
                                                                    <div className="cell text-slate-500 w-44">
                                                                        {strategy?.incrScheduleActive === true && strategy?.incrScheduleName }
                                                                        {/* {strategy?.incrScheduleActive === false && 'NA' } */}
                                                                    </div>
                                                                </div>}
                                                            </>
                                                        )
                                                    }

                                                }) :

                                                <div className="row text-sm font-sm flex border-b max-h-52 h-44 w-full p-2 hover:bg-slate-100 whitespace-nowrap">
                                                    <div className="cell w-full text-center text-slate-500">No Schedule Found</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full items-center justify-center align-middle p-4 bg-slate-50">
                                <div 
                                    className="w-full"
                                    onClick={()=>{
                                        // LevelTwoDrawerComponent
                                        sideDrawer.setSecondDrawerChild(<LevelTwoDrawerComponent type="Calender" userProfile={calenderUser} ms365App={ms365App} />);
                                        sideDrawer.setLevelTwoDrawerVisibility(true)
                                    }}
                                >
                                    <Button size='sm' solid={false} block={true} className="bg-white rounded-r-none">View Logs</Button>
                                </div>
                                <div className="w-full">
                                    <Button size='sm' solid={false} block={true} className="bg-white rounded-l-none border-l-0" onClick={()=>toast.info('For secure data restore. Please contact the support team.')}>Data Restore</Button>
                                </div>
                            </div>
                        </AccordionItem>
                        <AccordionItem className="text-sm w-full" maxHeight='h-fit' title={
                            <span className="flex">
                                <img src="/assets/img/logo/ms365/MS_OneDrive.svg" alt="MS OutLook" className="w-5 h-5 inline mr-2" />
                                <div className="min-w-max"> OneDrive</div>
                                { Object.keys(oneDriveUser[0]?.data_profile || {}).length < 1 ?
                                    <span className="font-light text-xs px-2 py-[1px] border border-opacity-40 border-yellow-400 text-yellow-600 bg-yellow-100 max-w-min rounded-md ml-4 whitespace-nowrap">Not Configured</span> :
                                    (
                                        oneDriveUser[0]?.protected === true ?
                                        <span className="font-light text-xs px-2 py-[1px] border border-opacity-40 border-green-500 text-green-700 bg-green-100 max-w-min rounded-md ml-4 whitespace-nowrap">Protected</span> : 
                                        <span className="font-light text-xs px-2 py-[1px] border border-opacity-40 border-red-400 text-red-500 bg-red-100 max-w-min rounded-md ml-4 whitespace-nowrap">Unprotected</span>
                                    )
                                }
                                { 
                                    Object.keys(oneDriveUser[0]?.data_profile || {}).length > 0 ?
                                    <span className="ml-4 text-xs">({formatSize(oneDriveUser[0]?.protected_data_size_bytes).value} {formatSize(oneDriveUser[0]?.protected_data_size_bytes).unit})</span> :
                                    <span className="ml-4 text-xs">(NA)</span>
                                }
                            </span>
                        }
                        state={product === 'OneDrive' ? true : false}
                        >
                            <div className="overflow-hidden border">
                                <div className="text-sm" >
                                    <span className=" text-slate-500 flex justify-start items-center py-2 px-4 bg-slate-50 w-full">
                                        <em className="fa-regular fa-calendar-pen mr-4 text-[#ff5e0e]"></em>
                                        <span className="font-medium">Schedule(s)</span>
                                    </span>
                                    <div className="overflow-x-scroll customScroll max-h-52 h-44 bg-white">
                                        <div className={`thead bg-slate-50 border-y p-2 flex flex-row items-center w-[480px]`}>
                                            <div className="row text-gray-800 text-sm flex pl-2">
                                                <div className="cell w-28">Backup Type</div>
                                                <div className="cell w-44 whitespace-nowrap">Comment</div>
                                            </div>
                                        </div>
                                        <div className={`tbody max-h-48 bg-white flex flex-col text-slate-600 w-[480px]`}>
                                            {
                                                (oneDriveUser[0]?.data_profile?.strategyList || []).length > 0 ?
                                                (oneDriveUser[0]?.data_profile?.strategyList || []).map((strategyName:string)=>{
                                                    let strategy = (ms365App?.ms365_app?.strategyList || []).find((strategy:any)=>{ return strategy.name === strategyName });

                                                    if( strategy ) {
                                                        return (
                                                            <>
                                                                {strategy?.fullScheduleActive && <div className="row text-sm font-sm flex border-b w-full py-2 px-4 hover:bg-slate-100 whitespace-nowrap">
                                                                    <div className="cell text-slate-500 w-28">Full</div>
                                                                    <div className="cell text-slate-500 w-44">
                                                                        {strategy?.fullScheduleActive === true && strategy?.fullScheduleName }
                                                                        {/* {strategy?.fullScheduleActive === false && 'NA' } */}
                                                                    </div>
                                                                </div>}
                                                                {strategy?.incrScheduleActive && <div className="row text-sm font-sm flex border-b w-full py-2 px-4 hover:bg-slate-100 whitespace-nowrap">
                                                                    <div className="cell text-slate-500 w-28">Incremental</div>
                                                                    <div className="cell text-slate-500 w-44">
                                                                        {strategy?.incrScheduleActive === true && strategy?.incrScheduleName }
                                                                        {/* {strategy?.incrScheduleActive === false && 'NA' } */}
                                                                    </div>
                                                                </div>}
                                                            </>
                                                        )
                                                    }

                                                }) :
                                                <div className="row text-sm font-sm flex border-b max-h-52 h-44 w-full p-2 hover:bg-slate-100 whitespace-nowrap">
                                                    <div className="cell w-full text-center text-slate-500">No Schedule Found</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full items-center justify-center align-middle p-4 bg-slate-50">
                                <div 
                                    className="w-full"
                                    onClick={()=>{
                                        // LevelTwoDrawerComponent
                                        sideDrawer.setSecondDrawerChild(<LevelTwoDrawerComponent type="OneDrive" userProfile={oneDriveUser} ms365App={ms365App} />);
                                        sideDrawer.setLevelTwoDrawerVisibility(true)
                                    }}
                                >
                                    <Button size='sm' solid={false} block={true} className="bg-white rounded-r-none">View Logs</Button>
                                </div>
                                <div className="w-full">
                                    <Button size='sm' solid={false} block={true} className="bg-white rounded-l-none border-l-0" onClick={()=>toast.info('For secure data restore. Please contact the support team.')}>
                                        Data Restore
                                    </Button>
                                </div>
                            </div>
                        </AccordionItem>
                    </Accordion>                    
                </div>
            </div>
        </>
    )
}

interface ILevelTwoDrawerComponent {
    type: string;
    userProfile: any;
    ms365App: any;
}

const LevelTwoDrawerComponent:FC<ILevelTwoDrawerComponent> = ({type, userProfile, ms365App}) => {

    const [ activities, setActivities ] = useState<any[]>([]);

    const activityTypeDefinition: any = {
        1: 'Backup',
        4: 'Restore'
    }
    const arrStatusDefinition: string[] = [
        "Running (sessions not created)",
        "Running (sessions not created)",
        "Running (non active sessions)",
        "Paused on request",
        "Paused auto",
        "Running (active sessions)",
        "Pausing on request",
        "Pausing to ready",
        "Pausing auto",
        "Cancelling on Catalog Stop",
        "Stopping on error",
        "Stopping on request",
        "Stopping on request",
        "Cancelled on Catalog Stop",
        "Stopped on error",
        "Cancelled on request",
        "Paused",
        "Complete",
        "Restarting",
        "Scheduled",
        "Cancelled without starting",
        "Cancelled (unreachable agent)"
    ];

    useEffect(()=>{

        let arrActivities: any[] = [];

        (ms365App?.ms365_app?._current_app_jobs_details || []).map((caj:any)=>{
            // for selected user
            if( caj?.job?.delegatePresentationName === userProfile[0]?.profile?.displayName ) {
                // and for selected product
                let temp = (caj?.job?.backupSelections || []).filter((selection:any)=>{
                    if( type === 'MailBox' && selection?.path === '/Outlook' ) {
                        return true 
                    } else if( type === 'Contact' && selection?.path === '/Contacts' ) { 
                        return true 
                    } else if( (type === 'Calender' || type === 'Calendar') && selection?.path === '/Calendar' ) {
                        return true 
                    } else if( type === 'OneDrive' && selection?.path === '/OneDrive' ) {
                        return true 
                    }
                })
                if( temp && temp.length ) {
                    arrActivities.push(caj)
                }
            }
        });

        setActivities(arrActivities)

    },[ms365App, type])

    return (
        <>
            <div className="h-full flex flex-col">
                <div className="title w-full flex gap-4 px-6 top-0 pt-10 border-b pb-10 bg-slate-100 items-center">
                    {/* Icon */}
                    <i className="fa-regular fa-bolt text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i>

                    {/* title */}
                    <div className="flex flex-col">
                        <div className="text-xl font-bold text-slate-700">{type || ''} Activities</div>
                        {/* Sub title */}
                        <div className="block text-slate-500">
                            {/* <Link to="/endpoint/devices"> */}
                                <span>{userProfile[0]?.profile?.mail}</span>
                                {/* <em className="fa-solid fa-link ml-2 text-sm"></em> */}
                            {/* </Link> */}
                        </div>
                    </div>
                </div>
                
                <div className="data absolute top-40 mx-4">
                    
                    <div className={`_table flex flex-col h-full shadow-md rounded-md mb-8 overflow-hidden w-[405px] md:w-[600px]`}>
                            <span className=" text-slate-500 flex justify-start items-center py-2 px-4 bg-slate-100 w-full">
                                <em className="fa-light fa-bolt mr-2 text-[#ff5e0e]"></em>
                                <span className="font-medium">Activitie(s)</span>
                            </span>
                            <div className="overflow-x-scroll customScroll">
                                <div className={`thead bg-slate-50 border-b p-2 flex flex-row items-center w-[545px] md:w-[600px]`}>
                                    <div className="row text-gray-800 text-sm flex w-full whitespace-nowrap">
                                        <div className="cell w-4 md:w-6 ml-1">ID</div>
                                        <div className="cell w-12 md:w-16 ml-3">Type</div>
                                        <div className="cell w-16 md:w-24 ml-2">Status</div>
                                        <div className="cell w-44 md:w-44  ml-4">Start</div> 
                                        <div className="cell  ml-4">Stop</div>
                                    </div>
                                </div>
                                <div className={`tbody bg-white w-[545px] md:w-[600px] overflow-y-scroll h-[200px] customScroll overflow-x-hidden`}>
                                        {/* <div className="row text-sm font-sm flex border-b w-full p-2 hover:bg-slate-100 whitespace-nowrap">
                                            <div className="cell text-slate-500 w-8 ml-2">120</div>
                                            <div className="cell text-slate-500 w-20 ml-4">Restore</div>
                                            <div className="cell text-slate-500 w-52 ml-2">Cancelled (unreachable agent)</div>
                                            <div className="cell text-slate-500 w-72 ml-2"></div>
                                            <div className="cell text-slate-500 w-72 ml-2"></div>
                                        </div> */}
                                    { (activities || []).map((activity:any, activityIndex)=>(
                                        <div key={activityIndex} className="row text-sm font-sm flex border-b hover:bg-slate-100 whitespace-nowrap py-1">
                                            <div className="cell text-slate-500 w-10  ml-2">{ activity?.job?.presentationId || '' }</div>
                                            <div className="cell text-slate-500 w-20  ml-2">{ activity?.type ? activityTypeDefinition[activity?.type] : '' }</div>
                                            <div className="cell text-slate-500 w-32 ml-2">{ activity?.status ? arrStatusDefinition[activity?.status] : '' }</div>
                                            <div className="cell text-slate-500  ml-4">{ getFormattedDateTime(activity?.startDate || '') }</div>
                                            <div className="cell text-slate-500  ml-4">{ getFormattedDateTime(activity?.endedDate || '') }</div>
                                        </div>
                                    )) }
                                </div>
                            </div>
                    </div>
                </div> 
            </div>
        </>
    )
}