/* eslint-disable react/jsx-pascal-case */
import React, { FC } from "react";
import Tile3x3_Text from "../components/applicationComponents/Tiles/Tile3x3_Text/Tile3x3_Text";
import Tile5x4_ChartText from "../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText";
import Tile51 from "../components/applicationComponents/Tiles/Tile51/Tile51";
import Tile52 from "../components/applicationComponents/Tiles/Tile52/Tile52";
import ActivityTimeline from "../components/applicationComponents/Tiles/ActivityTimelineTile/ActivityTimeline";
import Tile54 from "../components/applicationComponents/Tiles/Tile54/Tile54";
import Tile3x3_TransparentText from "../components/applicationComponents/Tiles/Tile3x3_TransparentText/Tile3x3_TransparentText";
import { Tile } from "../components/core";
// import ButtonGroup from "../components/core/ButtonGroup/ButtonGroup";
// import { ThemeContext } from "../contexts";
import Tile55 from "../components/applicationComponents/Tiles/Tile55/Tile55";
import Tile56 from "../components/applicationComponents/Tiles/Tile56/Tile56";
import Tile57 from "../components/applicationComponents/Tiles/Tile57/Tile57";
import Tile58 from "../components/applicationComponents/Tiles/Tile58/Tile58";
// import Tile59 from "../components/applicationComponents/Tiles/Tile59/Tile59";
import Tile60 from "../components/applicationComponents/Tiles/Tile60/Tile60";
import Tile61 from "../components/applicationComponents/Tiles/Tile61/Tile61";
import TableExample from "./TableExample";
/**
 *
 * @returns
 */
const ServiceTest: FC = () => {
    // const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    return (
        <>
           <div className="p-6 w-full">
           <div className={`grid grid-cols-12 gap-6 w-full min-h-max `}>

                <Tile3x3_TransparentText title='Managed Products' icon='fa-regular fa-user' iconStyle="text-[#FF5E0E]" value='2500' />
                <Tile3x3_TransparentText title='Tickets' icon='fa-regular fa-dollar-sign' iconStyle="text-[#56CA00]" value='500' />
                <Tile3x3_TransparentText title='License Status' icon='fa-regular fa-file-certificate' iconStyle="text-[#16B1FF]" value='3500' />
                <Tile3x3_TransparentText title='Total Profit' icon='fa-regular fa-chart-line-up' iconStyle="text-[#FFB400]" value='200' />

                <Tile3x3_Text title='Managed Products' icon='fa-regular fa-user' iconStyle="text-[#FF5E0E]" value='2500'/>
                <Tile3x3_Text title='Tickets' icon='fa-regular fa-dollar-sign' iconStyle="text-[#56CA00]" value='500'/>
                <Tile3x3_Text title='License Status' icon='fa-regular fa-file-certificate' iconStyle="text-[#16B1FF]" value='3500'/>
                <Tile3x3_Text title='Total Profit' icon='fa-regular fa-chart-line-up' iconStyle="text-[#FFB400]" value='200'/>
                
                <Tile5x4_ChartText title="Managed Products" description="Managed Products description" value="2000" trend="up" trendValue="28%" 
                    designOption="chart"
                    chartType="donut" 
                    chartLabels={['Red', 'Blue', 'Yellow', 'Green']} 
                    chartDataset={[
                        {
                            label: '# of Votes',
                            data: [5, 5, 5, 5],
                            backgroundColor: [
                                'rgba(255, 94, 14, 1)',
                                'rgba(22, 177, 255, 1)',
                                'rgba(255, 180, 0, 1)',
                                'rgba(255, 76, 81, 1)',
                            ],
                            borderWidth: 5,
                            cutout: 40,
                        },
                    ]}
                    chartOptions={{
                        responsive: true,
                        plugins: {
                            legend: { display: false },
                            title: { display: false },
                        },
                        scales: {
                            x: { display: false },
                            y: { display: false },
                        },
                    }}
                    inChartMain='28%' inChartSub="1 Quatre"
                    className=''
                    tileMode='default'
                    alert={false}
                    live={false}
                />
                <Tile5x4_ChartText title="Managed Products" description="Managed Products description" value="2000" trend="up" trendValue="28%" 
                    designOption="chart"
                    chartType="line" 
                    chartLabels={['January', 'February', 'March', 'April', 'May', 'June', 'July']} 
                    chartDataset={[
                        {
                            fill: false,
                            label: 'Dataset 1',
                            data: [2,4,5,3,4,5,6],
                            borderColor: 'rgba(255, 94, 14, 1)',
                            backgroundColor: 'rgba(255, 94, 14, 1)',
                            tension: 0.5,
                            pointRadius: 0,
                            showLine: true,
                        },
                    ]} 
                    chartOptions={{
                        responsive: true,
                        maintainAspectRatio: false,
                        bezierCurve: true,
                        plugins: {
                            legend: {
                                display: false,
                                position: 'bottom' as const,
                            },
                            title: {
                                display: false,
                                text: 'Line Chart',
                            },
                        },
                        scales: {
                            x: {
                                display: false,
                            },
                            y: {
                                type: 'linear' as const,
                                display: false,
                                position: 'left' as const,
                            }
                        }
                    }}
                    className=''
                    tileMode='default'
                    alert={false}
                    live={false}
                />
                <Tile5x4_ChartText title="Managed Products" description="Managed Products description" value="2000" trend="up" trendValue="28%" 
                    designOption="chart"
                    chartType="bar" 
                    chartLabels={['January', 'February', 'March', 'April', 'May', 'June', 'July']} 
                    chartDataset={[
                        {
                            label: 'Dataset 1',
                            data: [3,4,7,3,2,8,4],
                            backgroundColor: 'rgba(97, 97, 97, 1)',
                            stack: 'Stack 0',
                            borderRadius: 5,
                            barThickness: 10,
                        },
                        {
                            label: 'Dataset 2',
                            data: [4,6,2,4,8,9,2],
                            backgroundColor: 'rgba(255, 76, 81, 1)',
                            stack: 'Stack 0',
                            borderRadius: 5,
                            barThickness: 10,
                        },
                    ]} 
                    chartOptions={{
                        indexAxis: 'x' as const,
                        responsive: true,
                        maintainAspectRatio: false,
                        interaction: {
                            mode: 'index' as const,
                            intersect: false,
                        },
                        plugins: {
                            legend: {
                                display: false,
                                position: 'bottom' as const,
                            },
                            title: {
                                display: false,
                                text: 'Bar Chart',
                            },
                        },
                        scales: {
                            x: {
                                stacked: true,
                                display: false,
                            },
                            y: {
                                stacked: true,
                                display: false,
                            },
                        },
                        
                    }}
                    className=''
                    tileMode='default'
                    alert={false}
                    live={false}
                />

                <Tile55 title="$35.4k" description="Total Revenue" 
                    chartType="line" 
                    chartLabels={['January', 'February', 'March', 'April', 'May', 'June', 'July']} 
                    chartDataset={[
                        {
                            fill: false,
                            label: 'Dataset 1',
                            data: [2,4,5,3,4,5,6],
                            borderColor: 'rgba(255, 94, 14, 1)',
                            backgroundColor: 'rgba(255, 94, 14, 1)',
                            tension: 0.5,
                            pointRadius: 0,
                            showLine: true,
                        },
                    ]} 
                    chartOptions={{
                        responsive: true,
                        maintainAspectRatio: false,
                        bezierCurve: true,
                        plugins: {
                            legend: {
                                display: false,
                                position: 'bottom' as const,
                            },
                            title: {
                                display: false,
                                text: 'Line Chart',
                            },
                        },
                        scales: {
                            x: {
                                display: false,
                            },
                            y: {
                                type: 'linear' as const,
                                display: false,
                                position: 'left' as const,
                            }
                        }
                    }}
                />
                <Tile55 title="43,500" description="Sessions" 
                    chartType="bar" 
                    chartLabels={['January', 'February', 'March', 'April', 'May', 'June', 'July']} 
                    chartDataset={[
                        {
                            label: 'Dataset 1',
                            data: [3,4,7,3,2,8,4],
                            backgroundColor: 'rgba(97, 97, 97, 1)',
                            stack: 'Stack 0',
                            borderRadius: 5,
                            barThickness: 10,
                            borderSkipped: true,
                        },
                        {
                            label: 'Dataset 2',
                            data: [4,6,2,4,8,9,2],
                            backgroundColor: 'rgba(255, 76, 81, 1)',
                            stack: 'Stack 0',
                            borderRadius: 5,
                            barThickness: 10,
                            borderSkipped: true,
                        },
                    ]} 
                    chartOptions={{
                        indexAxis: 'x' as const,
                        responsive: true,
                        maintainAspectRatio: false,
                        interaction: {
                            mode: 'index' as const,
                            intersect: false,
                        },
                        plugins: {
                            legend: {
                                display: false,
                                position: 'bottom' as const,
                            },
                            title: {
                                display: false,
                                text: 'Bar Chart',
                            },
                        },
                        scales: {
                            x: {
                                stacked: true,
                                display: false,
                            },
                            y: {
                                stacked: true,
                                display: false,
                            },
                        },
                        
                    }}
                />
                <Tile55 title="42.5k" description="Total Growth" 
                    chartType="line" chartLabels={['January', 'February', 'March', 'April', 'May', 'June', 'July']} 
                    chartDataset={[
                        {
                            fill: false,
                            label: 'Dataset 1',
                            data: [0,1,2,1,3,1,4],
                            borderColor: 'rgba(86, 202, 0, 1)',
                            backgroundColor: 'rgba(86, 202, 0, 1)',
                            tension: 0.5,
                            pointRadius: 0,
                            showLine: true,
                        },
                    ]}
                    chartOptions={{
                        responsive: true,
                        maintainAspectRatio: false,
                        bezierCurve: true,
                        plugins: {
                            legend: {
                                display: false,
                                position: 'bottom' as const,
                            },
                            title: {
                                display: false,
                                text: 'Line Chart',
                            },
                        },
                        scales: {
                            x: {
                                display: false,
                            },
                            y: {
                                type: 'linear' as const,
                                display: false,
                                position: 'left' as const,
                            }
                        }
                    }}
                />
                <Tile55 title="135k" description="Total Sales" 
                    chartType="bar" chartLabels={['January', 'February', 'March', 'April', 'May', 'June', 'July']} 
                    chartDataset={[
                        {
                            label: 'Dataset 1',
                            data: [3,4,7,3,2,8,4],
                            backgroundColor: 'rgba(255, 76, 81, 1)',
                            stack: 'Stack 0',
                            borderRadius: 5,
                            barThickness: 10,
                        },
                        {
                            label: 'Dataset 2',
                            data: [4,6,2,4,8,9,2],
                            backgroundColor: 'rgba(244, 245, 250, 1)',
                            stack: 'Stack 0',
                            borderRadius: 5,
                            barThickness: 10,
                        },
                    ]}
                    chartOptions={{
                        indexAxis: 'x' as const,
                        responsive: true,
                        maintainAspectRatio: false,
                        interaction: {
                            mode: 'index' as const,
                            intersect: false,
                        },
                        plugins: {
                            legend: {
                                display: false,
                                position: 'bottom' as const,
                            },
                            title: {
                                display: false,
                                text: 'Bar Chart',
                            },
                        },
                        scales: {
                            x: {
                                stacked: true,
                                display: false,
                            },
                            y: {
                                stacked: true,
                                display: false,
                            },
                        },
                        
                    }}
                />
                <Tile55 title="2,856" description="Sessions" 
                    chartType="bar" chartLabels={['January', 'February', 'March', 'April', 'May', 'June', 'July']} 
                    chartDataset={[
                        {
                            label: 'Dataset 1',
                            data: [3,4,7,3,2,8,4],
                            backgroundColor: 'rgba(255, 76, 81, 1)',
                            stack: 'Stack 0',
                            borderRadius: 5,
                            barThickness: 10,
                        },
                        {
                            label: 'Dataset 2',
                            data: [4,6,2,4,8,9,2],
                            backgroundColor: 'rgba(244, 245, 250, 1)',
                            stack: 'Stack 0',
                            borderRadius: 5,
                            barThickness: 10,
                        },
                    ]}
                    chartOptions={{
                        indexAxis: 'x' as const,
                        responsive: true,
                        maintainAspectRatio: false,
                        interaction: {
                            mode: 'index' as const,
                            intersect: false,
                        },
                        plugins: {
                            legend: {
                                display: false,
                                position: 'bottom' as const,
                            },
                            title: {
                                display: false,
                                text: 'Bar Chart',
                            },
                        },
                        scales: {
                            x: {
                                stacked: true,
                                display: false,
                            },
                            y: {
                                stacked: true,
                                display: false,
                            },
                        },
                        
                    }}
                />
                <Tile55 title="$86.4k" description="Total Profit" 
                    chartType="line" chartLabels={['January', 'February', 'March', 'April', 'May', 'June', 'July']} 
                    chartDataset={[
                        {
                            fill: false,
                            label: 'Dataset 1',
                            data: [0,1,2,1,3,1,4],
                            borderColor: 'rgba(255, 94, 14, 1)',
                            backgroundColor: 'rgba(255, 94, 14, 1)',
                            pointRadius: 0,
                            showLine: true,
                        },
                    ]}
                    chartOptions={{
                        responsive: true,
                        maintainAspectRatio: false,
                        bezierCurve: true,
                        plugins: {
                            legend: {
                                display: false,
                                position: 'bottom' as const,
                            },
                            title: {
                                display: false,
                                text: 'Line Chart',
                            },
                        },
                        scales: {
                            x: {
                                display: false,
                                grid: {
                                    display: false,
                                }
                            },
                            y: {
                                type: 'linear' as const,
                                display: false,
                                position: 'left' as const,
                                grid: {
                                    display: false,
                                },
                            }
                        }
                    }}
                />
                
                <Tile57 
                    title='Total Profit' 
                    chartType="bar"
                    chartLabels={['2015', '2016', '2017', '2018', '2019', '2020', '2021']}
                    chartDataset={[
                        {
                            label: 'Dataset 1',
                            data: [28000,21000,25000,18000,28000,19000,33000],
                            backgroundColor: 'rgba(255, 94, 14, 1)',
                            stack: 'Stack 0',
                            borderRadius: 5,
                            barThickness: 10,
                        },
                        {
                            label: 'Dataset 1',
                            data: [0,8000,6000,8000,0,5000,4000],
                            backgroundColor: 'rgba(86, 202, 0, 1)',
                            stack: 'Stack 0',
                            borderRadius: 5,
                            barThickness: 10,
                        },
                        {
                            label: 'Dataset 1',
                            data: [0,0,0,8000,0,4000,5000],
                            backgroundColor: 'rgba(138, 141, 147, 1)',
                            stack: 'Stack 0',
                            borderRadius: 5,
                            barThickness: 10,
                        }
                    ]}
                    chartOptions={{
                        indexAxis: 'x' as const,
                        responsive: true,
                        maintainAspectRatio: false,
                        interaction: {
                            mode: 'index' as const,
                            intersect: false,
                        },
                        plugins: {
                            legend: {
                                display: false,
                                position: 'bottom' as const,
                            },
                            title: {
                                display: false,
                                text: 'Bar Chart',
                            },
                        },
                        scales: {
                            x: {
                                beginAtZero: false,
                                stacked: true,
                                display: true,
                                grid: {
                                    display: false,
                                    borderDash: [8, 4]
                                }

                            },
                            y: {
                                stacked: true,
                                display: true,
                                grid: {
                                    display: true,
                                    borderDash: [8, 4]
                                },
                                layout: {
                                    padding: {
                                       left: 10
                                    }
                                }
                            },
                        },
                        ticks: {
                            stepSize: 10000,
                            callback: function(value:number) {
                               var ranges = [
                                  { divider: 1e3, suffix: 'K' }
                               ];
                               function formatNumber(n:number) {
                                  for (var i = 0; i < ranges.length; i++) {
                                     if (n >= ranges[i].divider) {
                                        return (n / ranges[i].divider).toString() + ranges[i].suffix;
                                     }
                                  }
                                  return n;
                               }
                               return '$' + formatNumber(value);
                            }
                        }
                    }}

                    secondaryTitle='$482.85k'
                    secondaryDescription='Last month balance $234.40k'
                    secondaryNav={[
                        {
                            name: 'Option 1'
                        },
                        {
                            name: 'Option 1'
                        },
                        {
                            name: 'Option 1'
                        },
                        {
                            name: 'Option 1'
                        },
                        {
                            name: 'Option 1'
                        },
                        {
                            name: 'Option 1'
                        },
                        {
                            name: 'Option 1'
                        }
                    ]}
                    secondaryData={[
                        {
                            value: '$48,568.20',
                            description: 'Total Profit',
                            icon: 'fa-regular fa-chart-line-up'
                        },
                        {
                            value: '$34,453.25',
                            description: 'Total Income',
                            icon: 'fa-regular fa-user'
                        },
                        {
                            value: '$2,453.45',
                            description: 'Total Expense',
                            icon: 'fa-regular fa-laptop-mobile'
                        },
                        {
                            value: '$2,453.45',
                            description: 'Total Expense',
                            icon: 'fa-regular fa-laptop-mobile'
                        },
                        {
                            value: '$2,453.45',
                            description: 'Total Expense',
                            icon: 'fa-regular fa-laptop-mobile'
                        },
                        {
                            value: '$2,453.45',
                            description: 'Total Expense',
                            icon: 'fa-regular fa-laptop-mobile'
                        },
                        {
                            value: '$2,453.45',
                            description: 'Total Expense',
                            icon: 'fa-regular fa-laptop-mobile'
                        }
                    ]}

                    secondaryButtonText='View Report'
                    secondaryButtonLink='#'
                />
                <Tile58
                    title='Total Visitors'
                    navItems={[
                        {
                            name: 'Option 1'
                        },
                        {
                            name: 'Option 1'
                        },
                        {
                            name: 'Option 1'
                        },
                        {
                            name: 'Option 1'
                        },
                        {
                            name: 'Option 1'
                        },
                        {
                            name: 'Option 1'
                        },
                        {
                            name: 'Option 1'
                        }
                    ]}
                    chartType='donut'
                    chartLabels={['FR', 'UK', 'ESP', 'USA']}
                    chartDataset={[
                        {
                          label: '# of Votes',
                          data: [10, 30, 10, 50],
                          backgroundColor: [
                            'rgba(255, 94, 14, 1)',
                            'rgba(255, 94, 14, 0.7)',
                            'rgba(255, 94, 14, 0.5)',
                            'rgba(244, 245, 250, 1)',
                          ],
                          borderColor: [
                            'rgba(255, 255, 255, 1)',
                            'rgba(255, 255, 255, 1)',
                            'rgba(255, 255, 255, 1)',
                            'rgba(255, 255, 255, 1)',
                          ],
                          borderWidth: 0,
                        //   borderRadius: 20,
                          cutout: 80,
                        },
                    ]}
                    chartOptions={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false,
                                position: 'bottom' as const,
                            },
                            title: {
                                display: false,
                                text: 'Donut Chart',
                            },
                        },
                        scales: {
                            x: {
                                display: false,
                            },
                            y: {
                                display: false,
                            },
                        },
                    }}
                    inChartMain='100%'
                    inChartSub="Weekly Visits"
                />


                {/* <Tile59 
                    title="Weekly Sales"
                    navItems={[
                        {
                            name: 'Option 1'
                        },
                        {
                            name: 'Option 1'
                        },
                        {
                            name: 'Option 1'
                        },
                        {
                            name: 'Option 1'
                        },
                        {
                            name: 'Option 1'
                        },
                        {
                            name: 'Option 1'
                        },
                        {
                            name: 'Option 1'
                        }
                    ]}
                    chartType='bar'
                    chartLabels={[]}
                    chartDataset={}
                    chartOptions={}
                /> */}
                <Tile60 />
                <Tile61 />

                <Tile51/>
                <Tile52/>
                <Tile52/>
                <Tile54/>

                <ActivityTimeline />
                <Tile56/>

                <Tile colSpan={12} rowSpan={13} className="p-0 overflow-hidden">
                    <TableExample />
                </Tile>
            </div>
            </div>
        </>
    )
}
export default ServiceTest;