import { deviceSymbol, getDeviceStatus } from "../agentUtils";

const SortWithDeviceId = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = Number(a?.device_id?.text);
        const symbolB = Number(b?.device_id?.text);

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}
const SortWithHostname = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = a?.hostname?.text;
        const symbolB = b?.hostname?.text;

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA.localeCompare(symbolB);
        } else if ( order === 'DESC' ) {
            return symbolB.localeCompare(symbolA);
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}
const SortWithOS = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = a?.os?.text;
        const symbolB = b?.os?.text;

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA.localeCompare(symbolB);
        } else if ( order === 'DESC' ) {
            return symbolB.localeCompare(symbolA);
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}
const SortWithDeviceStatus = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = a?.status?.text;
        const symbolB = b?.status?.text;

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA.localeCompare(symbolB);
        } else if ( order === 'DESC' ) {
            return symbolB.localeCompare(symbolA);
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}
const SortWithLastConnection = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = Number(a?.lastConnection?.raw);
        const symbolB = Number(b?.lastConnection?.raw);

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}
const SortWithLastBackup = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = Number(a?.lastBackup?.raw);
        const symbolB = Number(b?.lastBackup?.raw);

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}
const SortWithProtectedData = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = Number(a?.protectedData?.raw);
        const symbolB = Number(b?.protectedData?.raw);

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}
const SortWithInstalledDate = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = a?.installed_at?.text === 'NA' ? 0 : +new Date(a?.installed_at?.text);
        const symbolB = b?.installed_at?.text === 'NA' ? 0 : +new Date(b?.installed_at?.text);

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}
const SortWithDeletedData = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = a?.deleted_at?.text === 'NA' ? 0 : +new Date(a?.deleted_at?.text);
        const symbolB = b?.deleted_at?.text === 'NA' ? 0 : +new Date(b?.deleted_at?.text);

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}

export {
    SortWithDeviceId,
    SortWithHostname,
    SortWithOS,
    SortWithDeviceStatus,
    SortWithLastConnection,
    SortWithLastBackup,
    SortWithProtectedData,
    SortWithInstalledDate,
    SortWithDeletedData,
}