import React, { FC, ReactNode, ComponentProps } from "react";


interface Props extends ComponentProps<"div">  {
    /**
     * Child node(s) of button
     */
    children?: ReactNode | ReactNode[] | string;
    /**
     * 
     */
    className?: string
}

/**
 * Button Component
 * @param param0 
 * @returns 
 */
const ModalBody:FC<Props> = ({
    className='',
    children=<></>,
    ...rest
}: Props) => {
    return (
        <>
            <div className={`modal-body grow ${className}`}>{ children }</div>
        </>
    );
}

export default ModalBody;
