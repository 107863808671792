import React, { FC, useContext, useState } from "react";
import { ThemeContext } from "../contexts";
import { ReactSortable } from "react-sortablejs";

interface ItemType {
    id: number;
    name: string;
    background: boolean;
    colSpan: number;
    rowSpan: number;
    height: string;
}

/**
 * bg-orange-light-tile-background bg-orange-dark-tile-background
 * bg-blue-light-tile-background bg-blue-dark-tile-background
 * h-[160px] h-[344px] h-[526px] h-[710px] 
 * row-span-2 row-span-3 row-span-4 row-span-5 row-span-6 row-span-7 row-span-8 row-span-9 row-span-10
 * @returns 
 */
const Dashboard1: FC = () => {

    const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};

    const [state, setState] = useState<ItemType[]>([
        { id: 1, name: "Card 1", background: false, colSpan: 3, rowSpan: 1, height: '160px' },
        { id: 2, name: "Card 2", background: false, colSpan: 3, rowSpan: 1, height: '160px' },
        { id: 3, name: "Card 3", background: false, colSpan: 3, rowSpan: 1, height: '160px' },
        { id: 3, name: "Card 3", background: false, colSpan: 3, rowSpan: 1, height: '160px' },
        { id: 3, name: "Card 3", background: true, colSpan: 3, rowSpan: 4, height: '710px' },
        { id: 3, name: "Card 3", background: true, colSpan: 4, rowSpan: 1, height: '160px' },
        { id: 3, name: "Card 3", background: true, colSpan: 4, rowSpan: 1, height: '160px' },
        { id: 3, name: "Card 3", background: true, colSpan: 2, rowSpan: 1, height: '160px' },
        { id: 3, name: "Card 3", background: true, colSpan: 2, rowSpan: 1, height: '160px' },
        { id: 3, name: "Card 3", background: true, colSpan: 2, rowSpan: 1, height: '160px' },
        { id: 3, name: "Card 3", background: true, colSpan: 2, rowSpan: 1, height: '160px' },
        { id: 3, name: "Card 3", background: true, colSpan: 2, rowSpan: 1, height: '160px' },
        { id: 3, name: "Card 3", background: true, colSpan: 2, rowSpan: 1, height: '160px' },
        { id: 3, name: "Card 3", background: true, colSpan: 8, rowSpan: 1, height: '160px' },
        { id: 3, name: "Card 3", background: true, colSpan: 4, rowSpan: 1, height: '160px' },
        { id: 3, name: "Card 3", background: true, colSpan: 4, rowSpan: 1, height: '160px' },
        { id: 3, name: "Card 3", background: true, colSpan: 4, rowSpan: 1, height: '160px' },
        { id: 3, name: "Card 3", background: true, colSpan: 4, rowSpan: 1, height: '160px' },
        { id: 3, name: "Card 3", background: true, colSpan: 3, rowSpan: 1, height: '160px' },
        { id: 3, name: "Card 3", background: true, colSpan: 3, rowSpan: 1, height: '160px' },
        { id: 3, name: "Card 3", background: true, colSpan: 3, rowSpan: 1, height: '160px' },
        { id: 3, name: "Card 3", background: true, colSpan: 3, rowSpan: 1, height: '160px' },
        { id: 3, name: "Card 3", background: true, colSpan: 6, rowSpan: 1, height: '160px' },
        { id: 3, name: "Card 3", background: true, colSpan: 6, rowSpan: 1, height: '160px' },
    ]);

    return (
        <>
            <div className={`p-5`}>

                    <ReactSortable
                        list={state}
                        setList={setState}
                        className="main grid grid-cols-12 gap-6 mb-6"
                        group="groupName"
                        animation={200}
                        delayOnTouchOnly={true}
                        delay={2}
                    >
                        {state.map((item, itemIndex)=>(
                            <div key={itemIndex} className={`tile relative lg:col-span-${item.colSpan} col-span-12 row-span-${item.rowSpan} h-[${item.height}] p-5 rounded ${item.background ? `bg-${theme.themeColor}-${theme.themeContrast}-component-background` : ''} shadow hover:shadow-md`}>
                            </div>
                        ))}
                    </ReactSortable>
                    new
            </div>
        </>
    )
}

export default Dashboard1;
