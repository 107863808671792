import React, { FC, Dispatch, SetStateAction } from "react"
import { DonutChart } from "../../../components/Charts/ApacheECharts";
// import { MasterChart } from "../../../components/Charts/ChartJS";
import { Tile } from "../../../components/core";
import { formatSize } from "../../../utils/backupFileUtils";

interface ILargeFileCard {
    fileStatsRawData: any;
    fileStatsProcessedData: any;
    donutCutout: number;
    setSelectedTile: Dispatch<SetStateAction<string>>;
}

const LargeFileCard:FC<ILargeFileCard> = ({ fileStatsRawData, fileStatsProcessedData, donutCutout, setSelectedTile }) => {

    // const valuesCap = 5;
    // const [spliceValue, setSpliceValue] = useState<number[]>([0,20]);
    // const [topFiveOnly, setTopPathOnly] = useState<boolean>(false);
    // const [topFiveAmongAll, setTopPathAmongAll] = useState<boolean>(false);
    // const [topFiveAmongTwenty, setTopPathAmongTwenty] = useState<boolean>(false);

    // useEffect(()=>{
    //     if( topFiveOnly ) {
    //         setSpliceValue((prevState)=>{
    //             let newState = [...prevState];
    //             newState[1] = 5
    //             return newState;
    //         })
    //     } else {
    //         setSpliceValue((prevState)=>{
    //             let newState = [...prevState];
    //             newState[1] = 20
    //             return newState;
    //         })
    //     }
    // },[topFiveOnly])

    console.log({LargeFileCard: fileStatsRawData})

    return (
        <>
        {
            (fileStatsProcessedData?.largeFile || []).length > 0 ?
            <Tile 
                rowSpan={13} 
                colSpan={3}
                onClick={()=>{
                    setSelectedTile('LargeFiles');
                }}
                className="cursor-pointer"
            >
                <div className="flex flex-col h-full mb-1 p-5 text-[#3A3541]">
                    <div className="w-full h-full">
                        <DonutChart
                            title="Largest Files"
                            // subTitle="(Total files covered in Top 20 Extensions)"
                            subTitle="(Top 20)"
                            thickness={["45%","75%"]}
                            // fontStyle={
                            //     margine-bottom:
                            // }
                            legend={{show:false}}
                            data={[
                                ...(fileStatsRawData.data.stats.data.Stats.LargestFiles.Files.File || []).map(
                                    // ...(fileStatsRawData?.data?.stats?.data?.Stats?.LargestExts?.Extensions?.Extension || []).map(
                                        (n:any, i:number)=>{
                                            // console.log({n})
                                            return {
                                                value: n?.Size || "",
                                                name: (n?.Path || "").split('/').pop(),
                                                itemStyle: {
                                                    opacity: 1/(i+1)
                                                }
                                            }
                                        }
                                    ) 
                               
                            ]}
                            legendFormatter={(params)=>{
                                console.log(params)
                                return `
                                <div>
                                    ${params?.data?.name} <br>
                                    <em class="fa-solid fa-circle" style="color:rgba(255, 94, 14, ${(params?.data?.itemStyle?.opacity || 0).toFixed(1)})"></em> <strong>${formatSize(params?.data?.value).value} ${formatSize(params?.data?.value).unit}</strong><br>
                                    <em class="fa-regular fa-circle-dot" style="color:rgba(255, 94, 14, ${(params?.data?.itemStyle?.opacity || 0).toFixed(1)})"></em> ${params?.percent}% (out of top 20)
                                `
                            }}
                            centreValue={
                                <>
                                    <div className="text-2xl font-semibold">{formatSize((fileStatsProcessedData?.largeFile || []).reduce((a:number,d:any)=>{ a = a + d.size.text; return a },0) || 0).value + ' ' + formatSize((fileStatsProcessedData?.largeFile || []).reduce((a:number,d:any)=>{ a = a + d.size.text; return a },0)).unit}</div>
                                    <div className="text-sm font-normal">Large file size</div>
                                </>
                            }
                        />
                    </div>
                </div>
            </Tile> : 
            <Tile
                rowSpan={13} 
                colSpan={3} 
            >
                <div className="flex flex-col h-full mb-1 p-5 text-[#3A3541]">
                    <div className="flex flex-row justify-between w-full">
                        <div title='Ticket Status' className={`text-base md:text-lg lg:text-xl leading-8 font-medium whitespace-nowrap truncate`}></div>
                    </div>
                    <div className="regular flex-col gap-4 grow my-4 mt-4 mb-2 max-h-80 md:max-h-80 lg:max-h-80  items-center justify-center flex z-10 relative">
                        <div className="relative flex h-full aspect-square items-center justify-center align-middle">
                            <div className="h-[90%] lg:h-[70%] xl:h-[90%] aspect-square rounded-full bg-slate-300 animate-pulse"></div>
                            <div className="absolute h-[50%] aspect-square rounded-full border bg-white"></div>
                        </div>
                        <div className="absolute flex flex-col top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <span className="block bg-slate-300 animate-pulse h-[30px] w-[60px] xl:w-[60px] mb-2"></span>
                            <span className="block bg-slate-300 animate-pulse h-[10px] w-[60px] xl:w-[60px]"></span>
                        </div>
                        <div className="legends flex flex-row justify-center space-x-4 flex-wrap lg:-mt-4 2xl:mt-0">
                            <div className="flex flex-row items-center text-xs whitespace-nowrap pb-1">
                                <i className="fa-solid fa-circle text-slate-300 text-center justify-center items-start flex text-xs"></i>
                                <div className="ml-1">
                                    <span className="block bg-slate-300 animate-pulse h-[10px] w-[60px]"></span>
                                </div>
                            </div>
                            <div className="flex flex-row items-center text-xs whitespace-nowrap pb-1">
                                <i className="fa-solid fa-circle text-slate-300 text-center justify-center items-start flex text-xs"></i>
                                <div className="ml-1">
                                    <span className="block bg-slate-300 animate-pulse h-[10px] w-[60px]"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Tile>
        }
        </>
    )
}

export default LargeFileCard