/* eslint-disable react/jsx-pascal-case */
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import Tile5x4_ChartText from "../../../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText";
import { ITableControllerProps } from "../../../components/core/Table/Table";
import { getProtectedAgents, originalDisabledReasons } from "../../../utils/agentUtils";

interface IActiveTodayTile {
    endpointQuota: number;
    endpointData: any;
    setSelectedData: Dispatch<SetStateAction<string>>
    setTableController: Dispatch<SetStateAction<ITableControllerProps>>
    scrollToBottom: ()=>void;
}

const ActiveTodayTile:FC<IActiveTodayTile> = ({
    endpointQuota=0,
    endpointData,
    setSelectedData,
    setTableController,
    scrollToBottom
}) => {

    // console.log({endpointData})

    return (
        <>
            <div className="tile relative lg:col-span-4 md:col-span-6 col-span-12 row-span-5 hover:touch-pinch-zoom h-[150px] rounded-lg bg-orange-light-component-background shadow hover:shadow-md p-5 border cursor-pointer overflow-hidden"
                onClick={()=>{
                    setSelectedData('Active Today')
                    setTableController((prevState)=>{
                        let newState = {...prevState};
                        newState.data = (endpointData?.installed || []).filter((ep:any)=>{ 
                            let currentDate: string = (new Date()).toISOString().split('T')[0];
                            let subjectDate: string = (new Date(ep?.agent_profile?.NetworkInfos?.LastConnectionTime)).toISOString().split('T')[0]
                            return currentDate === subjectDate
                        });
                        scrollToBottom()
                        return newState;
                    })
                }}
            >
                <div className="flex h-full items-center text-[#3A3541]">
                    <div className="grow h-full">
                        <div className="text-xl leading-8 font-medium text-[#3A3541] mb-5 whitespace-nowrap">
                            <div className="flex items-center">Active Today</div>
                        </div>
                        <div className="text-xs leading-5 font-normal text-gray-400 tracking-widest"></div>
                        <div className="flex flex-row text-[#3A3541] items-center">
                            <div className="text-xl leading-8 font-medium pr-2">
                                <em className="fa-duotone fa-bolt text-5xl text-[#ff5e0e]"></em>
                            </div>
                        </div>
                    </div>
                    <div className="relative w-[114px] h-[114px] flex justify-center items-center">
                        <div className="absolute text-xl font-medium top-[32px] w-full text-center"></div>
                        <div className="absolute text-xs font-normal top-[60px] w-full text-center"></div>
                    </div>
                    <div className="text-7xl text-[#ff5e0e] w-28 h-28 flex items-end mb-8 text-right rounded-md absolute whitespace-nowrap right-4">
                        <div className="flex items-end absolute right-2">{(endpointData?.installed || []).filter((ep:any)=>{ 
                            let currentDate: string = (new Date()).toISOString().split('T')[0];
                            let subjectDate: string = (new Date(ep?.agent_profile?.NetworkInfos?.LastConnectionTime)).toISOString().split('T')[0]
                            return currentDate === subjectDate
                        }).length}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ActiveTodayTile;
