import React, { ComponentProps, FC, ReactNode } from "react";
import { Tile } from "../../../core";

interface IProp extends ComponentProps<"div"> {
    title: string;
    icon: string;
    iconStyle?: string;
    value: ReactNode | string;
    className?: string;
    tileMode?: "default" | "success" | "warning" | "info" | "danger" | "error";
    alert?: boolean;
    live?: boolean;
}

/**
 *
 * @param param0 
 * @returns 
 */
const Tile3x3_TransparentText:FC<IProp> = ({
    title='',
    icon='',
    iconStyle='',
    value='',
    tileMode='default',
    alert=false,
    live=false,
    className='',
}: IProp) =>{

    let tileModeStyle  = '';

    switch (tileMode) {
        case 'default':
            tileModeStyle = '';
            break;

        case 'success':
            tileModeStyle = 'border-green-500';
            break;

        case 'warning':
            tileModeStyle = 'border-yellow-500';
            break;

        case 'info':
            tileModeStyle = 'border-blue-500';
            break;

        case 'danger':
        case 'error':
            tileModeStyle = 'border-red-500';
            break;
    
        default:
            break;
    }

    return (
        <>
            <Tile rowSpan={3} colSpan={3} className={`relative bg-transparent border shadow-none ${tileModeStyle} ${className}`}>
                    <div className="p-5 flex justify-start items-center">
                        <div className="w-10 h-10 flex justify-center items-center align-middle rounded bg-white shadow-md">
                            <i className={`${icon} text-2xl  ${iconStyle}`}></i>
                        </div>
                        <div className="px-3">
                            <div className={`text-xs leading-5 font-normal text-orange-500`}>
                                {title} 
                                {alert && <em className="fa-solid fa-circle ml-3 text-red-500 animate-ping"></em>}
                            </div>
                            <div className={`text-xl leading-8 font-medium text-[#3A3541]`}>{value}</div>
                        </div>
                        {live && <div className="inline absolute top-2 right-3 text-xs">
                            <em className="fa-solid fa-circle text-red-500 blink"></em>
                        </div>}
                    </div>
            </Tile>
        </>
    )
}

export default Tile3x3_TransparentText;