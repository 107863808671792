/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { Breadcrumb, Button, Dropdown, Modal, ModalBody, ModalHeader, Tile } from "../../components/core";
import {DateTime} from "luxon";
import { getAPICall, postAPICall } from "../../services/APILayer/axiosMethodCalls";
import { AuditReportContext } from "../../contexts";
import { useNavigate } from "react-router-dom";
import { dateTimeToUnixLocalTimestamp, dateTimeToUnixTimestamp, localToGMT } from "../../utils/format";
import OutsideClickHandler from 'react-outside-click-handler';
import { processEndpointData } from "../../utils/auditUtils";


const EndpointDeviceAuditReportFilterPage = () =>{

    const navigator = useNavigate()
    const [pageSize, setPageSize] = useState<number[]>([0, 0]);

    const auditContext = useContext(AuditReportContext);

    const today = new Date();
    const sevenDatBefore = new Date(today.setDate(today.getDate() - 6));

    
    const [loader, setLoader] = useState<boolean>(false)
    const [modalVisible, setModalVisibility] = useState<boolean>(false)
    
    const [endpoints, setEndpoints] = useState<any>({})
    const [processedEndpoints, setProcessedEndpoints] = useState<any>({})
    const [rawActivities, setRawActivities] = useState<any>({})

    const d = new Date();
    const p = new Date(d.setDate( d.getDate()-1 ))

    useLayoutEffect(() => {
        function updateSize() {
            setPageSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    /* state management for calender visibility */
    const [calenderVisibleState, setCalenderVisibleState] = useState<boolean>(false);
    
    /* states to save calender values */
    const [globalCalenderState, setGlobalCalenderState] = useState<any[]>([
        {
          startDate: sevenDatBefore,
          endDate: p,
          key: 'selection',
          color: "#FF5E0E"
        }
    ]);
    /* states to save calender values on pressing custom select button */
    // const [globalSelectedDates, setGlobalSelectedDates] = useState<any[]>([
    //     {
    //       startDate: sevenDatBefore,
    //       endDate: new Date()
    //     }
    // ]);
    // const [reportTypeDropdownValue, setReportTypeDropdownValue] = useState<string|string[]>()

    /* search parameters */
    // const [searchParams, setSearchParams] = useState<any>({
    //     endpoints: [],
    //     range: {
    //         startDate: sevenDatBefore,
    //         endDate: new Date()
    //     },
    //     window: {
    //         value: 1,
    //         unit: 'd'
    //     }
    // })

    /* method sending post request for fetching audit data */
    const getEndpointAuditData = async (filter: any) => {
        try {
            const response = await postAPICall(
                `${process.env.REACT_APP_API_URL}/analytics/endpoints_audit_v2_report`,
                {
                    "filter":{
                        // start_date: "2023-03-01",
                        // end_date: "2023-04-15",
                        // activity_type:[ "endpoints-backup", "endpoints-restore", "endpoints-strategy-change", "endpoints-policy-change" ]
                        ...filter
                    }
                }
            );
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const getEndPoints = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( Object.keys( endpoints ).length > 0 ) {
            return;
        }
        const fetchEndPoints = async () => {
            await getEndPoints().then((data)=>{
                setEndpoints(data)
            }).catch((error)=>{
                console.log(error)
            }).finally()
        }
        fetchEndPoints()
    },[])

    useEffect(()=>{

        auditContext.setSearchParamErrors({
            ...auditContext.setSearchParamErrors,
            range: null
        })
        auditContext.setSearchParams((prevState:any)=>{
            let newState = {...prevState}
            newState.range.startDate = globalCalenderState[0].startDate;
            newState.range.endDate = globalCalenderState[0].endDate;
            return newState;
        })
    },[globalCalenderState])

    const checkQuery = async () => {
        let errors: any = {
            reportType:null,
            endpoints: null,
            range: null,
            window: null
        }
        
        let tempElements = auditContext?.searchParams?.endpoints || [];
        let tempReportType = Array.isArray(auditContext?.searchParams?.reportType) ? 'All' : auditContext?.searchParams?.reportType;
        

        // console.log({auditContext})

        if( (auditContext?.searchParams?.endpoints || []).length < 1 ) {
            tempElements = [
                ...(endpoints?.endpoints || [])
                    // .filter((ep:any)=>{ if(ep?.agent_profile?.AgentInfos?.ComputerName) { return true; } else { return false; } })
                    // .filter((ep:any)=>{ if(ep?.agent_profile?.AdminInfos?.Name.split('-')[0] !== '@del') { return true } })
                    .map((ep:any)=>{ return `(${ep.agent_id}) ${ep.agent_profile.AdminInfos.Name}` })
            ]
        }

        auditContext.setSearchParams((prevState:any)=>{
            let newState = {...prevState};
            newState.endpoints = tempElements;
            newState.reportType = tempReportType;
            return newState;
        })

        if( !auditContext.searchParams.range.startDate || !auditContext.searchParams.range.endDate ) {
            errors.range = 'Required Field';
        }

        if( !auditContext.searchParams.window.value || !auditContext.searchParams.window.unit ) {
            errors.window = 'Required Field';
        }

        auditContext.setSearchParamErrors(errors)

        // console.log({errors})

        if( errors.endpoints === null && errors.range === null && errors.window === null ) {
            // console.log({__pppp:(auditContext.searchParams.range.startDate).toISOString().split('T')[0]});
            // console.log({__pppp:(auditContext.searchParams.range.endDate).toISOString().split('T')[0]});

            // @TODO require loader on modal
            setLoader(true)
            setRawActivities({})
            setModalVisibility(true)

            let _startDate = (auditContext.searchParams.range.startDate).toISOString().split('T')[0];
            let _endDate = (auditContext.searchParams.range.endDate).toISOString().split('T')[0];
            let param = {
                start_date: _startDate,
                end_date: _endDate,
                activity_type:[
                    "endpoints-backup",
                    "endpoints-restore",
                    "endpoints-strategy-change",
                    "endpoints-policy-change"
                ]
            }

            console.log({param})

            await getEndpointAuditData(param).then((data)=>{
                setLoader(false)
                setRawActivities(data)
                console.log({_data:data})
            }).catch((error)=>{
                console.log({error})
            }).finally(()=>{ }) 
        }
    }

    useEffect(()=>{
        if( Object.keys(rawActivities).length < 1 ) {
            return;
        }

        console.log({_endpoints:endpoints})

        processEndpointData(endpoints).then((filterData:any)=>{
            console.group({filterData})
            setProcessedEndpoints(filterData)
        }).catch((error)=>{
            console.log(error)
        }).finally(()=>{})

    },[rawActivities])

    useEffect(()=>{
        if( Object.keys(processedEndpoints).length < 1 ) {
            return;
        }

        let restoreActivities = (rawActivities?.activities || [])?.['endpoints-restore']
        let backupActivities = (rawActivities?.activities || [])?.['endpoints-backup']
        let policyActivities = (rawActivities?.activities || [])?.['endpoints-policy-change']
        let strategyActivities = (rawActivities?.activities || [])?.['endpoints-strategy-change']

        let finalResult = (processedEndpoints || []).filter((ep:any)=>{
            return (auditContext?.searchParams?.endpoints || []).includes( `(${ep?.device_id?.text}) ${ep?.hostname?.text}` )
        }).map((ep:any)=>{

            if(!ep['activity']){ ep['activity'] = [] }
            if(!ep['restore_activities']){ ep['restore_activities'] = [] }
            if(!ep['backup_activities']){ ep['backup_activities'] = [] }
            if(!ep['policy_activities']){ ep['policy_activities'] = [] }
            if(!ep['strategy_activities']){ ep['strategy_activities'] = [] }

            if( (restoreActivities?.endpoints || []).includes( `${ep?.server_id?.text}#${ep?.device_id?.text}` ) ) {
                ep['activity'] = [...ep['activity'], 'Restore']
                let temp = (restoreActivities?.data || []).filter((a:any)=>{ return `${a?.server || ''}#${a?.agent_id || ''}` === `${ep?.server_id?.text}#${ep?.device_id?.text}` })
                ep['restore_activities'] = temp
            }
            if( (backupActivities?.endpoints || []).includes( `${ep?.server_id?.text}#${ep?.device_id?.text}` ) ) {
                ep['activity'] = [...ep['activity'], 'Backup']
                let temp = (backupActivities?.data || []).filter((a:any)=>{ return `${a?.server || ''}#${a?.agent_id || ''}` === `${ep?.server_id?.text}#${ep?.device_id?.text}` })
                ep['backup_activities'] = temp
            }
            if( (policyActivities?.endpoints || []).includes( `${ep?.server_id?.text}#${ep?.device_id?.text}` ) ) {
                ep['activity'] = [...ep['activity'], 'Policy']
                let temp = (policyActivities?.data || []).filter((a:any)=>{ return `${a?.server || ''}#${a?.agent_id || ''}` === `${ep?.server_id?.text}#${ep?.device_id?.text}` })
                ep['policy_activities'] = temp
            }
            if( (strategyActivities?.endpoints || []).includes( `${ep?.server_id?.text}#${ep?.device_id?.text}` ) ) {
                ep['activity'] = [...ep['activity'], 'Strategy']
                let temp = (strategyActivities?.data || []).filter((a:any)=>{ return `${a?.server || ''}#${a?.agent_id || ''}` === `${ep?.server_id?.text}#${ep?.device_id?.text}` })
                ep['strategy_activities'] = temp
            }

            return ep;

        })

        console.log({finalResult})

        auditContext.setSearchParams((prevState:any)=>{
            let newState = {...prevState}
            newState.data = finalResult || []
            return newState;
        });

    },[processedEndpoints])

    console.log(auditContext)
    
    return(
        <>
        <div className={`p-6 grow flex flex-col`}>
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Endpoint Audit Trail</h1>
                <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-bell-on', link: '/dashboard', name: 'Home' },
                    { icon: '', link: '', name: 'Managed Services' },
                    { icon: '', link: '/endpoint', name: 'Endpoint Backup' },
                    { icon: '', link: '', name: 'Audit Trail' },
                ]} />
                <div className={`grow flex flex-col`}>
                <div className="w-full">
                    <Tile colSpan={4} rowSpan={21} className="p-4">
                        <div className="w-full text-center text-2xl font-bold text-[#FF5E0E] mt-4 mb-8">
                            Audit Trail Search
                        </div>
                        <div className="flex flex-col ">
                            <div className="grid grid-flow-row-dense xl:gap-8 grid-cols-1 xl:grid-cols-3 w-full">
                                <div className="w-full items-center justify-center align-middle mb-2 flex">
                                    <div className="w-full sm:grow max-w-[600px] z-40">
                                        <Dropdown
                                            multiple={false}
                                            required={false}
                                            label='Select Report Type'
                                            placeholder='Select Report(s)'
                                            children='abc'
                                            allOptionSelector={true}
                                            options={['All','Backup','Restore','Strategy','Policy','Deleted','Installed','No Activity']}
                                            // value={}
                                            defaultValue={'All'}
                                            onChange={(e)=>{
                                                auditContext.setSearchParamErrors({
                                                    ...auditContext.setSearchParamErrors,
                                                    reportType: null
                                                })
                                                auditContext.setSearchParams((prevState: any)=>{
                                                    let newState = {...prevState}
                                                    newState.reportType = e.target.value
                                                    return newState
                                                })
                                            }}
                                            className=''
                                        />
                                        <small className="text-sm text-red-500">
                                            {auditContext.searchParamErrors.reportType && <>Required Field</>}
                                        </small>
                                    </div>
                                </div>
                                <div className="w-full items-center justify-center align-middle mb-2 flex">
                                    <div className="w-full sm:grow max-w-[600px] z-30">
                                        <Dropdown
                                            multiple={true}
                                            required={false}
                                            label='Endpoint Device(s)'
                                            placeholder='Select Endpoint(s)'
                                            children='abc'
                                            options={[
                                                ...(endpoints?.endpoints || [])
                                                    // .filter((ep:any)=>{ if(ep?.agent_profile?.AgentInfos?.ComputerName) { return true; } else { return false; } })
                                                    // .filter((ep:any)=>{ if(ep?.agent_profile?.AdminInfos?.Name.split('-')[0] !== '@del') { return true } })
                                                    .map((ep:any)=>{ return `(${ep.agent_id}) ${ep.agent_profile.AdminInfos.Name}` })
                                            ]}
                                            defaultValue={[
                                                ...(endpoints?.endpoints || [])
                                                    // .filter((ep:any)=>{ if(ep?.agent_profile?.AgentInfos?.ComputerName) { return true; } else { return false; } })
                                                    // .filter((ep:any)=>{ if(ep?.agent_profile?.AdminInfos?.Name.split('-')[0] !== '@del') { return true } })
                                                    .map((ep:any)=>{ return `(${ep.agent_id}) ${ep.agent_profile.AdminInfos.Name}` })
                                            ]}
                                            onChange={(e)=>{
                                                auditContext.setSearchParamErrors({
                                                    ...auditContext.setSearchParamErrors,
                                                    endpoints: null
                                                })
                                                auditContext.setSearchParams((prevState: any)=>{
                                                    let newState = {...prevState}
                                                    newState.endpoints = e.target.value
                                                    return newState
                                                })
                                            }}
                                            className=''
                                        />
                                        <small className="text-sm text-red-500">
                                            {auditContext.searchParamErrors.endpoints && <>Required Field</>}
                                        </small>
                                    </div>
                                </div>
                                <div className="w-full items-center justify-center align-middle mb-2 flex">
                                    <div className="w-full relative sm:grow max-w-[600px] mt-2 z-20">
                                        <OutsideClickHandler
                                            onOutsideClick={()=>{
                                                setCalenderVisibleState(false)
                                            }}
                                        >
                                            <label className="flex flex-col">
                                                <div className="font-semibold mb-2">Date Range <span className="text-red-500 ml-2">*</span></div>
                                                <div className="border rounded overflow-hidden w-full">
                                                    <button
                                                        onClick={()=>{
                                                            setCalenderVisibleState((prevState)=>{
                                                                return !prevState;
                                                            })
                                                        }}
                                                        className='px-4 py-[7px] w-full '
                                                    >
                                                        <div className="flex w-full items-center">
                                                            <div className="flex items-center justify-start grow">
                                                                <span className="whitespace-nowrap">{DateTime.fromISO((globalCalenderState[0].startDate).toISOString()).toFormat('dd MMM yyyy')}</span>
                                                                <em className="fa-regular fa-dash px-2 text-slate-300"></em>
                                                                <span className="whitespace-nowrap">{DateTime.fromISO((globalCalenderState[0].endDate).toISOString()).toFormat('dd MMM yyyy')}</span>
                                                            </div>
                                                            <i className="fa-solid fa-calendar-days text-[#ff5e0e] ml-4 border-l w-12"></i>
                                                        </div>
                                                    </button>
                                                    {calenderVisibleState && <span className={`auditTrailCalendar absolute ${pageSize[0]<=1280 ? "left-[0px]": "lg:-left-[600px]"} top-full rounded-md overflow-hidden border border-[#ff5e0e] z-50`}>
                                                        <DateRangePicker
                                                            onChange={(item:any) => {
                                                                setGlobalCalenderState([item.selection])
                                                            }}
                                                            moveRangeOnFirstSelection={false}
                                                            months={2}
                                                            ranges={globalCalenderState}
                                                            direction={pageSize[0]<=1280 ? "vertical": "horizontal"}
                                                            maxDate={p}
                                                            editableDateInputs={true}
                                                        />
                                                    </span>}
                                                </div>
                                            </label>
                                            <small className="text-sm text-red-500">{auditContext.searchParamErrors.range && <>Required Field</> }</small>
                                        </OutsideClickHandler>
                                    </div>
                                </div>
                                {/* <div className="w-full items-center justify-center align-middle mb-2 flex">
                                    <div className="w-full sm:grow max-w-[400px] mt-2 z-10">
                                        <div className="flex flex-col">
                                            <div className="font-semibold mb-2">Data Window <span className="text-red-500 ml-2">*</span></div>
                                            <div className="flex border rounded overflow-hidden ">
                                                <input 
                                                    type="number"
                                                    placeholder=""
                                                    className="outline-none py-[7px] pl-2 grow"
                                                    value={ auditContext.searchParams.window.value }
                                                    onChange={(e)=>{

                                                        auditContext.setSearchParamErrors({
                                                            ...auditContext.setSearchParamErrors,
                                                            window: null
                                                        })

                                                        auditContext.setSearchParams((prevState:any)=>{
                                                            let newState = {...prevState}
                                                            newState.window.value = e.target.value
                                                            return newState;
                                                        })
                                                    }}
                                                />
                                                <select
                                                    className="outline-none py-2 border-l items-center w-24 px-2"
                                                    value={ auditContext.searchParams.window.unit }
                                                    onChange={(e)=>{
                                                        auditContext.setSearchParams((prevState:any)=>{
                                                            let newState = {...prevState}
                                                            newState.window.unit = e.target.value
                                                            return newState;
                                                        })
                                                    }}
                                                >
                                                    { Math.ceil( Math.abs(globalCalenderState[0].startDate - globalCalenderState[0].endDate) / (1000 * 60 * 60 * 24) ) < 2 && 
                                                    <option value='h'>Hour(s)</option> }
                                                    <option value='d'>Day(s)</option>
                                                    <option value='w'>Week(s)</option>
                                                </select>
                                            </div>
                                        </div>
                                        <small className="text-sm text-red-500">{auditContext.searchParamErrors.window && <>Required Field</> }</small>
                                    </div>
                                </div> */}
                            </div>
                            <div className="grid grid-flow-row-dense xl:gap-4 grid-cols-1 xl:grid-cols-1 w-full">
                                <div className="items-center justify-center align-middle xl:mt-6 mb-2 flex w-full">
                                    <Button 
                                        className={`border shadow-md rounded-md font-semibold w-52 my-4`}
                                        solid={true}
                                        onClick={()=>{
                                            checkQuery()
                                        }}
                                    >
                                        <i className="fa-solid fa-magnifying-glass mr-2"></i> Search
                                    </Button>
                                </div>
                            </div>
                                
                                {/* <div className="relative ">
                                    <label className="flex flex-col">
                                        <div className="font-semibold mb-2">Date Range <span className="text-red-500 ml-2">*</span></div>
                                        <div className="border rounded overflow-hidden">
                                            <button
                                                onClick={()=>{
                                                    setCalenderVisibleState((prevState)=>{
                                                        return !prevState;
                                                    })
                                                }}
                                                className='px-4 py-2'
                                            >
                                                <div className="flex flex-row w-full items-center">
                                                    <div className="flex items-center md:w-80">
                                                    <span className="w-full whitespace-nowrap">{DateTime.fromISO((globalSelectedDates[0].startDate).toISOString()).toFormat('dd MMM yyyy')}</span>
                                                    <em className="fa-regular fa-dash px-2 text-slate-300"></em>
                                                    <span className="w-full whitespace-nowrap">{DateTime.fromISO((globalSelectedDates[0].endDate).toISOString()).toFormat('dd MMM yyyy')}</span>
                                                    </div>
                                                <i className="fa-solid fa-calendar-days text-[#ff5e0e] pl-4 ml-4 border-l md:w-1/6"></i>
                                                </div>
                                            </button>
                                        {calenderVisibleState && <span className={`absolute -left-[400px] top-full rounded-md overflow-hidden border border-[#ff5e0e] z-50`}>
                                            <DateRangePicker
                                                onChange={(item:any) => {
                                                    setGlobalCalenderState([item.selection])
                                                }}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                ranges={globalCalenderState}
                                                direction='horizontal'
                                            />
                                            <span className={`absolute left-4 bottom-4 w-48`}>
                                                <Button
                                                    solid={false}
                                                    className="w-full"
                                                    onClick={()=>{
                                                        setCalenderVisibleState(false)
                                                        setGlobalSelectedDates((prevState)=>{
                                                            let newState = [...prevState];
                                                            newState[0].startDate = globalCalenderState[0].startDate
                                                            newState[0].endDate = globalCalenderState[0].endDate
                                                            return newState;
                                                        })
                                                    }}
                                                >Select</Button>
                                            </span>
                                        </span>}
                                        </div>
                                    </label>
                                    <small className="text-sm text-red-500">{auditContext.searchParamErrors.range && <>Required Field</> }</small>
                                </div> */}
                                
                                
                            {/* </div> */}
                            <div className="w-full text-center text-[#FF5E0E]">
                                Please select above details to generate audit trail
                            </div>
                        </div>
                    </Tile>
                    {/* <Tile colSpan={5} rowSpan={15} className="p-4">
                        <div className="grid col-span-5 md:col-span-3 lg:col-span-4 gap">
                            
                        </div>
                    </Tile> */}
                </div>
                </div>
        </div>

        <Modal modalVisible={modalVisible} setModalVisibility={setModalVisibility} className="md:h-1/3 md:w-1/3 w-1/2 h-1/3">
            <ModalHeader modalVisible={modalVisible} setModalVisibility={setModalVisibility}>
                Result(s) {loader && <em className="fa-solid fa-loader fa-spin ml-2 text-[#FF5E0E]"></em> }
            </ModalHeader>
            <ModalBody className="flex flex-col items-center align-middle justify-center">
                { 
                    loader ? 
                    <>Please wait</> :
                    <>
                        { 
                            auditContext?.searchParams?.reportType === "All" && 

                            (rawActivities?.activities?.['endpoints-backup']?.data || []).filter((result:any)=>{ return (auditContext?.searchParams?.endpoints || []).map((ep:any)=>{ return ep.replace(/^(\()(\d+)(\)) (.+$)/i,'$2') }).includes(result.agent_id) }).length + 
                            (rawActivities?.activities?.['endpoints-restore']?.data || []).filter((result:any)=>{ return (auditContext?.searchParams?.endpoints || []).map((ep:any)=>{ return ep.replace(/^(\()(\d+)(\)) (.+$)/i,'$2') }).includes(result.agent_id) }).length + 
                            (rawActivities?.activities?.['endpoints-strategy-change']?.data || []).filter((result:any)=>{ return (auditContext?.searchParams?.endpoints || []).map((ep:any)=>{ return ep.replace(/^(\()(\d+)(\)) (.+$)/i,'$2') }).includes(result.agent_id) }).length + 
                            (rawActivities?.activities?.['endpoints-policy-change']?.data || []).filter((result:any)=>{ return (auditContext?.searchParams?.endpoints || []).map((ep:any)=>{ return ep.replace(/^(\()(\d+)(\)) (.+$)/i,'$2') }).includes(result.agent_id) }).length
                        }
                        { 
                            auditContext?.searchParams?.reportType === "Backup" && 

                            (rawActivities?.activities?.['endpoints-backup']?.data || []).filter((result:any)=>{ return (auditContext?.searchParams?.endpoints || []).map((ep:any)=>{ return ep.replace(/^(\()(\d+)(\)) (.+$)/i,'$2') }).includes(result.agent_id) }).length 
                        }
                        { 
                            auditContext?.searchParams?.reportType === "Restore" && 

                            (rawActivities?.activities?.['endpoints-restore']?.data || []).filter((result:any)=>{ return (auditContext?.searchParams?.endpoints || []).map((ep:any)=>{ return ep.replace(/^(\()(\d+)(\)) (.+$)/i,'$2') }).includes(result.agent_id) }).length 
                        }
                        { 
                            auditContext?.searchParams?.reportType === "Strategy" && 

                            (rawActivities?.activities?.['endpoints-strategy-change']?.data || []).filter((result:any)=>{ return (auditContext?.searchParams?.endpoints || []).map((ep:any)=>{ return ep.replace(/^(\()(\d+)(\)) (.+$)/i,'$2') }).includes(result.agent_id) }).length 
                        }
                        { 
                            auditContext?.searchParams?.reportType === "Policy" && 

                            (rawActivities?.activities?.['endpoints-policy-change']?.data || []).filter((result:any)=>{ return (auditContext?.searchParams?.endpoints || []).map((ep:any)=>{ return ep.replace(/^(\()(\d+)(\)) (.+$)/i,'$2') }).includes(result.agent_id) }).length 
                        }
                        { 
                            auditContext?.searchParams?.reportType === "Deleted" && 

                            (auditContext?.searchParams?.data || []).filter((ep:any)=>{
                                if(
                                    ep.deleted_at.text !== 'NA' && 
                                    new Date(ep.deleted_at.text) >= auditContext.searchParams.range.startDate &&
                                    new Date(ep.deleted_at.text) <= auditContext.searchParams.range.endDate
                                ) { return true }
                            }).length
                        }
                        { 
                            auditContext?.searchParams?.reportType === "Installed" && 

                            (auditContext?.searchParams?.data || []).filter((ep:any)=>{
                                if(
                                    ep.installed_at.text !== 'NA' && 
                                    new Date(ep.installed_at.text) >= auditContext.searchParams.range.startDate &&
                                    new Date(ep.installed_at.text) <= auditContext.searchParams.range.endDate
                                ) { return true }
                            }).length
                        }
                        { 
                            auditContext?.searchParams?.reportType === "No Activity" && 

                            (auditContext?.searchParams?.data || [])
                                    .filter((device:any)=>{ 
                                        if( device?.deleted_at?.text === 'NA' || new Date(device?.deleted_at?.text) > auditContext.searchParams.range.startDate ) {
                                            return true
                                        }
                                        return false
                                    })
                                    .filter((device:any)=>{ return (device?.activity || []).length < 1 }).length
                        } Results Found
                        
                    </>
                    
                }
                <Button
                    className="mt-2"
                    onClick={()=>{
                        navigator('/endpoint/audit/search/result/1')
                    }}
                >View Results</Button>

                {/* {JSON.stringify(auditContext?.searchParams)} */}
            </ModalBody>
        </Modal>
        </>
    )
}

export default EndpointDeviceAuditReportFilterPage
