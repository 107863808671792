import React from"react";
import { Tile } from"../../../core";


const ActivityTimeline= ( ) =>{
    return (
        <>
            <Tile rowSpan={13} colSpan={4} className="shadow hover:shadow-md p-0">
                    <div className="flex flex-col h-full mb-1 p-5 text-[#3A3541]">
                        <div className="flex flex-row justify-between w-full mb-5">
                            <div className={`text-xl leading-8 mb-5 w-full font-medium`}>Activity Timeline</div>
                        </div>
                        <div  className="flex flex-col justify-start text-[#3A3541]">
                            <div className="single_notification_group flex flex-col justify-start mb-4">
                                <div className="flex flex-row justify-start items-center w-full py-4">
                                    <i className="fa-solid fa-circle text-red-500"></i>
                                    <div className="text-base leading-6 font-medium ml-4 w-full">8 Invoices have been paid</div>
                                    <div className="text-orange-500 text-xs leading-5 font-light text-right">Wednesday</div>
                                </div> 
                                <div className="border-l-2 ml-2 px-6"> 
                                    <div className="text-sm leading-5 font-normal opacity-40">Invoices have been paid to the company.</div>
                                    <div className="flex items-center justify-start">
                                        <i className="fa-solid fa-file-pdf text-red-400 mr-2"></i>
                                        <div className="text-sm leading-5 font-semibold opacity-60">invoice.pdf</div>
                                    </div>
                                </div>
                            </div>
                            <div className="single_notification_group flex flex-col justify-start mb-4">
                                <div className="flex flex-row justify-start items-center w-full py-4">
                                    <i className="fa-solid fa-circle text-blue-500"></i>
                                    <div className="text-base leading-6 font-medium ml-4 w-full">Create a new project for client 😎</div>
                                    <div className="text-orange-500 text-xs leading-5 font-light text-right">Wednesday</div>
                                </div> 
                                <div className="border-l-2 ml-2 px-6"> 
                                    <div className="text-sm leading-5 font-normal opacity-40">Invoices have been paid to the company.</div>
                                    <div className="flex items-center justify-start">
                                        <i className="fa-solid fa-user text-red-400 mr-2"></i>
                                        <div className="text-sm leading-5 font-semibold opacity-60">John Doe (Client)</div>
                                    </div>
                                </div>
                            </div>
                            <div className="single_notification_group flex flex-col justify-start mb-4">
                                <div className="flex flex-row justify-start items-center w-full py-4">
                                    <i className="fa-solid fa-circle text-green-500"></i>
                                    <div className="text-base leading-6 font-medium ml-4 w-full">Order #37745 from September</div>
                                    <div className="text-orange-500 text-xs leading-5 font-light text-right">Wednesday</div>
                                </div> 
                            </div>
                        </div>
                    </div>
            </Tile>
        </>
    )
}

export default ActivityTimeline