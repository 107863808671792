/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
// import { Link } from "react-router-dom";
import { Button } from "../../components/core";
import ButtonGroup from "../../components/core/ButtonGroup/ButtonGroup";
// import Tooltip from "../../components/core/Tooltip/Tooltip";
import { getAPICall, postAPICall } from "../../services/APILayer/axiosMethodCalls";
// import { deviceSymbol, getDeviceStatus } from '../../utils/agentUtils'
// import { getFormattedDateTime } from '../../utils/format'
import { SideDrawerContext } from "../../contexts";
import { LevelOneComponent } from "../DashboardComponents/ActivityCard"
import { dataSizeConversion } from "../../utils/format";
import { formatSize } from "../../utils/backupFileUtils";
// import { active } from "sortablejs";


const ActivityNotificationCard = () => {

    const sideDrawerContext = useContext(SideDrawerContext);

    const [dataFilter, setDataFilter] = useState<string>('Last 24 Hrs');
    const [requestFilter, setRequestFilter] = useState<string>('1d');

    // Step One
    const [activityCallActive, setActivityCallActive] = useState<boolean>(true);
    const [rawActivity, setRawActivity] = useState<any>({});
    const [activityList, setActivityList] = useState<any[]>([]);

    const today = new Date();
    const twentyFourHour = new Date(today.setDate(today.getDate() - 1));
    const fortyEightHour = new Date(today.setDate(today.getDate() - 2));
    const seventyTwoHours = new Date(today.setDate(today.getDate() - 3));
    const [calenderVisibleState, setCalenderVisibleState] = useState<boolean>(false);
    const [activityCalenderState, setActivityCalenderState] = useState<any[]>([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
          color: "#FF5E0E"
        }
    ]);

    const [rawPoliciesStrategies,setPoliciesStrategies] = useState<any>({})

    const getActivityData = async () => {
        try {
            const response = await postAPICall(
                `${process.env.REACT_APP_API_URL}/analytics/endpoints_recent_activities`,
                {
                    "filter":{
                        "range": requestFilter
                    }
                }
            );
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const getPoliciesStrategies = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints/config`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const checkQuery = async () => {

        // @TODO require loader on modal
        setActivityCallActive(true)

        let _startDate = (activityCalenderState[0].startDate);
        let _endDate = (activityCalenderState[0].endDate);

        let param = {
            start_date: `${(_startDate.getFullYear())}-${('00'+(_startDate.getMonth()+1)).slice(-2)}-${('00'+_startDate.getDate()).slice(-2)}`,
            end_date: `${(_endDate.getFullYear())}-${('00'+(_endDate.getMonth()+1)).slice(-2)}-${('00'+_endDate.getDate()).slice(-2)}`,
            activity_type:[
                "endpoints-backup",
                "endpoints-restore",
                "endpoints-strategy-change",
                "endpoints-policy-change"
            ]
        }
        
        await getEndpointAuditData(param).then((data)=>{
            console.log({_data:data})
            setRawActivity(data)
        }).catch((error)=>{
            console.log({error})
        }).finally(()=>{
            setActivityCallActive(false)
        })
        
    }

    useEffect(()=>{
        setDataFilter('Last 24 Hrs')
        setRequestFilter('1d')
        getActivityData().then((res)=>{
            console.log({res})
            setRawActivity(res)
        }).catch((err)=>{
            console.log(err)
        })
        // setActivityCalenderState((previousState:any[])=>{
        //     return [{
        //         startDate: twentyFourHour,
        //         endDate: new Date(),
        //         key: 'selection',
        //         color: "#FF5E0E"
        //     }]
        // })
    },[])

    useEffect(()=>{
        checkQuery();
    },[activityCalenderState])

    useEffect(()=>{

        var finalActivities = []

        if( dataFilter === 'Date Range' ) {

            if( rawActivity?.activities?.['endpoints-backup']?.total > 0 ) {
                finalActivities.push({
                    "activity_type": "endpoints-backup",
                    "comment": `Total ${rawActivity?.activities?.['endpoints-backup']?.total_text} data backed up by ${rawActivity?.activities?.['endpoints-backup']?.endpoints.length} device(s).`
                })
            }
    
            if( rawActivity?.activities?.['endpoints-restore']?.total > 0 ) {
                finalActivities.push({
                    "activity_type": "endpoints-restore",
                    "comment": `Total ${rawActivity?.activities?.['endpoints-restore']?.total_text} data restored by ${rawActivity?.activities?.['endpoints-restore']?.endpoints.length} device(s).`
                })
            }
    
            if( rawActivity?.activities?.['endpoints-policy-change']?.total > 0 ) {
                finalActivities.push({
                    "activity_type": "endpoints-policy-change",
                    "comment": `${rawActivity?.activities?.['endpoints-policy-change']?.total} times policy changed in ${rawActivity?.activities?.['endpoints-policy-change']?.endpoints.length} device(s).`
                })
            }
    
            if( rawActivity?.activities?.['endpoints-strategy-change']?.total > 0 ) {
                finalActivities.push({
                    "activity_type": "endpoints-strategy-change",
                    "comment": `${rawActivity?.activities?.['endpoints-strategy-change']?.total} times strategy changed in ${rawActivity?.activities?.['endpoints-strategy-change']?.endpoints.length} device(s).`
                })
            }
        } else if( dataFilter === 'Last 24 Hrs' ) {

            console.log({__rawActivity:rawActivity});

            if( Array.isArray(rawActivity?.activities) ) {

                (rawActivity?.activities || [])?.map((a:any)=>{
                    if( a?.activity_type === 'endpoints-backup' ) {
                        finalActivities.push({
                            "activity_type": "endpoints-backup",
                            "comment": `Total ${formatSize(dataSizeConversion(a?.data[0],'MB','B').value).value} ${formatSize(dataSizeConversion(a?.data[0],'MB','B').value).unit} data backed up by ${(a?.endpoints || [])?.length} device(s).`
                        })
                    }
                    if( a?.activity_type === 'endpoints-restore' ) {
                        finalActivities.push({
                            "activity_type": "endpoints-restore",
                            "comment": `Total ${formatSize(dataSizeConversion(a?.data[0],'MB','B').value).value} ${formatSize(dataSizeConversion(a?.data[0],'MB','B').value).unit} data is restored by ${(a?.endpoints || [])?.length} device(s).`
                        })
                    }
                    if( a?.activity_type === 'endpoints-policy-change' ) {
                        finalActivities.push({
                            "activity_type": "endpoints-policy-change",
                            "comment": a?.comment
                        })
                    }
                    if( a?.activity_type === 'endpoints-strategy-change' ) {
                        finalActivities.push({
                            "activity_type": "endpoints-strategy-change",
                            // "comment": `Total ${a?.data[0]} times strategy changes by ${(a?.endpoints || [])?.length} device(s).`
                            "comment": a?.comment || ''
                        })
                    }
                })
            }

        }


        setActivityList(finalActivities)


    },[rawActivity])

    useEffect(()=>{
        if(!activityCallActive) {
            return;
        }

        if( dataFilter === 'Date Range' ) {
            return;
        }

        Promise.all([
            getActivityData(),
            getPoliciesStrategies()
        ]).then((response)=>{

            // activity data
            if((response[0]?.activities || []).length > 0) {
                // setActivityList(response[0]?.activities)
                setRawActivity(response[0])
            } else {
                // setActivityList()
            }

            // strategy policy data

            setPoliciesStrategies(response[1])

        }).catch((err)=>{console.log(err)}).finally(()=>{
            setActivityCallActive(false)
        })

    },[activityList, dataFilter, getActivityData])

    const getEndpointAuditData = async (filter: any) => {
        try {
            const response = await postAPICall(
                `${process.env.REACT_APP_API_URL}/analytics/endpoints_audit_v2_report`,
                {
                    "filter":{
                        // start_date: "2023-03-01",
                        // end_date: "2023-04-15",
                        // activity_type:[ "endpoints-backup", "endpoints-restore", "endpoints-strategy-change", "endpoints-policy-change" ]
                        ...filter
                    }
                }
            );
            return response.data;
        } catch(error) {
            return error;
        }
    }

    // useEffect(()=>{
    //     if(!activityCallActive) {
    //         return;
    //     }

    //     Promise.all([
    //         getActivityData(),
    //         getPoliciesStrategies()
    //     ]).then((response)=>{

    //         // activity data
    //         if((response[0]?.activities || []).length > 0) {
    //             setActivityList((prevState)=>{
    //                 return response[0]?.activities || [];
    //             })
    //         } else {
    //             setActivityList((prevState)=>{
    //                 return [];
    //             }) 
    //         }

    //         // strategy policy data

    //         setPoliciesStrategies(response[1])

    //     }).catch((err)=>{console.log(err)}).finally(()=>{
    //         setActivityCallActive(false)
    //     })

    // },[])

    // useEffect(()=>{
    //     if(!activityCallActive) {
    //         return;
    //     }

    //     Promise.all([
    //         getActivityData(),
    //         getPoliciesStrategies()
    //     ]).then((response)=>{

    //         // activity data
    //         if((response[0]?.activities || []).length > 0) {
    //             setActivityList((prevState)=>{
    //                 return response[0]?.activities || [];
    //             })
    //         } else {
    //             setActivityList((prevState)=>{
    //                 return [];
    //             }) 
    //         }

    //         // strategy policy data

    //         setPoliciesStrategies(response[1])

    //     }).catch((err)=>{console.log(err)}).finally(()=>{
    //         setActivityCallActive(false)
    //     })

    // },[activityList, dataFilter, getActivityData])

    return (
        <>
            <div className="tile relative rounded-lg w-[350px] bg-white col-span-12 mb-6">
                <div className="flex flex-col justify-center items-center w-full p-4 border-b">
                    <div className="title text-base md:text-lg lg:text-xl mb-2 text-gray-800 font-medium">
                        Recent Activities
                        {activityCallActive && <em className='fa-solid fa-loader fa-spin ml-4'></em>}
                    </div>
                    <ButtonGroup className="border">
                        {/* <Button 
                            size='sm'
                            solid={dataFilter === 'Last 24 Hrs' ? true : false}
                            onClick={()=>{
                                setActivityCallActive(true)
                                setDataFilter('Last 24 Hrs')
                                setRequestFilter('1d')
                                setActivityCalenderState((previousState:any[])=>{
                                    return [{
                                        startDate: twentyFourHour,
                                        endDate: new Date(),
                                        key: 'selection',
                                        color: "#FF5E0E"
                                    }]
                                })
                            }}
                        >Last 24 Hrs</Button> */}
                        {/* <Button 
                            size='sm'
                            solid={dataFilter === 'Last 48 Hrs' ? true : false}
                            onClick={()=>{
                                setActivityCallActive(true)
                                setDataFilter('Last 48 Hrs')
                                setRequestFilter('1d')
                                setActivityCalenderState((previousState:any[])=>{
                                    return [{
                                        startDate: fortyEightHour,
                                        endDate: new Date(),
                                        key: 'selection',
                                        color: "#FF5E0E"
                                    }]
                                })
                            }}
                        >Last 48 Hrs</Button>
                        <Button 
                            size='sm'
                            solid={dataFilter === 'Last 72 Hrs' ? true : false}
                            onClick={()=>{
                                setActivityCallActive(true)
                                setDataFilter('Last 72 Hrs')
                                setRequestFilter('1d')
                                setActivityCalenderState((previousState:any[])=>{
                                    return [{
                                        startDate: seventyTwoHours,
                                        endDate: new Date(),
                                        key: 'selection',
                                        color: "#FF5E0E"
                                    }]
                                })
                            }}
                        >Last 72 Hrs</Button> */}
                    </ButtonGroup>
                    {/* <ButtonGroup className="border">
                        <Button 
                            size='sm'
                            solid={dataFilter === 'Last 24 Hrs' ? true : false}
                            onClick={()=>{
                                setActivityCallActive(true)
                                setDataFilter('Last 24 Hrs')
                                setRequestFilter('1d')
                            }}
                        >Last 24 Hrs</Button>
                        <Button
                            size='sm'
                            solid={dataFilter === 'Last 48 Hrs' ? true : false}
                            onClick={()=>{
                                setActivityCallActive(true)
                                setDataFilter('Last 48 Hrs')
                                setRequestFilter('2d')
                            }}
                        >Last 48 Hrs</Button>
                        <Button
                            size='sm'
                            solid={dataFilter === 'Last 72 Hrs' ? true : false}
                            onClick={()=>{
                                setActivityCallActive(true)
                                setDataFilter('Last 72 Hrs')
                                setRequestFilter('3d')
                            }}
                        >Last 72 Hrs</Button>
                    </ButtonGroup> */}
                </div>
                <div className="w-full overflow-y-scroll customScroll min-h-[328px] max-h-[445px]">
                    {
                        activityList.length > 0 ?

                        activityList.map((activity, activityIndex)=>(
                            <div 
                                key={activityIndex}
                                className="p-2 grow flex flex-col border-b items-start w-full hover:bg-slate-100 cursor-pointer"
                                onClick={()=>{
                                    sideDrawerContext.setLevelOneDrawerVisibility(false)
                                    sideDrawerContext.setLevelTwoDrawerVisibility(false)
                                    sideDrawerContext.setFirstDrawerChild(
                                        <LevelOneComponent rawPoliciesStrategies={rawPoliciesStrategies} requestFilter={requestFilter} dataFilter={dataFilter} selectedData={activity} rawActivity={rawActivity}/>)
                                    sideDrawerContext.setLevelOneDrawerVisibility(true)
                                }}
                            >
                                <div className="flex flex-row">
                                { activity?.activity_type === 'endpoints-backup' && <i className="fa-solid fa-circle-dot mt-1 text-green-500 items-start flex justify-start"></i> }
                                { activity?.activity_type === 'endpoints-restore' && <i className="fa-solid fa-circle-dot mt-1 text-blue-500 items-start flex justify-start"></i> }
                                { activity?.activity_type === 'endpoints-policy-change' && <i className="fa-solid fa-circle-dot mt-1 text-red-500 items-start flex justify-start"></i> }
                                { activity?.activity_type === 'endpoints-strategy-change' && <i className="fa-solid fa-circle-dot mt-1 text-pink-500 items-start flex justify-start"></i> }
                                <div className=" flex flex-col grow md:ml-4 mx-2">
                                    <div className="text-base text-slate-800">{activity?.comment || ''}</div>
                                </div>
                                </div>
                                <span className="text-slate-500 whitespace-nowrap text-base ml-8">
                                    { activity?.activity_type === 'endpoints-backup' && 'Endpoint / Backup' }
                                    { activity?.activity_type === 'endpoints-restore' && 'Endpoint / Restore' }
                                    { activity?.activity_type === 'endpoints-policy-change' && 'Endpoint / Policy Change' }
                                    { activity?.activity_type === 'endpoints-strategy-change' && 'Endpoint / Strategy Change' }
                                </span>
                            </div>
                        ))
                        :
                        (
                            <span className="flex w-full items-center justify-center align-middle">
                                <span className="flex flex-col">
                                    <img src='/assets/img/well_done.svg' alt='well done' className="ml-auto mr-auto h-56 my-6" />
                                    <span className="flex">
                                        {!activityCallActive && <div className="w-full text-slate-500 text-center mb-6 text-2xl">No Activities</div>}
                                        {activityCallActive && <div className="w-full text-slate-500 text-center mb-6 text-2xl">Loading...</div>}
                                    </span>
                                </span>
                            </span>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default ActivityNotificationCard;