import React, { useEffect, useState } from "react"

interface ITimePicker {
    dayVisible?: boolean;
    timeFormat?: number; // '12' | '24'
    className?: string;
}

const TimePicker = ({
    dayVisible= true,
    timeFormat= 12,
    className= ''
}) => {

    const [selectedDay, setSelectedDay] = useState<string>('')
    const [selectedHours, setSelectedHours] = useState<string>('00')
    const [selectedMinutes, setSelectedMinutes] = useState<string>('00')
    const [selectedPart, setSelectedPart] = useState<string>('AM')

    const [arrDays, setArrDays] = useState<any[]>([
        {name:'Sunday', selected: false},
        {name:'Monday', selected: true},
        {name:'Tuesday', selected: false},
        {name:'Wednesday', selected: false},
        {name:'Thursday', selected: false},
        {name:'Friday', selected: false},
        {name:'Saturday', selected: false}
    ])

    useEffect(()=>{
        let newSelectedDay = arrDays.filter((day:any)=>{ return day.selected === true })[0].name;
        console.log(newSelectedDay)
        setSelectedDay(newSelectedDay)
    },[arrDays])

    const handleDayIncrement = () => {
        let currentSelectedIndex = 0;
        for (let i = 0; i < arrDays.length; i++) {
            const day = arrDays[i];
            if( day.selected === true ) {
                currentSelectedIndex = i
            }
        }

        let nextSelectedIndex = 1;
        if( currentSelectedIndex === 6 ) {
            nextSelectedIndex = 0;
        } else {
            nextSelectedIndex = currentSelectedIndex + 1;
        }

        setArrDays((prevState:any[])=>{
            let newState = [...prevState];
            newState[currentSelectedIndex].selected = false;
            newState[nextSelectedIndex].selected = true;
            return newState
        })
    }

    const handleDayDecrement = () => {
        let currentSelectedIndex = 1;
        for (let i = 0; i < arrDays.length; i++) {
            const day = arrDays[i];
            if( day.selected === true ) {
                currentSelectedIndex = i
            }
        }

        let nextSelectedIndex = 0;
        if( currentSelectedIndex === 0 ) {
            nextSelectedIndex = 6;
        } else {
            nextSelectedIndex = currentSelectedIndex - 1;
        }

        setArrDays((prevState:any[])=>{
            let newState = [...prevState];
            newState[currentSelectedIndex].selected = false;
            newState[nextSelectedIndex].selected = true;
            return newState
        })
    }

    const handleHoursIncrement = () => {
        let currentValue = Number(selectedHours);
        let newValue = '00';
        if( currentValue === timeFormat ) {
            newValue = '00';
        } else {
            newValue = twoDigitNumberString(currentValue + 1);
        }
        setSelectedHours(newValue);
    }
    
    const handleHoursDecrement = () => {
        let currentValue = Number(selectedHours);
        let newValue = '00';
        if( currentValue === 0 ) {
            newValue = twoDigitNumberString(timeFormat);
        } else {
            newValue = twoDigitNumberString(currentValue - 1);
        }
        setSelectedHours(newValue);
    }

    const handleMinutesIncrement = () => {
        let currentValue = Number(selectedMinutes);
        let newValue = '00';
        if( currentValue === 60 ) {
            newValue = '00';
        } else {
            newValue = twoDigitNumberString(currentValue + 1);
        }
        setSelectedMinutes(newValue);
    }
    
    const handleMinutesDecrement = () => {
        let currentValue = Number(selectedMinutes);
        let newValue = '00';
        if( currentValue === 0 ) {
            newValue = '60';
        } else {
            newValue = twoDigitNumberString(currentValue - 1);
        }
        setSelectedMinutes(newValue);
    }

    const handleTimeFormatChange = () => {
        if( selectedPart === 'AM' ) {
            setSelectedPart('PM')
        } else {
            setSelectedPart('AM')
        }
    }

    const twoDigitNumberString = (val:string|number) => {
        return `00${val}`.slice(-2)
    }


    return (
        <>
            <div className={`flex text-[#ff5e0e] ${className}`}>
                {/* {JSON.stringify(arrDays)} */}
                {dayVisible && 
                    <>
                        <div className="flex group">
                            <div contentEditable={false} className="min-w-[40px] group-hover:w-[85px] group-hover:bg-white">{ selectedDay }</div>
                            <div className="hidden group-hover:flex flex-col group-hover:bg-white">
                                <div className="flex items-center justify-center align-middle h-[12px] w-[12px] pt-2 px-2 cursor-pointer hover:bg-gray-100 group-hover:border border-slate-400"
                                    onClick={()=>{
                                        handleDayIncrement()
                                    }}
                                ><em className="fa-solid fa-sort-up text-slate-500"></em></div>
                                <div className="flex items-center justify-center align-middle h-[12px] w-[12px] pb-2 px-2 cursor-pointer hover:bg-gray-100 group-hover:border border-slate-400"
                                    onClick={()=>{
                                        handleDayDecrement()
                                    }}
                                ><em className="fa-solid fa-sort-down text-slate-500"></em></div>
                            </div>
                        </div>
                        <div className="mr-2">,</div>
                    </>
                }
                <div className="flex group">
                    <div className="flex">
                        <div contentEditable={false} className="min-w-[10px] group-hover:w-[30px] group-hover:bg-white">{selectedHours}</div>
                        <div className="hidden group-hover:flex flex-col group-hover:bg-white">
                            <div className="flex items-center justify-center align-middle h-[12px] w-[12px] pt-2 px-2 cursor-pointer hover:bg-gray-100 group-hover:border border-slate-400"
                                onClick={()=>{
                                    handleHoursIncrement()
                                }}
                            ><em className="fa-solid fa-sort-up text-slate-500"></em></div>
                            <div className="flex items-center justify-center align-middle h-[12px] w-[12px] pb-2 px-2 cursor-pointer hover:bg-gray-100 group-hover:border border-slate-400"
                                onClick={()=>{
                                    handleHoursDecrement()
                                }}
                            ><em className="fa-solid fa-sort-down text-slate-500"></em></div>
                        </div>
                    </div>
                    <div>:</div>
                    <div className="flex">
                        <div contentEditable={false} className="min-w-[10px] group-hover:w-[30px] group-hover:bg-white">{selectedMinutes}</div>
                        <div className="hidden group-hover:flex flex-col group-hover:bg-white">
                            <div className="flex items-center justify-center align-middle h-[12px] w-[12px] pt-2 px-2 cursor-pointer hover:bg-gray-100 group-hover:border border-slate-400"
                                onClick={()=>{
                                    handleMinutesIncrement()
                                }}
                            ><em className="fa-solid fa-sort-up text-slate-500"></em></div>
                            <div className="flex items-center justify-center align-middle h-[12px] w-[12px] pb-2 px-2 cursor-pointer hover:bg-gray-100 group-hover:border border-slate-400"
                                onClick={()=>{
                                    handleMinutesDecrement()
                                }}
                            ><em className="fa-solid fa-sort-down text-slate-500"></em></div>
                        </div>
                    </div>
                    { timeFormat === 12 &&
                    <>
                        <div className="ml-2">
                            <div className="flex">
                                <div contentEditable={false} className="min-w-[10px] group-hover:w-[30px] group-hover:bg-white">{selectedPart}</div>
                                <div className="hidden group-hover:flex flex-col group-hover:bg-white">
                                    <div className="flex items-center justify-center align-middle h-[12px] w-[12px] pt-2 px-2 cursor-pointer hover:bg-gray-100 group-hover:border border-slate-400"
                                        onClick={()=>{
                                            handleTimeFormatChange()
                                        }}
                                    ><em className="fa-solid fa-sort-up text-slate-500"></em></div>
                                    <div className="flex items-center justify-center align-middle h-[12px] w-[12px] pb-2 px-2 cursor-pointer hover:bg-gray-100 group-hover:border border-slate-400"
                                        onClick={()=>{
                                            handleTimeFormatChange()
                                        }}
                                    ><em className="fa-solid fa-sort-down text-slate-500"></em></div>
                                </div>
                            </div>
                        </div>
                    </>}
                    { timeFormat === 24 && <div className="ml-2">Hrs</div> }
                    
                </div>
                <em className="fa-solid fa-calendar-days ml-2 text-sm"></em>
            </div>
        </>
    )
}

export default TimePicker;