/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useRef, FC } from "react"
import { v4 as uuidv4 } from 'uuid'
import { SideDrawerContext, ThemeContext } from "../../contexts";
// import Tile5x4_ChartText from "../../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText";
import { Breadcrumb, Tile } from "../../components/core";
import { Table } from "../../components/core/Table";
import { ITableColumnItem, ITableControllerProps } from "../../components/core/Table/Table";
// import { MasterChart } from "../../components/Charts/ChartJS";
import { useNavigate } from "react-router-dom";
import { getAPICall } from "../../services/APILayer/axiosMethodCalls";
import { originalStrategy, deviceSymbol } from '../../utils/agentUtils';
import { StrategyInUsedCard, StrategyNotInUsedCard, TotalStrategiesCard } from "./EndpointStrategyComponents";
import { formatSeconds } from "../../utils/format";
import Tooltip from "../../components/core/Tooltip/Tooltip";
import { SortWithAlert, SortWithID, SortWithRPO, SortWithRetention, SortWithStrategyName } from "../../utils/Sorting/endpointStrategy";

const EndpointStrategy = ( ) =>{

    // const [backupTrend,setBackupTrend]=useState(false)
    const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    const sideDrawerContext = useContext(SideDrawerContext)
    const navRaf = useRef<HTMLDivElement>(null)

    const [rawPolicyStrategy, setRawPolicyStrategy] = useState<any>({});
    const [selectedStrategyType, setSelectedStrategyType] = useState<string>('all')
    const [policyStrategy, setPolicyStrategy] = useState<any>({});
    const [strategies, setStrategies] = useState<any[]>([])
    const [selectedStrategy, setSelectedStrategy] = useState<string>('');

    const [strategyId, setStrategyId] = useState<string>('')
    const [allEndpoints, setAllEndpoints] = useState<any[]>([]);
    const [changeUUID, setChangeUUID] = useState<string>('');
    // const [selectedEndpoints, setSelectedEndpoints] = useState<any[]>([]);

    const getEndPoints = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        const fetchEndPoints = async () => {
            getEndPoints().then((data)=>{
                setAllEndpoints(data.endpoints)
            }).catch((error)=>{ console.log(error) }).finally()
        }
        fetchEndPoints()
    },[])

    useEffect(()=>{
        if( allEndpoints.length > 1 ) {
            console.log({allEndpoints})

            let filteredEndPoints: any[] = (allEndpoints || [])
                                    .filter((ep:any)=>{ return ep?.agent_profile?.AdminInfos?.Deletion !== true })
                                    .filter((ep:any)=>{  return (ep?.agent_profile?.AdminInfos?.ServiceProfile?.ID || '').toString() === strategyId });
            
            // setSelectedEndpoints(filteredEndPoints)
            sideDrawerContext.setFirstDrawerChild(<FirstDrawerChildComponent selectedEndpoints={filteredEndPoints} selectedStrategy={selectedStrategy} />)
            sideDrawerContext.setLevelOneDrawerVisibility(true)
            console.log({filteredEndPoints})
        }
    },[strategyId, changeUUID])

    const getPolicyStrategy = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints/config`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        const fetchPolicyStrategy = async () => {
            await getPolicyStrategy().then((data)=>{
                console.log({_data:data})
                setRawPolicyStrategy(data)
            }).catch((error)=>{ console.log({error}) }).finally();
        }
        fetchPolicyStrategy()
    },[]);

    useEffect(()=>{
        if( Object.keys(rawPolicyStrategy).length > 1 ) {
            let arrStrategyObj: any[] = [];
            let arrStrategy: string[] = [];

            (rawPolicyStrategy?.config?.current_strategies || []).forEach((strategy:any) => {
                if( strategy.ID > 100 ) {
                    let strategyName = {...originalStrategy.find((s)=>{ if( s.ID === strategy.ID ) return s })};
                    arrStrategy.push(`(${strategy.ID}) ${strategyName?.Name || strategy?.Name}`)
                    arrStrategyObj.push({
                        id: strategy.ID,
                        value: strategy.Name,
                        name: strategyName?.Name || strategy?.Name
                    })
                }
            });
            setPolicyStrategy({
                strategy: arrStrategy,
                strategyObj: arrStrategyObj
            });
        }

    },[rawPolicyStrategy])

    useEffect(()=>{
        // console.log({rawPolicyStrategy},{policyStrategy})
        let requiredStrategies = (rawPolicyStrategy?.config?.current_strategies || []).filter((s:any)=>{ return s.ID > 99 })
        setStrategies(requiredStrategies)
        setTableController((prevState:any)=>{
            let newState = {...prevState}
            newState.data = requiredStrategies;
            return newState;
        })
        console.log({strategies})
        // getEndpointConfig(rawPolicyStrategy.config, addDeviceFormData.policy.value, addDeviceFormData.strategy.value);
    },[policyStrategy])

    const [tableController, setTableController] = useState<ITableControllerProps>({
        data: [],
        loader: false,
        currentPage: 1,
        offset: 0,
        limit: 5,
        totalRecords: 10,
        maxVisiblePaginationButtons: 5,
        searchTerm: '',
        filter: {},
        sorting: {
            column: '',
            direction: null
        }
    })

    const [tableColumns, setTableColumns] = useState<ITableColumnItem[]>([
        {
            id: 1,
            name: 'ID',
            column: 'ID',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true,
            className: 'min-w-[40px] w-[60px] pl-4 ',
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }

        },
        {
            id: 2,
            name: 'Strategy Name',
            column: 'Name',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true,
            className: 'min-w-[190px] w-[190px] mr-2',
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }

        },
        {
            id: 3,
            name: 'Retention',
            column: 'ParamDataAging',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true,
            className: 'min-w-[120px]',
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }

        },
        {
            id: 4,
            name: 'RPO',
            column: 'ParamSchedule',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true,
            className: 'min-w-[120px]',
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }

        },
        {
            id: 10,
            name: 'Alerts',
            column: 'ParamAlertTime',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true,
            className: 'min-w-[120px]',
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 9,
            name: 'Endpoint Device',
            column: '',
            textAlign: 'text-left',
            textColor: 'text-[#FF5E0E]',
            visibility: true,
            className: 'min-w-[120px] font-medium',
            sorting: {
                enable: false,
                direction: 'NEUTRAL'
            }
        },
    ]);

    const actionButtonGenerator = (row: object) => {
        return <>
            <button aria-label='edit'
                onClick={()=>{

                }}
            ><em className='fa-regular fa-pen opacity-80 mr-2'></em></button>
            <button aria-label='view'
                onClick={()=>{
                    
                }}
            ><em className='fa-regular fa-eye opacity-80'></em></button>
        </>
    }

    const tableDataFormatter = (rowObj: any, format?: string | undefined, rowIndex?: number) => {
        let returningVal = null;
        let objColumn = tableColumns.find((c:any)=>{ return c.name === format });
        let cellWidth = objColumn ? objColumn.className : ''
        switch (format) {
            case 'ID':
                returningVal = (
                    <div className={`${cellWidth} -ml-3`}>{rowObj || ""}</div>
                );
                break;

            case 'Strategy Name':
                returningVal = (
                    <div className="relative group">
                    <div className={`${cellWidth} truncate`}>{rowObj}</div>
                    { rowIndex === 0 ? (
                        <Tooltip direction="down" value={`${rowObj || ""}`} />
                        ) : (
                        <Tooltip value={`${rowObj || ""}`} />
                    ) }
                    </div>
                );
                break;
            
            case 'Retention':
                returningVal=(
                        <div className={`${cellWidth}`}>{rowObj === 0 ? 'Unlimited' : `${rowObj} Days`}</div>
                );
                break;

            case 'RPO':
                returningVal=(
                    <div className={`${cellWidth}`}>{formatSeconds(rowObj).value} {formatSeconds(rowObj).unit}</div>
                );
                break;

            // case 'Compression Type':
            //     returningVal=(
            //         <div className={`${cellWidth}`}>{rowObj}</div>
            //     );
            //     break;

            // case 'Network Throughput':
            //     returningVal=(
            //         <div className={`${cellWidth}`}>{rowObj}</div>
            //     );
            //     break;

            // case 'Quota':
            //     returningVal=(
            //             <div className={`${cellWidth}`}>{rowObj}</div>
            //     );
            //     break;

            case 'Endpoint Device':
                returningVal=(
                        <div 
                            className={`${cellWidth} cursor-pointer`}
                            onClick={()=>{
                                setSelectedStrategy(rowObj.Name)
                                setStrategyId(rowObj.ID.toString())
                                let uid = uuidv4()
                                console.log({uid})
                                setChangeUUID(uid)
                            }}
                        >{allEndpoints
                            .filter((ep:any)=>{ return ep?.agent_profile?.AdminInfos?.Deletion === false })
                            .filter((ep:any)=>{ return ep?.agent_profile?.AdminInfos?.ServiceProfile?.ID === rowObj?.ID }).length} device(s)</div>
                );
                break;
            
            case 'Alerts':
                returningVal=(
                    <div className="">{formatSeconds(rowObj).value} {formatSeconds(rowObj).unit}</div>
                );
                break;

            default:
                returningVal = <div className={`${cellWidth}`}>{rowObj}</div>;
                break;
        }
        return returningVal;
    }
    
    const handleDataSorting = (column:any) => {

        let columnName = column?.name;
        let tableData = [...tableController?.data];
        let returningValue: {[key: string]: any}[] = []

        switch (columnName) {
            case 'ID':
                let sortedById = SortWithID( column?.sorting?.direction, tableData )
                if( sortedById )
                    returningValue = sortedById

                break;

            case 'Strategy Name':
                let sortedByStrategyName = SortWithStrategyName( column?.sorting?.direction, tableData )
                if( sortedByStrategyName )
                    returningValue = sortedByStrategyName

                break;
                
            case 'Retention':
                let sortedRetention = SortWithRetention( column?.sorting?.direction, tableData )
                if( sortedRetention )
                    returningValue = sortedRetention

                break;
            
            case 'RPO':
                let sortedRPO = SortWithRPO( column?.sorting?.direction, tableData )
                if( sortedRPO )
                    returningValue = sortedRPO

                break;
        
            case 'Alerts':
                let sortedAlertResult = SortWithAlert( column?.sorting?.direction, tableData )
                if( sortedAlertResult )
                    returningValue = sortedAlertResult

                break;
        
            default:
                returningValue = tableData
                break;
        }

        console.log({columnName, tableData, column, returningValue})

        setTableController((previousState:ITableControllerProps)=>{
            let newState = {...previousState}
            newState.data = returningValue
            return newState
        })

    }
    
    return(
        <>
            <div className={`p-6 grow flex flex-col`}>
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Backup Strategies</h1>
                <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-bell-on', link: '', name: 'Home' },
                    { icon: '', link: '', name: 'Managed Services' },
                    { icon: '', link: '/endpoint', name: 'Endpoint Backup' },
                    { icon: '', link: '', name: 'Backup Strategies' }
                ]} />
                <div className={`grow flex flex-col`}>
                    <div className={`grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full min-h-max mb-6 text-${theme.setThemeColor}-${theme.themeContrast}-component-text`}>
                        <div 
                            className="cursor-pointer"
                            onClick={()=>{
                                setSelectedStrategyType('all')
                                setTableController((prevState)=>{
                                    let newState = {...prevState}
                                    let allStrategies = rawPolicyStrategy.config.current_strategies.filter((s:any)=>{ return s.ID > 99 });
                                    newState.data = allStrategies.length ? allStrategies : []
                                    return newState;
                                })
                            }}
                        >
                            <TotalStrategiesCard strategies={strategies} />
                        </div>
                        <div
                            className="cursor-pointer"
                            onClick={()=>{
                                setSelectedStrategyType('active')
                                setTableController((prevState)=>{
                                    let newState = {...prevState}
                                    let activeStrategies = rawPolicyStrategy.config.current_strategies.filter((s:any)=>{ return s.ID > 99 && s.NbRefs > 0 })
                                    newState.data = activeStrategies.length ? activeStrategies : []
                                    return newState;
                                })
                            }}
                        >
                            <StrategyInUsedCard strategies={strategies} />
                        </div>
                        <div
                            className="cursor-pointer"
                            onClick={()=>{
                                setSelectedStrategyType('inactive')
                                setTableController((prevState)=>{
                                    let newState = {...prevState}
                                    let inactiveStrategies = rawPolicyStrategy.config.current_strategies.filter((s:any)=>{ return s.ID > 99 && s.NbRefs < 1 });
                                    console.log({inactiveStrategies})
                                    newState.data = inactiveStrategies.length ? inactiveStrategies : []
                                    return newState;
                                })
                            }}
                        >
                            <StrategyNotInUsedCard strategies={strategies} />
                        </div>
                    </div>
                    <div className={`grid grid-flow-row-dense grid-cols-1 md:grid-cols-3 gap-6 w-full min-h-max mb-6 text-${theme.setThemeColor}-${theme.themeContrast}-component-text`}>
                        <div className="grow w-full col-span-3 relative">
                            <Tile colSpan={12} rowSpan={25} className="pb-4 overflow-hidden ">
                                <div className="p-4 flex flex-col text-gray-800 w-full ">
                                    <div className="whitespace-nowrap">End-point Strategy</div>
                                    <div className="text-sm text-gray-500 font-semibold">{selectedStrategyType[0].toUpperCase()}{selectedStrategyType.split('').splice(1,selectedStrategyType.split('').length)} strategies</div>
                                </div>
                            </Tile>
                            <div className="absolute left-0 right-0 top-20 bottom-0 h-auto w-auto">
                                <Table
                                    navRef={navRaf}
                                    // actionButtonGenerator={actionButtonGenerator}
                                    columns={tableColumns}
                                    setColumns={setTableColumns}
                                    formatData={tableDataFormatter}
                                    showActionCell={false}
                                    showActionHeaderCell={false}
                                    showColumnManagementMenu={false}
                                    // switchHandler={handleSwitch}
                                    tableController={tableController}
                                    dataSortingHandler={handleDataSorting}
                                    tableId="Strategy Table"
                                />
                            </div>
                        </div>                            
                    </div>
                </div> 
            </div>
        </>
    )
}

export default EndpointStrategy


interface IFirstDrawerChildComponent {
    selectedEndpoints: any[]
    selectedStrategy: string
}

const FirstDrawerChildComponent:FC<IFirstDrawerChildComponent> = ({selectedEndpoints, selectedStrategy}) => {
    const navigator = useNavigate()
    const sideDrawerContext = useContext(SideDrawerContext)
    const [hovering, setHovering] = useState(false);
    
    return (
        <>
            {/* {JSON.stringify(selectedEndpoints)} */}
            <div className="h-full flex flex-col">
                <div className="title w-full flex gap-4 px-6 top-0 pt-10 border-b pb-10 bg-slate-100 items-center">
                    {/* Icon */}
                    <i className="fa-regular fa-tv text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i>

                    {/* title */}
                    <div className="flex flex-col">
                        <div className="text-xl font-bold text-slate-700">Endpoint Devices</div>
                        {/* Sub title */}
                        <div className="block text-slate-500">
                            <div className="w-80 whitespace-nowrap overflow-hidden"
                                onMouseOver={()=>{ setHovering(true); }}
                                onMouseOut={()=>{ setHovering(false); }}
                            ><div className={`${hovering ? 'animate-marquee' : ''}`}>{selectedStrategy || 'NA'}</div></div>
                                {/* <div className={`${hovering ? 'animate-marquee' : ''}`}>{selectedStrategy}</div> */}
                                                                  
                                {/* <em className="fa-solid fa-link ml-2 text-sm"></em> */}
                        </div>
                    </div>
                </div>
                <div className="data grow top-40 px-4 w-full py-6">
                    {/* <Accordion className="rounded-lg overflow-hidden"> */}
                        {/* <AccordionItem className="text-sm" maxHeight='h-fit' title={<span><em className="fa-regular fa-list-check mr-4 text-[#f97315]"></em> All ({(selectedEndpoints || []).length})</span>}> */}
                            {(selectedEndpoints || []).length > 0 ?
                            (<div className={`table w-full`}>
                                <div className={`thead bg-slate-200 w-full py-2 px-2 mt-10 flex flex-row items-center text-orange-500 rounded-t-md`}>
                                    <div className="row text-gray-800 text-sm flex">
                                        <div className="cell text-left w-16 pl-4">#</div>
                                        <div className="cell text-left w-16 -ml-2">OS</div>
                                        <div className="cell text-left grow">Device Name</div>
                                    </div>
                                </div>
                                <div className={`tbody overflow-y-scroll customScroll h-[60vh] bg-white w-full flex flex-col items-center text-slate-600 rounded-b-md border border-t-0`}>
                                    {(selectedEndpoints || []).length > 0 && (selectedEndpoints || []).map((endpoint:any, endpointIndex:number)=>(
                                        <div key={endpointIndex} className="row text-sm font-sm flex border-b w-full py-2 hover:bg-slate-100">
                                            <div
                                                className="cell w-16 pl-4 cursor-pointer text-orange-500"
                                                onClick={()=>{
                                                    navigator(`/endpoint/devices/profile/${endpoint?._id || ''}`)
                                                    sideDrawerContext.setLevelOneDrawerVisibility(false)
                                                }}
                                            >{endpoint?.agent_id || ''}</div>
                                            <div className="cell text-slate-500 w-16">{deviceSymbol(endpoint?.agent_profile?.AgentInfos?.System || '')}</div>
                                            <div className="cell grow">{endpoint?.agent_profile?.AdminInfos?.Name || ''}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            ):
                            (
                                <div className="py-6 flex flex-col justify-center items-center text-center align-middle">
                                    <img src="/assets/img/well_done.svg" alt="placeholder" className="h-52 mt-6"/>
                                    <div className="text-sm font-medium text-slate-500 mt-10 relative">
                                        <span className="">No Data Found..</span>
                                    </div>
                                </div>
                    )
                            }
                        {/* </AccordionItem> */}
                    {/* </Accordion> */}
                </div>
            </div>
        </>
    )
}