import React, { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import MasterChart, { IChartDatasetData } from "../../../Charts/ChartJS/CJMasterChart/MasterChart";
import { Tile } from "../../../core";

interface IPrios {
    /**
     * {Required} Title of tile
     */
    title?: ReactNode | ReactNode[] | string;
    /**
     * {Required} description or secondary data title of tile
     */
    description?: ReactNode | ReactNode[] | string;
    /**
     * {Required} Value of tile
     */
    value?: ReactNode | ReactNode[] | string;
    /**
     * (Optional) represents trend value up or down
     */
    trend?: "up" | "down";
    /**
     * {Required if trend value up or down} Value of trend (in percentage but not required)
     */
    trendValue?: string;
    /**
     * {Required} if tile contains text or chart on right side of tile?
     */
    designOption?: "chart" | "text";


    /**
     * {Required if designOption is chart} represents chart type
     */
    chartType?: "bar" | "line" | "pie" | "donut";
    /**
     * {Required if designOption is chart} chart labels
     */
    chartLabels?: string[];
    /**
     * {Required if designOption is chart} chart dataset.
     */
    chartDataset?: IChartDatasetData[] | any;
    /**
     * {Required if designOption is chart} chart design object.
     */
    chartOptions?: object; 
    /**
     * {Optional, works only if designOption is chart} Main text in between chart.
     * Note: this looks good only in donut chart
     */
    inChartMain?: string;
    /**
     * {Optional, works only if designOption is chart} Secondary text in between chart
     * Note: this looks good only in donut chart
     */
    inChartSub?: string;

    sideBar?: boolean;

    clickNavigate?: boolean;
    navigationPath?: string;

    tileAction?: boolean;
    actionHandler?: ()=>void;

    /**
     * {Required only if designOption is text} represents big value in right side
     */
    textValue?: ReactNode | ReactNode[] | string;
    /**
     * {Optional, works only if design option is text} over rights default text color other than default tileMode type 
     */
    textColor?: string;
    /**
     * {Optional, works only if design option is text} over rights default background color other than default tileMode type 
     */
    textBackground?: string;
    
    /**
     * {Optional} Represents tile alert modes 
     */
    tileMode?: "default" | "success" | "warning" | "info" | "danger" | "error";
    /**
     * {Optional} if tile is updated and require attention? if its true, will show a pulsating dot
     */
    alert?: boolean;
    /**
     * {optional} if tile is live? if its true
     */
    live?: boolean;

    /**
     * additional className values
     */
    className?: string;

    children?: ReactNode | ReactNode[] | string;

}

const Tile5x4_ChartText:FC<IPrios> = ({
    title='',
    designOption='text',
    description='',
    value='',
    trend,
    trendValue='',
    inChartMain='',
    inChartSub='',
    textValue='',
    textColor='',
    textBackground='',
    chartType='bar',
    clickNavigate=false,
    tileAction=false,
    actionHandler=()=>{},
    navigationPath='',
    chartLabels=[],
    chartDataset={},
    chartOptions={},
    tileMode='default',
    alert=false,
    live=false,
    className='',
    children=""
}: IPrios) => {

    const navigator = useNavigate();

    // const [levelOneDrawerVisibility, setLevelOneDrawerVisibility] = useState(false);
    // const [levelTwoDrawerVisibility, setLevelTwoDrawerVisibility] = useState(false);
    // const [openLevel1,setOpenLevel1] = useState (false);
    // const [openLevel2,setOpenLevel2] = useState (false);
    // const [openLevel3,setOpenLevel3] = useState (false);
    // const [openLevel4,setOpenLevel4] = useState (false);

    let modeBorderStyle  = '';
    let modeBackgroundStyle  = '';
    let modeTextStyle  = '';

    switch (tileMode) {
        case 'default':
            modeBorderStyle = '';
            modeBackgroundStyle = textBackground;
            modeTextStyle = textColor;
            break;

        case 'success':
            modeBorderStyle = 'border-green-500';
            modeBackgroundStyle = 'bg-green-100';
            modeTextStyle = 'text-green-500';
            break;

        case 'warning':
            modeBorderStyle = 'border-yellow-500';
            modeBackgroundStyle = 'bg-yellow-100';
            modeTextStyle = 'text-yellow-500';
            break;

        case 'info':
            modeBorderStyle = 'border-blue-500';
            modeBackgroundStyle = 'bg-blue-100';
            modeTextStyle = 'text-blue-500';
            break;

        case 'danger':
        case 'error':
            modeBorderStyle = 'border-red-500';
            modeBackgroundStyle = 'bg-red-100';
            modeTextStyle = 'text-red-500';
            break;
    
        default:
            break;
    }

    // const  openHandler1 = ()=>{
    //     setOpenLevel1(!openLevel1)
    // }
    // const  openHandler2 = ()=>{
    //     setOpenLevel2(!openLevel2)
    // }
    // const  openHandler3 = ()=>{
    //     setOpenLevel3(!openLevel3)
    // }
    // const  openHandler4 = ()=>{
    //     setOpenLevel4(!openLevel4)
    // }

    return (
        <>
            <Tile rowSpan={5} colSpan={4} className={`relative p-5 border ${modeBorderStyle} ${ (clickNavigate === true || tileAction === true) ? 'cursor-pointer' : '' } ${className}`}
                onClick={()=>{

                    if(clickNavigate)
                        navigator(navigationPath);

                    if(tileAction)
                        actionHandler();
                }}
            >
                <div className="flex h-full items-center text-[#3A3541]">
                    <div className="grow h-full">
                        <div className={`text-xl leading-8 font-medium text-[#3A3541] mb-5 whitespace-nowrap`}>
                            {title}
                            {alert && <em className="fa-solid fa-circle text-xs text-red-500 ml-2 animate-ping"></em>}
                        </div>
                        <div className={`text-xs leading-5 font-normal text-gray-400 tracking-widest`}>{description}</div>
                        <div className={`flex flex-row text-[#3A3541] items-center`}>
                            <div className="text-xl leading-8 font-medium pr-2">{value}</div>
                            { trend === 'up' && (<div className={`flex flex-row text-xs text-green-500 leading-5 items-center`}>
                                <i className="fa-solid fa-angle-up text-center items-center flex justify-center w-6 h-6"></i>
                                <div className="">{trendValue}</div>
                            </div>)}
                            { trend === 'down' && (<div className="flex flex-row text-xs text-red-500 leading-5 items-center">
                                <i className="fa-solid fa-angle-down text-center items-center flex justify-center w-6 h-6"></i>
                                <div className="">{trendValue}</div>
                            </div>)}
                        </div>
                    </div>
                    {designOption === 'text' && <div className={`h-[100px] w-[100px] ${modeBackgroundStyle} rounded-lg flex items-center justify-center align-middle`}>
                        <div className={`text-4xl ${modeTextStyle}`}>{textValue}</div>
                    </div>}
                    {designOption === 'chart' && <div className="relative w-[114px] h-[114px] flex justify-center items-center">
                            {chartType && <MasterChart
                                type={chartType}
                                labels={chartLabels}
                                datasets={chartDataset}
                                options={chartOptions}
                            />}
                            <div className="absolute text-xl font-medium top-[32px] w-full text-center">{inChartMain}</div>
                            <div className="absolute text-xs font-normal top-[60px] w-full text-center">{inChartSub}</div>
                    </div>}
                    {children}
                    {live && <div className="inline absolute top-2 right-3 text-xs">
                        <em className="fa-solid fa-circle text-red-500 blink"></em>
                    </div>}
                </div>
            </Tile>
            
            {/* <SideDrawer
                    levelOneDrawerVisibility={levelOneDrawerVisibility}
                    setLevelOneDrawerVisibility={setLevelOneDrawerVisibility}
                    levelTwoDrawerVisibility={levelTwoDrawerVisibility}
                    setLevelTwoDrawerVisibility={setLevelTwoDrawerVisibility}
                >
                <LevelOneDrawer
                    levelOneDrawerVisibility={levelOneDrawerVisibility}
                    setLevelOneDrawerVisibility={setLevelOneDrawerVisibility}
                    levelTwoDrawerVisibility={levelTwoDrawerVisibility}
                    setLevelTwoDrawerVisibility={setLevelTwoDrawerVisibility}
                > */}

{/* ///////Notification Status right side drawer /////// */}

            {/* <div className="py-2 px-5">
                        <div className="text-gray-800 text-xl font-semibold flex items-center mt-5">Notifications<span ><i className="fa-sharp fa-solid fa-receipt text-sm ml-2 relative bg-slate-700 text-white   w-7 h-7 pt-1 justify-center items-center text-center rounded-full"><span className="text-sm  bg-orange-500 border-2 border-white  text-white rounded-full flex justify-center text-center align-middle items-center w-6 h-6 absolute  -top-[14px] -right-[14px]">16</span></i></span></div>
                            <div className={`flex flex-row items-center hover:bg-slate-200 cursor-pointer text-orange-500 rounded-t-md ${openLevel1===true?"border":"border-t border-l border-r"} bg-slate-100 w-full p-2 px-4 mt-10`} onClick={openHandler1}>
                                <i className="fa-solid fa-tv mr-4"></i>
                                <span className="text-gray-800 text-sm">Endopoints ( 7 )</span>
                                <div className="flex grow"></div>
                                <i className={`fa-solid fa-angle-down ml-4 ${openLevel1===true?"rotate-180":"rotate-0"} transition-all duration-500s ease-in-outs`}></i>
                            </div>
                            <div className={`${openLevel1===true?"h-[220px]":"h-[0px]"} border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-dot text-blue-500 mr-2"/>Swap/page file(s) are nearly full</div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-exclamation text-gray-700 mr-2"/>Database and block files repositories should be stored on separated volumes for optional performance</div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-dot text-red-500 mr-2"/>Running on a Virtualized server (HyperV)</div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-exclamation text-gray-700 mr-2"/>Database and block files repositories should be stored on separated volumes for optional performance</div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-exclamation text-gray-700 mr-2"/>Database and block files repositories should be stored on separated volumes for optional performance</div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-exclamation text-gray-700 mr-2"/>Database and block files repositories should be stored on separated volumes for optional performance</div>
                            </div>
                        
                            <div className={`flex flex-row text-orange-500 items-center hover:bg-slate-200 cursor-pointer border bg-slate-100 w-full p-2 px-4`} onClick={openHandler2}>
                            <img src="assets/img/office-365.svg" className="w-5 h-5 items-center mr-4"/><span className="text-gray-800 text-sm">O365 ( 1 )</span>
                                <div className="flex grow"></div>
                                <i className={`fa-solid fa-angle-down ml-4 ${openLevel2===true?"rotate-180":"rotate-0"}`}/>
                            </div>
                            <div className={`${openLevel2===true?"h-[220px]":"h-[0px]"} border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-dot text-blue-500 mr-2"/>Swap/page file(s) are nearly full</div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-exclamation text-gray-700 mr-2"/>Database and block files repositories should be stored on separated volumes for optional performance</div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-dot text-red-500 mr-2"/>Running on a Virtualized server (HyperV)</div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-exclamation text-gray-700 mr-2"/>Database and block files repositories should be stored on separated volumes for optional performance</div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-exclamation text-gray-700 mr-2"/>Database and block files repositories should be stored on separated volumes for optional performance</div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-exclamation text-gray-700 mr-2"/>Database and block files repositories should be stored on separated volumes for optional performance</div>
                            </div>
                        
                            <div className={`flex flex-row text-orange-500 items-center hover:bg-slate-200 cursor-pointer border bg-slate-100 w-full p-2 px-4`} onClick={openHandler3}>
                                <i className="fa-solid fa-server mr-4"></i>
                                <span className="text-gray-800 text-sm">Server ( 5 )</span>
                                <div className="flex grow"></div>
                                <i className={`fa-solid fa-angle-down ml-4 ${openLevel3===true?"rotate-180":"rotate-0"}`}/>
                            </div>
                            <div className={`${openLevel3===true?"h-[220px]":"h-[0px]"} border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-dot text-blue-500 mr-2"/>Swap/page file(s) are nearly full</div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-exclamation text-gray-700 mr-2"/>Database and block files repositories should be stored on separated volumes for optional performance</div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-dot text-red-500 mr-2"/>Running on a Virtualized server (HyperV)</div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-exclamation text-gray-700 mr-2"/>Database and block files repositories should be stored on separated volumes for optional performance</div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-exclamation text-gray-700 mr-2"/>Database and block files repositories should be stored on separated volumes for optional performance</div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-exclamation text-gray-700 mr-2"/>Database and block files repositories should be stored on separated volumes for optional performance</div>
                            </div>

                            <div className={`flex flex-row text-orange-500 items-center hover:bg-slate-200 cursor-pointer ${openLevel4===true?"rounded-none":"rounded-b-md"} border bg-slate-100 w-full p-2 px-4`} onClick={openHandler4}>
                                <i className="fa-solid fa-arrow-right-arrow-left mr-4"></i>
                                <span className="text-gray-800 text-sm">Migration ( 3 )</span>
                                <div className="flex grow"></div>
                                <i className={`fa-solid fa-angle-down ml-4 ${openLevel4===true?"rotate-180":"rotate-0"}`}/>
                            </div>
                            <div className={`${openLevel4===true?"h-[220px] border rounded-b-md":"h-[0px]"} border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-dot text-blue-500 mr-2"/>Swap/page file(s) are nearly full</div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-exclamation text-gray-700 mr-2"/>Database and block files repositories should be stored on separated volumes for optional performance</div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-dot text-red-500 mr-2"/>Running on a Virtualized server (HyperV)</div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-exclamation text-gray-700 mr-2"/>Database and block files repositories should be stored on separated volumes for optional performance</div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-exclamation text-gray-700 mr-2"/>Database and block files repositories should be stored on separated volumes for optional performance</div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex items-center text-gray-700 font-light"><i className="fa-regular fa-circle-exclamation text-gray-700 mr-2"/>Database and block files repositories should be stored on separated volumes for optional performance</div>
                            </div>
            </div>*/}

{/* ///////Tickets Status right side drawer /////// */}

                    {/* <div className="py-2 px-5">
                        <div className="text-gray-800 text-xl font-semibold flex items-center mt-5">Ticket Status <span ><i className="fa-sharp fa-solid fa-receipt text-sm ml-2 relative bg-slate-700 text-white w-7 h-7 pt-1 justify-center items-center text-center rounded-full"><span className="text-sm bg-orange-500 border-2 border-white text-white rounded-full flex justify-center text-center align-middle items-center w-6 h-6 absolute -top-[14px] -right-[14px]">90</span></i></span></div>
                            <div className={`flex flex-row items-center hover:bg-slate-200 cursor-pointer text-orange-500 rounded-t-md ${openLevel1===true?"border":"border-t border-l border-r"} bg-slate-100 w-full p-2 px-4 mt-10`} onClick={openHandler1}>
                                <i className="fa-solid fa-check mr-4"></i>
                                <span className="text-gray-800 text-sm">Closed ( 10 )</span>
                                <div className="flex grow"></div>
                                <i className={`fa-solid fa-angle-down ml-4 ${openLevel1===true?"rotate-180":"rotate-0"} transition-all duration-500s ease-in-outs`}></i>
                            </div>
                            <div className={`${openLevel1===true?"h-[230px]":"h-[0px]"} border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="text-gray-700 font-medium"><span className="text-orange-600">Disconnection during backup</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Ticket Id :</span>
                                        <span className="text-[#ff5e0e]">123ABC-12</span>
                                    </div>
                                    <div className="flex flex-row justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Create date :</span>
                                        <span className="text-[#ff5e0e] font-light">12-12-2021</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Closed date :</span>
                                        <span className="text-[#ff5e0e] font-light">12-12-2021</span>
                                    </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="text-gray-700 font-medium"><span className="text-orange-600">Data Migration error</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Ticket Id :</span>
                                        <span className="text-[#ff5e0e]">123MIG-44</span>
                                    </div>
                                    <div className="flex flex-row justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Create date :</span>
                                        <span className="text-[#ff5e0e] font-light">10-12-2021</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Closed date :</span>
                                        <span className="text-[#ff5e0e] font-light">13-12-2021</span>
                                    </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="text-gray-700 font-medium"><span className="text-orange-600">Data not restored properly</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Ticket Id :</span>
                                        <span className="text-[#ff5e0e]">143AC-12</span>
                                    </div>
                                    <div className="flex flex-row justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Create date :</span>
                                        <span className="text-[#ff5e0e] font-light">22-12-2021</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Closed date :</span>
                                        <span className="text-[#ff5e0e] font-light">23-12-2021</span>
                                    </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="text-gray-700 font-medium"><span className="text-orange-600">Disconnection during backup</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Ticket Id :</span>
                                        <span className="text-[#ff5e0e]">123ABC-12</span>
                                    </div>
                                    <div className="flex flex-row justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Create date :</span>
                                        <span className="text-[#ff5e0e] font-light">12-12-2021</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Closed date :</span>
                                        <span className="text-[#ff5e0e] font-light">12-12-2021</span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div className={`flex flex-row text-orange-500 items-center hover:bg-slate-200 cursor-pointer border bg-slate-100 w-full p-2 px-4`} onClick={openHandler2}>
                                <i className="fa-solid fa-spinner mr-4"></i>
                                <span className="text-gray-800 text-sm">In-progress ( 40 )</span>
                                <div className="flex grow"></div>
                                <i className={`fa-solid fa-angle-down ml-4 ${openLevel2===true?"rotate-180":"rotate-0"} transition-all duration-500s ease-in-outs`}></i>
                            </div>
                            <div className={`${openLevel2===true?"h-[200px]":"h-[0px]"} border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                            <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="text-gray-700 font-medium"><span className="text-orange-600">Server Backup is Running</span></div>
                                    
                                    <div className="flex flex-row justify-between w-full mt-1 mb-2">
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Ticket Id :</span>
                                        <span className="text-[#ff5e0e]">123ABC-12</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Create date :</span>
                                        <span className="text-[#ff5e0e] font-light">12-12-2021</span>
                                    </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="text-gray-700 font-medium"><span className="text-orange-600">Server Backup is Running</span></div>
                                    <div className="flex flex-row justify-between w-full mt-1 mb-2">
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Ticket Id :</span>
                                        <span className="text-[#ff5e0e]">123ABC-12</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Create date :</span>
                                        <span className="text-[#ff5e0e] font-light">12-12-2021</span>
                                    </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="text-gray-700 font-medium"><span className="text-orange-600">Server Backup is Running</span></div>
                                    <div className="flex flex-row justify-between w-full mt-1 mb-2">
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Ticket Id :</span>
                                        <span className="text-[#ff5e0e]">123ABC-12</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Create date :</span>
                                        <span className="text-[#ff5e0e] font-light">12-12-2021</span>
                                    </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="text-gray-700 font-medium"><span className="text-orange-600">Disconnection during backup</span></div>
                                    <div className="flex flex-row justify-between w-full mt-1 mb-2">
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Ticket Id :</span>
                                        <span className="text-[#ff5e0e]">123ABC-12</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Create date :</span>
                                        <span className="text-[#ff5e0e] font-light">12-12-2021</span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div className={`flex flex-row text-orange-500 items-center hover:bg-slate-200 cursor-pointer ${openLevel3===true?"rounded-none":"rounded-b-md"} border bg-slate-100 w-full p-2 px-4`} onClick={openHandler3}>
                                <i className="fa-solid fa-list-check mr-4"></i>
                                <span className="text-gray-800 text-sm">Pending ( 50 )</span>
                                <div className="flex grow"></div>
                                <i className={`fa-solid fa-angle-down ml-4 ${openLevel3===true?"rotate-180":"rotate-0"} transition-all duration-500s ease-in-outs`}></i>
                            </div>
                            <div className={`${openLevel3===true?"h-[200px] border rounded-b-md":"h-[0px]"} border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                            <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="text-gray-700 font-medium"><span className="text-orange-600">Server Backup is Running</span></div>
                                    
                                    <div className="flex flex-row justify-between w-full mt-1 mb-2">
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Ticket Id :</span>
                                        <span className="text-[#ff5e0e]">123ABC-12</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Create date :</span>
                                        <span className="text-[#ff5e0e] font-light">12-12-2021</span>
                                    </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="text-gray-700 font-medium"><span className="text-orange-600">Server Backup is Running</span></div>
                                    <div className="flex flex-row justify-between w-full mt-1 mb-2">
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Ticket Id :</span>
                                        <span className="text-[#ff5e0e]">123ABC-12</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Create date :</span>
                                        <span className="text-[#ff5e0e] font-light">12-12-2021</span>
                                    </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="text-gray-700 font-medium"><span className="text-orange-600">Server Backup is Running</span></div>
                                    <div className="flex flex-row justify-between w-full mt-1 mb-2">
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Ticket Id :</span>
                                        <span className="text-[#ff5e0e]">123ABC-12</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Create date :</span>
                                        <span className="text-[#ff5e0e] font-light">12-12-2021</span>
                                    </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="text-gray-700 font-medium"><span className="text-orange-600">Disconnection during backup</span></div>
                                    <div className="flex flex-row justify-between w-full mt-1 mb-2">
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Ticket Id :</span>
                                        <span className="text-[#ff5e0e]">123ABC-12</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-24">Create date :</span>
                                        <span className="text-[#ff5e0e] font-light">12-12-2021</span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                    </div> */}


{/* ///////End-point Device Status right side drawer /////// */}
                    
                    {/* <div className="py-2 px-5">
                        <div className="text-gray-800 text-xl font-semibold flex items-center mt-5">End-points Device Status <span ><i className="fa-sharp fa-solid fa-download text-sm ml-2 relative bg-slate-700 text-white   w-7 h-7 pt-1 justify-center items-center text-center rounded-full"><span className="text-sm  bg-orange-500 border-2 border-white  text-white rounded-full flex justify-center text-center align-middle items-center w-6 h-6 absolute  -top-[14px] -right-[14px]">15</span></i></span></div>
                            <div className={`flex flex-row items-center hover:bg-slate-200 cursor-pointer text-orange-500 rounded-t-md ${openLevel1===true?"border":"border-t border-l border-r"} bg-slate-100 w-full p-2 px-4 mt-10`} onClick={openHandler1}>
                                <i className="fa-solid fa-shield-check mr-4 text-green-600"></i>
                                <span className="text-gray-800 text-sm">Protected ( 5 )</span>
                                <div className="flex grow"></div>
                                <i className={`fa-solid fa-angle-down ml-4 ${openLevel1===true?"rotate-180":"rotate-0"} transition-all duration-500s ease-in-outs`}></i>
                            </div>
                            <div className={`${openLevel1===true?"h-[255px]":"h-[0px]"} border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-green-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-green-600 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last connection :</span>
                                        <span className="text-green-600 font-light">12-12-2021 12:00 PM</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last synchronization :</span>
                                        <span className="text-green-600 font-light">15-12-2021 11:00 AM</span>
                                    </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-green-600">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-green-600 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last connection :</span>
                                        <span className="text-green-600 font-light">12-12-2021 12:00 PM</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last synchronization :</span>
                                        <span className="text-green-600 font-light">15-12-2021 11:00 AM</span>
                                    </div>
                                    </div>
                                </div><div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-green-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-green-600 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last connection :</span>
                                        <span className="text-green-600 font-light">12-12-2021 12:00 PM</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last synchronization :</span>
                                        <span className="text-green-600 font-light">15-12-2021 11:00 AM</span>
                                    </div>
                                    </div>
                                </div><div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-green-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-green-600 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last connection :</span>
                                        <span className="text-green-600 font-light">12-12-2021 12:00 PM</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last synchronization :</span>
                                        <span className="text-green-600 font-light">15-12-2021 11:00 AM</span>
                                    </div>
                                    </div>
                                </div><div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-green-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-green-600 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last connection :</span>
                                        <span className="text-green-600 font-light">12-12-2021 12:00 PM</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last synchronization :</span>
                                        <span className="text-green-600 font-light">15-12-2021 11:00 AM</span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div className={`flex flex-row text-orange-500 items-center hover:bg-slate-200 cursor-pointer border bg-slate-100 w-full p-2 px-4`} onClick={openHandler2}>
                                <i className="fa-regular fa-shield-xmark mr-4 text-red-500"></i>
                                <span className="text-gray-800 text-sm">Unprotected ( 7 )</span>
                                <div className="flex grow"></div>
                                <i className={`fa-solid fa-angle-down ml-4 ${openLevel2===true?"rotate-180":"rotate-0"} transition-all duration-500s ease-in-outs`}></i>
                            </div>
                            <div className={`${openLevel2===true?"h-[255px]":"h-[0px]"} border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-red-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-red-600 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last connection :</span>
                                        <span className="text-red-600 font-light">12-12-2021 12:00 PM</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last synchronization :</span>
                                        <span className="text-red-600 font-light">15-12-2021 11:00 AM</span>
                                    </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-red-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-red-600 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last connection :</span>
                                        <span className="text-red-600 font-light">12-12-2021 12:00 PM</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last synchronization :</span>
                                        <span className="text-red-600 font-light">15-12-2021 11:00 AM</span>
                                    </div>
                                    </div>
                                </div><div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-red-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-red-600 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last connection :</span>
                                        <span className="text-red-600 font-light">12-12-2021 12:00 PM</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last synchronization :</span>
                                        <span className="text-red-600 font-light">15-12-2021 11:00 AM</span>
                                    </div>
                                    </div>
                                </div><div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-red-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-red-600 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last connection :</span>
                                        <span className="text-red-600 font-light">12-12-2021 12:00 PM</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last synchronization :</span>
                                        <span className="text-red-600 font-light">15-12-2021 11:00 AM</span>
                                    </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-red-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-red-600 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last connection :</span>
                                        <span className="text-red-600 font-light">12-12-2021 12:00 PM</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last synchronization :</span>
                                        <span className="text-red-600 font-light">15-12-2021 11:00 AM</span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div className={`flex flex-row text-orange-500 items-center hover:bg-slate-200 cursor-pointer ${openLevel3===true?"rounded-none":"rounded-b-md"} border bg-slate-100 w-full p-2 px-4`} onClick={openHandler3}>
                                <i className="fa-solid fa-bars-progress mr-4 text-yellow-400"></i>
                                <span className="text-gray-800 text-sm">Installation Pending ( 3 )</span>
                                <div className="flex grow"></div>
                                <i className={`fa-solid fa-angle-down ml-4 ${openLevel3===true?"rotate-180":"rotate-0"} transition-all duration-500s ease-in-outs`}></i>
                            </div>
                            <div className={`${openLevel3===true?"h-[150px] border rounded-b-md":"h-[0px]"} border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                            <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-yellow-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-yellow-500 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                       
                            </div>
                            <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-yellow-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-yellow-500 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                       
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-yellow-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-yellow-500 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                       
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-yellow-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-yellow-500 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                       
                                </div>
                            </div>
                    </div>*/}

                    
{/* ///////File server Status right side drawer /////// */}
                    {/* <div className="py-2 px-5">
                        <div className="text-gray-800 text-xl font-semibold flex items-center mt-5">File Server Status <span ><i className="fa-sharp fa-solid fa-server text-sm ml-2 relative bg-slate-700 text-white w-7 h-7 pt-1 justify-center items-center text-center rounded-full"><span className="text-sm bg-orange-500 border-2 border-white text-white rounded-full flex justify-center text-center align-middle items-center w-6 h-6 absolute -top-[14px] -right-[14px]">22</span></i></span></div>
                            <div className={`flex flex-row items-center hover:bg-slate-200 cursor-pointer text-orange-500 rounded-t-md ${openLevel1===true?"border":"border-t border-l border-r"} bg-slate-100 w-full p-2 px-4 mt-10`} onClick={openHandler1}>
                                <i className="fa-solid fa-shield-check mr-4 text-green-600"></i>
                                <span className="text-gray-800 text-sm">Protected ( 10 )</span>
                                <div className="flex grow"></div>
                                <i className={`fa-solid fa-angle-down ml-4 ${openLevel1===true?"rotate-180":"rotate-0"} transition-all duration-500s ease-in-outs`}></i>
                            </div>
                            <div className={`${openLevel1===true?"h-[255px]":"h-[0px]"} border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-green-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-green-600 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last connection :</span>
                                        <span className="text-green-600 font-light">12-12-2021 12:00 PM</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last synchronization :</span>
                                        <span className="text-green-600 font-light">15-12-2021 11:00 AM</span>
                                    </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-green-600">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-green-600 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last connection :</span>
                                        <span className="text-green-600 font-light">12-12-2021 12:00 PM</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last synchronization :</span>
                                        <span className="text-green-600 font-light">15-12-2021 11:00 AM</span>
                                    </div>
                                    </div>
                                </div><div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-green-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-green-600 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last connection :</span>
                                        <span className="text-green-600 font-light">12-12-2021 12:00 PM</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last synchronization :</span>
                                        <span className="text-green-600 font-light">15-12-2021 11:00 AM</span>
                                    </div>
                                    </div>
                                </div><div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-green-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-green-600 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last connection :</span>
                                        <span className="text-green-600 font-light">12-12-2021 12:00 PM</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last synchronization :</span>
                                        <span className="text-green-600 font-light">15-12-2021 11:00 AM</span>
                                    </div>
                                    </div>
                                </div><div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-green-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-green-600 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last connection :</span>
                                        <span className="text-green-600 font-light">12-12-2021 12:00 PM</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last synchronization :</span>
                                        <span className="text-green-600 font-light">15-12-2021 11:00 AM</span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div className={`flex flex-row text-orange-500 items-center hover:bg-slate-200 cursor-pointer border bg-slate-100 w-full p-2 px-4`} onClick={openHandler2}>
                                <i className="fa-regular fa-shield-xmark mr-4 text-red-500"></i>
                                <span className="text-gray-800 text-sm">Unprotected ( 9 )</span>
                                <div className="flex grow"></div>
                                <i className={`fa-solid fa-angle-down ml-4 ${openLevel2===true?"rotate-180":"rotate-0"} transition-all duration-500s ease-in-outs`}></i>
                            </div>
                            <div className={`${openLevel2===true?"h-[255px]":"h-[0px]"} border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-red-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-red-600 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last connection :</span>
                                        <span className="text-red-600 font-light">12-12-2021 12:00 PM</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last synchronization :</span>
                                        <span className="text-red-600 font-light">15-12-2021 11:00 AM</span>
                                    </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-red-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-red-600 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last connection :</span>
                                        <span className="text-red-600 font-light">12-12-2021 12:00 PM</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last synchronization :</span>
                                        <span className="text-red-600 font-light">15-12-2021 11:00 AM</span>
                                    </div>
                                    </div>
                                </div><div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-red-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-red-600 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last connection :</span>
                                        <span className="text-red-600 font-light">12-12-2021 12:00 PM</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last synchronization :</span>
                                        <span className="text-red-600 font-light">15-12-2021 11:00 AM</span>
                                    </div>
                                    </div>
                                </div><div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-red-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-red-600 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last connection :</span>
                                        <span className="text-red-600 font-light">12-12-2021 12:00 PM</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last synchronization :</span>
                                        <span className="text-red-600 font-light">15-12-2021 11:00 AM</span>
                                    </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-red-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-red-600 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full">
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last connection :</span>
                                        <span className="text-red-600 font-light">12-12-2021 12:00 PM</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-48">Last synchronization :</span>
                                        <span className="text-red-600 font-light">15-12-2021 11:00 AM</span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div className={`flex flex-row text-orange-500 items-center hover:bg-slate-200 cursor-pointer ${openLevel3===true?"rounded-none":"rounded-b-md"} border bg-slate-100 w-full p-2 px-4`} onClick={openHandler3}>
                                <i className="fa-solid fa-bars-progress mr-4 text-yellow-400"></i>
                                <span className="text-gray-800 text-sm">Installation Pending ( 3 )</span>
                                <div className="flex grow"></div>
                                <i className={`fa-solid fa-angle-down ml-4 ${openLevel3===true?"rotate-180":"rotate-0"} transition-all duration-500s ease-in-outs`}></i>
                            </div>
                            <div className={`${openLevel3===true?"h-[150px] border rounded-b-md":"h-[0px]"} border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                            <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-yellow-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-yellow-500 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                       
                            </div>
                            <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-yellow-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-yellow-500 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                       
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-yellow-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-yellow-500 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                       
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full">
                                    <div className="text-gray-700 font-semibold w-48"><span className="text-yellow-500">WIN-123</span></div>
                                    <div className="flex items-center">
                                        <span className="font-normal w-28">Device Id :</span>
                                        <span className="text-yellow-500 font-normal">AWS-ABC-12</span>
                                    </div>
                                    </div>
                                       
                                </div>
                            </div>
                    </div>*/}

{/* ///////Policy status right side drawer /////// */}
                    {/* <div className="py-2 px-5">
                        <div className="text-gray-800 text-xl font-semibold flex items-center mt-5">Policy Status <span ><i className="fa-sharp fa-solid fa-file-contract text-sm ml-2 relative bg-slate-700 text-white   w-7 h-7 pt-1 justify-center items-center text-center rounded-full"><span className="text-sm  bg-orange-500 border-2 border-white  text-white rounded-full flex justify-center text-center align-middle items-center w-6 h-6 absolute  -top-[14px] -right-[14px]">7</span></i></span></div>
                            <div className={`flex flex-row items-center hover:bg-slate-200 cursor-pointer text-orange-500 rounded-t-md ${openLevel1===true?"border":"border-t border-l border-r"} bg-slate-100 w-full p-2 px-4 mt-10`} onClick={openHandler1}>
                                <i className="fa-solid fa-chart-network mr-4 text-[#ff5e0e]"></i>
                                <span className="text-gray-800 text-sm">Applied on end-points ( 3 )</span>
                                <div className="flex grow"></div>
                                <i className={`fa-solid fa-angle-down ml-4 ${openLevel1===true?"rotate-180":"rotate-0"} transition-all duration-500s ease-in-outs`}></i>
                            </div>
                            <div className={`${openLevel1===true?"h-[260px]":"h-[0px]"} border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                        <div className="text-gray-700 font-semibold w-full">
                                            <span className="text-[#ff5e0e]">IMG file protection</span>
                                        </div>
                                        </div>
                                        <div className="flex flex-row w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Policy Id :</span>
                                            <span className="text-gray-600 font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Device count :</span>
                                            <span className="text-gray-600 font-light">4</span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                        <div className="text-gray-700 font-semibold w-full">
                                            <span className="text-[#ff5e0e]">IMG file protection</span>
                                        </div>
                                        </div>
                                        <div className="flex flex-row w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Policy Id :</span>
                                            <span className="text-gray-600 font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Device count :</span>
                                            <span className="text-gray-600 font-light">4</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                        <div className="text-gray-700 font-semibold w-full">
                                            <span className="text-[#ff5e0e]">IMG file protection</span>
                                        </div>
                                        </div>
                                        <div className="flex flex-row w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Policy Id :</span>
                                            <span className="text-gray-600 font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Device count :</span>
                                            <span className="text-gray-600 font-light">4</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                        <div className="text-gray-700 font-semibold w-full">
                                            <span className="text-[#ff5e0e]">IMG file protection</span>
                                        </div>
                                        </div>
                                        <div className="flex flex-row w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Policy Id :</span>
                                            <span className="text-gray-600 font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Device count :</span>
                                            <span className="text-gray-600 font-light">4</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                        <div className="text-gray-700 font-semibold w-full">
                                            <span className="text-[#ff5e0e]">IMG file protection</span>
                                        </div>
                                        </div>
                                        <div className="flex flex-row w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Policy Id :</span>
                                            <span className="text-gray-600 font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Device count :</span>
                                            <span className="text-gray-600 font-light">4</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div className={`flex flex-row rounded-b-md text-[#ff5e0e] items-center hover:bg-slate-200 cursor-pointer border bg-slate-100 w-full p-2 px-4`} onClick={openHandler2}>
                                <i className="fa-regular fa-server mr-4 text-[#ff5e0e]"></i>
                                <span className="text-gray-800 text-sm">Applied on file server ( 4 )</span>
                                <div className="flex grow"></div>
                                <i className={`fa-solid fa-angle-down ml-4 ${openLevel2===true?"rotate-180":"rotate-0"} transition-all duration-500s ease-in-outs`}></i>
                            </div>
                            <div className={`${openLevel2===true?"h-[260px] border":"h-[0px]"} border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                        <div className="text-gray-700 font-semibold w-full">
                                            <span className="text-[#ff5e0e]">IMG file protection</span>
                                        </div>
                                        </div>
                                        <div className="flex flex-row w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Policy Id :</span>
                                            <span className="text-gray-600 font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Device count :</span>
                                            <span className="text-gray-600 font-light">4</span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                        <div className="text-gray-700 font-semibold w-full">
                                            <span className="text-[#ff5e0e]">IMG file protection</span>
                                        </div>
                                        </div>
                                        <div className="flex flex-row w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Policy Id :</span>
                                            <span className="text-gray-600 font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Device count :</span>
                                            <span className="text-gray-600 font-light">4</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                        <div className="text-gray-700 font-semibold w-full">
                                            <span className="text-[#ff5e0e]">IMG file protection</span>
                                        </div>
                                        </div>
                                        <div className="flex flex-row w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Policy Id :</span>
                                            <span className="text-gray-600 font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Device count :</span>
                                            <span className="text-gray-600 font-light">4</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                        <div className="text-gray-700 font-semibold w-full">
                                            <span className="text-[#ff5e0e]">IMG file protection</span>
                                        </div>
                                        </div>
                                        <div className="flex flex-row w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Policy Id :</span>
                                            <span className="text-gray-600 font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Device count :</span>
                                            <span className="text-gray-600 font-light">4</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                        <div className="text-gray-700 font-semibold w-full">
                                            <span className="text-[#ff5e0e]">IMG file protection</span>
                                        </div>
                                        </div>
                                        <div className="flex flex-row w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Policy Id :</span>
                                            <span className="text-gray-600 font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Device count :</span>
                                            <span className="text-gray-600 font-light">4</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>*/}

{/* ///////Strategy status right side drawer /////// */}
                    {/* <div className="py-2 px-5">
                        <div className="text-gray-800 text-xl font-semibold flex items-center mt-5">Strategy Status <span ><i className="fa-sharp fa-solid fa-chess text-sm ml-2 relative bg-slate-700 text-white   w-7 h-7 pt-1 justify-center items-center text-center rounded-full"><span className="text-sm  bg-orange-500 border-2 border-white  text-white rounded-full flex justify-center text-center align-middle items-center w-6 h-6 absolute  -top-[14px] -right-[14px]">7</span></i></span></div>
                            <div className={`flex flex-row items-center hover:bg-slate-200 cursor-pointer text-orange-500 rounded-t-md ${openLevel1===true?"border":"border-t border-l border-r"} bg-slate-100 w-full p-2 px-4 mt-10`} onClick={openHandler1}>
                                <i className="fa-solid fa-chart-network mr-4 text-[#ff5e0e]"></i>
                                <span className="text-gray-800 text-sm">Applied on end-points ( 3 )</span>
                                <div className="flex grow"></div>
                                <i className={`fa-solid fa-angle-down ml-4 ${openLevel1===true?"rotate-180":"rotate-0"} transition-all duration-500s ease-in-outs`}></i>
                            </div>
                            <div className={`${openLevel1===true?"h-[260px]":"h-[0px]"} border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                        <div className="text-gray-700 font-semibold w-full">
                                            <span className="text-[#ff5e0e]">IMG file protection</span>
                                        </div>
                                        </div>
                                        <div className="flex flex-row w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Policy Id :</span>
                                            <span className="text-gray-600 font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Device count :</span>
                                            <span className="text-gray-600 font-light">4</span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                        <div className="text-gray-700 font-semibold w-full">
                                            <span className="text-[#ff5e0e]">IMG file protection</span>
                                        </div>
                                        </div>
                                        <div className="flex flex-row w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Policy Id :</span>
                                            <span className="text-gray-600 font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Device count :</span>
                                            <span className="text-gray-600 font-light">4</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                        <div className="text-gray-700 font-semibold w-full">
                                            <span className="text-[#ff5e0e]">IMG file protection</span>
                                        </div>
                                        </div>
                                        <div className="flex flex-row w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Policy Id :</span>
                                            <span className="text-gray-600 font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Device count :</span>
                                            <span className="text-gray-600 font-light">4</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                        <div className="text-gray-700 font-semibold w-full">
                                            <span className="text-[#ff5e0e]">IMG file protection</span>
                                        </div>
                                        </div>
                                        <div className="flex flex-row w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Policy Id :</span>
                                            <span className="text-gray-600 font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Device count :</span>
                                            <span className="text-gray-600 font-light">4</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row w-full mb-2">
                                        <div className="text-gray-700 font-semibold w-full">
                                            <span className="text-[#ff5e0e]">IMG file protection</span>
                                        </div>
                                        </div>
                                        <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Policy Id :</span>
                                            <span className="text-gray-600 font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Device count :</span>
                                            <span className="text-gray-600 font-light">4</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div className={`flex flex-row  text-[#ff5e0e] items-center hover:bg-slate-200 cursor-pointer border bg-slate-100 w-full p-2 px-4  ${openLevel2===false?"rounded-b-md":""}`} onClick={openHandler2}>
                                <i className="fa-regular fa-server mr-4 text-[#ff5e0e]"></i>
                                <span className="text-gray-800 text-sm">Applied on file server ( 4 )</span>
                                <div className="flex grow"></div>
                                <i className={`fa-solid fa-angle-down ml-4  ${openLevel2===true?"rotate-180":"rotate-0"}  transition-all duration-500s ease-in-outs`}></i>
                            </div>
                            <div className={`${openLevel2===true?"h-[260px] border rounded-b-md":"h-[0px]"}  border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row   w-full mb-2">
                                        <div className="text-gray-700 font-semibold w-full">
                                            <span className="text-[#ff5e0e]">IMG file protection</span>
                                        </div>
                                        </div>
                                        <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Policy Id :</span>
                                            <span className="text-gray-600 font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Device count :</span>
                                            <span className="text-gray-600 font-light">4</span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row   w-full mb-2">
                                        <div className="text-gray-700 font-semibold w-full">
                                            <span className="text-[#ff5e0e]">IMG file protection</span>
                                        </div>
                                        </div>
                                        <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Policy Id :</span>
                                            <span className="text-gray-600 font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Device count :</span>
                                            <span className="text-gray-600 font-light">4</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row   w-full mb-2">
                                        <div className="text-gray-700 font-semibold w-full">
                                            <span className="text-[#ff5e0e]">IMG file protection</span>
                                        </div>
                                        </div>
                                        <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Policy Id :</span>
                                            <span className="text-gray-600 font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Device count :</span>
                                            <span className="text-gray-600 font-light">4</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row   w-full mb-2">
                                        <div className="text-gray-700 font-semibold w-full">
                                            <span className="text-[#ff5e0e]">IMG file protection</span>
                                        </div>
                                        </div>
                                        <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Policy Id :</span>
                                            <span className="text-gray-600 font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Device count :</span>
                                            <span className="text-gray-600 font-light">4</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row   w-full mb-2">
                                        <div className="text-gray-700 font-semibold w-full">
                                            <span className="text-[#ff5e0e]">IMG file protection</span>
                                        </div>
                                        </div>
                                        <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Policy Id :</span>
                                            <span className="text-gray-600 font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Device count :</span>
                                            <span className="text-gray-600 font-light">4</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>*/}

{/* ///////Strategy status right side drawer /////// */}
                    {/* <div className="py-2 px-5">
                        <div className="text-gray-800  text-xl font-semibold flex items-center mt-5">Storage Status <span ><i className="fa-sharp fa-solid fa-hard-drive text-sm ml-2 relative bg-slate-700 text-white   w-7 h-7 pt-1 justify-center items-center text-center rounded-full"><span className="text-sm  bg-orange-500 border-2 border-white  text-white rounded-full flex justify-center text-center align-middle items-center w-6 h-6 absolute  -top-[14px] -right-[14px]">7</span></i></span></div>
                            <div className={`flex flex-row items-center  hover:bg-slate-200  cursor-pointer text-orange-500 rounded-t-md ${openLevel1===true?"border":"border-t border-l border-r"} bg-slate-100 w-full p-2 px-4 mt-10`} onClick={openHandler1}>
                                <i className="fa-solid fa-chart-network mr-4 text-[#ff5e0e]"></i>
                                <span className="text-gray-800 text-sm">End-points</span>
                                <div className="flex grow"></div>
                                <i className={`fa-solid fa-angle-down ml-4 ${openLevel1===true?"rotate-180":"rotate-0"} transition-all duration-500s ease-in-outs`}></i>
                            </div>
                            <div className={`${openLevel1===true?"h-[227px]":"h-[0px]"} border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                            <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Storage Type :</span>
                                            <span className="text-[#ff5e0e] font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Storage Size :</span>
                                            <span className="text-[#ff5e0e] font-light">50 GB</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Storage Used :</span>
                                            <span className="text-[#ff5e0e] font-light">10 GB</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Storage Free :</span>
                                            <span className="text-[#ff5e0e] font-light">40 GB</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Storage Type :</span>
                                            <span className="text-[#ff5e0e] font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Storage Size :</span>
                                            <span className="text-[#ff5e0e] font-light">50 GB</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Storage Used :</span>
                                            <span className="text-[#ff5e0e] font-light">10 GB</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Storage Free :</span>
                                            <span className="text-[#ff5e0e] font-light">40 GB</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Storage Type :</span>
                                            <span className="text-[#ff5e0e] font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Storage Size :</span>
                                            <span className="text-[#ff5e0e] font-light">50 GB</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Storage Used :</span>
                                            <span className="text-[#ff5e0e] font-light">10 GB</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Storage Free :</span>
                                            <span className="text-[#ff5e0e] font-light">40 GB</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Storage Type :</span>
                                            <span className="text-[#ff5e0e] font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Storage Size :</span>
                                            <span className="text-[#ff5e0e] font-light">50 GB</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Storage Used :</span>
                                            <span className="text-[#ff5e0e] font-light">10 GB</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Storage Free :</span>
                                            <span className="text-[#ff5e0e] font-light">40 GB</span>
                                        </div>
                                    </div>
                                </div>
                                
                                
                                
                                
                            </div>
                        
                            <div className={`flex flex-row  text-[#ff5e0e] items-center hover:bg-slate-200 cursor-pointer border bg-slate-100 w-full p-2 px-4  ${openLevel2===false?"rounded-b-md":""}`} onClick={openHandler2}>
                                <i className="fa-regular fa-server mr-4 text-[#ff5e0e]"></i>
                                <span className="text-gray-800 text-sm">File server</span>
                                <div className="flex grow"></div>
                                <i className={`fa-solid fa-angle-down ml-4  ${openLevel2===true?"rotate-180":"rotate-0"}  transition-all duration-500s ease-in-outs`}></i>
                            </div>
                            <div className={`${openLevel2===true?"h-[230px] border rounded-b-md":"h-[0px]"}  border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Storage Type :</span>
                                            <span className="text-[#ff5e0e] font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Storage Size :</span>
                                            <span className="text-[#ff5e0e] font-light">50 GB</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Storage Used :</span>
                                            <span className="text-[#ff5e0e] font-light">10 GB</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Storage Free :</span>
                                            <span className="text-[#ff5e0e] font-light">40 GB</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Storage Type :</span>
                                            <span className="text-[#ff5e0e] font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Storage Size :</span>
                                            <span className="text-[#ff5e0e] font-light">50 GB</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Storage Used :</span>
                                            <span className="text-[#ff5e0e] font-light">10 GB</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Storage Free :</span>
                                            <span className="text-[#ff5e0e] font-light">40 GB</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Storage Type :</span>
                                            <span className="text-[#ff5e0e] font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Storage Size :</span>
                                            <span className="text-[#ff5e0e] font-light">50 GB</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Storage Used :</span>
                                            <span className="text-[#ff5e0e] font-light">10 GB</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Storage Free :</span>
                                            <span className="text-[#ff5e0e] font-light">40 GB</span>
                                        </div>
                                    </div>
                                </div><div className="bg-white text-sm p-2 border-b hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light">
                                    <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Storage Type :</span>
                                            <span className="text-[#ff5e0e] font-light">F-ABC-12</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Storage Size :</span>
                                            <span className="text-[#ff5e0e] font-light">50 GB</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-row  w-full">
                                        <div className="flex items-center w-56">
                                            <span className="font-normal w-32">Storage Used :</span>
                                            <span className="text-[#ff5e0e] font-light">10 GB</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-normal w-28">Storage Free :</span>
                                            <span className="text-[#ff5e0e] font-light">40 GB</span>
                                        </div>
                                    </div>
                                </div>
                                
                                
                                
                                
                            </div>
                    </div>*/}
        
                {/* </LevelOneDrawer>
                <LevelTwoDrawer
                    // levelTwoDrawerVisibility={levelTwoDrawerVisibility}
                    levelTwoDrawerVisibility={false}
                    setLevelTwoDrawerVisibility={setLevelTwoDrawerVisibility}
                >
                    <div className="py-2 px-5">
                        <div className="text-gray-800  text-xl font-semibold flex items-center mt-5 mb-8">Ticket Detail</div>
                        <div className="flex flex-col mx-8  bg-slate-300 h-[690px] rounded-t-md rounded-b-md p-4 relative">
                        <div className="w-10 h-10 bg-white rounded-full absolute bottom-60 -right-6"></div>
                        <div className="w-10 h-10 bg-white rounded-full absolute bottom-60 -left-6"></div>
                        <div className="bg-white text-base p-6 mx-4 my-2 rounded-md flex flex-col text-gray-700 font-light">
                                    <div className="text-gray-700 font-medium mb-8"><span className="text-orange-600">Disconnection during backup</span></div>
                                    <div className="flex flex-row  justify-start w-full">
                                        <div className="flex items-center">
                                            <span className="font-medium w-28">Status :</span>
                                            <span className="font-light text-base  px-4 py-1 flex justify-center items-center bg-green-600 text-green-600 bg-opacity-10 rounded-md">Closed</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="font-medium w-28 ml-6">Assign to :</span>
                                            <span className="text-[#ff5e0e] font-normal">ABC-XYZ-DM</span>
                                        </div>
                                    </div>

                                    <div className="flex flex-row  justify-start w-full mt-8">
                                        <div className="flex items-center">
                                            <span className="font-medium w-28">Create date :</span>
                                            <span className="text-[#ff5e0e] font-normal">12-12-2021</span>
                                        </div>
                                        <div className="flex items-center ml-6">
                                            <span className="font-medium w-28">Closed date :</span>
                                            <span className="text-[#ff5e0e] font-normal">12-12-2021</span>
                                        </div>
                                    </div>

                                    <div className="flex w-full items-center justify-center align-middle text-center px-10">
                                        <i className="fa-solid fa-circle-dot text-[#ff5e0e] -mr-1"/>
                                        <div className="rounded-l-full rounded-r-full h-1 bg-gradient-to-r from-[#ff5e0e] to-blue-900 grow mb-[1px] via-cyan-500"></div>
                                        <i className="fa-solid fa-circle-dot text-blue-900 -ml-1"/>
                                    </div>

                                    <div className="text-gray-700 font-medium flex justify-center mt-8">
                                        Last updated Date :<span className="text-[#ff5e0e] ml-6 font-normal">12-12-2021</span>
                                    </div>

                                    <div className="text-gray-700 font-medium flex justify-center mt-4">
                                        Source :<span className="text-[#ff5e0e] ml-6 font-normal">abcxyz@zeeq.io</span>
                                    </div>

                                    <div className="text-gray-700 font-medium flex justify-center mt-8  text-4xl">
                                        <i className="fa-solid fa-barcode"/>
                                        <i className="fa-solid fa-barcode"/>
                                        <i className="fa-solid fa-barcode"/>
                                        <i className="fa-solid fa-barcode"/>
                                        <i className="fa-solid fa-barcode"/>
                                        <i className="fa-solid fa-barcode"/>
                                        <i className="fa-solid fa-barcode"/>
                                    </div>
                                    <div className="flex justify-center  text-gray-700 font-medium border-b-2 pb-5 border-[#ff5e0e] border-opacity-50 border-dashed">
                                        Ticket ID :<span className="ml-4 text-[#ff5e0e]">123ABC-12</span>
                                    </div>
                                    <div className="mt-4 font-medium">Ticket Conversation/Details :</div>
                                    <div className="text-[#ff5e0e] h-[180px] py-4 px-2  overflow-y-auto">
                                         But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure
                                    </div>
                                </div>
                                
                        </div>
                        

                    </div>

                </LevelTwoDrawer>
            </SideDrawer> */}
        </>
    )
}

export default Tile5x4_ChartText;