import React, { FC, useEffect, useState, SetStateAction, Dispatch } from "react";

interface ISwitchProps {
    state?: boolean;
    switchHandler: Dispatch<SetStateAction<boolean>>;
    showLabel?: boolean;
    labels?: string[];
    colors?: string[];
    className?: string;
    keepEnable?: boolean;
    keepDisable?: boolean;
}

/**
 * border-green-500 border-red-500 border-gray-400
 * bg-green-500 bg-red-500 bg-gray-400 
 * text-green-500 text-red-500 text-gray-400
 * @param param0 
 * @returns 
 */
const Switch:FC<ISwitchProps> = ({
    state=false,
    switchHandler,
    showLabel=false,
    labels=['Yes','No'],
    colors=['gray-400','green-500'],
    className='',
    keepEnable=false,
    keepDisable=false,
}) => {
    const [btnStats, setBtnState] = useState<boolean>(state || false)

    useEffect(()=>{
        if( keepEnable ) { setBtnState(true) }
        else if( keepDisable ) { setBtnState(false) }
        else { setBtnState(state) }
    },[keepDisable, keepEnable, state])

    return (
        <div 
            className={`flex relative w-[52px] h-6 rounded-[12px] border transition-[border] ease-in-out duration-500 ${btnStats ? `border-${colors[1]} bg-${colors[1]}` : `border-${colors[0]} bg-${colors[0]}`} bg-opacity-10 cursor-pointer ${className}`}
            onClick={()=>{
                if( keepEnable ) { setBtnState(true) }
                else if( keepDisable ) { setBtnState(false) }
                else { 
                    setBtnState( ( prev ) => { return !prev})
                    switchHandler(!btnStats)
                }
            }}
        >
            {showLabel && <span className={`absolute text-xs font-semibold mt-[2.5px] ${btnStats ? `text-${colors[1]} ml-1` : `text-${colors[0]} ml-6`} transition-all ease-in-out duration-500 z-0`}>{btnStats ? labels[0] : labels[1]}</span>}
            <span className={`h-[18px] w-[18px] rounded-full transition-all m-[2px] ${btnStats ? `bg-${colors[1]} ml-[30px]` :  `bg-${colors[0]} ml-[1.5px]`} transition-[margin] ease-in-out duration-500 z-10`}></span>
        </div>
    );
}

export default Switch