import React, { useState, useContext, useLayoutEffect, useEffect, FC, useRef, Dispatch, SetStateAction, createContext, ReactNode } from "react"
import { Breadcrumb, Button, Dropdown, Tile, TimePicker } from "../../components/core";
import { MailingListContext, SideDrawerContext, ThemeContext } from "../../contexts";
import { getAPICall, postAPICall, putAPICall } from "../../services/APILayer/axiosMethodCalls";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';

const Notification = () =>{

    const [licenseInfo, setLicenseInfo] = useState<any>({});
    const [settingsEnabled, setSettingsEnabled] = useState<string|null>('Endpoint');

    const getLicenseInfo = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/app/licenses/me`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( Object.keys(licenseInfo).length > 0 ) {
            return;
        }
        const fetchLicenseInfo = () => {
            getLicenseInfo()
                .then((license)=>{
                    setLicenseInfo(license)
                })
                .catch(err=>console.log(err));
        }
        fetchLicenseInfo();
    },[licenseInfo]) 



    //  const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
     const sideDrawerContext = useContext(SideDrawerContext)
     return (
        <>
           <div className={`p-6 grow flex flex-col`}>
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Notification</h1>
                <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-bell-on', link: '', name: 'Home' },
                    { icon: '', link: '', name: 'Settings' },
                    { icon: '', link: '/notifications', name: 'Notifications' },
                ]} />
                <div className={` grow flex flex-col`}>
                    <div className={`grid grid-flow-row-dense grid-cols-1 lg:grid-cols-12 gap-6 w-full min-h-max mb-6 `}>
                            <Tile colSpan={3} rowSpan={22} className="pb-4 lg:col-span-3">
                                <div className="title text-base md:text-lg lg:text-xl text-gray-800 font-medium p-4 border rounded-t-lg">
                                    Notifications
                                    <div className="text-gray-500 text-sm"></div>
                                </div>
                                <div className="flex flex-col w-full h-[590px] overflow-y-scroll customScroll">
                                    {licenseInfo?.license?.license_data?.product_access?.endpoints?.enabled && <div 
                                        onClick={()=>{
                                            setSettingsEnabled('Endpoint')
                                        }}
                                        className={`flex px-4 py-3 border-b cursor-pointer border-l-4 hover:bg-slate-50 ${settingsEnabled === 'Endpoint' ? 'border-l-orange-500' : 'border-l-white'}  hover:border-l-orange-500 items-center`}
                                    >
                                            <div className="text-slate-500 font-medium py-2 order-b">
                                                Endpoint 
                                            </div>
                                            <div className="grow"></div>
                                            {settingsEnabled === 'Endpoint' && <span className="fa-stack text-xs flex items-center mr-2">
                                                <i className="fa-solid fa-circle fa-stack-2x text-[#ff5e0e]"></i>
                                                <i className="fa-solid fa-check fa-stack-1x fa-inverse"></i>
                                            </span>}                             
                                    </div>}
                                    {licenseInfo?.license?.license_data?.product_access?.ms365?.enabled && <div 
                                        onClick={()=>{
                                            setSettingsEnabled('MS365')
                                        }}
                                        className={`flex px-4 py-3 border-b cursor-pointer border-l-4 hover:bg-slate-50 ${settingsEnabled === 'MS365' ? 'border-l-orange-500' : 'border-l-white'} hover:border-l-orange-500 items-center`}
                                    >
                                            <div className="text-slate-500 font-medium py-2 order-b">
                                                MS365 
                                            </div>
                                            <div className="grow"></div>
                                            {settingsEnabled === 'MS365' && <span className="fa-stack text-xs flex items-center mr-2">
                                                <i className="fa-solid fa-circle fa-stack-2x text-[#ff5e0e]"></i>
                                                <i className="fa-solid fa-check fa-stack-1x fa-inverse"></i>
                                            </span>}  
                                    </div>}
                                    {licenseInfo?.license?.license_data?.product_access?.servers?.enabled && <div 
                                        onClick={()=>{
                                            setSettingsEnabled('Servers')
                                        }}
                                        className={`flex px-4 py-3 border-b cursor-pointer border-l-4 hover:bg-slate-50 ${settingsEnabled === 'Servers' ? 'border-l-orange-500' : 'border-l-white'} hover:border-l-orange-500 items-center`}
                                    >
                                            <div className="text-slate-500 font-medium py-2 order-b">
                                                Servers 
                                            </div>
                                            <div className="grow"></div>
                                            <span className="fa-stack text-xs flex items-center mr-2">
                                                <i className="fa-solid fa-circle fa-stack-2x text-[#ff5e0e]"></i>
                                                <i className="fa-solid fa-check fa-stack-1x fa-inverse"></i>
                                            </span>
                                    </div>}
                                    
                                </div>
                            </Tile>
                            {settingsEnabled === 'Endpoint' && <Tile colSpan={8} rowSpan={38} className="flex flex-col pb-4 overflow-hidden lg:col-span-9 border">
                                <div className="title flex items-center justify-center align-middle p-4 border-b">
                                    <span className="flex flex-col">
                                        <span className="text-base md:text-lg lg:text-xl text-gray-800 font-medium whitespace-nowrap">Endpoint Notification Settings</span>
                                    </span>
                                    <span className="grow"></span>
                                    <Button
                                        onClick={()=>{
                                            sideDrawerContext.setFirstDrawerChild(<FirstSideDrawer licenseInfo={licenseInfo}/>)
                                            sideDrawerContext.setLevelOneDrawerVisibility(true)
                                        }}

                                        size="sm"
                                    >Manage Mailing list</Button>
                                </div>
                                <div className="">
                                <div className="text-[#ff5e0e] font-medium py-2 px-4 flex flex-row justify-between items-center text-lg bg-slate-50">
                                    Incidence Alerts
                                </div>
                                <div className={`custom-table flex flex-col w-full`}>
                                    <div className="tbody grow w-full border-t">
                                        <div className="row flex flex-col lg:flex-row w-full font-normal hover:bg-slate-50 text-slate-500 border-b pl-4 items-start lg:items-center">
                                            <div className="cell whitespace-nowrap my-2 lg:mb-0 w-40 max-w-52 text-left">Device Registration</div>
                                            <div className="grow"></div>
                                            <div className="cell flex w-[380px] text-left mr-6 z-[80] items-center">
                                                <Dropdown options={[
                                                    "Default mail list","Mail list name 1","Mail list name 2","Mail list name 3","Mail list name 4","Mail list name 5"
                                                ]} 
                                                placeholder="Select Mailing List"
                                                multiple={true}
                                                minWidth='w-[320px]'
                                                />
                                            </div>
                                            <i className="fa-regular fa-eye lg:p-5 p-4 pl-1  text-[#ff5e0e] hover:bg-gray-100 cursor-pointer"
                                                onClick={()=>{
                                                    sideDrawerContext.setFirstDrawerChild(<FirstSideDrawer licenseInfo={licenseInfo}/>)
                                                    sideDrawerContext.setLevelOneDrawerVisibility(true)
                                                }}
                                            ></i>
                                        </div>
                                        <div className="row flex flex-col lg:flex-row w-full font-normal hover:bg-slate-50 text-slate-500 border-b pl-4 items-start lg:items-center">
                                            <div className="cell w-40 max-w-52 whitespace-nowrap my-2 lg:mb-0 text-left">Device Installed</div>
                                            <div className="grow"></div>
                                            <div className="cell flex w-[380px] text-left mr-6 z-[70] items-center">
                                                <Dropdown options={[
                                                    "Default mail list","Mail list name 1","Mail list name 2","Mail list name 3","Mail list name 4","Mail list name 5"
                                                ]} 
                                                placeholder="Select Mailing List"
                                                multiple={true}
                                                minWidth='w-[320px]'
                                                />
                                            </div>
                                            <i className="fa-regular fa-eye lg:p-5 p-4 pl-1  text-[#ff5e0e] hover:bg-gray-100 cursor-pointer"
                                                onClick={()=>{
                                                    sideDrawerContext.setFirstDrawerChild(<FirstSideDrawer licenseInfo={licenseInfo}/>)
                                                    sideDrawerContext.setLevelOneDrawerVisibility(true)
                                                }}
                                            ></i>                                  
                                        </div>
                                        <div className="row flex flex-col lg:flex-row w-full font-normal hover:bg-slate-50 text-slate-500 border-b pl-4 items-start lg:items-center">
                                            <div className="cell grow w-40 max-w-52 whitespace-nowrap my-2 lg:mb-0 text-left">Device Deletion</div>
                                            <div className="grow"></div>
                                            <div className="cell flex w-[380px] text-left mr-6 z-[60] items-center">
                                                <Dropdown options={[
                                                    "Default mail list","Mail list name 1","Mail list name 2","Mail list name 3","Mail list name 4","Mail list name 5"
                                                ]} 
                                                placeholder="Select Mailing List"
                                                multiple={true}
                                                minWidth='w-[320px]'
                                                />
                                            </div>
                                            <i className="fa-regular fa-eye lg:p-5 p-4 pl-1  text-[#ff5e0e] hover:bg-gray-100 cursor-pointer"
                                                onClick={()=>{
                                                    sideDrawerContext.setFirstDrawerChild(<FirstSideDrawer licenseInfo={licenseInfo}/>)
                                                    sideDrawerContext.setLevelOneDrawerVisibility(true)
                                                }}
                                            ></i>                                  
                                        </div>
                                        <div className="row flex flex-col lg:flex-row w-full font-normal hover:bg-slate-50 text-slate-500 border-b pl-4 items-start lg:items-center">
                                            <div className="cell grow w-40 max-w-52 whitespace-nowrap my-2 lg:mb-0 text-left">Restore</div>
                                            <div className="grow"></div>
                                            <div className="cell flex w-[380px] text-left mr-6 z-50 items-center">
                                                <Dropdown options={[
                                                    "Default mail list","Mail list name 1","Mail list name 2","Mail list name 3","Mail list name 4","Mail list name 5"
                                                ]} 
                                                placeholder="Select Mailing List"
                                                multiple={true}
                                                minWidth='w-[320px]'
                                                />
                                            </div>
                                            <i className="fa-regular fa-eye lg:p-5 p-4 pl-1  text-[#ff5e0e] hover:bg-gray-100 cursor-pointer"
                                                onClick={()=>{
                                                    sideDrawerContext.setFirstDrawerChild(<FirstSideDrawer licenseInfo={licenseInfo}/>)
                                                    sideDrawerContext.setLevelOneDrawerVisibility(true)
                                                }}
                                            ></i>                                  
                                        </div>
                                    </div>
                                </div>
                                <div className="text-[#ff5e0e] font-medium py-2 px-4 flex flex-row justify-between items-center text-lg bg-slate-50">
                                    Audit Trail Report Alerts
                                </div>
                                <div className={`custom-table flex flex-col w-full`}>
                                    <div className="tbody grow w-full border-t">
                                        <div className="row flex flex-col lg:flex-row justify-start w-full font-normal hover:bg-slate-50 text-slate-500 border-b pl-4 items-start lg:items-center">
                                            <div className="flex cell max-w-52 my-2 whitespace-nowrap lg:mb-0 text-left">
                                                Daily Schedule Report (&nbsp;<TimePicker dayVisible={false} timeFormat={24} />&nbsp;) 
                                            </div>
                                            <div className="grow"></div>
                                            <div className="cell flex w-[380px] text-left mr-6 z-40 items-center">
                                                <Dropdown options={[
                                                    "Default mail list","Mail list name 1","Mail list name 2","Mail list name 3","Mail list name 4","Mail list name 5"
                                                ]} 
                                                placeholder="Select Mailing List"
                                                multiple={true}
                                                minWidth='w-[320px]'
                                                />
                                            </div>
                                            <i className="fa-regular fa-eye lg:p-5 p-4 pl-1  text-[#ff5e0e] hover:bg-gray-100 cursor-pointer"
                                                onClick={()=>{
                                                    sideDrawerContext.setFirstDrawerChild(<FirstSideDrawer licenseInfo={licenseInfo}/>)
                                                    sideDrawerContext.setLevelOneDrawerVisibility(true)
                                                }}
                                            ></i>
                                        </div>
                                        <div className="row flex flex-col lg:flex-row w-full font-normal hover:bg-slate-50 text-slate-500 border-b pl-4 items-start lg:items-center">
                                            <div className="flex cell grow w-40 max-w-52 whitespace-nowrap my-2 lg:mb-0 text-left">Weekly Schedule Report (&nbsp;<div className="text-[#FF5E0E]">Every</div>&nbsp;<TimePicker dayVisible={true} timeFormat={24} />&nbsp;) </div>
                                            <div className="grow"></div>
                                            <div className="cell flex w-[380px] text-left mr-6 z-30 items-center">
                                                <Dropdown options={[
                                                    "Default mail list","Mail list name 1","Mail list name 2","Mail list name 3","Mail list name 4","Mail list name 5"
                                                ]} 
                                                placeholder="Select Mailing List"
                                                multiple={true}
                                                minWidth='w-[320px]'
                                                />
                                            </div>
                                            <i className="fa-regular fa-eye lg:p-5 p-4 pl-1  text-[#ff5e0e] hover:bg-gray-100 cursor-pointer"
                                                onClick={()=>{
                                                    sideDrawerContext.setFirstDrawerChild(<FirstSideDrawer licenseInfo={licenseInfo}/>)
                                                    sideDrawerContext.setLevelOneDrawerVisibility(true)
                                                }}
                                            ></i>                                    
                                        </div>
                                    </div>
                                </div>
                                <div className="text-[#ff5e0e] font-medium py-2 px-4 flex flex-row justify-between items-center text-lg bg-slate-50">
                                    License Alerts
                                </div>
                                <div className={`custom-table flex flex-col w-full`}>
                                    <div className="tbody grow w-full border-t">
                                        <div className="row flex flex-col lg:flex-row w-full font-normal hover:bg-slate-50 text-slate-500 border-b pl-4 items-start lg:items-center">
                                            <div className="cell grow w-40 max-w-52 whitespace-nowrap my-2 lg:mb-0 text-left">License Alert</div>
                                            <div className="grow"></div>
                                            <div className="cell flex w-[380px] text-left mr-6 z-20 items-center">
                                                <Dropdown options={[
                                                    "Default mail list","Mail list name 1","Mail list name 2","Mail list name 3","Mail list name 4","Mail list name 5"
                                                ]} 
                                                placeholder="Select Mailing List"
                                                multiple={true}
                                                minWidth='w-[320px]'
                                                />
                                            </div>
                                            <i className="fa-regular fa-eye lg:p-5 p-4 pl-1  text-[#ff5e0e] hover:bg-gray-100 cursor-pointer"
                                                onClick={()=>{
                                                    sideDrawerContext.setFirstDrawerChild(<FirstSideDrawer licenseInfo={licenseInfo}/>)
                                                    sideDrawerContext.setLevelOneDrawerVisibility(true)
                                                }}
                                            ></i>                                 
                                        </div>
                                        <div className="row flex flex-col lg:flex-row w-full font-normal hover:bg-slate-50 text-slate-500 border-b pl-4 items-start lg:items-center">
                                            <div className="cell grow w-40 max-w-52 whitespace-nowrap my-2 lg:mb-0 text-left">Storage Quota</div>
                                            <div className="grow"></div>
                                            <div className="cell flex w-[380px] text-left mr-6 z-10 items-center">
                                                <Dropdown options={[
                                                    "Default mail list","Mail list name 1","Mail list name 2","Mail list name 3","Mail list name 4","Mail list name 5"
                                                ]} 
                                                placeholder="Select Mailing List"
                                                multiple={true}
                                                minWidth='w-[320px]'
                                                />
                                            </div>
                                            <i className="fa-regular fa-eye lg:p-5 p-4 pl-1  text-[#ff5e0e] hover:bg-gray-100 cursor-pointer"
                                                onClick={()=>{
                                                    sideDrawerContext.setFirstDrawerChild(<FirstSideDrawer licenseInfo={licenseInfo}/>)
                                                    sideDrawerContext.setLevelOneDrawerVisibility(true)
                                                }}
                                            ></i>
                                        </div>
                                        <div className="row flex flex-col lg:flex-row w-full font-normal hover:bg-slate-50 text-slate-500 border-b pl-4 items-start lg:items-center">
                                            <div className="cell grow w-40 max-w-52 whitespace-nowrap my-2 lg:mb-0 text-left">Device Quota</div>
                                            <div className="grow"></div>
                                            <div className="cell flex w-[380px] text-left mr-6 z-0 items-center">
                                                <Dropdown options={[
                                                    "Default mail list","Mail list name 1","Mail list name 2","Mail list name 3","Mail list name 4","Mail list name 5"
                                                ]} 
                                                placeholder="Select Mailing List"
                                                multiple={true}
                                                minWidth='w-[320px]'
                                                />
                                            </div>
                                            <i className="fa-regular fa-eye lg:p-5 p-4 pl-1  text-[#ff5e0e] hover:bg-gray-100 cursor-pointer"
                                                onClick={()=>{
                                                    sideDrawerContext.setFirstDrawerChild(<FirstSideDrawer licenseInfo={licenseInfo}/>)
                                                    sideDrawerContext.setLevelOneDrawerVisibility(true)
                                                }}
                                            ></i>                                   
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>
                            </Tile>}
                            {settingsEnabled === 'MS365' && <Tile colSpan={8} rowSpan={33} className="flex flex-col pb-4 overflow-hidden md:col-span-9 border">
                                <div className="title flex items-center justify-center align-middle p-4 border-b">
                                    <span className="flex flex-col">
                                        <span className="text-base md:text-lg lg:text-xl text-gray-800 font-medium">MS365 Security Settings</span>
                                    </span>
                                    <span className="grow"></span>
                                </div>
                                <div className="">
                                    <div className="tbody grow w-full border-t"></div>
                                </div>
                            </Tile>}
                            {settingsEnabled === 'Servers' && <Tile colSpan={8} rowSpan={33} className="flex flex-col pb-4 overflow-hidden md:col-span-9 border">
                                <div className="title flex items-center justify-center align-middle p-4 border-b">
                                    <span className="flex flex-col">
                                        <span className="text-base md:text-lg lg:text-xl text-gray-800 font-medium">Servers Security Settings</span>
                                    </span>
                                    <span className="grow"></span>
                                </div>
                                <div className="">
                                    <div className="tbody grow w-full border-t"></div>
                                </div>
                            </Tile>}
                    </div>
                </div>
            </div>
        
        </>
    )
}

export default Notification

interface IFirstSideDrawer {
    licenseInfo: any;
}

const FirstSideDrawer:FC<IFirstSideDrawer> = ({
    licenseInfo
}) => {

    const sideDrawerContext = useContext(SideDrawerContext);
    const mailingListContext = useContext(MailingListContext);
    const firstChecked = useRef(false);
    
    const [ mailingListFormVisibilityState, setMailingListFormVisibilityState ] = useState<boolean>(false);
    const [ loader, setLoader ] = useState<boolean>(false);
    const [ searchTerm, setSearchTerm ] = useState<string>('');
    const [ searchResults, setSearchResults ] = useState<any[]>([]);
    const [ formOpenFor, setFormOpenFor ] = useState<string>('Add');
    const [ change, setChange ] = useState<string>('')
    const [ newMailingListError, setNewMailingListError ] = useState<any>({ name: ''})
    const [ newMailingList, setNewMailingList ] = useState<any>({
        name: '',
        users: [],
        status: true,
        metadata: {
            type: 'general',
            description: ''
        }
    });

    const getAllMailingLists = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/app/mailinglists`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const createMailingList = async (data:any) => {
        try {
            const response = await postAPICall(`${process.env.REACT_APP_API_URL}/app/mailinglists`,{ insert: {...data}});
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const updateMailingList = async (mailingListId:string, data:any) => {
        try {
            const response = await putAPICall(`${process.env.REACT_APP_API_URL}/app/mailinglists/${mailingListId}`,{ updates: {...data}});
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( firstChecked.current ) { return }
        const fetchAllMailingList = async () => {
            let response: any = await getAllMailingLists()
            if( response ) {
                if( (response?.mailinglists || []).length < 1 && (mailingListContext?.allMailingList || []).length < 1 ) {
                    // create default mailing list
                    createDefaultMailingLists()
                } else {
                    // save mailing lists to context
                    if( (mailingListContext?.allMailingList || []).length < 1 ) {
                        mailingListContext.setAllMailingList(response?.mailinglists || [])
                    }
                }
            } else {
                toast.warning('Something went wrong')
            }
        }
        fetchAllMailingList()
        firstChecked.current = true;
    },[])

    const createDefaultMailingLists = async () => {
        setLoader(true)
        let allUsersMailingList = await createMailingList({
            name: "All Users",
            users: [],
            status: true,
            metadata: {
                type: 'default',
                description: 'Default mailing list contains all users if organization'
            }
        })
        let deletedUsersMailingList = await createMailingList({
            name: "Deleted Users",
            users: [],
            status: false,
            metadata: {
                type: 'default',
                description: 'Default mailing list contains all deleted users if organization'
            }
        })
        let newMailingLists = [
            allUsersMailingList.mailinglist._doc,
            deletedUsersMailingList.mailinglist._doc
        ]
        mailingListContext.setAllMailingList(newMailingLists)
        setLoader(false)
    }

    const validateNewMailingList = () => {
        if( newMailingList?.name === '' ) {
            setNewMailingListError({ name: 'Required field'})
            return false
        } else {
            let arrMailingListNames = (mailingListContext?.allMailingList || []).filter((ml:any)=>{ return ml.status === true }).map((ml:any)=>{ return (ml?.name || '').trim().toLowerCase() });
            if( [...arrMailingListNames, 'deleted users'].includes( (newMailingList?.name || '').trim().toLowerCase() ) ) {
                setNewMailingListError({ name: `Mailing list with name '${newMailingList?.name}' already exists`})
                return false
            } else {
                return true
            }
        }
    }

    const createNewMailingList = async () => {
        if( !validateNewMailingList() ) { return }
        setLoader(true)
        let response = await createMailingList(newMailingList)

        if( response?.mailinglist?.$__?.inserting === true ) {
            
            let newMailingListData = response?.mailinglist?._doc
            
            mailingListContext.setAllMailingList([
                ...mailingListContext.allMailingList,
                newMailingListData
            ])
            
            setNewMailingList({
                name: '',
                users: [],
                status: true,
                metadata: {
                    type: 'general',
                    description: ''
                }
            })
            toast.success('Mailing list added successfully')
            setMailingListFormVisibilityState(false)
            setLoader(false)
        } else {
            toast.warning('Something went wrong')
            setLoader(false)
        }
    }

    const validateUpdateExistingMailingListName = () => {
        if( newMailingList?.name === '' ) {
            setNewMailingListError({ name: 'Required field'})
            return false
        } else {
            if( newMailingList.name === mailingListContext.selectedMailingList.name ) {
                setNewMailingListError({ name: `No change`})
                return false
            }
            let arrMailingListNames = (mailingListContext?.allMailingList || []).filter((ml:any)=>{ return ml.name !== mailingListContext?.selectedMailingList?.name && ml.status === true }).map((ml:any)=>{ return (ml?.name || '').trim().toLowerCase() });
            if( [...arrMailingListNames, 'deleted users'].includes( (newMailingList?.name || '').trim().toLowerCase() ) ) {
                setNewMailingListError({ name: `Mailing list with name '${newMailingList?.name}' already exists`})
                return false
            } else {
                return true
            }
        }
        
    }

    const updateExistingMailingListName = async () => {
        if(!validateUpdateExistingMailingListName()) { return }

        setLoader(true)

        let updatedMailingList = {
            name: newMailingList?.name,
            users: mailingListContext?.selectedMailingList?.users || [],
            status: mailingListContext?.selectedMailingList?.status || true,
            metadata: {
                type: 'general',
                description: ''
            }
        }

        let response = await updateMailingList(mailingListContext?.selectedMailingList?._id, updatedMailingList)
        if( response?.mailinglist?.acknowledged === true ) {

            let copyOfAllMailingLists: any[] = [...mailingListContext?.allMailingList]
            let copyOfSelectedMailingList: any = {...mailingListContext?.selectedMailingList}
            let selectedMailingListIndex: number = -1

            for (let i = 0; i < mailingListContext.allMailingList.length; i++) {
                const mailingList = mailingListContext.allMailingList[i];
                if( mailingList?._id === copyOfSelectedMailingList?._id ) {
                    selectedMailingListIndex = i
                }
            }

            copyOfAllMailingLists[selectedMailingListIndex] = {
                ...copyOfSelectedMailingList,
                name: newMailingList?.name
            }

            mailingListContext.setAllMailingList( copyOfAllMailingLists )
            mailingListContext.setSelectedMailingList({})
            
            setNewMailingList({
                name: '',
                users: [],
                status: true,
                metadata: {
                    type: 'general',
                    description: ''
                }
            })
            toast.success('Mailing list updated successfully')
            setMailingListFormVisibilityState(false)
            setLoader(false)
        } else {
            toast.warning('Something went wrong')
            setLoader(false)
        }

    }

    useEffect(()=>{
        if( formOpenFor !== 'Delete' ) { return }
        deleteMailingList()
    },[formOpenFor, change])

    const deleteMailingList = async () => {
        setLoader(true)

        let ml = mailingListContext.selectedMailingList;

        if( ml?._id ) {
            let mailingListToDelete = {
                name: ml?.name,
                users: ml?.users,
                status: false,
                metadata: {
                    type: 'general',
                    description: ''
                }
            }
    
            let response = await updateMailingList(ml?._id, mailingListToDelete)
            if( response?.mailinglist?.acknowledged === true ) {

                let copyOfAllMailingLists: any[] = [...mailingListContext?.allMailingList]
                let copyOfSelectedMailingList: any = {...mailingListContext?.selectedMailingList}
                let selectedMailingListIndex: number = -1
    
                for (let i = 0; i < mailingListContext.allMailingList.length; i++) {
                    const mailingList = mailingListContext.allMailingList[i];
                    if( mailingList?._id === copyOfSelectedMailingList?._id ) {
                        selectedMailingListIndex = i
                    }
                }
    
                copyOfAllMailingLists[selectedMailingListIndex] = {
                    ...copyOfSelectedMailingList,
                    status: false
                }
    
                mailingListContext.setAllMailingList( copyOfAllMailingLists )
                mailingListContext.setSelectedMailingList({})
                
                setNewMailingList({
                    name: '',
                    users: [],
                    status: true,
                    metadata: {
                        type: 'general',
                        description: ''
                    }
                })
                setFormOpenFor('Add')
                toast.success('Mailing list deleted successfully')
                setLoader(false)
            } else {
                toast.warning('Something went wrong')
                setLoader(false)
            }
        } else {
            toast.warning('Something went wrong')
            setLoader(false)
        }
    }

    useEffect(()=>{
        if( searchTerm === '' && searchTerm === null && searchTerm === undefined ) { return }

        let results = (mailingListContext?.allMailingList || []).filter((ml:any)=>{ return ml?.status === true && (ml?.name || '').toLowerCase().includes( searchTerm.toLowerCase() )  })

        setSearchResults(results)
    },[searchTerm])

    useEffect(()=>{
        mailingListContext.setSelectedMailingList({})
        setLoader(false);
        setSearchTerm('');
        setSearchResults([]);
        setFormOpenFor('Add');
        setChange('')
        setNewMailingListError({ name: ''})
        setNewMailingList({
            name: '',
            users: [],
            status: true,
            metadata: {
                type: 'general',
                description: ''
            }
        });

    },[ sideDrawerContext.setLevelOneDrawerVisibility ])

    // useEffect(()=>{
    //     if( sideDrawerContext.levelOneDrawerVisibility !== true ) { return }
    //     sideDrawerContext.setSecondDrawerChild(
    //         <SecondSideDrawer />
    //     )
    //     sideDrawerContext.setLevelTwoDrawerVisibility(true)
    // },[mailingListContext?.allMailingList])

    return (
        <>
            <div className="title w-full absolute flex gap-4 px-6 top-0 pt-10 border-b pb-8 bg-slate-100 items-center">
                <i className="fa-regular fa-envelope text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i>
                <div className="flex flex-col w-full">
                    <div className="text-xl font-bold text-slate-700">Manage Mailing List</div>
                    <div className="flex items-center gap-4">
                        <div className="block text-slate-500">All Lists ({ (mailingListContext?.allMailingList || []).filter((ml:any)=>{ return ml.status === true }).length })</div>
                        {loader && <em className="fa-solid fa-loader fa-spin"></em>}
                    </div>
                </div>
            </div>
            <div className="flex w-full absolute top-[125px]">
                <div className="w-1/2">
                    <Button size="md" block={true} solid={!mailingListFormVisibilityState} className="rounded-none"
                        onClick={()=>{
                            setMailingListFormVisibilityState(false)
                        }}
                    >View</Button>
                </div>
                <div className="w-1/2">
                    <Button 
                        onClick={()=>{
                            setMailingListFormVisibilityState(true)
                        }}
                        size="md" block={true} solid={mailingListFormVisibilityState} className="rounded-none"
                    >{formOpenFor === 'Add' || formOpenFor === 'Delete' ? 'Add' : 'Update'}</Button>
                </div>
            </div>
            <div className="data absolute top-[167px] w-full overflow-clip">
                <div className="flex h-[42px]">
                    <input 
                        type="text"
                        placeholder="Search..."
                        className="w-full outline-none px-4 border-b"
                        value={searchTerm}
                        onChange={(e)=>{
                            setSearchTerm(e?.target?.value || '')
                        }}
                    />
                    <div className="">
                        <Button className="rounded-none">
                            <em className="fa-solid fa-search"></em>
                        </Button>
                    </div>
                </div>
                <div className="h-[71vh] flex flex-col overflow-y-scroll customScroll">
                    { (mailingListContext?.allMailingList || []).filter((ml:any)=>{ return ml.name === 'All Users' }).map((ml:any, mlIndex:number)=>(
                        <div key={mlIndex} className={`flex items-center border-b cursor-pointer ${(ml?._id === mailingListContext?.selectedMailingList?._id) ? 'bg-orange-100 hover:bg-orange-50' : 'hover:bg-slate-50'}`}
                            onClick={()=>{
                                mailingListContext.setSelectedMailingList(ml)
                                sideDrawerContext.setSecondDrawerChild(
                                    <SecondSideDrawer />
                                )
                                sideDrawerContext.setLevelTwoDrawerVisibility(true)
                            }}
                        >
                            <em className="fa-solid fa-angle-left p-4 text-[#ff5e0e]"></em>
                            <div className="grow flex items-center py-4">
                                <div className="max-w-[200px] mr-2 truncate">{ml?.name || ''}</div>
                                <small className="text-orange-500">({(ml?.users || []).length})</small>
                            </div>
                            <div className="group relative"
                                onClick={(e)=>{
                                    e.stopPropagation()
                                    mailingListContext.setSelectedMailingList(ml)
                                    setNewMailingList({
                                        name: `${ml?.name || ''} Copy`,
                                        users: ml?.users || [],
                                        status: ml?.status || true,
                                        metadata: {
                                            type: 'general',
                                            description: ''
                                        }
                                    })
                                    setFormOpenFor('Add')
                                    setMailingListFormVisibilityState(true)
                                    setSearchTerm('')
                                }}
                            >
                                <em className="fa-regular fa-copy p-5 text-[#ff5e0e] hover:bg-slate-200"></em>
                                <div className="absolute left-[-90px] top-[10px] px-2 py-1 bg-white rounded-md shadow-md hidden group-hover:block">Duplicate</div>
                            </div>
                        </div>
                    )) }
                    { (searchTerm === '' || searchTerm === undefined || searchTerm === null) && (mailingListContext?.allMailingList || []).filter((ml:any)=>{ return ml.name !== 'All Users' && ml.status === true }).map((ml:any, mlIndex:number)=>(
                        <div key={mlIndex} className={`flex items-center border-b cursor-pointer ${(ml?._id === mailingListContext?.selectedMailingList?._id) ? 'bg-orange-100 hover:bg-orange-50' : 'hover:bg-slate-50'}`}
                            onClick={()=>{
                                mailingListContext.setSelectedMailingList(ml)
                                sideDrawerContext.setSecondDrawerChild(
                                    <SecondSideDrawer />
                                )
                                sideDrawerContext.setLevelTwoDrawerVisibility(true)
                            }}
                        >
                            <em className="fa-solid fa-angle-left p-4 text-[#ff5e0e]"></em>
                            <div className="grow flex items-center py-4">
                                <div className="max-w-[200px] mr-2 truncate">{ml?.name || ''}</div>
                                <small className="text-orange-500">({(ml?.users || []).length})</small>
                            </div>
                            <div className="group relative"
                                onClick={(e)=>{
                                    e.stopPropagation()
                                    mailingListContext.setSelectedMailingList({...ml})
                                    setFormOpenFor('Delete')
                                    setChange(uuidv4())
                                    setSearchTerm('')
                                }}
                            >
                                <em className="fa-regular fa-trash p-5 text-[#ff5e0e] hover:bg-slate-200"></em>
                                <div className="absolute left-[-70px] top-[10px] px-2 py-1 bg-white rounded-md shadow-md hidden group-hover:block">Delete</div>
                            </div>
                            <div className="group relative"
                                onClick={(e)=>{
                                    e.stopPropagation()
                                    mailingListContext.setSelectedMailingList(ml)
                                    setNewMailingList({
                                        name: `Copy of ${ml?.name || ''}`,
                                        users: ml?.users || [],
                                        status: ml?.status || true,
                                        metadata: {
                                            type: 'general',
                                            description: ''
                                        }
                                    })
                                    setFormOpenFor('Add')
                                    setMailingListFormVisibilityState(true)
                                    setSearchTerm('')
                                }}
                            >
                                <em className="fa-regular fa-copy p-5 text-[#ff5e0e] hover:bg-slate-200"></em>
                                <div className="absolute left-[-90px] top-[10px] px-2 py-1 bg-white rounded-md shadow-md hidden group-hover:block">Duplicate</div>
                            </div>
                            <div className="group relative"
                                onClick={(e)=>{
                                    e.stopPropagation()
                                    mailingListContext.setSelectedMailingList(ml)
                                    setNewMailingList(ml)
                                    setFormOpenFor('Update')
                                    setMailingListFormVisibilityState(true)
                                    setSearchTerm('')
                                }}
                            >
                                <em className="fa-regular fa-pen p-5 text-[#ff5e0e] hover:bg-slate-200"></em>
                                <div className="absolute left-[-50px] top-[10px] px-2 py-1 bg-white rounded-md shadow-md hidden group-hover:block">Edit</div>
                            </div>
                        </div>
                    )) }
                    { searchTerm.length > 0 && (searchResults || []).filter((ml:any)=>{ return ml.name !== 'All Users' && ml.status === true }).map((ml:any, mlIndex:number)=>(
                        <div key={mlIndex} className={`flex items-center border-b cursor-pointer ${(ml?._id === mailingListContext?.selectedMailingList?._id) ? 'bg-orange-100 hover:bg-orange-50' : 'hover:bg-slate-50'}`}
                            onClick={()=>{
                                mailingListContext.setSelectedMailingList(ml)
                                sideDrawerContext.setSecondDrawerChild(
                                    <SecondSideDrawer />
                                )
                                sideDrawerContext.setLevelTwoDrawerVisibility(true)
                            }}
                        >
                            <em className="fa-solid fa-angle-left p-4 text-[#ff5e0e]"></em>
                            <div className="grow flex items-center py-4">
                                <div className="max-w-[200px] mr-2 truncate">{ml?.name || ''}</div>
                                <small className="text-orange-500">({(ml?.users || []).length})</small>
                            </div>
                            <div className="group relative"
                                onClick={(e)=>{
                                    e.stopPropagation()
                                    mailingListContext.setSelectedMailingList({...ml})
                                    setFormOpenFor('Delete')
                                    setChange(uuidv4())
                                    setSearchTerm('')
                                }}
                            >
                                <em className="fa-regular fa-trash p-5 text-[#ff5e0e] hover:bg-slate-200"></em>
                                <div className="absolute left-[-70px] top-[10px] px-2 py-1 bg-white rounded-md shadow-md hidden group-hover:block">Delete</div>
                            </div>
                            <div className="group relative"
                                onClick={(e)=>{
                                    e.stopPropagation()
                                    mailingListContext.setSelectedMailingList(ml)
                                    setNewMailingList({
                                        name: `${ml?.name || ''} Copy`,
                                        users: ml?.users || [],
                                        status: ml?.status || true,
                                        metadata: {
                                            type: 'general',
                                            description: ''
                                        }
                                    })
                                    setFormOpenFor('Add')
                                    setMailingListFormVisibilityState(true)
                                    setSearchTerm('')
                                }}
                            >
                                <em className="fa-regular fa-copy p-5 text-[#ff5e0e] hover:bg-slate-200"></em>
                                <div className="absolute left-[-90px] top-[10px] px-2 py-1 bg-white rounded-md shadow-md hidden group-hover:block">Duplicate</div>
                            </div>
                            <div className="group relative"
                                onClick={(e)=>{
                                    e.stopPropagation()
                                    mailingListContext.setSelectedMailingList(ml)
                                    setNewMailingList(ml)
                                    setFormOpenFor('Add')
                                    setMailingListFormVisibilityState(true)
                                    setSearchTerm('')
                                }}
                            >
                                <em className="fa-regular fa-pen p-5 text-[#ff5e0e] hover:bg-slate-200"></em>
                                <div className="absolute left-[-50px] top-[10px] px-2 py-1 bg-white rounded-md shadow-md hidden group-hover:block">Edit</div>
                            </div>
                        </div>
                    )) }
                </div>
            </div>
            <div className={`data absolute ${mailingListFormVisibilityState ? 'h-[200px]' : 'h-[0px]'} transition-all duration-500 top-[167px] w-full bg-slate-100 shadow-md overflow-hidden`}>
                <div className={`p-6 ${mailingListFormVisibilityState ? 'mt-[0px]' : '-mt-[200px]'} transition-all duration-500`}>
                    <label className="flex flex-col items-start justify-center align-middle">
                        <div className="flex items-center mb-2">
                            <div className="font-semibold mr-2">Mailing List Name</div> <span className="text-red-500">*</span>
                        </div>
                        <div className="w-full h-12 border border-orange-500 rounded-md mb-1">
                            <input type="text" placeholder="Mailing List Name" className="outline-none w-full h-full rounded-md px-4"
                                value={newMailingList?.name || ''}
                                onChange={(e)=>{
                                    setNewMailingListError({ name: null });
                                    setNewMailingList({
                                        ...newMailingList,
                                        name: e?.target?.value || ''
                                    })
                                }}
                            />
                        </div>
                        <small className="text-red-500">{newMailingListError?.name ? newMailingListError?.name : ''}</small>
                    </label>
                </div>
                <div className="flex w-full absolute bottom-0">
                    <div className="w-1/2">
                        <Button size="sm" block={true} solid={true} className="rounded-none"
                            onClick={()=>{
                                if( formOpenFor === 'Add' ) {
                                    mailingListContext.setSelectedMailingList({})
                                    createNewMailingList()
                                } else if( formOpenFor === 'Update' ) {
                                    updateExistingMailingListName()
                                    setFormOpenFor('Add')
                                }
                            }}
                        >{formOpenFor === 'Add' || formOpenFor === 'Delete' ? 'Add' : 'Update'}</Button>
                    </div>
                    <div className="w-1/2">
                        <Button 
                            onClick={()=>{
                                setMailingListFormVisibilityState(false)
                                setFormOpenFor('Add')
                                setNewMailingListError({ name: ''})
                                setNewMailingList({
                                    name: '',
                                    users: [],
                                    status: true,
                                    metadata: {
                                        type: 'general',
                                        description: ''
                                    }
                                });
                                mailingListContext.setSelectedMailingList({})
                            }}
                            size="sm" block={true} solid={false} className="rounded-none bg-white"
                        >Cancel</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

interface ISecondSideDrawer {}

const SecondSideDrawer:FC<ISecondSideDrawer> = ({}) => {
    let theme = useContext(ThemeContext)

    const sideDrawerContext = useContext(SideDrawerContext);
    const mailingListContext = useContext(MailingListContext);
    const {includedMailingUsers, excludedMailingUsers} = useContext(MailingListContext);
    
    const [pageSize, setPageSize] = useState<number[]>([0, 0]);
    const [firstSlider, setFirstSlider] = useState(0);
    const [openForm, setOpenForm] = useState<boolean>(false);

    const [ loader, setLoader ] = useState<boolean>(false)
    const [ newEmail, setNewEmail ] = useState<string>('')
    const [ newEmailError, setNewEmailError ] = useState<any>({ email: '' })

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchResult, setSearchResult] = useState<string[]>([]);

    const [searchTerm2, setSearchTerm2] = useState<string>('');
    const [searchResult2, setSearchResult2] = useState<string[]>([]);

    useLayoutEffect(() => {
        function updateSize() {
            setPageSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const getAllMailingLists = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/app/mailinglists`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const createMailingList = async (data:any) => {
        try {
            const response = await postAPICall(`${process.env.REACT_APP_API_URL}/app/mailinglists`,{ insert: {...data}});
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const updateMailingList = async (mailingListId:string, data:any) => {
        try {
            const response = await putAPICall(`${process.env.REACT_APP_API_URL}/app/mailinglists/${mailingListId}`,{ updates: {...data}});
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const validateNewEmailAddressToAdd = () => {

        if( newEmail === '' || newEmail === undefined || newEmail === null ) {
            setNewEmailError({ email: 'Required field' })
            return false
        } else {
            if( !/^(.*?)@(.*?)\.([a-zA-Z]{2,5})$/.test(newEmail) ) {
                setNewEmailError({ email: 'Please enter a valid email address' })
                return false
            } else if((mailingListContext?.allMailingUsers || []).includes( newEmail.trim().toLowerCase() )) {
                setNewEmailError({ email: `Email '${newEmail.trim().toLowerCase()}' already exists` })
                return false
            } else {
                return true
            }
        }
    }

    const addNewEmailAddressToMailingList = async () => {
        if( !validateNewEmailAddressToAdd() ) { return }

        setLoader(true)
        // console.log(mailingListContext?.selectedMailingList?.users)
        let updatedMailingList = {
            name: mailingListContext?.selectedMailingList?.name,
            users: [...mailingListContext?.selectedMailingList?.users, newEmail],
            status: mailingListContext?.selectedMailingList?.status || true,
            metadata: mailingListContext?.selectedMailingList?.metadata
        }

        let response = await updateMailingList(mailingListContext?.selectedMailingList?._id, updatedMailingList)

        let allUsersMailingList = null;
        
        if( mailingListContext?.selectedMailingList?.name !== 'All Users' ) {
            allUsersMailingList = (mailingListContext?.allMailingList || []).find((ml:any)=>{ return ml?.name === 'All Users' })

            await updateMailingList(allUsersMailingList?._id, {
                name: allUsersMailingList?.name,
                users: [...allUsersMailingList?.users, newEmail],
                status: allUsersMailingList?.status || true,
                metadata: allUsersMailingList?.metadata
            })
        }
        
        
        if( response?.mailinglist?.acknowledged === true ) {

            let copyOfAllMailingLists: any[] = [...mailingListContext?.allMailingList]
            let copyOfSelectedMailingList: any = {...mailingListContext?.selectedMailingList}
            let selectedMailingListIndex: number = -1

            for (let i = 0; i < mailingListContext.allMailingList.length; i++) {
                const mailingList = mailingListContext.allMailingList[i];
                if( mailingList?._id === copyOfSelectedMailingList?._id ) {
                    selectedMailingListIndex = i
                }
            }

            copyOfAllMailingLists[selectedMailingListIndex] = {
                ...copyOfSelectedMailingList,
                users: [...mailingListContext?.selectedMailingList?.users, newEmail]
            }

            if( mailingListContext?.selectedMailingList?.name !== 'All Users' ) {
                let allUsersMailingListIndex: number = -1

                for (let i = 0; i < mailingListContext.allMailingList.length; i++) {
                    const mailingList = mailingListContext.allMailingList[i];
                    if( mailingList?._id === allUsersMailingList?._id ) {
                        allUsersMailingListIndex = i
                    }
                }

                copyOfAllMailingLists[allUsersMailingListIndex] = {
                    ...allUsersMailingList,
                    users: [...allUsersMailingList?.users, newEmail]
                }
            }

            mailingListContext.setAllMailingList( (previousState:any) =>{
                return copyOfAllMailingLists
            } )
            mailingListContext.setSelectedMailingList({
                ...copyOfSelectedMailingList,
                users: [...mailingListContext?.selectedMailingList?.users, newEmail]
            })
            
            toast.success('Email added successfully')
            setOpenForm(false)
            setNewEmail('')
            setNewEmailError({ email: '' })
            setLoader(false)
        } else {
            toast.warning('Something went wrong')
            setLoader(false)
        }

    }

    const excludeEmailFromMailingList = async (emailToExclude:string) => {
        let selectedML = {...mailingListContext?.selectedMailingList}

        
        let newMailingList = (selectedML?.users || [])
        let indexOfEmailToDelete = [...newMailingList].indexOf(emailToExclude)
        if( indexOfEmailToDelete !== -1 ) {

            newMailingList.splice(indexOfEmailToDelete,1)
    
            let updatedMailingList = {
                name: selectedML.name,
                users: newMailingList,
                status: selectedML.status || true,
                metadata: selectedML.metadata
            }

            // console.log({selectedML, updatedMailingList})

            let response = await updateMailingList(selectedML._id, updatedMailingList)
            if( response?.mailinglist?.acknowledged === true ) {

                let copyOfAllMailingLists: any[] = [...mailingListContext?.allMailingList]
                let selectedMailingListIndex: number = -1
    
                for (let i = 0; i < mailingListContext.allMailingList.length; i++) {
                    const mailingList = mailingListContext.allMailingList[i];
                    if( mailingList?._id === selectedML?._id ) {
                        selectedMailingListIndex = i
                    }
                }
    
                copyOfAllMailingLists[selectedMailingListIndex] = {
                    ...selectedML,
                    users: newMailingList
                }
    
                mailingListContext.setAllMailingList( (previousState:any) =>{
                    return copyOfAllMailingLists
                } )
                mailingListContext.setSelectedMailingList({
                    ...selectedML,
                    users: newMailingList
                })
                
                toast.success('Email excluded successfully')
                setOpenForm(false)
                setNewEmail('')
                setNewEmailError({ email: '' })
                setLoader(false)
            } else {
                toast.warning('Something went wrong')
                setLoader(false)
            }
            
        } 

    }

    const includeEmailToMailingList = async (emailToInclude:string) => {

        let updatedMailingList = {
            name: mailingListContext?.selectedMailingList?.name,
            users: [...mailingListContext?.selectedMailingList?.users, emailToInclude],
            status: mailingListContext?.selectedMailingList?.status,
            metadata: mailingListContext?.selectedMailingList?.metadata
        }

        let response = await updateMailingList(mailingListContext?.selectedMailingList?._id, updatedMailingList)

        
        if( response?.mailinglist?.acknowledged === true ) {

            let copyOfAllMailingLists: any[] = [...mailingListContext?.allMailingList]
            let copyOfSelectedMailingList: any = {...mailingListContext?.selectedMailingList}
            let selectedMailingListIndex: number = -1

            for (let i = 0; i < mailingListContext.allMailingList.length; i++) {
                const mailingList = mailingListContext.allMailingList[i];
                if( mailingList?._id === copyOfSelectedMailingList?._id ) {
                    selectedMailingListIndex = i
                }
            }

            copyOfAllMailingLists[selectedMailingListIndex] = {
                ...copyOfSelectedMailingList,
                users: [...mailingListContext?.selectedMailingList?.users, emailToInclude]
            }

            mailingListContext.setAllMailingList( (previousState:any) =>{
                return copyOfAllMailingLists
            } )

            mailingListContext.setSelectedMailingList({
                ...copyOfSelectedMailingList,
                users: [...mailingListContext?.selectedMailingList?.users, emailToInclude]
            })
            
            toast.success('Email included successfully')
            setOpenForm(false)
            setNewEmail('')
            setNewEmailError({ email: '' })
            setLoader(false)
        } else {
            toast.warning('Something went wrong')
            setLoader(false)
        }
    }

    const excludeAllEmailFromMailingList = async () => {
        let selectedML = {...mailingListContext?.selectedMailingList}
    
        let updatedMailingList = {
            name: selectedML.name,
            users: [],
            status: selectedML.status || true,
            metadata: selectedML.metadata
        }

        // console.log({selectedML, updatedMailingList})

        let response = await updateMailingList(selectedML._id, updatedMailingList)
        if( response?.mailinglist?.acknowledged === true ) {

            let copyOfAllMailingLists: any[] = [...mailingListContext?.allMailingList]
            let selectedMailingListIndex: number = -1

            for (let i = 0; i < mailingListContext.allMailingList.length; i++) {
                const mailingList = mailingListContext.allMailingList[i];
                if( mailingList?._id === selectedML?._id ) {
                    selectedMailingListIndex = i
                }
            }

            copyOfAllMailingLists[selectedMailingListIndex] = {
                ...selectedML,
                users: []
            }

            mailingListContext.setAllMailingList( (previousState:any) =>{
                return copyOfAllMailingLists
            } )
            mailingListContext.setSelectedMailingList({
                ...selectedML,
                users: []
            })
            
            toast.success('All email excluded successfully')
            setOpenForm(false)
            setNewEmail('')
            setNewEmailError({ email: '' })
            setLoader(false)
        } else {
            toast.warning('Something went wrong')
            setLoader(false)
        }
    }

    const includeAllEmailFromMailingList = async () => {
        let selectedML = {...mailingListContext?.selectedMailingList}

        let updatedEmailList = [...selectedML?.users, ...mailingListContext?.excludedMailingUsers]

        let arrUniqueAllUsers = updatedEmailList.filter(function(item, pos) {
            return updatedEmailList.indexOf(item) === pos;
        })
    
        let updatedMailingList = {
            name: selectedML.name,
            users: arrUniqueAllUsers,
            status: selectedML.status || true,
            metadata: selectedML.metadata
        }

        let response = await updateMailingList(selectedML._id, updatedMailingList)
        if( response?.mailinglist?.acknowledged === true ) {

            let copyOfAllMailingLists: any[] = [...mailingListContext?.allMailingList]
            let selectedMailingListIndex: number = -1

            for (let i = 0; i < mailingListContext.allMailingList.length; i++) {
                const mailingList = mailingListContext.allMailingList[i];
                if( mailingList?._id === selectedML?._id ) {
                    selectedMailingListIndex = i
                }
            }

            copyOfAllMailingLists[selectedMailingListIndex] = {
                ...selectedML,
                users: arrUniqueAllUsers
            }

            mailingListContext.setAllMailingList( (previousState:any) =>{
                return copyOfAllMailingLists
            } )
            mailingListContext.setSelectedMailingList({
                ...selectedML,
                users: arrUniqueAllUsers
            })
            
            toast.success('All email included successfully')
            setOpenForm(false)
            setNewEmail('')
            setNewEmailError({ email: '' })
            setLoader(false)
        } else {
            toast.warning('Something went wrong')
            setLoader(false)
        }
    }

    const deleteEmailFromAllUsersMailingList = async (emailToDelete:string) => {

        // before deleting, check if entry is not present in any other mailing list
        let arrAvailableEmails: string[] = []
        mailingListContext.allMailingList.filter((ml:any)=>{ return ml.name !== 'All Users' && ml.name !== 'Deleted Users' && ml.status !== false }).map((ml:any)=>{ 
            arrAvailableEmails = [...arrAvailableEmails, ...ml?.users]
        })

        let arrUniqueAvailableEmails = arrAvailableEmails.filter(function(item, pos) {
            return arrAvailableEmails.indexOf(item) === pos;
        })

        if( arrUniqueAvailableEmails.includes( emailToDelete ) ) {
            toast.info(`Can't delete the email present in other mailing list(s)`)
            return
        }

        let allUsersML:any = {...mailingListContext?.selectedMailingList}
        let deletedUserML:any = mailingListContext.allMailingList.find((ml:any)=>{ return ml.name === 'Deleted Users' })

        // console.log({emailToDelete,allUsersML,deletedUserML})

        
        let newMailingList = (allUsersML?.users || [])
        let indexOfEmailToDelete = [...newMailingList].indexOf(emailToDelete)
        if( indexOfEmailToDelete !== -1 ) {

            newMailingList.splice(indexOfEmailToDelete,1)
    
            let updatedMailingList = {
                name: 'All Users',
                users: newMailingList,
                status: true,
                metadata: allUsersML.metadata
            }

            let response = await updateMailingList(allUsersML._id, updatedMailingList)


    
            let updatedDeletedUsersMailingList = {
                name: 'Deleted Users',
                users: [...deletedUserML?.users, emailToDelete],
                status: false,
                metadata: allUsersML.metadata
            }

            await updateMailingList(deletedUserML._id, updatedDeletedUsersMailingList)

            if( response?.mailinglist?.acknowledged === true ) {

                let copyOfAllMailingLists: any[] = [...mailingListContext?.allMailingList]
                let allUserMailingListIndex: number = -1
                let deletedMailingListIndex: number = -1
    
                for (let i = 0; i < mailingListContext.allMailingList.length; i++) {
                    const mailingList = mailingListContext.allMailingList[i];
                    if( mailingList?.name === 'All Users' ) {
                        allUserMailingListIndex = i
                    }
                }

                for (let i = 0; i < mailingListContext.allMailingList.length; i++) {
                    const mailingList = mailingListContext.allMailingList[i];
                    if( mailingList?.name === 'Deleted Users' ) {
                        deletedMailingListIndex = i
                    }
                }
    
                copyOfAllMailingLists[allUserMailingListIndex] = {
                    ...allUsersML,
                    users: newMailingList
                }
    
                copyOfAllMailingLists[deletedMailingListIndex] = {
                    ...deletedUserML,
                    users: [...deletedUserML?.users, emailToDelete]
                }
    
                mailingListContext.setAllMailingList( (previousState:any) =>{
                    return copyOfAllMailingLists
                } )
                mailingListContext.setSelectedMailingList({
                    ...allUsersML,
                    users: newMailingList
                })
                
                toast.success('Email deleted successfully')
                setOpenForm(false)
                setNewEmail('')
                setNewEmailError({ email: '' })
                setLoader(false)
            } else {
                toast.warning('Something went wrong')
                setLoader(false)
            }
            
        } 
    }

    const restoreEmailToAllUsersMailingList = async (emailToRestore:string) => {
        // before deleting, check if entry is not present in any other mailing list

        let allUsersML:any = mailingListContext.allMailingList.find((ml:any)=>{ return ml.name === 'All Users' })
        let deletedUserML:any = mailingListContext.allMailingList.find((ml:any)=>{ return ml.name === 'Deleted Users' })


        let newMailingList = (deletedUserML?.users || [])
        let indexOfEmailToDelete = [...newMailingList].indexOf(emailToRestore)
        if( indexOfEmailToDelete !== -1 ) {

            newMailingList.splice(indexOfEmailToDelete,1)

            let updatedMailingList = {
                name: 'Deleted Users',
                users: newMailingList,
                status: false,
                metadata: allUsersML.metadata
            }

            let response = await updateMailingList(deletedUserML._id, updatedMailingList)


            let updatedAllUsersMailingList = {
                name: 'All Users',
                users: [...allUsersML?.users, emailToRestore],
                status: true,
                metadata: allUsersML.metadata
            }

            await updateMailingList(allUsersML._id, updatedAllUsersMailingList)

            if( response?.mailinglist?.acknowledged === true ) {

                let copyOfAllMailingLists: any[] = [...mailingListContext?.allMailingList]
                let deletedMailingListIndex: number = -1
                let allUserMailingListIndex: number = -1

                for (let i = 0; i < mailingListContext.allMailingList.length; i++) {
                    const mailingList = mailingListContext.allMailingList[i];
                    if( mailingList?.name === 'Deleted Users' ) {
                        deletedMailingListIndex = i
                    }
                }

                for (let i = 0; i < mailingListContext.allMailingList.length; i++) {
                    const mailingList = mailingListContext.allMailingList[i];
                    if( mailingList?.name === 'All Users' ) {
                        allUserMailingListIndex = i
                    }
                }

                copyOfAllMailingLists[deletedMailingListIndex] = {
                    ...deletedUserML,
                    users: newMailingList
                }

                copyOfAllMailingLists[allUserMailingListIndex] = {
                    ...allUsersML,
                    users: [...allUsersML?.users, emailToRestore]
                }

                mailingListContext.setAllMailingList( (previousState:any) =>{
                    return copyOfAllMailingLists
                } )
                mailingListContext.setSelectedMailingList({
                    ...allUsersML,
                    // users: newMailingList
                    users: [...allUsersML?.users, emailToRestore]
                })
                
                toast.success('Email restored successfully')
                setOpenForm(false)
                setNewEmail('')
                setNewEmailError({ email: '' })
                setLoader(false)
            } else {
                toast.warning('Something went wrong')
                setLoader(false)
            }
            
        } 
    }

    const deleteUnusedEmailFromDeletedUsersMailingList = async () => {

        // console.log({context: mailingListContext})

        // before deleting, check if entry is not present in any other mailing list
        let arrAvailableEmails: string[] = []
        mailingListContext.allMailingList.filter((ml:any)=>{ return ml.name !== 'All Users' && ml.name !== 'Deleted Users' && ml.status !==false }).map((ml:any)=>{ 
            arrAvailableEmails = [...arrAvailableEmails, ...ml?.users]
        })

        let arrUniqueAvailableEmails = arrAvailableEmails.filter(function(item, pos) {
            return arrAvailableEmails.indexOf(item) === pos;
        })

        let arrAllUsersEmails: string[] = []

        let allUsersMailingList = (mailingListContext?.allMailingList || []).find((ml:any)=>{ return ml.name === 'All Users' })
        arrAllUsersEmails = [...allUsersMailingList?.users]

        let arrEmailsToDeleteFromAllUsers: string[] = []
        let arrEmailsToKeepInAllUsers: string[] = []

        for (let i = 0; i < arrAllUsersEmails.length; i++) {
            const email = arrAllUsersEmails[i];

            if(!arrUniqueAvailableEmails.includes(email)) {
                arrEmailsToDeleteFromAllUsers.push(email)
            } else {
                arrEmailsToKeepInAllUsers.push(email)
            }
            
        }

        let arrDeletedUsersEmails: string[] = []

        let deletedUsersMailingList = (mailingListContext?.allMailingList || []).find((ml:any)=>{ return ml.name === 'Deleted Users' })
        arrDeletedUsersEmails = [...deletedUsersMailingList?.users]

        let arrUpdatedDeletedUsersEmails: string[] = [...arrDeletedUsersEmails, ...arrEmailsToDeleteFromAllUsers]

        // console.log({arrUniqueAvailableEmails, arrAllUsersEmails, arrEmailsToDeleteFromAllUsers, arrEmailsToKeepInAllUsers, arrDeletedUsersEmails, arrUpdatedDeletedUsersEmails})
        console.log({arrEmailsToDeleteFromAllUsers, arrEmailsToKeepInAllUsers})


        // updated deleted users mailing list

        let updatedDeletedUsersMailingList = {
            name: 'Deleted Users',
            users: arrUpdatedDeletedUsersEmails,
            status: false,
            metadata: deletedUsersMailingList?.metadata
        }

        let response1 = await updateMailingList(deletedUsersMailingList._id, updatedDeletedUsersMailingList)

        let updatedAllUsersMailingList = {
            name: 'All Users',
            users: arrEmailsToKeepInAllUsers,
            status: true,
            metadata: allUsersMailingList?.metadata
        }

        let response2 = await updateMailingList(allUsersMailingList._id, updatedAllUsersMailingList)


        if( response1?.mailinglist?.acknowledged === true && response2?.mailinglist?.acknowledged === true ) {

            let copyOfAllMailingLists: any[] = [...mailingListContext?.allMailingList]
            let allUserMailingListIndex: number = -1
            let deletedMailingListIndex: number = -1

            for (let i = 0; i < mailingListContext.allMailingList.length; i++) {
                const mailingList = mailingListContext.allMailingList[i];
                if( mailingList?.name === 'All Users' ) {
                    allUserMailingListIndex = i
                } else if(mailingList?.name === 'Deleted Users') {
                    deletedMailingListIndex = i
                }
            }

            copyOfAllMailingLists[allUserMailingListIndex] = {
                ...copyOfAllMailingLists[allUserMailingListIndex],
                ...updatedAllUsersMailingList
            }

            copyOfAllMailingLists[deletedMailingListIndex] = {
                ...copyOfAllMailingLists[deletedMailingListIndex],
                ...updatedDeletedUsersMailingList
            }

            mailingListContext.setAllMailingList((previousState:any)=>{
                return copyOfAllMailingLists
            })

            mailingListContext.setAllMailingUsers((previousState:any)=>{
                return arrEmailsToKeepInAllUsers
            })

            mailingListContext.setDeletedMailingUsers((previousState:any)=>{
                return arrUpdatedDeletedUsersEmails
            })

            mailingListContext.setSelectedMailingList((previousState:any)=>{
                return copyOfAllMailingLists[allUserMailingListIndex]
            })

            toast.success('Unused email(s) deleted successfully')
            setOpenForm(false)
            setNewEmail('')
            setNewEmailError({ email: '' })
            setLoader(false)

        } else {
            toast.info('Something went wrong');
        }
    }

    const restoreAllEmailsFromDeletedUsersMailingList = async () => {


        let allUsersMailingList = (mailingListContext?.allMailingList || []).find((ml:any)=>{ return ml.name === 'All Users' })
        let deletedUsersMailingList = (mailingListContext?.allMailingList || []).find((ml:any)=>{ return ml.name === 'Deleted Users' })


        let updatedDeletedUsersMailingList = {
            name: 'Deleted Users',
            users: [],
            status: false,
            metadata: deletedUsersMailingList?.metadata
        }

        let response1 = await updateMailingList(deletedUsersMailingList._id, updatedDeletedUsersMailingList)

        let updatedAllUsersMailingList = {
            name: 'All Users',
            users: [...allUsersMailingList?.users, ...deletedUsersMailingList?.users],
            status: true,
            metadata: allUsersMailingList?.metadata
        }

        let response2 = await updateMailingList(allUsersMailingList._id, updatedAllUsersMailingList)


        if( response1?.mailinglist?.acknowledged === true && response2?.mailinglist?.acknowledged === true ) {

            let copyOfAllMailingLists: any[] = [...mailingListContext?.allMailingList]
            let allUserMailingListIndex: number = -1
            let deletedMailingListIndex: number = -1

            for (let i = 0; i < mailingListContext.allMailingList.length; i++) {
                const mailingList = mailingListContext.allMailingList[i];
                if( mailingList?.name === 'All Users' ) {
                    allUserMailingListIndex = i
                } else if(mailingList?.name === 'Deleted Users') {
                    deletedMailingListIndex = i
                }
            }

            copyOfAllMailingLists[allUserMailingListIndex] = {
                ...copyOfAllMailingLists[allUserMailingListIndex],
                ...updatedAllUsersMailingList
            }

            copyOfAllMailingLists[deletedMailingListIndex] = {
                ...copyOfAllMailingLists[deletedMailingListIndex],
                ...updatedDeletedUsersMailingList
            }

            mailingListContext.setAllMailingList((previousState:any)=>{
                return copyOfAllMailingLists
            })

            mailingListContext.setAllMailingUsers((previousState:any)=>{
                return [...allUsersMailingList?.users, ...deletedUsersMailingList?.users]
            })

            mailingListContext.setDeletedMailingUsers((previousState:any)=>{
                return []
            })

            mailingListContext.setSelectedMailingList((previousState:any)=>{
                return copyOfAllMailingLists[allUserMailingListIndex]
            })

            toast.success('Restored all email(s) successfully')
            setOpenForm(false)
            setNewEmail('')
            setNewEmailError({ email: '' })
            setLoader(false)

        } else {
            toast.info('Something went wrong');
        }
    }

    const permanentlyDeleteAllEmailsFromDeletedUsersMailingList = async () => {


        // let allUsersMailingList = (mailingListContext?.allMailingList || []).find((ml:any)=>{ return ml.name === 'All Users' })
        let deletedUsersMailingList = (mailingListContext?.allMailingList || []).find((ml:any)=>{ return ml.name === 'Deleted Users' })


        let updatedDeletedUsersMailingList = {
            name: 'Deleted Users',
            users: [],
            status: false,
            metadata: deletedUsersMailingList?.metadata
        }

        let response = await updateMailingList(deletedUsersMailingList._id, updatedDeletedUsersMailingList)


        if( response?.mailinglist?.acknowledged === true ) {

            let copyOfAllMailingLists: any[] = [...mailingListContext?.allMailingList]
            let deletedMailingListIndex: number = -1

            for (let i = 0; i < mailingListContext.allMailingList.length; i++) {
                const mailingList = mailingListContext.allMailingList[i];
                if(mailingList?.name === 'Deleted Users') {
                    deletedMailingListIndex = i
                }
            }

            copyOfAllMailingLists[deletedMailingListIndex] = {
                ...copyOfAllMailingLists[deletedMailingListIndex],
                ...updatedDeletedUsersMailingList
            }

            mailingListContext.setAllMailingList((previousState:any)=>{
                return copyOfAllMailingLists
            })

            mailingListContext.setDeletedMailingUsers((previousState:any)=>{
                return []
            })

            // mailingListContext.setSelectedMailingList((previousState:any)=>{
            //     return copyOfAllMailingLists[deletedMailingListIndex]
            // })

            toast.success('Deleted all email(s) successfully')
            setOpenForm(false)
            setNewEmail('')
            setNewEmailError({ email: '' })
            setLoader(false)

        } else {
            toast.info('Something went wrong');
        }
    }

    const permanentlyDeleteEmailFromDeletedUsersMailingList = async (emailToDelete:string) => {


        let allUsersML:any = {...mailingListContext?.selectedMailingList}
        let deletedUserML:any = mailingListContext.allMailingList.find((ml:any)=>{ return ml.name === 'Deleted Users' });

        let existingDeletedUsersEmails = [...deletedUserML?.users]

        let indexToDelete = existingDeletedUsersEmails.indexOf(emailToDelete)

        if( indexToDelete !== -1 ) {

            existingDeletedUsersEmails.splice(indexToDelete,1)

            let updatedDeletedUsersEmail = [...existingDeletedUsersEmails]

            let updatedDeletedUsersMailingList = {
                name: 'Deleted Users',
                users: updatedDeletedUsersEmail,
                status: false,
                metadata: deletedUserML?.metadata
            }
    
            let response = await updateMailingList(deletedUserML._id, updatedDeletedUsersMailingList)

            if( response?.mailinglist?.acknowledged === true ) {

                let copyOfAllMailingLists: any[] = [...mailingListContext?.allMailingList]
                let deletedMailingListIndex: number = -1
    
                for (let i = 0; i < mailingListContext.allMailingList.length; i++) {
                    const mailingList = mailingListContext.allMailingList[i];
                    if(mailingList?.name === 'Deleted Users') {
                        deletedMailingListIndex = i
                    }
                }
    
                copyOfAllMailingLists[deletedMailingListIndex] = {
                    ...copyOfAllMailingLists[deletedMailingListIndex],
                    ...updatedDeletedUsersMailingList
                }
    
                mailingListContext.setAllMailingList((previousState:any)=>{
                    return copyOfAllMailingLists
                })
    
                mailingListContext.setDeletedMailingUsers((previousState:any)=>{
                    return updatedDeletedUsersEmail
                })
    
                toast.success('Permanently deleted')
                setOpenForm(false)
                setNewEmail('')
                setNewEmailError({ email: '' })
                setLoader(false)
    
            } else {
                toast.warning('Something went wrong');
            }

        } else {
            toast.info('Not found');
        }
    }

    const searchHandler = () => {
        if( searchTerm.trim() !== '' ) {

            let allEmails:string[] = [...mailingListContext?.selectedMailingList?.users];

            let tempSearchResults:string[] = allEmails.filter((email:string)=>{
                if(email.toLowerCase().includes( searchTerm.toLowerCase().trim() )) {
                    return true
                } else {
                    return false
                }
            })

            setSearchResult((previous:any)=>{
                return tempSearchResults;
            })
        }
    }

    // const searchHandler2 = () => {

    //     console.log({mailingListContext})


    //     if( mailingListContext?.selectedMailingList?.name === 'All Users' ) {

    //         let deletedUsersMailingListEmails:any = (mailingListContext?.allMailingList || []).find((ml:any)=>{ return ml?.name === 'Deleted Users' })
    //         let allEmails: string[] = [...deletedUsersMailingListEmails?.users];
    //         let tempSearchResults:string[] = allEmails.filter((email:string)=>{
    //             if(email.toLowerCase().includes( searchTerm2.toLowerCase().trim() )) {
    //                 return true
    //             } else {
    //                 return false
    //             }
    //         })

    //         console.log({allEmails, tempSearchResults})

    //         setSearchResult2((previous:any)=>{
    //             return tempSearchResults;
    //         })

    //     } else {
    //         let availableEmails:any = (mailingListContext?.allMailingList || []).find((ml:any)=>{ return ml?.name !== 'All Users' && ml?.name !== 'Deleted Users' && ml?.status !== false })

    //         let arrUniqueAvailableEmails: string[] = (availableEmails?.users || []).filter(function(item:string, pos:number) {
    //             return availableEmails.indexOf(item) === pos;
    //         })

    //         let arrEmailsToSearchFrom : string[] = []

    //         for (let i = 0; i < arrUniqueAvailableEmails.length; i++) {
    //             const email = arrUniqueAvailableEmails[i];

    //             let arrSelectedML: string[] = [...mailingListContext?.selectedMailingList?.users]

    //             if( !arrSelectedML.includes(email) ) {
    //                 arrEmailsToSearchFrom.push(email)
    //             }
                
    //         }

    //         let tempSearchResults:string[] = arrEmailsToSearchFrom.filter((email:string)=>{
    //             if(email.toLowerCase().includes( searchTerm2.toLowerCase().trim() )) {
    //                 return true
    //             } else {
    //                 return false
    //             }
    //         })

    //         console.log({arrUniqueAvailableEmails, arrEmailsToSearchFrom, tempSearchResults})

    //         setSearchResult2((previous:any)=>{
    //             return tempSearchResults;
    //         })
    //     }

    //     // if( searchTerm.trim() !== '' ) {

    //     //     let allEmails:string[] = [...mailingListContext?.selectedMailingList?.users];

    //     //     let tempSearchResults:string[] = allEmails.filter((email:string)=>{
    //     //         if(email.toLowerCase().includes( searchTerm.toLowerCase().trim() )) {
    //     //             return true
    //     //         } else {
    //     //             return false
    //     //         }
    //     //     })

    //     //     setSearchResult((previous:any)=>{
    //     //         return tempSearchResults;
    //     //     })
    //     // }
    // }


    useEffect(()=>{
        searchHandler()
    },[searchTerm])

    // useEffect(()=>{
    //     searchHandler2()
    // },[searchTerm2])

    useEffect(()=>{
        if( Object.keys(mailingListContext.selectedMailingList).length < 1 ) {
            sideDrawerContext.setLevelTwoDrawerVisibility(false)
        }
    },[ mailingListContext ])


    return (
        <>
            <div className={`relative flex flex-col z-0 h-full overflow-hidden`}>
                <div className={`tabs flex w-full mb-4`}>
                    <div className={`tab group flex flex-col gap-2 w-1/2 h-32 rounded-md ${firstSlider===0 ? `border-b-4 border-b-${theme.themeColor}-${theme.themeContrast}-primary-default` : '' }  ${theme.themeContrast === 'light' ? 'hover:bg-slate-100' : 'hover:bg-[#444444]'}  cursor-pointer justify-center align-middle items-center`}
                        onClick={()=>{
                            setFirstSlider(0)
                        }}
                    >
                        <em className={`fa-solid ${mailingListContext?.selectedMailingList?.name === 'All Users' ? 'fa-users' : 'fa-user-plus'} text-3xl text-${theme.themeColor}-${theme.themeContrast}-primary-default`}></em>
                        <div className={`block text-${theme.themeColor}-${theme.themeContrast}-primary-default`}>{ mailingListContext?.selectedMailingList?.name === 'All Users' ? 'All Users' : 'Included Users'} ({ (mailingListContext?.selectedMailingList?.users || []).length})</div>
                    </div>
                    <div className={`tab group flex flex-col gap-2 w-1/2 h-32 rounded-md ${(firstSlider===-640 || firstSlider===-440) ? `border-b-4 border-b-${theme.themeColor}-${theme.themeContrast}-primary-default` : '' } ${theme.themeContrast === 'light' ? 'hover:bg-slate-100' : 'hover:bg-[#444444]'} cursor-pointer justify-center align-middle items-center`}
                        onClick={()=>{
                            if(pageSize[0] <= 768) {
                                setFirstSlider(-440)
                            } else {
                                setFirstSlider(-640)
                            }
                        }}
                    >
                        <em className={`fa-solid ${mailingListContext?.selectedMailingList?.name === 'All Users' ? 'fa-trash' : 'fa-user-minus' } text-3xl text-${theme.themeColor}-${theme.themeContrast}-primary-default`}></em>
                        <div className={`block text-${theme.themeColor}-${theme.themeContrast}-primary-default`}>{ mailingListContext?.selectedMailingList?.name === 'All Users' ? 'Deleted Users' : 'Excluded Users'} ({mailingListContext?.selectedMailingList?.name === 'All Users' ? (mailingListContext?.deletedMailingUsers).length : ((mailingListContext?.allMailingList).find((ml:any)=>{ return ml.name === 'All Users' })?.users || []).length - (mailingListContext?.selectedMailingList?.users || []).length })</div>
                    </div>
                </div>
                <div className={`tabs-content-group flex relative transition-[margin] duration-200 ease-in-out ${firstSlider === 0 && 'ml-[0px]'} ${firstSlider===-440 && 'ml-[-440px]'} ${firstSlider===-640 && 'ml-[-640px]'} w-[880px] md:w-[1280px] grow`}>
                    <div className={`tab-content flex flex-col w-[440px] md:w-[640px] h-[80%]`}>
                        <div className="flex relative p-4 border-y font-semibold bg-gray-100">
                            <div className="flex items-center">
                                <div className="text-[#FF5E0E] mr-2">Included Users</div>
                                <div className="text-xs font-light text-black">({mailingListContext?.selectedMailingList?.name})</div>
                            </div>
                            <span className="grow"></span>
                            <Button solid={false} size="xs" className="mr-2"
                                onClick={()=>{
                                    setOpenForm(true)
                                }}
                            >Add Users</Button>
                            {mailingListContext?.selectedMailingList?.name === 'All Users' && <Button solid={false} size="xs"
                                onClick={()=>{
                                    deleteUnusedEmailFromDeletedUsersMailingList()
                                }}
                            >Delete unused emails</Button>}
                            {mailingListContext?.selectedMailingList?.name !== 'All Users' && <Button solid={false} size="xs"
                                onClick={()=>{
                                    excludeAllEmailFromMailingList()
                                }}
                            >Exclude All Users</Button>}
                            <div className={`absolute left-0 top-full w-full ${ openForm ? 'h-[200px]' : 'h-[0px]' } transition-all duration-500 border-t border-slate-300 bg-slate-100 shadow-md overflow-hidden z-[10]`}>
                                <div className={`w-full p-6 ${ openForm ? 'mt-[0px]' : 'mt-[-200px]' } transition-all duration-500`}>
                                    <label className="flex flex-col items-start justify-center align-middle">
                                        <div className="flex items-center mb-2">
                                            <div className="font-semibold mr-2">Email Address</div> <span className="text-red-500">*</span>
                                        </div>
                                        <div className="w-full h-12 border border-orange-500 rounded-md mb-1">
                                            <input type="email" placeholder="Email Address" className="outline-none w-full h-full rounded-md px-4"
                                                value={newEmail}
                                                onChange={(e)=>{
                                                    setNewEmailError({email: ''})
                                                    setNewEmail( (e?.target?.value || '').toLowerCase() )
                                                }}
                                            />
                                        </div>
                                        <small className="text-red-500">{newEmailError?.email ? newEmailError?.email : '' }</small>
                                    </label>
                                </div>
                                <div className="absolute flex items-center justify-center align-middle bottom-0 left-0 right-0 h-[30px] w-full border border-orange-500">
                                    <div className="flex items-center justify-center align-middle w-1/2 h-full text-center cursor-pointer hover:bg-orange-600 bg-orange-500 text-white"
                                        onClick={()=>{
                                            addNewEmailAddressToMailingList()
                                        }}
                                    >Add</div>
                                    <div className="flex items-center justify-center align-middle w-1/2 h-full text-center cursor-pointer hover:bg-orange-50 bg-white text-orange-500"
                                        onClick={()=>{
                                            setNewEmailError({email: ''})
                                            setNewEmail('')
                                            setOpenForm(false)
                                        }}
                                    >Cancel</div>
                                </div>
                            </div>
                        </div>
                        <div className="flex h-[42px] border-b">
                            <input
                                type="text"
                                placeholder="Search..."
                                className="w-full outline-none px-4"
                                onChange={(e)=>{
                                    setSearchTerm(e?.target?.value || '')
                                }}
                            />
                            <div className="">
                                <Button className="rounded-none">
                                    <em className="fa-solid fa-search"></em>
                                </Button>
                            </div>
                        </div>
                        <div className="overflow-y-scroll grow">
                            {
                                searchTerm === '' ?
                                (mailingListContext?.selectedMailingList?.users || []).map((email:string, emailIndex:number)=>(
                                    <div key={emailIndex} className="flex border-b hover:bg-slate-100">
                                        <div className="aspect-square h-[50px] flex items-center justify-center align-middle z-0">
                                            <em className="fa-duotone fa-user text-2xl text-[#FF5E0E] z-0"></em>
                                        </div>
                                        <div className="flex flex-col grow">
                                            <div className="font-semibold">Mail Address</div>
                                            <div className="max-w-[400px]">{email}</div>
                                        </div>
                                        <div className="group relative aspect-square h-[50px] flex items-center justify-center align-middle hover:bg-slate-200 cursor-pointer"
                                            onClick={(e)=>{
                                                e.stopPropagation()
                                                if(  mailingListContext?.selectedMailingList?.name === 'All Users' ) {
                                                    deleteEmailFromAllUsersMailingList(email)
                                                } else {
                                                    excludeEmailFromMailingList(email)
                                                }

                                            }}
                                        >
                                            <em className={`fa-regular ${ mailingListContext?.selectedMailingList?.name === 'All Users' ? 'fa-trash' : 'fa-dash' } text-[#ff5e0e] hover:bg-slate-200`}></em>
                                            <div className="absolute left-[-80px] top-[10px] px-2 py-1 bg-white rounded-md shadow-md hidden group-hover:block">{mailingListContext?.selectedMailingList?.name === 'All Users' ? 'Delete' : 'Exclude'}</div>
                                        </div>
                                        
                                    </div>
                                )) :
                                (searchResult || []).map((email:string, emailIndex:number)=>(
                                    <div key={emailIndex} className="flex border-b hover:bg-slate-100">
                                        <div className="aspect-square h-[50px] flex items-center justify-center align-middle z-0">
                                            <em className="fa-duotone fa-user text-2xl text-[#FF5E0E] z-0"></em>
                                        </div>
                                        <div className="flex flex-col grow">
                                            <div className="font-semibold">Mail Address</div>
                                            <div className="max-w-[400px]">{email}</div>
                                        </div>
                                        <div className="group relative aspect-square h-[50px] flex items-center justify-center align-middle hover:bg-slate-200 cursor-pointer"
                                            onClick={(e)=>{
                                                e.stopPropagation()
                                                if(  mailingListContext?.selectedMailingList?.name === 'All Users' ) {
                                                    deleteEmailFromAllUsersMailingList(email)
                                                } else {
                                                    excludeEmailFromMailingList(email)
                                                }

                                            }}
                                        >
                                            <em className={`fa-regular ${ mailingListContext?.selectedMailingList?.name === 'All Users' ? 'fa-trash' : 'fa-dash' } text-[#ff5e0e] hover:bg-slate-200`}></em>
                                            <div className="absolute left-[-80px] top-[10px] px-2 py-1 bg-white rounded-md shadow-md hidden group-hover:block">{mailingListContext?.selectedMailingList?.name === 'All Users' ? 'Delete' : 'Exclude'}</div>
                                        </div>
                                        
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className={`tab-content flex flex-col w-[440px] md:w-[640px] h-[80%]`}>
                        <div className="flex relative p-4 border-y font-semibold bg-gray-100">
                            <div className="flex items-center">
                                <div className="text-[#FF5E0E] mr-2">Excluded Users</div>
                                <div className="text-xs font-light text-black">({mailingListContext?.selectedMailingList?.name})</div>
                            </div>
                            <span className="grow"></span>
                            {mailingListContext?.selectedMailingList?.name !== 'All Users' && <Button solid={false} size="xs"
                                onClick={()=>{
                                    includeAllEmailFromMailingList()
                                }}
                            >Include All Users</Button>}
                            {mailingListContext?.selectedMailingList?.name == 'All Users' && <Button className="mr-2" solid={false} size="xs"
                                onClick={()=>{
                                    restoreAllEmailsFromDeletedUsersMailingList()
                                }}
                            >Restore All</Button>}
                            {mailingListContext?.selectedMailingList?.name == 'All Users' && <Button solid={false} size="xs"
                                onClick={()=>{
                                    permanentlyDeleteAllEmailsFromDeletedUsersMailingList()
                                }}
                            >Permanently Delete All</Button>}
                            
                        </div>
                        <div className="flex h-[42px] border-b">
                            <input 
                                type="text"
                                placeholder="Search..."
                                className="w-full outline-none px-4"
                                onChange={(e)=>{
                                    setSearchTerm2(e?.target?.value || '')
                                }}
                            />
                            <div className="">
                                <Button className="rounded-none">
                                    <em className="fa-solid fa-search"></em>
                                </Button>
                            </div>
                        </div>
                        <div className="overflow-y-scroll grow customScroll">
                            { mailingListContext?.selectedMailingList?.name === 'All Users' ?
                                (mailingListContext?.deletedMailingUsers || []).map((email:string, emailIndex:number)=>(
                                    <div key={emailIndex} className="flex border-b hover:bg-slate-100 ">
                                        <div className="aspect-square h-[50px] flex items-center justify-center align-middle z-0">
                                            <em className="fa-duotone fa-user text-2xl text-[#FF5E0E] z-0"></em>
                                        </div>
                                        <div className="flex flex-col grow">
                                            <div className="font-semibold">Mail Address</div>
                                            <div className="max-w-[400px]">{email}</div>
                                        </div>
                                        <div className="group relative aspect-square h-[50px] flex items-center justify-center align-middle hover:bg-slate-200 cursor-pointer"
                                            onClick={(e)=>{
                                                e.stopPropagation()
                                                restoreEmailToAllUsersMailingList(email)
                                            }}
                                        >
                                            <em className={`fa-regular fa-reply text-[#ff5e0e] hover:bg-slate-200`}></em>
                                            <div className="absolute left-[-80px] top-[10px] px-2 py-1 bg-white rounded-md shadow-md hidden group-hover:block">Restore</div>
                                        </div>
                                        <div className="group relative aspect-square h-[50px] flex items-center justify-center align-middle hover:bg-slate-200 cursor-pointer"
                                            onClick={(e)=>{
                                                e.stopPropagation()
                                                permanentlyDeleteEmailFromDeletedUsersMailingList(email)
                                            }}
                                        >
                                            <em className={`fa-regular fa-trash text-[#ff5e0e] hover:bg-slate-200`}></em>
                                            <div className="absolute right-[60px] top-[10px] px-2 py-1 bg-white rounded-md shadow-md hidden group-hover:block whitespace-nowrap">Permanently Delete</div>
                                        </div>
                                    </div>
                                )) :
                                (mailingListContext?.excludedMailingUsers || []).map((email:string, emailIndex:number)=>(
                                    <div key={emailIndex} className="flex border-b hover:bg-slate-100">
                                        <div className="aspect-square h-[50px] flex items-center justify-center align-middle z-0">
                                            <em className="fa-duotone fa-user text-2xl text-[#FF5E0E] z-0"></em>
                                        </div>
                                        <div className="flex flex-col grow">
                                            <div className="font-semibold">Mail Address</div>
                                            <div className="max-w-[400px]">{email}</div>
                                        </div>
                                        <div className="group relative aspect-square h-[50px] flex items-center justify-center align-middle hover:bg-slate-200 cursor-pointer"
                                            onClick={(e)=>{
                                                e.stopPropagation()
                                                includeEmailToMailingList(email)
                                            }}
                                        >
                                            <em className={`fa-regular fa-plus text-[#ff5e0e] hover:bg-slate-200`}></em>
                                            <div className="absolute left-[-80px] top-[10px] px-2 py-1 bg-white rounded-md shadow-md hidden group-hover:block">Include</div>
                                        </div>
                                        
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}