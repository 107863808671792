import React, { FC, ReactNode, ComponentProps, useContext } from "react";
import { SideDrawerContext, ThemeContext } from "../../../contexts";

interface IDrawerProps extends ComponentProps<"div"> {
    /**
     * Child node(s) of button
     */
    children?: ReactNode | ReactNode[] | any;
}

/**
 * Button Component
 * @param param0
 * @returns 
 */
const SideDrawer:FC<IDrawerProps> = ({
    children=<></>,
    // ...rest
}: IDrawerProps) => {

    const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    const sideDrawerContext = useContext(SideDrawerContext)

    return (
        <>
            <div className="absolute top-0 right-0 bottom-0 min-w-max bg-white">
                {children}
                { sideDrawerContext.gearHandler && <div className={`floatingSideButton absolute transition-[right] duration-200 ease-in-out z-30 ${sideDrawerContext.levelOneDrawerVisibility === true ? (sideDrawerContext.levelTwoDrawerVisibility === true ? 'right-[1120px]' : 'right-[480px]') : 'right-0'} bottom-[140px] w-[60px] h-[47px] bg-${theme.themeColor}-${theme.themeContrast}-primary-default rounded-l-xl cursor-pointer overflow-hidden`}
                    onClick={()=>{
                        if( sideDrawerContext.levelOneDrawerVisibility === false ) {
                            sideDrawerContext.setLevelOneDrawerVisibility(true)
                        } else {

                            if( sideDrawerContext.levelOneDrawerVisibility === true && sideDrawerContext.levelTwoDrawerVisibility === false ) {
                                sideDrawerContext.setLevelOneDrawerVisibility(false);
                            }

                            if( sideDrawerContext.levelOneDrawerVisibility === true && sideDrawerContext.levelTwoDrawerVisibility === true ) {
                                sideDrawerContext.setLevelOneDrawerVisibility(false);
                                sideDrawerContext.setLevelTwoDrawerVisibility(false);
                            }
                        }
                    }}
                >
                    <em className={`fa-light fa-gear text-2xl px-4 py-2 text-${theme.themeColor}-${theme.themeContrast === 'light' ? 'dark' : 'light'}-component-text animate-spin`}></em>
                </div>}
            </div>
        </>
    );
}

export default SideDrawer;
