import React, { Dispatch, SetStateAction, ReactNode, useState, useEffect } from "react";

interface ISideDrawerContext {
    /* property will handle visibility of sideDrawer floating button */
    gearHandler: boolean;
    setGearHandler: Dispatch<SetStateAction<boolean>>;

    /* property will handle visibility of level one sideDrawer */
    levelOneDrawerVisibility: boolean;
    setLevelOneDrawerVisibility: Dispatch<SetStateAction<boolean>>;

    /* property will handle child node(s) of level one sideDrawer */
    levelOneDrawerChild: ReactNode | ReactNode[];
    setLevelOneDrawerChild: Dispatch<SetStateAction<ReactNode | ReactNode[]>>;

    /* property will handle visibility of level two sideDrawer */
    levelTwoDrawerVisibility: boolean;
    setLevelTwoDrawerVisibility: Dispatch<SetStateAction<boolean>>;

    /* property will handle child node(s) of level two sideDrawer */
    levelTwoDrawerChild: ReactNode | ReactNode[];
    setLevelTwoDrawerChild: Dispatch<SetStateAction<ReactNode | ReactNode[]>>;

    setFirstDrawerChild: any;
    setSecondDrawerChild: any;
}

export const SideDrawerContext = React.createContext({} as ISideDrawerContext);

interface IProp {
    children: ReactNode | ReactNode[];
}

const SideDrawerProvider = (props: IProp) => {

    const [gearHandler, setGearHandler] = useState<boolean>(false);
    const [levelOneDrawerVisibility, setLevelOneDrawerVisibility] = useState<boolean>(false);
    const [levelOneDrawerChild, setLevelOneDrawerChild] = useState<ReactNode | ReactNode[]>(<></>);
    const [levelTwoDrawerVisibility, setLevelTwoDrawerVisibility] = useState<boolean>(false);
    const [levelTwoDrawerChild, setLevelTwoDrawerChild] = useState<ReactNode | ReactNode[]>(<></>);

    useEffect(()=>{
      if( levelOneDrawerVisibility === false ) {
        setSecondDrawerChild(<></>)
        setLevelTwoDrawerVisibility(false)
        setFirstDrawerChild(<></>)
      }
    },[levelOneDrawerVisibility])

    useEffect(()=>{
      if( levelTwoDrawerVisibility === false ) {
        setSecondDrawerChild(<></>)
      }
    },[levelTwoDrawerVisibility])

    const setFirstDrawerChild = (content: ReactNode | ReactNode[]) => {
      setLevelOneDrawerChild(content)
    }

    const setSecondDrawerChild = (content: ReactNode | ReactNode[]) => {
      setLevelTwoDrawerChild(content)
    }

    return (
      <SideDrawerContext.Provider value={{
          gearHandler,
          setGearHandler,
          levelOneDrawerVisibility,
          setLevelOneDrawerVisibility,
          levelOneDrawerChild,
          setLevelOneDrawerChild,
          levelTwoDrawerVisibility,
          setLevelTwoDrawerVisibility,
          levelTwoDrawerChild,
          setLevelTwoDrawerChild,
          setFirstDrawerChild,
          setSecondDrawerChild,
        }}>
        {props.children}
      </SideDrawerContext.Provider>
    )
}

export default SideDrawerProvider;