import React, { useState } from "react"
// import Tile5x4_ChartText from "../../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText"
import { Button, Tile } from "../../components/core"

const EndpointDashboard = () =>{

    const [ activeTabs, setActiveTabs ] = useState<string>('info');
    // const [ msLoginIn, setMsLoginIn ] = useState<string>('info');

    return(
        <>
            <div className={`p-6 grow flex flex-col`}>
                <div className={`grow flex flex-col`}>
                    <div className="grid grid-cols-5 gap-6 w-full">
                        <Tile colSpan={1} rowSpan={26} className="hidden md:block p-4">
                            <div className="md:col-span-2 lg:col-span-1 gap-6 mb-6">
                                <div className="flex flex-col items-center justify-center text-center w-full">
                                    <div className="h-32 w-32 aspect-square my-6 rounded-md">
                                        <img src="/assets/img/logo/atempo/LINA_LOGO.svg" alt="Atempo Lina" />
                                    </div>
                                    <div className="bg-slate-100 w-full mb-6 py-2 rounded-md text-[#ff5e0e] font-medium uppercase">Atempo Lina</div>
                                </div>
                                <div className="mt-10">
                                    <div className="text-lg font-medium text-slate-600 mb-2">Tags</div>
                                    <div className="flex flex-wrap gap-2">
                                        <div className="text-slate-600 bg-slate-100 rounded-md px-2 py-1 text-sm max-w-min whitespace-nowrap hover:bg-orange-50 hover:text-[#ff5e0e]">Backup</div>
                                        <div className="text-slate-600 bg-slate-100 rounded-md px-2 py-1 text-sm max-w-min whitespace-nowrap hover:bg-orange-50 hover:text-[#ff5e0e]">Restore</div>
                                        <div className="text-slate-600 bg-slate-100 rounded-md px-2 py-1 text-sm max-w-min whitespace-nowrap hover:bg-orange-50 hover:text-[#ff5e0e]">Protection</div>
                                        <div className="text-slate-600 bg-slate-100 rounded-md px-2 py-1 text-sm max-w-min whitespace-nowrap hover:bg-orange-50 hover:text-[#ff5e0e]">Security</div>
                                        <div className="text-slate-600 bg-slate-100 rounded-md px-2 py-1 text-sm max-w-min whitespace-nowrap hover:bg-orange-50 hover:text-[#ff5e0e]">Endpoints</div>
                                        <div className="text-slate-600 bg-slate-100 rounded-md px-2 py-1 text-sm max-w-min whitespace-nowrap hover:bg-orange-50 hover:text-[#ff5e0e]">Strategy and policy</div>
                                    </div>
                                </div>
                                <div className="mt-10">
                                        <div className="text-lg font-medium text-slate-600 mb-2">Supporting Links</div>
                                        <a href="https://www.atempo.com/support-en/contacting-support/" target="_blank" rel="nofollow noreferrer noopener">
                                            <div className="text-[#ff5e0e] text-sm cursor-pointer"><i className="fa-solid fa-angle-right pr-2 mb-2"></i>OEM Support</div>
                                        </a>
                                        <a href="https://www.atempo.com/products/lina-live-navigator-backup-workstations-laptops/" target="_blank" rel="nofollow noreferrer noopener">
                                            <div className="text-[#ff5e0e] text-sm cursor-pointer"><i className="fa-solid fa-angle-right pr-2 mb-2"></i>OEM Knowledge Base</div>
                                        </a>
                                        <a href="https://www.atempo.com/mandatory-legal-notice/" target="_blank" rel="nofollow noreferrer noopener">
                                            <div className="text-[#ff5e0e] text-sm cursor-pointer"><i className="fa-solid fa-angle-right pr-2 mb-2"></i>OEM Privacy Policy</div>
                                        </a>
                                    </div>
                            </div>
                        </Tile>
                        <Tile colSpan={4} rowSpan={26} className="p-4 overflow-hidden mb-6">
                            <div className="grid col-span-5 md:col-span-3 lg:col-span-4 gap">
                                <div className="md:col-span-1 lg:col-span-1 gap-6 pb-3 mb-4 border-b border-gray-400">
                                    <div className="text-slate-600 uppercase text-xl font-medium items-center flex">Endpoint Backup (Powered by Atempo Lina)
                                    {/* <i className="fa-duotone fa-cloud-bolt text-[#ff5e0e] ml-2 bg-orange-100 p-1 rounded-full"></i> */}
                                    </div>
                                </div>
                                <div className="w-full flex flex-col md:flex-row items-center justify-start align-middle text-center mb-6 gap-2">
                                    {/* <Button className={`border ${activeTabs === 'stats' ? 'bg-opacity-100 text-[#ffffff] hover:bg-opacity-90 hover:text-[#ff5e0e]' : 'bg-opacity-10 text-[#FF5E0E]'} bg-[#FF5E0E] border-none shadow-md rounded-md font-semibold w-full lg:w-1/4`} solid={false}
                                        onClick={()=>{
                                            setActiveTabs('stats')
                                        }}
                                    >Stats</Button> */}
                                    <Button className={`border ${activeTabs === 'info' ? 'bg-opacity-100 text-white hover:text-white hover:bg-opacity-90' : 'bg-opacity-10 text-[#FF5E0E]'} bg-[#FF5E0E] border-none shadow-md rounded-md font-semibold w-full lg:w-1/4`} solid={false}
                                        onClick={()=>{
                                            setActiveTabs('info')
                                        }}
                                    >Info</Button>
                                    <Button className={`border ${activeTabs === 'features' ? 'bg-opacity-100 text-white hover:text-white hover:bg-opacity-90' : 'bg-opacity-10 text-[#FF5E0E]'} bg-[#FF5E0E] border-none shadow-md rounded-md font-semibold w-full lg:w-1/4`} solid={false}
                                        onClick={()=>{
                                            setActiveTabs('features')
                                        }}
                                    >Features</Button>
                                </div>
                                <div className="overflow-y-scroll px-2 h-[480px] md:h-[600px] customScroll mt-4 pr-4">
                                    {/* {activeTabs==='stats' && <LinaStats /> } */}
                                    {activeTabs==='info' && <LinaInfo /> }
                                    {activeTabs==='features' && <LinaFeatures /> }
                                </div>
                            </div>
                        </Tile>
                    </div>
                </div>
            </div>
                        
        </>
    )
}

export default EndpointDashboard

// const LinaStats = () => {
//     return (
//         <>
//         <div className="flex flex-col justify-center items-center align-middle text-center">
//             <img src="/assets/img/server_cluster.svg" alt="Coming soon img" className="w-[250px] mt-28"/>
//             <div className="text-lg  my-6">Coming soon...</div>
//         </div></>
//     )
// }

const LinaInfo = () => {
    return (
        <>
            <div className="text-2xl font-semibold text-slate-500 items-center flex justify-center text-center">LINA Architecture</div>
            <img src="assets/img/lina.png" className="py-2 px-10 mb-16" alt="Tina" width=""/>
            <div className="mb-10 px-[10%] text-center">
            <div className="text-2xl font-semibold text-slate-500 items-center flex justify-center text-center py-6 mt-10">LINA (LIVE NAVIGATOR)</div>
                <div className="text-slate-500 text-sm font-normal text-center flex-wrap">Lina is a solution for the protection of file servers, laptops and workstations. Lina uses a client/server architecture: a Lina server protects Lina endpoints in a network. Lina offers automatic and near continuous protection of user data. There is
                        no backup scheduling. Instead, new or modified data is detected in real time and automatically transferred to the Lina server, without user intervention. The time-intervals between transfers of data to protect is defined by the Recovery Point Objective (RPO). The RPO can vary from one minute to one day.
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 py-6">
                <div className="flex flex-col py-8 justify-start items-start align-middle text-center rounded-md bg-slate-50 shadow-md hover:shadow-lg">
                    <div className="text-xl text-slate-500 mb-4 capitalize px-4">Continuous data backup everywhere</div>
                    <div className="flex justify-start items-start text-sm font-normal px-4 text-justify">Because file protection only makes sense if it is continuous and for all data. Lina backs up your new and modified files cut out in little blocks as you go. No more uncontrollable backup windows.</div>
                    {/* <i className="h-36 w-36 text-8xl text-[#ff5e0e] items-center justify-center text-center align-middle flex rounded-md fa-duotone fa-window-restore"></i> */}
                </div>
                <div className="flex flex-col py-8 justify-start items-start align-middle text-center rounded-md bg-slate-50 shadow-md hover:shadow-lg">
                    <div className="text-xl text-slate-500 mb-4 capitalize px-4">Restore from any destination from a browser in complete security</div>
                    <div className="flex justify-start items-start text-sm font-normal px-4 text-justify">A simple network connection means you log on and restore whatever you need from wherever you are.</div>
                    {/* <i className="h-36 w-36 text-8xl text-[#ff5e0e] items-center justify-center text-center align-middle flex rounded-md fa-duotone fa-computer"></i> */}
                </div>
                <div className="flex flex-col py-8 justify-start items-start align-middle text-center rounded-md bg-slate-50 shadow-md hover:shadow-lg">
                    <div className="text-xl text-slate-500 mb-4 capitalize px-4">Empower users to restore data themselves</div>
                    <div className="flex justify-start items-start text-sm font-normal px-4 text-justify">Real simplicity means users need no help to recover a lost file or return to a previous version of the file. IT teams save time and can concentrate on other activities.</div>
                    {/* <i className="h-36 w-36 text-8xl text-[#ff5e0e] items-center justify-center text-center align-middle flex rounded-md fa-duotone fa-tv"></i> */}
                </div>
                <div className="flex flex-col py-8 justify-start items-start align-middle text-center rounded-md bg-slate-50 shadow-md hover:shadow-lg">
                    <div className="text-xl text-slate-500 mb-4 capitalize px-4">Completely rebuild a machine (system + data + applications)</div>
                    <div className="flex justify-start items-start text-sm font-normal px-4 text-justify">Lina combines Bare Metal Recovery (BMR) with CDP Continuous Data Protection which captures each file modification and enables the reinstallation of a complete machine as it was prior to the incident.</div>
                    {/* <i className="h-36 w-36 text-8xl text-[#ff5e0e] items-center justify-center text-center align-middle flex rounded-md fa-duotone fa-cloud-arrow-up"></i> */}
                </div>
                <div className="flex flex-col py-8 justify-start items-start align-middle text-center rounded-md bg-slate-50 shadow-md hover:shadow-lg">
                    <div className="text-xl text-slate-500 mb-4 capitalize px-4">Reduce the volume of stored data</div>
                    <div className="flex justify-start items-start text-sm font-normal px-4 text-justify">Efficient deduplication runs on the source side before data blocks are sent to the backup server meaning you economize on network and storage costs. Likewise inter-machine deduplication on the server side to further optimize storage costs.</div>
                    {/* <i className="h-36 w-36 text-8xl text-[#ff5e0e] items-center justify-center text-center align-middle flex rounded-md fa-duotone fa-server"></i> */}
                </div>
                <div className="flex flex-col py-8 justify-start items-start align-middle text-center rounded-md bg-slate-50 shadow-md hover:shadow-lg">
                    <div className="text-xl text-slate-500 mb-4 capitalize px-4">Protect without impacting the user</div>
                    <div className="flex justify-start items-start text-sm font-normal px-4 text-justify">Rapid and granular recovery means almost no downtime or impact on production.</div>
                    {/* <i className="h-36 w-36 text-8xl text-[#ff5e0e] items-center justify-center text-center align-middle flex rounded-md fa-duotone fa-server"></i> */}
                </div>
            </div>
        </>
    )
}

const LinaFeatures = () => {
    return (
        <>
            <div className="text-2xl font-semibold text-slate-500 items-center flex justify-center text-center py-6 ">Key Features and Benefits</div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 py-6 px-4">
                <div className="flex flex-col py-8 rounded-md bg-slate-100 px-6 h-auto max-h-[550px] shadow-md hover:shadow-lg">
                    <div className="text-xl mb-10 capitalize text-[#ff5e0e] flex justify-center items-center align-middle text-center ">Continuous backup everywhere</div>
                    <div className="flex flex-col text-left justify-start items-start text-slate-600 text-sm">
                        Lina offers automatic and near continuous protection of user data. There is no backup scheduling. Instead, new or modified data is detected in real time and automatically transferred to the Lina server, without user intervention. The time-intervals between transfers of data to protect is defined by the
                            Recovery Point Objective (RPO). The RPO can vary from one minute to one day. 
                    </div>                    
                </div>
                <div className="flex flex-col py-8 rounded-md bg-slate-100 px-6 h-auto max-h-[550px] shadow-md hover:shadow-lg">
                    <div className="text-xl mb-10 capitalize text-[#ff5e0e] flex justify-center items-center align-middle text-center ">Workstation recovery</div>
                    <div className="flex flex-col text-left justify-start items-start text-slate-600 text-sm">The Bare Metal Recovery (herein called BMR) is a Lina module used to recover a complete Windows endpoint (including system, applications, and data). The BMR feature allows to backup and restore MS SQL Servers and requires setting a specific protection policy at backup time. This ensures that all necessary files are backed up and available if you need to perform a recovery of your machine.
                    </div>
                </div>
                <div className="flex flex-col py-8 rounded-md bg-slate-100 px-6 h-auto max-h-[550px] shadow-md hover:shadow-lg">
                    <div className="text-xl mb-10 capitalize text-[#ff5e0e] flex justify-center items-center align-middle text-center ">Block Level Deduplication </div>
                    <div className="flex flex-col text-left justify-start items-start text-slate-600 text-sm">
                        Lina implements incremental backup and deduplication technology at block level.
                    </div>                    
                </div>
                <div className="flex flex-col py-8 rounded-md bg-slate-100 px-6 h-auto max-h-[550px] shadow-md hover:shadow-lg">
                    <div className="text-xl mb-10 capitalize text-[#ff5e0e] flex justify-center items-center align-middle text-center ">Network
Management
</div>
                    <div className="flex flex-col text-left justify-start items-start text-slate-600 text-sm">
                    Lina agents automatically connect to the server as soon as a network
connection is available.
Lina lets you fine-tune data protection by defining, among other things, the
behavior to adopt for different types of network, or the compression
algorithm for data transfer.
                    </div>                    
                </div>
                <div className="flex flex-col py-8 rounded-md bg-slate-100 px-6 h-auto max-h-[550px] shadow-md hover:shadow-lg">
                    <div className="text-xl mb-10 capitalize text-[#ff5e0e] flex justify-center items-center align-middle text-center ">
                        Centralized, Server free Handling of the Endpoints
                    </div>
                    <div className="flex flex-col text-left justify-start items-start text-slate-600 text-sm">
                    Administrators of the Lina server can:
                            <div className="flex items-start text-left justify-start align-middle mt-1"><i className="fa-solid fa-circle-dot mr-2 text-[8px] mt-[6px]"/>Oversee the entire IT environment of the company (file servers,
                            workstations and laptops).</div>
                            <div className="flex items-start text-left justify-start align-middle"><i className="fa-solid fa-circle-dot mr-2 text-[8px] mt-[6px]"/>Apply common protection policies to groups of machines through a
                            centralized Administration Console.</div>
                            <div className="flex items-start text-left justify-start align-middle"><i className="fa-solid fa-circle-dot mr-2 text-[8px] mt-[6px]"/>Define enterprise-wide protection policies such as exclusions and
                            inclusions, and rights for Lina endpoints.</div>
                    </div>                    
                </div>
                <div className="flex flex-col py-8 rounded-md bg-slate-100 px-6 h-auto max-h-[550px] shadow-md hover:shadow-lg">
                    <div className="text-xl mb-10 capitalize text-[#ff5e0e] flex justify-center items-center align-middle text-center ">WAN Mode</div>
                    <div className="flex flex-col text-left justify-start items-start text-slate-600 text-sm">
                    Lina reduces network traffic since already protected blocks are not re-sent
to the server.
                    </div>                    
                </div>
            </div>   
        </>
    )
}