import React, { FC, ReactNode } from "react";
import { Chart } from "chart.js";
// import { AnyObject } from "chart.js/types/basic";
// import { Chart as RChart } from 'react-chartjs-2';
import { ForwardedRef } from "react-chartjs-2/dist/types";
import CJDonutChart from "../CJDonutChart/CJDonutChart";
import CJLineChart from "../CJLineChart/CJLineChart";
import CJPieChart from "../CJPieChart/CJPieChart";
import CJBarChart from "../CJSBarChart/CJSBarChart";

export interface IChartDatasetData { 
    fill?: boolean;
    label: string; 
    data: number[]; 
    responsive?: boolean;
    backgroundColor: string | string[];
    borderColor?: string | string[];
    stack?: string,
    borderWidth?: number;
    borderRadius?: number;
    barThickness?: number;
    borderSkipped?: boolean;
    barPercentage?: number;
    maxBarThickness?: number;
    minBarLength?: number;
    lineTension?: number;
    tension?: number;
    pointRadius?: number;
    showLine?: boolean;
    cutout?: number;
    pointStyle?: 'circle' | 'cross' | 'crossRot' | 'dash' | 'line' | 'rect' | 'rectRounded' | 'rectRot' | 'star' | 'triangle';

}

export interface IChartParams {
    /**
     * Type indicates chart type
     */
    type?: "bar" | "line" | "pie" | "donut";
    /**
     * Name of label against each dataset value
     */
    labels: string[];
    /**
     * ChartJS Chart Object, refer component documentation https://react-chartjs-2.js.org/components for more details.
     */
    datasets: IChartDatasetData[];
    /**
     * CharJS Chart style option object. It's very complicated object and has a lot of options
     * Refer component documentation https://react-chartjs-2.js.org/components for more details.
     */
    options?: object;
    /**
     * There are whole lot of ChartJS plugins check this link to search all => https://www.npmjs.com/search?q=chartjs-plugin-
     */
    // plugins?: Plugin<"line", AnyObject>[] | Plugin<"pie", AnyObject>[] | Plugin<"bar", AnyObject>[] | Plugin<"doughnut", AnyObject>[];
    plugins?: any;
    /**
     * Teardown and redraw chart on every update
     */
    redraw?: boolean;
    /**
     * Key name to identification dataset
     */
    datasetIdKey?: string;
    /**
     * A fallback for when the canvas cannot be rendered. Can be used for accessible chart descriptions
     */
    fallbackContent?: ReactNode;
    /**
     * A mode string to indicate transition configuration should be used.
     */
    updateMode?: "resize" | "reset" | "none" | "hide" | "show" | "normal" | "active";
    ref?: ForwardedRef<Chart<"line", any, any>> | ForwardedRef<Chart<"pie", any, any>> | ForwardedRef<Chart<"bar", any, any>> | ForwardedRef<Chart<"doughnut", any, any>>;
};

const MasterChart: FC<IChartParams> = ({
    type,
    labels,
    datasets,
    options={},
    plugins=[],
    redraw=false,
    datasetIdKey='label',
    fallbackContent=<></>,
    updateMode,
    ref
}:IChartParams) => {

    return (
        <>
            {type === 'bar' && <CJBarChart labels={labels} datasets={datasets} options={options} plugins={plugins} />}
            {type === 'line' && <CJLineChart labels={labels} datasets={datasets} options={options} plugins={plugins} />}
            {type === 'pie' && <CJPieChart labels={labels} datasets={datasets} options={options} plugins={plugins} />}
            {type === 'donut' && <CJDonutChart labels={labels} datasets={datasets} options={options} plugins={plugins} />}
        </>
    );
}

export default MasterChart;