import { Dispatch, FC, RefObject, SetStateAction } from "react";
import { ReactSortable } from "react-sortablejs";
import Switch from "../Switch/Switch";
import Tooltip from "../Tooltip/Tooltip";

import { ITableColumnItem } from './Table'

interface ITableManagementNavBtn {
    navRef: RefObject<HTMLDivElement>;
}

export const TableColumnManagementNav:FC<ITableManagementNavBtn> = ({ navRef }) => {
   
    return (
        <span className="relative normal-case text-left">
            <button
                aria-label="Toggle"
                className="relative group"
                onClick={()=>{
                    if( navRef.current !== null ) {
                        navRef.current.className = navRef.current.className.includes('hidden') ? navRef.current.className.replace('hidden', 'block') : navRef.current.className.replace('block', 'hidden');
                    }
                }}
            >
                <Tooltip value="Click to open column management menu" className="-left-[270px]" />
                <i className="fa-solid fa-sliders"></i>
            </button>
        </span>
    )
}

interface ITableColumnManagementMenu {
    navRef: RefObject<HTMLDivElement>;
    columns?: ITableColumnItem[];
    setColumns: Dispatch<SetStateAction<ITableColumnItem[]>>;
    switchHandler: any;
}

export const TableColumnManagementMenu:FC<ITableColumnManagementMenu> = ({
    navRef, 
    columns=[], 
    setColumns, 
    switchHandler
}) => {

    return (
        <span className="relative w-full h-0 z-50">
            <div 
                className={`absolute z-50 hidden w-[231px] max-h-min rounded bg-white right-[8px] top-[3px] card-shadow`}
                ref={navRef}
            >
                <div className="flex w-full h-[40px] border-b font-semibold text-base py-[9px] px-3 text-grey-darker">
                    <span>Columns display</span>
                    <span className="grow"></span>
                    <button
                        aria-label="Close"
                        className="h-4 w-4"
                        onClick={()=>{
                            if( navRef.current !== null ) {
                                navRef.current.className = navRef.current.className.includes('hidden') ? navRef.current.className.replace('hidden', 'block') : navRef.current.className.replace('block', 'hidden');
                            }
                        }}
                    ><i className="fa-solid fa-xmark"></i></button>
                </div>
                <div className="flex flex-col py-2">
                    <ReactSortable
                        animation={200}
                        // delayOnTouchStart={true}
                        delay={2}
                        list={columns}
                        setList={setColumns}
                    >
                        {columns.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex w-full px-3 hover:bg-slate-50 py-2 align-middle justify-center">
                            <span className="relative group">
                                <Tooltip value="drag to change position" className="-left-[170px]"/>
                                <i className="flex-none fas fa-grip-vertical w-4 h-4 py-[6px] cursor-move text-grey-dark text-center"></i>
                            </span>
                            <span className="relative group">
                                <Tooltip value="Change visibility" className="-left-[25px]"/>
                                <Switch
                                    state={item.visibility}
                                    switchHandler={(e)=>{switchHandler(item)}}
                                    className='ml-2'
                                />
                            </span>
                            <div className="font-normal text-sm ml-2 my-[2px] grow">{item.name}</div>
                        </div>
                        ))}
                    </ReactSortable>
                </div>
            </div>
        </span>
    )
}
