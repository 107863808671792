// import {DateTime} from "luxon";
// import * as _ from "lodash";

const getFilteredTicketStatus = (arrTickets:any[]) => {

    // arrTickets[0].properties.hs_pipeline_stage === 1 => 'Pending'
    // arrTickets[0].properties.hs_pipeline_stage === 4 => 'Closed'
    // arrTickets[0].properties.hs_pipeline_stage === else => 'in progress'

    let pending = arrTickets.filter((t:any)=>t.properties.hs_pipeline_stage === '1')
    let closed = arrTickets.filter((t:any)=>t.properties.hs_pipeline_stage === '4')
    let inProgress = arrTickets.filter((t:any)=>t.properties.hs_pipeline_stage !== '1' && t.properties.hs_pipeline_stage !== '4')

    return {
        total: arrTickets.length,
        all: arrTickets,
        pending,
        closed,
        inProgress,
    }
}

export {
    getFilteredTicketStatus
}