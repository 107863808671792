/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, ReactNode, ComponentProps, useContext, Dispatch, SetStateAction, useState, useLayoutEffect, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { CustomizationContext, ThemeContext } from "../../../contexts";

interface Props extends ComponentProps<"aside">  {
    navCollapsed?: boolean 
    setNavCollapsed?: Dispatch<SetStateAction<boolean>>
    children?: ReactNode | ReactNode[]
}

/**
 * - **Orange Background:** bg-orange-light-component-background bg-orange-dark-component-background
 * - **Blue Background:** bg-blue-light-component-background bg-blue-dark-component-background
 * - **Orange Primary:** bg-orange-light-primary-default bg-orange-dark-primary-default
 * - **Blue Primary:** bg-blue-light-primary-default bg-blue-dark-primary-default
 * - **Orange Text:** text-orange-light-primary-default text-orange-dark-primary-default
 * - **Blue Text:** text-blue-light-primary-default text-blue-dark-primary-default
 * - **Orange Text:** text-orange-light-component-text text-orange-dark-component-text
 * - **Blue Text:** text-blue-light-component-text text-blue-dark-component-text
 * - **Orange Border:** border-orange-light-primary-default border-orange-dark-primary-default
 * - **Blue Border:** border-blue-light-primary-default border-blue-dark-primary-default
 * @param props 
 * @returns 
 */

const SideBar:FC<Props> = ({children, ...rest}: Props) => {

    const {navCollapsed=false, setNavCollapsed}: any = rest;

    const theme = useContext(ThemeContext) || {themeColor: 'orange', themeContrast: 'light'};
    const customElements = useContext(CustomizationContext);

    const [pageSize, setPageSize] = useState<number[]>([0, 0]);
    const [responsiveBreakPoint, setBreakPoint] = useState<string>('sm');

    useLayoutEffect(() => {
        function updateSize() {
            setPageSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(()=>{
        if(pageSize[0] < 1280 ) {
            setNavCollapsed(true)
        }
        if( pageSize[0] >= 1280 ) {
            setNavCollapsed(false)
        }
    },[responsiveBreakPoint])

    useEffect(()=>{
        if(pageSize[0] <= 640) {
            setBreakPoint('sm')
        }
        if(pageSize[0] > 640 && pageSize[0] <= 768) {
            setBreakPoint('md')
        }
        if(pageSize[0] > 768 && pageSize[0]  <= 1024) {
            setBreakPoint('lg')
        }
        if(pageSize[0] > 1024 && pageSize[0]  <= 1280) {
            setBreakPoint('xl')
        }
        if(pageSize[0] > 1280) {
            setBreakPoint('2xl')
        }
    },[pageSize]);

    return (
        <div className={`sidebar bg-${theme.themeColor}-${theme.themeContrast}-component-background relative flex flex-col h-screen ${ navCollapsed===false ? 'w-[290px]' : 'w-[65px]'} shadow-md transition-[width] duration-200 ease-in-out`}>
            <div className={`brand flex relative  w-full justify-center py-10`}>
                { navCollapsed === false && <img style={{'height':'33px', 'width': 'auto'}} src={`/assets/img/logo/zeeq_360_${theme.themeContrast === 'dark' ? 'light' : 'dark'}_${theme.themeColor}.svg`} alt="ZeeQ 360 Logo" /> }
                { navCollapsed === true && <img style={{'height':'33px', 'width': 'auto'}} src={`/assets/img/logo/zee_${theme.themeContrast === 'dark' ? 'light' : 'dark'}_${theme.themeColor}.svg`} alt="ZeeQ 360 Logo" /> }
                { process.env.REACT_APP_ENVIRONMENT !== 'prod' ? <div className={`absolute top-[75px] ${ navCollapsed ? 'left-[20px]' : 'left-[183px]' } text-xs font-medium bg-[#FF5E0E] rounded-full text-white px-2 py-0 transition-all duration-200 `}>{navCollapsed ? 'β' : 'Beta'}</div> : '' }
                { process.env.REACT_APP_ENVIRONMENT === 'prod' ? <div className={`absolute top-[75px] ${ navCollapsed ? 'left-[20px]' : 'left-[195px]' } text-xs font-medium bg-[#FF5E0E] rounded-full text-white px-2 py-0 transition-all duration-200 `}>V2</div> : '' }
                <div
                    className={`btnNavCollapse flex absolute items-center align-middle justify-center rounded-full w-[25px] h-[25px] shadow bg-white top-2/3 -right-3 cursor-pointer`}
                    onClick={()=>{
                        setNavCollapsed((prev: boolean)=>{
                            return !prev;
                        })
                    }}
                >

                    <i className={`fa-solid fa-chevron-${navCollapsed ? 'right' : 'left'} text-${theme.themeColor}-${theme.themeContrast}-primary-default`}></i>
                </div>
            </div>
            <nav className={`nav flex flex-col gap-[6px] grow overflow-scroll pt-3 shadow-inner pb-5 customScroll`}>

                {Object.keys( customElements.pageElements ).map((pageGroup, pageGroupIndex)=>(
                    <span key={pageGroupIndex}>
                    {pageGroup !== 'ungrouped' && <div  className={`nav-item-group flex relative items-center px-5 pt-[20px] w-[270px]`}>
                        <div className={`line absolute w-full left-0 h-0 border-t border-${theme.themeColor}-${theme.themeContrast}-primary-default z-10 ${navCollapsed ? 'w-[55px]' : 'w-270'} `}></div>
                        {navCollapsed === false && <div className={`text-${theme.themeColor}-${theme.themeContrast}-primary-default block px-2 bg-${theme.themeColor}-${theme.themeContrast}-component-background text-xs uppercase font-semibold leading-6 z-20`}>
                            {pageGroup}
                        </div>}
                    </div>}
                    {Object.keys( customElements.pageElements[pageGroup] ).map((page,pageIndex)=>(
                        customElements.pageElements[pageGroup][page].displayOnNav === true && (
                            <>
                                <NavLink key={`${pageGroupIndex}-${pageIndex}`} to={page}>
                                    {({ isActive }) => (
                                        <>
                                        <div 
                                            // className={`nav-item flex relative items-center px-5 py-[10px] text-${theme.themeColor}-${theme.themeContrast}-component-text bg-slate-50 mb-2 ${isActive ? `bg-${theme.themeColor}-${theme.themeContrast}-primary-default` : ` ${ theme.themeContrast === 'light' ? `hover:bg-slate-100` : 'hover:bg-[#333333]'}`} cursor-pointer ${navCollapsed === false ? 'w-[270px]' : 'w-[60px]'} rounded-tr-[22px] rounded-br-[22px]`}
                                            className={`nav-item ${isActive ? 'bg-orange-500 hover:bg-orange-600' : 'hover:bg-slate-100'} flex relative items-center px-5 py-[10px] cursor-pointer ${navCollapsed === false ? 'w-[270px]' : 'w-[60px]'} rounded-tr-[22px] rounded-br-[22px]`}
                                            onClick={()=>{
                                                customElements.pageElements[pageGroup][page].collapsed = !customElements.pageElements[pageGroup][page].collapsed;
                                            }}
                                        >
                                            {/* page icon */}
                                            <div className={`nav-item-icon flex items-center justify-center w-[22px] h-[22px] mr-3`}>
                                                <i className={`${customElements.pageElements[pageGroup][page].icon} text-base ${isActive ? 'text-white' : ''} `}></i>
                                            </div>
                                            {
                                                    navCollapsed === false && (
                                                        <>
                                                            {/* page title */}
                                                            <div className={`${isActive ? 'text-white' : ''} text-base font-normal leading-6`}>
                                                                {customElements.pageElements[pageGroup][page].title}
                                                            </div>
                                                            {/* new badge */}
                                                            {customElements.pageElements[pageGroup][page].new && (
                                                                <div className={`absolute right-[38px] text-[13px] font-normal leading-[18px] bg-${theme.themeColor}-${theme.themeContrast}-component-background text-${theme.themeColor}-${theme.themeContrast}-primary-default rounded-[12px] px-[10px] py-[3px]`}>
                                                                    New
                                                                </div>
                                                            )}
                                                            {/* up down arrow */}
                                                            {customElements.pageElements[pageGroup][page].pages.length > 0 && (
                                                                <div className={`absolute right-[15px] rounded-full`}>
                                                                    {customElements.pageElements[pageGroup][page].collapsed ?
                                                                    <em className={`fa-solid fa-chevron-down text-${theme.themeColor}-${isActive ? (theme.themeContrast === 'light' ? 'dark' : 'light') : theme.themeContrast}-component-text`}></em> :
                                                                    <em className={`fa-solid fa-chevron-up text-${theme.themeColor}-${isActive ? (theme.themeContrast === 'light' ? 'dark' : 'light') : theme.themeContrast}-component-text`}></em>}
                                                                </div>
                                                            )}
                                                        </>
                                                    )
                                            }
                                        </div>
                                        
                                        </>
                                    )}
                                </NavLink>
                                {(customElements.pageElements[pageGroup][page].collapsed === false && customElements.pageElements[pageGroup][page].pages.length > 0) && 
                                    customElements.pageElements[pageGroup][page].pages.map((subPage: any, subPageIndex:number) => (
                                        subPage.displayOnNav && 
                                            <NavLink key={`${pageGroupIndex}-${pageIndex}-${subPageIndex}`} to={subPage?.link || ''}>
                                                {({ isActive }) => (
                                                    // subPage link
                                                    <div className={`sub-nav-items flex items-center align-middle ${navCollapsed ? `${subPage?.svg ? 'ml-2' : 'pl-2 mr-2'}` : 'pl-8 mr-4'} pr-5 py-[5px] cursor-pointer ${isActive ? `hover:bg-${theme.themeColor}-${theme.themeContrast}-primary-default hover:bg-opacity-30` : 'hover:bg-slate-100'} rounded-r-full text-${theme.themeColor}-${theme.themeContrast}-component-text ${isActive ? `bg-${theme.themeColor}-${theme.themeContrast}-primary-default bg-opacity-30` : ` ${ theme.themeContrast === 'light' ? `hover:bg-slate-100` : 'hover:bg-[#333333]'}`}`}>
                                                        {/* vertical line */}
                                                        {/* {navCollapsed ? '' : <span className="border-l border-[#f97315] h-full"></span>} */}
                                                        {/* icon */}
                                                        {
                                                            subPage?.svg ? 
                                                            <img className={`w-[16px] h-auto ml-3`} src={subPage?.svg} alt="icon" /> : 
                                                            <em className={`${subPage?.icon} text-[14px] w-[16px] text-[#f97315] ml-3`}></em>
                                                        }
                                                        {/* <em className={`${subPage?.icon} text-xs text-[] ml-4`}></em> */}
                                                        {/* <em className={`fa-solid fa-circle text-[6px] text-[#f97315] ml-4`}></em> */}
                                                        {/* page title */}
                                                        {!navCollapsed && <span className="ml-4">{subPage?.title || ''}</span>}
                                                    </div>
                                                )}
                                            </NavLink>
                                    ))
                                }
                            </>
                        )
                    ))}
                    </span>
                ))}

            </nav>
        </div>
    );
}

export default SideBar;
