import React from "react";
import { Tile } from "../../../core";


const Tile54= ( ) =>{
    return (
        <>
        <Tile rowSpan={15} colSpan={3} className=" border shadow-none   p-0 ">
                <div className=" flex flex-col   h-full  p-5 text-[#3A3541]  ">

                    <div className="flex flex-row justify-between w-full ">
                        <div className={`text-base leading-6 mb-5 font-semibold`}>Revenue Report</div>
                        <i className="fa-solid fa-ellipsis-vertical text-center justify-center items-start flex"></i>   
                    </div>
                    <i className=" fa-light fa-atom-simple text-9xl border  h-full text-center items-center flex  justify-center pt-5 mb-4 "></i>   
                    <div className="w-full  text-center gap-4 flex flex-row items-center justify-center">
                        <span className="flex flex-row items-center"><i className="fa-sharp fa-solid fa-circle text-xs leading-5 mr-2 text-green-600"></i>Earning</span>
                        <span className="flex flex-row items-center "><i className="fa-sharp fa-solid fa-circle text-xs leading-5  mr-2 text-orange-400"></i>Expense</span> 
                    </div>
                </div>
        </Tile>
        </>
    )
}

export default Tile54