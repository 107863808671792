import React, { FC, ReactNode, ComponentProps, useContext, Dispatch, SetStateAction } from "react";
import { ThemeContext } from "../../../contexts";


interface Props extends ComponentProps<"div">  {
    // setModalVisibility?: () => void;
    /**
     * Child node(s) of button
     */
    children?: ReactNode | ReactNode[] | string;
    /**
     * 
     */
    className?: string
    modalVisible?: boolean;
    setModalVisibility?: Dispatch<SetStateAction<boolean>>;
}

/**
 * Button Component
 * hover:bg-orange-light-primary-default hover:bg-blue-light-primary-default
 * hover:bg-orange-dark-primary-default hover:bg-blue-dark-primary-default
 * @param param0 
 * @returns 
 */
const ModalHeader:FC<Props> = ({
    modalVisible=false,
    setModalVisibility=()=>{},
    className='',
    children=<>Modal Title</>,
    onClick=()=>{},
    ...rest
}: Props) => {
    const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    return (
        <>
            <div className="modal-head flex px-4 py-2 border-b-2">
                <div className={`modal-title grow text-xl font-semibold py-1 px-2 ${className}`}>{children}</div>
                <button
                    className={`modal-close-button pb-1 px-3 rounded hover:bg-${theme.themeColor}-${theme.themeContrast}-primary-default hover:bg-opacity-25 text-xl`}
                    onClick={()=>{
                        setModalVisibility((prev)=>{
                            return !prev;
                        })
                    }}
                >&times;</button>
            </div>
        </>
    );
}

export default ModalHeader;
