import {DateTime} from "luxon";

const ifTimeExpired = (startDate: string, expiryDate: string) => {
    let subStartDate: any = new Date(startDate); // d
    let subEndDate: any = new Date(expiryDate); // d
    let currentDate: any = new Date(); // cd

    let differenceFromToday = subEndDate - currentDate;
    let daysLeftToday = subEndDate.getTime() - currentDate.getTime() ? Math.ceil(differenceFromToday / ( 1000 * 3600 * 24 )) : 0;

    let differenceFromStart = subEndDate - subStartDate;
    let licenseDays = subEndDate.getTime() - subStartDate.getTime() ? Math.ceil(differenceFromStart / ( 1000 * 3600 * 24 )) : 0;

    return {
        totalDays: licenseDays,
        renewalDate: DateTime.fromISO(expiryDate).plus({days: 1}).toLocaleString(),
        daysLeft: daysLeftToday,
        expired: currentDate.getTime() > subEndDate.getTime()
    }
}

const getFormattedDate = (d: any) => {
    if( d === '' || d === undefined || d === null ) { return '00-00-0000' }

    try {
        let tempD = new Date(d);

        let tempDate = (tempD.getDate()).toString();
        let date = "00".slice(tempDate.length,2) + tempDate;

        let tempMonth = (tempD.getMonth() + 1).toString();
        let month = "00".slice(tempMonth.length,2) + tempMonth;

        let year = tempD.getFullYear();

        return `${date}-${month}-${year}`
    } catch (error) {
        console.log(error)
    }
}

const getFormattedDateTime = (d: any, format: any = null) => {
    if( d === '' || d === undefined || d === null ) { return '00-00-0000 00:00' }
    // return DateTime.fromISO(d).toLocaleString(DateTime.DATETIME_MED)
    // return DateTime.fromISO(d).toLocaleString(DateTime.DATE_SHORT)
    // return DateTime.fromISO(d).toLocaleString(DateTime.DATETIME_MED,{ locale: 'en-in' })
    try {
        // return DateTime.fromISO(d).toFormat('dd/MM/yy HH:mm')
        // return DateTime.fromISO(d).toLocaleString(DateTime.DATETIME_SHORT)
        if( format === 'utc' ) {
            return DateTime.fromISO(d, {zone: 'utc'}).toLocaleString(DateTime.DATETIME_SHORT)
        } else {
            return DateTime.fromISO(d).toLocaleString(DateTime.DATETIME_SHORT)
        }
    } catch (error) {
        console.log(error)
    }
    
}

const dateTimeToUnixLocalTimestamp = (d: any, dayTime='start') => {
    if( d === '' || d === undefined || d === null ) { return '00-00-0000 00:00' }
    try {
        let timestamp = dayTime === 'start' ? DateTime.fromISO(d.toISOString()).startOf('day').toSeconds() : DateTime.fromISO(d.toISOString()).endOf('day').toSeconds();
        return Number(timestamp.toString().split('.')[0]);
    } catch (error) {
        console.log(error)
    }
}

const dateTimeToUnixTimestamp = (d: any, dayTime='start') => {
    if( d === '' || d === undefined || d === null ) { return '00-00-0000 00:00' }
    try {
        let timestamp = dayTime === 'start' ? DateTime.fromISO(d.toISOString()).startOf('day').toSeconds() : DateTime.fromISO(d.toISOString()).endOf('day').toSeconds();
        let temp = new Date(Number(timestamp.toString().split('.')[0]) * 1000);
        const gmtTimestamp = temp.getTime() + (temp.getTimezoneOffset() * 60 * 1000)
        return gmtTimestamp/1000;
    } catch (error) {
        console.log(error)
    }
}


const formatSeconds = (seconds: number, decimals: number = 2) => {
    // let negative = false;
    if (seconds === 0) return {value: 0, unit: 'Seconds'};

    if( seconds < 0 ) {
        seconds = seconds * -1;
        // negative = true;
    }

    const k = [60,60,24,30,12];
    const sizes = ["Minutes", "Hours", "Days","Months","Years"];

    // console.log({seconds})

    let finalVal = seconds
    let index = 0

    if(finalVal < 60){
        return {
            value: finalVal,
            unit: 'Seconds'
        }
    }

    for (let i = 0; i < k.length; i++) {
        finalVal = finalVal / k[i]
        index = i;
        if(finalVal < 60){
            break;
        }
    }

    return {
        value: finalVal,
        unit: sizes[index]
    }
};

const parseJwt = (token:string) => {
    if( token === "" || token === null || token === undefined ) {
        return
    }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

const dataSizeConversion = ( dataValue:number, fromUnitSymbol: string, toUnitSymbol: string ) => {

    // Definition
    const definition = [
        {
            index: 0,
            name: 'Byte',
            symbol: 'B',
            factor: 1024
        },
        {
            index: 1,
            name: 'Kilo Byte',
            symbol: 'KB',
            factor: 1024
        },
        {
            index: 2,
            name: 'Mega Byte',
            symbol: 'MB',
            factor: 1024
        },
        {
            index: 3,
            name: 'Giga Byte',
            symbol: 'GB',
            factor: 1024
        },
        {
            index: 4,
            name: 'Tera Byte',
            symbol: 'TB',
            factor: 1024
        },
        {
            index: 5,
            name: 'Peta Byte',
            symbol: 'PB',
            factor: 1024
        }
    ];

    let fromIndex: any = definition.find((u)=>{return u.symbol === fromUnitSymbol.toUpperCase()});
    let toIndex:any = definition.find((u)=>{return u.symbol === toUnitSymbol.toUpperCase()});

    let bytes = dataValue

    if( fromIndex?.index > toIndex?.index ) { // decremental order
        for ( let i = fromIndex?.index; i > toIndex?.index; i-- ) {
            bytes = bytes * definition[i].factor;
        }
    } else if( fromIndex?.index < toIndex?.index ) { // incremental order
        for ( let i = fromIndex?.index; i < toIndex?.index; i++ ) {
            bytes = bytes / definition[i].factor;
        }
    }

    return {
        value: bytes,
        unit: toIndex?.symbol
    }
    
}

const localToGMT = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    const gmtTimestamp = date.getTime() + (date.getTimezoneOffset() * 60 * 1000);
    return gmtTimestamp/1000;
}

function humanReadableTime(seconds:number) {
    var years = Math.floor(seconds / 31536000);
    var months = Math.floor((seconds % 31536000) / 2628000);
    var days = Math.floor((seconds % 2628000) / 86400);
    var hours = Math.floor((seconds % 86400) / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var seconds = seconds % 60;
  
    var output = "";
    if (years > 0) {
      output += years + " years ";
    }
    if (months > 0) {
      output += months + " months ";
    }
    if (days > 0) {
      output += days + " days ";
    }
    if (hours > 0) {
      output += hours + " hours ";
    }
    if (minutes > 0) {
      output += minutes + " minutes ";
    }
    if (seconds > 0) {
      output += seconds + " seconds ";
    }
  
    return output;
  }

function convertUTCtoLocalDate(utcDate:any) {
    const utcDateTime = new Date(utcDate);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localDateTime = utcDateTime.toLocaleString(undefined, { timeZone });
    return localDateTime;
}

export {
    ifTimeExpired,
    getFormattedDate,
    getFormattedDateTime,
    dateTimeToUnixLocalTimestamp,
    formatSeconds,
    parseJwt,
    dataSizeConversion,
    localToGMT,
    dateTimeToUnixTimestamp,
    humanReadableTime,
    convertUTCtoLocalDate
}