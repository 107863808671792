/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useRef, useContext, FC } from "react";
import { Accordion, AccordionItem, Breadcrumb, Button, Modal, ModalBody, ModalFooter, ModalHeader, Tile } from "../../components/core";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Tile5x4_ChartText from "../../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText";
import { Table } from "../../components/core/Table";
import { getAPICall, postAPICall } from "../../services/APILayer/axiosMethodCalls";
import { AuditReportContext, SideDrawerContext } from "../../contexts";
import { ITableColumnItem, ITableControllerProps } from "../../components/core/Table/Table";
// import { dateTimeToUnixLocalTimestamp, formatSeconds, getFormattedDateTime } from "../../utils/format";
// import { parseAuditData, processEndpointData } from "../../utils/auditUtils";
// import { formatSize } from "../../utils/backupFileUtils";
// import { Dropdown } from "../../components/core";
import { useNavigate } from "react-router-dom";
// import { lastConnectionFormatter } from "../../utils/agentUtils";
import { generateCsv } from "../../utils/reportingUtil";
import { dataSizeConversion, getFormattedDateTime } from "../../utils/format";
import { formatSize } from "../../utils/backupFileUtils";
import Tooltip from "../../components/core/Tooltip/Tooltip";
import { deviceSymbol, lastBackupFormatter, lastConnectionFormatter } from "../../utils/agentUtils";
import { SortWithDeletedData, SortWithDeviceId, SortWithDeviceStatus, SortWithHostname, SortWithInstalledDate, SortWithLastBackup, SortWithLastConnection, SortWithOS, SortWithProtectedData } from "../../utils/Sorting/endpointAudit";
// import { read, utils, writeFile } from 'xlsx';
// import { saveAs } from "file-saver";
// import XlsxPopulate from "xlsx-populate";

const EndpointDeviceAuditReport = ( ) =>{

    const navigate = useNavigate();
    const navRaf = useRef<null|HTMLDivElement>(null)
    const sideDrawerContext = useContext(SideDrawerContext);
    const auditContext = useContext(AuditReportContext);
    
    // const [loader, setLoader] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<string>('All');
    const [selectedTableData, setSelectedTableData] = useState<string>('');
    const [strategyPolicies, setStrategyPolicies] = useState<any>('');

    useEffect(()=>{
        if((auditContext?.searchParams?.endpoints || []).length < 1) {
            navigate('/endpoint/audit/search')
        }
    },[auditContext.searchParams.endpoints])

    /* states managing tables */
    const [tableDataBackup, setTableDataBackup] = useState<any[]>([])
    const [tableController, setTableController] = useState<ITableControllerProps>({
        data: [],
        loader: false,
        currentPage: 1,
        offset: 0,
        limit: 5,
        totalRecords: 10,
        maxVisiblePaginationButtons: 5,
        searchTerm: '',
        filter: {},
        sorting: {
            column: '',
            direction: null
        }
    });
    const [tableColumns, setTableColumns] = useState<ITableColumnItem[]>([
        {
            id: 1,
            name: 'Device Id',
            column: 'device_id',
            textAlign: 'text-left',
            textColor: 'text-[#FF5E0E]',
            className: 'min-w-[80px] pl-4',
            visibility: true,
            filter: {
                visible: false,
                type: "uniqueValues",
                selected: false,
                unit: '',
                object: []
            },
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 2,
            name: 'Hostname',
            column: 'hostname',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            className: 'min-w-[150px]',
            visibility: true,
            filter: {
                visible: false,
                type: "uniqueValues",
                selected: false,
                unit: '',
                object: []
            },
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 3,
            name: 'OS',
            column: 'os',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            className: 'min-w-[80px]',
            visibility: true,
            filter: {
                visible: true,
                type: "objectArray",
                selected: false,
                unit: '',
                object: [
                    { name: 'All', selected: true },
                    { name: 'Windows', selected: true },
                    { name: 'Linux', selected: true },
                    { name: 'Apple', selected: true },
                    { name: 'Unknown', selected: true },
                ]
            },
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 4,
            name: 'Device Status (Current)',
            column: 'status',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            className: 'min-w-[180px]',
            visibility: true,
            filter: {
                visible: true,
                type: "objectArray",
                selected: false,
                unit: '',
                object: [
                    { name: 'All', selected: true },
                    { name: 'Protected', selected: true },
                    { name: 'Unprotected', selected: true },
                    { name: 'Installed', selected: true },
                    { name: 'Pending Installation', selected: true },
                    { name: 'Deleted', selected: true },
                ]
            },
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 5,
            name: 'Last Connection',
            column: 'lastConnection',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            className: 'min-w-[150px]',
            visibility: true,
            filter: {
                visible: true,
                type: "calendar",
                selected: false,
                unit: '',
                object: {
                    startTime: '',
                    endTime: ''
                }
            },
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 6,
            name: 'Last Backup',
            column: 'lastBackup',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            className: 'min-w-[150px]',
            visibility: true,
            filter: {
                visible: true,
                type: "operators",
                selected: false,
                unit: ['Sec', 'Min', 'Hrs', 'Days', 'Months', 'Years'],
                object: [
                    { operator: 'EQ', name: 'Equals To', selected: false },
                    { operator: 'NEQ', name: 'Not Equals To', selected: false },
                    { operator: 'LT', name: 'Less Than', selected: false },
                    { operator: 'LTE', name: 'Less Than Equal To', selected: false },
                    { operator: 'GT', name: 'Greater Than', selected: false },
                    { operator: 'GTE', name: 'Greater Than Equal To', selected: false },
                    { operator: 'BW', name: 'Between', selected: false },
                ],
                values: {
                    values: (new Array(2)).fill(0),
                    unit: '',
                    operator: '' // LT GT EQ NEQ LEQ GEQ BT  
                }
            },
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 7,
            name: 'Protected Data',
            column: 'protectedData',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            className: 'min-w-[150px]',
            visibility: true,
            filter: {
                visible: true,
                type: "operators",
                selected: false,
                unit: ['KB', 'MB', 'GB', 'TB'],
                object: [
                    { operator: 'EQ', name: 'Equals To', selected: false },
                    { operator: 'NEQ', name: 'Not Equals To', selected: false },
                    { operator: 'LT', name: 'Less Than', selected: false },
                    { operator: 'LTE', name: 'Less Than Equal To', selected: false },
                    { operator: 'GT', name: 'Greater Than', selected: false },
                    { operator: 'GTE', name: 'Greater Than Equal To', selected: false },
                    { operator: 'BW', name: 'Between', selected: false },
                ],
                values: {
                    values: (new Array(2)).fill(0),
                    unit: '',
                    operator: '' // LT GT EQ NEQ LEQ GEQ BT 
                }
            },
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 8,
            name: 'Installed On',
            column: 'installed_at',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            className: 'min-w-[150px]',
            visibility: true,
            filter: {
                visible: false,
                type: "operators",
                selected: false,
                unit: ['KB', 'MB', 'GB', 'TB'],
                object: [
                    { operator: 'EQ', name: 'Equals To', selected: false },
                    { operator: 'NEQ', name: 'Not Equals To', selected: false },
                    { operator: 'LT', name: 'Less Than', selected: false },
                    { operator: 'LTE', name: 'Less Than Equal To', selected: false },
                    { operator: 'GT', name: 'Greater Than', selected: false },
                    { operator: 'GTE', name: 'Greater Than Equal To', selected: false },
                    { operator: 'BW', name: 'Between', selected: false },
                ],
                values: {
                    values: (new Array(2)).fill(0),
                    unit: '',
                    operator: '' // LT GT EQ NEQ LEQ GEQ BT 
                }
            },
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 9,
            name: 'Deleted On',
            column: 'deleted_at',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            className: 'min-w-[150px]',
            visibility: true,
            filter: {
                visible: false,
                type: "operators",
                selected: false,
                unit: ['KB', 'MB', 'GB', 'TB'],
                object: [
                    { operator: 'EQ', name: 'Equals To', selected: false },
                    { operator: 'NEQ', name: 'Not Equals To', selected: false },
                    { operator: 'LT', name: 'Less Than', selected: false },
                    { operator: 'LTE', name: 'Less Than Equal To', selected: false },
                    { operator: 'GT', name: 'Greater Than', selected: false },
                    { operator: 'GTE', name: 'Greater Than Equal To', selected: false },
                    { operator: 'BW', name: 'Between', selected: false },
                ],
                values: {
                    values: (new Array(2)).fill(0),
                    unit: '',
                    operator: '' // LT GT EQ NEQ LEQ GEQ BT 
                }
            },
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        }
    ]);

    const actionButtonGenerator = (row:any) => {
        
        return (
            <div className="w-20">
                <button 
                    aria-label="view"
                    className=""
                    onClick={()=>{
                        sideDrawerContext.setLevelOneDrawerVisibility(true)
                        sideDrawerContext.setFirstDrawerChild(<FirstDrawerComponent selectedTableData={selectedTableData} endpoint={row} />)
                        
                    }}
                >
                    <em className="fa-regular fa-eye text-[#FF5E0E]"></em>
                </button>
            </div>
        )
    }

    const tableDataFormatter = (rowObj: any, format?: string | undefined, rowIndex?: number) => {
        let returningVal = null;
        switch (format) {
            case 'Hostname':
                if( rowIndex === 0 ) {
                    returningVal = <div className="relative group">
                            <div className="max-w-[150px] truncate">{rowObj?.text || ""}</div>
                            <Tooltip direction='down' value={rowObj?.raw || ""} />
                        </div>
                } else {
                    returningVal = rowObj.node
                }
                break;

            
            case 'OS':
                if( rowIndex === 0 ) {
                    returningVal = <span className="relative group">
                        <span className="">{deviceSymbol(rowObj.text)}</span>
                        <Tooltip direction='down' value={rowObj?.text || 'Unknown'} />
                    </span>
                } else {
                    returningVal = rowObj.node
                }
                break;

            case 'Last Connection':
                if( rowIndex === 0 ) {
                    returningVal = <div className="relative group">
                                        <span className="">{rowObj?.text}</span>
                                        <Tooltip direction='down' value={lastBackupFormatter(rowObj.raw) || ""} />
                                    </div>
                } else {
                    returningVal = rowObj.node
                }
                break;
            // case 'Device Id':
            //     returningVal = (<>
            //         <span className="text-sm hover:text-orange-600 font-medium text-[#FF5e0e] opacity-80 cursor-pointer ">{rowObj}</span>
            //     </>);
            //     break;

            // case 'Device':
            //     returningVal=(
            //         <span className="text-sm hover:text-gray-900 font-medium text-[#3A3541] opacity-80 cursor-pointer  ">{rowObj}</span>
            //         );
            //     break;
            
            case 'Device Status (Current)':
                if( rowIndex === 0 ) {
                    returningVal = <div className="relative group">
                                        <span className={`text-xs px-3 py-1 
                                            ${rowObj?.text === 'Pending' && 'text-orange-500 font-light bg-orange-100'}
                                            ${rowObj?.text === 'Deleted' && 'text-red-500 font-light bg-red-100'}
                                            ${rowObj?.text === 'Unprotected' && 'text-red-500 font-light bg-red-100'}
                                            ${rowObj?.text === 'Disabled' && 'text-violet-500 font-light bg-violet-100'}
                                            ${rowObj?.text === 'Protected' && 'text-green-500 font-light bg-green-100'}
                                             max-w-min rounded-full`}>{rowObj?.text}</span>
                                        {rowObj?.text === 'Disabled' && <Tooltip direction='down' value={rowObj?.reason || ""} />}
                                    </div>
                } else {
                    returningVal = rowObj.node
                }
                break;

            // case 'Last Connection':
            //     returningVal=(
            //         <span className="text-sm hover:text-gray-900 font-medium text-[#3A3541] opacity-80">{rowObj}</span>

            //     );
            //     break;

            // case 'Last Backup ':
            //     returningVal=(
            //         <span className="text-sm hover:text-gray-900 font-medium text-[#3A3541] opacity-80">{rowObj}</span>
            //     );
            //     break;

            // case 'Protected Data':
            //       returningVal=(
            //         <>
            //         <span className="text-sm font-medium text-green-500 opacity-80 cursor-pointer text-center flex justify-center ">{rowObj}</span>
            //         </>
            //     );
            //     break;

             default:
                returningVal = rowObj.node;
                break;
        }
        return returningVal;
    }

    const getPolicyStrategy = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints/config`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{

        const fetchStrategyPolicies = async () => {
            await getPolicyStrategy().then((data)=>{
                setStrategyPolicies(data)
            }).catch((error)=>{
                console.log(error);
            }).finally(()=>{ })
        }


        console.log({auditContext})
        setSelectedData(auditContext?.searchParams?.reportType || 'All')
        setSelectedTableData(auditContext?.searchParams?.reportType || 'All')

        console.log({auditContext})

        fetchStrategyPolicies()

        // setTableController((prevState:any)=>{
        //     let newState = {...prevState}
        //     newState.data = auditContext?.searchParams?.data || [];
        //     return newState;
        // })
        // setTableDataBackup(auditContext?.searchParams?.data || [])
    },[])

    useEffect(()=>{

        sideDrawerContext.setLevelOneDrawerVisibility(false)

        if( selectedTableData === 'All' ) {
            let filteredData = (auditContext?.searchParams?.data || []).filter((device:any)=>{ return (device?.activity || []).length > 0 });
            setTableController((prevState:any)=>{
                let newState = {...prevState}
                newState.data = filteredData;
                return newState;
            })
            setTableDataBackup( filteredData )
        }

        if( selectedTableData === 'Backup' ) {
            let filteredData = (auditContext?.searchParams?.data || []).filter((activity:any)=>{ return activity.activity.includes('Backup') });
            setTableController((prevState:any)=>{
                let newState = {...prevState}
                newState.data = filteredData
                return newState;
            })
            setTableDataBackup( filteredData )
        }

        if( selectedTableData === 'Restore' ) {
            let filteredData = (auditContext?.searchParams?.data || []).filter((activity:any)=>{ return activity.activity.includes('Restore') });
            setTableController((prevState:any)=>{
                let newState = {...prevState}
                newState.data = filteredData;
                return newState;
            })
            setTableDataBackup( filteredData )
        }

        if( selectedTableData === 'Policy' ) {
            let filteredData = (auditContext?.searchParams?.data || []).filter((activity:any)=>{ return activity.activity.includes('Policy') });
            setTableController((prevState:any)=>{
                let newState = {...prevState}
                newState.data = filteredData;
                return newState;
            })
            setTableDataBackup( filteredData )
        }

        if( selectedTableData === 'Strategy' ) {
            let filteredData = (auditContext?.searchParams?.data || []).filter((activity:any)=>{ return activity.activity.includes('Strategy') });
            setTableController((prevState:any)=>{
                let newState = {...prevState}
                newState.data = filteredData;
                return newState;
            })
            setTableDataBackup( filteredData )
        }

        if( selectedTableData === 'Installed' ) {
            let filteredData = (auditContext?.searchParams?.data || []).filter((ep:any)=>{
                if(
                    ep.installed_at.text !== 'NA' && 
                    new Date(ep.installed_at.text) >= auditContext.searchParams.range.startDate &&
                    new Date(ep.installed_at.text) <= auditContext.searchParams.range.endDate
                ) { return true }
            })
            setTableController((prevState:any)=>{
                let newState = {...prevState}
                newState.data = filteredData
                return newState;
            })

            setTableDataBackup( filteredData )
        }

        if( selectedTableData === 'Deleted' ) {
            let filteredData = (auditContext?.searchParams?.data || []).filter((ep:any)=>{
                if(
                    ep.deleted_at.text !== 'NA' && 
                    new Date(ep.deleted_at.text) >= auditContext.searchParams.range.startDate &&
                    new Date(ep.deleted_at.text) <= auditContext.searchParams.range.endDate
                ) { return true }
            })
            setTableController((prevState:any)=>{
                let newState = {...prevState}
                newState.data = filteredData;
                return newState;
            })
            setTableDataBackup( filteredData )
        }

        if( selectedTableData === 'No Activity' ) {
            let device_deleted_at = new Date(auditContext?.searchParams?.data[21]?.deleted_at?.text)
            let filter_start_date = auditContext?.searchParams?.range?.startDate
            
            console.log({ifDeletedDateBeforeFilterStartDate: device_deleted_at < filter_start_date})

            let filteredData = (auditContext?.searchParams?.data || [])
                                    .filter((device:any)=>{ 
                                        if( device?.deleted_at?.text === 'NA' || new Date(device?.deleted_at?.text) > auditContext.searchParams.range.startDate ) {
                                            return true
                                        }
                                        return false
                                    })
                                    .filter((device:any)=>{ return (device?.activity || []).length < 1 });
            setTableController((prevState:any)=>{
                let newState = {...prevState}
                newState.data = filteredData
                return newState;
            })
            setTableDataBackup( filteredData )
        }

    },[selectedTableData])

    useEffect(()=>{
        if(tableController.searchTerm.length < 1) {
            if(tableDataBackup) {
                setTableController((prevState)=>{
                    let newState = {...prevState};
                    newState.data = tableDataBackup;
                    return newState;
                })
                // setSelectedData('All');
            }
            return;
        }

        let searchedNodes = (tableDataBackup || []).filter((ep:any) => {
            console.log({ep})
            if(
                ( ep?.device_id?.text || '' ).toString().toLowerCase().includes(tableController.searchTerm.toLowerCase()) ||
                ( ep?.hostname?.text || '' ).toLowerCase().includes(tableController.searchTerm.toLowerCase()) ||
                ( ep?.os?.text || '' ).toLowerCase().includes(tableController.searchTerm.toLowerCase()) ||
                ( ep?.status?.text || '' ).toLowerCase().includes(tableController.searchTerm.toLowerCase()) ||

                ( ep?.lastConnection?.text || '' ).toLowerCase().includes(tableController.searchTerm.toLowerCase()) ||

                ( ep?.lastBackup?.text || '' ).toLowerCase().includes(tableController.searchTerm.toLowerCase()) ||
                ( ep?.protectedData?.text || '' ).toLowerCase().includes(tableController.searchTerm.toLowerCase()) ||

                
                (getFormattedDateTime( ep?.installed_at?.text || '' ) || '').toLowerCase().includes(tableController.searchTerm.toLowerCase()) ||
                (getFormattedDateTime( ep?.deleted_at?.text || '' ) || '').toLowerCase().includes(tableController.searchTerm.toLowerCase())
            ) {
                return ep;
            }
        }) || [];

        setTableController((prevState)=>{
            let newState = {...prevState};
            // setSelectedData('Search');
            newState.data = searchedNodes || [];
            return newState;
        })

    },[tableController.searchTerm]);

    const handleDataSorting = (column:any) => {

        let columnName = column?.name;
        let tableData = [...tableController?.data];
        let returningValue: {[key: string]: any}[] = []

        switch (columnName) {
            case 'Device Id':
                let sortedByDeviceId = SortWithDeviceId( column?.sorting?.direction, tableData )
                if( sortedByDeviceId )
                    returningValue = sortedByDeviceId
                break;

            case 'Hostname':
                let sortedHostnameResult = SortWithHostname( column?.sorting?.direction, tableData )
                if( sortedHostnameResult )
                    returningValue = sortedHostnameResult

                break;
                
            case 'OS':
                let sortedOSResult = SortWithOS( column?.sorting?.direction, tableData )
                if( sortedOSResult )
                    returningValue = sortedOSResult
                break;
            
            case 'Device Status':
                let sortedDeviceStatusResult = SortWithDeviceStatus( column?.sorting?.direction, tableData )
                if( sortedDeviceStatusResult )
                    returningValue = sortedDeviceStatusResult

                break;
        
            case 'Last Connection':
                let sortedLastConnectionResult = SortWithLastConnection( column?.sorting?.direction, tableData )
                if( sortedLastConnectionResult )
                    returningValue = sortedLastConnectionResult

                break;

            case 'Last Backup':
                let sortedLastBackupResult = SortWithLastBackup( column?.sorting?.direction, tableData )
                if( sortedLastBackupResult )
                    returningValue = sortedLastBackupResult

                break;

            case 'Protected Data':
                let sortedProtectedDataResult = SortWithProtectedData( column?.sorting?.direction, tableData )
                if( sortedProtectedDataResult )
                    returningValue = sortedProtectedDataResult
                break;

            case 'Installed On':
                let sortedInstalledDateResult = SortWithInstalledDate( column?.sorting?.direction, tableData )
                if( sortedInstalledDateResult )
                    returningValue = sortedInstalledDateResult
                break;

            case 'Deleted On':
                let sortedDeletedDateResult = SortWithDeletedData( column?.sorting?.direction, tableData )
                if( sortedDeletedDateResult )
                    returningValue = sortedDeletedDateResult
                break;
        
            default:
                returningValue = tableData
                break;
        }

        console.log({columnName, tableData, column, returningValue})

        setTableController((previousState:ITableControllerProps)=>{
            let newState = {...previousState}
            newState.data = returningValue
            return newState
        })


    }

    
    return (
        <>
            <div className={`p-6 grow flex flex-col`}>
                <span className="flex flex-col lg:flex-row items-start">
                    <span className="flex-col">
                        <div className="flex flex-row">
                            <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Endpoint Audit Trail Result1 </h1>
                            <h3 className="inline text-sm font-normal text-gray-500 -ml-2 mt-1">of date range&nbsp;
                                <span className="font-semibold whitespace-nowrap">{getFormattedDateTime((auditContext?.searchParams?.range?.startDate).toISOString())?.split(',')[0]}
                                &nbsp;&mdash;&nbsp;
                                {getFormattedDateTime((auditContext?.searchParams?.range?.endDate).toISOString())?.split(',')[0]}&nbsp;
                                ({Math.ceil((auditContext?.searchParams?.range?.endDate - auditContext?.searchParams?.range?.startDate)/(1000*60*60*24))} Days)</span>
                                &nbsp;
                                {/* &mdash;&nbsp; */}
                                for
                                &nbsp;
                                <span className="font-semibold whitespace-nowrap">{auditContext?.searchParams?.window?.value}
                                &nbsp;
                                {auditContext?.searchParams?.window?.unit === 'h' && 'hour'}
                                {auditContext?.searchParams?.window?.unit === 'd' && 'day'}
                                {auditContext?.searchParams?.window?.unit === 'w' && 'week'}
                                {auditContext?.searchParams?.window?.unit === 'm' && 'month'}
                                &nbsp;window</span>
                            </h3>
                        </div>
                        
                        <Breadcrumb arrBreadcrumb={[
                            { icon: 'fa-solid fa-bell-on', link: '/dashboard', name: 'Home' },
                            { icon: '', link: '', name: 'Managed Services' },
                            { icon: '', link: '/endpoint', name: 'Endpoints' },
                            { icon: '', link: '/endpoint/audit/search', name: 'Audit Trail' },
                            { icon: '', link: '', name: 'Result' }
                        ]} />
                    </span>
                    <span className="grow"></span>
                    <div className="flex gap-2 z-50 mb-10">
                        <select 
                            aria-label="report type"
                            className="outline-none w-56 px-4 py-2 border border-gray-700 rounded-md"
                            onChange={(e)=>{
                                // console.log(e.target.value)
                                setSelectedData(e?.target?.value || 'All')
                                setSelectedTableData(e.target.value || 'All')
                            }}
                        >
                            <option value="All" selected={selectedData === 'All' ? true : false}>All</option>
                            <option value="Backup" selected={selectedData === 'Backup' ? true : false}>Backup</option>
                            <option value="Restore" selected={selectedData === 'Restore' ? true : false}>Restore</option>
                            <option value="Policy" selected={selectedData === 'Policy' ? true : false}>Policy Change</option>
                            <option value="Strategy" selected={selectedData === 'Strategy' ? true : false}>Strategy Change</option>
                            <option value="Deleted" selected={selectedData === 'Deleted' ? true : false}>Deleted</option>
                            <option value="Installed" selected={selectedData === 'Installed' ? true : false}>Installed</option>
                            <option value="No Activity" selected={selectedData === 'No Activity' ? true : false}>No Activity</option>
                        </select>
                        <Button
                            onClick={()=>{
                                sideDrawerContext.setFirstDrawerChild(<MoreActionsComponent auditContext={auditContext} strategyPolicies={strategyPolicies} selectedData={selectedData} />)
                                sideDrawerContext.setLevelOneDrawerVisibility(true)
                            }}
                            className="group h-10 w-10 hover:w-36"
                            size="sm"
                        >
                            <i className="fa-solid fa-ellipsis-vertical"></i>
                            <span className="ml-3 hidden group-hover:inline">More Actions</span>
                        </Button>
                    </div>
                </span>
                <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mb-6">
                    {(selectedData === 'All') && <div className="w-full">
                        <Tile5x4_ChartText 
                            sideBar={false}
                            clickNavigate={false}
                            navigationPath='/'
                            title={
                                < div className="flex items-center">
                                    All Devices - <span className="ml-2 mt-1 text-base border-b border-white text-orange-500 font-normal">
                                    {(auditContext?.searchParams?.data || []).filter((device:any)=>{ return (device?.activity || []).length > 0 }).length}/{(auditContext?.searchParams?.data || []).length} Devices</span> 
                                </div>}
                            description=""
                            value={<em className="fa-duotone fa-laptop text-5xl text-[#ff5e0e]"></em>}
                            className='border relative'
                            tileMode='default'
                            alert={false}
                            live={false}
                            tileAction={true}
                            actionHandler={()=>{
                                setSelectedTableData('All')
                            }}
                        > 
                            <div className="text-4xl text-[#ff5e0e] w-28 h-28 flex items-end mb-8 text-right rounded-md absolute whitespace-nowrap right-4">
                                <div className="flex items-end absolute right-2">
                                    {(auditContext?.searchParams?.data || [])
                                    .reduce((a:number,device:any)=>{ 
                                        a = a + (device?.backup_activities || []).length + (device?.restore_activities || []).length + (device?.policy_activities || []).length + (device?.strategy_activities || []).length; 
                                        return a
                                    },0) }
                                    <div className="text-lg ml-2">Activities</div>
                                </div>
                            </div>
                        </Tile5x4_ChartText>
                    </div>}
                    {(selectedData === 'Backup' || selectedData === 'All') && <div className="w-full">
                        <Tile5x4_ChartText 
                            sideBar={false}
                            clickNavigate={false}
                            navigationPath='/'
                            title={
                                < div className="flex items-center">
                                        Total Backup - <span className="ml-2 mt-1 text-base border-b border-white text-orange-500 font-normal">
                                            {(auditContext?.searchParams?.data || []).reduce((a:number,d:any)=>{
                                                if( (d?.activity || []).includes('Backup') ) { 
                                                    a = a + 1;
                                                } else {
                                                    a = a + 0;
                                                }
                                                return a; 
                                            },0)} Devices
                                        </span>
                                </div>}
                            description=""
                            value={<em className="fa-duotone fa-upload text-5xl text-[#ff5e0e]"></em>}
                            className='border relative'
                            tileMode='default'
                            alert={false}
                            live={false}
                            tileAction={true}
                            actionHandler={()=>{
                                setSelectedTableData('Backup')
                            }}
                        >
                            <div className="text-4xl text-[#ff5e0e] w-28 h-28 flex items-end mb-8 text-right rounded-md absolute whitespace-nowrap right-4">
                                <div className="flex items-end absolute right-2">
                                {
                                    formatSize(dataSizeConversion((auditContext?.searchParams?.data || [])
                                    .map((ep:any)=>{ return ep.backup_activities.reduce((a:number,ac:any)=>{ a = a + ac._value; return a  },0) })
                                    .reduce((a:number,temp:number)=>{ a = a + temp; return a  },0),'MB','B').value).value
                                } 
                                <div className="text-lg ml-2">
                                    {
                                        formatSize(dataSizeConversion((auditContext?.searchParams?.data || [])
                                        .map((ep:any)=>{ return ep.backup_activities.reduce((a:number,ac:any)=>{ a = a + ac._value; return a  },0) })
                                        .reduce((a:number,temp:number)=>{ a = a + temp; return a  },0),'MB','B').value).unit
                                    }
                                </div>
                                </div>
                            </div>
                        </Tile5x4_ChartText>
                    </div>}
                    {(selectedData === 'Restore' || selectedData === 'All') && <div className="w-full">
                        <Tile5x4_ChartText 
                            sideBar={false}
                            clickNavigate={false}
                            navigationPath='/'
                            title={
                                < div className="flex items-center">
                                    Total Restore - <span className="ml-2 mt-1 text-base border-b border-white text-orange-500 font-normal">
                                        {(auditContext?.searchParams?.data || []).reduce((a:number,d:any)=>{
                                            if( (d?.activity || []).includes('Restore') ) { 
                                                a = a + 1;
                                            } else {
                                                a = a + 0;
                                            }
                                            return a; 
                                        },0)} Devices
                                    </span>
                                </div>}
                            description=""
                            value={<em className="fa-duotone fa-download text-5xl text-[#ff5e0e]"></em>}
                            className='border relative'
                            tileMode='default'
                            alert={false}
                            live={false}
                            tileAction={true}
                            actionHandler={()=>{
                                setSelectedTableData('Restore')
                            }}
                        > 
                            <div className="text-4xl text-[#ff5e0e] w-28 h-28 flex items-end mb-8 text-right rounded-md absolute whitespace-nowrap right-4">
                                <div className="flex items-end absolute right-2">
                                {
                                    formatSize(dataSizeConversion((auditContext?.searchParams?.data || [])
                                    .map((ep:any)=>{ return ep.restore_activities.reduce((a:number,ac:any)=>{ a = a + ac._value; return a  },0) })
                                    .reduce((a:number,temp:number)=>{ a = a + temp; return a  },0),'MB','B').value).value
                                }
                                <div className="text-lg ml-2">
                                    {
                                        formatSize(dataSizeConversion((auditContext?.searchParams?.data || [])
                                        .map((ep:any)=>{ return ep.restore_activities.reduce((a:number,ac:any)=>{ a = a + ac._value; return a  },0) })
                                        .reduce((a:number,temp:number)=>{ a = a + temp; return a  },0),'MB','B').value).unit
                                    }
                                </div>
                                </div>
                            </div>
                        </Tile5x4_ChartText>
                    </div>}
                    {(selectedData === 'Policy' || selectedData === 'All') && <div className="w-full">
                        <Tile5x4_ChartText 
                            sideBar={false}
                            clickNavigate={false}
                            navigationPath='/'
                            title={
                                < div className="flex items-center">
                                    Policy Change - <span className="ml-2 mt-1 text-base border-b border-white text-orange-500 font-normal">
                                    {(auditContext?.searchParams?.data || []).reduce((a:number,d:any)=>{
                                        if( (d?.activity || []).includes('Policy') ) { 
                                            a = a + 1;
                                        } else {
                                            a = a + 0;
                                        }
                                        return a; 
                                    },0)} Devices
                                </span>
                            </div>}
                            description=""
                            value={<em className="fa-duotone fa-ballot-check text-5xl text-[#ff5e0e]"></em>}
                            className='border relative'
                            tileMode='default'
                            alert={false}
                            live={false}
                            tileAction={true}
                            actionHandler={()=>{
                                setSelectedTableData('Policy')
                            }}
                        >
                            <div className="text-4xl text-[#ff5e0e] w-28 h-28 flex items-end mb-8 text-right rounded-md absolute whitespace-nowrap right-4">
                                <div className="flex items-end absolute right-2">{(auditContext?.searchParams?.data || [])
                                    .reduce((a:number,ep:any)=>{ a = a + (ep?.policy_activities || []).length; return a  },0)
                                } <div className="text-lg ml-2">Changes</div>
                                </div>
                            </div>
                        </Tile5x4_ChartText>
                    </div>}
                    {(selectedData === 'Strategy' || selectedData === 'All') && <div className="w-full">
                        <Tile5x4_ChartText 
                            sideBar={false}
                            clickNavigate={false}
                            navigationPath='/'
                            title={<div className="flex items-center">Strategy Change - <span className="ml-2 mt-1 text-base border-b border-white text-orange-500 font-normal">
                                {(auditContext?.searchParams?.data || []).reduce((a:number,d:any)=>{
                                    if( (d?.activity || []).includes('Strategy') ) { 
                                        a = a + 1;
                                    } else {
                                        a = a + 0;
                                    }
                                    return a; 
                                },0)} Devices
                            </span>
                        </div>}
                            description=""
                            value={<em className="fa-duotone fa-chess-knight text-5xl text-[#ff5e0e]"></em>}
                            className='border relative'
                            tileMode='default'
                            alert={false}
                            live={false}
                            tileAction={true}
                            actionHandler={()=>{
                                setSelectedTableData('Strategy')
                            }}
                        >
                            <div className="text-4xl text-[#ff5e0e] w-28 h-28 flex items-end mb-8 text-right rounded-md absolute whitespace-nowrap right-4">
                                <div className="flex items-end absolute right-2">{(auditContext?.searchParams?.data || [])
                                    .reduce((a:number,ep:any)=>{ a = a + (ep?.strategy_activities || []).length; return a  },0)
                                } <div className="text-lg ml-2">Changes</div>
                                </div>
                            </div>
                        </Tile5x4_ChartText>
                    </div>}
                    {(selectedData === 'Installed' || selectedData === 'All') && <div className="w-full">
                        <Tile5x4_ChartText 
                            sideBar={false}
                            clickNavigate={false}
                            navigationPath='/'
                            title={
                                < div className="flex items-center">
                                    Installed Devices 
                                    {/* - <span className="ml-2 mt-1 text-base border-b border-white text-orange-500 font-normal">{
                                    (auditContext?.searchParams?.data || []).filter((ep:any)=>{
                                        if(
                                            ep.installed_at.text !== 'NA' && 
                                            new Date(ep.installed_at.text) >= auditContext.searchParams.range.startDate &&
                                            new Date(ep.installed_at.text) <= auditContext.searchParams.range.endDate
                                        ) { return true }
                                    }).length
                                } Devices
                                </span> */}
                                </div>}
                            description=""
                            value={<em className="fa-duotone fa-box-circle-check text-5xl text-[#ff5e0e]"></em>}
                            className='border relative'
                            tileMode='default'
                            alert={false}
                            live={false}
                            tileAction={true}
                            actionHandler={()=>{
                                setSelectedTableData('Installed')
                            }}
                        >
                            <div className="text-4xl text-[#ff5e0e] w-28 h-28 flex items-end mb-8 text-right rounded-md absolute whitespace-nowrap right-4">
                                <div className="flex items-end absolute right-2">
                                    {/* {
                                    (auditContext?.searchParams?.data || []).filter((ep:any)=>{
                                        if(
                                            ep.installed_at.text !== 'NA' && 
                                            new Date(ep.installed_at.text) >= auditContext.searchParams.range.startDate &&
                                            new Date(ep.installed_at.text) <= auditContext.searchParams.range.endDate
                                        ) { return true }
                                    }).reduce((a:number, ac:any)=>{ 
                                        a = a + (ac?.backup_activities || []).length + (ac?.restore_activities || []).length + (ac?.policy_activities || []).length + (ac?.strategy_activities || []).length
                                        return a
                                    },0)
                                } <div className="text-lg ml-2">Activities</div> */}

                                {
                                    (auditContext?.searchParams?.data || []).filter((ep:any)=>{
                                        if(
                                            ep.installed_at.text !== 'NA' && 
                                            new Date(ep.installed_at.text) >= auditContext.searchParams.range.startDate &&
                                            new Date(ep.installed_at.text) <= auditContext.searchParams.range.endDate
                                        ) { return true }
                                    }).length
                                } <div className="text-lg ml-2">
                                    {
                                        (auditContext?.searchParams?.data || []).filter((ep:any)=>{
                                            if(
                                                ep.installed_at.text !== 'NA' && 
                                                new Date(ep.installed_at.text) >= auditContext.searchParams.range.startDate &&
                                                new Date(ep.installed_at.text) <= auditContext.searchParams.range.endDate
                                            ) { return true }
                                        }).length === 1 ?
                                        'Device' :
                                        'Devices'
                                    }
                                </div>

                                </div>
                            </div>
                        </Tile5x4_ChartText>
                    </div>}
                    {(selectedData === 'Deleted' || selectedData === 'All') && <div className="w-full">
                        <Tile5x4_ChartText 
                            sideBar={false}
                            clickNavigate={false}
                            navigationPath='/'
                            title={
                                < div className="flex items-center">
                                    Deleted Devices
                                     {/* - <span className="ml-2 mt-1 text-base border-b border-white text-orange-500 font-normal">{
                                    (auditContext?.searchParams?.data || []).filter((ep:any)=>{
                                        if(
                                            ep.deleted_at.text !== 'NA' && 
                                            new Date(ep.deleted_at.text) >= auditContext.searchParams.range.startDate &&
                                            new Date(ep.deleted_at.text) <= auditContext.searchParams.range.endDate
                                        ) { return true }
                                    }).length
                                } Devices</span> */}
                                </div>}
                            description=""
                            value={<em className="fa-duotone fa-trash text-5xl text-[#ff5e0e]"></em>}
                            className='border relative'
                            tileMode='default'
                            alert={false}
                            live={false}
                            tileAction={true}
                            actionHandler={()=>{
                                setSelectedTableData('Deleted')
                            }}
                        >
                            <div className="text-4xl text-[#ff5e0e] w-28 h-28 flex items-end mb-8 text-right rounded-md absolute whitespace-nowrap right-4">
                                {/* <div className="flex items-end absolute right-2">{(auditContext?.searchParams?.data || []).filter((ep:any)=>{
                                        if(
                                            ep.deleted_at.text !== 'NA' && 
                                            new Date(ep.deleted_at.text) >= auditContext.searchParams.range.startDate &&
                                            new Date(ep.deleted_at.text) <= auditContext.searchParams.range.endDate
                                        ) { return true }
                                    }).reduce((a:number, ac:any)=>{ 
                                        a = a + (ac?.backup_activities || []).length + (ac?.restore_activities || []).length + (ac?.policy_activities || []).length + (ac?.strategy_activities || []).length
                                        return a
                                    },0)
                                } <div className="text-lg ml-2">Activities</div>
                                </div> */}
                                <div className="flex items-end absolute right-2">{
                                        (auditContext?.searchParams?.data || []).filter((ep:any)=>{
                                            if(
                                                ep.deleted_at.text !== 'NA' && 
                                                new Date(ep.deleted_at.text) >= auditContext.searchParams.range.startDate &&
                                                new Date(ep.deleted_at.text) <= auditContext.searchParams.range.endDate
                                            ) { return true }
                                        }).length
                                    } 
                                    <div className="text-lg ml-2">
                                        {
                                            (auditContext?.searchParams?.data || []).filter((ep:any)=>{
                                                if(
                                                    ep.deleted_at.text !== 'NA' && 
                                                    new Date(ep.deleted_at.text) >= auditContext.searchParams.range.startDate &&
                                                    new Date(ep.deleted_at.text) <= auditContext.searchParams.range.endDate
                                                ) { return true }
                                            }).length === 1 ? 'Device' : 'Devices'
                                        }
                                    </div>
                                </div>
                            </div>
                        </Tile5x4_ChartText>
                    </div>}
                    {(selectedData === 'No Activity' || selectedData === 'All') && <div className="w-full">
                        <Tile5x4_ChartText 
                            sideBar={false}
                            clickNavigate={false}
                            navigationPath='/'
                            title={
                                < div className="flex items-center">
                                    No Activity Devices 
                                    {/* - <span className="ml-2 mt-1 text-base border-b border-white text-orange-500 font-normal">
                                    {(auditContext?.searchParams?.data || []).filter((device:any)=>{ return (device?.activity || []).length < 1 }).length}/{(auditContext?.searchParams?.data || []).length} Devices
                                    </span>  */}
                                </div>}
                            description=""
                            value={<em className="fa-duotone fa-ban text-5xl text-[#ff5e0e]"></em>}
                            className='border relative'
                            tileMode='default'
                            alert={false}
                            live={false}
                            tileAction={true}
                            actionHandler={()=>{
                                setSelectedTableData('No Activity')
                            }}
                        >
                            <div className="text-4xl text-[#ff5e0e] w-28 h-28 flex items-end mb-8 text-right rounded-md absolute whitespace-nowrap right-4">
                                <div className="flex items-end absolute right-2">
                                    {(auditContext?.searchParams?.data || [])
                                        .filter((device:any)=>{ 
                                            if( device?.deleted_at?.text === 'NA' || new Date(device?.deleted_at?.text) > auditContext.searchParams.range.startDate ) {
                                                return true
                                            }
                                            return false
                                        })
                                        .filter((device:any)=>{ return (device?.activity || []).length < 1 })
                                        .length
                                    } 
                                    <div className="text-lg ml-2">
                                        {(auditContext?.searchParams?.data || [])
                                            .filter((device:any)=>{ 
                                                if( device?.deleted_at?.text === 'NA' || new Date(device?.deleted_at?.text) > auditContext.searchParams.range.startDate ) {
                                                    return true
                                                }
                                                return false
                                            })
                                            .filter((device:any)=>{ return (device?.activity || []).length < 1 })
                                            .length === 1 ? 'Device' : 'Devices'
                                        }
                                    </div>
                                </div>
                            </div>
                        </Tile5x4_ChartText>
                    </div>}
                </div>
                <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
                    <Tile colSpan={12} rowSpan={25} className="pt-2 relative mb-6">
                        <div className="p-4 flex flex-col md:items-center md:align-middle md:justify-center md:flex-row md:gap-4 gap-2">
                            <div className=" text-gray-800 font-medium whitespace-nowrap">
                                {selectedTableData === 'All' && 'All Devices with Activity'}
                                {selectedTableData === 'Backup' && 'Devices with Backup Activity'}
                                {selectedTableData === 'Restore' && 'Devices with Restore Activity'}
                                {selectedTableData === 'Strategy' && 'Devices with Strategy Change Activity'}
                                {selectedTableData === 'Policy' && 'Devices with Policy Change Activity'}
                                {selectedTableData === 'Installed' && 'Devices installed in selected date range'}
                                {selectedTableData === 'Deleted' && 'Devices deleted in selected date range'}
                                {selectedTableData === 'No Activity' && 'Devices with no activity in selected date range'}
                            </div>
                            <div className="flex gap-4">
                                <label htmlFor="searchBox" className="search flex items-center border rounded-md px-3 py-[2px]">
                                    <em className="fa-solid fa-search text-slate-400 pr-2"></em>
                                    <input 
                                        type="text" 
                                        id="searchBox"
                                        placeholder="Search" 
                                        className="outline-none w-full" 
                                        value={tableController.searchTerm} 
                                        onChange={(e)=>{
                                            setTableController((prevState)=>{
                                                let newState = {...prevState};
                                                newState.searchTerm = e.target.value;
                                                return newState;
                                            });
                                        }}
                                    />
                                    <em className="fa-solid fa-times text-slate-200 hover:text-slate-500 cursor-pointer pl-2"
                                        onClick={()=>{
                                            setTableController((prevState)=>{
                                                let newState = {...prevState};
                                                newState.searchTerm = '';
                                                return newState;
                                            });
                                        }}
                                    ></em>
                                </label>
                                {/* <Filter tableColumns={tableColumns} setTableColumns={setTableColumns} tableController={tableController} /> */}
                            </div>
                            <div className="grow"></div>
                            {/* <ButtonGroup className="border hidden lg:block whitespace-nowrap">
                                <Button size='sm' className="" solid={selectedData === 'All' || selectedData === '' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('All')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState}
                                            newState.data = auditModifiedData?.changedAgents || [];
                                            return newState;
                                        })
                                    }}
                                >All</Button>
                                <Button size='sm' className="" solid={selectedData === 'Protected' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Protected')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState}
                                            newState.data = (auditModifiedData?.changedAgents || []).filter((d:any)=>{
                                                return d.status.text === 'Protected'
                                            });
                                            return newState;
                                        })
                                    }}
                                >Protected</Button>
                                <Button size='sm' className="" solid={selectedData === 'Unprotected' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Unprotected')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState}
                                            newState.data = (auditModifiedData?.changedAgents || []).filter((d:any)=>{
                                                return d.status.text === 'Unprotected'
                                            });
                                            return newState;
                                        })
                                    }}
                                >Unprotected</Button>
                                <Button size='sm' className="" solid={selectedData === 'Installed' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Installed')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState}
                                            newState.data = (auditModifiedData?.changedAgents || []).filter((d:any)=>{
                                                return d.status.text === 'Installed'
                                            });
                                            return newState;
                                        })
                                    }}
                                >Installed</Button>
                                <Button size='sm' className="" solid={selectedData === 'Pending Installation' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Pending Installation')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState}
                                            newState.data = (auditModifiedData?.changedAgents || []).filter((d:any)=>{
                                                return d.status.text === 'Pending Installation'
                                            });
                                            return newState;
                                        })
                                    }}
                                >Pending Installation</Button>
                                <Button size='sm' className="" solid={selectedData === 'Deleted' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Deleted')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState}
                                            newState.data = (auditModifiedData?.changedAgents || []).filter((d:any)=>{
                                                return d.status.text === 'Deleted'
                                            });
                                            return newState;
                                        })
                                    }}
                                >Deleted</Button>
                                <Button size='sm' className="" solid={selectedData === 'Devices with no change' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Devices with no change')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState}
                                            newState.data = auditModifiedData?.noChangeAgents || [];
                                            return newState;
                                        })
                                    }}
                                >Devices without change</Button>
                            </ButtonGroup> */}
                        </div>
                        <div className="p-4 flex flex-row">
                            <div className="absolute left-0 right-0 sm:top-[80px] top-[110px] bottom-0 h-auto w-auto overflow-hidden">
                                <Table
                                    navRef={navRaf}
                                    actionButtonGenerator={actionButtonGenerator}
                                    columns={tableColumns}
                                    setColumns={setTableColumns}
                                    formatData={tableDataFormatter}
                                    showActionCell={true}
                                    showActionHeaderCell={true}
                                    showColumnManagementMenu={false}
                                    // switchHandler={handleSwitch}
                                    tableController={tableController}
                                    dataSortingHandler={handleDataSorting}
                                    tableId="DeviceTable"
                                />
                            </div>
                        </div>
                    </Tile>
                </div>


            </div>
        </>
    )
}

export default EndpointDeviceAuditReport

interface IFirstDrawerComponent {
    // auditModifiedData:any;
    selectedTableData:string;
    endpoint:any;
}

const FirstDrawerComponent:FC<IFirstDrawerComponent> = ({ endpoint }) => {

    const [selectedActivityData, setSelectedActivityData] = useState<any>(null);
    const [deviceAllActivityData, setDeviceAllActivityData] = useState<any>(null);

    const [selectedProperty, setSelectedProperty] = useState<any>(null);
    const [finalProperty, setFinalProperty] = useState<any>(null);
    const [modalVisibility,setModalVisibility] = useState<boolean>(false);

    useEffect(()=>{
        setSelectedActivityData(endpoint)
    },[])

    const getEndpointActivity = async (deviceId:string) => {
        try {
            const response = await postAPICall(
                `${process.env.REACT_APP_API_URL}/analytics/endpoints_recent_activities/${deviceId}`,
                {
                    filter: {
                        // ...activityType[selectedData?.activity_type],
                        // range: requestFilter,
                    }
                }
            );
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{

        if( selectedActivityData === null || selectedActivityData === undefined || selectedActivityData === '' ) {
            return;
        }
        const fetchEndpointActivities = async () => {
            await getEndpointActivity(selectedActivityData?.endpoint_id?.text).then((data)=>{
                if(data?.activities) {
                    setDeviceAllActivityData(data?.activities);
                } else {
                    setDeviceAllActivityData([]);
                }
            }).catch((err)=>{console.log(err)}).finally(()=>{
                // setSelectedActivityClicked(false)
            })
        }

        fetchEndpointActivities();

    },[selectedActivityData])

    useEffect(()=>{
        console.log({selectedProperty})
    },[selectedProperty])

    console.log({endpoint})


    return (
        <>
            <div className="title w-full absolute flex gap-4 px-6 top-0 pt-10 border-b pb-10 bg-slate-100 items-center">
                <i className="fa-regular fa-upload text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i>
                <div className="flex flex-col">
                    <div className="flex text-xl font-bold text-slate-700 cursor-pointer uppercase">
                        ({selectedActivityData?.device_id?.text}) {selectedActivityData?.hostname?.text}
                    </div>
                    <div className="block text-slate-500">
                        Activities
                    </div>
                </div>
            </div>

            <div className="data absolute top-40 px-4 w-full max-h-[80%] overflow-y-scroll">
                {/* {JSON.stringify(endpoint.backup_activities)} */}
                <Accordion className="rounded-lg">
                    <AccordionItem maxHeight="202px" title={<><em className="fa-regular fa-upload text-[#FF5E0E]"></em><span className="ml-2 h-full">Backup &mdash; ( {(endpoint?.backup_activities || []).length} {(endpoint?.backup_activities || []).length === 1 ? 'Activity' : 'Activities'}{(endpoint?.backup_activities || []).length > 0 && ' of ' + 
                        formatSize(dataSizeConversion((endpoint?.backup_activities || []).reduce((a:number,ac:any)=>{ a = a + ac._value; return a },0),'MB','B').value).value + ' ' + formatSize(dataSizeConversion((endpoint?.backup_activities || []).reduce((a:number,ac:any)=>{ a = a + ac._value; return a },0),'MB','B').value).unit
                    } )</span></>}>
                        <div className={`_table flex flex-col w-[405px] md:w-[445px]`}>
                            <div className="overflow-x-scroll customScroll">
                                <div className={`thead bg-slate-50 flex flex-row p-2 border-y items-center w-[545px] overflow-hidden`}>
                                    <div className="row text-gray-800 text-sm flex">
                                        <div className="cell text-left w-12 pl-2">#</div>
                                        {/* <div className="cell text-left w-28">Previous</div> */}
                                        <div className={`cell text-left w-28`}>Value</div>
                                        {/* <div className={`cell text-left w-28`}>Change</div> */}
                                        <div className="cell text-left w-32">Start Time</div>
                                        <div className="cell text-left w-32">Stop Time</div>
                                    </div>
                                </div>
                                <div className={`tbody bg-white w-[545px] overflow-y-scroll max-h-96 h-44 customScroll`}>
                                    <div className="overflow-hidden">
                                    {
                                        (endpoint?.backup_activities || []).length > 0 ?
                                        ((endpoint?.backup_activities || []).map((activity:any,activityIndex:number)=>(
                                            <div 
                                                key={activityIndex}
                                                className="row flex flex-row border-b font-light p-2 px-4 hover:bg-slate-100 text-sm items-center"
                                            >
                                                <div className="cell text-slate-700 w-12 pl-2">{activityIndex+1}</div>
                                                {/* <div className="cell text-slate-700 w-12 pl-2">{endpoint?.backup_activities?.length-activityIndex}</div> */}
                                                {/* <div className="cell text-slate-700 w-28">{((activity?._original || 0).toFixed(2))} MB</div>
                                                <div className="cell text-slate-700 w-28">{((activity?._original || 0).toFixed(2) - (activity?._value || 0).toFixed(2))} MB</div> */}
                                                <div className="cell text-slate-700 w-28">{formatSize(dataSizeConversion((activity?._value || 0).toFixed(2), 'MB', 'B').value).value} {formatSize(dataSizeConversion((activity?._value || 0).toFixed(2), 'MB', 'B').value).unit}</div>
                                                {/* <div className="cell text-slate-700 w-28">{((activity?._original || 0).toFixed(2) - (activity?._value || 0).toFixed(2))} MB</div> */}
                                                {/* <div className={`cell text-slate-700 w-28`}>{(activity?._original || 0).toFixed(2)} MB</div> */}
                                                {/* <div className={`cell text-slate-700 w-28`}>{(activity?._value || 0).toFixed(2)} MB</div> */}
                                                <div className="cell text-slate-700 w-32">{getFormattedDateTime(activity?._start)}</div>
                                                <div className="cell text-slate-700 w-32">{getFormattedDateTime(activity?._stop)}</div>
                                            </div>
                                        ))) :
                                        (
                                                <div className="cell text-center h-[95px] w-[460px] items-center justify-center flex text-slate-600">
                                                    {/* <em className="fa-solid fa-loader text-xl fa-spin"></em> */}
                                                    No backup related activity
                                                </div>
                                        )
                                    }
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </AccordionItem>
                    <AccordionItem maxHeight="202px" title={<><em className="fa-regular fa-download text-[#FF5E0E]"></em><span className="ml-2">Restore &mdash; ( {(endpoint?.restore_activities || []).length} {(endpoint?.restore_activities || []).length === 1 ? 'Activity' : 'Activities'}{(endpoint?.restore_activities || []).length > 0 && ' of ' + 
                        formatSize(dataSizeConversion((endpoint?.restore_activities || []).reduce((a:number,ac:any)=>{ a = a + ac._value; return a },0),'MB','B').value).value + ' ' + formatSize(dataSizeConversion((endpoint?.restore_activities || []).reduce((a:number,ac:any)=>{ a = a + ac._value; return a },0),'MB','B').value).unit
                    } )</span></>}>
                        <div className={`_table flex flex-col h-full overflow-hidden w-[405px] md:w-[445px]`}>
                            <div className="overflow-x-scroll overflow-y-hidden customScroll">
                                <div className={`thead bg-slate-50 flex flex-row p-2 border-y items-center w-[545px]`}>
                                    <div className="row text-gray-800 text-sm flex">
                                    <div className="cell text-left w-12 pl-2">#</div>
                                    {/* <div className="cell text-left w-28">Previous</div> */}
                                    <div className={`cell text-left w-28`}>Value</div>
                                    {/* <div className={`cell text-left w-28`}>Change</div> */}
                                    <div className="cell text-left w-32">Start Time</div>
                                    <div className="cell text-left w-32">Stop Time</div>
                                </div>
                                </div>
                                <div className={`tbody bg-white w-[545px] overflow-y-scroll max-h-96 h-44 customScroll`}>
                                    {
                                        (endpoint?.restore_activities || []).length > 0 ?
                                        ((endpoint?.restore_activities || []).map((activity:any,activityIndex:number)=>(
                                            <div 
                                                key={activityIndex}
                                                className="row flex flex-row border-b font-light p-2 px-4 hover:bg-slate-100 text-sm items-center"
                                            >
                                                <div className="cell text-slate-700 w-12 pl-2">{activityIndex+1}</div>
                                                {/* <div className="cell text-slate-700 w-12 pl-2">{endpoint?.restore_activities?.length-activityIndex}</div> */}
                                                {/* <div className="cell text-slate-700 w-28">{((activity?._original || 0).toFixed(2) - (activity?._value || 0).toFixed(2))} MB</div> */}
                                                {/* <div className={`cell text-slate-700 w-28`}>{(activity?._original || 0).toFixed(2)} MB</div> */}
                                                <div className={`cell text-slate-700 w-28`}>{formatSize(dataSizeConversion((activity?._value || 0).toFixed(2),'MB','B').value).value} {formatSize(dataSizeConversion((activity?._value || 0).toFixed(2),'MB','B').value).unit}</div>
                                                <div className="cell text-slate-700 w-32">{getFormattedDateTime(activity?._start)}</div>
                                                <div className="cell text-slate-700 w-32">{getFormattedDateTime(activity?._stop)}</div>
                                            </div>
                                        ))) :
                                        (
                                            <div>
                                                <div className="cell text-center h-[95px] w-[460px] items-center justify-center flex text-slate-600">
                                                    {/* <em className="fa-solid fa-loader text-xl fa-spin"></em> */}
                                                    No restore related activity
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </AccordionItem>
                    <AccordionItem maxHeight="202px" title={<><em className="fa-regular fa-ballot-check text-[#FF5E0E]"></em><span className="ml-2">Policy Change &mdash; ( {(endpoint?.policy_activities || []).length} {(endpoint?.policy_activities || []).length === 1 ? 'Change' : 'Changes'} )</span></>}>
                        <div className={`_table flex flex-col w-[405px] md:w-[445px]`}>
                            <div className="overflow-x-scroll customScroll">
                                <div className={`thead bg-slate-50 flex flex-row p-2 border-y items-center w-[545px] `}>
                                    <div className="row text-gray-800 text-sm flex">
                                        <div className="cell text-left w-12 pl-2">#</div>
                                        <div className="cell text-left w-28 ml-2">Previous</div>
                                        <div className={`cell text-left w-28`}>Current</div>
                                        <div className="cell text-left grow">Time</div>
                                    </div>
                                </div>
                                <div className={`tbody bg-white w-[545px]  overflow-y-scroll overflow-x-hidden max-h-[600px] h-44 customScroll`}>
                                    {
                                        (endpoint?.policy_activities || []).length > 0 ?
                                        ((endpoint?.policy_activities || []).map((activity:any,activityIndex:number)=>(
                                            <div 
                                                key={activityIndex}
                                                className="row flex flex-row border-b font-light p-2 px-4 hover:bg-slate-100 text-sm items-center justify-start cursor-pointer"
                                                onClick={()=>{
                                                    setSelectedProperty(activity)
                                                }}
                                            >
                                                <div className="cell text-slate-700 w-12">{activityIndex+1}</div>
                                                {/* <div className="cell text-slate-700 w-12">{endpoint?.policy_activities?.length-activityIndex}</div> */}
                                                <div className="cell text-slate-700 w-28">{((activity?._original || 0) - (activity?._value || 0))}</div>
                                                <div className={`cell text-slate-700 w-28`}>{(activity?._original || 0)}</div>
                                                {/* <div className={`cell text-slate-700 w-28`}>{(activity?._value || 0).toFixed(2)} MB</div> */}
                                                <div className="cell text-slate-700 grow">{getFormattedDateTime(activity?._time)}</div>
                                            </div>
                                        ))) :
                                        (
                                            <div>
                                                <div className="cell text-center h-[95px] w-[460px] md:w-[600px] items-center justify-center flex text-slate-600">
                                                    {/* <em className="fa-solid fa-loader text-xl fa-spin"></em> */}
                                                    No policy related activity
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </AccordionItem>
                    <AccordionItem maxHeight="202px" title={<><em className="fa-regular fa-chess-knight text-[#FF5E0E]"></em><span className="ml-2">Strategy Change &mdash; ( {(endpoint?.strategy_activities || []).length} {(endpoint?.strategy_activities || []).length === 1 ? 'Change' : 'Changes'} )</span></>}>
                        <div className={`_table flex flex-col h-full overflow-hidden w-[405px] md:w-[454px]`}>
                            <div className="overflow-x-scroll overflow-y-hidden customScroll">
                                <div className={`thead bg-slate-50 flex flex-row p-2 border-y items-center w-[545px]`}>
                                    <div className="row text-gray-800 text-sm flex">
                                    <div className="cell text-left w-12 pl-2">#</div>
                                    <div className="cell text-left w-28">Previous</div>
                                    <div className={`cell text-left w-28`}>Current</div>
                                    <div className="cell text-left grow">Time</div>
                                </div>
                                </div>
                                <div className={`tbody bg-white w-[545px] overflow-y-scroll max-h-96 h-44 customScroll`}>
                                    {
                                        (endpoint?.strategy_activities || []).length > 0 ?
                                        ((endpoint?.strategy_activities || []).map((activity:any,activityIndex:number)=>(
                                            <div 
                                                key={activityIndex}
                                                className="row flex flex-row border-b font-light p-2 px-4 hover:bg-slate-100 text-sm items-center"
                                                onClick={()=>{
                                                    setSelectedProperty(activity)
                                                }}
                                            >
                                                <div className="cell text-slate-700 w-12 pl-2">{activityIndex+1}</div>
                                                {/* <div className="cell text-slate-700 w-12 pl-2">{endpoint?.strategy_activities?.length-activityIndex}</div> */}
                                                <div className="cell text-slate-700 w-28">{((activity?._original || 0) - (activity?._value || 0))}</div>
                                                <div className={`cell text-slate-700 w-28`}>{(activity?._original || 0)}</div>
                                                {/* <div className={`cell text-slate-700 w-28`}>{(activity?._value || 0).toFixed(2)} MB</div> */}
                                                <div className="cell text-slate-700 grow">{getFormattedDateTime(activity?._time)}</div>
                                            </div>
                                        ))) :
                                        (
                                            <div>
                                                <div className="cell text-center h-[95px] w-[460px] items-center justify-center flex text-slate-600">
                                                    {/* <em className="fa-solid fa-loader text-xl fa-spin"></em> */}
                                                    No strategy related activity
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </AccordionItem>
                </Accordion>
            </div> 

            <Modal modalVisible={modalVisibility} className={`${ (selectedProperty?._field || '') === 'strategy' && 'w-1/2' } ${ (selectedProperty?._field || '') === 'protection' && 'w-[70%] h-[70%]' }`}>
                <ModalHeader modalVisible={modalVisibility} setModalVisibility={setModalVisibility} className="flex items-end">
                    <div>
                        {(selectedProperty?._field || '') === 'strategy' && <>Strategy Change</>}
                        {(selectedProperty?._field || '') === 'protection' && <>Policy Change</>}
                    </div>
                    <div className="ml-2 text-sm font-normal">{getFormattedDateTime(selectedProperty?._time || '')}</div>
                </ModalHeader>
                <ModalBody>
                    {selectedProperty?._field === 'strategy' && <div className="flex flex-col w-full h-full">
                        <div className="w-full flex bg-gray-100">
                            <div className="w-1/5 py-2 border-r border-b pl-2 text-[#FF5E0E] text-base font-semibold">Properties</div>
                            <div className="w-2/5 py-2 border-r border-b pl-2 text-[#FF5E0E] text-base font-semibold">Previous</div>
                            <div className="w-2/5 py-2 border-b pl-2 text-[#FF5E0E] text-base font-semibold">Current</div>
                        </div>
                        <div className="w-full flex">
                            <div className="w-1/5 py-2 bg-gray-50 border-r border-b pl-2 text-[#FF5E0E] text-base font-semibold">ID</div>
                            {/* <div className="w-2/5 py-2 hover:bg-slate-50 border-r border-b pl-2">{finalProperty?.previousProperty?.ID || 'NA'}</div> */}
                            {/* <div className="w-2/5 py-2 hover:bg-slate-50 border-b pl-2">{finalProperty?.currentProperty?.ID || ''}</div> */}
                        </div>
                        <div className="w-full flex">
                            <div className="w-1/5 py-2 bg-gray-50 border-r border-b pl-2 text-[#FF5E0E] text-base font-semibold">Name</div>
                            {/* <div className="w-2/5 py-2 hover:bg-slate-50 border-r border-b pl-2">{finalProperty?.previousProperty?.Name || 'NA'}</div> */}
                            {/* <div className="w-2/5 py-2 hover:bg-slate-50 border-b pl-2">{finalProperty?.currentProperty?.Name || ''}</div> */}
                        </div>
                        <div className="w-full flex">
                            <div className="w-1/5 py-2 bg-gray-50 border-r border-b pl-2 text-[#FF5E0E] text-base font-semibold">Retention</div>
                            {/* <div className="w-2/5 py-2 hover:bg-slate-50 border-r border-b pl-2">{finalProperty?.previousProperty?.ParamDataAging || 0} Days</div> */}
                            {/* <div className="w-2/5 py-2 hover:bg-slate-50 border-b pl-2">{finalProperty?.currentProperty?.ParamDataAging || 0} Days</div> */}
                        </div>
                        <div className="w-full flex">
                            <div className="w-1/5 py-2 bg-gray-50 border-r border-b pl-2 text-[#FF5E0E] text-base font-semibold">RPO</div>
                            {/* <div className="w-2/5 py-2 hover:bg-slate-50 border-r border-b pl-2">{finalProperty?.previousProperty?.ParamSchedule ? formatSeconds(finalProperty?.previousProperty?.ParamSchedule).value + ' ' + formatSeconds(finalProperty?.previousProperty?.ParamSchedule).unit : 'NA'}</div> */}
                            {/* <div className="w-2/5 py-2 hover:bg-slate-50 border-b pl-2">{finalProperty?.currentProperty?.ParamSchedule ? formatSeconds(finalProperty?.currentProperty?.ParamSchedule).value + ' ' + formatSeconds(finalProperty?.currentProperty?.ParamSchedule).unit : 'NA'}</div> */}
                        </div>
                        <div className="w-full flex">
                            <div className="w-1/5 py-2 bg-gray-50 border-r pl-2 text-[#FF5E0E] text-base font-semibold">Alerts</div>
                            {/* <div className="w-2/5 py-2 hover:bg-slate-50 border-r pl-2">{finalProperty?.previousProperty?.ParamAlertTime ? formatSeconds(finalProperty?.previousProperty?.ParamAlertTime).value + ' ' + formatSeconds(finalProperty?.previousProperty?.ParamAlertTime).unit : 'NA'}</div> */}
                            {/* <div className="w-2/5 py-2 hover:bg-slate-50 pl-2">{finalProperty?.currentProperty?.ParamAlertTime ? formatSeconds(finalProperty?.currentProperty?.ParamAlertTime).value + ' ' + formatSeconds(finalProperty?.currentProperty?.ParamAlertTime).unit : 'NA'}</div> */}
                        </div>
                    </div>}
                    {selectedProperty?._field === 'protection' && (
                        <div className="flex flex-col w-full h-[400px]">
                            <div className="w-fill flex items-center p-2 border-b bg-gray-100 text-[#FF5E0E] text-base font-semibold">
                                <div className="">Previous Policy</div>
                                <div className="text-sm font-normal text-gray-600 ml-2">({finalProperty?.previousProperty?.ID || ''}) {finalProperty?.previousProperty?.Name || ''}</div>
                            </div>
                            <div className="w-full grow">
                                <div className="table w-full overflow-y-scroll">
                                    <div className="thead w-full border-b px-4 py-2 font-medium text-[#FF5E0E] bg-gray-100 bg-opacity-50">
                                        <div className="row flex w-full font-bold">
                                            <div className="cell grow w-36 max-w-40 text-left font-semibold">Name</div>
                                            <div className="cell grow w-40 max-w-80 text-left font-semibold">Path</div>
                                            <div className="cell grow w-40 max-w-80 text-left font-semibold">Filter</div>
                                        </div>
                                    </div>
                                    <div className="tbody max-h-[200px] overflow-y-scroll w-full">
                                        {/* {(policyDetails?.previous?.path || []).map((pathObj:any, pathIndex:number)=>(
                                            <div key={pathIndex} className="row flex w-full border-b px-4 py-2 hover:bg-slate-100">
                                                <div className="cell grow w-36 max-w-40 h-14 text-left font-light whitespace-nowrap">{pathObj?.path?.Name || 'NA'}</div>
                                                <div className="cell grow w-40 max-w-80 text-left font-light whitespace-nowrap">{pathObj?.path?.Path || 'NA'}</div>
                                                <div className="cell flex flex-col grow w-40 max-w-80 text-left font-light whitespace-nowrap">
                                                        <div>{ behaviorText[ pathObj?.extensions?.Behavior]|| pathObj?.filter?.comment || ""}</div>

                                                    {pathObj?.extensions?.Behavior ? (
                                                        <>
                                                            {(pathObj?.extensions?.Behavior === "INCL" || pathObj?.extensions?.Behavior === "EXCL") && 
                                                                <span>{pathObj?.extensions?.Ext.map((d:any)=>(
                                                                    <div className="inline-block px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 mb-1 text-xs font-medium">{d || ''}</div>
                                                                ))}</span>
                                                            }
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span>
                                                                <div className="inline-block px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 mb-1 text-xs font-medium">
                                                                    All Extensions
                                                                </div>
                                                            </span>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ))} */}
                                    </div>
                                </div>
                            </div>
                            <div className="w-fill flex items-center p-2 border-b bg-gray-100 text-[#FF5E0E] text-base font-semibold">
                                <div className="">Current Policy</div>
                                <div className="text-sm font-normal text-gray-600 ml-2">({finalProperty?.currentProperty?.ID || ''}) {finalProperty?.currentProperty?.Name || ''}</div>
                            </div>
                            <div className="w-full grow">
                                <div className="table w-full overflow-y-scroll">
                                    <div className="thead w-full border-b px-4 py-2 font-medium text-[#FF5E0E] bg-gray-100 bg-opacity-50">
                                        <div className="row flex w-full font-bold text-orange-500">
                                            <div className="cell grow w-36 max-w-40 text-left font-semibold">Name</div>
                                            <div className="cell grow w-40 max-w-80 text-left font-semibold">Path</div>
                                            <div className="cell grow w-40 max-w-80 text-left font-semibold">Filter</div>
                                        </div>
                                    </div>
                                    <div className="tbody max-h-[200px] overflow-y-scroll w-full">
                                        {/* {(policyDetails?.current?.path || []).map((pathObj:any, pathIndex:number)=>(
                                            <div key={pathIndex} className="row flex w-full border-b px-4 py-2 hover:bg-slate-100">
                                                <div className="cell grow w-36 max-w-40 h-14 text-left font-light whitespace-nowrap">{pathObj?.path?.Name || 'NA'}</div>
                                                <div className="cell grow w-40 max-w-80 text-left font-light whitespace-nowrap">{pathObj?.path?.Path || 'NA'}</div>
                                                <div className="cell flex flex-col grow w-40 max-w-80 text-left font-light whitespace-nowrap">
                                                        <div>{ behaviorText[ pathObj?.extensions?.Behavior]|| pathObj?.filter?.comment || ""}</div>

                                                    {pathObj?.extensions?.Behavior ? (
                                                        <>
                                                            {(pathObj?.extensions?.Behavior === "INCL" || pathObj?.extensions?.Behavior === "EXCL") && 
                                                                <span>{pathObj?.extensions?.Ext.map((d:any)=>(
                                                                    <div className="inline-block px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 mb-1 text-xs font-medium">{d || ''}</div>
                                                                ))}</span>
                                                            }
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span>
                                                                <div className="inline-block px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 mb-1 text-xs font-medium">
                                                                    All Extensions
                                                                </div>
                                                            </span>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ))} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={()=>{
                            setModalVisibility(false)
                        }}
                    >Close</Button>
                </ModalFooter>
            </Modal>  
        </>
    )
}

interface IMoreActionsComponent {
    auditContext: any;
    strategyPolicies: any;
    selectedData: string;
}

const MoreActionsComponent:FC<IMoreActionsComponent> = ({auditContext, strategyPolicies, selectedData}) => {

    const [csvReportData, setCsvReportData] = useState<any[]>([]);
    const [xlsxReportData, setXlsxReportData] = useState<any[]>([]);

    const processDataForReport = (type: string) => {

        let rawData = auditContext.searchParams;

        let finalData: any[] = [];

        (rawData?.data || []).map((d:any)=>{

            // devices with backup activities
            if( (d.activity).includes('Backup') && ( selectedData === 'Backup' || selectedData === 'All' ) ) {
                d.backup_activities
                    .filter((activity:any)=>{ return activity._field === 'backup' })
                    .map((activity:any) => {
                        finalData.push({
                            device_id: d?.device_id?.text ? d?.device_id?.text : '',
                            hostname: d?.hostname?.text ? d?.hostname?.text : '',
                            os: d?.os?.text ? d?.os?.text : '',
                            // status: d?.status?.text ? d?.status?.text : '',
                            // lastConnection: d?.lastConnection?.text ? d?.lastConnection?.text : '',
                            // lastBackup: d?.lastBackup?.raw ? d?.lastBackup?.raw : '',
                            // protectedData: d?.protectedData?.text ? d?.protectedData?.text : '',
                            installed_at: d?.installed_at?.text ? ( d?.installed_at?.text === 'NA' ? '' : getFormattedDateTime(d?.installed_at?.text || '') ) : '',
                            deleted_at: d?.deleted_at?.text ? (d?.deleted_at?.text === 'NA' ? '' : getFormattedDateTime(d?.deleted_at?.text || '') ) : '',
                            activity: activity?._field ? activity?._field : '',
                            value: activity?._value ? `${(activity?._value || 0).toFixed(2)}` : '',
                            previous: '',
                            current: '',
                            start_at: activity?._start ? getFormattedDateTime(activity?._start || '', 'utc') : '',
                            stop_at: activity?._stop ? getFormattedDateTime(activity?._stop || '', 'utc') : '',
                            range_started_at: getFormattedDateTime((auditContext?.searchParams?.range?.startDate).toISOString())?.split(' ')[0] + ' 00:00 am',
                            range_stop_at: getFormattedDateTime((auditContext?.searchParams?.range?.endDate).toISOString())?.split(' ')[0] + ' 11:59 pm'
                        })
                    })
            }

            // devices with restore activities
            if( (d.activity).includes('Restore') && ( selectedData === 'Restore' || selectedData === 'All' ) ) {
                d.restore_activities
                .filter((activity:any)=>{ return activity._field === 'restore' })
                .map((activity:any) => {
                    finalData.push({
                        device_id: d?.device_id?.text ? d?.device_id?.text : '',
                        hostname: d?.hostname?.text ? d?.hostname?.text : '',
                        os: d?.os?.text ? d?.os?.text : '',
                        // status: d?.status?.text || '',
                        // lastConnection: d?.lastConnection?.text || '',
                        // lastBackup: d?.lastBackup?.raw || '',
                        // protectedData: d?.protectedData?.text || '',
                        installed_at: d?.installed_at?.text ? ( d?.installed_at?.text === 'NA' ? '' : getFormattedDateTime(d?.installed_at?.text || '') ) : '',
                        deleted_at: d?.deleted_at?.text ? (d?.deleted_at?.text === 'NA' ? '' : getFormattedDateTime(d?.deleted_at?.text || '') ) : '',
                        activity: activity?._field ? activity?._field : '',
                        value: activity?._value ? `${(activity?._value || '').toFixed(2)}` : '',
                        previous: '',
                        current: '',
                        start_at: activity?._start ? getFormattedDateTime(activity?._start || '', 'utc') : '',
                        stop_at: activity?._stop ? getFormattedDateTime(activity?._stop || '', 'utc') : '',
                        range_started_at: getFormattedDateTime((auditContext?.searchParams?.range?.startDate).toISOString())?.split(' ')[0] + ' 00:00 am',
                        range_stop_at: getFormattedDateTime((auditContext?.searchParams?.range?.endDate).toISOString())?.split(' ')[0] + ' 11:59 pm'
                    })
                })
            }

            // devices with policy change activities
            if( (d.activity).includes('Policy') && ( selectedData === 'Policy' || selectedData === 'All' ) ) {
                d.policy_activities
                .filter((activity:any)=>{ return activity._field === 'protection' })
                .map((activity:any) => {
                    finalData.push({
                        device_id: d?.device_id?.text ? d?.device_id?.text : '',
                        hostname: d?.hostname?.text ? d?.hostname?.text : '',
                        os: d?.os?.text ? d?.os?.text : '',
                        // status: d?.status?.text || '',
                        // lastConnection: d?.lastConnection?.text || '',
                        // lastBackup: d?.lastBackup?.raw || '',
                        // protectedData: d?.protectedData?.text || '',
                        installed_at: d?.installed_at?.text ? ( d?.installed_at?.text === 'NA' ? '' : getFormattedDateTime(d?.installed_at?.text || '') ) : '',
                        deleted_at: d?.deleted_at?.text ? (d?.deleted_at?.text === 'NA' ? '' : getFormattedDateTime(d?.deleted_at?.text || '') ) : '',
                        activity: activity?._field ? activity?._field : '',
                        value: '',
                        previous: (activity?._value && activity?._original) ? `(${activity?._original - activity?._value}) ${ (strategyPolicies?.config?.current_policies || []).find((p:any)=>{ return p.ID === activity?._original }).Name }` : '',
                        current: activity?._original ? `(${activity?._original}) ${ (strategyPolicies?.config?.current_policies || []).find((p:any)=>{ return p.ID === activity?._original }).Name }` : '',
                        start_at: '',
                        stop_at: '',
                        range_started_at: getFormattedDateTime((auditContext?.searchParams?.range?.startDate).toISOString())?.split(' ')[0] + ' 00:00 am',
                        range_stop_at: getFormattedDateTime((auditContext?.searchParams?.range?.endDate).toISOString())?.split(' ')[0] + ' 11:59 pm'
                    })
                })
            }

            // devices with strategy change activities
            if( (d.activity).includes('Strategy') && ( selectedData === 'Strategy' || selectedData === 'All' ) ) {
                d.strategy_activities
                .filter((activity:any)=>{ return activity._field === 'strategy' })
                .map((activity:any) => {
                    finalData.push({
                        device_id: d?.device_id?.text ? d?.device_id?.text : '',
                        hostname: d?.hostname?.text ? d?.hostname?.text : '',
                        os: d?.os?.text ? d?.os?.text : '',
                        // status: d?.status?.text || '',
                        // lastConnection: d?.lastConnection?.text || '',
                        // lastBackup: d?.lastBackup?.raw || '',
                        // protectedData: d?.protectedData?.text || '',
                        installed_at: d?.installed_at?.text ? ( d?.installed_at?.text === 'NA' ? '' : getFormattedDateTime(d?.installed_at?.text || '')) : '',
                        deleted_at: d?.deleted_at?.text ? (d?.deleted_at?.text === 'NA' ? '' : getFormattedDateTime(d?.deleted_at?.text || '')) : '',
                        activity: activity?._field ? activity?._field : '',
                        value: '',
                        previous: (activity?._value && activity?._original) ? `(${activity?._original - activity?._value})  ${ (strategyPolicies?.config?.current_strategies || []).find((s:any)=>{ return s.ID === (activity?._original - activity?._value) }).Name }` : '',
                        current: activity?._original ? `(${activity?._original}) ${ (strategyPolicies?.config?.current_strategies || []).find((s:any)=>{ return s.ID === activity?._original }).Name }` : '',
                        start_at: '',
                        stop_at: '',
                        range_started_at: getFormattedDateTime((auditContext?.searchParams?.range?.startDate).toISOString())?.split(' ')[0] + ' 00:00 am',
                        range_stop_at: getFormattedDateTime((auditContext?.searchParams?.range?.endDate).toISOString())?.split(' ')[0] + ' 11:59 pm'
                    })
                })
            }

            // devices with installed devices activities
            if( d.installed_at.text !== 'NA' && ( selectedData === 'Installed' || selectedData === 'All' ) ) {
                finalData.push({
                    device_id: d?.device_id?.text ? d?.device_id?.text : '',
                    hostname: d?.hostname?.text ? d?.hostname?.text : '',
                    os: d?.os?.text ? d?.os?.text : '',
                    // status: d?.status?.text || '',
                    // lastConnection: d?.lastConnection?.text || '',
                    // lastBackup: d?.lastBackup?.raw || '',
                    // protectedData: d?.protectedData?.text || '',
                    installed_at: d?.installed_at?.text ? ( d?.installed_at?.text === 'NA' ? '' : getFormattedDateTime(d?.installed_at?.text || '') ) : '',
                    deleted_at: d?.deleted_at?.text ? (d?.deleted_at?.text === 'NA' ? '' : getFormattedDateTime(d?.deleted_at?.text || '') ) : '',
                    activity: 'installed',
                    value: '',
                    previous: '',
                    current: '',
                    start_at: '',
                    stop_at: '',
                    range_started_at: getFormattedDateTime((auditContext?.searchParams?.range?.startDate).toISOString())?.split(' ')[0] + ' 00:00 am',
                    range_stop_at: getFormattedDateTime((auditContext?.searchParams?.range?.endDate).toISOString())?.split(' ')[0] + ' 11:59 pm'
                })
            }

            // devices with deleted devices activities
            if( d.deleted_at.text !== 'NA'  && ( selectedData === 'Deleted' || selectedData === 'All' ) ) {
                finalData.push({
                    device_id: d?.device_id?.text ? d?.device_id?.text : '',
                    hostname: d?.hostname?.text ? d?.hostname?.text : '',
                    os: d?.os?.text ? d?.os?.text : '',
                    // status: d?.status?.text || '',
                    // lastConnection: d?.lastConnection?.text || '',
                    // lastBackup: d?.lastBackup?.raw || '',
                    // protectedData: d?.protectedData?.text || '',
                    installed_at: d?.installed_at?.text ? ( d?.installed_at?.text === 'NA' ? '' : getFormattedDateTime(d?.installed_at?.text || '') ) : '',
                    deleted_at: d?.deleted_at?.text ? (d?.deleted_at?.text === 'NA' ? '' : getFormattedDateTime(d?.deleted_at?.text || '') ) : '',
                    activity: 'deleted',
                    value: '',
                    previous: '',
                    current: '',
                    start_at: '',
                    stop_at: '',
                    range_started_at: getFormattedDateTime((auditContext?.searchParams?.range?.startDate).toISOString())?.split(' ')[0] + ' 00:00 am',
                    range_stop_at: getFormattedDateTime((auditContext?.searchParams?.range?.endDate).toISOString())?.split(' ')[0] + ' 11:59 pm'
                })
            }

            // devices with no activities
            if( d.activity.length < 1 && ( selectedData === 'No Activity' || selectedData === 'All' ) ) {                
                if( d?.deleted_at?.text === 'NA' || new Date(d?.deleted_at?.text) > auditContext.searchParams.range.startDate ) {
                    finalData.push({
                        device_id: d?.device_id?.text ? d?.device_id?.text : '',
                        hostname: d?.hostname?.text ? d?.hostname?.text : '',
                        os: d?.os?.text ? d?.os?.text : '',
                        // status: d?.status?.text || '',
                        // lastConnection: d?.lastConnection?.text || '',
                        // lastBackup: d?.lastBackup?.raw || '',
                        // protectedData: d?.protectedData?.text || '',
                        installed_at: d?.installed_at?.text ? ( d?.installed_at?.text === 'NA' ? '' : getFormattedDateTime(d?.installed_at?.text || '') ) : '',
                        deleted_at: d?.deleted_at?.text ? (d?.deleted_at?.text === 'NA' ? '' : getFormattedDateTime(d?.deleted_at?.text || '') ) : '',
                        activity: 'no activity',
                        value: '',
                        previous: '',
                        current: '',
                        start_at: '',
                        stop_at: '',
                        range_started_at: getFormattedDateTime((auditContext?.searchParams?.range?.startDate).toISOString())?.split(' ')[0] + ' 00:00 am',
                        range_stop_at: getFormattedDateTime((auditContext?.searchParams?.range?.endDate).toISOString())?.split(' ')[0] + ' 11:59 pm'
                    })
                }
            }

        });

        console.log({finalData})

        if( type === 'csv' ) {
            setCsvReportData(finalData);
        } else if( type === 'xlsx' ) {
            setXlsxReportData(finalData);
        }
    }

    useEffect(()=>{

        if( csvReportData.length < 1 ) {
            return;
        }

        let tempCsv: string = `"DEVICE_ID","HOSTNAME","OS","INSTALLED_AT","DELETED_AT","ACTIVITY","DATA (MB)","PREVIOUS","CURRENT","START_TIME","STOP_TIME","REPORT_START_RANGE","REPORT_STOP_RANGE"\n`;

        console.log({csvReportData})

        tempCsv = generateCsv(csvReportData, tempCsv)

        const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${tempCsv}`);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "report.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    },[csvReportData])

    // const getSheetData = (data: any[], header: string[]) => {
    //     var fields = Object.keys(data[0]);
    //     var sheetData = data.map(function (row) {
    //       return fields.map(function (fieldName) {
    //         return row[fieldName] ? row[fieldName] : "";
    //       });
    //     });
    //     sheetData.unshift(header);
    //     return sheetData;
    // }

    useEffect(()=>{

        // if( xlsxReportData.length < 1 ) {
        //     return;
        // }

        // let headers = ["DEVICE_ID","HOSTNAME","OS","INSTALLED_AT","DELETED_AT","ACTIVITY","DATA (MB)","PREVIOUS","CURRENT","START_TIME","STOP_TIME","REPORT_START_RANGE","REPORT_STOP_RANGE"];

        // XlsxPopulate
        //     .fromBlankAsync()
        //     .then(async (workbook:any) => {
        //         const sheet1 = workbook.sheet(0);
        //         const sheetData = getSheetData(xlsxReportData, headers);
        //         const totalColumns = sheetData[0].length;
        
        //         sheet1.cell("A1").value(sheetData);
        //         const range = sheet1.usedRange();
        //         const endColumn = String.fromCharCode(64 + totalColumns);
        //         sheet1.row(1).style("bold", true);
        //         sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
        //         range.style("border", true);
        //         return workbook.outputAsync().then((res: any) => {
        //             saveAs(res, "file.xlsx");
        //         });
        // });

    },[xlsxReportData])


    return (
        <>
            <div className="title w-full absolute flex gap-4 px-6 top-0 pt-10 border-b pb-10 bg-slate-100 items-center">

            <i className="fa-regular fa-upload text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i>

            <div className="flex flex-col">
                <div className="flex text-xl font-bold text-slate-700 cursor-pointer uppercase">
                    More Actions
                </div>
                <div className="block text-slate-500">
                    {/* {selectedActivityData?.hostname?.text} */}
                </div>
            </div>

            </div>

            <div className="data absolute top-40 px-4 w-full">

                {/* <a href={`/endpoint/audit/report.html?reportId=${document.location.pathname.split('/').pop()}`} target="_blank" rel="noreferrer noopener"> 
                    <Button size="sm"><em className="fa-solid fa-eye"></em> View As Report</Button>
                </a>
                <br/>
                <br/>
                <Button>Download as PDF</Button>
                <br/>
                <br/> */}
                <Button
                    onClick={()=>{
                        processDataForReport('csv')
                    }}
                >Download {selectedData} Activities As CSV</Button>
                {/* <br/>
                <br/>
                <Button
                    onClick={()=>{
                        processDataForReport('xlsx')
                    }}
                >Download as Excel</Button>
                <br/>
                <br/>
                <Button>Email Report</Button>
                <br/>
                <br/>
                <Button>Schedule Report</Button>
                <br/>
                <br/> */}


            </div>
        </>
    )
}
