/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useRef, useContext, ReactNode } from "react"
import { ThemeContext } from "../../contexts";
// import Tile5x4_ChartText from "../../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText";
import { Breadcrumb, Button, Tile } from "../../components/core";
import { Table } from "../../components/core/Table";
import { ITableColumnItem, ITableControllerProps } from "../../components/core/Table/Table";
// import { MasterChart } from "../../components/Charts/ChartJS";
import { Link } from "react-router-dom";
import { getAPICall } from "../../services/APILayer/axiosMethodCalls";
import {
    lastConnectionFormatter,
    lastBackupFormatter,
    protectedDataFormatter,
    deviceSymbol,
    getDeviceStatus,
    originalDisabledReasons,
    getProtectedAgents
} from "../../utils/agentUtils";
import Tooltip from "../../components/core/Tooltip/Tooltip";
import ButtonGroup from "../../components/core/ButtonGroup/ButtonGroup";
import { ActiveTodayTile, AllDevicesTile, DeletedDevicesTile, DisabledDevicesTile, InstallationPendingDevicesTile, InstalledDevicesTile, LicenseUtilizedTile, ProtectedDevicesTile, UnprotectedDevicesTile } from "./EndpointDevice/index";
import Dropdown from "./EndpointDevice/Dropdown";
import { SortWithDeviceId, SortWithDeviceStatus, SortWithHostname, SortWithLastBackup, SortWithLastConnection, SortWithLastReplicated, SortWithOS, SortWithProtectedData, SortWithReplicationLag } from "../../utils/Sorting/endpointDevices";
import { formatSeconds, getFormattedDateTime, humanReadableTime } from "../../utils/format";

const EndpointDevice = ( ) =>{

    const scrollerRef = useRef<null | HTMLDivElement>(null)

    const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};

    // const [ backupTrend, setBackupTrend ] = useState(false);

    const navRaf = useRef<HTMLDivElement>(null);

    const [ endpointData, setEndpointData ] = useState<any>(null)
    const [ replicationConfigStatus, setReplicationConfigStatus ] = useState<boolean>(false)

    const [selectedData, setSelectedData] = useState<string>('')
    const [tableController, setTableController] = useState<ITableControllerProps>({
        data: [],
        loader: false,
        currentPage: 1,
        offset: 0,
        limit: 5,
        totalRecords: 10,
        maxVisiblePaginationButtons: 5,
        searchTerm: '',
        filter: {},
        sorting: {
            column: '',
            direction: null
        }
    });
    const [tableColumns, setTableColumns] = useState<ITableColumnItem[]>([
        {
            id: 1,
            name: 'Device Id',
            column: '',
            textAlign: 'text-left',
            textColor: 'text-[#ff5e0e]',
            className: 'min-w-[80px] pl-4 font-medium',
            visibility: true,
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 2,
            name: 'Hostname',
            column: '',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            className: 'w-[120px]',
            visibility: true,
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 3,
            name: 'OS',
            column: 'agent_profile',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            className: 'min-w-[80px]',
            visibility: true,
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 4,
            name: 'Device Status',
            column: '',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            className: 'min-w-[150px]',
            visibility: true,
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 5,
            name: 'Last Connection',
            column: 'agent_profile',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            className: 'min-w-[150px]',
            visibility: true,
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 6,
            name: 'Last Backup',
            column: 'agent_profile',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            className: 'min-w-[150px]',
            visibility: true,
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 7,
            name: 'Protected Data',
            column: 'agent_profile',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            className: 'min-w-[150px]',
            visibility: true,
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 8,
            name: 'Replication Lag',
            column: '',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            className: 'min-w-[150px]',
            visibility: replicationConfigStatus,
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 9,
            name: 'Last Replicated',
            column: '',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            className: 'min-w-[150px]',
            visibility: replicationConfigStatus,
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        }
    ]);

    const [ordersInfo, setOrdersInfo] = useState<any[]>([]);
    const [endpointQuota, setEndpointQuota] = useState<number>(0);

    const getOrderInfo = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/app/orders/me`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( endpointData === null ) { return }

        let filteredData = (endpointData?.endpoints || []).filter((ep:any)=>{ return ep?.agent_profile?.RepliInfos?.RepliDestName !== null && ep?.agent_profile?.RepliInfos?.RepliDestName !== undefined })

        console.log({filteredData})

        if( filteredData.length > 0 ) {
            setReplicationConfigStatus(true)
            setTableColumns((prevState:ITableColumnItem[])=>{
                let newState = [...prevState];
                newState[7].visibility = true;
                newState[8].visibility = true;
                return newState;
            })
        }

    },[endpointData])

    useEffect(()=>{
        if( Object.keys(ordersInfo || {}).length > 0 ) {
            return;
        }
        const fetchOrderInfo = () => {
            getOrderInfo()
                .then((data)=>{
                    if( data ) {
                        // console.log({data})
                        setOrdersInfo(data?.orders || [])
                    }
                })
                .catch(err=>console.log(err));
        }
        fetchOrderInfo()
    },[ordersInfo])

    useEffect(()=>{
        if( Object.keys(ordersInfo).length > 0 ) {
            let allOrderEndpointTotal = (ordersInfo || []).map((orders:any)=>{ 
                let endpointOrders = (orders?.order_data || []).filter((o:any)=>{ return o.unit === 'endpoints' })
                let totalOrderEndpoints = endpointOrders.reduce((a:number,d:any)=>{ a = a + d.qty; return a; },0)
                return totalOrderEndpoints
            }).reduce((a:number,d:number)=>{ a = a + d; return a },0)
            setEndpointQuota(allOrderEndpointTotal)
            // console.log({allOrderEndpointTotal})
        }
    },[ordersInfo])

    const actionButtonGenerator = (row: object) => {
        return <>
            <button aria-label='edit'
                onClick={()=>{

                }}
            ><em className='fa-regular fa-pen text-[#3A3541] opacity-80 mr-2'></em></button>
            <button aria-label='view'
                onClick={()=>{
                    
                }}
            ><em className='fa-regular fa-eye text-[#3A3541] opacity-80'></em></button>
        </>
    }

    // console.log({})

    const tableDataFormatter = (rowObj: any, format?: string | undefined, rowIndex?: number) => {
        let returningVal: ReactNode | null | string = null;
        let deviceStatus = getDeviceStatus(endpointData,rowObj);
        
        switch (format) {
            case 'Device Id':
                returningVal = (
                    <Link to={`/endpoint/devices/profile/${rowObj?._id || ""}`} >
                        <div className="cursor-pointer">{rowObj?.agent_profile?.AdminInfos?.ID || ""}</div>
                    </Link>
                );
                break;

            case 'Hostname':
                returningVal = (
                    <div className="relative group">
                        <div className="max-w-[150px] truncate">{rowObj?.agent_profile?.AdminInfos?.Name || ''}</div>
                        { rowIndex === 0 ? (
                            <Tooltip direction="down" value={`${rowObj?.agent_profile?.AdminInfos?.Name || ''} / ${rowObj?.agent_profile?.AgentInfos?.ComputerName || ''}`} />
                            ) : (
                            <Tooltip value={`${rowObj?.agent_profile?.AdminInfos?.Name || ''} / ${rowObj?.agent_profile?.AgentInfos?.ComputerName || ''}`} />
                        ) }
                    </div>
                );
                break;

            case 'OS':
                returningVal=(
                    <>
                        <div className="relative group">
                            <div className="">{deviceSymbol(rowObj?.AgentInfos?.System || "")}</div>
                            { rowIndex === 0 ? (
                                <Tooltip direction="down" className="" value={rowObj?.AgentInfos?.System || 'Unknown'} />
                                ) : (
                                <Tooltip className="" value={rowObj?.AgentInfos?.System || 'Unknown'} />
                            ) }
                            
                        </div>
                    </>
                );
                break;
            
            case 'Device Status':

                // console.log({__DeviceStatus:rowObj})

                if( deviceStatus === 'Pending' ) {
                    returningVal = <div className="text-xs px-3 py-1 text-orange-500 font-light bg-orange-100 max-w-min rounded-full">Pending</div>
                } else if( deviceStatus === 'Protected' ) {
                    returningVal = <div className="text-xs px-3 py-1 text-green-500 font-light bg-green-100 max-w-min rounded-full">Protected</div>
                } else if( deviceStatus === 'Unprotected' ) {
                    returningVal = <div className="text-xs px-3 py-1 text-red-500 font-light bg-red-100 max-w-min rounded-full">Unprotected</div>
                } else if( deviceStatus === 'Deleted' ) {
                    returningVal = <div className="text-xs px-3 py-1 text-red-500 font-light bg-red-100 max-w-min rounded-full">Deleted</div>
                } else if( deviceStatus === 'Disabled' ) {
                    returningVal =  <div className="relative group">
                                        <div className="text-xs px-3 py-1 text-violet-500 font-light bg-violet-100 max-w-min rounded-full">Disabled</div>
                                        { rowIndex === 0 ? (
                                            <Tooltip direction="down"  className="" value={originalDisabledReasons[rowObj?.agent_profile?.BlockListInfos?.Reason] || ""} />
                                            ) : (
                                            <Tooltip  className="" value={originalDisabledReasons[rowObj?.agent_profile?.BlockListInfos?.Reason] || ""} />
                                        ) }
                                    </div>
                } else {
                    returningVal = <div className="text-xs px-3 py-1 text-black-600 font-light bg-opacity-10 bg-black-600 max-w-min rounded-full">Unknown</div>
                }

                break;

            case 'Last Connection':
                returningVal=(
                    <div className="relative group">{lastConnectionFormatter(rowObj?.NetworkInfos?.LastConnectionTime || '')}
                         { rowIndex === 0 ? (
                            <Tooltip direction="down"  className="" value={lastBackupFormatter(rowObj?.NetworkInfos?.LastConnectionTime || '') || ""} />
                            ) : (
                            <Tooltip  className="" value={lastBackupFormatter(rowObj?.NetworkInfos?.LastConnectionTime || '') || ""} />
                        ) }
                    </div>
                );
                break;

            case 'Last Backup':
                returningVal=(
                    <div className="">{lastBackupFormatter(rowObj?.NetworkInfos?.LastCompletedSession || '')}
                    </div>
                );
                break;

            case 'Protected Data':
                returningVal=(
                    <>
                        <div className="">{protectedDataFormatter(rowObj?.ServerInfos?.Backup?.ProtSize || 0)}</div>
                    </>
                );
                break;

            case 'Replication Lag':
                // endpoints[0].agent_profile.RepliInfos.RepliLag // sec to days => month
                returningVal=(
                    <>
                        <div className='text-center'>
                            { 
                                (rowObj?.agent_profile?.RepliInfos?.RepliDestName === null ) ?
                                <span className="">&mdash;</span> :
                                (    
                                    (rowObj?.agent_profile?.RepliInfos?.RepliLag === undefined ||  deviceStatus === 'Deleted') ?
                                    <span className="bg-orange-100 px-2 py-1 rounded-full text-xs font-light text-orange-600">NA</span> :
                                    (
                                        rowObj?.agent_profile?.RepliInfos?.RepliLag < 1 ?
                                        <span className="bg-green-100 px-2 py-1 rounded-full text-xs font-light text-green-800">Synchronized</span> : 
                                        <span className="relative group bg-red-100 px-2 py-1 rounded-full text-xs font-light text-red-800">
                                            <i className="fa-light fa-tilde text-[10px]"></i> {Math.round(formatSeconds(rowObj?.agent_profile?.RepliInfos?.RepliLag).value)} {formatSeconds(rowObj?.agent_profile?.RepliInfos?.RepliLag).unit}
                                            {
                                                rowIndex === 0 ?
                                                <Tooltip direction='down' value={humanReadableTime(rowObj?.agent_profile?.RepliInfos?.RepliLag)} /> :
                                                <Tooltip direction='up' value={humanReadableTime(rowObj?.agent_profile?.RepliInfos?.RepliLag)} />
                                            }
                                        </span>
                                    )
                                )
                            }
                        </div>
                    </>
                );
                break;

            case 'Last Replicated':
                // miliseconds date 
                returningVal=(
                    // <>
                    //     <div className="">{JSON.stringify(rowObj?.agent_profile?.RepliInfos?.RepliLastPIT)}</div>
                    // </>
                    <>
                    <div className='text-center'>
                        {
                            (rowObj?.agent_profile?.RepliInfos?.RepliDestName === null) ?
                            <span className="">&mdash;</span> :
                            (
                                (rowObj?.agent_profile?.RepliInfos?.RepliLastPIT === undefined || rowObj?.agent_profile?.RepliInfos?.RepliLastPIT === null ||  deviceStatus === 'Deleted') ?
                                <span className="bg-orange-100 px-2 py-1 rounded-full text-xs font-light text-orange-600">NA</span> :
                                <span className="">{getFormattedDateTime(new Date(Number(rowObj?.agent_profile?.RepliInfos?.RepliLastPIT)).toISOString())}</span>
                            )
                        }
                    </div>
                </>
                );
                break;
        
            default:
                returningVal = rowObj;
                break;
        }
        return returningVal;
    }

    const handleSwitch = () => {}

    const getEndpoints = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( (tableController.data || []).length > 0 || selectedData !== '' ) {
            return;
        }

        const fetchEndpoints = async () => {
            await getEndpoints().then((data)=>{
                if(data) {
                    setEndpointData(data);
                    // console.log({data})
                    setTableController((prevState) => {
                        let newState = {...prevState};
                        newState.data = data?.endpoints || [];
                        return newState;
                    });
                }
            }).catch((err)=>{
                console.log({err})
            });
        }

        fetchEndpoints();

    },[tableController.data]);

    useEffect(()=>{
        if(tableController.searchTerm.length < 1) {
            if(endpointData) {
                setTableController((prevState)=>{
                    let newState = {...prevState};
                    newState.data = endpointData?.endpoints || [];
                    return newState;
                })
                setSelectedData('All');
            }
            return;
        }

        let searchedNodes = (endpointData?.endpoints || []).filter((ep:any) => {
            if(
                ( ep?.agent_id || '' ).toLowerCase().includes(tableController.searchTerm) ||
                ( ep?.agent_profile?.AdminInfos?.Name || '' ).toLowerCase().includes(tableController.searchTerm) ||
                ( ep?.agent_profile?.AgentInfos?.ComputerName || '' ).toLowerCase().includes(tableController.searchTerm) ||
                ( ep?.agent_profile?.AgentInfos?.Hostname || '' ).toLowerCase().includes(tableController.searchTerm) ||
                ( ep?.agent_profile?.AgentInfos?.System || '' ).toLowerCase().includes(tableController.searchTerm)
            ) {
                return ep;
            }
        }) || [];

        setTableController((prevState)=>{
            let newState = {...prevState};
            setSelectedData('Search');
            newState.data = searchedNodes || [];
            return newState;
        })

    },[tableController.searchTerm]);

    const scrollToBottom = () => {
        scrollerRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const handleExport = () => {
        let tempCsv: string = `"DEVICE_ID","HOSTNAME","COMPUTER_NAME","OS","DEVICE STATUS","LAST CONNECTION","LAST BACKUP","PROTECTED DATA"\n`;

        // console.log({_tableController:tableController?.data})

        for (let i = 0; i < (tableController?.data || []).length; i++) {
            const rowObj = (tableController?.data || [])[i];

            let data = `"${rowObj?.agent_profile?.AdminInfos?.ID || ''}","${rowObj?.agent_profile?.AdminInfos?.Name || ''}","${rowObj?.agent_profile?.AgentInfos?.ComputerName || ''}","${deviceSymbol(rowObj?.agent_profile?.AgentInfos?.System || "", "text")}","${getDeviceStatus(endpointData,rowObj)}","${lastConnectionFormatter(rowObj?.agent_profile?.NetworkInfos?.LastConnectionTime || '')} (${(lastBackupFormatter(rowObj?.agent_profile?.NetworkInfos?.LastConnectionTime || ''))})","${lastConnectionFormatter(rowObj?.agent_profile?.NetworkInfos?.LastCompletedSession || '')} (${lastBackupFormatter(rowObj?.agent_profile?.NetworkInfos?.LastCompletedSession || '')})","${protectedDataFormatter(rowObj?.agent_profile?.ServerInfos?.Backup?.ProtSize || 0)}"\n`
            tempCsv = tempCsv + data;
        }

        // console.log({tempCsv})

        const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${tempCsv}`);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "report.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
    }

    const handleDataSorting = (column:any) => {

        let columnName = column?.name;
        let tableData = [...tableController?.data];
        let returningValue: {[key: string]: any}[] = []

        console.log({columnName, tableData, returningValue})

        switch (columnName) {
            case 'Device Id':
                let sortedByDeviceId = SortWithDeviceId( column?.sorting?.direction, tableData )
                if( sortedByDeviceId )
                    returningValue = sortedByDeviceId

                break;

            case 'Hostname':
                let sortedHostnameResult = SortWithHostname( column?.sorting?.direction, tableData )
                if( sortedHostnameResult )
                    returningValue = sortedHostnameResult

                break;
                
            case 'OS':
                let sortedOSResult = SortWithOS( column?.sorting?.direction, tableData )
                if( sortedOSResult )
                    returningValue = sortedOSResult
                break;
            
            case 'Device Status':
                let sortedDeviceStatusResult = SortWithDeviceStatus( column?.sorting?.direction, tableData, endpointData )
                if( sortedDeviceStatusResult )
                    returningValue = sortedDeviceStatusResult

                break;
        
            case 'Last Connection':
                let sortedLastConnectionResult = SortWithLastConnection( column?.sorting?.direction, tableData )
                if( sortedLastConnectionResult )
                    returningValue = sortedLastConnectionResult

                break;

            case 'Last Backup':
                let sortedLastBackupResult = SortWithLastBackup( column?.sorting?.direction, tableData )
                if( sortedLastBackupResult )
                    returningValue = sortedLastBackupResult

                break;

            case 'Protected Data':
                let sortedProtectedDataResult = SortWithProtectedData( column?.sorting?.direction, tableData )
                if( sortedProtectedDataResult )
                    returningValue = sortedProtectedDataResult
                break;

            case 'Replication Lag':
                let sortedReplicationLagResult = SortWithReplicationLag( column?.sorting?.direction, tableData )
                if( sortedReplicationLagResult )
                    returningValue = sortedReplicationLagResult
                break;

            case 'Last Replicated':
                let sortedLastReplicatedResult = SortWithLastReplicated( column?.sorting?.direction, tableData )
                if( sortedLastReplicatedResult )
                    returningValue = sortedLastReplicatedResult
                break;
        
            default:
                break;
        }

        console.log({columnName, tableData, column, returningValue})

        setTableController((previousState:ITableControllerProps)=>{
            let newState = {...previousState}
            newState.data = returningValue
            return newState
        })


    }
    
    const options= [
        {option:"All"},
        {option:"Delete"},
        {option:"Active today"},
        {option:"Disabled devices"},
        {option:"Protected Devices"},
        {option:"License Occupied"},
        {option:"Install Devices"},
        {option:"Unprotected Devices"},
        {option:"Installation Pending"},
    ]

    useEffect(()=>{

        console.log({selectedData})
    },[selectedData])

    // console.log({replicationConfigStatus, tableColumns})

    return(
        <>
            <div className={`p-6 grow flex flex-col`}>
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Endpoint Devices</h1>
                <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-bell-on', link: '', name: 'Home' },
                    { icon: '', link: '', name: 'Managed Services' },
                    { icon: '', link: '/endpoint', name: 'Endpoint Backup' },
                    { icon: '', link: '', name: 'Endpoint Devices' }
                ]} />
                <div className={` grow flex flex-col`}>
                    <div className={`page grid grid-cols-12 gap-6 w-full mb-6 min-h-max text-${theme.setThemeColor}-${theme.themeContrast}-component-text`}>                        
                        <InstalledDevicesTile
                            endpointQuota={endpointQuota}
                            endpointData={endpointData}
                            setSelectedData={setSelectedData}
                            setTableController={setTableController}
                            scrollToBottom={scrollToBottom}
                        />

                        <LicenseUtilizedTile
                            endpointQuota={endpointQuota}
                            endpointData={endpointData}
                            setSelectedData={setSelectedData}
                            setTableController={setTableController}
                            scrollToBottom={scrollToBottom}
                        />
                        
                        <ProtectedDevicesTile
                            endpointData={endpointData}
                            setSelectedData={setSelectedData}
                            setTableController={setTableController}
                            scrollToBottom={scrollToBottom}
                            getDeviceStatus={getDeviceStatus}
                        />
                        
                        <UnprotectedDevicesTile
                            endpointData={endpointData}
                            setSelectedData={setSelectedData}
                            setTableController={setTableController}
                            scrollToBottom={scrollToBottom}
                        />

                        <InstallationPendingDevicesTile
                            endpointData={endpointData}
                            setSelectedData={setSelectedData}
                            setTableController={setTableController}
                            scrollToBottom={scrollToBottom}
                        />
                        
                        <DeletedDevicesTile
                            endpointData={endpointData}
                            setSelectedData={setSelectedData}
                            setTableController={setTableController}
                            scrollToBottom={scrollToBottom}
                        />

                        <DisabledDevicesTile
                            endpointQuota={endpointQuota}
                            endpointData={endpointData}
                            setSelectedData={setSelectedData}
                            setTableController={setTableController}
                            scrollToBottom={scrollToBottom}
                        />

                        <ActiveTodayTile
                            endpointQuota={endpointQuota}
                            endpointData={endpointData}
                            setSelectedData={setSelectedData}
                            setTableController={setTableController}
                            scrollToBottom={scrollToBottom}
                        />

                        <AllDevicesTile 
                            endpointQuota={endpointQuota}
                            endpointData={endpointData}
                            setSelectedData={setSelectedData}
                            setTableController={setTableController}
                            scrollToBottom={scrollToBottom}
                        />
                        
                    </div>
                    <div className={`grow flex flex-col gap-6`}>
                    {/* <Tile colSpan={12} rowSpan={13} className="relative">
                    
                        <div className="flex md:flex-row flex-col items-center md:justify-between mx-2 py-4 border-b border-[#ff5e0e] mb-2">
                            {
                                backupTrend===true ? 
                                <div className="md:px-4 text-gray-600 font-semibold flex flex-col justify-center items-center md:justify-start md:items-start text-left">Backup history
                                    <small className="text-gray-400 font-normal">Showing the backup history per day
                                    </small>
                                </div> : 
                                <div className="px-4 text-gray-600 font-semibold flex flex-col justify-center items-center md:justify-start md:items-start text-left">Backup and Restore Trends
                                    <small className="text-gray-400 font-normal">Showing the trends backup and restore per day
                                    </small>
                                </div>
                            }
                            <div className="flex gap-2 justify-center md:justify-end">
                            <div className={`${backupTrend===false?"text-[#ff5e0e] border-b border-[#ff5e0e]":"text-gray-500"} font-normal cursor-pointer py-1 mr-4`} onClick={ShowBackupHistory}>Backup and Restore Trends</div>
                            <div className={`${backupTrend===true?"text-[#ff5e0e] border-b border-[#ff5e0e]":"text-gray-500"} font-normal cursor-pointer py-1 mr-4`} onClick={ShowBackupTrend}>Backup History</div>
                            </div>
                        </div>
                            <div className="responsive">
                            {backupTrend===true
                                ?
                                    <div className="absolute left-0 right-0 top-28 bottom-0 h-auto w-auto p-4">
                                <MasterChart
                                            datasets={[
                                                {
                                                    backgroundColor: 'rgba(255, 94, 14, 0.5)',
                                                    borderColor: 'rgb(225, 99, 132)',
                                                    data: [
                                                    2,
                                                    4,
                                                    6,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    8,
                                                    1,
                                                    8,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    8,
                                                    1,
                                                    8,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    8,
                                                    1,
                                                    8,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    8,
                                                    1,
                                                    8,
                                                    ],
                                                    fill: false,
                                                    label: 'Dataset 1'
                                                },
                                                
                                            ]}
                                            labels={[
                                                '01',
                                                '02',
                                                '03',
                                                '04',
                                                '05',
                                                '06',
                                                '07',
                                                '08',
                                                '09',
                                                '10',
                                                '11',
                                                '12',
                                                '13',
                                                '14',
                                                '15',
                                                '16',
                                                '17',
                                                '18',
                                                '19',
                                                '20',
                                                '21',
                                                '22',
                                                '23',
                                                '24',
                                                '25',
                                                '26',
                                                '27',
                                                '28',
                                                '29',
                                                '30',
                                                '31',
                                            ]}
                                            options={{
                                                plugins: {
                                                    legend: {
                                                    display: false,
                                                    position: 'bottom'
                                                    },
                                                    title: {
                                                    display: false,
                                                    text: 'Line Chart'
                                                    },
                                                    datalabels: { display: false },
                                                },
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                scales: {
                                                    x: {
                                                    display: true
                                                    },
                                                    y: {
                                                    display: true,
                                                    position: 'left',
                                                    type: 'linear'
                                                    }
                                                }
                                            }}
                                            type="bar"
                                /></div>
                                :
                                    <div className="absolute left-0 right-0 top-28 bottom-0 h-auto w-auto p-4">

                                <MasterChart
                                            datasets={[
                                                {
                                                    backgroundColor: 'rgba(255, 94, 14, 0.8)',
                                                    borderColor: 'rgba(255, 94, 14, 1)',
                                                    barPercentage: 0.5,
                                                    barThickness: 12,
                                                    maxBarThickness: 12,
                                                    minBarLength: 2,
                                                    data: [
                                                    2,
                                                    4,
                                                    6,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    8,
                                                    1,
                                                    8,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    8,
                                                    1,
                                                    8,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    8,
                                                    1,
                                                    8,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    8,
                                                    1,
                                                    8,
                                                    ],
                                                    fill: false,
                                                    label: 'Backup'
                                                },
                                                {
                                                    
                                                    backgroundColor: 'rgba(255, 94, 14, 0.4)',
                                                    borderColor: 'rgba(255, 94, 14, 0.5)',
                                                    barPercentage: 0.5,
                                                    barThickness: 12,
                                                    maxBarThickness: 12,
                                                    minBarLength: 2,
                                                    data: [
                                                    5,
                                                    3,
                                                    2,
                                                    7,
                                                    8,
                                                    3,
                                                    4,
                                                    5,
                                                    7,
                                                    0,
                                                    3,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    8,
                                                    1,
                                                    8,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    8,
                                                    1,
                                                    8,
                                                    ],
                                                    fill: false,
                                                    label: 'Restore'
                                                }
                                            ]}
                                            labels={[
                                                '01',
                                                '02',
                                                '03',
                                                '04',
                                                '05',
                                                '06',
                                                '07',
                                                '08',
                                                '09',
                                                '10',
                                                '11',
                                                '12',
                                                '13',
                                                '14',
                                                '15',
                                                '16',
                                                '17',
                                                '18',
                                                '19',
                                                '20',
                                                '21',
                                                '22',
                                                '23',
                                                '24',
                                                '25',
                                                '26',
                                                '27',
                                                '28',
                                                '29',
                                                '30',
                                                '31',
                                            ]}
                                            options={{
                                                plugins: {
                                                    legend: {
                                                        display: true,
                                                        position: 'bottom'
                                                    },
                                                    title: {
                                                        display: false,
                                                        text: 'Line Chart'
                                                    },
                                                    datalabels: { display: false },
                                                },
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                scales: {
                                                    x: {
                                                        display: true,
                                                        grid: {
                                                            display: false
                                                        }
                                                    },
                                                    y: {
                                                        display: true,
                                                        position: 'left',
                                                        type: 'linear',
                                                        grid: {
                                                            display: true
                                                        }
                                                    },
                                                }
                                            }}
                                            type="bar"
                                />
                                </div>                            
                            }
                        </div>
                    </Tile> */}

                    <Tile colSpan={12} rowSpan={25} className="pt-2 mb-6" >
                        <div className="p-4 flex md:flex-row md:gap-4 gap-2 items-center" ref={scrollerRef}>
                            <div className=" text-gray-800 font-medium">Devices</div>
                            <label htmlFor="searchBox" className="search flex items-center border rounded-md px-3 py-[2px]">
                                <em className="fa-solid fa-search text-slate-400 pr-2"></em>
                                <input 
                                    type="text" 
                                    id="searchBox"
                                    placeholder="Search hostname" 
                                    className="outline-none w-full" 
                                    value={tableController.searchTerm} 
                                    onChange={(e)=>{
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.searchTerm = e.target.value;
                                            return newState;
                                        });
                                    }}
                                />
                                <em className="fa-solid fa-times text-slate-200 hover:text-slate-500 cursor-pointer pl-2"
                                    onClick={()=>{
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.searchTerm = '';
                                            return newState;
                                        });
                                    }}
                                ></em>
                            </label>
                            <div className="grow"></div>
                            <Button size='sm' className="items-center" solid={true}
                                    onClick={()=>{
                                        handleExport()
                                    }}
                            >
                                <em className="fa-solid fa-download mr-1"></em> Download
                            </Button>

                            <Dropdown options={options} endpointData={endpointData} selectedData={selectedData} setSelectedData={setSelectedData} setTableController={setTableController} getProtectedAgents={getProtectedAgents} />
                            
                            {/* <ButtonGroup className="border hidden lg:block whitespace-nowrap">
                                <Button size='sm' className="" solid={selectedData === 'All' || selectedData === '' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('All')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.data = endpointData?.endpoints || [];
                                            return newState;
                                        })
                                    }}
                                >All</Button>
                                <Button size='sm' className="" solid={selectedData === 'Protected' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Protected')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.data = (getProtectedAgents(endpointData) || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos === null });;
                                            return newState;
                                        })
                                    }}
                                >Protected</Button>
                                <Button size='sm' className="" solid={selectedData === 'Unprotected' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Unprotected')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.data = endpointData?.unprotected || [];
                                            return newState;
                                        })
                                    }}
                                >Unprotected</Button>
                                <Button size='sm' className="" solid={selectedData === 'Installed' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Installed')
                                        setTableController((prevState)=>{
                                            let newState = prevState;
                                            newState.data = endpointData?.installed || [];
                                            return newState;
                                        })
                                    }}
                                >Installed</Button>
                                <Button size='sm' className="" solid={selectedData === 'Pending Installation' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Pending Installation')
                                        setTableController((prevState)=>{
                                            let newState = prevState;
                                            newState.data = endpointData?.pending_installation || [];
                                            return newState;
                                        })
                                    }}
                                >Pending Installation</Button>
                                <Button size='sm' className="" solid={selectedData === 'Deleted' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Deleted')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.data = endpointData?.deleted || [];
                                            return newState;
                                        })
                                    }}
                                >Deleted</Button>
                                <Button size='sm' className="" solid={selectedData === 'Disabled' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Disabled')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.data = (endpointData?.endpoints || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos !== null });
                                            return newState;
                                        })
                                    }}
                                >Disabled</Button>
                                <Button size='sm' className="" solid={selectedData === 'License Occupied' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('License Occupied')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.data = (endpointData?.installed || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos === null });
                                            return newState;
                                        })
                                    }}
                                >License Occupied</Button>
                                <Button size='sm' className="" solid={selectedData === 'Active Today' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Active Today')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.data = (endpointData?.installed || []).filter((ep:any)=>{ 
                                                let currentDate: string = (new Date()).toISOString().split('T')[0];
                                                let subjectDate: string = (new Date(ep?.agent_profile?.NetworkInfos?.LastConnectionTime)).toISOString().split('T')[0]
                                                return currentDate === subjectDate
                                            });
                                            return newState;
                                        })
                                    }}
                                >Active Today</Button>
                            </ButtonGroup> */}
                        </div>
                        <div className="absolute left-0 right-0 sm:top-[80px] top-20 bottom-0 h-[650px] w-auto">
                            <Table
                                navRef={navRaf}
                                actionButtonGenerator={actionButtonGenerator}
                                columns={tableColumns}
                                setColumns={setTableColumns}
                                formatData={tableDataFormatter}
                                showActionCell={false}
                                showActionHeaderCell={false}
                                showColumnManagementMenu={false}
                                switchHandler={handleSwitch}
                                tableController={tableController}
                                dataSortingHandler={handleDataSorting}
                                tableId="DeviceTable"
                            />
                        </div>
                    </Tile>
                </div> 
                </div>
            </div>
        </>
    )
}

export default EndpointDevice