const SortWithID = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {

    console.log({dataArray})

    return dataArray.sort((a:any, b:any) => {
        const symbolA = Number(a?.ID);
        const symbolB = Number(b?.ID);

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}
const SortWithStrategyName = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {

    console.log({dataArray})

    return dataArray.sort((a:any, b:any) => {
        const symbolA = a?.Name;
        const symbolB = b?.Name;

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA.localeCompare(symbolB);
        } else if ( order === 'DESC' ) {
            return symbolB.localeCompare(symbolA)
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}
const SortWithRetention = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {

    console.log({dataArray})

    return dataArray.sort((a:any, b:any) => {
        const symbolA = Number(a?.ParamDataAging);
        const symbolB = Number(b?.ParamDataAging);

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}
const SortWithRPO = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {

    console.log({dataArray})

    return dataArray.sort((a:any, b:any) => {
        const symbolA = Number(a?.ParamDataAging);
        const symbolB = Number(b?.ParamDataAging);

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}
const SortWithAlert = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {

    console.log({dataArray})

    return dataArray.sort((a:any, b:any) => {
        const symbolA = Number(a?.ParamAlertTime);
        const symbolB = Number(b?.ParamAlertTime);

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}

export {
    SortWithID,
    SortWithStrategyName,
    SortWithRetention,
    SortWithRPO,
    SortWithAlert
}