import React, { FC, useState } from "react";
import { formatSeconds } from "../../../utils/format";

interface IDeviceStrategyCard {
    strategyData: any;
}

const DeviceStrategyCard:FC<IDeviceStrategyCard> = ({ strategyData }) => {
    const [hovering, setHovering] = useState(false);
    return (
        <>
            <div className="tile relative rounded-lg shadow-md w-full bg-white col-span-6">
                <div className="flex flex-row w-full p-4 border-b">
                    <div className="title text-base md:text-lg lg:text-xl text-gray-800 font-medium">
                        Device Strategy <span className="ml-2 text-slate-500 text-sm">({strategyData?.Name || 'NA'})</span>
                    </div>
                </div>
                <div className="w-full flex">
                    <div className="flex flex-col w-1/3">
                        <div className="w-full border-b px-4 py-2 bg-slate-100 text-orange-500 font-semibold">Property</div>
                        <div className="w-full border-b px-4 py-2 hover:bg-slate-100 text-orange-500 font-medium">Strategy</div>
                        <div className="w-full border-b px-4 py-2 hover:bg-slate-100 text-orange-500 font-medium">Retention</div>
                        <div className="w-full border-b px-4 py-2 hover:bg-slate-100 text-orange-500 font-medium">RPO</div>
                        <div className="w-full border-b px-4 py-2 hover:bg-slate-100 text-orange-500 font-medium">Raise Alert</div>
                    </div>
                    <div className="flex flex-col w-2/3">
                        <div className="w-full border-b px-4 py-2 bg-slate-100 text-orange-500 font-semibold">Value</div>
                        <div className="w-full border-b px-4 py-2 hover:bg-slate-100 font-light whitespace-nowrap overflow-hidden"
                            onMouseOver={()=>{ setHovering(true); }}
                            onMouseOut={()=>{ setHovering(false); }}
                        ><div className={`${hovering ? 'animate-marquee' : ''}`}>{strategyData?.Name || 'NA'}</div></div>
                        <div className="w-full border-b px-4 py-2 hover:bg-slate-100 font-light whitespace-nowrap">{strategyData?.Name ? `${(strategyData?.ParamDataAging || 'Unlimited')} Days` : 'NA'}</div>
                        <div className="w-full border-b px-4 py-2 hover:bg-slate-100 font-light whitespace-nowrap">{strategyData?.ParamSchedule ? formatSeconds(strategyData?.ParamSchedule).value + ' ' + formatSeconds(strategyData?.ParamSchedule).unit : 'NA'}</div>
                        <div className="w-full border-b px-4 py-2 hover:bg-slate-100 font-light whitespace-nowrap">{strategyData?.ParamAlertTime ? formatSeconds(strategyData?.ParamAlertTime).value + ' ' + formatSeconds(strategyData?.ParamAlertTime).unit : 'NA'}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeviceStrategyCard;