import React, { FC, ReactNode, ComponentProps, useState, useEffect, Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../contexts";
import { GlobalContext } from "../../../contexts/GlobalContext/GlobalContext";
// import { ActivityCard } from "../../../pages/DashboardComponents";
import ActivityNotificationCard from "../../../pages/DashboardComponents/ActivityNotificationCard";
// import Button from "../../core/Button/Button";
import { QuickActionNav } from "./TopBarComponents";
import { getAPICall } from "../../../services/APILayer/axiosMethodCalls";

interface Props extends ComponentProps<"div">  {
    children?: ReactNode | ReactNode[]
    globalSearchVisibility: boolean
    setGlobalSearchVisibility: Dispatch<SetStateAction<boolean>>
}

/**
 * bg-orange-light-component-text bg-orange-dark-component-text
 * bg-blue-light-component-text bg-blue-dark-component-text
 * border-orange-light-component-text border-orange-dark-component-text
 * border-blue-light-component-text border-blue-dark-component-text
 * text-orange-light-component-text text-orange-dark-component-text
 * text-blue-light-component-text text-blue-dark-component-text
 * @param props 
 * @returns 
 */
const TopBar:FC<Props> = (props: Props) => {

    const theme = React.useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    const globalContext = React.useContext(GlobalContext)
    const [token, setToken] = useState<string>('')
    const [me, setMe] = useState<any>({})

    const fetchMyDetails = async () => {
        try {
            const response = await getAPICall(`https://zeeq-app.au.auth0.com/userinfo`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( token !== '' ) { return }
        let tokenInStorage = localStorage.getItem('token')
        if( tokenInStorage ) {
            setToken(tokenInStorage)
        }
    },[])

    useEffect(()=>{
        if( token === '' ) { return }
        const getMyDetails = async () => {
            let response = await fetchMyDetails()
            setMe(response)
        }
        getMyDetails()
    },[token])

    // const [show,setShow]=useState(false);

    // const quickAction = () =>{
    //     setShow(!show)
    // }

    // console.log({token: localStorage.getItem('token')})

    return (
        <div className={`flex h-16 w-full bg-${theme.themeColor}-${theme.themeContrast}-component-background p-2 items-center shadow-md`}>
            <div className="blockHideLeftShadow absolute h-16 w-4 bg-white top-0 -left-2"></div>
            {/* <div className="flex ml-6 justify-start">
                <i className={`fa-solid fa-search mr-2 mt-1 text-gray-500`}></i>
                <input type="text" className={`border-b outline-none border-gray-400 bg-${theme.themeColor}-${theme.themeContrast}-component-background px-2 py-1 w-80`} />
            </div> */}
            <QuickActionNav />
            <div className="grow"></div>
            <div className="flex justify-end gap-5 mr-6">
                {/* <button className={`flex border-2 border-${theme.themeColor}-${theme.themeContrast}-primary-default w-52 h-10 rounded-md p-[8px] mr-10 justify-center items-center gap-3`}>
                    <em className={`fa-solid fa-cloud-arrow-down text-xl text-${theme.themeColor}-${theme.themeContrast}-primary-default`}></em>
                    <div className={`text-${theme.themeColor}-${theme.themeContrast}-primary-default text-base font-semibold`}>Get Lina Web store</div>
                </button> */}
                {/* <div className={`w-10 h-10 flex align-middle items-center cursor-pointer`}>
                    <i className={`fa-regular fa-language mr-2 mt-1 text-${theme.themeColor}-${theme.themeContrast}-component-text text-2xl`}></i>
                </div> */}
                {/* <div
                    // className={`btnThemeContrastToggle w-10 h-10 flex align-middle items-center cursor-pointer`}
                    className={`btnThemeContrastToggle w-10 h-10 flex align-middle items-center cursor-pointer`}
                    onClick={()=>{
                        let newContrast = theme.themeContrast === 'dark' ? 'light' : 'dark';
                        theme.setThemeContrast(newContrast);
                    }}
                >
                    { theme.themeContrast === 'light' && <i className={`fa-regular fa-moon mr-2 mt-1 text-${theme.themeColor}-${theme.themeContrast}-component-text text-2xl`}></i>}
                    { theme.themeContrast === 'dark' && <i className={`fa-regular fa-sun-bright mr-2 mt-1 text-${theme.themeColor}-${theme.themeContrast}-component-text text-2xl`}></i>}
                </div> */}
                {/* <div className={`w-20 h-10 flex align-middle items-center`}>
                    <i
                        className={`fa-solid fa-circle mr-2 mt-1 text-[#4680FF] text-2xl cursor-pointer`}
                        onClick={()=>{
                            theme.setThemeColor('blue')
                        }}
                    ></i>
                    <i
                        className={`fa-solid fa-circle mr-2 mt-1 text-[#f97316] text-2xl cursor-pointer`}
                        onClick={()=>{
                            theme.setThemeColor('orange')
                        }}
                    ></i>
                </div> */}
                {/* <div className={`w-10 h-10 flex align-middle items-center cursor-pointer`}>
                    <i className={`fa-regular fa-bell mr-2 mt-1 text-${theme.themeColor}-${theme.themeContrast}-component-text text-2xl`}></i>
                </div> */}

                {/* <div className='group relative flex tracking-wider ml-4'>
                    <Button className={`rounded-md font-semibold pl-8 relative flex items-center`}  solid={true}> <i className="fa-regular fa-circle-dot absolute top-0 left-0 p-1 animate-ping text-xs"/>Live Activity<div className="bg-white w-6 h-6 text-[#ff5e0e] rounded-full relative ml-2"><div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semibold">99</div></div></Button>
                    <div className="absolute right-0 top-full min-w-[300px] flex flex-col invisible group-hover:visible bg-white rounded-lg shadow-md overflow-hidden">
                        <Link to="">
                            <div className="border-b border-slate-100 flex items-center px-3 py-2 hover:bg-slate-100 whitespace-nowrap cursor-pointer capitalize relative tracking-wider group">
                                <span className="pl-2 min-w-max">Endpoint: <span className="pl-1">Active Backup</span></span>
                                <div className="grow"></div>
                            <div className="bg-orange-100 w-5 h-5 rounded-full relative ml-2 text-orange-600 font-semibold text-base"><div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">5</div></div></div>
                        </Link>
                        <Link to="">
                            <div className="border-b border-slate-100 flex items-center px-3 py-2 hover:bg-slate-100 whitespace-nowrap cursor-pointer capitalize relative tracking-wider group">
                                <span className="pl-2 min-w-max">Endpoint: <span className="pl-1">Web Restore</span></span>
                                <div className="grow"></div>
                            <div className="bg-orange-100 w-5 h-5 rounded-full relative ml-2 text-orange-600 font-semibold text-base"><div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">5</div></div></div>
                        </Link>
                        <Link to="">
                            <div className="border-b border-slate-100 flex items-center px-3 py-2 hover:bg-slate-100 whitespace-nowrap cursor-pointer capitalize relative tracking-wider group">
                                <span className="pl-2 min-w-max">Endpoint: <span className="pl-1">client Restore</span></span>
                                <div className="grow"></div>
                            <div className="bg-orange-100 w-5 h-5 rounded-full relative ml-2 text-orange-600 font-semibold text-base"><div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">5</div></div></div>
                        </Link>
                       
                    </div>
                </div> */}

                <div className="relative group cursor-pointer"
                    onClick={()=>{
                        props.setGlobalSearchVisibility(true)
                    }}
                >
                    <div className="flex items-center align-middle w-60 h-8 border border-slate-300 bg-slate-50 rounded-md px-2 mt-1">
                        <div className="grow">
                            <img alt="z-search" className='my-2 h-5' src="/assets/img/Z-Search.svg" />
                        </div>
                        {/* <em className="fa-solid fa-search"></em> */}
                        <div className="flex gap-1">
                            <kbd className="flex items-center text-xs bg-orange-200 border border-slate-400 px-1 rounded-md shadow-sm">Ctrl</kbd><div className="text-sm">+</div><kbd className="flex items-center text-xs bg-orange-200 border border-slate-400 px-1 rounded-md shadow-sm">Z</kbd>
                        </div>
                    </div>
                </div>

                <div className="relative group">
                    <div className={`w-10 h-10 flex align-middle items-center cursor-pointer`}>
                        <i className={`fa-regular fa-bell mr-2 mt-1 text-gray-800 text-${theme.themeColor}-${theme.themeContrast}-component-text text-2xl`}></i>
                    </div>
                    <div className="absolute right-0 top-full w-[350px] flex flex-col invisible group-hover:visible bg-white rounded-lg shadow-md overflow-hidden">
                        <ActivityNotificationCard/>
                    </div>
                </div>
                    
                <div className="relative group">
                    {globalContext?.userData?.picture && <img className={`w-10 h-10 flex align-middle items-center cursor-pointer rounded-full`} src={me?.picture || globalContext?.userData?.picture} alt="avatar" />}
                    {!globalContext?.userData?.picture && <div className={`w-10 h-10 flex align-middle items-center cursor-pointer`}>
                        <i className={`fa-regular fa-user mr-2 mt-1 text-${theme.themeColor}-${theme.themeContrast}-component-text text-2xl`}></i>
                    </div>}
                    <div className="absolute right-0 top-full w-[200px] flex flex-col invisible group-hover:visible bg-white rounded-lg shadow-md overflow-hidden">
                        <Link to="/settings/user">
                            <div className="flex gap-3 px-3 py-2 hover:bg-slate-100 whitespace-nowrap cursor-pointer capitalize">
                                {/* <i className="fa-regular fa-user"></i> {me?.name || globalContext?.userData?.nickname || 'User'} */}
                                <i className="fa-regular fa-user"></i> {me?.name || globalContext?.userData?.name || 'User'}
                            </div>
                        </Link>
                        <Link to="/settings/organization">
                            <div className="flex gap-3 px-3 py-2 hover:bg-slate-100 whitespace-nowrap cursor-pointer">
                                <i className="fa-regular fa-building"></i> Organization
                            </div>
                        </Link>
                        {/* <Link to="/settings">
                            <div className="flex gap-3 px-3 py-2 hover:bg-slate-100 whitespace-nowrap cursor-pointer">
                                <i className="fa-regular fa-gear"></i> Settings
                            </div>
                        </Link> */}
                        <Link to='/logout'>
                            <div className="flex gap-3 px-3 py-2 hover:bg-slate-100 whitespace-nowrap cursor-pointer">
                                <i className="fa-regular fa-arrow-right-from-bracket"></i> Logout
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopBar;
