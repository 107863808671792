import React, { FC, ComponentProps, useContext } from "react";
import { SideDrawerContext, ThemeContext } from "../../../contexts";

interface Props extends ComponentProps<"div">  {
    // levelOneDrawerVisibility: boolean
    // setLevelOneDrawerVisibility: Dispatch<SetStateAction<boolean>>
    // levelTwoDrawerVisibility: boolean
    // setLevelTwoDrawerVisibility: Dispatch<SetStateAction<boolean>>
    /**
     * Child node(s) of button
     */
    // children?: ReactNode | ReactNode[] | string;
    /**
     * 
     */
    // className?: string
    // onClick?: () => void
}

/**
 * Button Component
 * @param param0 
 * @returns 
 */
const LevelOneDrawer:FC<Props> = ({
    // levelOneDrawerVisibility=false,
    // setLevelOneDrawerVisibility=()=>{},
    // levelTwoDrawerVisibility=false,
    // setLevelTwoDrawerVisibility=()=>{},
    // className='',
    // children=<></>,
    // onClick=()=>{},
    ...rest
}: Props) => {

    const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    const sideDrawerContext = useContext(SideDrawerContext)

    return (
        <>
            <div className={`levelOneDrawer z-[999995] absolute top-16 transition-[width] duration-200 ease-in-out ${sideDrawerContext.levelOneDrawerVisibility ? 'w-[440px] md:w-[480px]' : 'w-0'} right-0 bottom-0 ${ theme.themeContrast === 'light' ? 'bg-[#FFFFFF]' : 'bg-[#333333]'} shadow-lg overflow-hidden`}>
                <div className={`contentWrapper relative w-full h-full transition-all duration-200 ease-in-out ${sideDrawerContext.levelOneDrawerVisibility ? 'block' : 'hidden'} `}>
                    <button
                        className={`modal-close-button z-10 absolute top-4 right-4 pb-[3px] rounded hover:bg-${theme.themeColor}-${theme.themeContrast}-primary-default hover:bg-opacity-25 text-xl px-2`}
                        onClick={()=>{
                            if( sideDrawerContext.levelOneDrawerVisibility === false ) {
                                sideDrawerContext.setLevelOneDrawerVisibility(true)
                            } else {
    
                                if( sideDrawerContext.levelOneDrawerVisibility === true && sideDrawerContext.levelTwoDrawerVisibility === false ) {
                                    sideDrawerContext.setLevelOneDrawerVisibility(false);
                                }
    
                                if( sideDrawerContext.levelOneDrawerVisibility === true && sideDrawerContext.levelTwoDrawerVisibility === true ) {
                                    sideDrawerContext.setLevelOneDrawerVisibility(false);
                                    sideDrawerContext.setLevelTwoDrawerVisibility(false);
                                }
                            }
                        }}
                    >&times;</button>

                    {sideDrawerContext.levelOneDrawerChild}
                    
                </div>
            </div>
        </>
    );
}

export default LevelOneDrawer;
