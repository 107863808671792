/* eslint-disable array-callback-return */
// import {DateTime} from "luxon";
// import { ReactNode } from "react";
import { Link } from "react-router-dom";
import Tooltip from "../components/core/Tooltip/Tooltip";
import { deviceSymbol, getDeviceStatus, lastBackupFormatter, lastConnectionFormatter, originalDisabledReasons, protectedDataFormatter } from "./agentUtils";
import { getFormattedDateTime } from "./format";


type IDrilledParsedAgentData = {
    data: any[],
    total: any,
    devicesId: any,
    devices: any[],
    unit: string,
    comment: string,
    endpoints: any[],
}

type IParsedAgentData = {
    backup: IDrilledParsedAgentData;
    restore: IDrilledParsedAgentData;
    policyChange: IDrilledParsedAgentData;
    strategyChange: IDrilledParsedAgentData;
    agentIDs: any[];
    changedAgents: any[];
    noChangeAgents: any[];
    // all: any[];
}

// const generateTableData = (tableRawData: any[], tableColumns: any[], tableDataFormatter: ()=>void) => {
//     // console.log({tableRawData});
//     // let dataText: any[] = []
//     // let dataNode: any[] = []

//     // try {
//     //     tableRawData.forEach(row => {
//     //         let rowTextObject = {};
//     //         let rowNodeObject = {};
//     //         tableColumns.forEach(column => {
//     //             rowTextObject[column.name] = column.column ? tableDataFormatter(row[column.column],column.name,'text') : tableDataFormatter(row,column.name,'text')    
//     //             rowNodeObject[column.name] = column.column ? tableDataFormatter(row[column.column],column.name,'table') : tableDataFormatter(row,column.name,'table')    
//     //         });
//     //         dataText.push(rowTextObject);
//     //         dataNode.push(rowNodeObject);
//     //     });
//     //     setTableFormattedNodeData(dataNode);
//     //     setTableFormattedTextData(dataText);
//     // } catch (error) {
//     //     console.log({error})
//     // }
//     return false;
// }

const parseAuditData = async (activityData: any, endpointModifiedData: any[]) => {
    let parseData: IParsedAgentData = {
        backup: { data: [], total: 0, devicesId: [], devices: [], unit: '', comment: '', endpoints: [] },
        restore: { data: [], total: 0, devicesId: [], devices: [], unit: '', comment: '', endpoints: [] },
        policyChange: { data: [], total: 0, devicesId: [], devices: [], unit: '', comment: '', endpoints: [] },
        strategyChange: { data: [], total: 0, devicesId: [], devices: [], unit: '', comment: '', endpoints: [] },
        agentIDs: [],
        changedAgents: [],
        noChangeAgents: []
    };

    await (activityData?.activities || []).forEach((activity:any, activityIndex: any[]) => {
        if ( activity?.activity_type === 'endpoints-backup' ) {
            parseData.backup.data = activity?.data || [];
            parseData.backup.total = activity?.total[0] || 0;
            parseData.backup.unit = activity?.unit || '';
            parseData.backup.devicesId = activity?.endpoints || [];
            parseData.backup.comment = activity?.comment || '';

            /* collecting all agents with backup activity */
            (endpointModifiedData || []).find((agent:any)=>{
                let agentServerId = `${agent.server_id.text}#${agent.device_id.text}`;
                if( activity.endpoints.includes(agentServerId) ) {
                    parseData.backup.devices.push(agent);
                }
            });
        }
        if ( activity?.activity_type === 'endpoints-restore' ) {
            parseData.restore.data = activity?.data || [];
            parseData.restore.total = activity?.total[0] || 0;
            parseData.restore.unit = activity?.unit || '';
            parseData.restore.devicesId = activity?.endpoints || [];
            parseData.restore.comment = activity?.comment || '';

            /* collecting all agents with restore activity */
            (endpointModifiedData || []).find((agent:any)=>{
                let agentServerId = `${agent.server_id.text}#${agent.device_id.text}`;
                if( activity.endpoints.includes(agentServerId) ) {
                    parseData.restore.devices.push(agent);
                }
            });
        }
        if ( activity?.activity_type === 'endpoints-policy-change' ) {
            parseData.policyChange.data = activity?.data || [];
            parseData.policyChange.total = activity?.total[0] || 0;
            parseData.policyChange.unit = activity?.unit || '';
            parseData.policyChange.devicesId = activity?.endpoints || [];
            parseData.policyChange.comment = activity?.comment || '';

            /* collecting all agents with policy change activity */
            (endpointModifiedData || []).find((agent:any)=>{
                let agentServerId = `${agent.server_id.text}#${agent.device_id.text}`;
                if( activity.endpoints.includes(agentServerId) ) {
                    parseData.policyChange.devices.push(agent);
                }
            });
        }
        if ( activity?.activity_type === 'endpoints-strategy-change' ) {
            parseData.strategyChange.data = activity?.data || [];
            parseData.strategyChange.total = activity?.total[0] || 0;
            parseData.strategyChange.unit = activity?.unit || '';
            parseData.strategyChange.devicesId = activity?.endpoints || [];
            parseData.strategyChange.comment = activity?.comment || '';

            /* collecting all agents with strategy change activity */
            (endpointModifiedData || []).find((agent:any)=>{
                let agentServerId = `${agent.server_id.text}#${agent.device_id.text}`;
                if( activity.endpoints.includes(agentServerId) ) {
                    parseData.strategyChange.devices.push(agent);
                }
            });
        }

        activity.endpoints.forEach((endpoint:any) => {
            if( !parseData.agentIDs.includes(endpoint) ) {
                parseData.agentIDs.push(endpoint)
            }
        });
    });

    /* collecting all effected agents */
    (endpointModifiedData || []).find((agent:any)=>{
        let agentServerId = `${agent.server_id.text}#${agent.device_id.text}`;
        if( parseData.agentIDs.includes(agentServerId) ) {
            parseData.changedAgents.push(agent);
        }
    });

    /* collected all agents with no effect */
    (endpointModifiedData || []).find((agent:any)=>{
        let agentServerId = `${agent.server_id.text}#${agent.device_id.text}`;
        if( !parseData.agentIDs.includes(agentServerId) ) {
            parseData.noChangeAgents.push(agent);
        }
    });

    return parseData;
}

const processEndpointData = async (endpointsOriginalData: any) => {

    let agentsData: any[] = []

    await (endpointsOriginalData?.endpoints || []).forEach((endpoint:any, endpointIndex: number) => {

        let deviceStatus = getDeviceStatus(endpointsOriginalData, endpoint)

        // let deviceStatus = {
        //     text: status,
        //     node: getDeviceStatus(endpointsOriginalData, endpoint)
        // }

        let returningVal = <>Unknown</>


        if( deviceStatus === 'Pending' ) {
            returningVal = <div className="text-xs px-3 py-1 text-orange-500 font-light bg-orange-100 max-w-min rounded-full">Pending</div>
        } else if( deviceStatus === 'Protected' ) {
            returningVal = <div className="text-xs px-3 py-1 text-green-500 font-light bg-green-100 max-w-min rounded-full">Protected</div>
        } else if( deviceStatus === 'Unprotected' ) {
            returningVal = <div className="text-xs px-3 py-1 text-red-500 font-light bg-red-100 max-w-min rounded-full">Unprotected</div>
        } else if( deviceStatus === 'Deleted' ) {
            returningVal = <div className="text-xs px-3 py-1 text-red-500 font-light bg-red-100 max-w-min rounded-full">Deleted</div>
        } else if( deviceStatus === 'Disabled' ) {
            returningVal =  <div className="relative group">
                                <div className="text-xs px-3 py-1 text-violet-500 font-light bg-violet-100 max-w-min rounded-full">Disabled</div>
                                { endpointIndex === 0 ? (
                                    <Tooltip direction="down"  className="" value={originalDisabledReasons[endpoint?.agent_profile?.BlockListInfos?.Reason] || ""} />
                                    ) : (
                                    <Tooltip  className="" value={originalDisabledReasons[endpoint?.agent_profile?.BlockListInfos?.Reason] || ""} />
                                ) }
                            </div>
        } else {
            returningVal = <div className="text-xs px-3 py-1 text-black-600 font-light bg-opacity-10 bg-black-600 max-w-min rounded-full">Unknown</div>
        }

        
        /* now generate modified row data */
        agentsData.push({
            endpoint_id: {
                text: endpoint?._id || '',
                raw: endpoint?._id || '',
                node: endpoint?._id || ''
            },
            device_id: {
                text: endpoint?.agent_id || '',
                raw: endpoint?.agent_id || '',
                node: <Link to={`/endpoint/devices/profile/${endpoint?._id || ''}`} >
                        <span className="cursor-pointer">{endpoint?.agent_id || ''}</span>
                    </Link>
            },
            server_id: {
                text: endpoint?.server_id || '',
                raw: endpoint?.server_id || '',
                node: endpoint?.server_id || ''
            },
            hostname: {
                text: endpoint?.agent_profile?.AdminInfos?.Name || '',
                // raw: endpoint?.agent_profile?.AdminInfos?.Name || '',
                raw: `${endpoint?.agent_profile?.AdminInfos?.Name || ''} / ${endpoint?.agent_profile?.AgentInfos?.ComputerName || ''}`,
                node: <>
                        {/* <span className="relative group">
                            <span className="">{endpoint?.agent_profile?.AdminInfos?.Name || ''}</span>
                            <Tooltip direction={`${ agentsData.length === 0 ? 'down' : 'up' }`} value={`${endpoint?.agent_profile?.AdminInfos?.Name || ''} / ${endpoint?.agent_profile?.AgentInfos?.ComputerName || ''}`} />
                        </span> */}

                        <div className="relative group">
                            <div className="max-w-[150px] truncate">{endpoint?.agent_profile?.AdminInfos?.Name  || ''}</div>
                            <Tooltip value={`${endpoint?.agent_profile?.AdminInfos?.Name || ''} / ${endpoint?.agent_profile?.AgentInfos?.ComputerName || ''}`} />
                        </div>
                    </>
            },
            os: {
                text: endpoint?.agent_profile?.AgentInfos?.System || '',
                raw: endpoint?.agent_profile?.AgentInfos?.System || '',
                node: <span className="relative group">
                        <span className="">{deviceSymbol(endpoint?.agent_profile?.AgentInfos?.System || '')}</span>
                        <Tooltip direction={`${ agentsData.length === 0 ? 'down' : 'up' }`} value={endpoint?.agent_profile?.AgentInfos?.System || 'Unknown'} />
                    </span>
            },
            status: { text: deviceStatus, node: returningVal, reason: originalDisabledReasons[endpoint?.agent_profile?.BlockListInfos?.Reason] }, //returningVal,
            lastConnection: {
                text: lastConnectionFormatter(endpoint?.agent_profile?.NetworkInfos?.LastConnectionTime || ''),
                raw: endpoint?.agent_profile?.NetworkInfos?.LastConnectionTime || '',
                node: <div className="relative group">
                        <span className="">{lastConnectionFormatter(endpoint?.agent_profile?.NetworkInfos?.LastConnectionTime || '')}</span>
                        <Tooltip direction={`${ agentsData.length === 0 ? 'down' : 'up' }`} value={lastBackupFormatter(endpoint?.agent_profile?.NetworkInfos?.LastConnectionTime || '') as string} />
                    </div>
            },
            // lastBackup: {
            //     text: lastBackupFormatter(endpoint?.agent_profile?.NetworkInfos?.LastCompletedSession || ''),
            //     node: <div className="">{lastBackupFormatter(endpoint?.agent_profile?.NetworkInfos?.LastCompletedSession || '')}</div>
            // },
            lastBackup: {
                text: lastBackupFormatter(endpoint?.agent_profile?.NetworkInfos?.SyncTime || ''),
                raw: endpoint?.agent_profile?.NetworkInfos?.SyncTime || '',
                node: <div className="">{lastBackupFormatter(endpoint?.agent_profile?.NetworkInfos?.SyncTime || '')}</div>
            },
            protectedData: {
                text: protectedDataFormatter(endpoint?.agent_profile?.ServerInfos?.Backup?.ProtSize || ''),
                raw: endpoint?.agent_profile?.ServerInfos?.Backup?.ProtSize || '',
                node: <div className="">{protectedDataFormatter(endpoint?.agent_profile?.ServerInfos?.Backup?.ProtSize || '')}</div>
            },
            installed_at: {
                // text: endpoint?.metadata?.agent_installation_date ? getFormattedDateTime(endpoint?.metadata?.agent_installation_date) : 'NA',
                text: endpoint?.metadata?.agent_installation_date ? endpoint?.metadata?.agent_installation_date : 'NA',
                raw: endpoint?.metadata?.agent_installation_date ? endpoint?.metadata?.agent_installation_date : 'NA',
                node: endpoint?.metadata?.agent_installation_date ? <div className="">{getFormattedDateTime(endpoint?.metadata?.agent_installation_date)}</div> : <div className="">NA</div>
            },
            deleted_at: {
                // text: endpoint?.metadata?.agent_deletion_date ? getFormattedDateTime(endpoint?.metadata?.agent_deletion_date) : 'NA',
                text: endpoint?.metadata?.agent_deletion_date ? endpoint?.metadata?.agent_deletion_date : 'NA',
                raw: endpoint?.metadata?.agent_deletion_date ? endpoint?.metadata?.agent_deletion_date : 'NA',
                node: endpoint?.metadata?.agent_deletion_date ? <div className="">{getFormattedDateTime(endpoint?.metadata?.agent_deletion_date)}</div> : <div className="">NA</div>
            }
        })
    });

    return agentsData;
}

export {
    parseAuditData,
    processEndpointData
}