import React, { Dispatch, SetStateAction, ReactNode, useState } from "react";

interface IGlobalContext {
    userData: any;
    setUser: Dispatch<SetStateAction<any>>;
}

export const GlobalContext = React.createContext({} as IGlobalContext);

interface IProp {
    children: ReactNode | ReactNode[];
}

const GlobalProvider = (props: IProp) => {
    
    const [userData, setUserData] = useState({token:""});
    const setUser = (data:any) =>{
        if(data.token!==userData.token) setUserData(data);
        // console.log({user:data})
        localStorage.setItem('token',data.token);
    } 

    return (
      <GlobalContext.Provider value={{userData, setUser: setUser}}>
        {props.children}
      </GlobalContext.Provider>
    )
}

export default GlobalProvider;