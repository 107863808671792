import React, { FC, ReactNode, ComponentProps } from "react";
// import { ThemeContext } from "../../../contexts";


interface IPlaceholderProps extends ComponentProps<"button">  {
    /**
     * Child node(s) of button
     */
    children?: ReactNode | ReactNode[] | string;
    /**
     * 
     */
    className?: string
    /**
     * Optional click handler
     */
    onClick?: () => void;
}

/**
 * Placeholder Component
 * 
 * w-full w-[20px]
 * @param param0 
 * @returns 
 */
const Placeholder:FC<IPlaceholderProps> = ({
    className='',
    ...rest
}: IPlaceholderProps) => {

    // const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};

    return (
        <>
            <span className={`placeholder block bg-slate-300 h-[20px] w-[160px] animate-pulse ${className}`} />
        </>
    );
}

export default Placeholder;
