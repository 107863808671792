import React, { Dispatch, SetStateAction, ReactNode, useState, useEffect, useContext } from "react";
import {
  // CompanyVerification,
  // EmailVerification,
  // LogoutPage,

  BackupFiles,
  EndpointDashboard,
  EndpointDevice,
  EndpointDeviceAuditReport,
  EndpointDeviceAuditReportFilterPage,
  EndpointDeviceProfile,
  EndpointPolicy,
  EndpointReport,
  EndpointStrategy,
  EndpointUserProfile,
  EndpointUsers,

  ExchangeDashboard,
  // MS365Dashboard,
  OneDriveDashboard,

  License,
  OrganizationSettings,
  UserSettings,

  // Customizable,
  Dashboard,
  // Dashboard1,
  // GettingStarted,
  Library,
  MenuManagement,
  Notification,
  // ServiceTest,
  // SortableCloning,
  Strategy,
  TableExample,
  Users,
  Settings,
  OrderDetails,
  ComingSoon,
  ProgressBar,
  CreateOrder,
  ManageLicense,
  AddLicense,
  ManageOrder,
  
} from '../../pages'
import PageTemplate from "../../pages/PageTemplate";
import Ms365Dashboard from "../../pages/Microsoft365/MS365dashboard";
import AuditReportProvider from "../AuditReportContext/AuditReportContext";
import Notifications from "../../pages/settings/Notifications";
import Security from "../../pages/settings/Security";
import { getAPICall } from "../../services/APILayer/axiosMethodCalls";
import Partnerdashboard from "../../pages/settings/PartnerDashboard";
import OnboardProcess from "../../pages/settings/OnboardProcess";
import Orders from "../../pages/ManageOrder";
import AddOrders from "../../pages/ManageOrder/AddOrders";
import { GlobalContext } from "../GlobalContext/GlobalContext";
import { parseJwt } from "../../utils/format";
// import Analise from "../../pages/Analise";
// import AddLicense from "../../pages/Partner/AddLicense";

interface ICustomizationContext {
    pageElements: {[key: string]: any};
    setPageElements: Dispatch<SetStateAction<{[key: string]: any}>>;
}

export const CustomizationContext = React.createContext({} as ICustomizationContext);

interface ICustomizationContextProp {
    children: ReactNode | ReactNode[];
}

const dict = {
  endpoints: '/endpoint',
  ms365: '/microsoft-365',
  servers: '/servers',
}

const CustomizationProvider = (props: ICustomizationContextProp) => {

    const [licenseInfo, setLicenseInfo] = useState<any>({});
    const [pageElements, setPageElements] = useState<{[key: string]: any}>({})
    const globalContext = useContext(GlobalContext)


    const getLicenseInfo = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/app/licenses/me`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( Object.keys(licenseInfo).length > 0 ) {
            return;
        }
        const fetchLicenseInfo = () => {
            getLicenseInfo()
                .then((license)=>{
                    // console.log({license})
                    // if(license?.response?.status === 200) {
                      setLicenseInfo(license)
                    // }
                })
                .catch(err=>console.log(err));
        }
        setTimeout(() => {
          fetchLicenseInfo();
        }, 500);
    },[licenseInfo])

    let unGrouped = {
      '/dashboard': {
        icon: "fa-regular fa-grid-2",
        title: "Dashboard",
        new: false,
        displayOnNav: true,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component: <Dashboard />, props: {} },
        breadcrumb: [
          {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
          {name: 'Dashboard', icon: '', link: ''}
        ],
        components: [],
        access: [],
        apiData: [],
        pages: [
        ]
      },
      // '/analise': {
      //   icon: "fa-regular fa-chart-line",
      //   title: "Analise",
      //   new: false,
      //   displayOnNav: true,
      //   collapsed: true,
      //   redirection: { value: false, url: '' },
      //   staticPage: { value: true, component: <Analise />, props: {} },
      //   breadcrumb: [
      //     {name: 'Analise', icon: 'fa-solid fa-home', link: '/Analise'},
      //     {name: 'Analise', icon: '', link: ''}
      //   ],
      //   components: [],
      //   access: [],
      //   apiData: [],
      //   pages: [
      //   ]
      // },
      '/organization': {
        icon: "fa-solid fa-sitemap",
        title: "Organization",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: false, component: null, props: {} },
        breadcrumb: [
          {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
          {name: 'Organization', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/analytics': {
        icon: "fa-solid fa-chart-mixed",
        title: "Analytics",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: false, component: null, props: {} },
        breadcrumb: [
          {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
          {name: 'Analytics', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/notification': {
        icon: "fa-regular fa-bell",
        title: "Notification",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component: <Notification />, props: {} },
        breadcrumb: [
          {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
          {name: 'Notification', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/strategy': {
        icon: "fa-regular fa-bell",
        title: "Strategy",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component: <Strategy />, props: {} },
        breadcrumb: [
          {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
          {name: 'Strategy', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/table': {
        icon: "fa-regular fa-bell",
        title: "Device",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component: <TableExample />, props: {} },
        breadcrumb: [
          {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
          {name: 'Device', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/createorder': {
        icon: "fa-regular fa-bell",
        title: "Create Order",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component: <CreateOrder/> , props: {} },
        breadcrumb: [
          // {name: 'Managed Services', icon: '', link: ''},
          {name: 'Create Order', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/orderdetails': {
        icon: "fa-regular fa-bell",
        title: "Order Details",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component: <OrderDetails/> , props: {} },
        breadcrumb: [
          // {name: 'Managed Services', icon: '', link: ''},
          {name: 'Order Details', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/manage/license': {
        icon: "fa-regular fa-file-certificate",
        title: "Manage License",
        new: false,
        displayOnNav: false,
        collapsed: false,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component: <ManageLicense/> , props: {} },
        breadcrumb: [
          {name: 'Onboard Process', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/manage/license/add': {
        icon: "fa-regular fa-handshake",
        title: "Add Company",
        new: false,
        displayOnNav: false,
        collapsed: false,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component: <AddLicense />, props: {} },
        breadcrumb: [
          // {name: 'Managed Services', icon: '', link: ''},
          {name: 'Onboard Process', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/manage/orders': {
        icon: "fa-regular fa-box",
        title: "Manage Orders",
        new: false,
        displayOnNav: false,
        collapsed: false,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component: <ManageOrder /> , props: {} },
        breadcrumb: [
          // {name: 'Managed Services', icon: '', link: ''},
          {name: 'Onboard Process', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/manage/orders/add': {
        icon: "fa-regular fa-box",
        title: "AddOrders",
        new: false,
        displayOnNav: false,
        collapsed: false,
        redirection: { value: false, url: '/addorders' },
        staticPage: { value: false, component: <AddOrders/> , props: {} },
        breadcrumb: [
          // {name: 'Managed Services', icon: '', link: ''},
          {name: 'Onboard Process', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
    }

    let zeeqAdminTools = {
      '/z-search': {
        icon: "fa-regular fa-magnifying-glass",
        title: "Z-Search",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: false, component: null, props: {} },
        breadcrumb: [
          {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
          {name: 'ZeeQ Admin Tools', icon: '', link: ''},
          {name: 'Z-Search', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/z-live': {
        icon: "fa-solid fa-signal-stream",
        title: "Z-Live",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: false, component: null, props: {} },
        breadcrumb: [
          {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
          {name: 'ZeeQ Admin Tools', icon: '', link: ''},
          {name: 'Z-live', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/z-support': {
        icon: "fa-solid fa-headset",
        title: "Z-Support",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: false, component: null, props: {} },
        breadcrumb: [
          {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
          {name: 'ZeeQ Admin Tools', icon: '', link: ''},
          {name: 'Z-support', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
    }

    let allManagedProducts = {
      '/endpoint': {
        icon: "fa-regular fa-tv",
        title: "Endpoint Backup",
        new: false,
        displayOnNav: licenseInfo?.license?.license_data?.product_access?.endpoints?.enabled || false,
        collapsed: true,
        redirection: { value: false, url: '/endpoint/users' },
        staticPage: { value: true, component: <EndpointDashboard />, props: {} },
        breadcrumb: [
          {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
          {name: 'Managed Services', icon: '', link: ''},
          {name: 'Endpoints', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: [
          {
            link: '/endpoint/users',
            icon: "fa-regular fa-chalkboard-user",
            title: "Endpoint Users",
            new: false,
            displayOnNav: false,
            collapsed: true,
            redirection: { value: false, url: '' },
            staticPage: { value: true, component: <EndpointUsers />, props: {} },
            breadcrumb: [
              {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
              {name: 'Managed Services', icon: '', link: ''},
              {name: 'Endpoints', icon: '', link: ''},
              {name: 'Endpoint Users', icon: '', link: ''},
            ],
            components: [],
            access: [],
            apiData: [],
          },
          {
            link: '/endpoint/user/profile',
            icon: "fa-regular fa-chalkboard-user",
            title: "Endpoint Users profile",
            new: false,
            displayOnNav: false,
            collapsed: true,
            redirection: { value: false, url: '' },
            staticPage: { value: true, component: <EndpointUserProfile />, props: {} },
            breadcrumb: [
              {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
              {name: 'Managed Services', icon: '', link: ''},
              {name: 'Endpoints', icon: '', link: ''},
              {name: 'Endpoint Users', icon: '', link: ''},
            ],
            components: [],
            access: [],
            apiData: [],
          },
          {
            link: '/endpoint/devices',
            icon: "fa-regular fa-desktop",
            title: "Endpoint Devices",
            new: true,
            displayOnNav: true,
            collapsed: true,
            redirection: { value: false, url: '' },
            staticPage: { value: true, component: <EndpointDevice />, props: {} },
            breadcrumb: [
              {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
              {name: 'Managed Services', icon: '', link: ''},
              {name: 'Endpoints', icon: '', link: ''},
              {name: 'Endpoint Devices', icon: '', link: ''},
            ],
            components: [],
            access: [],
            apiData: [],
          },
          {
            link: '/endpoint/devices/profile/:deviceId',
            icon: "fa-regular fa-upload",
            title: "Endpoint Devices",
            new: true,
            displayOnNav: false,
            collapsed: true,
            redirection: { value: false, url: '' },
            staticPage: { value: true, component: <EndpointDeviceProfile />, props: {} },
            breadcrumb: [
              {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
              {name: 'Managed Services', icon: '', link: ''},
              {name: 'Endpoints', icon: '', link: ''},
              {name: 'Endpoint Devices', icon: '', link: ''},
            ],
            components: [],
            access: [],
            apiData: ['user', 'admin'],
          },
          {
            link: '/endpoint/policy',
            icon: "fa-regular fa-list-check",
            title: "Backup Policies",
            new: true,
            displayOnNav: true,
            collapsed: true,
            redirection: { value: false, url: '' },
            staticPage: { value: true, component: <EndpointPolicy />, props: {} },
            breadcrumb: [
              {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
              {name: 'Managed Services', icon: '', link: ''},
              {name: 'Endpoints', icon: '', link: ''},
              {name: 'Endpoint Policy', icon: '', link: ''},
            ],
            components: [],
            access: [],
            apiData: [],
          },
          {
            link: '/endpoint/strategy',
            icon: "fa-regular fa-chess-knight",
            title: "Backup Strategies",
            new: true,
            displayOnNav: true,
            collapsed: true,
            redirection: { value: false, url: '' },
            staticPage: { value: true, component: <EndpointStrategy />, props: {} },
            breadcrumb: [
              {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
              {name: 'Managed Services', icon: '', link: ''},
              {name: 'Endpoints', icon: '', link: ''},
              {name: 'Endpoint Strategy', icon: '', link: ''},
            ],
            components: [],
            access: [],
            apiData: [],
          },
          {
            link: '/endpoint/reports',
            icon: "fa-regular fa-print",
            title: "Endpoint Reports",
            new: true,
            displayOnNav: false,
            collapsed: true,
            redirection: { value: false, url: '' },
            staticPage: { value: true, component: <EndpointReport />, props: {} },
            breadcrumb: [
              {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
              {name: 'Managed Services', icon: '', link: ''},
              {name: 'Endpoints', icon: '', link: ''},
              {name: 'Endpoint Strategy', icon: '', link: ''},
            ],
            components: [],
            access: [],
            apiData: [],
          },
          {
            link: '/endpoint/audit/search/result/:reportId',
            icon: "fa-regular fa-print",
            title: "Audit Trail",
            new: true,
            displayOnNav: false,
            collapsed: true,
            redirection: { value: false, url: '' },
            staticPage: { value: true, component: <><AuditReportProvider><EndpointDeviceAuditReport /></AuditReportProvider></>, props: {} },
            breadcrumb: [
              {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
              {name: 'Managed Services', icon: '', link: ''},
              {name: 'Endpoints', icon: '', link: ''},
              {name: 'Endpoint Strategy', icon: '', link: ''},
            ],
            components: [],
            access: [],
            apiData: [],
          },
          {
            link: '/endpoint/audit/search',
            icon: "fa-regular fa-file-chart-column",
            title: "Audit Trail",
            new: true,
            displayOnNav: true,
            collapsed: true,
            redirection: { value: false, url: '' },
            staticPage: { value: true, component: <><AuditReportProvider><EndpointDeviceAuditReportFilterPage /></AuditReportProvider></>, props: {} },
            breadcrumb: [
              {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
              {name: 'Managed Services', icon: '', link: ''},
              {name: 'Endpoints', icon: '', link: ''},
              {name: 'Endpoint Strategy', icon: '', link: ''},
            ],
            components: [],
            access: [],
            apiData: [],
          },
          {
            link: '/endpoint/backup-files',
            icon: "fa-regular fa-clock-rotate-left",
            title: "Backup Files",
            new: true,
            displayOnNav: true,
            collapsed: true,
            redirection: { value: false, url: '' },
            staticPage: { value: true, component: <BackupFiles />, props: {} },
            breadcrumb: [
              {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
              {name: 'Managed Services', icon: '', link: ''},
              {name: 'Endpoints', icon: '', link: ''},
              {name: 'Backup Fils', icon: '', link: ''},
            ],
            components: [],
            access: [],
            apiData: [],
          },
        ]
      },
      '/microsoft-365': {
        icon: "fa-brands fa-windows",
        title: "MS 365 Backup",
        new: false,
        displayOnNav: licenseInfo?.license?.license_data?.product_access?.ms365?.enabled || false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component: <Ms365Dashboard/>, props: {} },
        breadcrumb: [
          {name: 'Managed Services', icon: '', link: ''},
          {name: 'Microsoft 365', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: [
          {
            link: '/microsoft-365/Exchange',
            icon: "fa-regular fa-chalkboard-user",
            svg: '/assets/img/logo/ms365/Microsoft_Exchange.svg',
            title: "Exchange",
            new: false,
            displayOnNav: true,
            collapsed: true,
            redirection: { value: false, url: '' },
            staticPage: { value: true, component: <ExchangeDashboard/>, props: {} },
            breadcrumb: [
              {name: 'Managed Services', icon: '', link: ''},
              {name: 'Microsoft 365', icon: '', link: ''},
              {name: 'Exchange', icon: '', link: ''},
            ],
            components: [],
            access: [],
            apiData: [],
          },
          {
            link: '/microsoft-365/OneDrive',
            icon: "fa-regular fa-chalkboard-user",
            svg: '/assets/img/logo/ms365/MS_OneDrive.svg',
            title: "OneDrive",
            new: false,
            displayOnNav: true,
            collapsed: true,
            redirection: { value: false, url: '' },
            staticPage: { value: true, component: <OneDriveDashboard />, props: {} },
            breadcrumb: [
              {name: 'Managed Services', icon: '', link: ''},
              {name: 'Microsoft 365', icon: '', link: ''},
              {name: 'OneDrive', icon: '', link: ''},
            ],
            components: [],
            access: [],
            apiData: [],
          },
          {
            link: '/microsoft-365/MailBox',
            icon: "fa-regular fa-chalkboard-user",
            svg: '/assets/img/logo/ms365/MS_Outlook.svg',
            title: "MailBox",
            new: false,
            displayOnNav: false,
            collapsed: true,
            redirection: { value: false, url: '' },
            staticPage: { value: true, component: <ComingSoon />, props: {} },
            breadcrumb: [
              {name: 'Managed Services', icon: '', link: ''},
              {name: 'Microsoft 365', icon: '', link: ''},
              {name: 'MailBox', icon: '', link: ''},
            ],
            components: [],
            access: [],
            apiData: [],
          },
          {
            link: '/microsoft-365/SharePoint',
            icon: "fa-regular fa-chalkboard-user",
            svg: '/assets/img/logo/ms365/MS_SharePoint.svg',
            title: "SharePoint",
            new: false,
            displayOnNav: false,
            collapsed: true,
            redirection: { value: false, url: '' },
            staticPage: { value: true, component: <ComingSoon />, props: {} },
            breadcrumb: [
              {name: 'Managed Services', icon: '', link: ''},
              {name: 'Microsoft 365', icon: '', link: ''},
              {name: 'SharePoint', icon: '', link: ''},
            ],
            components: [],
            access: [],
            apiData: [],
          },
          {
            link: '/microsoft-365/Contacts',
            icon: "fa-regular fa-chalkboard-user",
            svg: '/assets/img/logo/ms365/MS_Contacts.svg',
            title: "Contacts",
            new: false,
            displayOnNav: false,
            collapsed: true,
            redirection: { value: false, url: '' },
            staticPage: { value: true, component: <ComingSoon />, props: {} },
            breadcrumb: [
              {name: 'Managed Services', icon: '', link: ''},
              {name: 'Microsoft 365', icon: '', link: ''},
              {name: 'Contacts', icon: '', link: ''},
            ],
            components: [],
            access: [],
            apiData: [],
          },
        ]
      },
      '/data-migration-service': {
        icon: "fa-solid fa-rotate",
        title: "Data Migration",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: false, component: null, props: {} },
        breadcrumb: [
          {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
          {name: 'Managed Services', icon: '', link: ''},
          {name: 'Data Migration', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/servers': {
        icon: "fa-regular fa-server",
        title: "Servers",
        new: false,
        displayOnNav: licenseInfo?.license?.license_data?.product_access?.servers?.enabled || false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component:<ComingSoon/>, props: {} },
        breadcrumb: [
          {name: 'Managed Services', icon: '', link: ''},
          {name: 'Servers', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: [
          {
            link: '/servers/file-server',
            icon: "fa-regular fa-chalkboard-user",
            title: "File Server",
            new: false,
            displayOnNav: true,
            collapsed: true,
            redirection: { value: false, url: '' },
            staticPage: { value: true, component: <ComingSoon />, props: {} },
            breadcrumb: [
              {name: 'Managed Services', icon: '', link: ''},
              {name: 'Servers', icon: '', link: ''},
              {name: 'File Server', icon: '', link: ''},
            ],
            components: [],
            access: [],
            apiData: [],
          },
          {
            link: '/servers/database-server',
            icon: "fa-regular fa-chalkboard-user",
            title: "Database Server",
            new: false,
            displayOnNav: true,
            collapsed: true,
            redirection: { value: false, url: '' },
            staticPage: { value: true, component: <ComingSoon />, props: {} },
            breadcrumb: [
              {name: 'Managed Services', icon: '', link: ''},
              {name: 'Servers', icon: '', link: ''},
              {name: 'Database Server', icon: '', link: ''},
            ],
            components: [],
            access: [],
            apiData: [],
          },
          {
            link: '/servers/virtual-machines',
            icon: "fa-regular fa-chalkboard-user",
            title: "Virtual Machine",
            new: false,
            displayOnNav: true,
            collapsed: true,
            redirection: { value: false, url: '' },
            staticPage: { value: true, component: <ComingSoon />, props: {} },
            breadcrumb: [
              {name: 'Managed Services', icon: '', link: ''},
              {name: 'Servers', icon: '', link: ''},
              {name: 'Virtual Machine', icon: '', link: ''},
            ],
            components: [],
            access: [],
            apiData: [],
          },
        ]
      },
    }

    let settings = {
      '/settings/user': {
        icon: "fa-regular fa-user",
        title: "My Profile",
        new: false,
        displayOnNav: true,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component: <UserSettings />, props: {} },
        breadcrumb: [
          {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
          {name: 'Settings', icon: '', link: ''},
          {name: 'My Profile', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/settings/organization': {
        icon: "fa-regular fa-building",
        title: "My Organization",
        new: false,
        displayOnNav: true,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component: <OrganizationSettings />, props: {} },
        breadcrumb: [
          {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
          {name: 'Settings', icon: '', link: ''},
          {name: 'My Organization', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/settings/profile': {
        icon: "fa-regular fa-id-card-clip",
        title: "Profile",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: false, component: null, props: {} },
        breadcrumb: [
          {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
          {name: 'Settings', icon: '', link: ''},
          {name: 'Profile', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/users': {
        icon: "fa-regular fa-id-card-clip",
        title: "Users",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: false, component: <Users/>, props: {} },
        breadcrumb: [
          {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
          {name: 'Settings', icon: '', link: ''},
          {name: 'Profile', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/settings/license': {
        icon: "fa-regular fa-file-contract",
        title: "License",
        new: false,
        displayOnNav: true,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component: <License />, props: {} },
        breadcrumb: [
          {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
          {name: 'Settings', icon: '', link: ''},
          {name: 'License', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/notifications': {
        icon: "fa-regular fa-bell",
        title: "Notifications",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component: <Notifications />, props: {} },
        breadcrumb: [
          {name: 'Managed Services', icon: '', link: ''},
          {name: 'Notification', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/security': {
        icon: "fa-regular fa-shield-check",
        title: "Security",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component: <Security />, props: {} },
        breadcrumb: [
          {name: 'Managed Services', icon: '', link: ''},
          {name: 'Security', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/settings': {
        icon: "fa-regular fa-user",
        title: "Settings",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component: <Settings />, props: {} },
        breadcrumb: [
          {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
          {name: 'Settings', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/test/template': {
        icon: "fa-regular fa-user",
        title: "Settings",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component: <PageTemplate />, props: {} },
        breadcrumb: [
          {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
          {name: 'Settings', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/partnerdashboard': {
        icon: "fa-regular fa-bell",
        title: "Dashboard",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component: <Partnerdashboard/> , props: {} },
        breadcrumb: [
          {name: 'Managed Services', icon: '', link: ''},
          {name: 'Dashboard', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/Onboard': {
        icon: "fa-regular fa-bell",
        title: "Onboard Process",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component: <OnboardProcess/> , props: {} },
        breadcrumb: [
          // {name: 'Managed Services', icon: '', link: ''},
          {name: 'Onboard Process', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
    } 

    let adminTools = {
      '/chart': {
        icon: "fa-regular fa-chart-pie",
        title: "Chart",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: false, component: null, props: {} },
        breadcrumb: [
          {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
          {name: 'Chart & Misc', icon: '', link: ''},
          {name: 'Chart', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/manage/navigation': {
        icon: "fa-regular fa-sitemap",
        title: "Navigations",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component: <MenuManagement />, props: {} },
        breadcrumb: [
          {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
          {name: 'Chart & Misc', icon: '', link: ''},
          {name: 'Library', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      },
      '/library': {
        icon: "fa-regular fa-books",
        title: "Component Library",
        new: false,
        displayOnNav: false,
        collapsed: true,
        redirection: { value: false, url: '' },
        staticPage: { value: true, component: <Library />, props: {} },
        breadcrumb: [
          {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
          {name: 'Admin Tools', icon: '', link: ''},
          {name: 'Component Library', icon: '', link: ''},
        ],
        components: [],
        access: [],
        apiData: ['user', 'admin'],
        pages: []
      }
    }

    const isItemInArray = (arr:string[], item:string) => {
      return arr.indexOf(item) !== -1
    }

    useEffect(()=>{
      if( ( Object.keys(licenseInfo).length < 0 ) && ( globalContext?.userData?.token === null || globalContext?.userData?.token === "" || globalContext?.userData?.token === undefined) ) {
        return;
      }

      // get Access control by decoding user's JWT token
      let decodedToken = parseJwt(globalContext?.userData?.token)

      // collect permissions
      let superZeeq: boolean = isItemInArray(decodedToken?.permissions || [], 'super:zeeq');
      let notificationZeeq: boolean = isItemInArray(decodedToken?.permissions || [], 'notification:zeeq');
      let securityZeeq: boolean = isItemInArray(decodedToken?.permissions || [], 'security:zeeq');

      // update objects for permissions
      unGrouped['/manage/license']['displayOnNav'] = superZeeq;
      unGrouped['/manage/orders']['displayOnNav'] = superZeeq;
      settings['/notifications']['displayOnNav'] = notificationZeeq;
      settings['/security']['displayOnNav'] = securityZeeq;

      // collect product access as per license
      let productAccess = {...licenseInfo?.license?.license_data?.product_access}
      let sortedProductAccess = {}

      let i = 0; 
      for (let name of Object.keys(productAccess)) {
        if(productAccess[name].priority === i) {
          sortedProductAccess[dict[name]]= allManagedProducts[dict[name]]
        }
        i++
      }

      // setup side navigation items
      setPageElements({
        'ungrouped': unGrouped,
        // 'ZeeQ Admin Tools': zeeqAdminTools,
        'Managed Services': sortedProductAccess,
        'Settings': settings,
        // 'Admin Tools': adminTools,
      })

    },[licenseInfo, globalContext])

    return (
      <CustomizationContext.Provider value={{pageElements, setPageElements}}>
        {props.children}
      </CustomizationContext.Provider>
    )
}

export default CustomizationProvider;