import React, { FC } from "react";

interface IGlobalFileExtension {
    policyData: any;
}

const GlobalFileExtension:FC<IGlobalFileExtension> = ({policyData}) => {
    return (
        <>
        {console.log({policyData: policyData})}
            <div className="tile relative rounded-lg shadow-md w-full h-full bg-white col-span-6 overflow-hidden">
                <div className="flex flex-row w-full p-4 border-b">
                    <div className="title text-base md:text-lg lg:text-xl text-gray-800 font-semibold">
                        Global File Extension
                    </div>
                </div>
                <div className="w-full min-h-full">
                    <section className="w-full border-b">
                        <div className="title border-b px-4 py-2 bg-slate-300 text-orange-500 font-medium bg-opacity-20">
                            Protected under policy
                        </div>
                        <div className="px-4 py-2 font-light">                                    
                            {
                                (policyData?.category?.INCL || []).length > 0 ?
                                (policyData?.category?.INCL || [])
                                    .filter((a:any,b:number)=>{ return a[b] === undefined })
                                    .reduce((a:any,b:any)=>{ return a = [...a, ...b]},[])
                                    .map((ext:string, extIndex: number)=>{
                                        return <div key={extIndex} className="inline-block px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 mb-1 text-xs font-medium">{ext}</div>
                                    })
                                : (
                                    ( policyData?.profile?.RuleArray?.RulePredef?.FilterRuleID === 1 && Object.keys( policyData?.profile?.RuleArray || {}).length > 0) ? 
                                    <div className="inline-block px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 mb-1 text-xs font-medium">All Extensions</div> : 
                                    'None'
                                )
                            }
                        </div>
                    </section>
                    <section className="w-full">
                        <div className="title border-b px-4 py-2 bg-slate-300 text-orange-500 font-medium bg-opacity-20">
                            Excluded under policy
                        </div>
                        <div className="px-4 py-2 font-light">
                            {
                                (policyData?.category?.EXCL || []).length > 0 ?
                                (policyData?.category?.EXCL || [])
                                    .filter((a:any,b:number)=>{ return a[b] === undefined })
                                    .reduce((a:any,b:any)=>{ return a = [...a, ...b]},[])
                                    .map((ext:string, extIndex: number)=>{
                                        return <div key={extIndex} className="inline-block px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 mb-1 text-xs font-medium">{ext}</div>
                                    })
                                : 'None'
                            }
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default GlobalFileExtension;