import { Dispatch, Fragment, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';
import { getAPICall } from '../../../services/APILayer/axiosMethodCalls';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

type ModalProps = {
  globalSearchVisibility: boolean
  setGlobalSearchVisibility: Dispatch<SetStateAction<boolean>>
};

const GlobalSearch = ({ globalSearchVisibility, setGlobalSearchVisibility }: ModalProps) => {

  const navigator = useNavigate()
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [rawEntity, setRawEntity] = useState<any>({});
  const [allSearchingData, setAllSearchingData] = useState<any[]>([
    { searchIn: "Dashboard", text: 'Dashboard', link: "/dashboard", icon: <em className='w-6 fa-regular fa-arrow-up-right mr-4'></em>, type: "Navigation"},
    { searchIn: "Endpoint Devices", text: 'Endpoint Devices', link: "/endpoint/devices", icon: <em className='w-6 fa-regular fa-arrow-up-right mr-4'></em>, type: "Navigation"},
    { searchIn: "Endpoint Audit Trail", text: 'Endpoint Audit Trail', link: "/endpoint/audit/search", icon: <em className='w-6 fa-regular fa-arrow-up-right mr-4'></em>, type: "Navigation"},
    { searchIn: "MS 365", text: 'MS 365', link: "/microsoft-365", icon: <em className='w-6 fa-regular fa-arrow-up-right mr-4'></em>, type: "Navigation"},
    { searchIn: "My Profile", text: 'My Profile', link: "/settings/user", icon: <em className='w-6 fa-regular fa-arrow-up-right mr-4'></em>, type: "Navigation"},
    { searchIn: "My Organization", text: 'My Organization', link: "/settings/organization", icon: <em className='w-6 fa-regular fa-arrow-up-right mr-4'></em>, type: "Navigation"},
    { searchIn: "License", text: 'License', link: "/settings/license", icon: <em className='w-6 fa-regular fa-arrow-up-right mr-4'></em>, type: "Navigation"},

    // { searchIn: "Search Documents", text: 'Search Documents', link: "/settings/license", icon: <em className='w-6 fa-regular fa-file mr-4'></em>, type: "Help"},
    // { searchIn: "Legal", text: 'Legal', link: "/settings/license", icon: <em className='w-6 fa-regular fa-scale-unbalanced mr-4'></em>, type: "Help"},
    // { searchIn: "Send Feedback", text: 'Send Feedback', link: "/settings/license", icon: <em className='w-6 fa-regular fa-comment mr-4'></em>, type: "Help"},
    // { searchIn: "Contact Support", text: 'Contact Support', link: "/settings/license", icon: <em className='w-6 fa-regular fa-envelope mr-4'></em>, type: "Help"},

    // { searchIn: "Copy Company ID", text: 'Copy Company ID', data: rawEntity?.["https://zeeq.app/meta"]?.hubspot_company_id || '', icon: <em className='w-6 fa-regular fa-copy mr-4'></em>, type: "Quick Copy"},
    { searchIn: "Copy Token", text: 'Copy Token', data: localStorage.getItem('token'), icon: <em className='w-6 fa-regular fa-copy mr-4'></em>, type: "Quick Copy"},


    // { searchIn: "General Settings", text: 'General Settings', link: "", icon: <em className='w-6 fa-regular fa-gear mr-4'></em>, type: "General"},
    // { searchIn: "Switch User Scope", text: 'Switch User Scope', link: "", icon: <em className='w-6 fa-regular fa-user-group mr-4'></em>, type: "General"},
    // { searchIn: "Change Theme", text: 'Change Theme', link: "", icon: <em className='w-6 fa-regular fa-ferris-wheel mr-4'></em>, type: "General"},
    { searchIn: "Logout", text: 'Logout', link: "/logout", icon: <em className='w-6 fa-regular fa-right-from-bracket mr-4'></em>, type: "General"},
  ])

  const fetchEntities = async () => {
    try {
        const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/entities`);
        return response.data;
    } catch(error) {
        return error;
    }
  }

  useEffect(()=>{
    if( Object.keys(rawEntity).length > 0 ) { return }

    const getEntities = async () => {
      let response = await fetchEntities()
      // console.log(response)
      setRawEntity(response)
    }

    getEntities()

  },[rawEntity])

  useEffect(()=>{
    if( Object.keys(rawEntity).length < 1 ) { return }

    let endpoints = rawEntity?.entities.filter((ep:any)=>{ return ep.type === 'backup_endpoint_lina' }).map((ep:any)=>{
      return { searchIn: `${ep?.name || ''} ${(ep?.tags || []).join(' ') || ''} ${ep?.metadata?.agent_id || ''}`, text: `${ep?.name} (${ep?.metadata?.agent_id || ''})`, link: `/endpoint/devices/profile/${ep?.metadata?._id || ''}`, icon: <em className='w-6 fa-regular fa-tv mr-4'></em>, type: "Lina Endpoint"}
    });

    let ms365 = rawEntity?.entities.filter((ep:any)=>{ return ep.type === 'backup_ms365_user_calendar_tina' }).map((ep:any)=>{
      return { searchIn: `${ep?.name || ''} ${(ep?.tags || []).join(' ') || ''} ${ep?.metadata?.mail || ''}`, text: `${ep?.name || ''} (${ep?.metadata?.mail || ''})`, link: "/microsoft-365/Exchange", icon: <em className='w-6 fa-regular fa-brands fa-windows mr-4'></em>, type: "MS360"}
    });

    if ( allSearchingData.filter((s:any)=>{ return s.text === endpoints[0].text }).length < 1 ) {
      setAllSearchingData([
        ...allSearchingData,
        ...endpoints,
        ...ms365,
        { searchIn: "Copy Company ID", text: 'Copy Company ID', data: rawEntity?.["https://zeeq.app/meta"]?.hubspot_company_id || '', icon: <em className='w-6 fa-regular fa-copy mr-4'></em>, type: "Quick Copy"}
      ])
    }


    // console.log({endpoints,ms365})

  },[rawEntity])

  // console.log({allSearchingData})


  return (
    <>
      {globalSearchVisibility && (
        <div className="fixed inset-0 overflow-y-auto z-[999999]"
          onClick={()=>{
            setGlobalSearchVisibility(false)
          }}
        >
          <div className="flex items-center justify-center min-h-screen backdrop-blur-sm bg-black/20">
            <div className="bg-white rounded-lg shadow-lg w-[480px] md:w-[800px]"
              onClick={(e)=>{
                e.stopPropagation()
              }}
            >
              <div className="">
                <div className="flex flex-col rounded-t-lg shadow-lg h-[600px]">
                    <input 
                      type="text"
                      className="px-2 py-3 mt-4 text-2xl outline-offset-0 outline-none border-b mx-2 text-center"
                      placeholder="What do you need ?"
                      value={searchTerm || ''}
                      onChange={(e:any)=>{
                        setSearchTerm(e?.target?.value || '')
                      }}
                    />
                    <div className="customScroll overflow-y-scroll my-4">
                        { allSearchingData
                            .filter((sd:any)=>{ return sd.type === 'Lina Endpoint' && sd.searchIn.toLowerCase()
                            .includes( searchTerm.toLowerCase() ) }).length > 0 && <>
                                <div className="flex flex-col mb-4">
                                    <div className="flex items-center text-2xl font-medium text-[#ff5e0e] pl-4 my-2">
                                        <div className='whitespace-nowrap'>Lina Endpoint</div>
                                        <div className="h-[2px] grow w-full border mx-4 bg-orange-400"></div>
                                    </div>
                                    <div className="flex flex-col gap-y-1 px-8 font-medium text-xl text-slate-600">
                                        { allSearchingData
                                            .filter((sd:any)=>{ return sd.type === 'Lina Endpoint' && sd.searchIn.toLowerCase()
                                            .includes( searchTerm.toLowerCase() ) })
                                            .slice(0,5)
                                            .map((st:any, stIndex:number)=>{
                                              return (
                                                  <div key={stIndex} className="hover:bg-slate-200 px-4 py-1.5 cursor-pointer rounded-md"
                                                    onClick={()=>{
                                                      navigator(st.link)
                                                      setGlobalSearchVisibility(false)
                                                    }}
                                                  >
                                                    {st.icon} {st.text}
                                                  </div>
                                              )
                                            })
                                        }
                                        {/* <div className="hover:bg-slate-200 px-4 py-1.5 cursor-pointer rounded-md">
                                            <em className='fa-solid fa-plus'></em> Add Device
                                        </div> */}
                                    </div>
                                </div>
                            </> 
                        }

                        { allSearchingData
                          .filter((sd:any)=>{ return sd.type === 'MS360' && sd.searchIn.toLowerCase().includes( searchTerm.toLowerCase() ) }).length > 0 && <>
                              <div className="flex flex-col mb-4">
                                  <div className="flex items-center text-2xl font-medium text-[#ff5e0e] pl-4 my-2">
                                      <div className='whitespace-nowrap'>MS 360</div>
                                      <div className="h-[2px] grow w-full border mx-4 bg-orange-400"></div>
                                  </div>
                                  <div className="flex flex-col gap-y-1 px-8 font-medium text-xl text-slate-600">
                                      { allSearchingData
                                          .filter((sd:any)=>{ return sd.type === 'MS360' && sd.searchIn.toLowerCase().includes( searchTerm.toLowerCase() ) })
                                          .slice(0,5)
                                          .map((st:any, stIndex:number)=>{
                                            return (
                                                <div key={stIndex} className="hover:bg-slate-200 px-4 py-1.5 cursor-pointer rounded-md"
                                                    onClick={()=>{
                                                      navigator(st.link)
                                                      setGlobalSearchVisibility(false)
                                                    }}
                                                >
                                                  {st.icon} {st.text}
                                                </div>
                                            )
                                          })
                                      }
                                  </div>
                              </div>
                          </>
                        }

                        { allSearchingData
                            .filter((sd:any)=>{ return sd.type === 'Navigation' && sd.searchIn.toLowerCase()
                            .includes( searchTerm.toLowerCase() ) }).length > 0 && <>
                                <div className="flex flex-col mb-4">
                                    <div className="flex items-center text-2xl font-medium text-[#ff5e0e] pl-4 my-2">
                                        <div className='whitespace-nowrap'>Navigation</div>
                                        <div className="h-[2px] grow w-full border mx-4 bg-orange-400"></div>
                                    </div>
                                    <div className="flex flex-col gap-y-1 px-8 font-medium text-xl text-slate-600">
                                        { allSearchingData
                                            .filter((sd:any)=>{ return sd.type === 'Navigation' && sd.searchIn.toLowerCase()
                                            .includes( searchTerm.toLowerCase() ) })
                                            .map((st:any, stIndex:number)=>{
                                              return (
                                                  <div key={stIndex} className="hover:bg-slate-200 px-4 py-1.5 cursor-pointer rounded-md"
                                                      onClick={()=>{
                                                        navigator(st.link)
                                                        setGlobalSearchVisibility(false)
                                                      }}
                                                  >
                                                    {st.icon} {st.text}
                                                  </div>
                                              )
                                            })
                                        }
                                    </div>
                                </div>
                            </> 
                        }
                        { allSearchingData
                            .filter((sd:any)=>{ return sd.type === 'Help' && sd.searchIn.toLowerCase()
                            .includes( searchTerm.toLowerCase() ) }).length > 0 && <>
                                <div className="flex flex-col mb-4">
                                    <div className="flex items-center text-2xl font-medium text-[#ff5e0e] pl-4 my-2">
                                        <div className='whitespace-nowrap'>Help</div>
                                        <div className="h-[2px] grow w-full border mx-4 bg-orange-400"></div>
                                    </div>
                                    <div className="flex flex-col gap-y-1 px-8 font-medium text-xl text-slate-600">
                                        { allSearchingData
                                            .filter((sd:any)=>{ return sd.type === 'Help' && sd.searchIn.toLowerCase()
                                            .includes( searchTerm.toLowerCase() ) })
                                            .map((st:any, stIndex:number)=>{
                                              return (
                                                  <div key={stIndex} className="hover:bg-slate-200 px-4 py-1.5 cursor-pointer rounded-md">
                                                    {st.icon} {st.text}
                                                  </div>
                                              )
                                            })
                                        }
                                    </div>
                                </div>
                            </> 
                        }
                        { allSearchingData
                            .filter((sd:any)=>{ return sd.type === 'Quick Copy' && sd.searchIn.toLowerCase()
                            .includes( searchTerm.toLowerCase() ) }).length > 0 && <>

                              <div className="flex flex-col mb-4">
                                  <div className="flex items-center text-2xl font-medium text-[#ff5e0e] pl-4 my-2">
                                      <div className='whitespace-nowrap'>Quick Copy</div>
                                      <div className="h-[2px] grow w-full border mx-4 bg-orange-400"></div>
                                  </div>
                                  <div className="flex flex-col gap-y-1 px-8 font-medium text-xl text-slate-600">
                                      { allSearchingData
                                          .filter((sd:any)=>{ return sd.type === 'Quick Copy' && sd.searchIn.toLowerCase()
                                          .includes( searchTerm.toLowerCase() ) })
                                          .map((st:any, stIndex:number)=>{
                                            return (
                                                <div key={stIndex} className="hover:bg-slate-200 px-4 py-1.5 cursor-pointer rounded-md"
                                                  onClick={()=>{
                                                    console.log(st.data)
                                                    // navigator.clipboard.writeText(st.data)
                                                    toast.success('Copied to clipboard')
                                                  }}
                                                >
                                                  {st.icon} {st.text}
                                                </div>
                                            )
                                          })
                                      }
                                      {/* <div className="hover:bg-slate-200 px-4 py-1.5 cursor-pointer rounded-md"><i className="w-6 fa-regular fa-copy mr-4"/>Copy Zeeq ID</div>
                                      <div className="hover:bg-slate-200 px-4 py-1.5 cursor-pointer rounded-md"><i className="w-6 fa-duotone fa-copy mr-4"/>Copy Token</div> */}
                                  </div>
                              </div>
                            </>
                        }
                        
                        { allSearchingData
                            .filter((sd:any)=>{ return sd.type === 'General' && sd.searchIn.toLowerCase()
                            .includes( searchTerm.toLowerCase() ) }).length > 0 && <>
                            
                                <div className="flex flex-col mb-4">
                                    <div className="flex items-center text-2xl font-medium text-[#ff5e0e] pl-4 my-2">
                                        <div className='whitespace-nowrap'>General</div>
                                        <div className="h-[2px] grow w-full border mx-4 bg-orange-400"></div>
                                    </div>
                                    <div className="flex flex-col gap-y-1 px-8 font-medium text-xl text-slate-600">
                                      { allSearchingData
                                          .filter((sd:any)=>{ return sd.type === 'General' && sd.searchIn.toLowerCase()
                                          .includes( searchTerm.toLowerCase() ) })
                                          .map((st:any, stIndex:number)=>{
                                            return (
                                                <div key={stIndex} className="hover:bg-slate-200 px-4 py-1.5 cursor-pointer rounded-md">
                                                  {st.icon} {st.text}
                                                </div>
                                            )
                                      }) }
                                        {/* <div className="hover:bg-slate-200 px-4 py-1.5 cursor-pointer rounded-md"><i className="w-6 fa-regular fa-gear mr-4"/>Search setting</div>
                                        <div className="hover:bg-slate-200 px-4 py-1.5 cursor-pointer rounded-md"><i className="w-6 fa-regular fa-user-group mr-4"/>Switch User/scope</div>
                                        <div className="hover:bg-slate-200 px-4 py-1.5 cursor-pointer rounded-md"><i className="w-6 fa-regular fa-ferris-wheel mr-4"/>Change Theme</div>
                                        <div className="hover:bg-slate-200 px-4 py-1.5 cursor-pointer rounded-md"><i className="w-6 fa-regular fa-right-from-bracket mr-4"/>Log Out</div> */}
                                    </div>
                                </div>
                            </>
                        }
                                  
                                  
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GlobalSearch
