/* eslint-disable react/jsx-pascal-case */
import React, { useContext, useState, useLayoutEffect, useEffect } from "react";
// import { Tile } from "../components/core";
import { ThemeContext } from "../contexts";
import {
    ActiveMigrationCard,
    ActivityCard,
    AdditionalStorageCard,
    DummyLiveActivityCard,
    DummyDeviceStatusCard,
    DummyStatsCard,
    EndpointDeviceStatusCard,
    // FileServerCard,
    LicenseStatusCard,
    LiveActivityCard,
    // NotificationsCard,
    // PolicyCard,
    ProtectedDatabaseCard,
    ProtectedMailboxCard,
    ProtectedVMsCard,
    StatsCard,
    // StrategyCard,
    TicketStatusCard,
    DummyCard,
} from "./DashboardComponents"
import { getAPICall } from "../services/APILayer/axiosMethodCalls";

/**
 * bg-orange-light-tile-background bg-orange-dark-tile-background
 * bg-blue-light-tile-background bg-blue-dark-tile-background
 * @returns 
 */
const Dashboard = () => {

    const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    // const [open,setOpen]=useState<boolean>(false);
    const [pageSize, setPageSize] = useState<number[]>([0, 0]);
    const [responsiveBreakPoint, setBreakPoint] = useState<string>('sm');
    const [donutCutout, setDonutCutout] = useState<number>(50);

    const [licenseFetched, setLicenseFetched] = useState<boolean>(false);
    const [licenseInfo, setLicenseInfo] = useState<any>({});
    const [productAccess, setProductAccess] = useState<any>();



    const getLicenseInfo = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/app/licenses/me`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( Object.keys(licenseInfo).length > 0 ) {
            return;
        }
        const fetchLicenseInfo = async () => {
            await getLicenseInfo()
                .then((license)=>{
                    setLicenseInfo(license);
                    setLicenseFetched(true);
                })
                .catch(err=>console.log(err));
        }
        setTimeout(() => {
            fetchLicenseInfo(); 
        }, 500);
    },[licenseInfo])

    useEffect(()=>{
        if( Object.keys(licenseInfo).length < 1 ) {
            return;
        } 
        setProductAccess(licenseInfo?.license?.license_data?.product_access || null)
    },[licenseInfo])

    useLayoutEffect(() => {
        function updateSize() {
            setPageSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(()=>{
        if(pageSize[0] <= 640) {
            setBreakPoint('sm')
        }
        if(pageSize[0] > 640 && pageSize[0] <= 768) {
            setBreakPoint('md')
        }
        if(pageSize[0] > 768 && pageSize[0]  <= 1024) {
            setBreakPoint('lg')
        }
        if(pageSize[0] > 1024 && pageSize[0]  <= 1280) {
            setBreakPoint('xl')
        }
        if(pageSize[0] > 1280) {
            setBreakPoint('2xl')
        }
    },[pageSize]);

    useEffect(()=>{
        if(responsiveBreakPoint === 'sm') {
            setDonutCutout(80);
        }
        if(responsiveBreakPoint === 'md') {
            setDonutCutout(50);
        }
        if(responsiveBreakPoint === 'lg') {
            setDonutCutout(80);
        }
        if(responsiveBreakPoint === 'xl') {
            setDonutCutout(55);
        }
        if(responsiveBreakPoint === '2xl') {
            setDonutCutout(80);
        }
    },[responsiveBreakPoint])

    // const openHandler = ()=>{
    //     return(
    //         setOpen(!open)
    //     )
    // }
    
    return (
        <>
            <div className={`p-6 grow flex flex-col`}>
                <div className={`page grid grid-cols-12 gap-6 w-full min-h-max text-${theme.setThemeColor}-${theme.themeContrast}-component-text`}>
                
                    {/* <NotificationsCard /> */}
                    {( productAccess?.endpoints?.enabled === true ) ? <StatsCard /> : ( licenseFetched === true ? <DummyStatsCard /> : <></>) }
                    <TicketStatusCard donutCutout={donutCutout} />
                    {( productAccess?.endpoints?.enabled === true ) ? <EndpointDeviceStatusCard donutCutout={donutCutout} /> : ( licenseFetched === true ? <DummyDeviceStatusCard title='Endpoint' /> : <></>) }
                    {/* <FileServerCard donutCutout={donutCutout} /> */}
                    {( productAccess?.endpoints?.enabled === true ) ? <LiveActivityCard /> : ( licenseFetched === true ? <DummyLiveActivityCard /> : <></>) }

                    <LicenseStatusCard licenseInfo={licenseInfo} />
                    {/* <PolicyCard /> */}
                    {/* <StrategyCard /> */}
                    { (productAccess?.ms365?.enabled === true ) ? <ProtectedMailboxCard /> : ( licenseFetched === true ? <DummyCard title="Protected Mail Box" icon="fa-duotone fa-envelopes-bulk" /> : <></> )}
                    {( productAccess?.endpoints?.enabled === true ) ? <AdditionalStorageCard /> : ( licenseFetched === true ? <DummyCard title="Addition Storage" icon="fa-duotone fa-database" /> : <></> ) }
                    {/* { (productAccess?.servers?.enabled === true ) ?  <AdditionalStorageCard /> : ( licenseFetched === true ? <DummyCard title="Additional Storage" icon="fa-duotone fa-database" /> : <></> )} */}
                    
                    { (productAccess?.servers?.enabled === true ) ? <ActiveMigrationCard /> : ( licenseFetched === true ? <DummyCard title="Active Migration" icon="fa-duotone fa-arrow-right-arrow-left" /> : <></> )}
                    { (productAccess?.servers?.enabled === true ) ? <ProtectedVMsCard /> : ( licenseFetched === true ? <DummyCard title="Protected VMs" icon="fa-duotone fa-server" /> : <></> )}
                    { (productAccess?.servers?.enabled === true ) ? <ProtectedDatabaseCard /> : ( licenseFetched === true ? <DummyCard title="Protected Database" icon="fa-duotone fa-database" /> : <></> )}

                    { (productAccess?.endpoints?.enabled === true ) && <ActivityCard />}
              </div>
            </div>
        </>
    )
}

export default Dashboard;
