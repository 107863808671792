import React, { Dispatch, SetStateAction, ReactNode, useState, useEffect } from "react";

interface IMailingListContext {
    allMailingList: any[];
    setAllMailingList: Dispatch<SetStateAction<any[]>>;
    selectedMailingList: any;
    setSelectedMailingList: Dispatch<SetStateAction<any>>;
    selectedMailingListIndex: number;
    setSelectedMailingListIndex: Dispatch<SetStateAction<number>>;
    allMailingUsers: any[];
    setAllMailingUsers: Dispatch<SetStateAction<any[]>>;
    includedMailingUsers: any[];
    setIncludedMailingUsers: Dispatch<SetStateAction<any[]>>;
    excludedMailingUsers: any[];
    setExcludedMailingUsers: Dispatch<SetStateAction<any[]>>;
    deletedMailingUsers: any[];
    setDeletedMailingUsers: Dispatch<SetStateAction<any[]>>;
}

export const MailingListContext = React.createContext({} as IMailingListContext);

interface IProp {
    children: ReactNode | ReactNode[];
}

const MailingListProvider = (props: IProp) => {

    const [allMailingList, setAllMailingList] = useState<any[]>([]);
    const [selectedMailingList, setSelectedMailingList] = useState<any>({});
    const [selectedMailingListIndex, setSelectedMailingListIndex] = useState<number>(-1);
    const [allMailingUsers, setAllMailingUsers] = useState<any[]>([]);
    const [includedMailingUsers, setIncludedMailingUsers] = useState<any[]>([]);
    const [excludedMailingUsers, setExcludedMailingUsers] = useState<any[]>([]);
    const [deletedMailingUsers, setDeletedMailingUsers] = useState<any[]>([]);


    useEffect(()=>{
        if( allMailingList.length < 1 ) { return }
        if( Object.keys(selectedMailingList).length < 1 ) { setExcludedMailingUsers([]);  return }

        let arrAllUsers:string[] = []
        let validMailingLists = ( allMailingList || [] ).filter((ml:any)=>{ return ml.name !== 'Deleted Users' && ml.status === true })
        let validDeletedMailingLists = ( allMailingList || [] ).filter((ml:any)=>{ return ml.name === 'Deleted Users' }).length ? ( allMailingList || [] ).find((ml:any)=>{ return ml.name === 'Deleted Users' }).users : []

        for (let i = 0; i < validMailingLists.length; i++) {
            const ml = validMailingLists[i];
            arrAllUsers = [...arrAllUsers, ...ml.users]
        }

        let arrUniqueAllUsers = arrAllUsers.filter(function(item, pos) {
            return arrAllUsers.indexOf(item) === pos;
        })

        setAllMailingUsers(arrUniqueAllUsers)
        setIncludedMailingUsers(()=>{return [...selectedMailingList?.users]})
        setDeletedMailingUsers(validDeletedMailingLists)

    },[selectedMailingList, allMailingList])

    useEffect(()=>{
        let nonIncludedUsers = removeItemsFromOneArrayPresentInAnother(allMailingUsers, includedMailingUsers)
        setExcludedMailingUsers(()=>{return [...nonIncludedUsers]})
    }, [allMailingList, includedMailingUsers])

    const removeItemsFromOneArrayPresentInAnother = (arrAllItems:string[], arrItemsToRemove:string[]) => {
        let arrNew: string[] = [];

        for (let index = 0; index < arrAllItems.length; index++) {
            const item = arrAllItems[index];
            if( !arrItemsToRemove.includes(item) ) {
                arrNew.push(item)
            }
        }

        return arrNew;
    }

    return (
      <MailingListContext.Provider value={{
            allMailingList,
            setAllMailingList,
            selectedMailingList,
            setSelectedMailingList,
            selectedMailingListIndex,
            setSelectedMailingListIndex,
            allMailingUsers,
            setAllMailingUsers,
            includedMailingUsers,
            setIncludedMailingUsers,
            excludedMailingUsers,
            setExcludedMailingUsers,
            deletedMailingUsers,
            setDeletedMailingUsers,
        }}>
        {props.children}
      </MailingListContext.Provider>
    )
}

export default MailingListProvider;