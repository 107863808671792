/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useRef, ComponentProps, useState, useEffect, RefObject } from "react";
import Tooltip from "../../Tooltip/Tooltip";
import OutsideClickHandler from 'react-outside-click-handler'


interface Props extends ComponentProps<"div">  {
    label?: string;
    placeholder?: string;
    required?: boolean;
    allOptionSelector?: boolean;
    tooltipText?: string;
    /**
     * Will it be a multi-selector layout
     */
    multiple?: boolean;
    options: string[];
    /**
     * Child node(s) of dropdown
     */
    children?: string;
    /**
     * Additional class values goes here
     */
    className?: string;
    visibleOptions?: number;
    onChange?: (e:any)=>void;
    value?: string | string[];
    defaultValue?: string | string[];
    id?: string;
    minWidth?: string;
    clearTrigger?: boolean;
}

interface IDropdownItem {
    value: string;
    selected: boolean;
}

/**
 * Dropdown Component
 * @param param0 
 * @returns 
 */
const Dropdown:FC<Props> = ({
    label='',
    placeholder='',
    multiple=false,
    required=false,
    allOptionSelector=false,
    tooltipText='',
    options,
    className='',
    children='',
    visibleOptions=2,
    onChange,
    value=multiple === true ? [] : '',
    defaultValue=multiple === true ? [] : '',
    id='',
    minWidth='min-w-[200px]',
    clearTrigger,
    ...rest
}: Props) => {

    // const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};

    const refDropdownList = useRef(null)

    const [dropdownVisibilityState, setDropdownVisibilityState] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [allOptions, setAllOptions] = useState<string[]>(options);
    // const [openDropdownList, setOpenDropdownList] = useState<boolean>(false);
    const [dropdownList, setDropdownList] = useState<IDropdownItem[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<string[]>((defaultValue.length > 0 && multiple === true) ? defaultValue as string[] : []);
    const [selectedValue, setSelectedValue] = useState<string>((defaultValue.length > 0 && multiple === false) ? defaultValue as string : '');
    const [numberOfVisibleOptions, setNumberOfVisibleOptions] = useState<number>(visibleOptions);

    useEffect(()=>{
        // console.log({options})
        setAllOptions(options)
    },[options])

    useEffect(()=>{
        setDefaultOptions();
    },[allOptions]);

    // useEffect(()=>{
    //     if( multiple === true && Array.isArray(value) === true && value.length > 0 ) {
    //         // if value is set as multiple values i.e. array
    //         setSelectedOptions(defaultValue as string[]);
    //         // if(onChange) onChange({target: {value: selectedOptions}})
    //     } else if( multiple === false && typeof value === 'string' && value.length > 0 ) {
    //         // if value is set as single value i.e. string
    //         setSelectedValue(defaultValue as string);
    //         // if(onChange) onChange({target: {value: selectedValue}})
    //     }
    // },[defaultValue])

    const addItem = (item:any) => {
        if(multiple === true) {
            setSelectedOptions((prevState:any)=>{
                let newState:any = []
                if(selectedOptions.includes(item.value)) {
                    let newArr = [...selectedOptions];
                    let index = newArr.indexOf( item.value )
                    newArr.splice(index, 1);
                    newState = [...newArr];
                } else {
                    newState = [
                        ...selectedOptions,
                        item.value
                    ];
                }
                return newState;
            })
        } else {
            setSelectedValue(item.value) 
            if(onChange) onChange({target: {value: item.value}})
            clearSearchBox();
            setDropdownVisibilityState(false);
        }
    }

    useEffect(()=>{
        if(onChange) onChange({target: {value: selectedOptions}})
    },[selectedOptions])

    const removeItem = (item:any) => {
        if(multiple) {
            setSelectedOptions((prevState)=>{
                let newArr = [...selectedOptions];
                let index = newArr.indexOf( item );
                newArr.splice(index, 1);
                return newArr;
            });
        } else {
            
        }
    }

    const handleSearch = (str:any) => {
        setSearchTerm(str);

        if(str === "") {
            setDefaultOptions();
            return;
        }

        setDropdownList((prevState)=>{
            let arrTemp = [...prevState]
            let arrFiltered = arrTemp.filter(item=>item?.value.toLowerCase().includes(str.toLowerCase()))
            return arrFiltered
        });
    }

    // const clearSelected = () => {
    //     if(multiple) {
    //         setSelectedOptions([]);
    //     } else {
    //         setSelectedValue("")
    //     }
    // }

    const setDefaultOptions = () => {
        let allOpt = [];
        for (let i = 0; i < allOptions.length; i++) {
            allOpt.push({value: allOptions[i], selected: false});   
        }
        setDropdownList(allOpt);
    }

    const clearSearchBox = () => {
        setSearchTerm('');
        setDefaultOptions();
    }

    const handleKeyDown = (event:any) => {
        if (event.key === 'Enter') {
          console.log('Enter')
        }
        if (event.key === 'Up') {
          console.log('Up')
        }
        if (event.key === 'Down') {
          console.log('Down')
        }
    }

    const resetDropDown = () => {
        setSelectedValue('') 
        setSelectedOptions([])
        if(onChange) onChange({target: {value: multiple ? [] : ''}})
        clearSearchBox();
        setDropdownVisibilityState(false);
    }

    useEffect(()=>{
        resetDropDown()
    },[clearTrigger])

    return (
        <>
            <OutsideClickHandler
                onOutsideClick={()=>{
                    setDropdownVisibilityState(false)
                }}
            >
            <div className="dropdown w-full mx-auto">
                { label !== '' && <label className="relative font-semibold block py-2 z-0">
                    <span 
                        onClick={()=>{ setDropdownVisibilityState((prevState)=>{return !prevState}) }}
                    >{label}</span>
                    {required && <span className="text-red-500 ml-2">*</span>}
                    {tooltipText !== '' && <span className="relative group ml-2 h-[20px] w-[20px]">
                        <em className="fa-solid fa-info-circle text-slate-500"></em>
                        <Tooltip value={tooltipText} className='ml-[122px] mt-[-65px]' />
                    </span>}

                </label>}

                <div className="relative w-full">
                    <div className="h-10 bg-white flex border border-gray-200 rounded items-center overflow-clip"
                        onClick={()=>{
                            if( dropdownVisibilityState === false ) {
                                setDropdownVisibilityState(true);
                            }
                        }}
                    >
                        {multiple === true ? (
                            <div className={`flex grow px-2 py-1 appearance-none outline-none text-gray-800 ${minWidth}`}>
                                {/* <span>
                                    <div className="flex rounded text-white bg-orange-500 hover:bg-orange-600 cursor-pointer overflow-clip justify-center items-center align-middle ml-1">
                                        <div className="px-2 py-1 text-sm  whitespace-nowrap border-r max-w-[80px] truncate">All</div>
                                        <em className="fa-regular fa-xmark text-sm bg-orange-500 hover:bg-orange-600 py-1 px-2"
                                            onClick={()=>{
                                                // removeItem(item)
                                            }}
                                        ></em>
                                    </div>
                                </span> */}
                                {selectedOptions.length > 0 && selectedOptions.map((item, itemIndex)=>(
                                    <span key={itemIndex}>
                                        {itemIndex < numberOfVisibleOptions &&
                                            <div className="flex rounded text-white bg-orange-500 hover:bg-orange-600 cursor-pointer overflow-clip justify-center items-center align-middle ml-1">
                                                <div className="px-2 py-1 text-sm  whitespace-nowrap border-r max-w-[80px] truncate">{item}</div>
                                                <em className="fa-regular fa-xmark text-sm bg-orange-500 hover:bg-orange-600 py-1 px-2"
                                                    onClick={()=>{
                                                        removeItem(item)
                                                    }}
                                                ></em>
                                            </div>
                                        }
                                    </span>
                                ))}
                                {selectedOptions.length > numberOfVisibleOptions && 
                                    <div key={selectedOptions.length} className="flex rounded text-white bg-orange-500 hover:bg-orange-600 cursor-pointer overflow-clip justify-center items-center align-middle ml-1">
                                        <div className="px-2 py-1 text-sm  whitespace-nowrap border-r max-w-[80px] truncate">{selectedOptions.length - numberOfVisibleOptions } more</div>
                                        <em className="fa-regular fa-xmark text-sm bg-orange-500 hover:bg-orange-600 py-1 px-2"
                                            onClick={()=>{
                                                // removeItem(item)
                                                setSelectedOptions((prevState)=>{
                                                    let newState = [...prevState]
                                                    newState.splice(numberOfVisibleOptions,newState.length)
                                                    return newState
                                                })
                                            }}
                                        ></em>
                                    </div>
                                }
                                {selectedOptions.length < 1 && <span className="text-gray-400 select-none ml-2">{placeholder}</span>}
                            </div>
                        ) : (
                            <input type="text" name="select" id="select" placeholder={placeholder} value={selectedValue} onChange={()=>{}} className="px-4 appearance-none outline-none text-gray-800 w-full" />
                        )}

                        <button 
                            aria-label="clear selection"
                            className="btnClear cursor-pointer outline-none focus:outline-none transition-all text-gray-300 hover:text-gray-600"
                            onClick={()=>{ 
                                setSelectedValue('') 
                                setSelectedOptions([])
                                if(onChange) onChange({target: {value: multiple ? [] : ''}})
                                clearSearchBox();
                                setDropdownVisibilityState(false);
                            }}
                        >
                            <em className="fa-regular fa-rotate w-4 h-4 mx-2 fill-current"></em>
                        </button>
                        <label htmlFor="show_more" className="cursor-pointer outline-none focus:outline-none border-l border-gray-200 transition-all text-gray-300 hover:text-gray-600"
                            onClick={()=>{ setDropdownVisibilityState((prevState)=>{return !prevState}) }}
                        >
                            <em className={`fa-solid ${dropdownVisibilityState ? 'fa-caret-up' : 'fa-caret-down'} w-4 h-4 mx-2 fill-current`}></em>
                        </label>
                    </div>
                    <input type="checkbox" name="show_more" id="show_more" className="hidden peer" checked={dropdownVisibilityState} onChange={(e)=>{ }} />
                    <div className="absolute rounded shadow bg-white overflow-hidden hidden peer-checked:flex flex-col w-full mt-1 border border-gray-200" ref={refDropdownList}>
                        <div className="searchBox relative flex w-full">
                            <input type="text" placeholder="search" value={searchTerm} className="px-2 py-1 m-1 bg-white w-full border border-gray-200 rounded items-center shadow"
                                onChange={(e)=>{
                                    handleSearch(e.target.value)
                                }}
                                onKeyDown={(e)=>{
                                    handleKeyDown(e)
                                }}
                            ></input>
                            <button 
                                aria-label="clear search"
                                className="btnClearSearch absolute right-2 top-2 cursor-pointer outline-none focus:outline-none transition-all text-gray-300 hover:text-gray-600"
                                onClick={()=>{ clearSearchBox() }}
                            >
                                <em className="fa-regular fa-xmark w-4 h-4 mx-2 fill-current"></em>
                            </button>
                        </div>
                        {/* <div className="max-h-40 overflow-y-scroll customScroll"> */}
                        <div>
                            {multiple === true &&  <div className={`border-y cursor-pointer group`}
                                onClick={(e)=>{
                                    if(selectedOptions.length > 1) {
                                        setSelectedOptions([])
                                    } else {
                                        setSelectedOptions([...options])
                                    }
                                }}
                            >
                                <span className={`flex p-2 border-transparent border-l-4 group-hover:border-orange-600  group-hover:bg-gray-100`}>
                                    <div className="grow">&mdash; {selectedOptions.length > 0 ? 'Deselect' : 'Select'} All &mdash;</div>
                                </span>
                            </div>}
                            <div className="max-h-40 overflow-y-scroll customScroll">
                                {dropdownList.length > 0 && dropdownList.map((item, itemIndex)=>(
                                    <div key={itemIndex} className={`border-t cursor-pointer group`}
                                        onClick={(e)=>{ 
                                            addItem(item);
                                            clearSearchBox();
                                        }}
                                    >
                                        <span className={`flex p-2 border-transparent border-l-4 group-hover:border-orange-600  group-hover:bg-gray-100`}>
                                            <div className="grow">{item.value}</div>
                                            { multiple === true && (
                                                selectedOptions.includes( item.value ) && <em className={`fa-regular fa-check items-end w-4 h-4 mr-2`}></em>
                                            )}
                                            { multiple === false && (
                                                selectedValue === item.value && <em className={`fa-regular fa-check items-end w-4 h-4 mr-2`}></em>
                                            )}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </OutsideClickHandler>
        </>
    );
}

export default Dropdown;
