import React, { Dispatch, FC, SetStateAction } from "react"

interface IProgressBar {
    selected: number;
    setSelected: Dispatch<SetStateAction<number>>;
}

const ProgressBar:FC<IProgressBar> = ({
    selected,
    setSelected
}) => {

    // status = 'past'|'present'|'future'|'partial'
    const steps = [
        {
            name: 'Validate company',
            state: selected === 1 ? 'present' : ( selected < 1 ? 'future' : ( selected > 1 ? 'past' : 'future' ) )
        },
        {
            name: 'License',
            state: selected === 2 ? 'present' : ( selected < 2 ? 'future' : ( selected > 2 ? 'past' : 'future' ) )
        },
        {
            name: 'Order',
            state: selected === 3 ? 'present' : ( selected < 3 ? 'future' : ( selected > 3 ? 'past' : 'future' ) )
        },
        {
            name: 'Export',
            state: selected === 4 ? 'present' : ( selected < 4 ? 'future' : ( selected > 4 ? 'past' : 'future' ) )
        }
    ]


    return (
        <>
            <div className="flex flex-col ">
                <div className="px-6 mb-6 flex flex-col md:flex-row">
                    { steps.map((item: any, itemIndex: number)=>(
                        <div key={itemIndex} className="relative font-medium w-full h-28 md:w-1/3 flex md:justify-center items-center md:text-center text-slate-600 flex-row md:flex-col">
                            <div className="flex flex-col md:flex-row items-center w-[20px] mr-10 md:mr-0 md:w-full">

                                {
                                    (itemIndex === 0) && (
                                        <>
                                            { (item.state === 'future') && (<>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow bg-opacity-0`}></div>
                                                <i className={`fa-duotone fa-circle-dot text-gray-300`} style={{zoom: '150%'}}></i>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow bg-gray-300`}></div>
                                            </>) }
                                            { (item.state === 'present') && (<>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow bg-opacity-0`}></div>
                                                <i className={`fa-duotone fa-circle-dot animate-pulse text-orange-500`} style={{zoom: '150%'}}></i>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow bg-gray-300`}></div>
                                            </>) }
                                            { (item.state === 'partial') && (<>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow bg-opacity-0`}></div>
                                                <i className={`fa-duotone fa-circle-dot text-orange-500`} style={{zoom: '150%'}}></i>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow bg-orange-500`}></div>
                                            </>) }
                                            { (item.state === 'past') && (<>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow bg-opacity-0`}></div>
                                                <i className={`fa-duotone fa-circle-dot text-green-500`} style={{zoom: '150%'}}></i>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow bg-orange-500`}></div>
                                            </>) }
                                        </>
                                    )
                                }

                                {
                                    (itemIndex !== 0 && steps.length !== itemIndex+1) && (
                                        <>
                                            { (item.state === 'future') && (<>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow ${(steps[itemIndex-1].state === 'future' || steps[itemIndex-1].state === 'present' || steps[itemIndex-1].state === 'partial' ) && 'bg-gray-300'} ${ steps[itemIndex-1].state === 'past' && 'bg-orange-500' }`}></div>
                                                <i className={`fa-duotone fa-circle-dot text-gray-300`} style={{zoom: '150%'}}></i>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow bg-gray-300`}></div>
                                            </>) }
                                            { (item.state === 'present') && (<>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow ${(steps[itemIndex-1].state === 'future' || steps[itemIndex-1].state === 'present' || steps[itemIndex-1].state === 'partial' ) && 'bg-gray-300'} ${ steps[itemIndex-1].state === 'past' && 'bg-orange-500' }`}></div>
                                                <i className={`fa-duotone fa-circle-dot animate-pulse text-orange-500`} style={{zoom: '150%'}}></i>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow bg-gray-300`}></div>
                                            </>) }
                                            { (item.state === 'partial') && (<>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow ${(steps[itemIndex-1].state === 'future' || steps[itemIndex-1].state === 'present' || steps[itemIndex-1].state === 'partial' ) && 'bg-gray-300'} ${ steps[itemIndex-1].state === 'past' && 'bg-orange-500' }`}></div>
                                                <i className={`fa-duotone fa-circle-dot text-orange-500`} style={{zoom: '150%'}}></i>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow bg-orange-500`}></div>
                                            </>) }
                                            { (item.state === 'past') && (<>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow ${(steps[itemIndex-1].state === 'future' || steps[itemIndex-1].state === 'present' || steps[itemIndex-1].state === 'partial' ) && 'bg-gray-300'} ${ steps[itemIndex-1].state === 'past' && 'bg-orange-500' }`}></div>
                                                <i className={`fa-duotone fa-circle-dot text-green-500`} style={{zoom: '150%'}}></i>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow bg-orange-500`}></div>
                                            </>) }
                                        </>
                                    )
                                }

                                {
                                    (steps.length === itemIndex+1) && (
                                        <>
                                            { (item.state === 'future') && (<>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow bg-gray-300`}></div>
                                                <i className={`fa-duotone fa-circle-dot text-gray-300`} style={{zoom: '150%'}}></i>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow bg-opacity-0`}></div>
                                            </>) }
                                            { (item.state === 'present') && (<>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow ${(steps[itemIndex-1].state === 'future' || steps[itemIndex-1].state === 'present' || steps[itemIndex-1].state === 'partial' ) && 'bg-gray-300'} ${ steps[itemIndex-1].state === 'past' && 'bg-orange-500' }`}></div>
                                                <i className={`fa-duotone fa-circle-dot text-green-500`} style={{zoom: '150%'}}></i>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow bg-opacity-0`}></div>
                                            </>) }
                                            { (item.state === 'partial') && (<>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow ${(steps[itemIndex-1].state === 'future' || steps[itemIndex-1].state === 'present' || steps[itemIndex-1].state === 'partial' ) && 'bg-gray-300'} ${ steps[itemIndex-1].state === 'past' && 'bg-orange-500' }`}></div>
                                                <i className={`fa-duotone fa-circle-dot text-orange-500`} style={{zoom: '150%'}}></i>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow bg-opacity-0`}></div>
                                            </>) }
                                            { (item.state === 'past') && (<>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow ${(steps[itemIndex-1].state === 'future' || steps[itemIndex-1].state === 'present' || steps[itemIndex-1].state === 'partial' ) && 'bg-gray-300'} ${ steps[itemIndex-1].state === 'past' && 'bg-orange-500' }`}></div>
                                                <i className={`fa-duotone fa-circle-dot text-green-500`} style={{zoom: '150%'}}></i>
                                                <div className={`md:min-w-[55px] h-14 w-1 md:h-1 grow bg-opacity-0`}></div>
                                            </>) }
                                        </>
                                    )
                                }
                                
                            </div>
                            <div 
                                className={`absolute mt-2 whitespace-nowrap text-lg text-orange-500 cursor-pointer h-[60px]`}
                                onClick={()=>{
                                    setSelected( itemIndex + 1 )
                                }}
                            >
                                <div className="pt-10">{item.name}</div>
                            </div>
                        </div>
                    )) }
                    
                </div>
            </div>
        </>
    )
}

export default ProgressBar