/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Button, Tile } from "../../../components/core";
import ButtonGroup from "../../../components/core/ButtonGroup/ButtonGroup";
import { formatSize } from "../../../utils/backupFileUtils";
import { dataSizeConversion, getFormattedDateTime } from "../../../utils/format";
import LineChart from "../../../components/Charts/ApacheECharts/LineChart.tsx/LineChart";
import { postAPICall } from "../../../services/APILayer/axiosMethodCalls";
// import { useParams } from "react-router-dom";

interface IBackupRestoreTrendGraph {
    trendSelection: string;
    trendPeriod: string;
    setTrendPeriod: Dispatch<SetStateAction<string>>;
    trendCallingActive: boolean;
    getBackupRestoreData: (filter: any)=>any;
    // resetGraphData: ()=>void;
    setTrendCallingActive: Dispatch<SetStateAction<boolean>>;
    setTrendSelection: Dispatch<SetStateAction<string>>;
    // graphData: any;
    // graphLive: boolean;
}

const BackupRestoreTrendGraph:FC<IBackupRestoreTrendGraph> = ({
    trendSelection,
    trendPeriod,
    setTrendPeriod,
    trendCallingActive,
    getBackupRestoreData,
    // resetGraphData,
    setTrendCallingActive,
    setTrendSelection,
    // graphData,
    // graphLive
}) => {

    const [graphData, setGraphData] = useState<{[key: string]:any}>({});

    const [dataType, setDataType] = useState<string>('backup');
    const [period, setPeriod] = useState<string>('1d');

    let today = new Date();
    const twentyFourHour = new Date(today.setDate(today.getDate() - 1));
    const fortyEightHour = new Date(today.setDate(today.getDate() - 2));
    const seventyTwoHours = new Date(today.setDate(today.getDate() - 7));

    const [periodDate, setPeriodDate] = useState<any>({
        startDate: twentyFourHour,
        endDate: twentyFourHour
    });

    const getEndpointAuditData = async (filter: any) => {
        try {
            const response = await postAPICall(
                `${process.env.REACT_APP_API_URL}/analytics/endpoints_audit_v2_report`,
                {
                    "filter":{
                        // start_date: "2023-03-01",
                        // end_date: "2023-04-15",
                        // activity_type:[ "endpoints-backup", "endpoints-restore", "endpoints-strategy-change", "endpoints-policy-change" ]
                        ...filter
                    }
                }
            );
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{

        let fetchData = async () => {

            let param: any = {
                start_date: `${(periodDate?.startDate.getFullYear())}-${('00'+(periodDate?.startDate.getMonth()+1)).slice(-2)}-${('00'+periodDate?.startDate.getDate()).slice(-2)}`,
                end_date: `${(periodDate?.endDate.getFullYear())}-${('00'+(periodDate?.endDate.getMonth()+1)).slice(-2)}-${('00'+periodDate?.endDate.getDate()).slice(-2)}`,
                activity_type: []
            }
    
            if( dataType === 'backup' ) {
                param.activity_type.push('endpoints-backup');
            } else if( dataType === 'restore' ) {
                param.activity_type.push('endpoints-restore');
            }
    
    
            await getEndpointAuditData(param).then((data)=>{
                console.log({_data:data})
                // setRawActivity(data)
            }).catch((error)=>{
                console.log({error})
            }).finally(()=>{
                // setActivityCallActive(false)
            })

        }
        
        fetchData()


    },[dataType,period])





    // useEffect(()=>{
    //     if(!trendCallingActive) {
    //         return;
    //     }
        
    //     const fetchTrendData = async () => {
    //         await getBackupRestoreData({
    //             range: trendPeriod,
    //             window: trendPeriod === '1d' ? '1h' : '1d',
    //             query: trendSelection,
    //             query_type: trendPeriod === '1d' ? 2 : 1
    //         }).then((data: any)=>{
    //             console.log({trendData:data});

    //             let chartOptions:{[key: string]:any} = {
    //                 dataset: {
    //                     source: []
    //                 },
    //                 tooltip: {
    //                     formatter: ()=>{ return '' }
    //                 },
    //                 legend: {
    //                     data: []
    //                 },
    //                 series: []
    //             }

    //             resetGraphData()

    //             if( trendSelection === 'backup' && trendPeriod === '1d' ) {
    //                 for (let i = 0; i < data?.activities.length; i++) {
    //                     if( i === 0 ) {
    //                         chartOptions.dataset.source.push(['time', 'Backup', 'Change'])
    //                     }
    //                     chartOptions.dataset.source.push([
    //                         data?.activities[i]?._time ? getFormattedDateTime(data?.activities[i]?._time) : '',
    //                         (data?.activities[i]?._original || 0).toFixed(2),
    //                         (data?.activities[i]?._value || 0).toFixed(2),
    //                     ])
    //                 }
    //                 chartOptions.tooltip.formatter = (params: any)=>{
    //                     return (
    //                         `
    //                           <div style="text-align: left">
    //                             <strong>${params[0].data[0]}</strong><br>
    //                             <i class="fa-solid fa-circle" style="font-size: 12px; color: #ff5e0e"></i> <strong>${params[0].dimensionNames[1]}:</strong> ${formatSize(dataSizeConversion(params[0].data[1],'MB','B').value).value} ${formatSize(dataSizeConversion(params[0].data[1],'MB','B').value).unit}<br>
    //                             <i class="fa-solid fa-circle" style="font-size: 12px; opacity: 0.5; color: #ff5e0e"></i> <strong>${params[0].dimensionNames[2]}:</strong> ${formatSize(dataSizeConversion(params[0].data[2],'MB','B').value).value} ${formatSize(dataSizeConversion(params[0].data[2],'MB','B').value).unit}
    //                           </div>
    //                         `
    //                       );
    //                 }
    //                 chartOptions.legend.data = ["Backup","change"]
    //                 chartOptions.series = [
    //                     {
    //                         name: "Backup",
    //                         id: 'line1',
    //                         type: "line",
    //                         stack: "stack1",
    //                         smooth: true,
    //                         symbolSize: 20,
    //                         label: {
    //                             show: true,
    //                             opacity: 1,
    //                             formatter: (val: any)=>{
    //                                 // console.log({val:dataSizeConversion(val.data[1],'MB','B').value});
    //                                 return `${formatSize(dataSizeConversion(val.data[1],'MB','B').value).value} ${formatSize(dataSizeConversion(val.data[1],'MB','B').value).unit}`
    //                             }
    //                         },
    //                         itemStyle: {
    //                             color: '#ff5e0e',
    //                             opacity: 1
    //                         },
    //                         lineStyle: {
    //                             color: '#ff5e0e',
    //                             opacity: 1,
    //                         }
    //                     },{
    //                         name: "Change",
    //                         id: 'line2',
    //                         type: "line",
    //                         stack: "stack2",
    //                         smooth: true,
    //                         symbolSize: 20,
    //                         label: {
    //                           show: true,
    //                           opacity: 1,
    //                           formatter: (val: any)=>{
    //                             // console.log({val:dataSizeConversion(val.data[1],'MB','B').value});
    //                             return `${formatSize(dataSizeConversion(val.data[2],'MB','B').value).value} ${formatSize(dataSizeConversion(val.data[2],'MB','B').value).unit}`
    //                         }
    //                         },
    //                         itemStyle: {
    //                           color: '#ff5e0e',
    //                           opacity: 0.5
    //                         },
    //                         lineStyle: {
    //                           color: '#ff5e0e',
    //                           opacity: 0.5,
    //                         }
    //                     }
    //                 ]

    //                 console.log({chartOptions})
    //             }

    //             if( trendSelection === 'backup' && trendPeriod !== '1d' ) {
    //                 for (let i = 0; i < data?.activities.length; i++) {
    //                     if( i === 0 ) {
    //                         chartOptions.dataset.source.push(['time', 'Backup'])
    //                     }
    //                     chartOptions.dataset.source.push([
    //                         getFormattedDateTime(data?.activities[i]?._start)?.split(',')[0],
    //                         data?.activities[i]?._value,
    //                     ])
    //                 }
    //                 chartOptions.tooltip.formatter = (params: any)=>{
    //                     return (
    //                         `
    //                           <div style="text-align: left">
    //                             <strong>${params[0].data[0]}</strong><br>
    //                             <i class="fa-solid fa-circle" style="font-size: 12px; color: #ff5e0e"></i> <strong>${params[0].dimensionNames[1]}:</strong> ${params[0].data[1]} MB
    //                           </div>
    //                         `
    //                       );
    //                 }
    //                 chartOptions.legend.data = ["Backup"]
    //                 chartOptions.series = [
    //                     {
    //                         name: "Backup",
    //                         id: 'line1',
    //                         type: "line",
    //                         stack: "stack1",
    //                         smooth: true,
    //                         symbolSize: 20,
    //                         label: {
    //                             show: true,
    //                             opacity: 1,
    //                             formatter: (val: any)=>{
    //                                 return `${formatSize(dataSizeConversion(val.data[1],'MB','B').value).value} ${formatSize(dataSizeConversion(val.data[1],'MB','B').value).unit}`
    //                             }
    //                         },
    //                         itemStyle: {
    //                             color: '#ff5e0e',
    //                             opacity: 1
    //                         },
    //                         lineStyle: {
    //                             color: '#ff5e0e',
    //                             opacity: 1,
    //                         }
    //                     }
    //                 ]

    //                 console.log({chartOptions})
    //             }

    //             if( trendSelection === 'restore' && trendPeriod === '1d' ) {
    //                 for (let i = 0; i < data?.activities.length; i++) {
    //                     if( i === 0 ) {
    //                         chartOptions.dataset.source.push(['time', 'Restore', 'Change'])
    //                     }
    //                     chartOptions.dataset.source.push([
    //                         getFormattedDateTime(data?.activities[i]?._time),
    //                         data?.activities[i]?._original,
    //                         data?.activities[i]?._value,
    //                     ])
    //                 }
    //                 chartOptions.tooltip.formatter = (params: any)=>{
    //                     return (
    //                         `
    //                           <div style="text-align: left">
    //                             <strong>${params[0].data[0]}</strong><br>
    //                             <i class="fa-solid fa-circle" style="font-size: 12px; color: #ff5e0e"></i> <strong>${params[0].dimensionNames[1]}:</strong> ${params[0].data[1]} MB<br>
    //                             <i class="fa-solid fa-circle" style="font-size: 12px; opacity: 0.5; color: #ff5e0e"></i> <strong>${params[0].dimensionNames[2]}:</strong> ${params[0].data[2]} MB
    //                           </div>
    //                         `
    //                       );
    //                 }
    //                 chartOptions.legend.data = ["Restore","change"]
    //                 chartOptions.series = [
    //                     {
    //                         name: "Restore",
    //                         id: 'line1',
    //                         type: "line",
    //                         stack: "stack1",
    //                         smooth: true,
    //                         symbolSize: 20,
    //                         label: {
    //                             show: true,
    //                             opacity: 1,
    //                             formatter: (val: any)=>{
    //                                 return `${formatSize(dataSizeConversion(val.data[1],'MB','B').value).value} ${formatSize(dataSizeConversion(val.data[1],'MB','B').value).unit}`
    //                             }
    //                         },
    //                         itemStyle: {
    //                             color: '#ff5e0e',
    //                             opacity: 1
    //                         },
    //                         lineStyle: {
    //                             color: '#ff5e0e',
    //                             opacity: 1,
    //                         }
    //                     },{
    //                         name: "Change",
    //                         id: 'line2',
    //                         type: "line",
    //                         stack: "stack2",
    //                         smooth: true,
    //                         symbolSize: 20,
    //                         label: {
    //                           show: true,
    //                           opacity: 1,
    //                           formatter: (val: any)=>{
    //                             return `${formatSize(dataSizeConversion(val.data[2],'MB','B').value).value} ${formatSize(dataSizeConversion(val.data[2],'MB','B').value).unit}`
    //                         }
    //                         },
    //                         itemStyle: {
    //                           color: '#ff5e0e',
    //                           opacity: 0.5
    //                         },
    //                         lineStyle: {
    //                           color: '#ff5e0e',
    //                           opacity: 0.5,
    //                         }
    //                     }
    //                 ]

    //                 console.log({chartOptions})
    //             }

    //             if( trendSelection === 'restore' && trendPeriod !== '1d' ) {
    //                 for (let i = 0; i < data?.activities.length; i++) {
    //                     if( i === 0 ) {
    //                         chartOptions.dataset.source.push(['time', 'Restore'])
    //                     }
    //                     let timeLabel = ((getFormattedDateTime(data?.activities[i]?._time) as string).split(' ').length > 0)  ? getFormattedDateTime(data?.activities[i]?._time)?.split(' ')[0] : getFormattedDateTime(data?.activities[i]?._time)?.split(',')[0];
    //                     chartOptions.dataset.source.push([
    //                         timeLabel,
    //                         data?.activities[i]?._value,
    //                     ])
    //                 }
    //                 chartOptions.tooltip.formatter = (params: any)=>{
    //                     return (
    //                         `
    //                           <div style="text-align: left">
    //                             <strong>${params[0].data[0]}</strong><br>
    //                             <i class="fa-solid fa-circle" style="font-size: 12px; color: #ff5e0e"></i> <strong>${params[0].dimensionNames[1]}:</strong> ${params[0].data[1]} MB
    //                           </div>
    //                         `
    //                       );
    //                 }
    //                 chartOptions.legend.data = ["Restore"]
    //                 chartOptions.series = [
    //                     {
    //                         name: "Restore",
    //                         id: 'line1',
    //                         type: "line",
    //                         stack: "stack1",
    //                         smooth: true,
    //                         symbolSize: 20,
    //                         label: {
    //                             show: true,
    //                             opacity: 1,
    //                             formatter: (val: any)=>{
    //                                 return `${formatSize(dataSizeConversion(val.data[1],'MB','B').value).value} ${formatSize(dataSizeConversion(val.data[1],'MB','B').value).unit}`
    //                             }
    //                         },
    //                         itemStyle: {
    //                             color: '#ff5e0e',
    //                             opacity: 1
    //                         },
    //                         lineStyle: {
    //                             color: '#ff5e0e',
    //                             opacity: 1,
    //                         }
    //                     }
    //                 ]

    //                 console.log({chartOptions})
    //             }

    //             setGraphData(chartOptions);
    //         }).catch((err: any)=>{
    //             console.log(err)
    //         }).finally(()=>{
    //             setTrendCallingActive(false);
    //         })
    //     }
    //     fetchTrendData();
    // },[trendSelection, trendPeriod])

    // const resetGraphData = () => {
    //     setGraphData({
    //         dataset: {
    //             source: []
    //         },
    //         tooltip: {
    //             formatter: ()=>{ return '' }
    //         },
    //         legend: {
    //             data: []
    //         },
    //         series: []
    //     })
    // }

    return (
        <>
            <Tile colSpan={8} rowSpan={13} className="py-2 h-[500px] lg:h-[430px] mb-6" >
                <div className="flex flex-col lg:flex-row justify-center text-center items-center align-middle md:justify-start md:text-left mx-2 py-2 md:py-4 border-b border-[#ff5e0e] md:mb-2">
                    <div className="px-4 text-gray-600 font-semibold flex flex-col items-center lg:items-start w-full my-2">
                        <span className="flex items-center">
                            <span className="title">{trendSelection.toUpperCase()} TRENDS
                                <small className="text-[#ff5e0e] font-medium ml-2">
                                    {/* Showing the total {trendSelection} trends of  */}
                                    { trendPeriod === '1d' && ' last one day' }
                                    { trendPeriod === '2d' && ' last two days' }
                                    { trendPeriod === '7d' && ' last seven days' }
                                </small>
                            </span>
                            {trendCallingActive && <em className="fa-solid fa-loader fa-spin ml-4"></em>}
                        </span>
                        {/* <span className="text-sm text-gray-400  mt-1 font-normal">
                            Press <span className="kbd ">Shift</span> and click drag to select range
                        </span> */}
                    </div>
                    <span className="grow"></span>
                    <ButtonGroup className="border mr-4 mb-2 lg:mb-0 w-full">
                        <Button size='sm' solid={dataType === 'backup' ? true : false}
                            onClick={()=>{
                                // setTrendCallingActive(true)
                                // setTrendSelection('backup')
                                // resetGraphData()
                                setDataType('backup')
                            }}
                        >Backup Trends</Button>
                        <Button size='sm' solid={dataType === 'restore' ? true : false}
                            onClick={()=>{
                                // setTrendCallingActive(true)
                                // setTrendSelection('restore')
                                // resetGraphData()
                                setDataType('restore')
                            }}
                        >Restore Trends</Button>
                    </ButtonGroup>
                    <ButtonGroup className="border w-full lg:mr-4">
                        <Button size='sm' solid={period === '1d' ? true : false}
                            onClick={()=>{
                                // setTrendCallingActive(true)
                                setPeriod('1d');
                                // resetGraphData()
                                setPeriodDate({
                                    startDate: twentyFourHour,
                                    endDate: twentyFourHour
                                })
                            }}
                        >1 Day</Button>
                        <Button size='sm' solid={period === '2d' ? true : false}
                            onClick={()=>{
                                // setTrendCallingActive(true)
                                setPeriod('2d');
                                // resetGraphData()
                                setPeriodDate({
                                    startDate: fortyEightHour,
                                    endDate: twentyFourHour
                                })
                            }}
                        >2 Days</Button>
                        <Button size='sm' solid={period === '7d' ? true : false}
                            onClick={()=>{
                                // setTrendCallingActive(true)
                                setPeriod('7d');
                                // resetGraphData()
                                setPeriodDate({
                                    startDate: seventyTwoHours,
                                    endDate: twentyFourHour
                                })
                            }}
                        >7 Days</Button>
                    </ButtonGroup>

                    
                </div>
                <div className="p-4 h-80 cursor-move">
                    {/* { JSON.stringify(graphData) } */}
                    <LineChart chartOptions={graphData} />
                </div>
            </Tile>
        </>
    )
}

export default BackupRestoreTrendGraph