import React, { FC } from "react";

interface ILoaderProp {
  className?: string
}

const Loader:FC<ILoaderProp> = ({ className='' }) => {
  return (
    <div className="absolute top-0 left-0 h-full w-full bg-gray-200 bg-opacity-50 flex items-center justify-center z-10">
      <i className={`fa-solid fa-circle-notch animate-spin text-orange-500 text-4xl ${className} `}></i>
    </div>
  );
};

export default Loader;
