
import React, { Dispatch, SetStateAction, ReactNode, useState, useEffect } from "react";

interface IAuditReportContext {
    searchParams: any;
    setSearchParams: Dispatch<SetStateAction<any>>;
    searchParamErrors: any;
    setSearchParamErrors: Dispatch<SetStateAction<any>>;
}

export const AuditReportContext = React.createContext({} as IAuditReportContext);

interface IProp {
    children: ReactNode | ReactNode[];
}

const AuditReportProvider = (props: IProp) => {

    const today = new Date();
    const sevenDatBefore = new Date(today.setDate(today.getDate() - 6));
    
    /* search parameters */
    const [searchParams, setSearchParams] = useState<any>({
        data: [],
        reportType: 'All',
        endpoints: [],
        range: {
            startDate: sevenDatBefore,
            endDate: new Date()
        },
        window: {
            value: 1,
            unit: 'd'
        }
    })

    const [searchParamErrors, setSearchParamErrors] = useState<any>({
      reportType: null,
      endpoints: null,
      range: null,
      window: null
    })

    useEffect(()=>{
      console.log({_changed:searchParams});
    },[searchParams])

    return (
      <AuditReportContext.Provider value={{
        searchParams:searchParams,
        setSearchParams:setSearchParams,
        searchParamErrors:searchParamErrors,
        setSearchParamErrors:setSearchParamErrors}}
      >
        {props.children}
      </AuditReportContext.Provider>
    )
}

export default AuditReportProvider;