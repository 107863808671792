/* eslint-disable react/jsx-pascal-case */
import React, { FC, useState, useEffect } from "react";
import Tile5x4_ChartText from "../../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText";
import { getAPICall } from "../../services/APILayer/axiosMethodCalls";
import { Tile } from "../../components/core";

interface IDummyCard {
    title: string,
    icon: string
}

const DummyCard:FC<IDummyCard> = ({
    title="",
    icon="fa-duotone fa-envelopes-bulk"
}) => {

    const [showMessage, setShowMessage] = useState(false);

    const handleMouseEnter = () => {
      setShowMessage(true);
    };
  
    const handleMouseLeave = () => {
      setShowMessage(false);
    };
  


    return (
        <>
            <Tile rowSpan={5} colSpan={4} className={`relative p-5 border`}>
                <div className="flex h-full items-center text-[#3A3541] z-0 ">
                    <div className="grow h-full">
                        <div className={`text-xl leading-8 font-medium text-[#3A3541] mb-5 whitespace-nowrap`}>
                            {title}
                        </div>
                        <div className={`flex flex-row text-[#3A3541] items-center`}>
                            <div className="text-xl leading-8 font-medium pr-2">
                                <i className={`${icon} text-5xl text-[#FF5E0E]`}></i>
                            </div>
                        </div>
                    </div>
                    <div className="text-7xl text-[#FF5E0E] flex items-center text-right justify-end rounded-md absolute whitespace-nowrap right-4">
                        0
                    </div>
                </div>
                
                <div className='absolute top-0 left-0 right-0 bottom-0 z-10'>
                    <div className='w-full h-full bg-white opacity-60'></div>
                    <div className="absolute top-0 right-0 px-3 py-1 bg-[#ff5e0e] text-white cursor-pointer rounded-tr-md"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>
                        {/* <span>Currently disabled </span> */}
                        <i className="fa-solid fa-circle-info "/>
                    </div>
                    <div className="">
                        {showMessage && (
                        <div className="absolute border font-medium min-w-max bottom-full left-1/2 top-8 max-h-min transform -translate-x-1/2 p-2 bg-white shadow-md rounded-md text-gray-700">
                            License is not procured for this service <br/>Please contact to our sales team
                        </div>
                        )}
                    </div>                       
                </div>
            </Tile>
        </>
    )
}

export default DummyCard;