import { Breadcrumb, Button, Dropdown, Modal, ModalBody, ModalHeader, Tile } from "../components/core"
import React, { useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import { Table } from "../components/core/Table";
import { ITableColumnItem, ITableControllerProps } from "../components/core/Table/Table";
import { getAPICall } from "../services/APILayer/axiosMethodCalls";
import { ThemeContext } from "../contexts";
import { Link, useNavigate } from "react-router-dom";
import { getFormattedDate } from "../utils/format";

const ManageLicense = () => {
    const navigator = useNavigate();
    
    const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    const [donutCutout, setDonutCutout] = useState<number>(50);
    const [pageSize, setPageSize] = useState<number[]>([0, 0]);
    const [responsiveBreakPoint, setBreakPoint] = useState<string>('sm');

    useLayoutEffect(() => {
        function updateSize() {
            setPageSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(()=>{
        if(pageSize[0] <= 640) {
            setBreakPoint('sm')
        }
        if(pageSize[0] > 640 && pageSize[0] <= 768) {
            setBreakPoint('md')
        }
        if(pageSize[0] > 768 && pageSize[0]  <= 1024) {
            setBreakPoint('lg')
        }
        if(pageSize[0] > 1024 && pageSize[0]  <= 1280) {
            setBreakPoint('xl')
        }
        if(pageSize[0] > 1280) {
            setBreakPoint('2xl')
        }
    },[pageSize]);

    useEffect(()=>{
        if(responsiveBreakPoint === 'sm') {
            setDonutCutout(80);
        }
        if(responsiveBreakPoint === 'md') {
            setDonutCutout(50);
        }
        if(responsiveBreakPoint === 'lg') {
            setDonutCutout(80);
        }
        if(responsiveBreakPoint === 'xl') {
            setDonutCutout(55);
        }
        if(responsiveBreakPoint === '2xl') {
            setDonutCutout(80);
        }
    },[responsiveBreakPoint])

    // const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    const navRaf = useRef<HTMLDivElement>(null)

    const [myOrgLicenseInfo, setMyOrgLicenseInfo] = useState<any>({});
    const [allLicensesByIssuer, setAllLicensesByIssuer] = useState<{[key: string]: any}[]>([]);
    const [ordersInfo, setOrdersInfo] = useState<any>({});
    const [modalVisible, setModalVisibility]=useState(false);
    const [selectedOrder, setSelectedOrder]=useState<any>(null);
    const [orgModalInfo, setOrgModalInfo] = useState<any>(null);
    const [orgModalVisibilityState, modalVisibilityState] = useState<any>(null);
    const [tableController, setTableController] = useState<ITableControllerProps>({
        data: [],
        loader: false,
        currentPage: 1,
        offset: 0,
        limit: 5,
        totalRecords: 10,
        maxVisiblePaginationButtons: 5,
        searchTerm: '',
        filter: {},
        sorting: {
            column: '',
            direction: null
        }
    });
    const [tableColumns, setTableColumns] = useState<ITableColumnItem[]>([
        {
            id: 1,
            name: 'Id',
            column: '_id',
            textAlign: 'text-left',
            textColor: 'text-slate-500',
            visibility: true,
            className: "pl-4 w-[200px] font-medium "
        },
        {
            id: 2,
            name: 'Issuer',
            column: 'hubspot_issuer_company_id',
            textAlign: 'text-left',
            textColor: 'text-[#FF5E0E]',
            visibility: true,
            className: "w-[80px]"
        },
        {
            id: 3,
            name: 'Owner',
            column: 'hubspot_owner_company_id',
            textAlign: 'text-left',
            textColor: 'text-[#FF5E0E]',
            visibility: true,
            className: "w-[80px]"
        },
        {
            id: 5,
            name: 'Start Date',
            column: 'license_start_date',
            textAlign: 'text-left',
            textColor: 'text-slate-500',
            visibility: true,
            className: "w-[80px] font-medium"
        },
        {
            id: 6,
            name: 'End Date',
            column: 'license_end_date',
            textAlign: 'text-left',
            textColor: 'text-slate-500',
            visibility: true,
            className: "w-[80px] font-medium"
        },
        {
            id: 6,
            name: 'Product Access',
            column: 'license_data',
            textAlign: 'text-left',
            textColor: 'text-slate-500',
            visibility: true,
            className: "w-[220px] font-medium"
        },
        {
            id: 6,
            name: 'Status',
            column: 'license_enable',
            textAlign: 'text-left',
            textColor: 'text-slate-500',
            visibility: true,
            className: "w-[80px] font-medium"
        },
    ]);

    const getMyOrgLicenseInfo = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/app/licenses/me`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( Object.keys(myOrgLicenseInfo).length > 0 ) {
            return;
        }
        const fetchLicenseInfo = () => {
            getMyOrgLicenseInfo()
                .then((license)=>{
                    console.log({license})
                    setMyOrgLicenseInfo(license)
                })
                .catch(err=>console.log(err));
        }
        fetchLicenseInfo();
    },[myOrgLicenseInfo])

    const getLicensesByOrg = async (issuerCompanyId: string) => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/app/licenses?hubspot_issuer_company_id=${issuerCompanyId}`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( Object.keys(myOrgLicenseInfo).length < 1){
            return;
        }
        const fetchLicencesByMyCompany = () => {
            const myCompanyId = myOrgLicenseInfo?.license?.hubspot_owner_company_id || "";
            getLicensesByOrg(myCompanyId)
                .then((licenses)=>{
                    console.log({licenses})
                    setAllLicensesByIssuer(licenses?.licenses || []);
                })
                .catch(err=>console.log(err));
        }
        fetchLicencesByMyCompany()
    },[myOrgLicenseInfo]);

    useEffect(()=>{
        if( allLicensesByIssuer.length < 1 ) {
            return;
        }

        setTableController({
            ...tableController,
            data: allLicensesByIssuer
        })
    },[allLicensesByIssuer])

    const actionButtonGenerator = (row: any) => {
        return <>
            {/* <button aria-label='edit'
                onClick={()=>{

                }}
            ><em className='fa-regular fa-pen text-[#3A3541] opacity-80 mr-2'></em></button> */}
            <button aria-label='view'
                onClick={()=>{
                    navigator(`/manage/license/add?ownerCompanyId=${row?.hubspot_owner_company_id || ""}`)
                }}
            ><em className='fa-regular fa-eye text-[#3A3541] opacity-80 w-[90px]'></em></button>
        </>
    }

    const handleOrgIdClick = async (orgId: any, title: string) => {
        // let org = await getOrgInfo(orgId);
        setOrgModalInfo((prevState: any)=>{
            return {
                modelTitle: title,
                // ...org
            }
        });
        modalVisibilityState(true)
    }

    const tableDataFormatter = (rowObj: any = {}, format?: string | undefined) => {
        let returningVal = null;
        switch (format) {
            case 'Start Date':
            case 'End Date':
                returningVal = (
                    getFormattedDate(rowObj)
                )
                break;
            
            case 'Product Access':                
                returningVal = (
                    <div className="flex">
                        {Object.keys(rowObj?.product_access || []).map((p:string, pIndex:number) => (
                            <>{ rowObj?.product_access[p].enabled ? (
                                <div className="bg-orange-500 px-2 py-1 text-white rounded-sm mr-2">{p}</div>
                            ) : ''}</>
                        ))}
                    </div>
                )
                break;

            case 'Status':
                    returningVal = (
                        <div className="ml-[2px] max-w-min  items-center justify-start w-[300px]">
                            {
                                rowObj === true ? 
                                (<div className="bg-green-500 text-green-600 px-2 py-1 bg-opacity-20 border border-green-200 rounded-sm mr-2">Enabled</div>) : 
                                (<div className="bg-yellow-500 text-yellow-600 px-2 py-1 bg-opacity-20 border border-yellow-200 rounded-sm mr-2">Disabled</div>) 
                            }
                        </div>
                    )
                    break;
            default:
                returningVal = rowObj;
                break;

        }
        return returningVal;
    }

    const handleSwitch = () => {}

    const handleModalVisibility = (orderDetails: any) => {
        setSelectedOrder(orderDetails)
        setModalVisibility(true)
    }

    return (
        <>
            <div className={`p-6 grow flex flex-col`}>    
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Manage License</h1>
                <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-home', link: '', name: 'Home' },
                    { icon: '', link: '/onboarding', name: 'Manage License' },
                ]} />

                <div className={`grow flex flex-col gap-6`}>
                    <Tile colSpan={9} rowSpan={25} className="pt-2 mb-6" >
                        <div className="p-4 flex md:flex-row md:gap-4 gap-2 w-full">
                            <div className=" text-gray-800 font-medium">Licenses</div>
                            <div className="flex justify-between w-full ">
                            <label htmlFor="searchBox" className="search flex items-center border rounded-md px-3 py-[2px]">
                                <em className="fa-solid fa-search text-slate-400 pr-2"></em>
                                <input 
                                    type="text" 
                                    id="searchBox"
                                    placeholder="Search for organization" 
                                    className="outline-none w-full" 
                                    value={tableController.searchTerm} 
                                    onChange={(e)=>{
                                        // setTableController((prevState)=>{
                                        //     let newState = {...prevState};
                                        //     newState.searchTerm = e.target.value;
                                        //     return newState;
                                        // });
                                    }}
                                />
                            </label> 
                            </div>
                            <Link className="" to="/manage/license/add">
                                <Button size='sm' className=" sm:w-36 flex items-center justify-center whitespace-nowrap" >
                                    <i className="fa-solid fa-link text-sm font-medium"/>
                                    <span className="ml-2 flex items-center">
                                        Assign <span className="hidden sm:block ml-1">License</span>
                                    </span>
                                </Button>
                            </Link>
                            </div>
                        <div className="absolute left-0 right-0 sm:top-[80px] top-20 bottom-0 h-auto w-auto">
                            <Table
                                navRef={navRaf}
                                columns={tableColumns}
                                setColumns={setTableColumns}
                                formatData={tableDataFormatter}
                                showActionCell={true}
                                showActionHeaderCell={true}
                                showColumnManagementMenu={false}
                                switchHandler={handleSwitch}
                                tableController={tableController}
                                actionButtonGenerator={actionButtonGenerator}
                                tableId="OrderTable"
                            />
                        </div>
                    </Tile>   
                </div>
                
            </div>

            <Modal modalVisible={modalVisible}  setModalVisibility={()=>{ setModalVisibility(false) }} className='orderInfoModal h-1/3 w-1/3'>
                <ModalHeader setModalVisibility={()=>{ setModalVisibility(false) }} className={`text-[#3A3541] text-base`}>Order Details</ModalHeader>
                <ModalBody className="flex w-full items-center justify-center">
                    {selectedOrder?.order_data.map((item: any, itemIndex: number)=>(
                        <div key={itemIndex} className="grow flex flex-col items-center justify-center align-middle">
                            {item.unit === 'endpoints' && <em className="fa-thin fa-laptop text-8xl text-[#ff5e0e] my-4"></em>}
                            {item.unit === 'GB' && <em className="fa-thin fa-hard-drive text-8xl text-[#ff5e0e] my-4"></em>}
                            <span className="text-lg text-gray-500 uppercase">
                                {item.unit === 'endpoints' && item.unit}
                                {item.unit === 'GB' && 'Storage'}
                            </span>
                            <span className="text-xl text-[#3A3541] font-bold">{item.qty} {item.unit === 'GB' && item.unit}</span>
                        </div>
                    ))}
                </ModalBody>
            </Modal>  

            <Modal modalVisible={orgModalVisibilityState}  setModalVisibility={()=>{ modalVisibilityState(false) }} className='orgDetailModal h-1/2 w-1/4'>
                <ModalHeader setModalVisibility={()=>{ modalVisibilityState(false) }} className={`text-[#3A3541] text-base`}>{orgModalInfo?.modelTitle || ''}</ModalHeader>
                <ModalBody className="flex w-full items-center justify-center">
                    <div className="flex flex-col w-full py-4 justify-center items-center">
                        <div className="p-8 h-44 w-full mb-8 flex justify-center items-center object-contain">
                            <img src="https://cdn.zeeq.io/zeeq-new-logos/Brand/SVG/wordmark_blackorange.svg" alt="logo" className="min-h-max w-auto" />
                        </div>
                        <div className="flex flex-col items-center">
                            <div className="text-[#3A3541] text-2xl font-semibold flex items-center capitalize mb-1">{orgModalInfo?.company?.properties?.name || ''}</div>
                            <div className="text-green-600 text-sm font-light flex items-center py-1 px-4 mb-3 bg-green-500 bg-opacity-10 rounded-sm ">{orgModalInfo?.company?.properties?.type || ''}</div>
                            <div className="text-[#3A3541] text-base flex items-center">{orgModalInfo?.company?.properties?.company_email.split(',')[0] || ''}</div>

                        </div>
                    </div>
                </ModalBody>
            </Modal>         
        </>
    );
}
export default ManageLicense;

