import React from "react";

const TicketMovement = () => {
    return (
        <>
            <div className="flex flex-col m-6">
                <div className="group">
                    <div className="flex gap-4 py-2 overflow-hidden group-hover:bg-white group-hover:rounded-[20px] group-hover:shadow-lg transition-all duration-500 ease-in-out">
                        <div className="relative w-14">
                            <div className="absolute -top-2 left-2 block ml-5 w-0 h-5 border-l-2 border-orange-500 z-0 group-hover:border-white transition-all duration-500 ease-in-out"></div>
                            <div className="absolute top-5 left-2 flex items-center justify-center align-middle rounded-full w-10 h-10 -mt-3 bg-orange-500 z-10">
                                <em className="fa-solid fa-heading text-white"></em>
                            </div>
                            <div className="absolute top-10 left-2 block ml-5 w-0 h-10 border-l-2 border-orange-500 z-0 group-hover:border-white transition-all duration-500 ease-in-out"></div>
                        </div>
                        <div className="flex flex-col">
                            <div className="font-bold">Subject Change</div>
                            <div className="flex items-center gap-2">
                                <div className="text-sm">Previous State</div>
                                <em className="fa-thin fa-arrow-right"></em>
                                <div className="text-sm">Current State</div>
                            </div>
                            <div className="text-xs font-light">2023-04-10 20:00:00</div>
                        </div>
                    </div>
                </div>
                <div className="group">
                    <div className="flex gap-4 py-2 overflow-hidden group-hover:bg-white group-hover:rounded-[20px] group-hover:shadow-lg transition-all duration-500 ease-in-out">
                        <div className="relative w-14">
                            <div className="absolute -top-2 left-2 block ml-5 w-0 h-5 border-l-2 border-orange-500 z-0 group-hover:border-white transition-all duration-500 ease-in-out"></div>
                            <div className="absolute top-5 left-2 flex items-center justify-center align-middle rounded-full w-10 h-10 -mt-3 bg-orange-500 z-10">
                                <em className="fa-solid fa-shuffle text-white"></em>
                            </div>
                            <div className="absolute top-10 left-2 block ml-5 w-0 h-10 border-l-2 border-orange-500 z-0 group-hover:border-white transition-all duration-500 ease-in-out"></div>
                        </div>
                        <div className="flex flex-col">
                            <div className="font-bold">Status Changed</div>
                            <div className="flex items-center gap-2">
                                <div className="bg-orange-100 px-2 py-1 text-xs font-bold rounded-full shadow-md">New</div>
                                <em className="fa-thin fa-arrow-right"></em>
                                <div className="bg-orange-100 px-2 py-1 text-xs font-bold rounded-full shadow-md">Pending</div>
                            </div>
                            <div className="text-xs font-light">2023-04-10 20:00:00</div>
                        </div>
                    </div>
                </div>
                <div className="group">
                    <div className="flex gap-4 py-2 overflow-hidden group-hover:bg-white group-hover:rounded-[20px] group-hover:shadow-lg transition-all duration-500 ease-in-out">
                        <div className="relative w-14">
                            <div className="absolute -top-2 left-2 block ml-5 w-0 h-5 border-l-2 border-orange-500 z-0 group-hover:border-white transition-all duration-500 ease-in-out"></div>
                            <div className="absolute top-5 left-2 flex items-center justify-center align-middle rounded-full w-10 h-10 -mt-3 bg-orange-500 z-10">
                                <em className="fa-solid fa-sparkles text-white"></em>
                            </div>
                            {/* <div className="absolute top-10 left-2 block ml-5 w-0 h-10 border-l-2 border-orange-500 z-0 group-hover:border-white transition-all duration-500 ease-in-out"></div> */}
                        </div>
                        <div className="flex flex-col">
                            <div className="font-bold">New Ticket Created</div>
                            <div className="flex items-center gap-2">
                                <div className="text-sm">Ticket ID: 1232987</div>
                                <em className="fa-thin fa-arrow-right"></em>
                                <div className="text-sm">Subject</div>
                            </div>
                            <div className="text-xs font-light">2023-04-10 20:00:00</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TicketMovement;