/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, FC } from "react";
import { getAPICall } from "../../services/APILayer/axiosMethodCalls";
import { ifTimeExpired } from "../../utils/format"
// import Tile5x4_ChartText from "../../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText";
import { Tile } from "../../components/core";
import { DonutChart } from "../../components/Charts/ApacheECharts";
import { Link } from "react-router-dom";

interface ILicenseStatusCard {
    licenseInfo: any
}

const LicenseStatusCard: FC<ILicenseStatusCard> = ({licenseInfo}) => {

    return (
        <>
            {/* <Tile5x4_ChartText
                clickNavigate={true}
                navigationPath='/settings/license'
                title={
                    < div className="flex items-center">
                        License Status 
                    </div>}
                description="Renewal Date"
                value={
                    <div className="flex md:flex-row flex-col text-xs mt-1 items-center justify-start">
                        <span className="font-semibold text-gray-600 text-base flex justify-center items-center">
                            { ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).renewalDate }
                        </span>
                            { (ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).daysLeft > 10) 
                                && (
                                <>
                                    <span className="lg:ml-4 text-sm md:text-base text-green-600 text-start justify-start flex w-full">Active</span>
                                    <i className="fa-solid fa-check md:block hidden bg-green-100 text-green-600 border border-green-300 rounded-full w-4 h-4 text-xs text-center ml-1"/>
                                </>
                                )
                            }
                            { (ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).daysLeft > 0 && ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).daysLeft < 11) 
                                && (
                                <>
                                    <div className="flex items-center md:ml-4 text-sm md:text-base w-full text-yellow-600">Expiring<i className="fa-solid fa-exclamation md:block hidden md:mt-1 bg-yellow-100 text-yellow-600 border w-4 h-4 items-center justify-center text-center ml-2 border-yellow-300 rounded-full text-xs"/></div>                                    
                                </>
                                )
                            }
                            { ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).expired 
                                && (
                                <>
                                    <span className="md:ml-8 text-sm md:text-base text-red-600 text-start justify-start flex w-full">Expired</span>
                                    <i className="fa-solid fa-exclamation md:block hidden bg-red-100 text-red-600 border border-red-300 rounded-full w-4 h-4 text-xs text-center ml-1"/>
                                </>
                                )
                            }
                    </div>
                }
                textValue={<em className="fa-light fa-file-certificate text-[56px]"></em>}
                textColor="text-green-600"
                designOption="chart"
                chartType="donut" 
                chartLabels={['Left', 'Spent']} 
                chartDataset={[
                    {
                        label: '# of Votes',
                        data: [
                            ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).daysLeft,
                            ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).totalDays - ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).daysLeft,
                        ],
                        backgroundColor: [
                            'rgba(255, 94, 14, 1)',
                            'rgba(65, 65,65, 0.1)',
                        ],
                        borderWidth: 5,
                        cutout: 43,
                        borderRadius: 20
                    },

                ]}
                chartOptions={{
                    responsive: true,
                    plugins: {
                        legend: { display: false },
                        title: { display: false },
                        datalabels: { display: false },
                    },
                    scales: {
                        x: { display: false },
                        y: { display: false },
                    },
                }}
                inChartMain={ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).daysLeft + ''} inChartSub="Days Left"
                className=''
                tileMode='default'
                alert={false}
                live={false}
            /> */}
            <Tile rowSpan={5} colSpan={4} className="cursor-pointer" >
                <Link to="/settings/license">
                    <div className="flex flex-row w-full h-full items-center pl-6">
                        <div className="w-full flex flex-col justify-start items-center text-left">
                            <div className="w-full text-xl font-medium text-[#3a3541] flex text-left justify-start whitespace-nowrap">License Status</div>
                            <div className="text-slate-400 font-normal text-xs w-full mt-3">Renewal Date</div>
                            <div className="flex flex-col text-xs w-full items-center justify-start text-left">
                                <div className="font-semibold text-slate-600 text-base flex justify-start items-center text-left w-full">
                                    { ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).renewalDate }
                                </div>
                                    { (ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).daysLeft > 10) 
                                        && (
                                        <>
                                            <div className="text-sm md:text-base text-green-600 text-left justify-start flex w-full font-medium items-center whitespace-nowrap">
                                                    Active
                                                    <i className="fa-solid fa-check  bg-green-100 text-green-600 border border-green-300 rounded-full w-4 h-4 text-xs text-center ml-1 items-center"/>                                            </div>
                                        </>
                                        )
                                    }
                                    { (ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).daysLeft > 0 && ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).daysLeft < 11) 
                                        && (
                                        <>
                                            <div className="flex items-center text-sm md:text-base w-full text-yellow-600 font-medium whitespace-nowrap">Expiring<i className="fa-solid fa-exclamation  md:mt-1 bg-yellow-100 text-yellow-600 border w-4 h-4 items-center justify-center text-center ml-2 border-yellow-300 rounded-full text-xs"/></div>
                                            {/* <div className="lg:ml-4 text-sm md:text-base text-yellow-600 text-start justify-start md:items-center flex w-full">Expiring<i className="fa-solid fa-exclamation  bg-yellow-100 text-yellow-600 border border-yellow-300 rounded-full w-8 h-8 ml-2 justify-center items-center align-middle text-xs text-center"/></div> */}
                                            
                                        </>
                                        )
                                    }
                                    { ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).expired 
                                        && (
                                        <>
                                            <div className="text-sm md:text-base text-red-600 text-start justify-start flex w-full font-medium items-center whitespace-nowrap">
                                                Expired
                                                <i className="fa-solid fa-exclamation bg-red-100 text-red-600 border border-red-300 rounded-full w-4 h-4 text-xs text-center ml-1"/>
                                            </div>
                                        </>
                                        )
                                    }
                            </div>
                        </div>
                        <div className="aspect-square w-[200px] mr-4">
                        <DonutChart
                            title=""
                            data={[                     
                                {
                                    value: ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).daysLeft,
                                    name: "Days Left",
                                    itemStyle: {
                                        opacity: 1,
                                    }
                                },
                                {
                                    value: ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).totalDays - ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).daysLeft,
                                    name: "Days Passed",
                                    itemStyle: {
                                        opacity: 0.2,
                                        color:"#999"

                                    }
                                }
                            ]}
                            thickness= {['70%', '80%']}
                            legend={{
                                show:false
                            }}
                            centreValue={
                                <>
                                    <div className="text-2xl font-semibold">{ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).daysLeft + ''}</div>
                                    <div className="text-sm font-normal">Days left</div>
                                </>
                            }
                            
                        />
                        </div>
                    </div>
                </Link>
            </Tile>
        </>
    )
}

export default LicenseStatusCard;