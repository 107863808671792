/* eslint-disable react/jsx-pascal-case */
import React, { FC, useContext, useEffect, useState } from "react";
import Tile5x4_ChartText from "../../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText";
import { getAPICall } from "../../services/APILayer/axiosMethodCalls";
import { formatSize } from "../../utils/backupFileUtils";
import { SideDrawerContext } from "../../contexts";
import { Link } from "react-router-dom";
import { deviceSymbol } from "../../utils/agentUtils";

interface IAdditionalStorageCard {
    
}

const AdditionalStorageCard:FC<IAdditionalStorageCard> = () => {

    const [ rawEndpointData, setRawEndpointData ] = useState<any[]>([]);
    const [ policyStrategy, setPolicyStrategy ] = useState<any>({});
    const [ additionalStorageEndpoints, setAdditionalStorageEndpoints ] = useState<any[]>([]);
    const [ protectedStorage, setProtectedStorage ] = useState<number>(0);
    const [ maxStorage, setMaxStorage ] = useState<number>(0);

    const sideDrawerContext = useContext(SideDrawerContext)

    const getEndpoints = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const getPolicyStrategy = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints/config`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( rawEndpointData.length > 0 ) {
            return;
        }

        const fetchEndpoints = async () => {
            let fetchedAgents = await getEndpoints();
            let fetchedPolicyStrategy = await getPolicyStrategy();
            setRawEndpointData(fetchedAgents?.endpoints || [])
            setPolicyStrategy(fetchedPolicyStrategy)
        }

        fetchEndpoints();

    },[rawEndpointData])

    useEffect(()=>{
        if( rawEndpointData.length < 1 ) {
            return;
        }

        console.log({rawEndpointData})

        let additionalStorageEndpoints = rawEndpointData
            .filter((ep:any)=>{ return ep?.agent_profile?.AgentInfos !== null })
            .filter((ep:any)=>{ return !ep?.agent_profile?.AdminInfos?.Deletion }).filter((ep:any)=> {
            return ep.agent_profile.AdminInfos.Quotas.MaxProtSize !== null
        })
        setAdditionalStorageEndpoints(additionalStorageEndpoints)
    },[rawEndpointData])

    useEffect(()=>{
        if( Object.keys(policyStrategy).length < 1 ) {
            return
        }

        console.log({policyStrategy})

        let calculatedMaxStorage = (policyStrategy?.config?.current_strategies || []).reduce((acc:number, strategy:any)=>{
            acc = acc + strategy?.ParamQuotaMaxProtSize || 0;
            return acc;
        },0)
        setMaxStorage(calculatedMaxStorage)
        console.log({calculatedMaxStorage})
    },[policyStrategy])

    useEffect(()=>{
        if(additionalStorageEndpoints.length < 1) {
            return;
        }

        let calculatedProtectedStorage = additionalStorageEndpoints.filter((ep:any)=>{ return !ep?.agent_profile?.AdminInfos?.Deletion }).reduce((acc:number, ep:any)=>{
            acc = acc + (ep?.agent_profile?.ServerInfos?.Backup?.ProtSize || 0);
            return acc;
        },0)

        setProtectedStorage(calculatedProtectedStorage)
        console.log({additionalStorageEndpoints},{calculatedProtectedStorage})

    },[additionalStorageEndpoints])



    return (
        <>
            <Tile5x4_ChartText 
                sideBar={false}
                tileAction={true}
                actionHandler={()=>{
                    sideDrawerContext.setLevelOneDrawerVisibility(false) 
                    sideDrawerContext.setLevelTwoDrawerVisibility(false) 
                    // eslint-disable-next-line react/jsx-no-undef
                    sideDrawerContext.setFirstDrawerChild(<FirstDrawerComponent additionalStorageEndpoints={additionalStorageEndpoints}  />)
                    sideDrawerContext.setLevelOneDrawerVisibility(true) 
                }}
                title={
                    < div className="flex flex-col">
                        <div>Addition Storage</div>
                        {/* <div className="text-sm -mt-1 font-light">(File server Backup)</div> */}
                        {/* <small className="text-green-600 p-1 px-2 border-green-200 text-xs"><span className=" text-green-600"></span><i className="fa-solid fa-check bg-green-100 text-green-600 border border-green-300 rounded-full w-4 h-4 ml-1 text-xs"/></small> */}
                    </div>}
                description=""
                value={<i className="fa-duotone fa-database text-5xl text-[#FF5E0E] relative">
                            {/* <i className="fa-light fa-shield-check absolute -bottom-2 left-7 bg-slate-300 flex justify-center w-10 h-10 text-xl items-center text-center text-orange-700 rounded-full border bg-opacity-70"></i> */}
                        </i>}
                // textBackground="bg-green-100"
                // textValue={<em className="fa-light fa-file-certificate text-[56px]"></em>}
                // textColor="text-green-600"
                // textArea=""
                // inChartMain='20' inChartSub="License"
                // alert={false}
                // live={false}
                designOption="chart"
                chartType="donut" 
                chartLabels={[]} 
                chartDataset={[
                    {
                        label: '# of Votes',
                        data: [],
                        backgroundColor: [
                        ],
                        borderWidth: 5,
                        cutout: 43,
                        borderRadius: 20
                    },

                ]}
                chartOptions={{
                    responsive: true,
                    plugins: {
                        legend: { display: false },
                        title: { display: false },
                    },
                    scales: {
                        x: { display: false },
                        y: { display: false },
                    },
                }}
                inChartMain='' inChartSub=""
                className='border relative'
                tileMode='default'
                alert={false}
                live={false}
            >
                <div className="text-[#FF5E0E] flex items-end text-right justify-end rounded-md absolute whitespace-nowrap right-4">
                    <div className="">{protectedStorage ?  <div className="flex items-end text-7xl">{formatSize(protectedStorage).value}<div className="text-2xl pl-1 pb-[2px]">{formatSize(protectedStorage).unit}</div></div> : '0 B'}</div>
                    {/* <div className="text-7xl ">{protectedStorage ? `${formatSize(protectedStorage).value} ${formatSize(protectedStorage).unit}` : '0 B'}</div> */}
                    {/* <div className="text-5xl mx-1 font-light">/</div> */}
                    {/* <div className="">{maxStorage && <div className="text-3xl flex items-end ">{formatSize(maxStorage).value}<div className="text-lg pl-[2px]">{formatSize(maxStorage).unit}</div></div>}</div> */}
                </div>
            </Tile5x4_ChartText>
        </>
    )
}

export default AdditionalStorageCard;

interface IFirstDrawerComponent {
    additionalStorageEndpoints: any[]
}

const FirstDrawerComponent:FC<IFirstDrawerComponent> = ({additionalStorageEndpoints}) => {
    
    const [openLevel1,setOpenLevel1] = useState (false);

    const  openHandler1 = ()=>{
        setOpenLevel1(!openLevel1)
    }

    return (
        <>
            <div className="h-full flex flex-col">
                <div className="title w-full flex gap-4 px-6 top-0 pt-10 border-b pb-10 bg-slate-100 items-center">
                    {/* Icon */}
                    <i className="fa-regular fa-database  text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i>

                    {/* title */}
                    <div className="flex flex-col">
                        <div className="text-xl font-bold text-slate-700">Addition Storage Devices</div>
                        {/* Sub title */}
                        <div className="block text-slate-500">
                            {/* <Link to="/endpoint/devices"> */}
                                <span>File Server Backup</span>
                                {/* <em className="fa-solid fa-link ml-2 text-sm"></em>
                            </Link> */}
                        </div>
                    </div>
                </div>
                <div className="data grow px-4 w-full">
                    <div className={`border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden  mt-10`}>
                        {(additionalStorageEndpoints || []).length > 0 && <div className={`table w-full`}>
                            <div className={`thead bg-slate-50 border-y w-full p-2 flex flex-row items-center`}>
                                <div className="row text-gray-800 text-sm flex">
                                    <div className="cell text-left w-16 pl-4">#</div>
                                    <div className="cell text-left w-16 -ml-2">OS</div>
                                    <div className="cell text-left w-[180px]">Device Name</div>
                                    <div className="cell text-left grow">Protected Size</div>
                                </div>
                            </div>
                            <div className={`tbody overflow-y-scroll customScroll h-[60vh] bg-white w-full flex flex-col text-slate-600`}>
                                {(additionalStorageEndpoints || []).filter((ep:any)=>{ return !ep.agent_profile.AdminInfos.Deletion }).map((endpoint:any, endpointIndex:number)=>(
                                    <Link 
                                        key={endpointIndex}
                                        to={`/endpoint/devices/profile/${endpoint?._id}`}
                                    >
                                        <div className="row text-sm font-sm flex border-b w-full py-2 hover:bg-slate-100">
                                            <div className="cell w-16 pl-4 cursor-pointer text-orange-500">{endpoint?.agent_id || ''}</div>
                                            <div className="cell text-slate-500 w-16">{deviceSymbol(endpoint?.agent_profile?.AgentInfos?.System || '')}</div>
                                            <div className="cell w-[180px] truncate">{endpoint?.agent_profile?.AdminInfos?.Name || ''}</div>
                                            <div className="cell grow">
                                                {/* {formatSize(endpoint?.agent_profile?.AdminInfos?.Quotas?.MaxProtSize).value}{formatSize(endpoint?.agent_profile?.AdminInfos?.Quotas?.MaxProtSize).unit}  */}
                                                {endpoint?.agent_profile?.ServerInfos?.Backup?.ProtSize ? `${formatSize(endpoint?.agent_profile?.ServerInfos?.Backup?.ProtSize).value} ${formatSize(endpoint?.agent_profile?.ServerInfos?.Backup?.ProtSize).unit}` : '0 Bytes'} 
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}