/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useEffect, useContext } from "react"
import { SideDrawerContext, ThemeContext } from "../../contexts";
import { Breadcrumb, Button, Tile } from "../../components/core";
import { ActiveProtectionPolicyCard, InactiveProtectionPolicyCard, TotalProtectedPolicyCard } from "./EndpointPolicyComponents";
import { getAPICall } from "../../services/APILayer/axiosMethodCalls";
import { originalPolicies, getEndpointConfig, deviceSymbol } from '../../utils/agentUtils';
import { useNavigate } from "react-router-dom";


const EndpointPolicy = ( ) =>{
    const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    const sideDrawerContext = useContext(SideDrawerContext)

    const [rawPolicyStrategy, setRawPolicyStrategy] = useState<any>({});
    const [policyStrategy, setPolicyStrategy] = useState<any>({});
    const [selectedPolicies, setSelectedPolicies] = useState<any>({});
    const [allEndpoints, setAllEndpoints] = useState<any[]>([]);

    const [selectedPolicy, setSelectedPolicy] = useState<string>('');
    const [policies, setPolicies] = useState<any>({});
    const [policyFilterName, setPolicyFilterName] = useState<string>('all');

    const [policyHoverIndex, setPolicyHoverIndex] = useState<number|null>(null);

    const getEndPoints = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const getPolicyStrategy = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints/config`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        const fetchEndPoints = async () => {
            getEndPoints().then((data)=>{
                setAllEndpoints(data.endpoints)
                console.log({allEndpoints:data.endpoints})
            }).catch((error)=>{ console.log(error) }).finally()
        }
        const fetchPolicyStrategy = async () => {
            await getPolicyStrategy().then((data)=>{
                setRawPolicyStrategy(data)
                // console.log({rowpolicy:data})
            }).catch((error)=>{ console.log({error}) }).finally();
        }
        fetchEndPoints()
        fetchPolicyStrategy()
    },[]);

    useEffect(()=>{
        if( Object.keys(rawPolicyStrategy).length > 0 ) {
            let arrPolicyObj: any[] = [];
            let arrPolicy: string[] = [];
            (rawPolicyStrategy?.config?.current_policies || []).forEach((policy:any) => {
                if( policy.ID > 99 ) {
                    let policyName = {...(originalPolicies || []).find((p)=>{ return p.ID === policy.ID })};
                    arrPolicy.push(`(${policy.ID}) ${policyName?.Name || policy?.Name}`)
                    arrPolicyObj.push({
                        id: policy.ID,
                        value: policy.Name,
                        name: policyName?.Name || policy?.Name
                    })
                }
            });
            setPolicyStrategy({
                policy: arrPolicy,
                policyObj: arrPolicyObj,
            });

            setSelectedPolicies({
                policy: arrPolicy,
                policyObj: arrPolicyObj,
            })
        }

    },[rawPolicyStrategy])

    const handlePolicySelect = (p:string) => {
        sideDrawerContext.setLevelOneDrawerVisibility(false)
        setSelectedPolicy(p)
        let selectedPolicy = (policyStrategy.policyObj || []).find((pol:any)=>{ return pol.name === p.split(') ')[1]})
        selectedPolicy.ID = selectedPolicy.id
        let policyData = getEndpointConfig(rawPolicyStrategy.config, selectedPolicy);
        setPolicies(policyData)
        // console.log({p},{selectedPolicy},{policyData})
    }

    const behaviorText = {
        EXCL:"Excluded Extensions",
        INCL:"Included Extensions"
    }

    const getFilteredPolicies = (type:string) => {

        setPolicyFilterName(type)
        
        let filteredRawPolicies = [];
        let newPolicyObj = [];
        let newPolicies = [];

        if( type === 'all' ) {
            filteredRawPolicies = (rawPolicyStrategy?.config?.current_policies || []).filter((p:any)=>{return p.ID > 99})
            newPolicyObj = filteredRawPolicies.map((p:any)=>{ return {id: p.ID, name: p.Name, value: p.Name} });
            newPolicies = newPolicyObj.map((p:any)=>{ return `(${p.id}) ${p.name}` })
        } else if( type === 'active' ) {
            filteredRawPolicies = (rawPolicyStrategy?.config?.current_policies || []).filter((p:any)=>{return p.ID > 99 && p.NbRefs > 0 })
            newPolicyObj = filteredRawPolicies.map((p:any)=>{ return {id: p.ID, name: p.Name, value: p.Name} });
            newPolicies = newPolicyObj.map((p:any)=>{ return `(${p.id}) ${p.name}` })
        } else if( type === 'inactive' ) {
            filteredRawPolicies = (rawPolicyStrategy?.config?.current_policies || []).filter((p:any)=>{return p.ID > 99 && p.NbRefs < 1 })
            newPolicyObj = filteredRawPolicies.map((p:any)=>{ return {id: p.ID, name: p.Name, value: p.Name} });
            newPolicies = newPolicyObj.map((p:any)=>{ return `(${p.id}) ${p.name}` })
        }

        return {
            policy: newPolicies,
            policyObj: newPolicyObj
        }
    }

    console.log({policies})

    return(
        <>
            <div className={`p-6 grow flex flex-col `}>
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Backup Policies</h1>
                <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-bell-on', link: '', name: 'Home' },
                    { icon: '', link: '', name: 'Managed Services' },
                    { icon: '', link: '/endpoint', name: 'Endpoint Backup' },
                    { icon: '', link: '', name: 'Backup Policies' }
                ]} />
                <div className={`grow flex flex-col`}>
                    <div className={`grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full min-h-max mb-6 text-${theme.setThemeColor}-${theme.themeContrast}-component-text`}>
                        <div 
                            onClick={()=>{
                                setSelectedPolicies((prevState:any)=>{
                                    let newState = {...prevState}
                                    newState = getFilteredPolicies('all')
                                    return newState
                                })
                                setSelectedPolicy('')
                            }}
                            className="cursor-pointer"
                            >
                            <TotalProtectedPolicyCard rawPolicyStrategy={rawPolicyStrategy} />
                        </div>
                        <div 
                            onClick={()=>{
                                setSelectedPolicies((prevState:any)=>{
                                    let newState = {...prevState}
                                    newState = getFilteredPolicies('active')
                                    return newState
                                })
                                setSelectedPolicy('')
                            }}
                            
                            className="cursor-pointer"
                            >
                            <ActiveProtectionPolicyCard rawPolicyStrategy={rawPolicyStrategy} />
                        </div>
                        <div 
                            onClick={()=>{
                                setSelectedPolicies((prevState:any)=>{
                                    let newState = {...prevState}
                                    newState = getFilteredPolicies('inactive')
                                    return newState
                                })
                                setSelectedPolicy('')
                            }}

                            className="cursor-pointer"
                            >
                            <InactiveProtectionPolicyCard rawPolicyStrategy={rawPolicyStrategy} />
                        </div>
                    </div> 

                    <div className={`grid grid-flow-row-dense grid-cols-1 md:grid-cols-3 gap-6 w-full min-h-max mb-6 text-${theme.setThemeColor}-${theme.themeContrast}-component-text`}>
                        <div className="grow w-full col-span-1 md:col-span-3 lg:col-span-1">
                            <Tile colSpan={4} rowSpan={17} className="pb-4">
                                <div className="title text-base md:text-lg lg:text-xl text-gray-800 font-medium p-4 border rounded-t-lg">
                                    Protection Policies
                                    <div className="text-gray-500 text-sm">{policyFilterName[0].toUpperCase()}{policyFilterName.split('').splice(1,policyFilterName.length).join('')} policies</div>
                                </div>
                                <div className="flex flex-col w-full h-[420px] overflow-y-scroll overflow-x-hidden customScroll">
                                    {(selectedPolicies?.policy || []).map((p:any, pIndex:number)=>(
                                        <div 
                                            key={pIndex}
                                            onClick={()=>{ handlePolicySelect(p) }}
                                            className={`flex flex-col px-4 py-3 border-b hover:bg-slate-100 cursor-pointer border-l-4 ${selectedPolicy === p ? 'border-l-[#FF5E0E]' : 'border-l-[#FFFFFF] hover:border-l-slate-100'} `}
                                            onMouseOver={()=>{ setPolicyHoverIndex(pIndex) }}
                                            onMouseOut={()=>{ setPolicyHoverIndex(null) }}
                                        >
                                            <div className={`overflow-hidden`}>
                                                <div className={`${policyHoverIndex === pIndex ? 'animate-marquee' : '' } whitespace-nowrap`}>{p}</div>
                                            </div>
                                            <div className="text-slate-500 font-medium">
                                                Devices: <span className="text-[#ff5e0e] font-semibold">
                                                    {/* {
                                                        (rawPolicyStrategy?.config?.current_policies || [])
                                                            .reduce((a:number,d:any)=>{
                                                                // console.log({d})
                                                                // (allEndpoints || []).reduce((a:number, ep:any)=>{
                                                                if (ep?.agent_profile?.AdminInfos?.DataProfile?.ID || '').toString() === (d?.ID).toString() {
                                                                    a = a + 1
                                                                }
                                                                // },0)
                                                                // if( d.ID === parseInt(p.split('(')[1].split(')')[0]) ){
                                                                //     a = a + d.NbRefs;
                                                                // };
                                                                return a;
                                                            },0)
                                                    } */}
                                                    {(allEndpoints || [])
                                                    .filter((ep:any)=>{ return ep?.agent_profile?.AdminInfos?.Deletion === false })
                                                    .reduce((a:number, ep:any)=>{
                                                        if((ep?.agent_profile?.AdminInfos?.DataProfile?.ID || '').toString() === p.split('(')[1].split(')')[0]) {
                                                            a = a + 1
                                                        } else {
                                                            a = a + 0;
                                                        }
                                                        return a;
                                                    },0)}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Tile>
                        </div>

                        <div className="grid col-span-1 md:col-span-3 lg:col-span-2">
                            <Tile colSpan={8} rowSpan={17} className="flex flex-col pb-4 overflow-hidden">
                                <div className="title flex items-center justify-center align-middle p-4 border-b">
                                    <span className="flex flex-col">
                                        <div className="flex items-center">
                                            <span className="text-base md:text-lg lg:text-xl text-gray-800 font-medium">Policy Details</span>
                                            {policies?.profile?.BMR === 1 ? <span className="ml-4 text-xs font-bold text-white bg-[#ff5e0e] px-3 py-1 rounded-full">BMR ENABLED</span> : <></>}
                                        </div>
                                        <span className="text-slate-500 text-sm font">{selectedPolicy}</span>
                                    </span>
                                    <span className="grow"></span>
                                    {selectedPolicy && <Button
                                        onClick={()=>{
                                            sideDrawerContext.setFirstDrawerChild(<LevelOneDrawerComponent selectedPolicy={selectedPolicy} allEndpoints={allEndpoints} />)
                                            sideDrawerContext.setLevelOneDrawerVisibility(true)
                                        }}
                                        size="sm"
                                    >View Devices</Button>}
                                </div>
                                {
                                    selectedPolicy ? 
                                    (
                                        <div className="custom-table flex flex-col w-full grow">
                                            <div className="thead w-full border-b px-4 py-2 font-medium bg-slate-300 text-orange-500 bg-opacity-20">
                                                <div className="row flex w-full font-bold text-orange-500">
                                                    <div className="cell grow w-40 max-w-50 text-left font-semibold">Name</div>
                                                    <div className="cell grow w-40 max-w-80 text-left font-semibold">Path</div>
                                                    <div className="cell grow w-40 max-w-80 text-left font-semibold">Filter</div>
                                                </div>
                                            </div>
                                            <div className="tbody grow h-[390px] pb-3 md:h-[405px] w-full overflow-scroll customScroll">
                                                {(policies?.path || []).map((pathObj:any, pathIndex:number) => {

                                                    if( (pathObj?._ID || []).length < 1 ) {
                                                        return (
                                                            <div key={pathIndex} className="row flex items-center w-full border-b px-4 py-2 hover:bg-slate-100 text-sm text-gray-700">
                                                                <div className="cell grow w-36 max-w-50 text-left whitespace-nowrap truncate">{pathObj?.path?.Name || 'NA'}</div>
                                                                <div className="cell grow w-36 max-w-80 text-left whitespace-nowrap truncate">{pathObj?.path?.Path || 'NA'}</div>
                                                                <div className="cell flex flex-col grow w-40 max-w-80 text-left whitespace-nowrap pl-4">
                                                                    {/* { Object.keys( policies?.profile?.RuleArray || {}).length > 0 ? (behaviorText[ pathObj?.extensions?.Behavior]|| pathObj?.filter?.comment || "" ) : ''} */}
                                                                    {pathObj?.ID && <div className="">{ pathObj?.filter?.comment || 'NA' } {pathObj?.extensions?.Behavior === 'INCL' && '[Inclusion]'}{pathObj?.extensions?.Behavior === 'EXCL' && '[Exclusion]'}</div>}
                                                                    <span>
                                                                        <div className="inline-block px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 text-xs font-medium cursor-default">
                                                                            { Object.keys( policies?.profile?.RuleArray || {}).length > 0 ? 'All Extensions' : 'No Rule Applied' } 
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )
                                                    } else if( (pathObj?._ID || []).length > 0 ) {
                                                        return (pathObj?._ID || []).map((item: any, itemIndex: number)=>(
                                                            <div key={pathIndex} className="row flex items-center w-full border-b px-4 py-2 hover:bg-slate-100 text-sm text-gray-700">
                                                                <div className="cell grow w-36 max-w-50 text-left whitespace-nowrap truncate">{pathObj?.path?.Name || 'NA'}</div>
                                                                <div className="cell grow w-36 max-w-80 text-left whitespace-nowrap truncate">{pathObj?.path?.Path || 'NA'}</div>
                                                                <div className="cell flex flex-col grow w-40 max-w-80 text-left whitespace-nowrap pl-4">
                                                                    <div className="">{ pathObj?.filter?.comment || 'NA' } {(pathObj?.extensions?.Behavior === 'INCL' || policies?.extensions[item]?.Behavior === 'INCL') && '[Inclusion]'}{(pathObj?.extensions?.Behavior === 'EXCL' || policies?.extensions[item]?.Behavior === 'EXCL' ) && '[Exclusion]'}</div>
                                                                    <div className="mb-2">({ policies?.extensions[item]?.Name || '' })</div>
                                                                    <div>
                                                                        {(policies.extensions[item]?.Ext || []).map((extItem:string, extIndex: number)=>{
                                                                            return (
                                                                                <div 
                                                                                    key={`${pathIndex}_${itemIndex}_${extIndex}`} 
                                                                                    className="float-left px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 mb-1 text-xs font-medium cursor-default"
                                                                                >
                                                                                    {extItem}
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="py-6 flex flex-col justify-center items-center text-center align-middle">
                                            <img src="/assets/img/well_done.svg" alt="Placeholder" className="h-52 mt-6"/>
                                            <div className="text-lg font-semibold text-slate-500 mt-10 relative">
                                                <span className="">Please select Protection Policy from given policy list</span>
                                                <i className={`fa-solid fa-hand-point-left hidden lg:block absolute text-3xl  -top-1 text-[#FF5E0E] bounceLeft`}/>
                                            </div>
                                        </div>
                                    )
                                }
                            </Tile>
                        </div>                                    
                    </div>
                </div>
            </div> 
                
                    
                    
        </>
    )
}

export default EndpointPolicy

interface ILevelOneDrawerComponent {
    selectedPolicy: any;
    allEndpoints: any[];
}

const LevelOneDrawerComponent:FC<ILevelOneDrawerComponent> = ({selectedPolicy, allEndpoints}) => {

    const navigator = useNavigate()
    const sideDrawerContext = useContext(SideDrawerContext)
    const [hovering, setHovering] = useState(false);
    const [policyId, setPolicyId] = useState<string>(selectedPolicy.split('(')[1].split(')')[0])
    // const [allEndpoints, setAllEndpoints] = useState<any[]>([]);
    const [selectedEndpoints, setSelectedEndpoints] = useState<any[]>([]);
 
    useEffect(()=>{
        if( allEndpoints.length > 1 ) {
            let filteredEndPoints = (allEndpoints || [])
            .filter((ep:any)=>{ return ep?.agent_profile?.AdminInfos?.Deletion === false })
            .filter((ep:any)=>{ return (ep?.agent_profile?.AdminInfos?.DataProfile?.ID || '').toString() === policyId });
            console.log({allEndpoints,policyId,filteredEndPoints})
            setSelectedEndpoints(filteredEndPoints)
        }

    },[allEndpoints])

    return (
        <>
            <div className="h-full flex flex-col">
                <div className="title w-full flex gap-4 px-6 top-0 pt-10 border-b pb-10 bg-slate-100 items-center">
                    {/* Icon */}
                    <i className="fa-regular fa-tv text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i>

                    {/* title */}
                    <div className="flex flex-col">
                        <div className="text-xl font-bold text-slate-700">Endpoint Devices</div>
                        {/* Sub title */}
                        <div className="block text-slate-500">
                            <div className="w-80 whitespace-nowrap overflow-hidden"
                                onMouseOver={()=>{ setHovering(true); }}
                                onMouseOut={()=>{ setHovering(false); }}
                            ><div className={`${hovering ? 'animate-marquee' : ''}`}>{selectedPolicy || 'NA'}</div></div>
                            {/* <Link to="/endpoint/devices"> */}
                                {/* <span>{selectedPolicy}</span> */}
                                {/* <em className="fa-solid fa-link ml-2 text-sm"></em> */}
                            {/* </Link> */}
                        </div>
                    </div>
                </div>
                <div className="data grow top-40 px-4 w-full py-6">
                    {/* <Accordion className="rounded-lg overflow-hidden"> */}
                        {/* <AccordionItem className="text-sm" maxHeight='h-fit' title={<span><em className="fa-regular fa-list-check mr-4 text-[#f97315]"></em> All ({(selectedEndpoints || []).length})</span>}> */}
                            {(selectedEndpoints || []).length > 0 ? (
                                <div className={`table w-full`}>
                                    <div className={`thead bg-slate-200 w-full py-2 px-2 mt-10 flex flex-row items-center text-orange-500 rounded-t-md`}>
                                        <div className="row text-gray-800 text-sm flex">
                                            <div className="cell text-left w-16 pl-4">#</div>
                                            <div className="cell text-left w-16 -ml-2">OS</div>
                                            <div className="cell text-left grow">Device Name</div>
                                        </div>
                                    </div>
                                    <div className={`tbody overflow-y-scroll customScroll h-[60vh] bg-white w-full flex flex-col items-center text-slate-600 rounded-b-md border border-t-0`}>
                                        {(selectedEndpoints || []).length > 0 && (selectedEndpoints || []).map((endpoint:any, endpointIndex:number)=>(
                                            <div key={endpointIndex} className="row text-sm font-sm flex border-b border-blue-200 w-full py-2 hover:bg-slate-100">
                                                <div
                                                    className="cell w-16 pl-4 cursor-pointer text-orange-500"
                                                    onClick={()=>{
                                                        navigator(`/endpoint/devices/profile/${endpoint?._id || ''}`)
                                                        sideDrawerContext.setLevelOneDrawerVisibility(false)
                                                    }}
                                                >{endpoint?.agent_id || ''}</div>
                                                <div className="cell text-slate-500 w-16">{deviceSymbol(endpoint?.agent_profile?.AgentInfos?.System || '')}</div>
                                                <div className="cell grow">{endpoint?.agent_profile?.AdminInfos?.Name || ''}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) :
                            (
                                <div className="flex flex-col p-8 mt-6">
                                    <img src="/assets/img/well_done.svg" alt="placeholder" className=""/>
                                    <div className="mt-6 flex item-center justify-center text-center text-slate-500">No data found</div>
                                </div>
                            )
                        }
                        {/* </AccordionItem> */}
                    {/* </Accordion> */}
                </div>
            </div>
        </>
    )
}