import React, { Dispatch, SetStateAction, ReactNode, useState } from "react";

interface IAnyComponentContext {
    libraryComponents: IComponent[];
    setLibraryComponents: Dispatch<SetStateAction<IComponent[]>>;
}

export const LibraryContext = React.createContext({} as IAnyComponentContext);

interface ILibraryContextProp {
    children: ReactNode | ReactNode[];
}

export interface ILinaMetronicTileData {
    title: string, color: string, value: string
}

export interface ISimpleTileData {
    title: string, amount: string, trend: string, growth: string
}

export interface IComponent {
    id: number;
    name: string;
    type: string;
    variant: string;
    description: string;
    colSpan: number;
    rowSpan: number;
    connector: object;
    data: ISimpleTileData | ILinaMetronicTileData;
    role: string[];
}

const LibraryProvider = (props: ILibraryContextProp) => {

    const [libraryComponents, setLibraryComponents] = useState<IComponent[]>([
        // { id: 1, name: 'Lina Active Backups', type: 'tile', variant: 'LinaMetronicTile', description: '', colSpan: 4, rowSpan: 4, connector:{},  data:{ title:'Active Backups', color: 'green-600', value:'0 Devices', }, role: [''] },
        // { id: 2, name: 'Lina Active Client Restore', type: 'tile', variant: 'LinaMetronicTile', description: '', colSpan: 4, rowSpan: 4, connector:{},  data:{ title:'Active Client Restore', color: 'red-600', value:'0 Devices', }, role: [''] },
        // { id: 3, name: 'Lina Active Web Restore', type: 'tile', variant: 'LinaMetronicTile', description: '', colSpan: 4, rowSpan: 4, connector:{},  data:{ title:'Active Web Restore', color: 'violet-600', value:'0 Devices', }, role: [''] },
        { id: 4, name: 'Lina Devices', type: 'tile', variant: 'LinaMetronicTile', description: '', colSpan: 4, rowSpan: 4, connector:{ method: 'get', endpoint: '/products/lina/backup', filter: {}}, data:{ title:'Lina Devices', color: 'green-600', value:'8 / 10 Devices', }, role: [''] },
        { id: 5, name: 'Lina Protected Data', type: 'tile', variant: 'LinaMetronicTile', description: '', colSpan: 4, rowSpan: 4, connector:{},  data:{ title:'Lina Protected Data', color: 'green-600', value:'73.1 GB / 100 GB', }, role: [''] },
        { id: 6, name: 'Lina Additional Storage', type: 'tile', variant: 'LinaMetronicTile', description: '', colSpan: 4, rowSpan: 4, connector:{},  data:{ title:'Additional Storage', color: 'green-600', value:'0 B /0 B', }, role: [''] },
        { id: 7, name: 'Lina Restore Data', type: 'tile', variant: 'LinaMetronicTile', description: '', colSpan: 4, rowSpan: 4, connector:{},  data:{ title:'Lina Restore Data', color: 'green-600', value:'93.2 GB', }, role: [''] },
        { id: 8, name: 'Lina Device Pending Installation', type: 'tile', variant: 'LinaMetronicTile', description: '', colSpan: 4, rowSpan: 4, connector:{},  data:{ title:'Device Pending Installation', color: 'yellow-600', value:'2 Devices', }, role: [''] },
        { id: 9, name: 'Lina Unprotected Data', type: 'tile', variant: 'LinaMetronicTile', description: '', colSpan: 4, rowSpan: 4, connector:{},  data:{ title:'Unprotected Data', color: 'red-600', value:'6 Devices', }, role: [''] },
        { id: 10, name: 'Lina Deleted Devices', type: 'tile', variant: 'LinaMetronicTile', description: '', colSpan: 4, rowSpan: 4, connector:{},  data:{ title:'Deleted Devices', color: 'red-600', value:'1 Devices', }, role: [''] },
        
        { id: 11, name: 'Alerts Tile', type: 'tile', variant: 'SimpleTile', description: '', colSpan: 4, rowSpan: 4, connector:{},  data:{ title:'Alerts', amount:'$25,980', trend:'up', growth:'15.6%' }, role: [''] },
        { id: 12, name: 'Protected Data Tile', type: 'tile', variant: 'SimpleTile', description: '', colSpan: 4, rowSpan: 4, connector:{},  data:{ title:'Protected Data', amount:'$25,980', trend:'up', growth:'15.6%' }, role: [''] },
        { id: 13, name: 'Total Restored Volume Tile', type: 'tile', variant: 'SimpleTile', description: '', colSpan: 4, rowSpan: 4, connector:{},  data:{ title:'Total Restored Volume', amount:'$25,980', trend:'up', growth:'15.6%' }, role: [''] },
        { id: 14, name: 'Clients Tile', type: 'tile', variant: 'SimpleTile', description: '', colSpan: 4, rowSpan: 4, connector:{},  data:{ title:'Clients', amount:'$25,980', trend:'up', growth:'15.6%' }, role: [''] },
        { id: 15, name: 'Server Tile', type: 'tile', variant: 'SimpleTile', description: '', colSpan: 4, rowSpan: 4, connector:{},  data:{ title:'Servers', amount:'$25,980', trend:'up', growth:'15.6%' }, role: [''] },
        { id: 16, name: 'Notification Tile', type: 'tile', variant: 'SimpleTile', description: '', colSpan: 4, rowSpan: 4, connector:{},  data:{ title:'Notifications', amount:'$25,980', trend:'up', growth:'15.6%' }, role: [''] },
        { id: 17, name: 'Clients of Client Tile', type: 'tile', variant: 'SimpleTile', description: '', colSpan: 4, rowSpan: 4, connector:{},  data:{ title:'Clients', amount:'$25,980', trend:'up', growth:'15.6%' }, role: [''] },
        { id: 18, name: 'Server of Client Tile', type: 'tile', variant: 'SimpleTile', description: '', colSpan: 4, rowSpan: 4, connector:{},  data:{ title:'Servers', amount:'$25,980', trend:'up', growth:'15.6%' }, role: [''] },
        { id: 19, name: 'Protected Data of Client Tile', type: 'table', variant: 'SimpleTile', description: '', colSpan: 12, rowSpan: 4, connector:{},  data:{ title:'Protected Data', amount:'$25,980', trend:'up', growth:'15.6%' }, role: [''] },
        { id: 20, name: 'Agents of Client Tile', type: 'form', variant: 'SimpleTile', description: '', colSpan: 4, rowSpan: 8, connector:{}, data:{ title:'Agents', amount:'$25,980', trend:'up', growth:'15.6%' }, role: [''] },
    ]);

    return (
      <LibraryContext.Provider value={{libraryComponents, setLibraryComponents}}>
        {props.children}
      </LibraryContext.Provider>
    )
}

export default LibraryProvider;