import React from "react";
import { Tile } from "../../../core";

const Tile51 = () =>{
    return (
        <>
            <Tile rowSpan={15} colSpan={3} className="p-5">
                <div className="flex flex-col h-full text-[#3A3541]">
                    <div className="flex flex-row justify-between w-full">
                        <div className={`text-base leading-6 mb-5 font-semibold`}>Total profit</div>
                        <i className="fa-solid fa-ellipsis-vertical text-center justify-center items-start flex"></i>
                    </div>
                    <i className=" fa-light fa-atom-simple text-9xl border h-full text-center items-center flex justify-center py-5"></i>
                    <div className="w-full text-center flex flex-col items-center justify-center">
                        <div className="text-xs text-center mb-[10px] leading-5 font-regular mt-5">18k new sales</div>
                        <div className="px-2 rounded-full text-[#FF5E0E] bg-[#e5dcf5] text-center justify-center flex items-center align-middle min-w-min text-sm leading-5 font-medium">This year</div> 
                    </div>
                </div>
            </Tile>
        </>
    )
}

export default Tile51