/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb } from "../../components/core";
// import { ThemeContext } from "../../contexts";
import { ITableControllerProps } from "../../components/core/Table/Table";
import { useParams } from 'react-router-dom';
import { getAPICall, postAPICall } from "../../services/APILayer/axiosMethodCalls";
import {
    getAgentPolicyDetails
} from "../../utils/agentUtils";
import { 
    DeviceDetails,
    TotalProtectedDataCard,
    TotalRestoreDataCard,
    BackupTodayCard,
    RestoreTodayCard,
    BackupRestoreTrendGraph,
    DeviceStrategyCard,
    GlobalFileExtension,
    DevicePolicy
} from "./EndpointDeviceProfile/index";

const EndpointDeviceProfile = ( ) =>{
    const { deviceId } = useParams();
    const scrollRef = useRef<HTMLDivElement|null>(null)

    const pageTop = useRef<HTMLDivElement>(null)

    // const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    // const navRaf = useRef<HTMLDivElement>(null);

    const [endpointProfile, setEndpointProfile] = useState<any>(null)
    const [backupTodaysData, setBackupTodaysData] = useState<any>(null)
    const [restoreTodaysData, setRestoreTodaysData] = useState<any>(null)

    const [trendCallingActive, setTrendCallingActive] = useState<boolean>(true);
    const [trendSelection, setTrendSelection] = useState<string>('backup');
    const [trendPeriod, setTrendPeriod] = useState<string>('1d');
    // const [graphData, setGraphData] = useState<{[key: string]:any}>({});

    const [strategyData, setStrategyData] = useState<any>(null);
    const [policyData, setProfileData] = useState<any>(null);


    // const [graphLive, setGraphLive] = useState(false)

    const [tableController, setTableController] = useState<ITableControllerProps>({
        data: [],
        loader: false,
        currentPage: 1,
        offset: 0,
        limit: 5,
        totalRecords: 10,
        maxVisiblePaginationButtons: 3,
        searchTerm: '',
        filter: {},
        sorting: {
            column: '',
            direction: null
        }
    });
    // const [tableColumns, setTableColumns] = useState<ITableColumnItem[]>([
    //     {
    //         id: 1,
    //         name: 'Device Id',
    //         column: 'device_id',
    //         textAlign: 'text-left',
    //         textColor: 'text-gray-500',
    //         visibility: true
    //     },
    //     {
    //         id: 2,
    //         name: 'Device',
    //         column: 'device',
    //         textAlign: 'text-left',
    //         textColor: 'text-gray-500',
    //         visibility: true
    //     },
    //     {
    //         id: 3,
    //         name: 'Device Status',
    //         column: 'device_status',
    //         textAlign: 'text-left',
    //         textColor: 'text-gray-500',
    //         visibility: true
    //     },
        
    //     {
    //         id: 4,
    //         name: 'Last Connection',
    //         column: 'last_connection',
    //         textAlign: 'text-left',
    //         textColor: 'text-gray-500',
    //         visibility: true
    //     },
    //     {
    //         id: 5,
    //         name: 'Last Backup',
    //         column: 'last_backup',
    //         textAlign: 'text-left',
    //         textColor: 'text-gray-500',
    //         visibility: true
    //     },
    //     {
    //         id: 6,
    //         name: 'Protected Data',
    //         column: 'protected_data',
    //         textAlign: 'text-left',
    //         textColor: 'text-gray-500',
    //         visibility: true
    //     },
        
    // ]);

    const [detailOpen,setDetailOpen]=useState(true)

    // API Calls
    const getEndpointProfile = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints/${deviceId}`);
            return response.data;
        } catch(error) {
            return error;
        }
    }
    const getBackupRestoreData = async (filter: any) => {
        try {
            const response = await postAPICall(
                `${process.env.REACT_APP_API_URL}/analytics/endpoints_recent_activities/${deviceId}`,
                {
                    filter: {
                        ...filter
                    }
                }
            );
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        pageTop.current?.scrollIntoView({ behavior: "smooth" })
    },[])
    
    useEffect(()=>{
        if( !deviceId ){
            return;
        }

        const fetchPageData = async () => {

            let pageData = await Promise.all([
                getEndpointProfile(),
                getBackupRestoreData({
                    range: "1d",
                    window: "1d",
                    query: "backup",
                    query_type: 1
                }),
                getBackupRestoreData({
                    range: "1d",
                    window: "1d",
                    query: "restore",
                    query_type: 1
                }),
            ])

            setEndpointProfile(pageData[0]); // endpoint
            setBackupTodaysData(pageData[1]); // todaysBackup
            setRestoreTodaysData(pageData[2]); // todaysRestore

            console.log({pageData},{endpointProfile: pageData[0]})

            let policy = getAgentPolicyDetails(pageData[0]);
            console.log({_policy:policy})
            setProfileData(policy);

            // setTimeout(() => {
            //     let policy = getAgentPolicyDetails(pageData[0]);
            //     console.log({_policy:policy})
            //     setProfileData(policy);
            // }, 500);
        }

        fetchPageData()
    },[deviceId]);

    useEffect(()=>{

        if( endpointProfile === null ) {
            return;
        }
        
        let devicePolicy = endpointProfile?.endpoint?.agent_profile?.AdminInfos?.DataProfile;
        let deviceStrategy = endpointProfile?.endpoint?.agent_profile?.AdminInfos?.ServiceProfile;

        let policyInfo = (endpointProfile?.endpoint?.metadata?.protectionPolicyData?.DataProfileArray?.DataProfile || []).find((p:any)=>{ return p.ID === devicePolicy.ID })
        let strategyInfo = (endpointProfile?.endpoint?.metadata?.strategyData?.HNConfig?.ServiceProfileArray?.ServiceProfile || []).find((s:any)=>{ return s.ID === deviceStrategy.ID });

        // setProfileData(policyInfo)
        setStrategyData(strategyInfo)

        console.log({endpointProfile},{policyInfo},{strategyInfo})

    },[endpointProfile])

    // useEffect(()=>{
    //     if(!trendCallingActive) {
    //         return;
    //     }
    //     const fetchTrendData = async () => {
    //         await getBackupRestoreData({
    //             range: trendPeriod,
    //             window: trendPeriod === '1d' ? '1h' : '1d',
    //             query: trendSelection,
    //             query_type: trendPeriod === '1d' ? 2 : 1
    //         }).then((data)=>{
    //             console.log({trendData:data});

    //             // data: [2,4,6,2, 3,4,6],
    //             // label: 'Dataset 1'
    //             // labels={['January','February','March','April','May','June','July']}

    //             let chartOptions:{[key: string]:any} = {
    //                 dataset: {
    //                     source: []
    //                 },
    //                 tooltip: {
    //                     formatter: ()=>{ return '' }
    //                 },
    //                 legend: {
    //                     data: []
    //                 },
    //                 series: []
    //             }

    //             if( trendSelection === 'backup' && trendPeriod === '1d' ) {
    //                 for (let i = 0; i < data?.activities.length; i++) {
    //                     if( i === 0 ) {
    //                         chartOptions.dataset.source.push(['time', 'Backup', 'Change'])
    //                     }
    //                     chartOptions.dataset.source.push([
    //                         getFormattedDateTime(data?.activities[i]?._time),
    //                         data?.activities[i]?._original,
    //                         data?.activities[i]?._value,
    //                     ])
    //                 }
    //                 chartOptions.tooltip.formatter = (params: any)=>{
    //                     return (
    //                         `
    //                           <div style="text-align: left">
    //                             <strong>${params[0].data[0]}</strong><br>
    //                             <i class="fa-solid fa-circle" style="font-size: 12px; color: #ff5e0e"></i> <strong>${params[0].dimensionNames[1]}:</strong> ${params[0].data[1]} MB<br>
    //                             <i class="fa-solid fa-circle" style="font-size: 12px; opacity: 0.5; color: #ff5e0e"></i> <strong>${params[0].dimensionNames[2]}:</strong> ${params[0].data[2]} MB
    //                           </div>
    //                         `
    //                       );
    //                 }
    //                 chartOptions.legend.data = ["Backup","change"]
    //                 chartOptions.series = [
    //                     {
    //                         name: "Backup",
    //                         id: 'line1',
    //                         type: "line",
    //                         stack: "total",
    //                         smooth: true,
    //                         symbolSize: 20,
    //                         label: {
    //                             show: true,
    //                             opacity: 1,
    //                         },
    //                         itemStyle: {
    //                             color: '#ff5e0e',
    //                             opacity: 1
    //                         },
    //                         lineStyle: {
    //                             color: '#ff5e0e',
    //                             opacity: 1,
    //                         }
    //                     },{
    //                         name: "Change",
    //                         id: 'line2',
    //                         type: "line",
    //                         stack: "total",
    //                         smooth: true,
    //                         symbolSize: 20,
    //                         label: {
    //                           show: true,
    //                           opacity: 1,
    //                         },
    //                         itemStyle: {
    //                           color: '#ff5e0e',
    //                           opacity: 0.5
    //                         },
    //                         lineStyle: {
    //                           color: '#ff5e0e',
    //                           opacity: 0.5,
    //                         }
    //                     }
    //                 ]
    //             }

    //             if( trendSelection === 'backup' && trendPeriod !== '1d' ) {
    //                 for (let i = 0; i < data?.activities.length; i++) {
    //                     if( i === 0 ) {
    //                         chartOptions.dataset.source.push(['time', 'Backup'])
    //                     }
    //                     chartOptions.dataset.source.push([
    //                         getFormattedDateTime(data?.activities[i]?._start),
    //                         data?.activities[i]?._value,
    //                     ])
    //                 }
    //                 chartOptions.tooltip.formatter = (params: any)=>{
    //                     return (
    //                         `
    //                           <div style="text-align: left">
    //                             <strong>${params[0].data[0]}</strong><br>
    //                             <i class="fa-solid fa-circle" style="font-size: 12px; color: #ff5e0e"></i> <strong>${params[0].dimensionNames[1]}:</strong> ${params[0].data[1]} MB
    //                           </div>
    //                         `
    //                       );
    //                 }
    //                 chartOptions.legend.data = ["Backup"]
    //                 chartOptions.series = [
    //                     {
    //                         name: "Backup",
    //                         id: 'line1',
    //                         type: "line",
    //                         stack: "total",
    //                         smooth: true,
    //                         symbolSize: 20,
    //                         label: {
    //                             show: true,
    //                             opacity: 1,
    //                         },
    //                         itemStyle: {
    //                             color: '#ff5e0e',
    //                             opacity: 1
    //                         },
    //                         lineStyle: {
    //                             color: '#ff5e0e',
    //                             opacity: 1,
    //                         }
    //                     }
    //                 ]
    //             }

    //             if( trendSelection === 'restore' && trendPeriod === '1d' ) {
    //                 for (let i = 0; i < data?.activities.length; i++) {
    //                     if( i === 0 ) {
    //                         chartOptions.dataset.source.push(['time', 'Restore', 'Change'])
    //                     }
    //                     chartOptions.dataset.source.push([
    //                         getFormattedDateTime(data?.activities[i]?._time),
    //                         data?.activities[i]?._original,
    //                         data?.activities[i]?._value,
    //                     ])
    //                 }
    //                 chartOptions.tooltip.formatter = (params: any)=>{
    //                     return (
    //                         `
    //                           <div style="text-align: left">
    //                             <strong>${params[0].data[0]}</strong><br>
    //                             <i class="fa-solid fa-circle" style="font-size: 12px; color: #ff5e0e"></i> <strong>${params[0].dimensionNames[1]}:</strong> ${params[0].data[1]} MB<br>
    //                             <i class="fa-solid fa-circle" style="font-size: 12px; opacity: 0.5; color: #ff5e0e"></i> <strong>${params[0].dimensionNames[2]}:</strong> ${params[0].data[2]} MB
    //                           </div>
    //                         `
    //                       );
    //                 }
    //                 chartOptions.legend.data = ["Restore","change"]
    //                 chartOptions.series = [
    //                     {
    //                         name: "Restore",
    //                         id: 'line1',
    //                         type: "line",
    //                         stack: "total",
    //                         smooth: true,
    //                         symbolSize: 20,
    //                         label: {
    //                             show: true,
    //                             opacity: 1,
    //                         },
    //                         itemStyle: {
    //                             color: '#ff5e0e',
    //                             opacity: 1
    //                         },
    //                         lineStyle: {
    //                             color: '#ff5e0e',
    //                             opacity: 1,
    //                         }
    //                     },{
    //                         name: "Change",
    //                         id: 'line2',
    //                         type: "line",
    //                         stack: "total",
    //                         smooth: true,
    //                         symbolSize: 20,
    //                         label: {
    //                           show: true,
    //                           opacity: 1,
    //                         },
    //                         itemStyle: {
    //                           color: '#ff5e0e',
    //                           opacity: 0.5
    //                         },
    //                         lineStyle: {
    //                           color: '#ff5e0e',
    //                           opacity: 0.5,
    //                         }
    //                     }
    //                 ]
    //             }

    //             if( trendSelection === 'restore' && trendPeriod !== '1d' ) {
    //                 for (let i = 0; i < data?.activities.length; i++) {
    //                     if( i === 0 ) {
    //                         chartOptions.dataset.source.push(['time', 'Restore'])
    //                     }
    //                     chartOptions.dataset.source.push([
    //                         getFormattedDateTime(data?.activities[i]?._time),
    //                         data?.activities[i]?._value,
    //                     ])
    //                 }
    //                 chartOptions.tooltip.formatter = (params: any)=>{
    //                     return (
    //                         `
    //                           <div style="text-align: left">
    //                             <strong>${params[0].data[0]}</strong><br>
    //                             <i class="fa-solid fa-circle" style="font-size: 12px; color: #ff5e0e"></i> <strong>${params[0].dimensionNames[1]}:</strong> ${params[0].data[1]} MB
    //                           </div>
    //                         `
    //                       );
    //                 }
    //                 chartOptions.legend.data = ["Restore"]
    //                 chartOptions.series = [
    //                     {
    //                         name: "Restore",
    //                         id: 'line1',
    //                         type: "line",
    //                         stack: "total",
    //                         smooth: true,
    //                         symbolSize: 20,
    //                         label: {
    //                             show: true,
    //                             opacity: 1,
    //                         },
    //                         itemStyle: {
    //                             color: '#ff5e0e',
    //                             opacity: 1
    //                         },
    //                         lineStyle: {
    //                             color: '#ff5e0e',
    //                             opacity: 1,
    //                         }
    //                     }
    //                 ]
    //             }

    //             // for (let i = 0; i < data?.activities.length; i++) {
    //             //     if( trendSelection === 'backup' && trendPeriod === '1d' ) {
    //             //         if( i === 0 ) {
    //             //             chartOptions.dataset.source.push(['time', 'Backup', 'Change'])
    //             //         }
    //             //         chartOptions.dataset.source.push([
    //             //             data?.activities[i]?._time,
    //             //             data?.activities[i]?._original,
    //             //             data?.activities[i]?._value,
    //             //         ])
    //             //         // syntheticGraphData.current.data.push(data?.activities[i]?._original);
    //             //         // syntheticGraphData.current.labels.push(data?.activities[i]?._time ? getFormattedDateTime(data?.activities[i]?._time || '') : getFormattedDateTime(data?.activities[i]?._start));
    //             //         // syntheticGraphData.change.data.push(data?.activities[i]?._value);
    //             //         // syntheticGraphData.change.labels.push(data?.activities[i]?._time ? getFormattedDateTime(data?.activities[i]?._time || '') : getFormattedDateTime(data?.activities[i]?._start));
    //             //     }

    //             //     if( trendSelection === 'backup' && trendPeriod !== '1d'  ) {
    //             //         // syntheticGraphData.current.data.push(data?.activities[i]?._value || '');
    //             //         // syntheticGraphData.current.labels.push(data?.activities[i]?._start ? getFormattedDateTime(data?.activities[i]?._start || '')?.split(',')[0] : getFormattedDateTime(data?.activities[i]?._start)?.split(',')[0]);
    //             //     }

    //             //     if( trendSelection === 'restore' && trendPeriod === '1d' ) {
    //             //         // syntheticGraphData.current.data.push(data?.activities[i]?._original);
    //             //         // syntheticGraphData.current.labels.push(data?.activities[i]?._time ? getFormattedDateTime(data?.activities[i]?._time || '') : getFormattedDateTime(data?.activities[i]?._start));
    //             //         // syntheticGraphData.change.data.push(data?.activities[i]?._value);
    //             //         // syntheticGraphData.change.labels.push(data?.activities[i]?._time ? getFormattedDateTime(data?.activities[i]?._time || '') : getFormattedDateTime(data?.activities[i]?._start));
    //             //     }

    //             //     if( trendSelection === 'restore' && trendPeriod !== '1d'  ) {
    //             //         // syntheticGraphData.current.data.push(data?.activities[i]?._value || '');
    //             //         // syntheticGraphData.current.labels.push(data?.activities[i]?._time ? getFormattedDateTime(data?.activities[i]?._time || '')?.split(',')[0] : getFormattedDateTime(data?.activities[i]?._start)?.split(',')[0]);
    //             //     }


    //             //     // if( data?.activities[i]?._original ) { // granularity 2
    //             //     //     syntheticGraphData.current.data.push(data?.activities[i]?._original);
    //             //     //     syntheticGraphData.current.labels.push(data?.activities[i]?._time ? getFormattedDateTime(data?.activities[i]?._time || '') : data?.activities[i]?._start);
    //             //     //     syntheticGraphData.change.data.push(data?.activities[i]?._value);
    //             //     //     syntheticGraphData.change.labels.push(data?.activities[i]?._time ? getFormattedDateTime(data?.activities[i]?._time || '') : data?.activities[i]?._start);
    //             //     // } else { // granularity 1
    //             //     //     // syntheticGraphData.current.data.push(data?.activities[i]?._original);
    //             //     //     // syntheticGraphData.current.labels.push(data?.activities[i]?._time ? getFormattedDateTime(data?.activities[i]?._time || '') : data?.activities[i]?._start);
    //             //     //     // syntheticGraphData.change.data.push(data?.activities[i]?._value);
    //             //     //     // syntheticGraphData.change.labels.push(data?.activities[i]?._time ? getFormattedDateTime(data?.activities[i]?._time || '') : data?.activities[i]?._start);

    //             //     //     syntheticGraphData.current.data.push(data?.activities[i]?._value || '');
    //             //     //     // syntheticGraphData.current.labels.push(data?.activities[i]?._time ? getFormattedDateTime(data?.activities[i]?._time || '') : data?.activities[i]?._time);
    //             //     //     syntheticGraphData.current.labels.push(data?.activities[i]?._start ? getFormattedDateTime(data?.activities[i]?._start || '') : data?.activities[i]?._start);
    //             //     // }
    //             //     // console.log({syntheticGraphData})
    //             //     // data?.activities?._start
    //             //     // data?.activities?._time
    //             //     // data?.activities?._value
    //             //     // data?.activities?._original
    //             // }

    //             setGraphData(chartOptions);
    //         }).catch((err)=>{
    //             console.log(err)
    //         }).finally(()=>{
    //             setTrendCallingActive(false);
    //         })
    //     }
    //     fetchTrendData();
    // },[trendSelection, trendPeriod, graphLive])

    // const resetGraphData = () => {
    //     setGraphData({
    //         dataset: {
    //             source: []
    //         },
    //         tooltip: {
    //             formatter: ()=>{ return '' }
    //         },
    //         legend: {
    //             data: []
    //         },
    //         series: []
    //     })
    // }

    const DetailOpenHandler = ()=>{
        return(
            setDetailOpen(!detailOpen)
        )
    }
    
    // const actionButtonGenerator = (row: object) => {
    //     return <>
    //         <button aria-label='edit'
    //             onClick={()=>{

    //             }}
    //         ><em className='fa-regular fa-pen text-[#3A3541] opacity-80 mr-2'></em></button>
    //         <button aria-label='view'
    //             onClick={()=>{
                    
    //             }}
    //         ><em className='fa-regular fa-eye text-[#3A3541] opacity-80'></em></button>
    //     </>
    // }

    // const tableDataFormatter = (rowObj: any, format?: string | undefined) => {
    //     let returningVal = null;
    //     switch (format) {
    //         case 'Device Id':
    //             returningVal = (<>
    //                 <span className="text-sm hover:text-orange-600 font-medium text-[#FF5e0e] opacity-80 cursor-pointer ">{rowObj}</span>
    //             </>);
    //             break;

    //         case 'Device':
    //             returningVal=(
    //                 <span className="text-sm hover:text-gray-900 font-medium text-[#3A3541] opacity-80 cursor-pointer  ">{rowObj}</span>
    //                 );
    //             break;
            
    //         case 'Device Status':
    //             returningVal=(
    //                 <>
    //                 {rowObj === 'Protected' && <div className="text-xs px-3 py-1 text-green-500 font-light bg-green-500 bg-opacity-10 max-w-min rounded-sm">{rowObj}</div>}  
    //                 {rowObj === 'Unprotected' && <div className="text-xs px-3 py-1 text-red-500 font-light bg-red-500 bg-opacity-10 max-w-min rounded-sm">{rowObj}</div>}  
    //             </>
    //             );
    //             break;

    //         case 'Last Connection':
    //             returningVal=(
    //                 <span className="text-sm hover:text-gray-900 font-medium text-[#3A3541] opacity-80">{rowObj}</span>

    //             );
    //             break;

    //             case 'Last Backup ':
    //                 returningVal=(
    //                     <span className="text-sm hover:text-gray-900 font-medium text-[#3A3541] opacity-80">{rowObj}</span>
    //               );
    //             break;

    //         case 'Protected Data':
    //               returningVal=(
    //                 <>
    //                 <span className="text-sm font-medium text-green-500 opacity-80 cursor-pointer text-center flex justify-center ">{rowObj}</span>
    //                 </>
    //             );
    //             break;

    //          default:
    //             returningVal = rowObj;
    //             break;
    //     }
    //     return returningVal;
    // }

    // const handleSwitch = () => {}

    useEffect(()=>{
        if( tableController.data.length > 0 ) {
            return;
        }

        setTableController({
            ...tableController,
            data: [{
                id: 1,
                device_id: "123",
                device:"UserPc",
                device_status:"Unprotected",
                last_connection: "3 Months ago",
                last_backup: "3 Months ago",
                protected_data: "3 GB",
            },
            {
                id: 2,
                device_id: "223",
                device:"Win",
                device_status:"Unprotected",
                last_connection: "3 Months ago",
                last_backup: "3 Months ago",
                protected_data: "2 GB",
            },
            {
                id: 3,
                device_id: "223",
                device:"Mac12",
                device_status:"Protected",
                last_connection: "1 Months ago",
                last_backup: "1 Months ago",
                protected_data: "30 GB",
            },
            {
                id: 3,
                device_id: "223",
                device:"Mac12",
                device_status:"Protected",
                last_connection: "1 Months ago",
                last_backup: "1 Months ago",
                protected_data: "30 GB",
            },
            {
                id: 3,
                device_id: "223",
                device:"Mac12",
                device_status:"Protected",
                last_connection: "1 Months ago",
                last_backup: "1 Months ago",
                protected_data: "30 GB",
            },
            
            ]
        })

    },[tableController.data]);

    return (
        <>
            <div className={`p-6 grow flex flex-col`} ref={pageTop}>
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2 items-center flex">Device Profile <em className="inline fa-solid fa-dash mx-2"></em> {endpointProfile?.endpoint?.agent_profile?.AdminInfos?.Name || ''}</h1>
                <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-bell-on', link: '', name: 'Home' },
                    { icon: '', link: '', name: 'Managed Services' },
                    { icon: '', link: '/endpoint', name: 'Endpoints' },
                    { icon: '', link: '/endpoint/devices', name: 'Endpoint Devices' },
                    { icon: '', link: '', name: 'Device Profile' },
                    // { icon: '', link: '', name: endpointProfile?.endpoint?.agent_profile?.AdminInfos?.Name || '' },
                ]} />

                    <div className="grow grid grid-flow-row-dense grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-h-min md:mb-0">
                        <div className="row-span-5">
                            <DeviceDetails
                                endpointProfile={endpointProfile}
                                detailOpen={detailOpen}
                                DetailOpenHandler={DetailOpenHandler}
                                scrollRef={scrollRef}
                            />
                        </div>
                        <div className="sm:col-span-1 w-full">
                            <TotalProtectedDataCard endpointProfile={endpointProfile} />
                        </div>
                        <div className="sm:col-span-1 w-full">
                            <TotalRestoreDataCard endpointProfile={endpointProfile} />
                        </div>
                        <div className="sm:col-span-1 w-full">
                            <BackupTodayCard backupTodaysData={backupTodaysData} />
                        </div>
                        <div className="sm:col-span-1 w-full">
                            <RestoreTodayCard restoreTodaysData={restoreTodaysData} />
                        </div>

                        {/* <Tile colSpan={3} rowSpan={5}>
                            <div className="flex flex-col  p-4">
                                <div className="flex flex-col justify-start text-left font-normal text-2xl ">Total Protected Device</div>
                                <div className="flex text-[#ff5e0e] text-4xl  justify-between w-full align-middle items-center mt-4"><i className="fa-thin fa-shield-check text-4xl px-2 mt-4 text-[#ff5e0e]"/><span className="text-[42px]">12.9 GB</span></div>
                            </div>    
                        </Tile>
                        <Tile colSpan={3} rowSpan={5}>
                            <div className="flex flex-col  p-4">
                                <div className="flex flex-col justify-start text-left font-normal text-2xl ">Total Restore Data</div>
                                <div className="flex text-[#ff5e0e] text-4xl  justify-between w-full align-middle items-center mt-4"><i className="fa-thin fa-download text-4xl px-2 mt-4 text-[#ff5e0e]"/><span className="text-[42px]">12.9 GB</span></div>
                            </div>    
                        </Tile>
                        <Tile colSpan={3} rowSpan={5}>
                            <div className="flex flex-col  p-4">
                                <div className="flex flex-col justify-start text-left font-normal text-2xl ">Backup Today</div>
                                <div className="flex text-[#ff5e0e] text-4xl  justify-between w-full align-middle items-center mt-4"><i className="fa-thin fa-arrow-down-to-bracket text-4xl px-2 mt-4 text-[#ff5e0e]"/><span className="text-[42px]">12.9 GB</span></div>
                            </div>
                        </Tile>  */}

                        <div className="sm:col-span-2 w-full">
                            <BackupRestoreTrendGraph
                                trendSelection={trendSelection}
                                trendPeriod={trendPeriod}
                                setTrendPeriod={setTrendPeriod}
                                trendCallingActive={trendCallingActive}
                                getBackupRestoreData={getBackupRestoreData}
                                // resetGraphData={resetGraphData}
                                setTrendCallingActive={setTrendCallingActive}
                                setTrendSelection={setTrendSelection}
                                // graphData={graphData}
                                // graphLive={graphLive}
                            />
                        </div>
                    </div>
                    <div className="grow grid grid-flow-row-dense grid-cols-1 sm:grid-cols-2 gap-6 md:mb-0">
                        <div className="sm:col-span-1 w-full lg:-mt-12">


                            {/* <Tile colSpan={4} rowSpan={15} className="pt-2 mb-6" >
                                <div className="px-6 py-4 pb-3  font-semibold text-gray-600 flex flex-col h-20 border-b border-[#ff5e0e]">Protected Data Share
                                <small className="text-gray-400 font-normal ">This device's backup share out of Overall Protected Data of protected data</small></div> 
                                        <div className="h-60 mt-10 flex justify-center items-center align-middle text-cente relativer">
                                            <div className="absolute text-gray-00 text-3xl font-medium">0.10 %</div>
                                            <MasterChart
                                                datasets={[
                                                    {
                                                        label: '# of Votes',
                                                        data: [1,9],
                                                        backgroundColor: [
                                                        'rgba(255, 94, 14, 1)',
                                                        'rgba(244, 245, 250, 1)',
                                                                ],
                                                                borderColor: [
                                                        'rgba(255, 255, 255, 1)',
                                                        'rgba(255, 255, 255, 1)',
                                                                ],
                                                                borderWidth: 0,
                                                            //   borderRadius: 20,
                                                                cutout: 100,
                                                            },
                                                ]}
                                                labels={[""
                                                ]}
                                                options={{
                                                plugins: {
                                                    legend: {
                                                    display: false,
                                                    position: 'bottom'
                                                    },
                                                    title: {
                                                    display: false,
                                                    text: 'Line Chart'
                                                    }
                                                },
                                                responsive: true,
                                                maintainAspectRatio: false,      
                                                scales: {
                                                    x: {
                                                    display: false
                                                    },
                                                    y: {
                                                    display: false,
                                                    position: 'left',
                                                    type: 'linear'
                                                    }
                                                }
                                                }}
                                                type="donut"
                                            />
                                        </div>
                            </Tile> */}

                            {/* <Tile colSpan={4} rowSpan={15} className="pt-2 mb-6" >
                                <div className="px-6 py-4 pb-8  font-semibold text-gray-600 flex flex-col h-20 border-b border-[#ff5e0e]">Largest Incremental Backups
                                <small className="text-gray-400 font-normal ">This device's top 6 largest backups</small></div>                                        
                                <div className="flex flex-col text-gray-700 mt-[9px] ">
                                    <div className="flex flex-row justify-between px-8 text-sm border-b py-2">
                                        <div className="flex flex-col font-semibold text-[#ff5e0e]">4.1 GB<small className="text-gray-400 font-normal">Done by device-SYSTEM</small></div>
                                        <div className="flex flex-col font-semibold">Added 2 days ago<small className="text-gray-400 font-normal">11-02-2022 02:02  AM</small></div>
                                    </div>
                                    <div className="flex flex-row justify-between px-8 text-sm border-b py-2">
                                        <div className="flex flex-col font-semibold text-[#ff5e0e]">4.1 GB<small className="text-gray-400 font-normal">Done by device-SYSTEM</small></div>
                                        <div className="flex flex-col font-semibold">Added 2 days ago<small className="text-gray-400 font-normal">11-02-2022 02:02  AM</small></div>
                                    </div>
                                    <div className="flex flex-row justify-between px-8 text-sm border-b py-2">
                                        <div className="flex flex-col font-semibold text-[#ff5e0e]">4.1 GB<small className="text-gray-400 font-normal">Done by device-SYSTEM</small></div>
                                        <div className="flex flex-col font-semibold">Added 2 days ago<small className="text-gray-400 font-normal">11-02-2022 02:02  AM</small></div>
                                    </div>
                                    <div className="flex flex-row justify-between px-8 text-sm border-b py-2">
                                        <div className="flex flex-col font-semibold text-[#ff5e0e]">4.1 GB<small className="text-gray-400 font-normal">Done by device-SYSTEM</small></div>
                                        <div className="flex flex-col font-semibold">Added 2 days ago<small className="text-gray-400 font-normal">11-02-2022 02:02  AM</small></div>
                                    </div>
                                    <div className="flex flex-row justify-between px-8 text-sm border-b py-2">
                                        <div className="flex flex-col font-semibold text-[#ff5e0e]">4.1 GB<small className="text-gray-400 font-normal">Done by device-SYSTEM</small></div>
                                        <div className="flex flex-col font-semibold">Added 2 days ago<small className="text-gray-400 font-normal">11-02-2022 02:02  AM</small></div>
                                    </div>
                                    <div className="flex flex-row justify-between px-8 text-sm border-b py-2">
                                        <div className="flex flex-col font-semibold text-[#ff5e0e]">4.1 GB<small className="text-gray-400 font-normal">Done by device-SYSTEM</small></div>
                                        <div className="flex flex-col font-semibold">Added 2 days ago<small className="text-gray-400 font-normal">11-02-2022 02:02  AM</small></div>
                                    </div>
                                </div>
                            </Tile> */}

                            {/* <Tile colSpan={4} rowSpan={15} className="pt-2 mb-8" >
                                <div className="px-6 py-4  font-semibold text-gray-600 flex flex-col h-20 border-b border-[#ff5e0e]">Activity Logs
                                </div> 
                                <div className="flex flex-col overflow-y-scroll customScroll  h-[320px] mt-4">
                                    <div className="px-6 flex flex-col mb-4 border-b pb-4">
                                        <div className="flex flex-row text-sm font-medium ">
                                            <i className="fa-solid fa-circle-dot text-green-600 mr-2"></i>
                                            <span className="text-gray-600 flex flex-col font-normal">39 minutes ago | 12/02/2022 12:11 AM<small className="text-gray-400 mb-2">1-Hour window | Single event logged</small></span>
                                        </div>
                                        <div className="flex flex-row text-sm font-medium border p-2 ">
                                            <i className="fa-solid fa-wave-pulse text-blue-600 mr-2"></i>
                                            <span className="text-gray-700 flex flex-col font-normal">Device was active for 24.11<small className="text-blue-400 ">Active Connection</small></span>
                                        </div>
                                    
                                    </div> 
                                    <div className="px-6 flex flex-col mb-4 border-b pb-4">
                                        <div className="flex flex-row text-sm font-medium ">
                                            <i className="fa-solid fa-circle-dot text-green-600 mr-2"></i>
                                            <span className="text-gray-600 flex flex-col font-normal">39 minutes ago | 12/02/2022 12:11 AM<small className="text-gray-400 mb-2">1-Hour window | Single event logged</small></span>
                                        </div>
                                        <div className="flex flex-row text-sm font-medium border p-2 ">
                                            <i className="fa-solid fa-wave-pulse text-blue-600 mr-2"></i>
                                            <span className="text-gray-700 flex flex-col font-normal">Device was active for 24.11<small className="text-blue-400 ">Active Connection</small></span>
                                        </div>
                                    </div> 
                                    <div className="px-6 flex flex-col mb-4 border-b pb-4">
                                        <div className="flex flex-row text-sm font-medium ">
                                            <i className="fa-solid fa-circle-dot text-green-600 mr-2"></i>
                                            <span className="text-gray-600 flex flex-col font-normal">39 minutes ago | 12/02/2022 12:11 AM<small className="text-gray-400 mb-2">1-Hour window | Single event logged</small></span>
                                        </div>
                                        <div className="flex flex-row text-sm font-medium border p-2 ">
                                            <i className="fa-solid fa-wave-pulse text-blue-600 mr-2"></i>
                                            <span className="text-gray-700 flex flex-col font-normal">Device was active for 24.11<small className="text-blue-400 ">Active Connection</small></span>
                                        </div>
                                    </div> 
                                </div>
                            </Tile> */}

                            <DeviceStrategyCard strategyData={strategyData} />
                        </div>
                        <div className="sm:col-span-1 w-full lg:-mt-12">
                            <GlobalFileExtension policyData={policyData} />
                        </div>
                    </div>
                    <div className="grow grid grid-flow-row-dense grid-cols-1 sm:grid-cols-2 gap-6">
                        <DevicePolicy
                            policyData={policyData}
                            scrollRef={scrollRef}
                        />
                    </div>     
            </div>
        </>
    )
}

export default EndpointDeviceProfile