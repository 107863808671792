import React, { FC, ComponentProps, useContext } from "react";
import { SideDrawerContext, ThemeContext } from "../../../contexts";

interface Props extends ComponentProps<"div">  {
    // levelTwoDrawerVisibility?: boolean
    // setLevelTwoDrawerVisibility?: Dispatch<SetStateAction<boolean>>
    /**
     * Child node(s) of button
     */
    // children?: ReactNode | ReactNode[] | string;
    /**
     * 
     */
    // className?: string
    // onClick?: () => void
}

/**
 * Button Component
 * @param param0 
 * @returns 
 */
const LevelTwoDrawer:FC<Props> = ({
    // levelTwoDrawerVisibility=false,
    // setLevelTwoDrawerVisibility=()=>{},
    // className='',
    // children=<></>,
    // onClick=()=>{},
    ...rest
}: Props) => {

    const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    const sideDrawerContext = useContext(SideDrawerContext)

    return (
        <>
            <div className={`levelTwoDrawer z-[999996] absolute top-16 transition-[width] duration-200 ease-in-out right-0 md:right-[0px] lg:right-[480px] ${sideDrawerContext.levelTwoDrawerVisibility ? 'w-[440px] md:w-[640px]' : 'w-0'}  bottom-0 ${ theme.themeContrast === 'light' ? 'bg-[#FFFFFF]' : 'bg-[#333333]'} shadow-lg`}>
                <div className={`contentWrapper relative w-full h-full transition-all duration-200 ease-in-out ${sideDrawerContext.levelTwoDrawerVisibility ? 'block' : 'hidden'} `}>
                    <button
                        className={`modal-close-button absolute top-4 right-4 pb-[3px] rounded hover:bg-${theme.themeColor}-${theme.themeContrast}-primary-default hover:bg-opacity-25 text-xl px-2 z-10`}
                        onClick={()=>{
                            sideDrawerContext.setLevelTwoDrawerVisibility(false);
                        }}
                    >&times;</button>

                    {sideDrawerContext.levelTwoDrawerChild}

                </div>
            </div>
        </>
    );
}

export default LevelTwoDrawer;
