import React, { Dispatch, FC, ReactNode, RefObject, SetStateAction } from "react";
import { TableColumnManagementNav } from "./TableColumnManagementNav";
import TableBody from "./TableBody";
import TableCell from "./TableCell";
import TableHead from "./TableHead";
import TableRow from "./TableRow";
import Loader from "../Loader/Loader";

export interface ISortingObj {
    enable: boolean;
    direction: 'ASC'|'DESC'|'NEUTRAL';
}

/**
 * Interface to represent table column item
 */
export interface ITableColumnItem {
    /**
     * ID if column
     */
    id: number;
    /**
     * name of column to be shown on header
     */
    name: string;
    /**
     * name of object key of data from which the cell data will be extracted or 
     * will remain empty string if whole column object need to be passed
     */
    column: string;
    /**
     * If column is visible?
     */
    visibility: boolean;
    /**
     * default Text color of the cell(s) of column {Tailwind Classes}
     */
    textColor?: string;
    /**
     * default text alignment of the cell(s) of column {Tailwind Classes}
     */
    textAlign?: 'text-left' | 'text-center' | 'text-right' | 'text-justify';

    className?: string,

    filter?: any;

    /**
     * Enable a colum sorting handler
     */
    sorting?: ISortingObj;
}

/**
 * Interface represents table controller props
 */
export interface ITableControllerProps {
    /**
     * Actual data to be shown in table, array of object, each object represents a row entry
     */
    data: {[key: string]: any}[],
    /**
     * data offset
     */
    offset: number;
    /**
     * data limit
     */
    limit: number;
    /**
     * is loader Loader active?
     */
    loader: boolean;
    /**
     * Represents currently active page in pagination
     */
    currentPage: number;
    /**
     * Total records in database to calculate number of pages based on page size
     */
    totalRecords: number;
    /**
     * maximum number of pagination buttons to be shown.
     */
    maxVisiblePaginationButtons: number;
    /**
     * search term to be search in database
     */
    searchTerm: string;
    /**
     * sorting of column data order
     */
    sorting: {
        /**
         * name of column
         */
        column: string;
        /**
         * order of data
         */
        direction: null | 'asc' | 'desc';
    },
    /**
     * filter object, represents aggregation filter object
     */
    filter: object;
}

type IFormatData = ReactNode | ReactNode[] | string;

/**
 * Interface for Table props
 */
export interface ITableProps {
    /**
     * Table ID just to make it distinguish from other table
     */
    tableId?: string;
    /**
     * useRef property of Column Management Navigation
     */
    navRef: RefObject<HTMLDivElement>;
    /**
     * ITableColumnItem column object array
     */
    columns?: ITableColumnItem[];
    /**
     * Table controller object
     */
    setColumns: Dispatch<SetStateAction<ITableColumnItem[]>>;
    /**
     * ITableControllerProps prop
     */
    tableController?: ITableControllerProps;
    /**
     * function responsible to format data
     */
    formatData?: (rowObj:object|string|any,format?:string, rowIndex?: number)=>IFormatData;
    /**
     * If you wish to show action header cell here action cell contain action buttons for each row like edit, update, delete etc.?
     */
    showActionHeaderCell?: boolean;
    /**
     * If you wish to show column management navigation
     */
    showColumnManagementMenu?: boolean;
    /**
     * If you wish to show action call at the end of each cell that contains action buttons like edit, update, delete etc.?
     */
    showActionCell?: boolean;
    /**
     * function responsible to generate action button
     */
    actionButtonGenerator?: (row: object) => IFormatData;
    /**
     * Function responsible to handle switch ON/OF response in column management navigation
     */
    switchHandler?: any;

    dataSortingHandler?: (column:any)=>void;
}

const Table:FC<ITableProps> = ({
    tableId='',
    navRef,
    columns=[],
    setColumns,
    tableController,
    formatData=(rowObj,format)=>{ return '' },
    showActionHeaderCell=false,
    showActionCell=false,
    actionButtonGenerator=(row) => {return ''},
    showColumnManagementMenu=false,
    dataSortingHandler=(column:any)=>{},
}) => {

    const dataSortingUiUpdater = (column:any) => {

        let tempColumns:ITableColumnItem[] = [...columns];

        let columnIndex = -1;

        for (let i = 0; i < tempColumns.length; i++) {
            const thisColumn = tempColumns[i];
            
            if( thisColumn.name === column.name ) {
                columnIndex = i
            }
        }

        let newSortingState = null

        if( column.sorting.direction === 'NEUTRAL' ) {
            newSortingState = 'ASC'
        } else if( column.sorting.direction === 'ASC' ) {
            newSortingState = 'DESC'
        } else if( column.sorting.direction === 'DESC' ){
            newSortingState = 'NEUTRAL'
        }
        
        
        tempColumns[columnIndex] = {
            ...column,
            sorting: {
                ...column.sorting,
                direction: newSortingState
            }
        }

        setColumns((previousState:any)=>{
            return tempColumns
        })

        dataSortingHandler(column)
    }

    return (
        <>
            <span className="relative">
                <div id={`${tableId ? tableId : 'table'}`} className='dataTable responsive block w-full overflow-auto customScroll'>
                    <table className='table break-inside-auto relative table-auto w-full'>
                        <TableHead className=''>
                            <TableRow type='header' className='flex'>
                                {columns?.length > 0 && columns.map((item, cellIndex)=>{
                                    return item.visibility ? <TableCell key={cellIndex} type='header' >
                                        <div className={`${item?.className || ''}`}>
                                            {item.name} {item?.sorting?.enable === true &&  <SortOrderIcon direction={item?.sorting?.direction} dataSortingUiUpdater={dataSortingUiUpdater} column={item} />}
                                        </div>
                                    </TableCell> : ''
                                })}
                                {
                                    showActionHeaderCell && <TableCell className="print:hidden w-24 pr-6" type='header' textAlignment='text-left'>
                                        {showColumnManagementMenu === true ? <TableColumnManagementNav navRef={navRef} /> : "Actions"}
                                    </TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(tableController?.data || []).length > 0 && tableController?.data.map((row,rowIndex)=>{
                                return (
                                    <TableRow key={rowIndex} type='body'>
                                        {columns.map((col, colIndex)=>{
                                            return (
                                                col.visibility && <TableCell key={colIndex} type='body' textColor={`${col?.textColor}`} textAlignment={`${col?.textAlign}`} className={``}>
                                                    <div className={`${col?.className || ''}`}>
                                                        { col.column !== "" ? formatData(row[col.column],col.name,rowIndex) : formatData(row,col.name,rowIndex) }
                                                    </div>
                                                </TableCell>
                                            )
                                        })}
                                        {showActionCell && <TableCell className="print:hidden" type='body' textColor='text-grey-dark' textAlignment='text-left'>
                                            {actionButtonGenerator(row)}
                                        </TableCell>}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </table>
                </div>
                {tableController?.data?.length === 0 && <div className="empty-row w-full break-inside-avoid break-after-auto h-10 hover:bg-background py-[10.5px] px-6 align-middle border-b font-semibold text-xs text-grey-dark text-center">
                    No data found
                </div>}
                {tableController?.loader && <Loader />}
            </span>
        </>
    )
}

export default Table;

interface ISortOrderIcon {
    direction: 'ASC'|'DESC'|'NEUTRAL';
    dataSortingUiUpdater: (columns:any)=>void;
    column: any;
}

const SortOrderIcon:FC<ISortOrderIcon> = ({direction, dataSortingUiUpdater, column}) => {

    return (
        <>
            { direction === 'ASC' && <button aria-label="sort ascending" className=""
                onClick={()=>{
                    dataSortingUiUpdater(column)
                }}
            ><em className="fa-solid fa-sort-down"></em></button> }
            { direction === 'DESC' && <button aria-label="sort descending" className=""
                onClick={()=>{
                    dataSortingUiUpdater(column)
                }}
            ><em className="fa-solid fa-sort-up"></em></button> }
            { direction === 'NEUTRAL' && <button aria-label="sort" className=""
                onClick={()=>{
                    dataSortingUiUpdater(column)
                }}
            ><em className="fa-solid fa-sort"></em></button> }
        </>
    )
}