/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
// import { Logout } from '../components/core';
import { useAuth0 } from "@auth0/auth0-react"
import { useNavigate } from "react-router-dom";

/**
 * Logout Page
 * @returns 
 */
const LogoutPage = () => {
    let navigate = useNavigate();
    const { logout } = useAuth0();

    useEffect(()=>{
        localStorage.clear();
        sessionStorage.clear();
        logout({ returnTo: window.location.origin });
        // navigate(document.location.origin)
    },[]);
    
    return (
        <>Logout</>
    )
}

export default LogoutPage;
