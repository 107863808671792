import React, { FC } from "react";
import { Link } from "react-router-dom";
import MasterChart, { IChartDatasetData } from "../../../Charts/ChartJS/CJMasterChart/MasterChart";
import { Button, Tile } from "../../../core";

interface ISecondaryDataItem {
    value: string;
    description: string;
    icon: string;
}

interface INavItem {
    name: string
}

interface IProps {
    title?: string;
    chartType?: "bar" | "line" | "pie" | "donut";
    chartLabels: string[];
    chartDataset: IChartDatasetData[];
    chartOptions?: object;
    secondaryTitle?: string;
    secondaryDescription?: string;
    secondaryNav?: INavItem[];
    secondaryData?: ISecondaryDataItem[];
    secondaryButtonText?: string;
    secondaryButtonLink?: string;
}

const BG_COLORS = [
    'bg-green-100',
    'bg-violet-100',
    'bg-gray-100',
    'bg-blue-100',
    'bg-red-100',
];
const TEXT_COLORS = [
    'text-green-500',
    'text-red-500',
    'text-gray-500',
    'text-blue-500',
    'text-red-500',
];

const Tile57:FC<IProps> = ({
    title='',
    chartType,
    chartLabels,
    chartDataset,
    chartOptions,
    secondaryTitle='',
    secondaryDescription='',
    secondaryNav=[],
    secondaryData=[],
    secondaryButtonText='',
    secondaryButtonLink='',
}: IProps) =>{
    return (
        <>
            <Tile rowSpan={13} colSpan={8} className="p-0">
                <div className="flex flex-row h-full text-[#3A3541]">
                    <div className="border-r flex flex-col h-full w-3/5 p-5 ">
                        <div className="text-xl leading-8 font-medium mb-5">{title}</div>
                        <div className="w-full grow flex flex-col items-center justify-center p-5">
                            {chartType && <MasterChart 
                                type={chartType} 
                                labels={chartLabels} 
                                datasets={chartDataset} 
                                options={chartOptions}
                            />}
                        </div>
                    </div>
                    <div className="w-2/5 h-full p-5 flex flex-col  ">
                        <div className="flex flex-row justify-between mb-5">
                            <div className="flex flex-col">
                                <div className="text-2xl leading-8 font-medium">{secondaryTitle}</div>
                                <div className="text-sm leading-5 font-normal text-[#3A3541] opacity-40">{secondaryDescription}</div>
                            </div>
                            {secondaryNav.length > 0 && <div className="regular group">
                                <button aria-label="more" className="flex w-10 h-10 rounded items-center justify-center hover:bg-slate-100">
                                    <i className="fa-regular fa-ellipsis-vertical"></i>
                                </button>
                                <div className="absolute hidden group-hover:block bg-white border shadow-md min-w-[200px] max-w-min max-h-[190px] overflow-y-scroll customScroll right-4 rounded-md">
                                    {secondaryNav.map((item, itemIndex)=>(
                                        <div key={itemIndex} className="px-3 border-b py-2 whitespace-nowrap hover:bg-slate-100 cursor-pointer">{item.name}</div>
                                    ))}
                                </div>
                            </div>}
                        </div>
                        <div className="flex flex-col grow justify-start overflow-y-scroll customScroll">
                            { secondaryData.length > 0 && secondaryData.map((item, itemIndex)=>(
                                <div key={itemIndex} className={`flex flex-row items-center mb-4`}>
                                    <div className={`flex w-10 h-10 ${BG_COLORS[itemIndex]} rounded mr-3 items-center justify-center`}>
                                        <i className={`${item.icon} text-2xl ${TEXT_COLORS[itemIndex]}`}></i>
                                    </div>
                                    <div className="flex flex-col justify-start">
                                        <div className="text-base leading-6 font-semibold">{item.value}</div>
                                        <div className="text-xs leading-5 font-norma text-[#3A3541] opacity-40l">{item.description}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="mt-4">
                            {(secondaryButtonText !== '' && secondaryButtonLink !== '') && (
                                <Link to={secondaryButtonLink}>
                                    <Button block >{secondaryButtonText}</Button>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </Tile>
        </>
    )
}

export default Tile57

