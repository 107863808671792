import React, { FC, useState, useEffect, useContext } from "react";
// import Tile58 from "../../components/applicationComponents/Tiles/Tile58/Tile58";
import { Tile } from "../../components/core";
import { SideDrawerContext } from "../../contexts";
import { getAPICall } from "../../services/APILayer/axiosMethodCalls";
import { getFilteredTicketStatus } from "../../utils/ticketUtils"
// import { getFormattedDate, getFormattedDateTime } from "../../utils/format"
import { DonutChart } from "../../components/Charts/ApacheECharts";
import { dateTimeToUnixLocalTimestamp, dateTimeToUnixTimestamp, getFormattedDateTime } from "../../utils/format";
import { DateTime } from "luxon";

type donutCard = {
    donutCutout: number;
}

const TicketStatusCard:FC<donutCard> = ({donutCutout}) => {
    
    const sideDrawerContext = useContext(SideDrawerContext)
    const [ticketData, setTicketData] = useState<any>({})

    const getTickets = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/crm/companies/me`);
            return response?.data?.company?.tickets || [];
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        const fetchTicket = () => {
            getTickets().then((data)=>{
                let analysis = getFilteredTicketStatus(data);
                setTicketData(analysis)
            }).catch((error)=>{
                console.log(error)
            }).finally()
        }
        setTimeout(() => {
            fetchTicket()
        }, 500);
    },[])

    return (
        <>
            {
                Object.keys(ticketData).length > 0 ?
                <Tile 
                    rowSpan={13} 
                    colSpan={3}
                    onClick={()=>{
                        sideDrawerContext.setLevelOneDrawerVisibility(false) 
                        sideDrawerContext.setLevelTwoDrawerVisibility(false) 
                        sideDrawerContext.setFirstDrawerChild(<SideDrawerLevelOneContent ticketData={ticketData} />)
                        sideDrawerContext.setLevelOneDrawerVisibility(true) 
                    }}
                    className="cursor-pointer"
                >
                    <div className="flex flex-col h-full mb-1 p-5 text-[#3A3541]">
                        {/* <div className="flex flex-row justify-between w-full">
                            <div title='Ticket Status' className={`text-base md:text-lg lg:text-xl leading-8 font-medium whitespace-nowrap truncate`}>Ticket Status</div>
                        </div> */}
                        <div className="w-full h-full">
                            <DonutChart
                                title="Ticket Status"
                                data={[
                                    {
                                        value: (ticketData?.pending || []).length,
                                        name: "Pending",
                                        itemStyle: {
                                            opacity: 1
                                        }
                                    },
                                    {
                                        value: (ticketData?.closed || []).length,
                                        name: "Closed",
                                        itemStyle: {
                                            opacity: 0.8
                                        }
                                    },
                                    {
                                        value: (ticketData?.inProgress || []).length,
                                        name: "In-progress",
                                        itemStyle: {
                                            opacity: 0.2,
                                        }
                                    }
                                ]}
                                centreValue={
                                    <>
                                        <div className="text-2xl font-semibold">{ticketData?.total > 0 ? (ticketData?.total || 'NA').toString() : 'NA'}</div>
                                        <div className="text-sm font-normal">Total Ticket</div>
                                    </>
                                }
                            />
                        </div>
                    </div>
                
                </Tile> :
                <Tile
                    rowSpan={13} 
                    colSpan={3} 
                >
                    <div className="flex flex-col h-full mb-1 p-5 text-[#3A3541]">
                        <div className="flex flex-row justify-between w-full">
                            <div title='Ticket Status' className={`text-base md:text-lg lg:text-xl leading-8 font-medium whitespace-nowrap truncate`}>Ticket Status</div>
                        </div>
                        <div className="regular flex-col gap-4 grow my-4 mt-4 mb-2 max-h-80 md:max-h-80 lg:max-h-80  items-center justify-center flex z-10 relative">
                            <div className="relative flex h-full aspect-square items-center justify-center align-middle">
                                <div className="h-[90%] lg:h-[70%] xl:h-[90%] aspect-square rounded-full bg-slate-300 animate-pulse"></div>
                                <div className="absolute h-[50%] aspect-square rounded-full border bg-white"></div>
                            </div>
                            <div className="absolute flex flex-col top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                <span className="block bg-slate-300 animate-pulse h-[30px] w-[60px] xl:w-[60px] mb-2"></span>
                                <span className="block bg-slate-300 animate-pulse h-[10px] w-[60px] xl:w-[60px]"></span>
                            </div>
                            <div className="legends flex flex-row justify-center space-x-4 flex-wrap lg:-mt-4 2xl:mt-0">
                                <div className="flex flex-row items-center text-xs whitespace-nowrap pb-1">
                                    <i className="fa-solid fa-circle text-slate-300 text-center justify-center items-start flex text-xs"></i>
                                    <div className="ml-1">
                                        <span className="block bg-slate-300 animate-pulse h-[10px] w-[60px]"></span>
                                    </div>
                                </div>
                                <div className="flex flex-row items-center text-xs whitespace-nowrap pb-1">
                                    <i className="fa-solid fa-circle text-slate-300 text-center justify-center items-start flex text-xs"></i>
                                    <div className="ml-1">
                                        <span className="block bg-slate-300 animate-pulse h-[10px] w-[60px]"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </Tile>
            }
        </>
    )
}

interface ISideDrawerLevelOneContent {
    ticketData: any
    // selectedTicketDetail: {[key: string]: any}[]
    // setSelectedTicketDetail: Dispatch<SetStateAction<{[key: string]: any}[]>>
}

const SideDrawerLevelOneContent:FC<ISideDrawerLevelOneContent> = ({ticketData}) => {

    const sideDrawerContext = useContext(SideDrawerContext)

    const [rawTickets, setRawTickets] = useState<any>(ticketData || {});

    const [closedTickets, setClosedTickets] = useState<any[]>([]);
    const [inProgressTickets, setInProgressTickets] = useState<any[]>([]);
    const [pendingTickets, setPendingTickets] = useState<any[]>([]);

    const [sortOrderClosed, setSortOrderClosed] = useState<string>('assenting');
    const [sortOrderInProgress, setSortOrderInProgress] = useState<string>('assenting');
    const [sortOrderPending, setSortOrderPending] = useState<string>('assenting');

    const [openLevel1,setOpenLevel1] = useState (false);
    const [openLevel2,setOpenLevel2] = useState (false);
    const [openLevel3,setOpenLevel3] = useState (false);
    // const [openLevel4,setOpenLevel4] = useState (false);

    // const [selectedTicketDetail, setSelectedDetailTicket] = useState<any>(null)

    useEffect(()=>{
        setRawTickets(ticketData)
    },[ticketData])

    useEffect(()=>{
        if( Object.keys(rawTickets).length < 1 ) { return }

        setClosedTickets(rawTickets?.closed || []);
        setInProgressTickets(rawTickets?.inProgress || []);
        setPendingTickets(rawTickets?.pending || []);
    },[rawTickets])

    useEffect(()=>{
        if(closedTickets.length < 1) { return }
        setClosedTickets((previousState: any[])=>{
            let newState = (previousState || []).sort(getSortOrder('createdAt','closed'));
            return newState;
        })
    },[sortOrderClosed])

    useEffect(()=>{
        if(inProgressTickets.length < 1) { return }
        setInProgressTickets((previousState: any[])=>{
            let newState = (previousState || []).sort(getSortOrder('createdAt','inProgress'));
            return newState;
        })
    },[sortOrderInProgress])

    useEffect(()=>{
        if(pendingTickets.length < 1) { return }
        setPendingTickets((previousState: any[])=>{
            let newState = (previousState || []).sort(getSortOrder('createdAt','pending'));
            return newState;
        })
    },[sortOrderPending])

    const  openHandler1 = ()=>{
        setOpenLevel1(!openLevel1)
    }
    const  openHandler2 = ()=>{
        setOpenLevel2(!openLevel2)
    }
    const  openHandler3 = ()=>{
        setOpenLevel3(!openLevel3)
    }

    const getSortOrder = (prop: string, dataType: string = 'closed') => {    
        let sortOrder: string = 'assenting';

        if( dataType === 'closed' ) {
            sortOrder = (sortOrderClosed === 'assenting') ? 'descending' : 'assenting';
        } else if ( dataType === 'inProgress' ) {
            sortOrder = (sortOrderInProgress === 'assenting') ? 'descending' : 'assenting';
        } else if ( dataType === 'pending' ) {
            sortOrder = (sortOrderPending === 'assenting') ? 'descending' : 'assenting';
        }

        return function(a: any, b: any) {  
            if( sortOrder === 'assenting' ) {
                if (tzDateTimeToTimestamp(a[prop]) > tzDateTimeToTimestamp(b[prop])) {    
                    return 1;    
                } else if (tzDateTimeToTimestamp(a[prop]) < tzDateTimeToTimestamp(b[prop])) {    
                    return -1;    
                } 
            } else if (sortOrder === 'descending') {
                if (tzDateTimeToTimestamp(a[prop]) < tzDateTimeToTimestamp(b[prop])) {    
                    return 1;    
                } else if (tzDateTimeToTimestamp(a[prop]) > tzDateTimeToTimestamp(b[prop])) {    
                    return -1;    
                } 
            } 
            return 0;    
        }    
    }  
    
    const tzDateTimeToTimestamp = (txDateTime: string) => {
        return Number(DateTime.fromISO(txDateTime).endOf('day').toSeconds().toString().split('.')[0])
    }
      

    return (
        <>
            <div className="h-full flex flex-col">
                <div className="title w-full flex gap-4 px-6 top-0 pt-10 border-b pb-10 bg-slate-100">
                    {/* Icon */}
                    <i className="fa-regular fa-receipt text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i>

                    {/* title */}
                    <div className="flex flex-col">
                        <div className="text-xl font-bold text-slate-700">
                            Ticket Status
                            {/* <span>
                                <i className="fa-solid fa-receipt text-sm ml-2 relative bg-slate-700 text-white w-7 h-7 pt-1 justify-center items-center text-center rounded-full">
                                    { (((ticketData?.inProgress || []).length) + ((ticketData?.pending || []).length)) < 1 ? (
                                        <></>
                                    ) : (
                                        <span className="text-sm bg-orange-500 border-2 border-white text-white rounded-full flex justify-center text-center align-middle items-center w-6 h-6 absolute -top-[14px] -right-[14px]">
                                            {(((ticketData?.inProgress || []).length) + ((ticketData?.pending || []).length))}
                                        </span>
                                    ) }
                                    
                                </i>
                            </span> */}
                        </div>
                        {/* Sub title */}
                        <div className="block text-slate-500 hover:text-[#FF5E0E] cursor-pointer">
                            <span>&nbsp;</span>
                            {/* <em className="fa-solid fa-link ml-2 text-sm"></em> */}
                        </div>
                    </div>
                </div>
                <div className="data grow md:px-4 w-full overflow-y-scroll customScroll">

                    <div className={`flex flex-row items-center hover:bg-slate-200 cursor-pointer text-orange-500 rounded-t-md ${openLevel1===true?"border":"border-t border-l border-r"} bg-slate-100 w-full py-2 px-4 mt-10`} onClick={openHandler1}>
                        <i className="fa-solid fa-check mr-4"></i>
                        <span className="text-gray-800 text-sm">Closed ( {(closedTickets || []).length} )</span>
                        <button className="px-3 py-1 rounded bg-orange-500 text-white text-xs ml-2"
                            onClick={(e)=>{
                                e.stopPropagation()
                                setSortOrderClosed((prevState: any)=>{
                                    let newState: any = (prevState === 'assenting') ? 'descending' : 'assenting';
                                    return newState
                                })
                            }}
                        >Toggle sort</button>
                        <div className="flex grow"></div>
                        <i className={`fa-solid fa-angle-down ml-4 ${openLevel1===true?"rotate-180":"rotate-0"} transition-all duration-500s ease-in-outs`}></i>
                    </div>
                    <div className={`${openLevel1===true?"h-[450px]":"h-[0px]"} border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                        {(closedTickets || []).length > 0 ? (
                            (closedTickets || []).map((ticket:any, ticketIndex:number)=>(
                                <div key={ticketIndex} className="bg-white m-2 border rounded-md shadow-md text-sm p-2 hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light"
                                    onClick={()=>{
                                        sideDrawerContext.setSecondDrawerChild(<SideDrawerLevelTwoContent selectedTicketDetail={ticket} status={'Closed'} />)
                                        sideDrawerContext.setLevelTwoDrawerVisibility(true)
                                    }}
                                >
                                    <div className="text-gray-700 font-medium mb-1">
                                        <span className="text-orange-600">{ticket?.properties?.subject || ''}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-semibold w-36">Ticket Id :</span>
                                        <span className="font-semibold">{ticket?.id || ''}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-semibold w-36">Create date :</span>
                                        <span className="font-normal text-slate-500">{getFormattedDateTime(ticket?.properties?.createdate || '')}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-semibold w-36">Closed date :</span>
                                        <span className="font-normal text-slate-500">{getFormattedDateTime(ticket?.properties?.hs_lastmodifieddate || '')}</span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="flex flex-col w-full h-48 items-center align-middle justify-center">
                                <i className="fa-duotone fa-badge-check text-green-600 text-7xl mb-2"></i>
                                <span className="text-green-600 uppercase">All good</span>
                            </div>
                        )}
                    </div>
                
                    <div className={`flex flex-row text-orange-500 items-center hover:bg-slate-200 cursor-pointer border bg-slate-100 w-full p-2 px-4`} onClick={openHandler2}>
                        <i className="fa-solid fa-spinner mr-4"></i>
                        <span className="text-gray-800 text-sm">In-progress ( {(inProgressTickets || []).length} )</span>
                        <button className="px-3 py-1 rounded bg-orange-500 text-white text-xs ml-2"
                            onClick={(e)=>{
                                e.stopPropagation()
                                setSortOrderInProgress((prevState: any)=>{
                                    let newState: any = (prevState === 'assenting') ? 'descending' : 'assenting';
                                    return newState
                                })
                            }}
                        >Toggle sort</button>
                        <div className="flex grow"></div>
                        <i className={`fa-solid fa-angle-down ml-4 ${openLevel2===true?"rotate-180":"rotate-0"} transition-all duration-500s ease-in-outs`}></i>
                    </div>
                    <div className={`${openLevel2===true?"h-[450px]":"h-[0px]"} border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                        {(inProgressTickets || []).length > 0 ? (
                            (inProgressTickets || []).map((ticket:any, ticketIndex:number)=>(
                                <div key={ticketIndex} className="bg-white m-2 border rounded-md shadow-md text-sm p-2 hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light"
                                    onClick={()=>{
                                        sideDrawerContext.setSecondDrawerChild(<SideDrawerLevelTwoContent selectedTicketDetail={ticket} status={'In-progress'} />)
                                        sideDrawerContext.setLevelTwoDrawerVisibility(true)
                                    }}
                                >
                                    <div className="text-gray-700 font-medium mb-1">
                                        <span className="text-orange-600">{ticket?.properties?.subject || ''}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-semibold w-36">Ticket Id :</span>
                                        <span className="font-semibold">{ticket?.id || ''}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-semibold w-36">Create date :</span>
                                        <span className="font-normal text-slate-500">{getFormattedDateTime(ticket?.properties?.createdate || '')}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-semibold w-36">Last Modified date :</span>
                                        <span className="font-normal text-slate-500">{getFormattedDateTime(ticket?.properties?.hs_lastmodifieddate || '')}</span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="flex flex-col w-full h-48 items-center align-middle justify-center">
                                <i className="fa-duotone fa-badge-check text-green-600 text-7xl mb-2"></i>
                                <span className="text-green-600 uppercase">All Resolved</span>
                            </div>
                        )}
                    </div>
                
                    <div className={`flex flex-row text-orange-500 items-center hover:bg-slate-200 cursor-pointer ${openLevel3===true?"rounded-none":"rounded-b-md"} border bg-slate-100 w-full p-2 px-4`} onClick={openHandler3}>
                        <i className="fa-solid fa-list-check mr-4"></i>
                        <span className="text-gray-800 text-sm">Pending ( {(pendingTickets || []).length} )</span>
                        <button className="px-3 py-1 rounded bg-orange-500 text-white text-xs ml-2"
                            onClick={(e)=>{
                                e.stopPropagation()
                                setSortOrderPending((prevState: any)=>{
                                    let newState: any = (prevState === 'assenting') ? 'descending' : 'assenting';
                                    return newState
                                })
                            }}
                        >Toggle sort</button>
                        <div className="flex grow"></div>
                        <i className={`fa-solid fa-angle-down ml-4 ${openLevel3===true?"rotate-180":"rotate-0"} transition-all duration-500s ease-in-outs`}></i>
                    </div>
                    <div className={`${openLevel3===true?"h-[450px] border rounded-b-md":"h-[0px]"} border-l border-r transition-[height] duration-500s ease-in-outs overflow-hidden overflow-y-scroll customScroll`}>
                        {(pendingTickets || []).length > 0 ? (
                            (pendingTickets || []).map((ticket:any, ticketIndex:number)=>(
                                <div key={ticketIndex} className="bg-white m-2 border rounded-md shadow-md text-sm p-2 hover:bg-slate-100 cursor-pointer flex flex-col text-gray-700 font-light"
                                    onClick={()=>{
                                        sideDrawerContext.setSecondDrawerChild(<SideDrawerLevelTwoContent selectedTicketDetail={ticket} status={'Pending'} />)
                                        sideDrawerContext.setLevelTwoDrawerVisibility(true)
                                    }}
                                >
                                    <div className="text-gray-700 font-medium mb-1">
                                        <span className="text-orange-600">{ticket?.properties?.subject || ''}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-semibold w-36">Ticket Id :</span>
                                        <span className="font-semibold">{ticket?.id || ''}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-semibold w-36">Create date :</span>
                                        <span className="font-normal text-slate-500">{getFormattedDateTime(ticket?.properties?.createdate || '')}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="font-semibold w-36">Last Modified date :</span>
                                        <span className="font-normal text-slate-500">{getFormattedDateTime(ticket?.properties?.hs_lastmodifieddate || '')}</span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="flex flex-col w-full h-48 items-center align-middle justify-center">
                                <i className="fa-duotone fa-badge-check text-green-600 text-7xl mb-2"></i>
                                <span className="text-green-600 uppercase">All Resolved</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}


interface ISideDrawerLevelTwoContent {
    selectedTicketDetail: any;
    // setSelectedDetailTicket: Dispatch<SetStateAction<any>>
    status?: string;
}

const SideDrawerLevelTwoContent:FC<ISideDrawerLevelTwoContent> = ({selectedTicketDetail, status}) => {

    const [selectedRawTicket, setSelectedRawTicket] = useState<any>({});
    const [activeTab, setActiveTab] = useState<string>('Ticket Details');

    useEffect(()=>{
        setSelectedRawTicket(selectedTicketDetail)
    },[selectedTicketDetail])

    useEffect(()=>{
        if( Object.keys(selectedRawTicket).length < 1 ) { return }
        let history = [...selectedRawTicket?.propertiesWithHistory?.hs_pipeline_stage, ...selectedRawTicket?.propertiesWithHistory?.subject].sort(function(x, y){
            return new Date(x.timestamp) < new Date(y.timestamp) ? 1 : -1
        })
        console.log({history})
    },[selectedRawTicket])


    return (
        <>
            <div className="h-full flex flex-col">
                <div className="title w-full flex gap-4 px-6 top-0 pt-10 border-b pb-6 bg-slate-100 items-center">
                    {/* Icon */}
                    <i className="fa-regular fa-receipt text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i>

                    {/* title */}
                    <div className="flex flex-col">
                        {/* <div className="text-xl font-bold text-slate-700">Ticket Details</div> */}
                        <div className="flex flex-row">
                            <div className={`text-[#ff5e0e] text-xl font-bold max-w-64`}>{selectedTicketDetail?.properties?.subject || ''}</div>
                            <div className="ml-2 text-base text-slate-500 font-medium">({status})</div>
                        </div>
                        <div className="text-slate-500">Ticket Id:<span className="font-medium ml-4">{selectedTicketDetail?.properties?.hs_object_id || ''}</span></div>
                        {/* Sub title */}
                        <div className="text-slate-500 text-sm">Create date:<span className="font-medium ml-4">{selectedTicketDetail?.properties?.createdate || ''}</span></div>

                    </div>
                </div>
                <div className="data grow px-1 md:px-4 w-full  overflow-y-scroll customScroll"> 
                    {/* <div className="flex rounded-md overflow-hidden my-2">
                        <button className={`w-1/2 py-2 ${activeTab === "Ticket Details" ? "bg-orange-500 text-white hover:bg-orange-600" : "bg-white text-orange-500 hover:bg-orange-100" }  border rounded-l-md border-orange-500`}
                            onClick={()=>{
                                setActiveTab('Ticket Details')
                            }}
                        >Ticket Detail</button>
                        <button className={`w-1/2 py-2 ${activeTab === "Ticket History" ? "bg-orange-500 text-white hover:bg-orange-600" : "bg-white text-orange-500 hover:bg-orange-100" }  border rounded-r-md border-orange-500`}
                            onClick={()=>{
                                setActiveTab('Ticket History')
                            }}
                        >Ticket Activities</button>
                    </div> */}
                    {activeTab === "Ticket Details" && <div className="flex flex-col gap-4 mt-6">
                        <div className="flex flex-col w-full rounded-md border border-slate-200 overflow-hidden">
                            <div className="px-3 py-2 bg-slate-100 text-orange-500">Subject</div>
                            <div className="grow px-3 py-2 bg-white">{selectedRawTicket?.properties?.subject}</div>
                        </div>
                        <div className="flex flex-col w-full rounded-md border border-slate-200 overflow-hidden">
                            <div className="px-3 py-2 bg-slate-100 text-orange-500">Body</div>
                            <div className="grow h-40 px-3 py-2 bg-white">{selectedRawTicket?.properties?.content}</div>
                        </div>

                        {/* <div>
                            <div className="bg-white rounded-lg p-3 max-w-3/5 self-end">
                                <p className="text-sm font-medium">This is the massage form user</p>
                                <span className="text-xs text-gray-500 block mt-1">20-0402023 : 11:00 AM</span>
                            </div>
                            <div className="bg-slate-200 rounded-lg p-3 max-w-3/5 self-start">
                                <p className="text-sm font-medium">This is the massage by support team</p>
                                <span className="text-xs text-gray-500 block mt-1">20-0402023 : 11:00 AM</span>
                            </div>
                            <div className="bg-white rounded-lg p-3 max-w-3/5 self-end">
                                <p className="text-sm font-medium">This is the massage form user</p>
                                <span className="text-xs text-gray-500 block mt-1">20-0402023 : 11:00 AM</span>
                            </div>
                            <div className="bg-slate-200 rounded-lg p-3 max-w-3/5 self-start">
                                <p className="text-sm font-medium">This is the massage by support team</p>
                                <span className="text-xs text-gray-500 block mt-1">20-0402023 : 11:00 AM</span>
                            </div><div className="bg-white rounded-lg p-3 max-w-3/5 self-end">
                                <p className="text-sm font-medium">This is the massage form user</p>
                                <span className="text-xs text-gray-500 block mt-1">20-0402023 : 11:00 AM</span>
                            </div>
                            <div className="bg-slate-200 rounded-lg p-3 max-w-3/5 self-start">
                                <p className="text-sm font-medium">This is the massage by support team</p>
                                <span className="text-xs text-gray-500 block mt-1">20-0402023 : 11:00 AM</span>
                            </div><div className="bg-white rounded-lg p-3 max-w-3/5 self-end">
                                <p className="text-sm font-medium">This is the massage form user This is the massage form user This is the massage form user This is the massage form user</p>
                                <span className="text-xs text-gray-500 block mt-1">20-0402023 : 11:00 AM</span>
                            </div>
                            <div className="bg-slate-200 rounded-lg p-3 max-w-3/5 self-start">
                                <p className="text-sm font-medium">This is the massage by support team</p>
                                <span className="text-xs text-gray-500 block mt-1">20-0402023 : 11:00 AM</span>
                            </div><div className="bg-white rounded-lg p-3 max-w-3/5 self-end">
                                <p className="text-sm font-medium">This is the massage form user</p>
                                <span className="text-xs text-gray-500 block mt-1">20-0402023 : 11:00 AM</span>
                            </div>
                            <div className="bg-slate-200 rounded-lg p-3 max-w-3/5 self-start">
                                <p className="text-sm font-medium">This is the massage by support team This is the massage form user This is the massage form user This is the massage form user</p>
                                <span className="text-xs text-gray-500 block mt-1">20-0402023 : 11:00 AM</span>
                            </div>
                        </div> */}
                    </div>}
                    {activeTab === "Ticket History" && <div className="flex flex-col my-6">
                        <div className="group">
                            <div className="flex gap-4 py-2 overflow-hidden group-hover:bg-orange-100 group-hover:rounded-[20px] group-hover:shadow-lg transition-all duration-500 ease-in-out">
                                <div className="relative w-14">
                                    <div className="absolute -top-2 left-3 block ml-5 w-0 h-5 border-l-2 border-orange-500 z-0 group-hover:border-orange-100 transition-all duration-500 ease-in-out"></div>
                                    <div className="absolute top-5 left-3 flex items-center justify-center align-middle rounded-full w-10 h-10 -mt-3 bg-orange-500 z-10">
                                        <em className="fa-solid fa-heading text-white"></em>
                                    </div>
                                    <div className="absolute top-10 left-3 block ml-5 w-0 h-10 border-l-2 border-orange-500 z-0 group-hover:border-orange-100 transition-all duration-500 ease-in-out"></div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="font-bold">Subject Change</div>
                                    <div className="flex items-center gap-2">
                                        <div className="text-sm">Previous State</div>
                                        <em className="fa-thin fa-arrow-right"></em>
                                        <div className="text-sm">Current State</div>
                                    </div>
                                    <div className="text-xs font-light">2023-04-10 20:00:00</div>
                                </div>
                            </div>
                        </div>
                        <div className="group">
                            <div className="flex gap-4 py-2 overflow-hidden group-hover:bg-orange-100 group-hover:rounded-[20px] group-hover:shadow-lg transition-all duration-500 ease-in-out">
                                <div className="relative w-14">
                                    <div className="absolute -top-2 left-3 block ml-5 w-0 h-5 border-l-2 border-orange-500 z-0 group-hover:border-orange-100 transition-all duration-500 ease-in-out"></div>
                                    <div className="absolute top-5 left-3 flex items-center justify-center align-middle rounded-full w-10 h-10 -mt-3 bg-orange-500 z-10">
                                        <em className="fa-solid fa-shuffle text-white"></em>
                                    </div>
                                    <div className="absolute top-10 left-3 block ml-5 w-0 h-10 border-l-2 border-orange-500 z-0 group-hover:border-orange-100 transition-all duration-500 ease-in-out"></div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="font-bold">Status Changed</div>
                                    <div className="flex items-center gap-2">
                                        <div className="bg-orange-100 px-2 py-1 text-xs font-bold rounded-full shadow-md">New</div>
                                        <em className="fa-thin fa-arrow-right"></em>
                                        <div className="bg-orange-100 px-2 py-1 text-xs font-bold rounded-full shadow-md">Pending</div>
                                    </div>
                                    <div className="text-xs font-light">2023-04-10 20:00:00</div>
                                </div>
                            </div>
                        </div>
                        <div className="group">
                            <div className="flex gap-4 py-2 overflow-hidden group-hover:bg-orange-100 group-hover:rounded-[20px] group-hover:shadow-lg transition-all duration-500 ease-in-out">
                                <div className="relative w-14">
                                    <div className="absolute -top-2 left-3 block ml-5 w-0 h-5 border-l-2 border-orange-500 z-0 group-hover:border-orange-100 transition-all duration-500 ease-in-out"></div>
                                    <div className="absolute top-5 left-3 flex items-center justify-center align-middle rounded-full w-10 h-10 -mt-3 bg-orange-500 z-10">
                                        <em className="fa-solid fa-sparkles text-white"></em>
                                    </div>
                                    {/* <div className="absolute top-10 l3ft-2 block ml-5 w-0 h-10 border-l-2 border-orange-500 z-0 group-hover:bororange-100hite transition-all duration-500 ease-in-out"></div> */}
                                </div>
                                <div className="flex flex-col">
                                    <div className="font-bold">New Ticket Created</div>
                                    <div className="flex items-center gap-2">
                                        <div className="text-sm">Ticket ID: 1232987</div>
                                        <em className="fa-thin fa-arrow-right"></em>
                                        <div className="text-sm">Subject</div>
                                    </div>
                                    <div className="text-xs font-light">2023-04-10 20:00:00</div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>

            </div>
        </>
    )
}


export default TicketStatusCard;



