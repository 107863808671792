import React, { FC, ReactNode, ComponentProps } from "react";


interface IAccordionProps extends ComponentProps<"div">  {
    /**
     * Child node(s) of button
     */
    children?: ReactNode | ReactNode[];
    className?: string
}

/**
 * Accordion Component
 * @param param0 
 * @returns 
 */
const Accordion:FC<IAccordionProps> = ({
    children=<></>,
    className='',
    ...rest
}: IAccordionProps) => {

    return (
        <div className={`accordion flex flex-col border space-y-0 w-full ${className}`}>
            {children}
        </div>
    );
}

export default Accordion;
