import React, { Dispatch, SetStateAction, ReactNode, useState, useEffect } from "react";
import { useMediaPredicate } from "react-media-hook";

interface IThemeContext {
    themeContrast: string;
    setThemeContrast: Dispatch<SetStateAction<string>>;
    changeThemeContrast: Dispatch<SetStateAction<string>>;
    themeColor: string;
    setThemeColor: Dispatch<SetStateAction<string>>;
}

export const ThemeContext = React.createContext({} as IThemeContext);

interface IProp {
    children: ReactNode | ReactNode[];
}

const ThemeProvider = (props: IProp) => {

    const preferredTheme = useMediaPredicate('(prefers-color-scheme: dark)') ? 'dark' : 'light'

    const [themeContrast, setThemeContrast] = useState('light');
    const [themeColor, setThemeColor] = useState('orange');

    useEffect(()=>{
        // setThemeContrast(preferredTheme);
        
        /* to handle memory leakage */
        return () => { return; }
    },[preferredTheme]);

    const changeThemeContrast = async (c: any) => {
      await setThemeContrast(c);
    }

    return (
      <ThemeContext.Provider value={{themeContrast, setThemeContrast, changeThemeContrast, themeColor, setThemeColor}}>
        {props.children}
      </ThemeContext.Provider>
    )
}

export default ThemeProvider;