import React, { FC, ReactNode } from "react";
import {SimpleTile, LinaMetronicTile} from "../applicationComponents/Tiles";
import { Tile } from "../core";


import { IComponent } from "../../contexts/LibraryContext/LibraryContext"

interface IProp  {
    item?: IComponent,
    /**
     * Child node(s) of button
     */
    children?: ReactNode | ReactNode[] | any;
}

/**
 * AnyComponent Component
 * @param param0 
 * @returns 
 */
const AnyComponent:FC<IProp> = ({
    item,
    children=<></>,
    ...rest
}: IProp) => {

    let finalComponent = <></>;

    let dynamicComponent = `${item?.type}_${item?.variant}`

    switch (dynamicComponent) {

        case 'tile_SimpleTile':
            finalComponent = <SimpleTile colSpan={item?.colSpan} rowSpan={item?.rowSpan} data={{...item?.data}} />
            break;

        case 'tile_LinaMetronicTile':
            finalComponent = <LinaMetronicTile colSpan={item?.colSpan} rowSpan={item?.rowSpan} data={{...item?.data}} />
            break;

        default:
            finalComponent = <><Tile colSpan={4} rowSpan={1} /></>
            break;
    }

    return finalComponent;
}

export default AnyComponent;
