import React from "react";
import { Tile } from "../../../core";


const Tile56
= ( ) =>{
    return (
        <>
        <Tile rowSpan={13} colSpan={6} className=" border shadow-none   p-0 ">
                <div className=" flex flex-col   h-full mb-1 p-5 text-[#3A3541]  ">
                    <div className="flex flex-row justify-between w-full mb-5  ">
                        <div className={`text-xl leading-8  font-medium `}>Total Sale</div>
                        <i className="fa-solid fa-ellipsis-vertical text-center justify-center items-start flex"></i>   
                    </div>
                    <div className="w-full flex flex-row">
                        <div className="w-1/2 py-7 pr-9   h-full ">
                            <div className="w-full border h-full flex items-center justify-center">
                            <i className="fa-thin fa-atom text-center justify-center items-center text-9xl flex"></i>
                            </div>
                        </div>
                        <div className="w-1/2 py-9 h-full ">
                            <div className="flex pb-4 border-b items-center">
                                <div><i className="fa-solid fa-earth-americas border text-[#3A3541] bg-sky-100 p-2"></i></div>
                                <div className="flex flex-col ml-3">
                                    <div className="text-sm leading-5 font-normal text-[#3A3541 opacity-50]">Number of Sales</div>
                                    <div className="text-xl leading-8 font-medium text-[#3A3541]">$86,400</div>
                                </div>
                            </div>
                            <div className="my-4">
                                <div className="flex flex-col">
                                    <div className="flex flex-row text-[#3A3541] justify-between mb-4">
                                        <div className="flex flex-col">
                                            <div className="flex items-center mb-2">
                                                <i className="fa-solid fa-circle text-orange-500 text-xs  mr-[6px]"></i>
                                                <div className="text-sm leading-5 text-regular opacity-50 ">Apparel</div>
                                            </div>
                                            <div className="text-base leading-6 font-semibold ">$1,840</div>
                                        </div>
                                        <div className="flex flex-col">
                                            <div className="flex items-center mb-2">
                                                <i className="fa-solid fa-circle text-orange-500 text-xs  mr-[6px]"></i>
                                                <div className="text-sm leading-5 text-regular opacity-50 ">Apparel</div>
                                            </div>
                                            <div className="text-base leading-6 font-semibold ">$1,840</div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row text-[#3A3541] justify-between mb-4">
                                        <div className="flex flex-col">
                                            <div className="flex items-center mb-2">
                                                <i className="fa-solid fa-circle text-orange-500 text-xs  mr-[6px]"></i>
                                                <div className="text-sm leading-5 text-regular opacity-50 ">Apparel</div>
                                            </div>
                                            <div className="text-base leading-6 font-semibold ">$1,840</div>
                                        </div>
                                        <div className="flex flex-col">
                                            <div className="flex items-center mb-2">
                                                <i className="fa-solid fa-circle text-orange-500 text-xs  mr-[6px]"></i>
                                                <div className="text-sm leading-5 text-regular opacity-50 ">Apparel</div>
                                            </div>
                                            <div className="text-base leading-6 font-semibold ">$1,840</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
        </Tile>
        </>
    )
}

export default Tile56
