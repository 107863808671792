import React, { useState, useEffect } from 'react';
import { getAPICall } from "../../services/APILayer/axiosMethodCalls";
import { Tile } from '../../components/core';
// import Placeholder from '../../components/core/Placeholder/Placeholder';

const StatsCard = () => {

    const [statsData, setStatsData] = useState<any>({})

    const getStats = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/analytics/endpoints_data_stats`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( Object.keys(statsData).length > 0 ) {
            return;
        }
        const fetchStats = () => {
            getStats()
                .then((stats)=>{
                    setStatsData(stats)
                })
                .catch(err=>console.log(err));
        }
        setTimeout(() => {
            fetchStats();
        }, 500);
    },[statsData])

    return (
        <>
            <Tile rowSpan={13} colSpan={3} className='p-0 overflow-hidden'>
                <div className="flex flex-col h-full mb-1 p-0 text-[#3A3541]">
                    <div className="flex flex-row justify-between w-full">
                        <div title='Ticket Status' className="text-base md:text-lg pt-5 pl-5 lg:text-xl leading-8 font-medium whitespace-nowrap truncate">Endpoint Stats</div>
                    </div>
                    <div className="flex flex-col h-[360px]">
                        <div className="flex grow h-[155px]">
                            <div className="relative flex flex-col items-center align-middle justify-center w-1/2 hover:bg-[#ff5e0e] hover:bg-opacity-5 shadow-md">
                                <span className={`text-4xl lg:text-2xl xl:text-4xl flex items-end gap-1 font-semibold mb-2 text-[#FF5E0E]`}> 
                                    { statsData?.stats?.protected_data_text ?
                                        (statsData?.stats?.protected_data_text || '.').split(' ')[0] :
                                        <div className='bg-slate-300 animate-pulse w-[60px] lg:w-[30px] xl:w-[60px] h-[40px]'></div>
                                    }
                                    <span className="text-sm font-medium">
                                        { statsData?.stats?.protected_data_text ?
                                            (statsData?.stats?.protected_data_text || '.').split(' ')[1] :
                                            <div className='bg-slate-300 animate-pulse w-[20px] h-[20px]'></div>
                                        }
                                    </span>
                                </span>
                                <span className="text-sm text-gray-600 px-6 text-center whitespace-nowrap">Protected Data</span>
                                <span className="absolute bottom-2 right-2 text-xs text-gray-500 uppercase">Backup</span>
                            </div>
                            <div className="relative flex flex-col items-center align-middle justify-center w-1/2 hover:bg-[#ff5e0e] hover:bg-opacity-5 shadow-md">
                                <span className="text-4xl lg:text-2xl xl:text-4xl flex items-end gap-1 font-semibold mb-2 text-[#FF5E0E]"> 
                                    { statsData?.stats?.recent_backup_text ?
                                        (statsData?.stats?.recent_backup_text || '.').split(' ')[0] :
                                        <div className='bg-slate-300 animate-pulse w-[60px] lg:w-[30px] xl:w-[60px] h-[40px]'></div>
                                    }
                                    <span className="text-sm font-medium">
                                        { statsData?.stats?.recent_backup_text ?
                                            (statsData?.stats?.recent_backup_text || '.').split(' ')[1] :
                                            <div className='bg-slate-300 animate-pulse w-[20px] h-[20px]'></div>
                                        }
                                    </span>
                                </span>
                                {/* <span className="text-4xl font-semibold mb-2 text-[#FF5E0E]">{(statsData?.stats?.recent_backup_text || ' ').split(' ')[0]}<span className="text-sm font-light">{(statsData?.stats?.recent_backup_text || ' ').split(' ')[1]}</span></span> */}
                                <span className="text-sm text-gray-600 px-6 text-center whitespace-nowrap">Last 24 Hrs</span>
                                <span className="absolute bottom-2 right-2 text-xs text-gray-500 uppercase">Backup</span>
                            </div>
                        </div>
                        <div className="flex grow h-[155px]">
                            <div className="relative flex flex-col items-center align-middle justify-center w-1/2 hover:bg-[#ff5e0e] hover:bg-opacity-5 shadow-md">
                                <span className="text-4xl lg:text-2xl xl:text-4xl flex items-end gap-1 font-semibold mb-2 text-[#FF5E0E]"> 
                                    { statsData?.stats?.restored_data_text ?
                                        (statsData?.stats?.restored_data_text || '.').split(' ')[0] :
                                        <div className='bg-slate-300 animate-pulse w-[60px] lg:w-[30px] xl:w-[60px] h-[40px]'></div>
                                    }
                                    <span className="text-sm font-medium">
                                        { statsData?.stats?.restored_data_text ?
                                            (statsData?.stats?.restored_data_text || '.').split(' ')[1] :
                                            <div className='bg-slate-300 animate-pulse w-[20px] h-[20px]'></div>
                                        }
                                    </span>
                                </span>
                                {/* <span className="text-4xl font-semibold mb-2 text-[#FF5E0E]">{(statsData?.stats?.restored_data_text || ' ').split(' ')[0]}<span className="text-sm font-light">{(statsData?.stats?.restored_data_text || ' ').split(' ')[1]}</span></span> */}
                                <span className="text-sm text-gray-600 px-6 text-center whitespace-nowrap">Data Restored</span>
                                <span className="absolute bottom-2 right-2 text-xs text-gray-500 uppercase">Restore</span>
                            </div>
                            <div className="relative flex flex-col items-center align-middle justify-center w-1/2 hover:bg-[#ff5e0e] hover:bg-opacity-5 shadow-md">
                                <span className="text-4xl lg:text-2xl xl:text-4xl flex items-end gap-1 font-semibold mb-2 text-[#FF5E0E]"> 
                                    { statsData?.stats?.recent_restore_text ?
                                        (statsData?.stats?.recent_restore_text || '.').split(' ')[0] :
                                        <div className='bg-slate-300 animate-pulse w-[60px] lg:w-[30px] xl:w-[60px] h-[40px]'></div>
                                    }
                                    <span className="text-sm font-medium">
                                        { statsData?.stats?.recent_restore_text ?
                                            (statsData?.stats?.recent_restore_text || '.').split(' ')[1] :
                                            <div className='bg-slate-300 animate-pulse w-[20px] h-[20px]'></div>
                                        }
                                    </span>
                                </span>
                                <span className="text-sm text-gray-600 px-6 text-center whitespace-nowrap">Last 24 Hrs</span>
                                <span className="absolute bottom-2 right-2 text-xs text-gray-500 uppercase">Restore</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Tile>
        </>
    )
}

export default StatsCard;