import path from "path";
import React, { FC } from "react";

interface IDevicePolicy {
    policyData: any;
    scrollRef: any;
}

const DevicePolicy:FC<IDevicePolicy> = ({
    policyData,
    scrollRef
}) => {

    const behaviorText = {
        EXCL:"Excluded Extensions",
        INCL:"Included Extensions"
    }

    
    return (
        <>
            <div className="sm:col-span-2 w-full">
                <div className="tile relative rounded-lg shadow-md w-full bg-white md:col-span-12 my-6 overflow-hidden" ref={scrollRef}>
                    <div className="flex flex-row w-full p-4 border-b">
                        <div className="title text-base md:text-lg lg:text-xl text-gray-800 font-medium">
                            Device Policy <span className="ml-2 text-slate-500 text-sm font">({policyData?.policy?.Name || 'NA'})</span>
                        </div>
                    </div>
                    <div className="w-full">
                        {/* {JSON.stringify(policyData.path[4].extensions.Behavior)} */}
                        {/* {JSON.stringify(policyData.path[4].extensions.Ext)} */}
                        <div className="table w-full overflow-y-scroll">
                            <div className="thead w-full border-b px-4 py-2 font-medium bg-slate-300 bg-opacity-20">
                                <div className="row flex w-full font-bold text-orange-500">
                                    <div className="cell grow w-36 max-w-40 text-left font-semibold">Name</div>
                                    <div className="cell grow w-40 max-w-80 text-left font-semibold">Path</div>
                                    <div className="cell grow w-40 max-w-80 text-left font-semibold">Filter</div>
                                    {/* <div className="cell grow w-40 max-w-80 text-left font-semibold">Behavior</div> */}
                                </div>
                            </div>
                            <div className="tbody max-h-80 overflow-y-scroll customScroll w-full">

                                {(policyData?.path || []).map((pathObj:any, pathIndex:number) => {

                                    if( (pathObj?._ID || []).length < 1 ) {
                                        return (
                                            <div key={pathIndex} className="row flex items-center w-full border-b px-4 py-2 hover:bg-slate-100 text-sm text-gray-700">
                                                <div className="cell grow w-36 max-w-50 text-left whitespace-nowrap truncate">{pathObj?.path?.Name || 'NA'}</div>
                                                <div className="cell grow w-36 max-w-80 text-left whitespace-nowrap truncate">{pathObj?.path?.Path || 'NA'}</div>
                                                <div className="cell flex flex-col grow w-40 max-w-80 text-left whitespace-nowrap pl-4">
                                                    {pathObj?.ID && <div className="">{ pathObj?.filter?.comment || 'NA' } {pathObj?.extensions?.Behavior === 'INCL' && '[Inclusion]'}{pathObj?.extensions?.Behavior === 'EXCL' && '[Exclusion]'}</div>}
                                                    <span>
                                                        <div className="inline-block px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 text-xs font-medium">
                                                            { Object.keys( policyData?.profile?.RuleArray || {}).length > 0 ? 'All Extensions' : 'No Rule Applied' } 
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    } else if( (pathObj?._ID || []).length > 0 ) {
                                        return (pathObj?._ID || []).map((item: any, itemIndex: number)=>(
                                            <div key={pathIndex} className="row flex items-center w-full border-b px-4 py-2 hover:bg-slate-100 text-sm text-gray-700">
                                                <div className="cell grow w-36 max-w-50 text-left whitespace-nowrap truncate">{pathObj?.path?.Name || 'NA'}</div>
                                                <div className="cell grow w-36 max-w-80 text-left whitespace-nowrap truncate">{pathObj?.path?.Path || 'NA'}</div>
                                                <div className="cell flex flex-col grow w-40 max-w-80 text-left whitespace-nowrap pl-4">
                                                    <div className="">{ pathObj?.filter?.comment || 'NA' } {(pathObj?.extensions?.Behavior === 'INCL' || policyData?.extensions[item]?.Behavior === 'INCL') && '[Inclusion]'}{(pathObj?.extensions?.Behavior === 'EXCL' || policyData?.extensions[item]?.Behavior === 'EXCL' ) && '[Exclusion]'}</div>
                                                    <div className="mb-2">({ policyData?.extensions[item]?.Name || '' })</div>
                                                    <div>
                                                        {(policyData.extensions[item]?.Ext || []).map((extItem:string, extIndex: number)=>{
                                                            return (
                                                                <div 
                                                                    key={`${pathIndex}_${itemIndex}_${extIndex}`} 
                                                                    className="float-left px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 mb-1 text-xs font-medium"
                                                                >
                                                                    {extItem}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                })}

                            
                                {/* {policyData?.policy?.Name ? (
                                    (policyData?.path || []).map((pathObj:any, pathIndex:number)=>(
                                        <div key={pathIndex} className="row flex items-center w-full border-b px-4 py-2 hover:bg-slate-100">
                                            <div className="cell grow w-36 max-w-40 text-left font-light whitespace-nowrap">{pathObj?.path?.Name || 'NA'}</div>
                                            <div className="cell grow w-40 max-w-80 text-left font-light whitespace-nowrap">{pathObj?.path?.Path || 'NA'}</div>
                                            <div className="cell flex flex-col grow w-40 max-w-80 text-left font-light whitespace-nowrap">
                                                <div>{ behaviorText[ pathObj?.extensions?.Behavior]|| pathObj?.filter?.comment || ""}</div>

                                                {pathObj?.extensions?.Behavior ? (
                                                    <>
                                                        {(pathObj?.extensions?.Behavior === "INCL" || pathObj?.extensions?.Behavior === "EXCL") && 
                                                            <span>{pathObj?.extensions?.Ext.map((d:any)=>(
                                                                <div className="float-left px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 mb-1 text-xs font-medium">{d || ''}</div>
                                                            ))}</span>
                                                        }
                                                    </>
                                                ) : (
                                                    <>
                                                        <span>
                                                            <div className="inline-block px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 text-xs font-medium">
                                                               {pathObj?.path?.Name && (policyData?.category?.INCL || []).length < 1 && (policyData?.category?.INCL || []) ? "All Extention" : "No Rule Applied"}
                                                            </div>
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <>
                                        <div className="row flex items-center w-full border-b px-4 py-2 hover:bg-slate-100">
                                            <div className="cell grow w-36 max-w-40 text-left font-light whitespace-nowrap">NA</div>
                                            <div className="cell grow w-40 max-w-80 text-left font-light whitespace-nowrap">NA</div>
                                            <div className="cell flex flex-col grow w-40 max-w-80 text-left font-light whitespace-nowrap">
                                                <span>
                                                    <div className="inline-block px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 text-xs font-medium">
                                                        No Rule Applied
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                )} */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DevicePolicy;