import React, { FC } from "react";
import { 
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    ArcElement,
    Legend,
    
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { IChartParams } from "../CJMasterChart/MasterChart";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-luxon';
import ChartStreaming from 'chartjs-plugin-streaming';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  ArcElement,
  Legend,
  ChartDataLabels,
  zoomPlugin,
  ChartStreaming
);

/**
 * Bar Chart using ChartJS inspired from https://react-chartjs-2.js.org/examples/line-chart
 * @param param0 
 * @returns 
 */
const CJBarChart: FC<IChartParams> = ({ 
  datasets,
  labels,
  options={},
  plugins=[],
  redraw=false,
  datasetIdKey='label',
  fallbackContent=<></>,
  updateMode,
  ref
}: IChartParams) => {

  return (
    <>
      <Bar 
        options={options} 
        data={{
            labels,
            datasets
        }}
        plugins={plugins}
      />
    </>
  );
};

export default CJBarChart;
