import React, { useEffect, useState } from "react";
import { Breadcrumb, Tile } from "../../components/core";
import Switch from "../../components/core/Switch/Switch";
import { getAPICall, putAPICall } from "../../services/APILayer/axiosMethodCalls";
import { toast } from "react-toastify";

const Security = () => {
    // const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    // const sideDrawerContext = useContext(SideDrawerContext)
    const [licenseInfo, setLicenseInfo] = useState<any>({});
    const [settingsEnabled, setSettingsEnabled] = useState<string|null>('Endpoint');

    const [loader, setLoader] = useState<boolean>(false);

    const [endpoint_dashboardOtpSwitchState, endpoint_setDashboardOtpSwitchState] = useState<boolean>(true);
    const [endpoint_emailOtpSwitchState, endpoint_setEmailOtpSwitchState] = useState<boolean>(true);
    const [endpoint_clientRestoreSwitchState, endpoint_setClientRestoreSwitchState] = useState<boolean>(true);


    const getLicenseInfo = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/app/licenses/me`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const getClientRestoreStatus = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints/client_restore_status`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const updateClientRestoreStatus = async (newStatus: boolean) => {
        try {
            const response = await putAPICall(
                `${process.env.REACT_APP_API_URL}/products/endpoints/client_restore_status`,
                {
                    updates:{
                        client_restore: newStatus
                    }
                }
            );
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( Object.keys(licenseInfo).length > 0 ) {
            return;
        }
        const fetchClientRestoreStatus = () => {
            setLoader(true)
            getClientRestoreStatus()
                .then((status)=>{
                    console.log({switch:status?.client_restore})
                    endpoint_setClientRestoreSwitchState(status?.client_restore)
                })
                .catch(err=>console.log(err))
                .finally(()=>{ setLoader(false) });
        }
        fetchClientRestoreStatus();
    },[licenseInfo])
    
    useEffect(()=>{
        if( Object.keys(licenseInfo).length > 0 ) {
            return;
        }
        const fetchLicenseInfo = () => {
            setLoader(true)
            getLicenseInfo()
                .then((license)=>{
                    console.log({license})
                    setLicenseInfo(license)
                })
                .catch(err=>console.log(err))
                .finally(()=>{ setLoader(false) });
        }
        fetchLicenseInfo();
    },[licenseInfo])

    const handleClientRestoreStatusSettings = () => {
        setLoader(true)
        const fetchUpdatedInfo = () => {
            updateClientRestoreStatus(!endpoint_clientRestoreSwitchState)
                .then((response)=>{
                    endpoint_setClientRestoreSwitchState(response?.tenant?.client_restore || !endpoint_clientRestoreSwitchState)
                    if( response?.tenant?.client_restore === true || response?.tenant?.client_restore === false ) {
                        toast.success(`Client restored ${response?.tenant?.client_restore ? 'enabled' : 'disabled'} successfully.`)
                    }
                })
                .catch(err=>console.log(err)).finally(()=>{ setLoader(false) });
        }
        fetchUpdatedInfo();
    }

    return (
        <>
            <div className={`p-6 grow flex flex-col`}>    
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Security {loader && <em className="fa-solid fa-loader fa-spin ml-4 text-[#ff5e0e]"></em>}</h1>
                <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-home', link: '', name: 'Home' },
                    { icon: '', link: '/security', name: 'Security' }
                ]} />
                <div className={` grow flex flex-col`}>
                    <div className={`grid grid-flow-row-dense grid-cols-1 md:grid-cols-12 gap-6 w-full min-h-max mb-6 `}>
                            <Tile colSpan={3} rowSpan={22} className="pb-4 md:col-span-3">
                                <div className="title text-base md:text-lg lg:text-xl text-gray-800 font-medium p-4 border rounded-t-lg">
                                    Security
                                    <div className="text-gray-500 text-sm"></div>
                                </div>
                                <div className="flex flex-col w-full h-[590px] overflow-y-scroll customScroll">
                                    {licenseInfo?.license?.license_data?.product_access?.endpoints?.enabled && <div 
                                        onClick={()=>{
                                            setSettingsEnabled('Endpoint')
                                        }}
                                        className={`flex px-4 py-3 border-b cursor-pointer border-l-4 hover:bg-slate-50 ${settingsEnabled === 'Endpoint' ? 'border-l-orange-500' : 'border-l-white'}  hover:border-l-orange-500 items-center`}
                                    >
                                            <div className="text-slate-500 font-medium py-2 order-b">
                                                Endpoint 
                                            </div>
                                            <div className="grow"></div>
                                            {settingsEnabled === 'Endpoint' && <span className="fa-stack text-xs flex items-center mr-2">
                                                <i className="fa-solid fa-circle fa-stack-2x text-[#ff5e0e]"></i>
                                                <i className="fa-solid fa-check fa-stack-1x fa-inverse"></i>
                                            </span>}                             
                                    </div>}
                                    {licenseInfo?.license?.license_data?.product_access?.ms365?.enabled && <div 
                                        onClick={()=>{
                                            setSettingsEnabled('MS365')
                                        }}
                                        className={`flex px-4 py-3 border-b cursor-pointer border-l-4 hover:bg-slate-50 ${settingsEnabled === 'MS365' ? 'border-l-orange-500' : 'border-l-white'} hover:border-l-orange-500 items-center`}
                                    >
                                            <div className="text-slate-500 font-medium py-2 order-b">
                                                MS365 
                                            </div>
                                            <div className="grow"></div>
                                            {settingsEnabled === 'MS365' && <span className="fa-stack text-xs flex items-center mr-2">
                                                <i className="fa-solid fa-circle fa-stack-2x text-[#ff5e0e]"></i>
                                                <i className="fa-solid fa-check fa-stack-1x fa-inverse"></i>
                                            </span>}  
                                    </div>}
                                    {licenseInfo?.license?.license_data?.product_access?.servers?.enabled && <div 
                                        onClick={()=>{
                                            setSettingsEnabled('Servers')
                                        }}
                                        className={`flex px-4 py-3 border-b cursor-pointer border-l-4 hover:bg-slate-50 ${settingsEnabled === 'Servers' ? 'border-l-orange-500' : 'border-l-white'} hover:border-l-orange-500 items-center`}
                                    >
                                            <div className="text-slate-500 font-medium py-2 order-b">
                                                Servers 
                                            </div>
                                            <div className="grow"></div>
                                            <span className="fa-stack text-xs flex items-center mr-2">
                                                <i className="fa-solid fa-circle fa-stack-2x text-[#ff5e0e]"></i>
                                                <i className="fa-solid fa-check fa-stack-1x fa-inverse"></i>
                                            </span>
                                    </div>}  
                                </div>
                            </Tile>
                            {settingsEnabled === 'Endpoint' && <Tile colSpan={8} rowSpan={33} className="flex flex-col pb-4 overflow-hidden md:col-span-9 border">
                                <div className="title flex items-center justify-center align-middle p-4 border-b">
                                    <span className="flex flex-col">
                                        <span className="text-base md:text-lg lg:text-xl text-gray-800 font-medium">Endpoint Security Settings</span>
                                    </span>
                                    <span className="grow"></span>
                                    {/* <Button
                                        onClick={()=>{
                                            sideDrawerContext.setFirstDrawerChild(<FirstSideDrawer/>)
                                            sideDrawerContext.setLevelOneDrawerVisibility(true)
                                        }}

                                        size="sm"
                                    >Manage Mailing list</Button> */}
                                </div>
                                <div className="">
                                {/* <div className="text-[#ff5e0e] font-medium py-2 px-4 flex flex-row justify-between items-center text-lg bg-slate-50">
                                    Incidence Alerts
                                </div> */}
                                <div className={`custom-table flex flex-col w-full`}>
                                    {/* <pre>{JSON.stringify(licenseInfo, undefined, 2)}</pre> */}
                                    <div className="tbody grow w-full border-t">
                                        <div className="row flex w-full hover:bg-slate-50 font-normal text-slate-500 py-2 border-b px-4 items-center">
                                            <div className="cell grow w-40 max-w-50 text-left font-medium">Restore OTP ( for web restoration )</div>
                                            <div className="grow"></div>
                                        </div>
                                        <div className="row flex w-full hover:bg-slate-50 font-normal text-slate-500 py-2 border-b px-4 items-center">
                                            <div className="cell grow w-40 max-w-50 text-left ml-8">Dashboard</div>
                                            <div className="grow"> </div>
                                            <span className={`mr-4 text-sm ${endpoint_dashboardOtpSwitchState ? 'text-green-600' : ''}`}>{endpoint_dashboardOtpSwitchState ? 'Enabled' : 'Disabled'}</span>
                                            <Switch keepEnable={true} state={true} switchHandler={()=>{ endpoint_setDashboardOtpSwitchState(true) }}  colors={['gray-400','[#ff5e0e]']} className="mr-8" />
                                        </div>  
                                        <div className="row flex w-full hover:bg-slate-50 font-normal text-slate-500 py-2 border-b px-4 items-center">
                                            <div className="cell grow w-40 max-w-50 text-left ml-8">Email</div>
                                            <div className="grow"> </div>
                                            <span className={`mr-4 text-sm ${endpoint_emailOtpSwitchState ? 'text-green-600' : ''}`}>{endpoint_emailOtpSwitchState ? 'Enabled' : 'Disabled'}</span>
                                            <Switch keepEnable={true} state={true} switchHandler={()=>{ endpoint_setEmailOtpSwitchState(true) }}  colors={['gray-400','[#ff5e0e]']} className="mr-8" />
                                        </div>
                                        <div className="row flex w-full hover:bg-slate-50 font-normal text-slate-500 py-2 border-b px-4 items-center">
                                            <div className="cell grow w-40 max-w-50 text-left font-medium">Client Restore</div>
                                            <div className="grow"></div>
                                            <span className={`mr-4 text-sm ${endpoint_clientRestoreSwitchState ? 'text-green-600' : ''}`}>{endpoint_clientRestoreSwitchState ? 'Enabled' : 'Disabled'}</span>
                                            <div className="flex items-center mr-8">
                                                <Switch state={endpoint_clientRestoreSwitchState} switchHandler={()=>{ handleClientRestoreStatusSettings() }} colors={['gray-400','[#ff5e0e]']} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </Tile>}
                            {settingsEnabled === 'MS365' && <Tile colSpan={8} rowSpan={33} className="flex flex-col pb-4 overflow-hidden md:col-span-9 border">
                                <div className="title flex items-center justify-center align-middle p-4 border-b">
                                    <span className="flex flex-col">
                                        <span className="text-base md:text-lg lg:text-xl text-gray-800 font-medium">MS365 Security Settings</span>
                                    </span>
                                    <span className="grow"></span>
                                </div>
                                <div className="">
                                    <div className="tbody grow w-full border-t"></div>
                                </div>
                            </Tile>}
                            {settingsEnabled === 'Servers' && <Tile colSpan={8} rowSpan={33} className="flex flex-col pb-4 overflow-hidden md:col-span-9 border">
                                <div className="title flex items-center justify-center align-middle p-4 border-b">
                                    <span className="flex flex-col">
                                        <span className="text-base md:text-lg lg:text-xl text-gray-800 font-medium">Servers Security Settings</span>
                                    </span>
                                    <span className="grow"></span>
                                </div>
                                <div className="">
                                    <div className="tbody grow w-full border-t"></div>
                                </div>
                            </Tile>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Security;