import React, { FC, useState } from "react";
import { Breadcrumb, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "../components/core";
// import { ThemeContext } from "../contexts";
import { ReactSortable } from "react-sortablejs";
import { getAPICall } from "../services/APILayer/axiosMethodCalls";

/**
 * MenuManagement 
 * @returns 
 */
const MenuManagement: FC = () => {

    // const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};

    /* modal visibility state */
    const [menuModalVisibilityState, setMenuModalViabilityState] = useState(false);
    const [menuModalEditState, setMenuModalEditState] = useState("Add");
    const [groupModalVisibilityState, setGroupModalViabilityState] = useState(false);
    const [groupModalEditState, setGroupModalEditState] = useState("Add");
    const [pageModalVisibilityState, setPageModalViabilityState] = useState(false);
    const [pageModalEditState, setPageModalEditState] = useState("Add");

    const [activeDrop, setActiveDrop] = useState("")

    const [menuListState, setMenuListState] = useState<any[]>([]);
    const [groupListState, setGroupListState] = useState<any[]>([]);
    const [pageListState, setPageListState] = useState<any[]>([]);
    const [assetListState, setAssetListState] = useState<any[]>([]);

    /**
     * Function that activates assets in asset section
     * @param tab 
     * @returns 
     */
    const setDropActive = (tab: string) => {
        if( tab === activeDrop ) {
            setActiveDrop("");
            return;
        }
        setActiveDrop(tab);
        populateAssets();
    }

    /**
     * Function fetched all available pages from API
     * @returns 
     */
    const fetchAllPages = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/menu_items`);
            return response.data;
        }
        catch(error){
            return error;
        }
    }

    /**
     * Function fetched all available groups from API
     * @returns 
     */
    const fetchAllGroups = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/menu_groups`);
            return response.data;
        }
        catch(error){
            return error;
        }
    }

    /**
     * Function fetched all available menus from API
     * @returns 
     */
    const fetchAllMenus = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/menus`);
            return response.data;
        }
        catch(error){
            return error;
        }
    }

    /**
     * Default useEffect
     */
    // useEffect(()=>{
    //     const getAllMenus = async () => {
    //         let fetchedMenus = await fetchAllMenus();
    //         console.log({group: fetchedMenus});
    //         setMenuListState( fetchedMenus.menus );
    //     }
    //     const getAllGroups = async () => {
    //         let fetchedGroups = await fetchAllGroups();
    //         console.log({group: fetchedGroups});
    //         setGroupListState( fetchedGroups.menu_groups );
    //     }
    //     const getAllPages = async () => {
    //         let fetchedPages = await fetchAllPages();
    //         console.log({page: fetchedPages.menu_items[0].menu_item.title});
    //         setPageListState( fetchedPages.menu_items );
    //     }
    //     getAllMenus();
    //     getAllGroups();
    //     getAllPages();
    // },[]);

    /**
     * Function that populates assets
     */
    const populateAssets = async () => {
        switch (activeDrop) {
            case 'menu':
                let fetchedMenus = await fetchAllMenus();
                console.log({group: fetchedMenus});
                setAssetListState( fetchedMenus.menus );
                break;

            case 'group':
                let fetchedGroups = await fetchAllGroups();
                console.log({group: fetchedGroups});
                setAssetListState( fetchedGroups.menu_groups );
                break;

            case 'page':
                let fetchedPages = await fetchAllPages();
                console.log({page: fetchedPages.menu_items[0].menu_item.title});
                setAssetListState( fetchedPages.menu_items );
                break;
        
            default:
                break;
        } 
    }

    /**
     * Function responsible for opening appropriate modal and set it's edit mode
     * @param modelName 
     * @param editState 
     */
    const openModal = (modelName: string, editState: string) => {
        switch (modelName) {
            case 'menu':
                setMenuModalViabilityState(true);
                setMenuModalEditState((prev)=>{
                    let title = ''
                    if( editState === 'add' ) { title = 'Add' }
                    if( editState === 'edit' ) { title = 'Edit' }
                    return title;
                });
                break;

            case 'group':
                setGroupModalViabilityState(true);
                setGroupModalEditState((prev)=>{
                    let title = ''
                    if( editState === 'add' ) { title = 'Add' }
                    if( editState === 'edit' ) { title = 'Edit' }
                    return title;
                });
                break;

            case 'page':
                setPageModalViabilityState(true);
                setPageModalEditState((prev)=>{
                    let title = ''
                    if( editState === 'add' ) { title = 'Add' }
                    if( editState === 'edit' ) { title = 'Edit' }
                    return title;
                });
                break;
        
            default:
                break;
        }
    }

    return (
        <>
            <div className="p-6 grow flex flex-col">
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Menu Management</h1>
                <Breadcrumb arrBreadcrumb={[
                    {name: 'Dashboard', icon: 'fa-solid fa-home', link: '/dashboard'},
                    {name: 'Settings', icon: '', link: ''},
                    {name: 'Menu Management', icon: '', link: ''}
                ]} />

                <div className="flex w-full h-[92%] bg-white rounded-lg shadow">
                    <div className="sectionOrganization w-1/5 flex flex-col h-full border-r">
                        <div className="header flex w-full px-3 py-2 border-b h-[43px]">
                            <div className="grow text-base font-semibold">Organizations</div>
                        </div>
                        <div className="listOrganization grow bg-gray-50 bg-opacity-50 overflow-y-scroll customScroll">
                            <div className="flex gap-4 w-full py-2 px-3 cursor-pointer bg-slate-50 hover:bg-white  border-b border-l-4 border-l-slate-50 hover:border-l-orange-500">
                                <em className="fa-solid fa-building text-gray-600 ml-2"></em>
                                <div className="grow text-gray-600">ZeeQ</div>
                                <em className="fa-solid fa-caret-right mr-2 text-gray-500"></em>
                            </div>
                        </div>
                    </div>
                    <div className="sectionMenus w-1/5 flex flex-col h-full border-r">
                        <div className="header flex w-full px-3 py-2 border-b">
                            <div className="grow text-base font-semibold">Menus</div>
                            <Button variant="primary" solid={false} size="xs" className="flex gap-2 group"
                                onClick={()=>{ openModal('menu', 'add'); }}
                            >
                                <em className="fa-solid fa-plus text-xs"></em>
                                <div className="text-xs hidden group-hover:flex">Add</div>
                            </Button>
                        </div>
                        {/* <div className="listMenu grow bg-gray-50 bg-opacity-50 overflow-y-scroll customScroll"> */}
                        {menuListState.length > 0  && <ReactSortable
                            list={menuListState}
                            setList={setMenuListState}
                            className={`listMenu grow bg-gray-50 bg-opacity-50 overflow-y-scroll customScroll`}
                            sort={true}
                            // group={{ name: 'listMenu', pull: false, put: activeDrop === 'menu' ? true : false}}
                            group={{ name: 'listMenu', pull: true, put: true }}
                            animation={200}
                            delayOnTouchOnly={true}
                            delay={2}
                            style={{ }}
                            onClone={(e)=>{ }}
                            onAdd={(e)=>{ }}
                        >
                            {menuListState.map((menu, menuIndex)=>(
                                <div key={menuIndex} className="flex gap-4 w-full py-2 px-3 cursor-pointer bg-slate-50 hover:bg-white  border-b border-l-4 border-l-slate-50 hover:border-l-orange-500 group">
                                    <em className="fa-regular fa-list-tree text-gray-600 ml-2"></em>
                                    <div className="grow text-gray-600 whitespace-nowrap">{menu?.title || ''}</div>
                                    <em className="fa-regular fa-pen px-2 rounded-md text-gray-500 invisible group-hover:visible hover:text-orange-500"
                                        onClick={()=>{ openModal('menu', 'edit'); }}
                                    ></em>
                                    <em className="fa-solid fa-caret-right mr-2 text-gray-500"></em>
                                </div>
                            ))}
                        </ReactSortable>}
                        {/* </div> */}
                    </div>
                    <div className="sectionGroups w-1/5 flex flex-col h-full border-r">
                        <div className="header flex w-full px-3 py-2 border-b">
                            <div className="grow text-base font-semibold">Groups</div>
                            <Button variant="primary" solid={false} size="xs" className=" flex gap-2 group"
                                onClick={()=>{ openModal('group', 'add'); }}
                            >
                                <em className="fa-solid fa-plus text-xs"></em>
                                <div className="text-xs hidden group-hover:flex">Add</div>
                            </Button>
                        </div>
                        { groupListState.length > 0 && <ReactSortable
                            list={groupListState}
                            setList={setGroupListState}
                            className={`listGroups grow bg-gray-50 bg-opacity-50 overflow-y-scroll customScroll`}
                            sort={true}
                            // group={{ name: 'listGroups', pull: false, put: activeDrop === 'group' ? true : false}}
                            group={{ name: 'listGroups', pull: true, put: true }}
                            animation={200}
                            delayOnTouchOnly={true}
                            delay={2}
                            style={{ }}
                            onClone={(e)=>{ }}
                            onAdd={(e)=>{ }}
                        >
                            {groupListState.map((group, groupIndex)=>(
                                // <>{JSON.stringify(group.title)}</>
                                <div key={groupIndex} className="flex gap-4 w-full py-2 px-3 cursor-pointer bg-slate-50 hover:bg-white  border-b border-l-4 border-l-slate-50 hover:border-l-orange-500 group">
                                    <em className="fa-regular fa-layer-group text-gray-600 ml-2"></em>
                                    <div className="grow text-gray-600 whitespace-nowrap">{group?.title || ''}</div>
                                    <em className="fa-regular fa-pen px-2 rounded-md text-gray-500 invisible group-hover:visible hover:text-orange-500"
                                        onClick={()=>{ openModal('group', 'edit'); }}
                                    ></em>
                                    <em className="fa-solid fa-caret-right mr-2 rounded-md text-gray-500"></em>
                                </div>
                            ))}
                        </ReactSortable> }
                    </div>
                    <div className="sectionPages w-1/5 flex flex-col h-full border-r">
                        <div className="header flex w-full px-3 py-2 border-b">
                            <div className="grow text-base font-semibold">Pages</div>
                            <Button variant="primary" solid={false} size="xs" className=" flex gap-2 group"
                                onClick={()=>{ openModal('page', 'add'); }}
                            >
                                <em className="fa-solid fa-plus text-xs"></em>
                                <div className="text-xs hidden group-hover:flex">Add</div>
                            </Button>
                        </div>
                        { pageListState.length > 0  && <ReactSortable
                            list={pageListState}
                            setList={setPageListState}
                            className={`listPages grow bg-gray-50 bg-opacity-50 overflow-y-scroll customScroll`}
                            sort={true}
                            // group={{ name: 'listPages', pull: false, put: activeDrop === 'page' ? true : false}}
                            group={{ name: 'listPages', pull: false, put: true }}
                            animation={200}
                            delayOnTouchOnly={true}
                            delay={2}
                            style={{ }}
                            onClone={(e)=>{ }}
                            onAdd={(e)=>{ }}
                        >
                            {pageListState.map((page, pageIndex)=>(
                                <div key={pageIndex} className="flex gap-4 w-full py-2 px-3 cursor-pointer bg-slate-50 hover:bg-white  border-b border-l-4 border-l-slate-50 hover:border-l-orange-500 group">
                                    <em className="fa-regular fa-file text-gray-600 ml-2"></em>
                                    <div className="grow text-gray-600 whitespace-nowrap">{page?.menu_item?.title || ''}</div>
                                    <em className="fa-regular fa-pen px-2 rounded-md text-gray-500 invisible group-hover:visible hover:text-orange-500"
                                        onClick={()=>{ openModal('page', 'edit'); }}
                                    ></em>
                                </div>
                            ))}
                        </ReactSortable> }
                    </div>
                    <div className="sectionAssets w-1/5 flex flex-col h-full">
                        <div className="header flex w-full gap-1 px-3 h-[42px]">
                            <div className="grow text-base font-semibold pt-2">Assets</div>
                            <Button variant="primary" solid={activeDrop === 'menu' ? true : false} size="xs" className="flex gap-2 rounded-b-none border-b-0 mt-4 group"
                                onClick={()=>{
                                    setDropActive('menu')
                                    fetchAllPages();
                                }}
                            >
                                <em className="fa-solid fa-list-tree h-4 w-4 text-sm"></em>
                                <div className={`text-sm ${activeDrop === 'menu' ? 'block' : 'hidden'} group-hover:block`}>Menu</div>
                            </Button>
                            <Button variant="primary" solid={activeDrop === 'group' ? true : false} size="xs" className="flex gap-2 rounded-b-none border-b-0 mt-4 group"
                                onClick={()=>{
                                    setDropActive('group')
                                    fetchAllGroups();
                                }}
                            >
                                <em className="fa-solid fa-layer-group h-4 w-4 text-sm"></em>
                                <div className={`text-sm ${activeDrop === 'group' ? 'block' : 'hidden'} group-hover:block`}>Group</div>
                            </Button>
                            <Button variant="primary" solid={activeDrop === 'page' ? true : false} size="xs" className="flex gap-2 rounded-b-none border-b-0 mt-4 group"
                                onClick={()=>{
                                    setDropActive('page')
                                    fetchAllMenus()
                                }}
                            >
                                <em className="fa-solid fa-file h-4 w-4 text-sm"></em>
                                <div className={`text-sm ${activeDrop === 'page' ? 'block' : 'hidden'} group-hover:block`}>Page</div>
                            </Button>
                        </div>
                        <div className="w-full bg-gray-50 bg-opacity-50 border-t shadow-sm">
                            <div className="relative bg-white w-full h-[52px] border-b">
                                <input type='text' placeholder="search" className="absolute rounded px-2 py-1 m-2 left-0 right-0 outline-none border border-gray-200" />
                            </div>
                        </div>
                        { assetListState.length > 0 && <ReactSortable
                            list={assetListState}
                            setList={setAssetListState}
                            className={`assetList grow bg-gray-50 bg-opacity-50 overflow-y-scroll customScroll`}
                            sort={true}
                            group={{ name: 'assetList', pull: 'clone', put: true}}
                            animation={200}
                            delayOnTouchOnly={true}
                            delay={2}
                            style={{ }}
                            onClone={(e)=>{ }}
                            onAdd={(e)=>{ }}
                        >
                            {assetListState.map((asset, assetIndex)=>(
                                <>{JSON.stringify(asset)}</>
                                // <div key={pageIndex} className="flex gap-4 w-full py-2 px-3 cursor-pointer bg-slate-50 hover:bg-white  border-b border-l-4 border-l-slate-50 hover:border-l-orange-500 group">
                                //     <em className="fa-solid fa-grip-vertical text-gray-400"></em>
                                //     {activeDrop === 'menu' && <em className="fa-regular fa-list-tree text-gray-600 ml-2"></em>}
                                //     {activeDrop === 'group' && <em className="fa-regular fa-layer-group text-gray-600 ml-2"></em>}
                                //     {activeDrop === 'file' && <em className="fa-regular fa-file text-gray-600 ml-2"></em>}
                                //     <div className="grow text-gray-600 whitespace-nowrap">{page.name}</div>
                                //     <em className="fa-regular fa-pen px-2 rounded-md text-gray-500 invisible group-hover:visible hover:text-orange-500"></em>
                                // </div>
                            ))}
                        </ReactSortable> }
                    </div>
                </div>

            </div>

            {/* Modals */}
            <Modal modalVisible={menuModalVisibilityState} setModalVisibility={setMenuModalViabilityState} className={`w-1/2 h-[600px]`}>
                <ModalHeader modalVisible={menuModalVisibilityState} setModalVisibility={setMenuModalViabilityState}>
                    <div className="inline mr-3">Menu</div>
                    <div className="inline text-xs px-3 py-1 rounded bg-orange-200 text-orange-500">{menuModalEditState}</div>
                </ModalHeader>
                <ModalBody></ModalBody>
                <ModalFooter>
                    <Button variant="primary" size='sm' solid={false} className={`w-[100px]`}
                        onClick={()=>{ setMenuModalViabilityState(false) }}
                    >Dismiss</Button>
                    <Button variant="primary" size='sm' className={`w-[100px]`}>{menuModalEditState === 'Add' ? 'Add' : 'Update'}</Button>
                </ModalFooter>
            </Modal>

            <Modal modalVisible={groupModalVisibilityState} setModalVisibility={setGroupModalViabilityState} className={`w-1/2 h-[600px]`}>
                <ModalHeader modalVisible={groupModalVisibilityState} setModalVisibility={setGroupModalViabilityState}>
                    <div className="inline mr-3">Group</div>
                    <div className="inline text-xs px-3 py-1 rounded bg-orange-200 text-orange-500">{groupModalEditState}</div>
                </ModalHeader>
                <ModalBody></ModalBody>
                <ModalFooter>
                    <Button variant="primary" size='sm' solid={false} className={`w-[100px]`}
                        onClick={()=>{ setGroupModalViabilityState(false) }}
                    >Dismiss</Button>
                    <Button variant="primary" size='sm' className={`w-[100px]`}>{groupModalEditState === 'Add' ? 'Add' : 'Update'}</Button>
                </ModalFooter>
            </Modal>

            <Modal modalVisible={pageModalVisibilityState} setModalVisibility={setPageModalViabilityState} className={`w-1/2 h-[600px]`}>
                <ModalHeader modalVisible={pageModalVisibilityState} setModalVisibility={setPageModalViabilityState}>
                    <div className="inline mr-3">Page</div>
                    <div className="inline text-xs px-3 py-1 rounded bg-orange-200 text-orange-500">{pageModalEditState}</div>
                </ModalHeader>
                <ModalBody className="p-6 flex flex-col gap-3">
                    <div className={`flex gap-3 px-4 w-full h-[40px]`}>
                        <label className={`flex gap-3 w-3/12`} htmlFor='route'>
                            <span className="font-semibold text-orange-500">Route</span>
                        </label>
                        <input 
                            placeholder="route"
                            type='text'
                            className="grow h-[40px] border p-2 rounded-md outline-none"
                            id='route' 
                            onChange={(e)=>{ }}
                            onFocus={()=>{ }}
                        />
                    </div>
                    <div className={`flex gap-3 px-4 w-full h-[40px]`}>
                        <label className={`flex gap-3 w-3/12`} htmlFor='title'>
                            <span className="font-semibold text-orange-500">Title</span>
                        </label>
                        <input 
                            placeholder="title"
                            type='text'
                            className="grow h-[40px] border p-2 rounded-md outline-none"
                            id='title' 
                            onChange={(e)=>{ }}
                            onFocus={()=>{ }}
                        />
                    </div>
                    <div className={`flex gap-3 px-4 w-full h-[40px]`}>
                        <label className={`flex gap-3 w-3/12`} htmlFor='title'>
                            <span className="font-semibold text-orange-500">Icon</span>
                        </label>
                        <input 
                            placeholder="icon"
                            type='text'
                            className="grow h-[40px] border p-2 rounded-md outline-none"
                            id='icon' 
                            onChange={(e)=>{ }}
                            onFocus={()=>{ }}
                        />
                    </div>
                    <div className={`flex gap-3 px-4 w-full h-[40px]`}>
                        <label className={`flex gap-3 w-3/12`} htmlFor='new'>
                            <span className="font-semibold text-orange-500">Indicate new</span>
                        </label>
                        <label>
                            <input 
                                placeholder="icon"
                                type='radio'
                                className="grow h-[40px] border p-2 rounded-md outline-none"
                                id='new'
                                name='new'
                                value='yes'
                            /> Yes
                        </label>
                        <label>
                            <input 
                                placeholder="icon"
                                type='radio'
                                className="grow h-[40px] border p-2 rounded-md outline-none"
                                id='new'
                                name='name'
                                value='no'
                            /> No
                        </label>
                    </div>
                    
                </ModalBody>
                <ModalFooter>
                    <Button variant="primary" solid={false} className={`w-[100px]`}
                        onClick={()=>{ setPageModalViabilityState(false) }}
                    >Dismiss</Button>
                    <Button variant="primary" className={`w-[100px]`}>{pageModalEditState === 'Add' ? 'Add' : 'Update'}</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default MenuManagement;
