import React from "react";
import { Tile } from "../../../core";


const Tile52= ( ) =>{
    return (
        <>
        <Tile rowSpan={15} colSpan={3} className="  shadow-none  p-0 ">
                <div className=" flex flex-col   h-full mb-1 p-5 text-[#3A3541]  ">

                    <div className="flex flex-row justify-between w-full  ">
                        <div className={`text-base  font-semibold`}>Total Sale</div>
                        <i className="fa-solid fa-ellipsis-vertical text-center justify-center items-start flex"></i>   
                    </div>
                    
                    <div className="text-2xl leading-8 font-medium mb-5">3000</div>
                    <i className=" fa-light fa-atom-simple text-9xl  h-full border text-center items-center flex  justify-center py-10  "></i>   
                    </div>
        </Tile>
        </>
    )
}

export default Tile52