/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useState, useLayoutEffect } from "react";
import { Button, Dropdown, Tile } from "../../components/core";
import { SideDrawerContext, ThemeContext } from "../../contexts";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';
import ProfileContent from './MS365Dashboard/ProfileContent'
import { getAPICall, postAPICall } from "../../services/APILayer/axiosMethodCalls";
import { Link } from "react-router-dom";



const Ms365Dashboard = () =>{

    const [ activeTabs, setActiveTabs ] = useState<string>('stats');
    // const [ msSetupStatus, setMsSetupStatus ] = useState<boolean>(false);
    const [ ms365App, setMs365App ] = useState<any>({});
    const sideDrawer = useContext(SideDrawerContext)

    const getMyMS365App = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/ms365/apps/me`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        sideDrawer.setLevelOneDrawerVisibility(false);

        if( Object.keys( ms365App ).length > 0 ) {
            return;
        }

        const fetchMyMS365App = async () => {
            getMyMS365App()
                .then((data)=>{
                    console.log({msc65:data});
                    setMs365App(data)
                })
                .catch((error)=>{ console.log(error) })
                .finally()
        }
        fetchMyMS365App();
    },[])

    return(
        <>
            <div className={`p-6 grow flex flex-col`}>
                    {/* <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Microsoft 365</h1>
                    <Breadcrumb arrBreadcrumb={[
                        { icon: 'fa-solid fa-bell-on', link: '', name: 'Home' },
                        { icon: '', link: '', name: 'Microsoft 365' }
                    ]} /> */}
                    <div className={`grow flex flex-col`}> 
                        <div className="grid grid-cols-5 gap-6 w-full ">
                            <Tile colSpan={1} rowSpan={26} className="hidden md:block p-4 overflow-hidden">
                                <div className="md:col-span-2 lg:col-span-1 gap-6 mb-6">
                                    <div className="flex flex-col items-center justify-center text-center w-full">
                                        <div className="h-36 w-36 aspect-square my-6 rounded-md">
                                            <img src="/assets/img/logo/atempo/TINA_LOGO.svg" alt="Atempo Tina" />
                                        </div>
                                        <div className="bg-slate-100 w-full mb-6 py-2 rounded-md text-[#ff5e0e] font-medium">MICROSOFT 365 BACKUP</div>
                                    </div>
                                    <div className="mt-10">
                                        <div className="text-lg font-medium text-slate-600 mb-2">Tags</div>
                                        <div className="flex flex-wrap gap-2 ">
                                            <div className="text-slate-600 bg-slate-100 rounded-md px-2 py-1 text-sm max-w-min whitespace-nowrap hover:bg-orange-50 hover:text-[#ff5e0e] normal-case">Office365</div>
                                            <div className="text-slate-600 bg-slate-100 rounded-md px-2 py-1 text-sm max-w-min whitespace-nowrap hover:bg-orange-50 hover:text-[#ff5e0e] normal-case">OutLook</div>
                                            <div className="text-slate-600 bg-slate-100 rounded-md px-2 py-1 text-sm max-w-min whitespace-nowrap hover:bg-orange-50 hover:text-[#ff5e0e] normal-case">Contacts </div>
                                            <div className="text-slate-600 bg-slate-100 rounded-md px-2 py-1 text-sm max-w-min whitespace-nowrap hover:bg-orange-50 hover:text-[#ff5e0e] normal-case">OneDrive</div>
                                            <div className="text-slate-600 bg-slate-100 rounded-md px-2 py-1 text-sm max-w-min whitespace-nowrap hover:bg-orange-50 hover:text-[#ff5e0e] normal-case">Calendar</div>
                                            <div className="text-slate-600 bg-slate-100 rounded-md px-2 py-1 text-sm max-w-min whitespace-nowrap hover:bg-orange-50 hover:text-[#ff5e0e] normal-case">Backup</div>
                                            <div className="text-slate-600 bg-slate-100 rounded-md px-2 py-1 text-sm max-w-min whitespace-nowrap hover:bg-orange-50 hover:text-[#ff5e0e] normal-case">Security</div>
                                        </div>
                                    </div>
                                    <div className="mt-10">
                                        <div className="text-lg font-medium text-slate-600 mb-2">Supporting Links</div>
                                        <a href="https://www.atempo.com/support-en/contacting-support/" target="_blank" rel="nofollow noreferrer">
                                            <div className="text-[#ff5e0e] text-sm cursor-pointer"><i className="fa-solid fa-angle-right pr-2 mb-2"></i>OEM Support</div>
                                        </a>
                                        <a href="https://www.atempo.com/products/tina-time-navigator-enterprise-solution-for-backup-and-recovery/" target="_blank" rel="nofollow noreferrer">
                                            <div className="text-[#ff5e0e] text-sm cursor-pointer"><i className="fa-solid fa-angle-right pr-2 mb-2"></i>OEM Knowledge Base</div>
                                        </a>
                                        <a href="https://www.atempo.com/mandatory-legal-notice/" target="_blank" rel="nofollow noreferrer">
                                            <div className="text-[#ff5e0e] text-sm cursor-pointer"><i className="fa-solid fa-angle-right pr-2 mb-2"></i>OEM Privacy Policy</div>
                                        </a>
                                    </div>
                                </div>
                            </Tile>
                            <Tile colSpan={4} rowSpan={26} className="p-4 mb-10 pb-10 md:pb-4 overflow-hidden">
                                <div className="grid col-span-5 md:col-span-3 lg:col-span-4 gap">
                                    <div className="md:col-span-1 lg:col-span-1 gap-6 pb-3 mb-4 border-b border-gray-400">
                                        <div className="text-slate-600 uppercase text-xl font-medium items-center flex">Microsoft 365 Backup (Powered by Atempo Tina)</div>
                                    </div>
                                    <div className="w-full flex flex-col md:flex-row items-center justify-start align-middle text-center mb-6 gap-2">
                                        <Button className={`border ${activeTabs === 'stats' ? 'bg-opacity-100 text-white hover:text-white hover:bg-opacity-90' : 'bg-opacity-0 text-[#FF5E0E]'} bg-[#FF5E0E] border-none shadow-md rounded-md font-semibold w-full lg:w-1/4`} solid={false}
                                            onClick={()=>{
                                                setActiveTabs('stats')
                                            }}
                                        >Stats</Button>
                                        <Button className={`border ${activeTabs === 'info' ? 'bg-opacity-100 text-white hover:text-white hover:bg-opacity-90' : 'bg-opacity-0 text-[#FF5E0E]'} bg-[#FF5E0E] border-none shadow-md rounded-md font-semibold w-full lg:w-1/4`} solid={false}
                                            onClick={()=>{
                                                setActiveTabs('info')
                                            }}
                                        >Info</Button>
                                        <Button className={`border ${activeTabs === 'features' ? 'bg-opacity-100 text-white hover:text-white hover:bg-opacity-90' : 'bg-opacity-0 text-[#FF5E0E]'} bg-[#FF5E0E] border-none shadow-md rounded-md font-semibold w-full lg:w-1/4`} solid={false}
                                            onClick={()=>{
                                                setActiveTabs('features')
                                            }}
                                        >Features</Button>
                                    </div>
                                    <div className="overflow-y-scroll px-2 h-[550px] md:h-[600px] customScroll mt-4 pr-4 pb-10 overflow-hidden">
                                        
                                        {/* { activeTabs==='stats'  && <TinaSetup /> } */}
                                        { activeTabs==='stats' && Object.keys( ms365App?.ms365_app || {} ).length < 1 && <TinaSetup /> }
                                        { activeTabs==='stats' && Object.keys( ms365App?.ms365_app || {} ).length > 0 && ms365App?.ms365_app?.isEnabled === false && <ProgressStatus /> }
                                        { activeTabs==='stats' && Object.keys( ms365App?.ms365_app || {} ).length > 0 && ms365App?.ms365_app?.isEnabled === true && <TinaStats /> }
                                        { activeTabs==='info' && <TinaInfo /> }
                                        { activeTabs==='features' && <TinaFeatures /> }
                                    </div>
                                </div>
                            </Tile>
                        </div>
                    </div>
            </div>
        </>
    )
}

export default Ms365Dashboard


const TinaSetup = () => {

    let sideDrawerContext = useContext(SideDrawerContext);

    const [graphDataUsers, setGraphDataUsers] = useState<any>(null);

    const [ tenantId, setTenantId ] = useState<string>('');

    const [ addAppResponse, setAddAppResponse ] = useState<any>({});
    const [ addUserResponse, setUserAppResponse ] = useState<any>({});
    // const [ deployResponse, setDeployResponse ] = useState<any>({});

    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    const [userIncludedProducts, setUserIncludedProducts] = useState<any>({});
    const [userExcludedProducts, setUserExcludedProducts] = useState<any>({});

    const [oneDriveSelected, setOneDriveSelected] = useState<string[]>([]);
    const [userIncludedOneDrive, setUserIncludedOneDrive] = useState<any>({});
    const [userExcludedOneDrive, setUserExcludedOneDrive] = useState<any>({});

    useEffect(()=>{
        setUserIncludedProducts(graphDataUsers || [])
        setUserExcludedOneDrive(graphDataUsers || [])
    },[graphDataUsers])

    useEffect(()=>{
        if( graphDataUsers === null ) {
            return
        }
        if( tenantId !== '' ) {
            return;
        }
        let objTid: any[] = [];
        Object.keys(sessionStorage).map((k:string)=>{ 
            if(JSON.parse(sessionStorage[k]).realm) {
                objTid.push(JSON.parse(sessionStorage[k]));
            } 
        })
        console.log({tid: objTid[0].realm})
        if( objTid.length > 0 ) {
            setTenantId( objTid[0].realm )
        }
    },[graphDataUsers])

    useEffect(()=>{
        // console.log({userIncludedProducts},{userExcludedProducts})
        sideDrawerContext.setFirstDrawerChild(<FirstDrawerChild
            title="For Calender, Contact and Outlook"
            allUsers={graphDataUsers}
            userIncluded={userIncludedProducts}
            setUserIncluded={setUserIncludedProducts}
            userExcluded={userExcludedProducts}
            setUserExcluded={setUserExcludedProducts}
        />)
    },[userIncludedProducts,userExcludedProducts])

    useEffect(()=>{
        // console.log({userIncludedProducts},{userExcludedProducts})
        sideDrawerContext.setFirstDrawerChild(<FirstDrawerChild
            title="For Calender, Contact and Outlook"
            allUsers={graphDataUsers}
            userIncluded={userIncludedOneDrive}
            setUserIncluded={setUserIncludedOneDrive}
            userExcluded={userExcludedOneDrive}
            setUserExcluded={setUserExcludedOneDrive}
        />)
    },[userIncludedOneDrive,userExcludedOneDrive])

    const request = {
        scopes: ["User.Read"],
        prompt: 'select_account'
    }

    const { login, result, error } = useMsalAuthentication(InteractionType.Silent, request);
    const { accounts } = useMsal();

    useEffect(() => {
        if(result){
          console.log(result);
        }
    }, [result]);

    const handleLogin = () => {
        login(InteractionType.Redirect, request);
    }

    const addMS365App = async (data: any) => {
        try {
            const response = await postAPICall(`${process.env.REACT_APP_API_URL}/products/ms365/apps/add_app`,{
                insert:{
                    ...data
                }
            });
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const addMS365User = async (data: any) => {
        try {
            const response = await postAPICall(`${process.env.REACT_APP_API_URL}/products/ms365/apps/add_users`,{
                insert:{
                    ...data
                }
            });
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const handleSubmitSetup = async () => {
        // if(selectedProducts.length < 1) {

        // }

        // 'OutLook (MailBox)','Calendar','Contact'
        let finalSubmission = {
            outlook: [],
            calender: [],
            contact: [],
            oneDrive: [],
        }

        selectedProducts.map((d:string)=>{
            if( d === 'OutLook (MailBox)' ) {
                finalSubmission.outlook = userIncludedProducts?.value || [];
            } else if( d === 'Calendar' ) {
                finalSubmission.calender = userIncludedProducts?.value || [];
            } else if( d === 'Contact' ) {
                finalSubmission.contact = userIncludedProducts?.value || [];
            }
        });

        oneDriveSelected.map((d:string)=>{
            finalSubmission.oneDrive = userIncludedOneDrive?.value || [];
        });

        console.log({finalSubmission})

        
        await addMS365App({
            "catalog_id": "63a77a447c75c478fc9fcf09",
            "azure_id": "63a73053bb0a88e911e91005",
            "tenant_id": tenantId,
            "users":{
                ...finalSubmission
            }
        }).then((response:any)=>{
            setAddAppResponse(response)
        }).catch((error)=>{
            console.log(error)
        }).finally(()=>{})
            
        

    }

    useEffect(()=>{
        if( Object.keys( addAppResponse ).length < 1 ) {
            return
        }

        const addingUsers = async () => {

            await addMS365User({
                app_id: addAppResponse?.ms365_app?._new_app?._id || null,
                tina_token: addAppResponse?.ms365_app?._tina_auth_token || null
            }).then((response:any)=>{
                setUserAppResponse(response)
            }).catch((error)=>{
                console.log(error)
            }).finally(()=>{})
        }

        addingUsers();

    },[addAppResponse])

    useEffect(()=>{
        if( Object.keys(addUserResponse).length < 1 ) {
            return;
        }
        document.location.reload();
    },[addUserResponse])
    
    return (
        <>
            <AuthenticatedTemplate>
                {/* {JSON.stringify(addAppResponse)} */}
                {
                    // Object.keys( addAppResponse ).length < 1 ?
                    (accounts.length > 0 && (
                        <>
                            <ProfileContent homeId={accounts[0].homeAccountId} name={accounts[0].name as string} graphDataUsers={graphDataUsers} setGraphDataUsers={setGraphDataUsers}  />
                            {/* {JSON.stringify(graphDataUsers.value)} */}
                            <div className="flex flex-col md:flex-row gap-4 w-full mb-10">
                                <Tile colSpan={6} rowSpan={4} className="w-full p-4 relative border">
                                    <div className="text-[#FF5E0E] font-semibold text-xl">Total Users</div>
                                    <i className="fa-duotone fa-users text-[#FF5E0E] text-3xl absolute bottom-4 left-6 xl:block md:hidden"></i>
                                    <div className="grow"></div>
                                    <div className="text-[#FF5E0E] text-4xl font-medium absolute bottom-6  right-6 whitespace-nowrap">{(graphDataUsers?.value || []).length}</div>
                                </Tile>
                                <Tile colSpan={6} rowSpan={4} className="w-full p-4 relative border">
                                    <div className="text-[#FF5E0E] font-semibold text-xl">User Quota</div>
                                    <i className="fa-duotone fa-user-plus text-[#FF5E0E] text-3xl absolute bottom-4 left-6 xl:block md:hidden"></i>
                                    <div className="grow"></div>
                                    <div className="text-[#FF5E0E] text-4xl font-medium absolute bottom-6  right-6 whitespace-nowrap">
                                        {(userIncludedProducts?.value || []).length}/<span className="text-xl text-[#ff5e0e]">{graphDataUsers && (graphDataUsers?.value || []).length}<span className="text-xs"> User(s)</span></span>
                                    </div>

                                </Tile>
                                <Tile colSpan={6} rowSpan={4} className="w-full p-4 relative border">
                                    <div className="text-[#FF5E0E] font-semibold text-xl">Storage Quota </div>
                                    <i className="fa-duotone fa-server text-[#FF5E0E] text-3xl absolute bottom-4 left-6 xl:block md:hidden"></i>
                                    <div className="grow"></div>
                                    <div className="text-[#FF5E0E] text-4xl font-medium absolute bottom-6  right-6 whitespace-nowrap">
                                        {(userIncludedProducts?.value || []).length * 100}/<span className="text-xl text-[#ff5e0e]">{graphDataUsers && (graphDataUsers?.value || []).length * 100}<span className="text-xs text-[#ff5e0e]"> GB</span></span>
                                    </div>
                                </Tile>
                            </div>
                            
                            <p className="pb-6 mb-6 border-b">MS365 ADMIN : <strong className="text-[#ff5e0e]">{accounts[0].name}</strong></p>

                            {/* {JSON.stringify(graphDataUsers.value)} */}

                            <span className="flex flex-col md:flex-row items-end gap-4 w-full mb-2">
                                <span className="grow z-30 w-full">
                                    <>
                                        <div className="text-base font-semibold mb-2">
                                            <span>Select for OutLook, Calender and Contact <span className="text-red-500">*</span></span>
                                            <span className="ml-4 text-sm text-gray-500">included {(userIncludedProducts?.value || []).length} user(s)</span>
                                        </div>
                                        <Dropdown 
                                            multiple={true}
                                            required={true}
                                            placeholder='Select Product(s)'
                                            children='abc'
                                            options={['OutLook (MailBox)','Calendar','Contact']}
                                            visibleOptions={3}
                                            onChange={(e)=>{
                                                setSelectedProducts(e.target.value)
                                            }}
                                        />
                                    </>
                                </span>
                                <Button
                                    disabled={(graphDataUsers?.value || []).length > 0 ? false : true}
                                    onClick={()=>{
                                        sideDrawerContext.setLevelOneDrawerVisibility(false);
                                        sideDrawerContext.setFirstDrawerChild(<FirstDrawerChild
                                            title="For Calender, Contact and Outlook"
                                            allUsers={graphDataUsers}
                                            userIncluded={userIncludedProducts}
                                            setUserIncluded={setUserIncludedProducts}
                                            userExcluded={userExcludedProducts}
                                            setUserExcluded={setUserExcludedProducts}
                                        />)
                                        sideDrawerContext.setLevelOneDrawerVisibility(true);
                                    }}
                                    className={`w-full md:w-52 lg:w-60 `}
                                >Manage Users</Button>
                            </span>
                            <span className="flex flex-col md:flex-row items-end gap-4 mb-4">
                                <span className="grow z-20 w-full">
                                    <>
                                        <div className="text-base font-semibold mb-2">
                                            <span>Select OneDrive</span>
                                            <span className="ml-4 text-sm text-gray-500">included {(userIncludedOneDrive?.value || []).length} user(s)</span>
                                        </div>
                                        <Dropdown 
                                            multiple={true}
                                            required={true}
                                            placeholder='Select OneDrive'
                                            children='abc'
                                            options={['OneDrive']}
                                            visibleOptions={3}
                                            onChange={(e)=>{
                                                setOneDriveSelected(e.target.value)
                                            }}
                                        />
                                    </>
                                </span>
                                <Button
                                    disabled={(graphDataUsers?.value || []).length > 0 ? false : true}
                                    onClick={()=>{
                                        sideDrawerContext.setLevelOneDrawerVisibility(false);
                                        sideDrawerContext.setFirstDrawerChild(<FirstDrawerChild
                                            title="For OneDrive"
                                            allUsers={graphDataUsers}
                                            userIncluded={userIncludedOneDrive}
                                            setUserIncluded={setUserIncludedOneDrive}
                                            userExcluded={userExcludedOneDrive}
                                            setUserExcluded={setUserExcludedOneDrive}
                                        />)
                                        sideDrawerContext.setLevelOneDrawerVisibility(true);
                                    }}
                                    className="w-full md:w-52 lg:w-60"
                                >Manage Users</Button>
                            </span>
                            <div className="w-full md:w-52 mt-14 mb-6 ">
                                <Button
                                    block={true}
                                    onClick={()=>{
                                        handleSubmitSetup()
                                    }}
                                >Submit</Button>
                            </div>
                        </>
                    ))
                    //  :
                    // (
                    //     <div>
                    //         <em className="fa-solid fa-loader fa-animate-spin"></em>
                    //         {}
                    //     </div>
                    // )
                }
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div className="flex flex-col items-center justify-start align-middle h-full pt-16">
                    <div className="text-3xl text-slate-600 mb-10">Setup your Microsoft 365 Backup</div>
                    <em className="fa-brands fa-windows text-[150px] text-[#FF5E0E] text-opacity-80 mb-10"></em>
                    <Button size="sm" block={false} onClick={handleLogin}>Login to your microsoft account</Button>
                </div>
            </UnauthenticatedTemplate>
        </>
    )
}

const TinaStats = () => {
    return (
        <>
            {/* Stats <br/> */}
            <div className="flex flex-col lg:flex-row items-center justify-center align-middle gap-10 mt-14">
                <div className="p-6 flex cursor-pointer bg-slate-100 items-center justify-center align-middle text-center rounded-md shadow-md hover:shadow-lg">
                    <Link to="/microsoft-365/Exchange" className="flex items-center justify-center flex-col w-[340px]">
                        <img className="min-w-[150px] h-[150px] my-14 flex justify-center items-center" src="/assets/img/logo/ms365/Microsoft_Exchange.svg" alt="Microsoft Exchange" />
                        <div className="text-center items-center align-middle flex justify-center text-slate-500 font-medium text-2xl  whitespace-nowrap px-6 border-t py-4">Go to Microsoft Exchange</div>
                    </Link>
                </div>
                <div className="p-6 flex cursor-pointer bg-slate-100 items-center justify-center align-middle text-center rounded-md shadow-md hover:shadow-lg">
                    <Link to="/microsoft-365/OneDrive" className="flex items-center justify-center flex-col w-[340px]">
                        <img className="min-w-[150px] h-[150px] my-14 flex justify-center items-center" src="/assets/img/logo/ms365/MS_OneDrive.svg" alt="Microsoft OneDrive" />
                        <div className="text-center items-center align-middle flex justify-center text-slate-500 font-medium text-2xl  whitespace-nowrap px-6 border-t py-4">Go To Microsoft OneDrive</div>
                    </Link>
                </div>
            </div>
        </>
    )
}

const TinaInfo = () => {
    return (
        <>
            <div className="text-2xl font-semibold text-slate-500 items-center flex justify-center text-center mb-10">Atempo Transforms Backup by Focusing on What Really Matters – Data Recovery</div>
            <img src="assets/img/microsoft365.jpg" className="py-2 px-10 mb-16" alt="Tina" width=""/>
            <div className="text-slate-500 text-sm font-medium mb-2">Tina Time Navigator (TINA)</div>
            <div className="text-slate-500 text-sm font-normal text-justify flex-wrap">Tina’s unique “Time Navigation” capability provides an intuitive UI to manage virtual environment backups. Administrators can simply view
                the tree structure and manage all virtual machine images directly in Tina’s interfaces. Additional granularity enables administrators to view
                individual VM disks and navigation to individual directories and files. Tina’s Time Navigation feature allows for point in time restore without
                scripting for VMs, VM disks and files.
            </div>
            <div className="text-2xl font-semibold text-slate-500 items-center flex justify-center text-center py-6 mt-10">Atempo Transforms Backup by Focusing on What Really Matters – Data Recovery</div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 py-6">
                <div className="flex flex-col py-8 justify-center items-center align-middle text-center rounded-md bg-slate-50 shadow-md hover:shadow-lg">
                    <div className="text-xl text-slate-500 mb-10 capitalize">UNIQUE RESTORE APPROACH</div>
                    <i className="h-36 w-36 text-8xl text-[#ff5e0e] items-center justify-center text-center align-middle flex rounded-md fa-duotone fa-window-restore"></i>
                </div>
                <div className="flex flex-col py-8 justify-center items-center align-middle text-center rounded-md bg-slate-50 shadow-md hover:shadow-lg">
                    <div className="text-xl text-slate-500 mb-10 capitalize">SUPPORTS BOTH PHYSICAL & VIRTUAL ENVIRONMENTS</div>
                    <i className="h-36 w-36 text-8xl text-[#ff5e0e] items-center justify-center text-center align-middle flex rounded-md fa-duotone fa-computer"></i>
                </div>
                <div className="flex flex-col py-8 justify-center items-center align-middle text-center rounded-md bg-slate-50 shadow-md hover:shadow-lg">
                    <div className="text-xl text-slate-500 mb-10 capitalize">WIDE PLATFORM SUPPORT</div>
                    <i className="h-36 w-36 text-8xl text-[#ff5e0e] items-center justify-center text-center align-middle flex rounded-md fa-duotone fa-tv"></i>
                </div>
                <div className="flex flex-col py-8 justify-center items-center align-middle text-center rounded-md bg-slate-50 shadow-md hover:shadow-lg">
                    <div className="text-xl text-slate-500 mb-10 capitalize">OFFICE365 EMAILS & ONEDRIVE</div>
                    <i className="h-36 w-36 text-8xl text-[#ff5e0e] items-center justify-center text-center align-middle flex rounded-md fa-duotone fa-cloud-arrow-up"></i>
                </div>
                <div className="flex flex-col py-8 justify-center items-center align-middle text-center rounded-md bg-slate-50 shadow-md hover:shadow-lg">
                    <div className="text-xl text-slate-500 mb-10 capitalize">HARDWARE INDEPENDENT DEDUPLICATION</div>
                    <i className="h-36 w-36 text-8xl text-[#ff5e0e] items-center justify-center text-center align-middle flex rounded-md fa-duotone fa-server"></i>
                </div>
            </div>            
        </>
    )
}

const TinaFeatures = () => {
    return (
        <>
            <div className="text-2xl font-semibold text-slate-500 items-center flex justify-center text-center py-6 ">Key Features and Benefits</div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 py-6 px-4">
                <div className="flex flex-col py-8 rounded-md bg-slate-100 px-6 h-[350px] shadow-md hover:shadow-lg">
                    <div className="text-xl mb-10 capitalize text-[#ff5e0e] flex justify-center items-center align-middle text-center ">Protect Microsoft 365 Email and OneDrive</div>
                    <div className="flex flex-col text-left justify-start items-start text-slate-600 text-sm">
                        <div className="flex items-start text-left justify-start align-middle"><i className="fa-solid fa-circle-dot mr-2 text-[8px] mt-[6px]"/>Microsoft 365 Exchange Online</div>
                        <div className="flex items-start text-left justify-start align-middle"><i className="fa-solid fa-circle-dot mr-2 text-[8px] mt-[6px]"/>Microsoft 365 OneDrive</div>
                        <div className="flex items-start text-left justify-start align-middle"><i className="fa-solid fa-circle-dot mr-2 text-[8px] mt-[6px]"/>Microsoft 365 SharePoint Online</div>
                        <div className="flex items-start text-left justify-start align-middle"><i className="fa-solid fa-circle-dot mr-2 text-[8px] mt-[6px]"/>Microsoft 365 Teams</div>
                        <div className="flex items-start text-left justify-start align-middle"><i className="fa-solid fa-circle-dot mr-2 text-[8px] mt-[6px]"/>Take back control and backup according to your company policy (down to every 5 minutes)</div>
                        <div className="flex items-start text-left justify-start align-middle"><i className="fa-solid fa-circle-dot mr-2 text-[8px] mt-[6px]"/>Store backups on disk, deduplicated storage, tape and Cloud</div>
                    </div>
               </div>
               <div className="flex flex-col py-8 rounded-md bg-slate-100 px-6 h-[350px] shadow-md hover:shadow-lg">
                    <div className="text-xl mb-10 capitalize text-[#ff5e0e] flex justify-center items-center align-middle text-center ">Greater Retention</div>
                    <div className="flex flex-col text-left justify-start items-start text-slate-600 text-sm">
                        <div className="flex items-start text-left justify-start align-middle"><i className="fa-solid fa-circle-dot mr-2 text-[8px] mt-[6px]"/>One solution addressing all company data protection needs (VMs, servers, applications, SANs, Microsoft 365, ...)</div>
                        <div className="flex items-start text-left justify-start align-middle"><i className="fa-solid fa-circle-dot mr-2 text-[8px] mt-[6px]"/>Take control of your cloud-based critical data by extending its life cycle</div>
                    </div>
               </div>
               <div className="flex flex-col py-8 rounded-md bg-slate-100 px-6 h-[350px] shadow-md hover:shadow-lg">
                    <div className="text-xl mb-10 capitalize text-[#ff5e0e] flex justify-center items-center align-middle text-center ">Granular restore capability</div>
                    <div className="flex flex-col text-left justify-start items-start text-slate-600 text-sm">
                        <div className="flex items-start text-left justify-start align-middle"><i className="fa-solid fa-circle-dot mr-2 text-[8px] mt-[6px]"/>Granular restore of mailboxes, folders and  individual emails SharePoint sites and Teams sites</div>
                        <div className="flex items-start text-left justify-start align-middle"><i className="fa-solid fa-circle-dot mr-2 text-[8px] mt-[6px]"/>Search object by name, or for emails criteria such as sender, cc, subject etc. for fast access and rapid restore</div>
                    </div>
               </div>
               <div className="flex flex-col py-8 rounded-md bg-slate-100 px-6 h-[350px] shadow-md hover:shadow-lg">
                    <div className="text-xl mb-10 capitalize text-[#ff5e0e] flex justify-center items-center align-middle text-center ">Intuitive Restore</div>
                    <div className="flex flex-col text-left justify-start items-start text-slate-600 text-sm">
                        <div className="flex items-start text-left justify-start align-middle"><i className="fa-solid fa-circle-dot mr-2 text-[8px] mt-[6px]"/>3-click restore to get emails or files back to the original location or to an alternative one</div>
                        <div className="flex items-start text-left justify-start align-middle"><i className="fa-solid fa-circle-dot mr-2 text-[8px] mt-[6px]"/>View Office 365 objects that have been deleted. These too can be recovered</div>
                    </div>
               </div>
               <div className="flex flex-col py-8 rounded-md bg-slate-100 px-6 h-[350px] shadow-md hover:shadow-lg">
                    <div className="text-xl mb-10 capitalize text-[#ff5e0e] flex justify-center items-center align-middle text-center ">Security & Compliance</div>
                    <div className="flex flex-col text-left justify-start items-start text-slate-600 text-sm">
                        <div className="flex items-start text-left justify-start align-middle"><i className="fa-solid fa-circle-dot mr-2 text-[8px] mt-[6px]"/>Encrypt data and meet strict compliance regulations</div>
                        <div className="flex items-start text-left justify-start align-middle"><i className="fa-solid fa-circle-dot mr-2 text-[8px] mt-[6px]"/>Keep data long-term to comply with regulations</div>
                    </div>
               </div>
               <div className="flex flex-col py-8 rounded-md bg-slate-100 px-6 h-[350px] shadow-md hover:shadow-lg">
                    <div className="text-xl mb-10 capitalize text-[#ff5e0e] flex justify-center items-center align-middle text-center ">Optimized Backup</div>
                    <div className="flex flex-col text-left justify-start items-start text-slate-600 text-sm">
                        <div className="flex items-start text-left justify-start align-middle"><i className="fa-solid fa-circle-dot mr-2 text-[8px] mt-[6px]"/>Faster backup time by protecting multiple mailboxes or folders in parallel</div>
                        <div className="flex items-start text-left justify-start align-middle"><i className="fa-solid fa-circle-dot mr-2 text-[8px] mt-[6px]"/>Backup wizard to select multiple mailboxes for fast configuration</div>
                    </div>
               </div>
            </div>
        </>
    )
}

const ProgressStatus = () => {
    return (
        <>
            <div className="flex flex-col bg-slate-100 md:mt-24 mt-4 justify-center md:py-6 rounded-md md:mx-10 shadow-md">
                <div className="p-6 flex flex-col md:flex-row">
                    <div className="font-medium w-full h-28 md:w-1/3 flex md:justify-center items-center md:text-center text-slate-600 flex-row md:flex-col">
                        <div className="flex flex-col md:flex-row items-center w-[20px] mr-10 md:mr-0 md:w-full">
                            <div className="md:min-w-[55px] h-14 w-1 md:h-1 grow bg-[#ff5e0e] bg-opacity-0"></div>
                            <i className="fa-solid fa-circle-dot text-[#ff5e0e]"></i>
                            <div className="md:min-w-[55px] h-14 w-1 md:h-1 grow bg-[#ff5e0e]"></div>
                        </div>
                        <div className="mt-2 whitespace-nowrap text-lg text-[#ff5e0e]">Application Created</div>
                    </div>
                    <div className="font-medium w-full h-28 md:w-1/3 flex md:justify-center items-center md:text-center text-slate-600 flex-row md:flex-col">
                        <div className="flex flex-col md:flex-row items-center w-[20px] mr-10 md:mr-0 md:w-full">
                            <div className="md:min-w-[55px] h-14 w-1 md:h-1 grow bg-[#ff5e0e]"></div>
                            <i className="fa-solid fa-circle-dot text-[#ff5e0e]"></i>
                            <div className="md:min-w-[55px] h-14 w-1 md:h-1 grow bg-[#ff5e0e]"></div>
                        </div>
                        <div className="mt-2 whitespace-nowrap text-lg text-[#ff5e0e]">Users Added</div>
                    </div>
                    <div className="font-medium w-full h-28 md:w-1/3 flex md:justify-center items-center md:text-center text-slate-600 flex-row md:flex-col">
                        <div className="flex flex-col md:flex-row items-center w-[20px] mr-10 md:mr-0 md:w-full">
                            <div className="md:min-w-[55px] h-14 w-1 md:h-1 grow bg-slate-300"></div>
                            <i className="fa-solid fa-circle-dot text-slate-300"></i>
                            <div className="md:min-w-[55px] h-14 w-1 md:h-1 grow bg-slate-300 bg-opacity-0"></div>
                        </div>
                        <div className="mt-2 whitespace-nowrap text-lg text-slate-500">Deploying Application</div>
                    </div>
                </div>
            </div>
        </>
    )
}

interface IFirstDrawerChild {
    title: string;
    allUsers: any;
    userIncluded: any;
    setUserIncluded: Dispatch<SetStateAction<any>>;
    userExcluded: any;
    setUserExcluded: Dispatch<SetStateAction<any>>;
}

// ml-[440px] ml-[480px]

const FirstDrawerChild:FC<IFirstDrawerChild> = ({
    title='',
    allUsers,
    userIncluded,
    setUserIncluded,
    userExcluded,
    setUserExcluded,
}) => {
    let theme = useContext(ThemeContext)
    let sideDrawerContext = useContext(SideDrawerContext)
    const [pageSize, setPageSize] = useState<number[]>([0, 0]);

    useLayoutEffect(() => {
        function updateSize() {
            setPageSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);


    const [firstSlider, setFirstSlider] = useState(0);


    const handleExcludeUsers = (userId: string) => {
        if( userId.length < 1 || (userIncluded?.value || []).length < 1 ) {
            return;
        }
        let excludingUserObj = userIncluded.value.find((u:any)=>{ return u.id === userId });

        setUserIncluded((prevState:any)=>{
            let newState = {...prevState}
            if( (newState?.value || []).length < 1 ) {
                newState.value = []
            }
            let filteredUser = prevState.value.filter((u:any)=>{ return u.id !== userId });
            newState.value = [...filteredUser]
            return newState;
        })

        setUserExcluded((prevState:any)=>{
            let newState: any = {...prevState}
            if( (newState?.value || []).length < 1 ) {
                newState.value = []
            }
            newState.value = [...newState.value, excludingUserObj]
            return newState;
        })
    }

    const handleIncludeUsers = (userId: string) => {
        if( userId.length < 1 ) {
            return;
        }

        let includingUserObj = userExcluded.value.find((u:any)=>{ return u.id === userId });

        setUserExcluded((prevState:any)=>{
            let newState = {...prevState}
            if( (newState?.value || []).length < 1 ) {
                newState.value = []
            }
            let filteredUser = prevState.value.filter((u:any)=>{ return u.id !== userId });
            newState.value = [...filteredUser]
            return newState;
        })

        setUserIncluded((prevState:any)=>{
            let newState: any = {...prevState}
            if( (newState?.value || []).length < 1 ) {
                newState.value = []
            }
            newState.value = [...newState.value, includingUserObj]
            return newState;
        })
    }

    return (
        <div className={`relative flex flex-col z-0 h-full`}>
            <div className={`tabs flex w-full mb-4`}>
                <div className={`tab group flex flex-col gap-2 w-1/2 h-32 rounded-md ${firstSlider===0 ? `border-b-4 border-b-${theme.themeColor}-${theme.themeContrast}-primary-default` : '' }  ${theme.themeContrast === 'light' ? 'hover:bg-slate-100' : 'hover:bg-[#444444]'}  cursor-pointer justify-center align-middle items-center`}
                    onClick={()=>{
                        console.log({pageSize})
                        setFirstSlider(0)
                    }}
                >
                    <em className={`fa-solid fa-user-plus text-3xl text-${theme.themeColor}-${theme.themeContrast}-primary-default`}></em>
                    <div className={`block text-${theme.themeColor}-${theme.themeContrast}-primary-default`}>Included Users ({(userIncluded.value ||[] ).length})</div>
                </div>
                <div className={`tab group flex flex-col gap-2 w-1/2 h-32 rounded-md ${(firstSlider===-480 || firstSlider===-440) ? `border-b-4 border-b-${theme.themeColor}-${theme.themeContrast}-primary-default` : '' } ${theme.themeContrast === 'light' ? 'hover:bg-slate-100' : 'hover:bg-[#444444]'} cursor-pointer justify-center align-middle items-center`}
                    onClick={()=>{
                        if(pageSize[0] <= 768) {
                            console.log({__:pageSize})
                            setFirstSlider(-440)
                        } else {
                            setFirstSlider(-480)
                        }
                        
                    }}
                >
                    <em className={`fa-solid fa-user-minus text-3xl text-${theme.themeColor}-${theme.themeContrast}-primary-default`}></em>
                    <div className={`block text-${theme.themeColor}-${theme.themeContrast}-primary-default`}>Excluded Users ({(userExcluded.value ||[] ).length})</div>
                </div>
            </div>
            <div className={`tabs-content-group flex relative transition-[margin] duration-200 ease-in-out ${firstSlider === 0 && 'ml-[0px]'} ${firstSlider===-440 && 'ml-[-440px]'} ${firstSlider===-480 && 'ml-[-480px]'} w-[880px] md:w-[960px] grow`}>
                <div className={`tab-content w-[440px] md:w-[480px] h-80`}>
                    <div className="flex p-4 border-y font-semibold bg-gray-100 text-[#FF5E0E]">
                        {title}
                        <span className="grow"></span>
                        <Button solid={false} size="xs"
                            onClick={()=>{
                                setUserExcluded((prevState:any)=>{
                                    let newState = {...prevState}
                                    if( (newState?.value || []).length < 1 ) {
                                        newState.value = []
                                    }
                                    newState.value = [...newState.value, ...userIncluded.value]
                                    return newState;
                                })
                                setUserIncluded((prevState:any)=>{
                                    let newState = {...prevState}
                                    if( (newState?.value || []).length < 1 ) {
                                        newState.value = []
                                    }
                                    newState.value = [];
                                    return newState
                                })
                            }}
                        >Exclude All Users</Button>
                    </div>
                    <div className="flex h-[42px] border-b">
                        <input
                            type="text"
                            placeholder="Search..."
                            className="w-full outline-none px-4"
                            onChange={(e)=>{
                                console.log({value:e.target.value})
                                if( e.target.value === '' ) {
                                    let arrIncludedUsers = excludeArrayFromArray(allUsers.value,userExcluded?.value || [])
                                    setUserIncluded({
                                        ...userIncluded,
                                        value: arrIncludedUsers
                                    })
                                } else {
                                    setUserIncluded((prevState:any)=>{
                                        let newState = {...prevState}
                                        newState.value = newState.value.filter((users:any)=>{
                                            return (users?.displayName || '').toLowerCase().includes(e.target.value) || (users?.mail || '').toLowerCase().includes(e.target.value)
                                        })
                                        return newState;
                                    })
                                }
                            }}
                        />
                        <div className="">
                            <Button className="rounded-none">
                                <em className="fa-solid fa-search"></em>
                            </Button>
                        </div>
                    </div>
                    { (userIncluded?.value || [] ).map((user:any, userIndex:number)=>(
                        <div key={userIndex} className="flex border-b hover:bg-slate-100">
                            <div className="aspect-square h-[50px] flex items-center justify-center align-middle">
                                <em className="fa-duotone fa-user text-2xl text-[#FF5E0E]"></em>
                            </div>
                            <div className="flex flex-col grow">
                                <div className="font-semibold">{user?.displayName || ''}</div>
                                <div>{user?.mail || ''}</div>
                            </div>
                            <div className="aspect-square h-[50px] flex items-center justify-center align-middle hover:bg-slate-200 cursor-pointer"
                                onClick={()=>{
                                    handleExcludeUsers(user?.id ||'')
                                }}
                            >
                                <em className="fa-light fa-dash text-[#FF5E0E]"></em>
                            </div>
                        </div>
                    )) }
                </div>
                <div className={`tab-content w-[440px] md:w-[480px] h-80`}>
                    <div className="flex p-4 border-y font-semibold bg-gray-100 text-[#FF5E0E]">
                        {title}
                        <span className="grow"></span>
                        <Button solid={false} size="xs"
                            onClick={()=>{
                                setUserIncluded((prevState:any)=>{
                                    let newState = {...prevState}
                                    if( (newState?.value || []).length < 1 ) {
                                        newState.value = []
                                    }
                                    newState.value = [...newState.value, ...userExcluded.value]
                                    return newState;
                                })
                                setUserExcluded((prevState:any)=>{
                                    let newState = {...prevState}
                                    if( (newState?.value || []).length < 1 ) {
                                        newState.value = []
                                    }
                                    newState.value = [];
                                    return newState
                                })
                            }}
                        >Include All Users</Button>
                    </div>
                    <div className="flex h-[42px] border-b">
                        <input 
                            type="text"
                            placeholder="Search..."
                            className="w-full outline-none px-4"
                            onChange={(e)=>{
                                console.log({value:e.target.value})
                                if( e.target.value === '' ) {
                                    let arrExcludedUsers = excludeArrayFromArray(allUsers.value,userIncluded?.value || [])
                                    setUserExcluded({
                                        ...userExcluded,
                                        value: arrExcludedUsers
                                    })
                                } else {
                                    setUserExcluded((prevState:any)=>{
                                        let newState = {...prevState}
                                        newState.value = (newState?.value || []).filter((users:any)=>{
                                            return (users?.displayName || '').toLowerCase().includes(e.target.value) || (users?.mail || '').toLowerCase().includes(e.target.value)
                                        })
                                        return newState;
                                    })
                                }
                            }}
                        />
                        <div className="">
                            <Button className="rounded-none">
                                <em className="fa-solid fa-search"></em>
                            </Button>
                        </div>
                    </div>
                    { (userExcluded?.value || [] ).map((user:any, userIndex:number)=>(
                        <div key={userIndex} className="flex border-b hover:bg-slate-100">
                            <div className="aspect-square h-[50px] flex items-center justify-center align-middle">
                                <em className="fa-duotone fa-user text-2xl text-[#FF5E0E]"></em>
                            </div>
                            <div className="flex flex-col grow">
                                <div className="font-semibold">{user?.displayName || ''}</div>
                                <div>{user?.mail || ''}</div>
                            </div>
                            <div className="aspect-square h-[50px] flex items-center justify-center align-middle hover:bg-slate-200 cursor-pointer"
                                onClick={()=>{
                                    console.log({user})
                                    handleIncludeUsers(user?.id ||'')
                                }}
                            >
                                <em className="fa-light fa-dash text-[#FF5E0E]"></em>
                            </div>
                        </div>
                    )) }
                </div>
            </div>
        </div>
    )
}

const excludeArrayFromArray = (arrParent:any[], arrToExclude: any[]) => {
    console.log({arrParent},{arrToExclude})

    let filteredArray:any[] = []

    arrParent.map((alwaysAvailableUser:any)=>{
        let userInExcludedArray = arrToExclude.find((u:any)=>{return u.id === alwaysAvailableUser.id})
        if( !userInExcludedArray ) {
            filteredArray.push(alwaysAvailableUser)
        }
    })

    console.log({filteredArray})

    return filteredArray
}

