import React, { FC } from "react";

interface ITooltipProps {
    value: string;
    className?: string;
    direction?: string;
}

const Tooltip:FC<ITooltipProps> = ({
    value,
    className='',
    direction='up'
}) => {
    return (
        <>
            {direction === 'up' && <span className={`tooltip up absolute w-1/2 ml-0 mt-[-65px] invisible group-hover:visible flex flex-col items-center ${className}`}>
                <span className="bg-white px-2 py-1 rounded whitespace-nowrap shadow-[0_0_5px_0_rgba(0,0,0,0.3)]">{value}</span>
                <i className="fa-solid fa-caret-down text-3xl text-white -mt-[16px] drop-shadow-md"></i>
            </span>}
            {direction === 'down' && <span className={`tooltip down absolute w-1/2 top-5 ml-0 mt-[10px] z-10 invisible group-hover:visible flex flex-col items-center ${className}`}>
                <span className="bg-white px-2 py-1 rounded whitespace-nowrap shadow-[0_0_5px_0_rgba(0,0,0,0.3)]">{value}</span>
                <i className="fa-solid fa-caret-down text-3xl text-white -mt-[45px] drop-shadow-md rotate-180"></i>
            </span>}
            {direction === 'right' && <div className={`tooltip absolute w-full invisible mt-[-20px] left-full z-10 group-hover:visible flex flex-col  ${className}`}>
                <div className="bg-white px-2 py-1 rounded whitespace-nowrap shadow-[0_0_5px_0_rgba(0,0,0,0.3)] min-w-max relative">{value}</div>
                <i className="fa-solid fa-caret-down text-3xl text-white drop-shadow-md -left-[11px] -top-[6px] rotate-90 absolute"></i>
            </div>}
        </>
    )
}

export default Tooltip;