import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/MSALConfig";

import { CustomizationProvider, MailingListProvider } from './contexts';

import App from './App';
import './index.css';
import  ProgressBar from "./pages/ProgressBar";


const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot( document.getElementById('root') as HTMLElement );

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <CustomizationProvider>
        <MailingListProvider>
          <MsalProvider instance={msalInstance}>
            <App />
            {/* <ProgressBar /> */}
          </MsalProvider>
        </MailingListProvider>
      </CustomizationProvider>
    </BrowserRouter>
  </React.StrictMode>
);
