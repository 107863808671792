import React, { ReactNode, ComponentProps, Children, cloneElement } from "react";


interface Props extends ComponentProps<"div">  {
    /**
     * Child node(s) of button
     */
    children?: ReactNode | ReactNode[];
    /**
     * 
     */
    className?: string
}

/**
 * Button Component
 * @param param0 
 * @returns 
 */
const ButtonGroup = ({
    className='',
    children=<></>,
    ...rest
}: Props) => {

    let numberOfChild = Children.count(children);

    let variant = 'primary';
    let color = '';
    
    let groupedButtons = Children.map(children, (childNode: any, childNodeIndex: number) => {
        variant = childNode?.props?.variant;
        if( childNode?.props?.block !== undefined || childNode?.props?.block !== false ) {
            delete childNode?.props?.block // <= block buttons of full width not allowed, so blocked property removed
        }
        // if( childNode?.props?.rounded !== undefined || childNode?.props?.rounded !== false ) {
        //     delete childNode?.props?.rounded // <= rounded buttons not allowed, so rounded property removed
        // }
        if( childNode?.props?.solid !== undefined && childNode?.props?.solid === false ) {
            if((numberOfChild - 1) === childNodeIndex) {
                return cloneElement(childNode, {className: 'rounded-none border-0'});
            } else {
                return cloneElement(childNode, {className: 'rounded-none border-0 border-r'});
            }
        } else {
            return cloneElement(childNode, {className: 'rounded-none border-0'});
        }
    });

    let finalColor = ''

    switch (variant) {
        case 'primary':
            finalColor = 'border border-orange-500';
            break;
        case 'secondary':
            finalColor = 'border border-gray-500';
            break;
        case 'success':
            finalColor = 'border border-green-600';
            break;
        case 'error':
        case 'danger':
            finalColor = 'border border-red-500';
            break;
        case 'warning':
            finalColor = 'border border-yellow-500';
            break;
        case 'info':
            finalColor = 'border border-cyan-400';
            break;
        case 'light':
        case 'default':
            finalColor = 'border gray-100';
            break;
        case 'dark':
            finalColor = 'border gray-800';
            break;
        case 'link':
            finalColor = 'border';
            break;
    
        default:
            finalColor = 'border-orange-500';
            break;
    }

    color = finalColor;

    return <>
        <div className={`flex max-w-min max-h-min rounded-md overflow-hidden ${color} ${className}`}>
            {groupedButtons}
        </div>
    </>
}

export default ButtonGroup;
