import React, { FC, ReactNode, ComponentProps } from "react";


interface Props extends ComponentProps<"button">  {
    /**
     * Is this the principal call to action on the page?
     */
    variant?: 'primary' | 'secondary' | 'success' | 'warning' | 'error' | 'danger' | 'info' | 'default' | 'light' | 'dark' | 'link';
    /**
     * How large should the button be?
     */
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
    /**
     * How large should the button be?
     */
    solid?: boolean;
    /**
     *  Will button takes full width?
     */
    block?: boolean;
    /**
     *  Is button rounded?
     */
    rounded?: boolean;
    /**
     *  Is button disabled?
     */
    disabled?: boolean;
    /**
     *  Is button loading?
     */
    loading?: boolean;
    /**
     * Child node(s) of button
     */
    children?: ReactNode | ReactNode[] | string;
    /**
     * 
     */
    className?: string
    /**
     * Optional click handler
     */
    onClick?: () => void;
}

/**
 * Button Component
 * @param param0 
 * @returns 
 */
const Button:FC<Props> = ({
    variant='primary',
    size='md',
    solid=true,
    block=false,
    rounded=false,
    disabled=false,
    loading=false,
    className='',
    children=<>Button</>,
    onClick=()=>{},
    ...rest
}: Props) => {

    // const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};

    let bgSolidColor = "";
    let borderSolidColor = "";
    let bgSolidHoverColor = "";
    let textSolidColor = "";
    let bgColor = "";
    let borderColor = "";
    let bgHoverColor = "";
    let textColor = "";

    switch (variant) {
        case "primary":
            bgSolidColor = `bg-orange-500`;
            borderSolidColor = `border-orange-500`;
            bgSolidHoverColor = `hover:bg-orange-600`;
            textSolidColor = `text-white`;
            bgColor = ``;
            borderColor = `border-orange-500`;
            bgHoverColor = `hover:bg-orange-500 hover:bg-opacity-20`;
            textColor = `text-orange-500`;
            break;

        case "secondary":
            bgSolidColor = "bg-gray-500";
            borderSolidColor = "border-gray-500";
            bgSolidHoverColor = "hover:bg-gray-600";
            textSolidColor = `text-white`;
            bgColor = ``;
            borderColor = `border-gray-500`;
            bgHoverColor = `hover:bg-gray-500 hover:bg-opacity-20`;
            textColor = `text-gray-500`;
            break;

        case "success":
            bgSolidColor = "bg-green-600";
            borderSolidColor = "border-green-600";
            bgSolidHoverColor = "hover:bg-green-700";
            textSolidColor = `text-white`;
            bgColor = ``;
            borderColor = `border-green-600`;
            bgHoverColor = `hover:bg-green-600 hover:bg-opacity-20`;
            textColor = `text-green-600`;
            break;

        case "error":
        case "danger":
            bgSolidColor = "bg-red-500";
            borderSolidColor = "border-red-500";
            bgSolidHoverColor = "hover:bg-red-600";
            textSolidColor = `text-white`;
            bgColor = ``;
            borderColor = `border-red-500`;
            bgHoverColor = `hover:bg-red-500 hover:bg-opacity-20`;
            textColor = `text-red-500`;
            break;

        case "warning":
            bgSolidColor = "bg-yellow-500";
            borderSolidColor = "border-yellow-500";
            bgSolidHoverColor = "hover:bg-yellow-600";
            textSolidColor = `text-white`;
            bgColor = ``;
            borderColor = `border-yellow-500`;
            bgHoverColor = `hover:bg-yellow-500 hover:bg-opacity-20`;
            textColor = `text-yellow-600`;
            break;

        case "info":
            bgSolidColor = "bg-cyan-400";
            borderSolidColor = "border-cyan-400";
            bgSolidHoverColor = "hover:bg-cyan-500";
            textSolidColor = `text-white`;
            bgColor = ``;
            borderColor = `border-cyan-400`;
            bgHoverColor = `hover:bg-cyan-400 hover:bg-opacity-20`;
            textColor = `text-cyan-500`;
            break;

        default:
        break;
    }

    let sizeStyle = "";

    switch (size) {
        case "xs":
            sizeStyle = `${rounded ? 'rounded-full' : 'rounded'} rounded px-2 py-1 text-xs font-xs ${block ? "min-w-full" : "min-w-[24px]"} min-h-[24px]`;
            break;

        case "sm":
            sizeStyle = `${rounded ? 'rounded-full' : 'rounded'} px-2 py-1 text-sm font-sm ${block ? "min-w-full" : "min-w-[32px]"} min-h-[32px]`;
            break;

        case "md":
            sizeStyle = `${rounded ? 'rounded-full' : 'rounded-md'} px-3 py-2 text-normal font-base ${block ? "min-w-full" : "min-w-[40px]"} min-h-[40px]`;
            break;

        case "lg":
            sizeStyle = `${rounded ? 'rounded-full' : 'rounded-lg'} px-3 py-2 text-lg font-lg ${block ? "min-w-full" : "min-w-[48px]"} min-h-[48px]`;
            break;

        case "xl":
            sizeStyle = `${rounded ? 'rounded-full' : 'rounded-lg'} px-5 py-3 text-xl font-xl ${block ? "min-w-full" : "min-w-[56px]"} min-h-[56px]`;
            break;

        case "2xl":
            sizeStyle = `${rounded ? 'rounded-full' : 'rounded-lg'} px-5 py-3 text-2xl font-2xl ${block ? "min-w-full" : "min-w-[56px]"} min-h-[56px]`;
            break;

            default:
            break;
    }

    let buttonStyle = "";

    if (solid) {
        buttonStyle = `${bgSolidColor} ${textSolidColor} ${bgSolidHoverColor} border ${borderSolidColor}`;
    } else {
        buttonStyle = `${bgColor} ${textColor} ${bgHoverColor} border ${borderColor}`;
    }

    switch (variant) {
        case "light":
        case "default":
            buttonStyle = "bg-gray-100  text-gray-500";
            break;
        case "dark":
            buttonStyle = "bg-gray-800  text-white";
            break;
        case "link":
            buttonStyle = "text-orange-600 hover:text-blue-700";
            break;

        default:
            break;
    }

    return (
        <button
            className={`${buttonStyle} min-w-max ${sizeStyle} overflow-hidden whitespace-nowrap ${disabled ? ' cursor-not-allowed opacity-70' : ''} ${block ? 'w-full' : ''} ${className}`}
            disabled={disabled}
            onClick={() => {
                if (!disabled) {
                    onClick();
                }
            }}
        >
            {children}
            {/* <span className={`absolute left-0 top-0 right-0 bottom-0 bg-gray-400 opacity-40 ${loading ? "visible" : "hidden"}`}></span> */}
            {/* <span className={`absolute top-1/2 left-1/2 -mr-1/2 mt-1 -translate-x-1/2 -translate-y-1/2 opacity-100 text-4xl ${loading ? "visible" : "hidden"}`}> */}
            {/* <span className="animate-spin text-gray-600">*</span> */}
        </button>
    );
}

export default Button;
