import { Breadcrumb, Button, Tile } from "../components/core"
import React, { useState} from "react";


const CreateOrder = () => {

    
    const [openLevel1,setOpenLevel1] = useState (false);
    const [openLevel2,setOpenLevel2] = useState (false);
    const [openLevel3,setOpenLevel3] = useState (false);

    const  openHandler1 = ()=>{
        setOpenLevel1(!openLevel1)
    }
    const  openHandler2 = ()=>{
        setOpenLevel2(!openLevel2)
    }
    const  openHandler3 = ()=>{
        setOpenLevel3(!openLevel3)
    }

    return (
        <>
            <div className={`p-6 grow flex flex-col`}>    
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Create Order</h1>
                <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-home', link: '', name: 'Home' },
                    { icon: '', link: '/createorder', name: 'Create Order' },
                ]} />
                <Tile colSpan={20} rowSpan={4} className="gap-6 grow flex flex-col" >
                    <div className="flex w-full items-center p-6 border-b justify-between text-center text-gray-600 font-medium text-lg">
                        <div className="">Please Select your products</div>
                        <div className="mr-10 text-gray-500 text-base">Selected products<span className="font-semibold text-left text-xl text-[#ff5e0e] ml-4">3</span></div>
                    </div>
                    <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mb-6 px-6 overflow-y-scroll customScroll pb-6 h-[600px]">
                        <Tile colSpan={1} rowSpan={4} className={`bg-slate-100 bg-opacity-25 relative ${openLevel1===true?"rounded-lg rounded-b-none shadow-none border" :""} `}  >
                            <div className="w-full flex flex-row h-full">
                                <div className="h-full px-4 flex justify-center items-center w-16 border-r">
                                    <input className="accent-[#ff5e0e]" type="checkbox" />
                                </div>
                                <i className="fa-duotone fa-computer w-32 text-4xl flex items-center justify-center text-[#ff5e0e]"></i>                           
                                <div className="flex flex-col w-full items-start justify-center">
                                    <div className="text-2xl font-medium text-[#ff5e0e] mb-2">Endpoint<span className="ml-4 border-l border-[#ff5e0e]"><span className="text-xs text-slate-500 font-semibold mx-4">Users:<span className="text-[#ff5e0e] text-sm font-medium ml-2">25</span></span><span className="text-xs text-slate-500 font-semibold mx-4">Storage:<span className="text-[#ff5e0e] text-sm font-medium ml-2">550 GB</span></span></span></div>
                                    <div className="text-slate-500 text-sm">Endpoint is a solution for the protection of file servers, laptops and workstations.</div>
                                </div>
                                <i className={`fa-solid fa-angle-down w-20 text-2xl flex items-center justify-center text-slate-500 ${openLevel1===true?"rotate-180":"rotate-0"}` } onClick={openHandler1}></i>
                            </div>
                                <div className={`${openLevel1===true?"h-[350px] z-50 absolute w-full shadow-md hover:shadow-lg border-t bg-white border block rounded-b-lg":"h-[0px] hidden"} transition-[height] duration-500s ease-in-outs`}>
                                    <div className="text-base font-medium text-slate-500 w-full border-b p-4">Please Enter the Details</div>
                                    <div className="flex flex-col items-end justify-start p-6">
                                        <div className="flex justify-center gap-32 text-center items-center mb-8 w-full ">
                                            <div className=" flex flex-col items-center justify-center align-middle">
                                                <em className="fa-thin fa-users text-8xl text-[#ff5e0e] my-4"></em>
                                                <span className="text-slate-500 text-lg font-medium">Enter no of users :<input type="text"  className="border-b border-gray-800 px-3 py-1 w-14 ml-4" /></span>
                                            </div>
                                            <div className="flex flex-col items-center justify-center align-middle">
                                                <em className="fa-thin fa-hard-drive text-8xl text-[#ff5e0e] my-4"></em>
                                                <span className="text-slate-500 text-lg font-medium">Enter Total Storage :<input type="text"  className="border-b border-gray-800 px-3 py-1 w-14 ml-4" /><span className="ml-1">GB</span></span>
                                            </div>
                                        </div>
                                        <Button size="sm" onClick={openHandler1}>
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                        </Tile>   
                        <Tile colSpan={1} rowSpan={4} className="bg-slate-100 bg-opacity-25" >
                            <div className="w-full flex flex-row h-full">
                                <div className="h-full px-4 flex justify-center items-center w-16 border-r">
                                    <input className="accent-[#ff5e0e]" type="checkbox" />
                                </div>
                                <i className="fa-brands fa-windows w-32 text-4xl flex items-center justify-center text-[#ff5e0e]"></i>                           
                                <div className="flex flex-col w-full items-start justify-center">
                                    <div className="text-2xl font-medium text-[#ff5e0e] mb-2">Microsoft365</div>
                                    <div className="text-slate-500 text-sm">Tina (Time Navigation) capability provides an intuitive UI to manage virtual environment backups. Administrators can simply view the tree structure and manage.</div>
                                </div>
                                <i className="fa-solid fa-angle-down w-20 text-2xl flex items-center justify-center text-slate-500"></i>                           
                            </div>
                        </Tile> 
                        <Tile colSpan={1} rowSpan={4} className="bg-slate-100 bg-opacity-25" >
                            <div className="w-full flex flex-row h-full">
                                <div className="h-full px-4 flex justify-center items-center w-16 border-r">
                                    <input className="accent-[#ff5e0e]" type="checkbox" />
                                </div>
                                <i className="fa-duotone fa-arrow-up-arrow-down rotate-90 w-32 text-4xl flex items-center justify-center text-[#ff5e0e]"></i>                           
                                <div className="flex flex-col w-full items-start justify-center">
                                    <div className="text-2xl font-medium text-[#ff5e0e] mb-2">Migration</div>
                                    <div className="text-slate-500 text-sm">Endpoint is a solution for the protection of file servers, laptops and workstations.</div>
                                </div>
                                <i className="fa-solid fa-angle-down w-20 text-2xl flex items-center justify-center text-slate-500"></i>                           
                            </div>
                        </Tile> 
                        <Tile colSpan={1} rowSpan={4} className="bg-slate-100 bg-opacity-25" >
                            <div className="w-full flex flex-row h-full">
                                <div className="h-full px-4 flex justify-center items-center w-16 border-r">
                                    <input className="accent-[#ff5e0e]" type="checkbox" />
                                </div>
                                <i className="fa-duotone fa-server w-32 text-4xl flex items-center justify-center text-[#ff5e0e]"></i>                           
                                <div className="flex flex-col w-full items-start justify-center">
                                    <div className="text-2xl font-medium text-[#ff5e0e] mb-2">Protected VMs</div>
                                    <div className="text-slate-500 text-sm">Endpoint is a solution for the protection of file servers, laptops and workstations.</div>
                                </div>
                                <i className="fa-solid fa-angle-down w-20 text-2xl flex items-center justify-center text-slate-500"></i>                           
                            </div>
                        </Tile> 
                        <Tile colSpan={1} rowSpan={4} className="bg-slate-100 bg-opacity-25" >
                            <div className="w-full flex flex-row h-full">
                                <div className="h-full px-4 flex justify-center items-center w-16 border-r">
                                    <input className="accent-[#ff5e0e]" type="checkbox" />
                                </div>
                                <i className="fa-duotone fa-database w-32 text-4xl flex items-center justify-center text-[#ff5e0e]"></i>                           
                                <div className="flex flex-col w-full items-start justify-center">
                                    <div className="text-2xl font-medium text-[#ff5e0e] mb-2">Database Server</div>
                                    <div className="text-slate-500 text-sm">Endpoint is a solution for the protection of file servers, laptops and workstations.</div>
                                </div>
                                <i className="fa-solid fa-angle-down w-20 text-2xl flex items-center justify-center text-slate-500"></i>                           
                            </div>
                        </Tile> 
                    </div>
                </Tile>
            </div>

        </>
    )
}

export default CreateOrder