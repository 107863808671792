import { Breadcrumb, Button, Tile } from "../components/core"
import React, { useState} from "react";


const OrderDetails = () => {

    
    const [openLevel1,setOpenLevel1] = useState (false);
    const [openLevel2,setOpenLevel2] = useState (false);
    const [openLevel3,setOpenLevel3] = useState (false);

    const  openHandler1 = ()=>{
        setOpenLevel1(!openLevel1)
    }
    const  openHandler2 = ()=>{
        setOpenLevel2(!openLevel2)
    }
    const  openHandler3 = ()=>{
        setOpenLevel3(!openLevel3)
    }

    return (
        <>
            <div className={`p-6 grow flex flex-col`}>    
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Create Order</h1>
                <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-home', link: '', name: 'Home' },
                    { icon: '', link: '/orderdetails', name: '132' },
                    { icon: '', link: '/orderdetails', name: 'Order Details' },
                ]} />
                <Tile colSpan={20} rowSpan={4} className="gap-6 grow flex flex-col" >
                    <div className="flex w-full items-center p-6 border-b justify-between text-center text-gray-600 font-medium text-lg">
                        <div className="">Please Select your products</div>
                        <div className="mr-10 text-gray-500 text-base">Selected products<span className="font-semibold text-left text-xl text-[#ff5e0e] ml-4">3</span></div>
                    </div>
                    <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mb-6 px-6 overflow-y-scroll customScroll pb-6 h-[600px]">
                    </div>
                </Tile>
            </div>

        </>
    )
}

export default OrderDetails