/* eslint-disable react-hooks/exhaustive-deps */
import React, {ReactNode, useEffect, useLayoutEffect, useRef, useState} from 'react';
import { toast } from 'react-toastify';
// import Tile58 from '../../components/applicationComponents/Tiles/Tile58/Tile58';
// import { MasterChart } from '../../components/Charts/ChartJS';
import { Breadcrumb, Tile } from '../../components/core';
import Table, { ITableColumnItem, ITableControllerProps } from '../../components/core/Table/Table';
import Tooltip from '../../components/core/Tooltip/Tooltip';
// import { ThemeContext } from '../../contexts';
import { getAPICall } from '../../services/APILayer/axiosMethodCalls'
import { processTableData, formatSize } from '../../utils/backupFileUtils'
import { FileExtensionsCard, FileTypeCard, LargeFileCard, VolumeByExtensionCard } from './BackupFilesComponents';
import { SortWithExtension, SortWithFile, SortWithInstances, SortWithPath, SortWithPercentage, SortWithSerial, SortWithSize, SortWithType } from '../../utils/Sorting/endpointBackupFiles';

const BackupFiles = () => {

    // const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    const pageTop = useRef<null | HTMLDivElement>(null);
    const scrollerRef = useRef<null | HTMLDivElement>(null);
    const navRaf = useRef<null | HTMLDivElement>(null);
    const [pageSize, setPageSize] = useState<number[]>([0, 0]);
    // const [responsiveBreakPoint, setBreakPoint] = useState<string>('sm');
    const [donutCutout, setDonutCutout] = useState<number>(50);

    const [fileStatsRawData, setFileStatsRawData] = useState<any>({}); // stats.data.Stats.LargestExts.Total
    const [fileStatsProcessedData, setFileStatsProcessedData] = useState<any>({});
    const [selectedTile, setSelectedTile] = useState('LargeFiles')
    const [tableController, setTableController] = useState<ITableControllerProps>({
        data: [],
        loader: false,
        currentPage: 1,
        offset: 0,
        limit: 5,
        totalRecords: 10,
        maxVisiblePaginationButtons: 5,
        searchTerm: '',
        filter: {},
        sorting: {
            column: '',
            direction: null
        }
    });
    const [tableColumns, setTableColumns] = useState<ITableColumnItem[]>([
        {
            id: 1,
            name: '#',
            column: 'serial',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            className: 'w-[30px] pl-4',
            visibility: true,
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 2,
            name: 'File',
            column: 'file',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            className: 'w-[150px] pl-4',
            visibility: true,
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 3,
            name: 'Path',
            column: 'path',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            className: 'w-[150px] truncate pl-4',
            visibility: true,
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 4,
            name: 'Extension',
            column: 'extension',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            className: 'w-[150px] pl-4',
            visibility: true,
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 5,
            name: 'Size',
            column: 'size',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            className: 'w-[150px] pl-4',
            visibility: true,
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        },
        {
            id: 6,
            name: 'Percent',
            column: 'percent',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            className: 'w-[180px] pl-4',
            visibility: true,
            sorting: {
                enable: true,
                direction: 'NEUTRAL'
            }
        }
    ]);

    useLayoutEffect(() => {
        function updateSize() {
            setPageSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(()=>{
        if(pageSize[0] <= 640) {
            // setBreakPoint('sm')
            setDonutCutout(80);
        }
        if(pageSize[0] > 640 && pageSize[0] <= 768) {
            // setBreakPoint('md')
            setDonutCutout(50);
        }
        if(pageSize[0] > 768 && pageSize[0]  <= 1024) {
            // setBreakPoint('lg')
            setDonutCutout(80);
        }
        if(pageSize[0] > 1024 && pageSize[0]  <= 1280) {
            // setBreakPoint('xl')
            setDonutCutout(55);
        }
        if(pageSize[0] > 1280) {
            // setBreakPoint('2xl')
            setDonutCutout(80);
        }
    },[pageSize]);

    useEffect(()=>{
        const fetchFileStats = async () => {
            await getAPICall(`${process.env.REACT_APP_API_URL}/analytics/endpoints_file_stats`)
                .then((data)=>{
                    setFileStatsRawData(data);
                })
                .catch((error)=>{console.log(error)})
                .finally(()=>{})
        }
        fetchFileStats();
    },[])

    useEffect(()=>{
        console.log({selectedTile})

        let procededData: any = processTableData(fileStatsRawData);

        let columns: any[] = [];
        let data: any[] = procededData.largeFile;

        if( selectedTile === 'LargeFiles' ) {
            columns = [
                {
                    id: 1,
                    name: '#',
                    column: 'serial',
                    textAlign: 'text-left',
                    textColor: 'text-[#3A3541]',
                    className: 'w-[30px] pl-4',
                    visibility: true,
                    sorting: {
                        enable: true,
                        direction: 'NEUTRAL'
                    }
                },
                {
                    id: 2,
                    name: 'File',
                    column: 'file',
                    textAlign: 'text-left',
                    textColor: 'text-[#3A3541]',
                    className: 'w-[150px] pl-4',
                    visibility: true,
                    sorting: {
                        enable: true,
                        direction: 'NEUTRAL'
                    }
                },
                {
                    id: 3,
                    name: 'Path',
                    column: 'path',
                    textAlign: 'text-left',
                    textColor: 'text-[#3A3541]',
                    className: 'w-[150px] truncate pl-4',
                    visibility: true,
                    sorting: {
                        enable: true,
                        direction: 'NEUTRAL'
                    }
                },
                {
                    id: 4,
                    name: 'Extension',
                    column: 'extension',
                    textAlign: 'text-left',
                    textColor: 'text-[#3A3541]',
                    className: 'w-[150px] pl-4',
                    visibility: true,
                    sorting: {
                        enable: true,
                        direction: 'NEUTRAL'
                    }
                },
                {
                    id: 5,
                    name: 'Size',
                    column: 'size',
                    textAlign: 'text-left',
                    textColor: 'text-[#3A3541]',
                    className: 'w-[150px] pl-4',
                    visibility: true,
                    sorting: {
                        enable: true,
                        direction: 'NEUTRAL'
                    }
                },
                {
                    id: 6,
                    name: 'Percent (Overall)',
                    column: 'percent',
                    textAlign: 'text-left',
                    textColor: 'text-[#3A3541]',
                    className: 'w-[180px] pl-4',
                    visibility: true,
                    sorting: {
                        enable: true,
                        direction: 'NEUTRAL'
                    }
                }
            ];
            data = procededData.largeFile;

        } else if( selectedTile === 'VolumeByExtension' ) {
            columns = [
                {
                    id: 1,
                    name: '#',
                    column: 'serial',
                    textAlign: 'text-left',
                    textColor: 'text-[#3A3541]',
                    className: 'w-[30px] pl-4',
                    visibility: true,
                    sorting: {
                        enable: true,
                        direction: 'NEUTRAL'
                    }
                },
                {
                    id: 2,
                    name: 'Extension',
                    column: 'extension',
                    textAlign: 'text-left',
                    textColor: 'text-[#3A3541]',
                    className: 'w-[150px] pl-4',
                    visibility: true,
                    sorting: {
                        enable: true,
                        direction: 'NEUTRAL'
                    }
                },
                {
                    id: 3,
                    name: 'Size',
                    column: 'size',
                    textAlign: 'text-left',
                    textColor: 'text-[#3A3541]',
                    className: 'w-[150px] pl-4',
                    visibility: true,
                    sorting: {
                        enable: true,
                        direction: 'NEUTRAL'
                    }
                },
                {
                    id: 4,
                    name: 'Percent',
                    column: 'percent',
                    textAlign: 'text-left',
                    textColor: 'text-[#3A3541]',
                    className: 'w-[180px] pl-4',
                    visibility: true,
                    sorting: {
                        enable: true,
                        direction: 'NEUTRAL'
                    }
                }
            ];
            data = procededData.largestExts;
        } else if( selectedTile === 'FileExtensions' ) {
            columns = [
                {
                    id: 1,
                    name: '#',
                    column: 'serial',
                    textAlign: 'text-left',
                    textColor: 'text-[#3A3541]',
                    className: 'w-[30px] pl-4',
                    visibility: true,
                    sorting: {
                        enable: true,
                        direction: 'NEUTRAL'
                    }
                },
                {
                    id: 2,
                    name: 'Extension',
                    column: 'extension',
                    textAlign: 'text-left',
                    textColor: 'text-[#3A3541]',
                    className: 'w-[150px] pl-4',
                    visibility: true,
                    sorting: {
                        enable: true,
                        direction: 'NEUTRAL'
                    }
                },
                {
                    id: 3,
                    name: 'Instances',
                    column: 'instences',
                    textAlign: 'text-left',
                    textColor: 'text-[#3A3541]',
                    className: 'w-[150px] truncte pl-4',
                    visibility: true,
                    sorting: {
                        enable: true,
                        direction: 'NEUTRAL'
                    }
                },
                // {
                //     id: 4,
                //     name: 'Percent',
                //     column: 'percent',
                //     textAlign: 'text-left',
                //     textColor: 'text-[#3A3541]',
                //     className: 'w-[180px] pl-4',
                //     visibility: true
                // }
            ];
            data = procededData.mostInstancesExts;
        } else if( selectedTile === 'FileType' ) {
            columns = [
                {
                    id: 1,
                    name: '#',
                    column: 'serial',
                    textAlign: 'text-left',
                    textColor: 'text-[#3A3541]',
                    className: 'w-[30px] pl-4',
                    visibility: true,
                    sorting: {
                        enable: true,
                        direction: 'NEUTRAL'
                    }
                },
                {
                    id: 2,
                    name: 'Type',
                    column: 'type',
                    textAlign: 'text-left',
                    textColor: 'text-[#3A3541]',
                    className: 'w-[150px] pl-4',
                    visibility: true,
                    sorting: {
                        enable: true,
                        direction: 'NEUTRAL'
                    }
                },
                {
                    id: 3,
                    name: 'Instances',
                    column: 'instance',
                    textAlign: 'text-left',
                    textColor: 'text-[#3A3541]',
                    className: 'w-[150px] truncte pl-4',
                    visibility: true,
                    sorting: {
                        enable: true,
                        direction: 'NEUTRAL'
                    }
                },
                // {
                //     id: 6,
                //     name: 'Percent',
                //     column: 'percent',
                //     textAlign: 'text-left',
                //     textColor: 'text-[#3A3541]',
                //     className: 'w-[180px] pl-4',
                //     visibility: true
                // }
            ];
            data = procededData.typesInstances;
        }

        setTableColumns(columns);
        setTableController((prevState)=>{
            let newState = prevState;
            newState.data = data;
            return newState;
        })
        
    },[selectedTile])

    useEffect(()=>{
        const fetchProcessedData = () => {
            let procededData: any = processTableData(fileStatsRawData);
            console.log({fileStatsRawData})
            setFileStatsProcessedData(procededData)
            console.log({procededData})
            setTableController({
                ...tableController,
                data: procededData.largeFile
            })
        }
        fetchProcessedData()
    },[fileStatsRawData]);

    useEffect(()=>{
        let procededData: any = processTableData(fileStatsRawData);
        let arrTableData: any[] = []
        if(selectedTile === 'LargeFiles') {
            arrTableData = procededData.largeFile
        } else if(selectedTile === 'VolumeByExtension') {
            arrTableData = procededData.largestExts
        } else if(selectedTile === 'FileExtensions') {
            arrTableData = procededData.mostInstancesExts
        } else if(selectedTile === 'FileType') {
            arrTableData = procededData.typesInstances
        }

        if(tableController.searchTerm.length < 1) {
            if(procededData) { 
                setFileStatsProcessedData(procededData)
                setTableController((prevState)=>{
                    let newState = {...prevState};
                    newState.data = arrTableData;
                    return newState;
                })
            }
            return;
        }

        console.log({procededData})

        let searchedNodes = (arrTableData || []).filter((d:any) => {
            // console.log({d})
            if(
                ( d?.file?.text || '' ).toLowerCase().includes(tableController?.searchTerm.toLowerCase()) ||
                ( d?.extension?.text || '' ).toLowerCase().includes(tableController?.searchTerm.toLowerCase()) ||
                ( d?.instences?.text.toString() || '' ).toLowerCase().includes(tableController?.searchTerm.toLowerCase()) ||
                ( d?.instances?.text.toString() || '' ).toLowerCase().includes(tableController?.searchTerm.toLowerCase()) ||
                ( d?.type?.text || '' ).toLowerCase().includes(tableController?.searchTerm.toLowerCase()) ||
                ( d?.path?.text || '' ).toLowerCase().includes(tableController?.searchTerm.toLowerCase()) ||
                ( d?.size?.text.toString() || '' ).toLowerCase().includes(tableController?.searchTerm.toLowerCase()) ||
                ( d?.percent?.text.toString() || '' ).toLowerCase().includes(tableController?.searchTerm.toLowerCase())
            ) {
                return d;
            }
        }) || [];

        setTableController((prevState)=>{
            let newState = {...prevState};
            newState.data = searchedNodes || [];
            return newState;
        })

    },[tableController.searchTerm]);

    const tableDataFormatter = (rowObj: any, format?: string | undefined, rowIndex?: number) => {
        let returningVal: ReactNode | null | string = null;
        
        if( selectedTile === 'LargeFiles' ) {
            switch (format) {
                case 'Path':
                    returningVal = <div className='relative group flex items-center'>
                                        <em 
                                            className="fa-solid fa-copy text-[#FF5E0E] mr-1 cursor-pointer"
                                            onClick={()=>{
                                                if( rowObj?.text ) {
                                                    navigator.clipboard.writeText(rowObj?.text);
                                                    toast.success('Copied to clipboard')
                                                }
                                            }}
                                        ></em>
                                        <div className='truncate w-[150px]'>{rowObj?.text || ''}</div>
                                        { rowIndex === 0 ? (
                                            <Tooltip direction="down" value={rowObj?.text} className="left-[50px] mt-[70px]"/>
                                        ):(
                                            <Tooltip direction="up" value={rowObj?.text} className="left-[50px]"/>
                                        )}
                                    </div>;
                                    break;

                case 'File':
                    returningVal =  <div className='group relative flex items-center'>
                                        <em 
                                            className="fa-solid fa-copy text-[#FF5E0E] mr-1 cursor-pointer"
                                            onClick={()=>{
                                                if( rowObj?.text ) {
                                                    navigator.clipboard.writeText(rowObj?.text);
                                                    toast.success('Copied to clipboard')
                                                }
                                            }}
                                        ></em>
                                        <div className='truncate'>{rowObj?.text}</div>
                                        {rowIndex === 0 ? (
                                            <Tooltip direction='down' value={rowObj?.text} />
                                        ) : (
                                            <Tooltip  direction='up' value={rowObj?.text} />
                                        )}
                                    </div>
                                    break;

                case 'Percent':
                    returningVal = <div className=''>{(rowObj?.text || 0).toFixed(2)}%</div>;
                    // returningVal = <div className=''>{rowObj?.total || 0}</div>;
                    break;
    
                default:
                    returningVal = rowObj?.node || '';
                    break;
            }
        } else if( selectedTile === 'VolumeByExtension' ) {
            switch (format) {
                case 'Size':
                    returningVal = <div className=''>{rowObj?.text?.value || ''} {rowObj?.text?.unit || ''}</div>;
                    break;

                case 'Percent':
                    returningVal = <div className=' '>{(rowObj?.text || 0).toFixed(2)}%</div>;
                    break;
    
                default:
                    returningVal = rowObj?.node || '';
                    break;
            }
        } else if( selectedTile === 'FileExtensions' ) {
            switch (format) {
                // case 'Path':
                //     returningVal = <div className=''>{rowObj.text}</div>;
                //     break;

                case 'Percent':
                    returningVal = <div className=''>{(rowObj?.text || 0).toFixed(2)}%</div>;
                    // returningVal = <div className=''>{rowObj?.total || 0}</div>;
                    break;
    
                default:
                    returningVal = rowObj?.node || '';
                    break;
            }
        } else if( selectedTile === 'FileType' ) {
            switch (format) {
                // case 'Path':
                //     returningVal = <div className=''>{rowObj.text}</div>;
                //     break;

                case 'Percent':
                    returningVal = <div className=''>{(rowObj?.text || 0).toFixed(2)}%</div>;
                    // returningVal = <div className=''>{rowObj?.total || 0}</div>;
                    break;
    
                default:
                    returningVal = rowObj?.node || '';
                    break;
            }
        }

        
        return returningVal;
    }

    const handleDataSorting = (column:any) => {

        let columnName = column?.name;
        let tableData = [...tableController?.data];
        let returningValue: {[key: string]: any}[] = []
        let selection = selectedTile

        switch (columnName) {
            case '#':
                let sortedBySerial = SortWithSerial( selection, column?.sorting?.direction, tableData )
                if( sortedBySerial )
                    returningValue = sortedBySerial
                break;

            case 'File':
                let sortedFile = SortWithFile( selection, column?.sorting?.direction, tableData )
                if( sortedFile )
                    returningValue = sortedFile
                break;
                
            case 'Path':
                let sortedPath = SortWithPath( selection, column?.sorting?.direction, tableData )
                if( sortedPath )
                    returningValue = sortedPath
                break;
            
            case 'Extension':
                let sortedExtension = SortWithExtension( selection, column?.sorting?.direction, tableData )
                if( sortedExtension )
                    returningValue = sortedExtension
                break;
        
            case 'Size':
                let sortedSize = SortWithSize( selection, column?.sorting?.direction, tableData )
                if( sortedSize )
                    returningValue = sortedSize
                break;

            case 'Percent (Overall)':
                let sortedPercentOverall = SortWithPercentage( selection, column?.sorting?.direction, tableData )
                if( sortedPercentOverall )
                    returningValue = sortedPercentOverall
                break;

            case 'Percent':
                let sortedPercent = SortWithPercentage( selection, column?.sorting?.direction, tableData )
                if( sortedPercent )
                    returningValue = sortedPercent
                break;

            case 'Instances':
                let sortedPInstances = SortWithInstances( selection, column?.sorting?.direction, tableData )
                if( sortedPInstances )
                    returningValue = sortedPInstances
                break;

            case 'Type':
                let sortedType = SortWithType( selection, column?.sorting?.direction, tableData )
                if( sortedType )
                    returningValue = sortedType
                break;
        
            default:
                returningValue = tableData
                break;
        }

        console.log({selection, columnName, tableData, column, returningValue})

        setTableController((previousState:ITableControllerProps)=>{
            let newState = {...previousState}
            newState.data = returningValue
            return newState
        })

    }
   
    return (
        <div className={`p-6 grow flex flex-col`} ref={pageTop}>
            <span className="flex flex-col lg:flex-row items-start">
                <span className="flex-col">
                    <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Endpoints<em className="inline fa-solid fa-dash text-gray-400 mx-2"></em>
                        Backup Files 
                        <span className='ml-4 text-sm text-gray-600'>
                            (
                                Total {formatSize(fileStatsRawData?.data?.stats?.data?.Stats?.LargestExts?.Total || 0).value}{formatSize(fileStatsRawData?.data?.stats?.data?.Stats?.LargestExts?.Total || 0).unit}&nbsp;/&nbsp;
                                {fileStatsRawData?.data?.stats?.data?.Stats?.MostInstancesExts?.Total || 0} Instences
                            )
                        </span>
                    </h1>
                    <Breadcrumb arrBreadcrumb={[
                        { icon: 'fa-solid fa-bell-on', link: '/dashboard', name: 'Home' },
                        { icon: '', link: '', name: 'Managed Services' },
                        { icon: '', link: '/endpoint', name: 'Endpoint Backup' },
                        { icon: '', link: '', name: 'Backup Files' }
                    ]} />
                </span>
                <span className="grow"></span>
            </span>
            <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
            <div className="w-full">
                    <VolumeByExtensionCard
                        fileStatsRawData={fileStatsRawData}
                        fileStatsProcessedData={fileStatsProcessedData}
                        donutCutout={donutCutout}
                        setSelectedTile={setSelectedTile}
                    />
                </div>
                <div className="w-full">
                    <LargeFileCard
                        fileStatsRawData={fileStatsRawData}
                        fileStatsProcessedData={fileStatsProcessedData}
                        donutCutout={donutCutout}
                        setSelectedTile={setSelectedTile}
                    />
                </div>
                <div className="w-full">
                    <FileExtensionsCard
                        fileStatsRawData={fileStatsRawData}
                        fileStatsProcessedData={fileStatsProcessedData}
                        donutCutout={donutCutout}
                        setSelectedTile={setSelectedTile}
                    />
                </div>
                <div className="w-full">
                    <FileTypeCard
                        fileStatsRawData={fileStatsRawData}
                        fileStatsProcessedData={fileStatsProcessedData}
                        donutCutout={donutCutout}
                        setSelectedTile={setSelectedTile}
                    />
                </div>
            </div>

            <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                <Tile colSpan={12} rowSpan={25} className="pt-2 mb-6 min-h-[300px]" >
                    <div className="p-4 flex md:flex-row md:gap-4 gap-2" ref={scrollerRef}>
                        <div className=" text-gray-800">
                            {selectedTile==='LargeFiles' && 'Largest Files (Top 20)'}
                            {selectedTile==='VolumeByExtension' && 'Volume by Extension (Top 20)'}
                            {selectedTile==='FileExtensions' && 'Number of Files by Extension (Top 20)'}
                            {selectedTile==='FileType' && 'Number of Files by Type (Top 20)'}
                        </div>
                        <label htmlFor="searchBox" className="search flex items-center border rounded-md px-3 py-[2px]">
                            <em className="fa-solid fa-search text-slate-400 pr-2"></em>
                            <input 
                                type="text" 
                                id="searchBox"
                                placeholder="Search" 
                                className="outline-none w-full" 
                                value={tableController.searchTerm} 
                                onChange={(e)=>{
                                    setTableController((prevState)=>{
                                        let newState = {...prevState};
                                        newState.searchTerm = e.target.value;
                                        return newState;
                                    });
                                }}
                            />
                            <em className="fa-solid fa-times text-slate-200 hover:text-slate-500 cursor-pointer pl-2"
                                onClick={()=>{
                                    setTableController((prevState)=>{
                                        let newState = {...prevState};
                                        newState.searchTerm = '';
                                        return newState;
                                    });
                                }}
                            ></em>
                        </label>
                        <div className="grow"></div>                        
                    </div>
                    <div className="p-4 flex flex-row">
                        <div className="absolute left-0 right-0 sm:top-[80px] top-20 bottom-0 h-auto w-auto">
                            <Table
                                navRef={navRaf}
                                // actionButtonGenerator={}
                                columns={tableColumns}
                                setColumns={setTableColumns}
                                formatData={tableDataFormatter}
                                showActionCell={false}
                                showActionHeaderCell={false}
                                showColumnManagementMenu={false}
                                // switchHandler={handleSwitch}
                                tableController={tableController}
                                dataSortingHandler={handleDataSorting}
                                tableId="BackupFileTable"
                            />
                        </div>
                    </div>
                </Tile>
            </div>
        </div>
    )
}

export default BackupFiles;