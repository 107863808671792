import {DateTime} from "luxon";
import * as _ from "lodash";

const originalPolicies: {[key: string]: any}[] = [
    {
        Name                      : "No server rule",
        ID                        : 1,
        Tenant                    : "",
        OS                        : "ALL",
        BareMetalRestore          : false,
        BuiltIn                   : true,
        TenantID                  : 0,
        InternalName              : "__DP__PLNP__",
    },
    {
        Name                      : "Windows - Free protection on the entire agent  / Not protected by default  / System Temp excluded",
        ID                        : 10,
        Tenant                    : "",
        OS                        : "WIN",
        BareMetalRestore          : false,
        BuiltIn                   : false,
        TenantID                  : 0,
        InternalName              : "__DP__PLNPSI__",
        Rule1                    : "The entire computer  &lparen; &bsol; &rparen;  &gt;&gt; Do not protect any object except those specified by user",
        Rule2                    : "System Directory  &lparen; &bsol;*&bsol;Windows&bsol; &rparen;  &gt;&gt; Free protection / Not protected by default / System Temp excluded",
    },
    {
        Name                      : "Windows - Free protection on the entire agent  / Protected by default  / System Temp excluded",
        ID                        : 11,
        Tenant                    : "",
        OS                        : "WIN",
        BareMetalRestore          : false,
        BuiltIn                   : false,
        TenantID                  : 0,
        InternalName              : "__DP__PLPSI__",
        Rule1                    : "The entire computer  &lparen; &bsol; &rparen;  &gt;&gt; Protect all objects except those specified by user",
        Rule2                    : "System Directory  &lparen; &bsol;*&bsol;Windows&bsol; &rparen;  &gt;&gt; Free protection / Protected by default / System Temp excluded",
    },
    {
        Name                      : "Windows - Mandatory protection of the entire agent  / System Temp excluded",
        ID                        : 12,
        Tenant                    : "",
        OS                        : "WIN",
        BareMetalRestore          : false,
        BuiltIn                   : false,
        TenantID                  : 0,
        InternalName              : "__DP__PLSI__",
        Rule1                    : "The entire computer  &lparen; &bsol; &rparen;  &gt;&gt; Protect all objects",
        Rule2                    : "System Directory  &lparen; &bsol;*&bsol;Windows&bsol; &rparen;  &gt;&gt; Mandatory protection / System Temp excluded",
    },
    {
        Name                      : "The entire agent is excluded from protection",
        ID                        : 13,
        Tenant                    : "",
        OS                        : "ALL",
        BareMetalRestore          : false,
        BuiltIn                   : false,
        TenantID                  : 0,
        InternalName              : "__DP__PI__",
        Rule1                    : "The entire computer  &lparen; &bsol; &rparen;  &gt;&gt; Do not protect any object",
    },
    {
        Name                      : "Windows - Protecting Office files only /Mandatory protection",
        ID                        : 14,
        Tenant                    : "",
        OS                        : "WIN",
        BareMetalRestore          : false,
        BuiltIn                   : false,
        TenantID                  : 0,
        InternalName              : "__DP__POPO__",
        Rule1                    : "The entire computer  &lparen; &bsol; &rparen;  &gt;&gt; Do not protect any object",
        Rule2                    : "Windows XP Users Directory  &lparen; &bsol;*&bsol;Documents and Settings&bsol;*&bsol; &rparen;  &gt;&gt; Office files only / Mandatory protection",
        Rule3                    : "Windows Vista/7 Users Directory  &lparen; &bsol;*&bsol;Users&bsol;*&bsol; &rparen;  &gt;&gt; Office files only / Mandatory protection",
    },
    {
        Name                      : "Windows - Protection limited to Office files / Protected by default",
        ID                        : 15,
        Tenant                    : "",
        OS                        : "WIN",
        BareMetalRestore          : false,
        BuiltIn                   : false,
        TenantID                  : 0,
        InternalName              : "__DP__POP__",
        Rule1                    : "The entire computer  &lparen; &bsol; &rparen;  &gt;&gt; Do not protect any object",
        Rule2                    : "Windows XP Users Directory  &lparen; &bsol;*&bsol;Documents and Settings&bsol;*&bsol; &rparen;  &gt;&gt; Office files only / Free protection / Protected by default ",
        Rule3                    : "Windows Vista/7 Users Directory  &lparen; &bsol;*&bsol;Users&bsol;*&bsol; &rparen;  &gt;&gt; Office files only / Free protection / Protected by default ",
    },
    {
        Name                      : "Windows - Protection limited to user directories / Mandatory protection",
        ID                        : 16,
        Tenant                    : "",
        OS                        : "WIN",
        BareMetalRestore          : false,
        BuiltIn                   : false,
        TenantID                  : 0,
        InternalName              : "__DP__PUPO__",
        Rule1                    : "The entire computer  &lparen; &bsol; &rparen;  &gt;&gt; Do not protect any object",
        Rule2                    : "Windows XP Users Directory  &lparen; &bsol;*&bsol;Documents and Settings&bsol;*&bsol; &rparen;  &gt;&gt; Protect all objects",
        Rule3                    : "Windows Vista/7 Users Directory  &lparen; &bsol;*&bsol;Users&bsol;*&bsol; &rparen;  &gt;&gt; Protect all objects",
    },
    {
        Name                      : "Windows - Protection limited to users directories / Free protection / Not protected by default",
        ID                        : 17,
        Tenant                    : "",
        OS                        : "WIN",
        BareMetalRestore          : false,
        BuiltIn                   : false,
        TenantID                  : 0,
        InternalName              : "__DP__PUPLNP__",
        Rule1                    : "The entire computer  &lparen; &bsol; &rparen;  &gt;&gt; Do not protect any object",
        Rule2                    : "Windows XP Users Directory  &lparen; &bsol;*&bsol;Documents and Settings&bsol;*&bsol; &rparen;  &gt;&gt; Do not protect any object except those specified by user",
        Rule3                    : "Windows Vista/7 Users Directory  &lparen; &bsol;*&bsol;Users&bsol;*&bsol; &rparen;  &gt;&gt; Do not protect any object except those specified by user",
    },
    {
        Name                      : "Windows - Protection limited to users directories / Free protection / Protected by default",
        ID                        : 18,
        Tenant                    : "",
        OS                        : "WIN",
        BareMetalRestore          : false,
        BuiltIn                   : false,
        TenantID                  : 0,
        InternalName              : "__DP__PUPLP__",
        Rule1                    : "The entire computer  &lparen; &bsol; &rparen;  &gt;&gt; Do not protect any object",
        Rule2                    : "Windows XP Users Directory  &lparen; &bsol;*&bsol;Documents and Settings&bsol;*&bsol; &rparen;  &gt;&gt; Protect all objects except those specified by user",
        Rule3                    : "Windows Vista/7 Users Directory  &lparen; &bsol;*&bsol;Users&bsol;*&bsol; &rparen;  &gt;&gt; Protect all objects except those specified by user",
    },
    {
        Name                      : "Windows - Protection limited to users directories / Free protection / Not protected by default / Multimedia excluded",
        ID                        : 19,
        Tenant                    : "",
        OS                        : "WIN",
        BareMetalRestore          : false,
        BuiltIn                   : false,
        TenantID                  : 0,
        InternalName              : "__DP__PUPLNPMI__",
        Rule1                    : "The entire computer  &lparen; &bsol; &rparen;  &gt;&gt; Do not protect any object",
        Rule2                    : "Windows XP Users Directory  &lparen; &bsol;*&bsol;Documents and Settings&bsol;*&bsol; &rparen;  &gt;&gt; Free protection / Not protected by default / Multimedia files excluded",
        Rule3                    : "Windows Vista/7 Users Directory  &lparen; &bsol;*&bsol;Users&bsol;*&bsol; &rparen;  &gt;&gt; Free protection / Not protected by default / Multimedia files excluded",
    },
    {
        Name                      : "Mac - Free protection on the entire agent / Not protected by default / System Temp excluded",
        ID                        : 20,
        Tenant                    : "",
        OS                        : "MAC",
        BareMetalRestore          : false,
        BuiltIn                   : false,
        TenantID                  : 0,
        InternalName              : "__DP__PLNPSI__MAC__",
        Rule1                    : "The entire computer  &lparen; &bsol; &rparen;  &gt;&gt; Do not protect any object except those specified by user",
        Rule2                    : "Mac System Directory  &lparen; /System &rparen;  &gt;&gt; Free protection / Not protected by default / System Temp excluded",
        Rule3                    : "Mac System Directory &lparen;libraries&rparen;  &lparen; /Library &rparen;  &gt;&gt; Free protection / Not protected by default / System Temp excluded",
    },
    {
        Name                      : "Mac - Free protection on the entire agent / Protected by default / System Temp excluded",
        ID                        : 21,
        Tenant                    : "",
        OS                        : "MAC",
        BareMetalRestore          : false,
        BuiltIn                   : false,
        TenantID                  : 0,
        InternalName              : "__DP__PLPSI__MAC__",
        Rule1                    : "The entire computer  &lparen; &bsol; &rparen;  &gt;&gt; Protect all objects except those specified by user",
        Rule2                    : "Mac System Directory  &lparen; /System &rparen;  &gt;&gt; Free protection / Protected by default / System Temp excluded",
        Rule3                    : "Mac System Directory &lparen;libraries&rparen;  &lparen; /Library &rparen;  &gt;&gt; Free protection / Protected by default / System Temp excluded",
    },
    {
        Name                      : "Mac - Mandatory protection on the entire agent / System Temp excluded",
        ID                        : 22,
        Tenant                    : "",
        OS                        : "MAC",
        BareMetalRestore          : false,
        BuiltIn                   : false,
        TenantID                  : 0,
        InternalName              : "__DP__PLSI__MAC__",
        Rule1                    : "The entire computer  &lparen; &bsol; &rparen;  &gt;&gt; Protect all objects",
        Rule2                    : "Mac System Directory  &lparen; /System &rparen;  &gt;&gt; Mandatory protection / System Temp excluded",
        Rule3                    : "Mac System Directory &lparen;libraries&rparen;  &lparen; /Library &rparen;  &gt;&gt; Mandatory protection / System Temp excluded",
    },
    {
        Name                      : "Mac - Protection limited to Office files / Mandatory protection ",
        ID                        : 23,
        Tenant                    : "",
        OS                        : "MAC",
        BareMetalRestore          : false,
        BuiltIn                   : false,
        TenantID                  : 0,
        InternalName              : "__DP__POPO__MAC__",
        Rule1                    : "The entire computer  &lparen; &bsol; &rparen;  &gt;&gt; Do not protect any object",
        Rule2                    : "Mac Users Directories  &lparen; /Users &rparen;  &gt;&gt; Office files only / Mandatory protection",
    },
    {
        Name                      : "Mac - Protection limited to Office files / Protected by default ",
        ID                        : 24,
        Tenant                    : "",
        OS                        : "MAC",
        BareMetalRestore          : false,
        BuiltIn                   : false,
        TenantID                  : 0,
        InternalName              : "__DP__POP__MAC__",
        Rule1                    : "The entire computer  &lparen; &bsol; &rparen;  &gt;&gt; Do not protect any object",
        Rule2                    : "Mac Users Directories  &lparen; /Users &rparen;  &gt;&gt; Office files only / Free protection / Protected by default ",
    },
    {
        Name                      : "Mac - Protection limited to users directories / Mandatory  protection ",
        ID                        : 25,
        Tenant                    : "",
        OS                        : "MAC",
        BareMetalRestore          : false,
        BuiltIn                   : false,
        TenantID                  : 0,
        InternalName              : "__DP__PUPO__MAC__",
        Rule1                    : "The entire computer  &lparen; &bsol; &rparen;  &gt;&gt; Do not protect any object",
        Rule2                    : "Mac Users Directories  &lparen; /Users &rparen;  &gt;&gt; Protect all objects",
    },
    {
        Name                      : "Mac - Protection limited to users directories / Free protection / Not protected by default ",
        ID                        : 26,
        Tenant                    : "",
        OS                        : "MAC",
        BareMetalRestore          : false,
        BuiltIn                   : false,
        TenantID                  : 0,
        InternalName              : "__DP__PUPLNP__MAC__",
        Rule1                    : "The entire computer  &lparen; &bsol; &rparen;  &gt;&gt; Do not protect any object",
        Rule2                    : "Mac Users Directories  &lparen; /Users &rparen;  &gt;&gt; Do not protect any object except those specified by user",
    },
    {
        Name                      : "Mac - Protection limited to users directories / Free protection / Protected by default ",
        ID                        : 27,
        Tenant                    : "",
        OS                        : "MAC",
        BareMetalRestore          : false,
        BuiltIn                   : false,
        TenantID                  : 0,
        InternalName              : "__DP__PUPLP__MAC__",
        Rule1                    : "The entire computer  &lparen; &bsol; &rparen;  &gt;&gt; Do not protect any object",
        Rule2                    : "Mac Users Directories  &lparen; /Users &rparen;  &gt;&gt; Protect all objects except those specified by user",
    },
    {
        Name                      : "Mac - Protection limited to users directories / Free protection / Not protected by default / Multimedia excluded",
        ID                        : 28,
        Tenant                    : "",
        OS                        : "MAC",
        BareMetalRestore          : false,
        BuiltIn                   : false,
        TenantID                  : 0,
        InternalName              : "__DP__PUPLNPMI__MAC__",
        Rule1                    : "The entire computer  &lparen; &bsol; &rparen;  &gt;&gt; Do not protect any object",
        Rule2                    : "Mac Users Directories  &lparen; /Users &rparen;  &gt;&gt; Free protection / Not protected by default / Multimedia files excluded",
    },
    {
        Name                      : "Windows - Protection for Bare Metal Restore / System Disk only",
        ID                        : 40,
        Tenant                    : "",
        OS                        : "WIN",
        BareMetalRestore          : true,
        BuiltIn                   : false,
        TenantID                  : 0,
        InternalName              : "__DP__BMR__",
    },
    {
        Name                      : "Windows - Protection for Bare Metal Restore / Mandatory protection of the entire agent",
        ID                        : 41,
        Tenant                    : "",
        OS                        : "WIN",
        BareMetalRestore          : true,
        BuiltIn                   : true,
        TenantID                  : 0,
        InternalName              : "__DP__BMRPO__",
        Rule1                    : "The entire computer  &lparen; &bsol; &rparen;  &gt;&gt; Protect all objects",
    },
    {
        Name                      : "Windows - Protection for Bare Metal Restore / Free protection on the entire agent / Protected by default",
        ID                        : 42,
        Tenant                    : "",
        OS                        : "WIN",
        BareMetalRestore          : true,
        BuiltIn                   : false,
        TenantID                  : 0,
        InternalName              : "__DP__BMRPLP__",
        Rule1                    : "The entire computer  &lparen; &bsol; &rparen;  &gt;&gt; Protect all objects except those specified by user",
    }
];

const originalStrategy: {[key: string]: any}[] = [
    {
        Name                      : "Default strategy / No restrictions / With network encryption",
        ID                        : 1,
        Tenant                    : "",
        RPOInMinutes              : 5,
        RetentionInDays           : 30,
        AlertAfterDays            : 3,
        ThroughputLimitKBps       : 0,
        ReplicationTarget         : "NONE",
        AlwaysEncrypt             : true,
        WanMode                   : false,
        CompressionAlgo           : "ZSTD",
        AllowClientRPO            : true,
        AllowClientRules          : true,
        AllowClientPause          : true,
        AllowClientBoost          : true,
        AllowClientNetworkParams  : true,
        AllowWebAccess            : true,
        QuotaMaxProtSizeMB        : 0,
        QuotaMaxProtObjs          : 0,
        QuotaMaxHistSizeMB        : 0,
        QuotaMaxHistObjs          : 0,
        ReplicationTargetID       : 0,
        TenantID                  : 0,
        InternalName              : "PREDEF",
    },
    {
        Name                      : "Unlocked strategy / No network encryption",
        ID                        : 10,
        Tenant                    : "",
        RPOInMinutes              : 5,
        RetentionInDays           : 30,
        AlertAfterDays            : 3,
        ThroughputLimitKBps       : 0,
        ReplicationTarget         : "NONE",
        AlwaysEncrypt             : false,
        WanMode                   : false,
        CompressionAlgo           : "ZSTD",
        AllowClientRPO            : true,
        AllowClientRules          : true,
        AllowClientPause          : true,
        AllowClientBoost          : true,
        AllowClientNetworkParams  : true,
        AllowWebAccess            : true,
        QuotaMaxProtSizeMB        : 0,
        QuotaMaxProtObjs          : 0,
        QuotaMaxHistSizeMB        : 0,
        QuotaMaxHistObjs          : 0,
        ReplicationTargetID       : 0,
        TenantID                  : 0,
        InternalName              : "__SP__SNVSC__",
    },
    {
        Name                      : "Locked strategy / No network encryption",
        ID                        : 11,
        Tenant                    : "",
        RPOInMinutes              : 5,
        RetentionInDays           : 30,
        AlertAfterDays            : 3,
        ThroughputLimitKBps       : 0,
        ReplicationTarget         : "NONE",
        AlwaysEncrypt             : false,
        WanMode                   : false,
        CompressionAlgo           : "ZSTD",
        AllowClientRPO            : false,
        AllowClientRules          : true,
        AllowClientPause          : true,
        AllowClientBoost          : true,
        AllowClientNetworkParams  : false,
        AllowWebAccess            : false,
        QuotaMaxProtSizeMB        : 0,
        QuotaMaxProtObjs          : 0,
        QuotaMaxHistSizeMB        : 0,
        QuotaMaxHistObjs          : 0,
        ReplicationTargetID       : 0,
        TenantID                  : 0,
        InternalName              : "__SP__SVSC__",
        },
    {
        Name                      : "Locked strategy / With network encryption",
        ID                        : 12,
        Tenant                    : "",
        RPOInMinutes              : 5,
        RetentionInDays           : 30,
        AlertAfterDays            : 3,
        ThroughputLimitKBps       : 0,
        ReplicationTarget         : "NONE",
        AlwaysEncrypt             : true,
        WanMode                   : false,
        CompressionAlgo           : "ZSTD",
        AllowClientRPO            : false,
        AllowClientRules          : true,
        AllowClientPause          : true,
        AllowClientBoost          : true,
        AllowClientNetworkParams  : false,
        AllowWebAccess            : false,
        QuotaMaxProtSizeMB        : 0,
        QuotaMaxProtObjs          : 0,
        QuotaMaxHistSizeMB        : 0,
        QuotaMaxHistObjs          : 0,
        ReplicationTargetID       : 0,
        TenantID                  : 0,
        InternalName              : "__SP__SVAC__",
    },
    {
        Name                      : "Unlocked strategy / With network encryption",
        ID                        : 13,
        Tenant                    : "",
        RPOInMinutes              : 5,
        RetentionInDays           : 30,
        AlertAfterDays            : 3,
        ThroughputLimitKBps       : 0,
        ReplicationTarget         : "NONE",
        AlwaysEncrypt             : true,
        WanMode                   : false,
        CompressionAlgo           : "ZSTD",
        AllowClientRPO            : true,
        AllowClientRules          : true,
        AllowClientPause          : true,
        AllowClientBoost          : true,
        AllowClientNetworkParams  : true,
        AllowWebAccess            : true,
        QuotaMaxProtSizeMB        : 0,
        QuotaMaxProtObjs          : 0,
        QuotaMaxHistSizeMB        : 0,
        QuotaMaxHistObjs          : 0,
        ReplicationTargetID       : 0,
        TenantID                  : 0,
        InternalName              : "__SP__SNVAC__",
    },
    {
        Name                      : "Network blocking strategy / No retention limit",
        ID                        : 14,
        Tenant                    : "",
        RPOInMinutes              : 5,
        RetentionInDays           : 0,
        AlertAfterDays            : 3,
        ThroughputLimitKBps       : 0,
        ReplicationTarget         : "NONE",
        AlwaysEncrypt             : true,
        WanMode                   : false,
        CompressionAlgo           : "ZSTD",
        AllowClientRPO            : false,
        AllowClientRules          : true,
        AllowClientPause          : true,
        AllowClientBoost          : true,
        AllowClientNetworkParams  : false,
        AllowWebAccess            : false,
        QuotaMaxProtSizeMB        : 0,
        QuotaMaxProtObjs          : 0,
        QuotaMaxHistSizeMB        : 0,
        QuotaMaxHistObjs          : 0,
        ReplicationTargetID       : 0,
        TenantID                  : 0,
        InternalName              : "__SP__BLOCKED__",
    },
];

const originalDisabledReasons: {[key: string]: any} = {
    "4": "On Vacation", // license count 
    "6": "Paused", // license count
    "2": "Archive", // no license count
    "3": "Keep Only Last Version", // no license count
    "5": "Manually Disabled" // no license count
}

const agentNameFormatter = (agentName: any) => {
    if (agentName === undefined || agentName === null || agentName === "") {
        return "NA";
    }
    return agentName;
};

const lastConnectionFormatter = (t: any) => {
    if ( t === undefined || t === null || t === '' ) {
        return "NA";
    }
    let formattedTime = '';
    try {
        formattedTime = DateTime.fromISO( new Date(t).toISOString() ).toFormat("dd/MM/y T");
        return formattedTime;
    } catch (error) {
        console.log(error)
    } finally {
        // console.log({t},{formattedTime})
    }
};

// const lastBackupFormatter = (t: any) => {
//     if ( t === undefined || t === null || t === '' ) { return "NA"; }
//     try {
//         let present = DateTime.now();
//         let past = DateTime.fromISO(new Date(parseInt(t)).toISOString());
//         let diff = present.diff(past, "days").toObject();
//         return parseInt(diff?.days + '') === 0 ? `Today` : `> ${parseInt(diff?.days + '')} days`;
//     } catch (error) {
//         console.log(error)   
//     } finally {
//         // console.log({t})
//     }
// };

const lastBackupFormatter = (t: any) => {
    if (t === undefined || t === null || t === '') {
        return "NA";
    }

    try {
        let present = DateTime.now();
        let past = DateTime.fromISO(new Date(parseInt(t)).toISOString());
        let diff = present.diff(past).shiftTo('days', 'hours', 'minutes', 'seconds');

        let days = diff.days;
        let hours = diff.hours;
        let minutes = diff.minutes;
        let seconds = diff.seconds;

        if (days > 0) {
            return `> ${days} days`;
        } else if (hours > 0) {
            return `${hours} hours`;
        } else if (minutes > 0) {
            return `${minutes} minutes`;
        } else {
            return `${seconds} seconds`;
        }
    } catch (error) {
        console.log(error);
    } finally {
        // console.log({t})
    }
};

const quotaFormatter = (quota: any) => {
    if (quota === undefined || quota === null || quota === "") {
        return "NA";
    }
    return quota;
};

const formatBytes = (bytes: any, decimals: number = 2) => {
    let negative = false;
    if (bytes === 0) return "0 Bytes";

    if( bytes < 0 ) {
        bytes = bytes * -1;
        negative = true;
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return (negative ? '- ' : '') + parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const protectedDataFormatter = (data: any) => {
    if (data === undefined || data === null || data === "") {
        return "NA";
    }
    return formatBytes(data);
};

const getDateStringForFiles = () => {
    let year = zerofill((new Date()).getFullYear(),4);
    let month = zerofill((new Date()).getMonth() + 1);
    let day = zerofill((new Date()).getDate());
    let hours = zerofill((new Date()).getHours());
    let minutes = zerofill((new Date()).getMinutes());
    let seconds = zerofill((new Date()).getSeconds());

    return (year || '').toString() + (month || '').toString() + (day || '').toString() + '_' + (hours || '').toString() + (minutes || '').toString() + (seconds || '').toString()
}

const zerofill = (val: any, outChat: number=2, side: string='previous', fill: string='0') => {

    let zeros = (new Array(outChat)).fill(0).join('');

    let output = null;

    if( side === 'previous' ) {
        output = (zeros + val).split('').splice(outChat).join('');
    }

    if( side === 'end' ) {
        output = (val + zeros).split('').splice(outChat).join('');
    }

    return output;
}

const deviceSymbol = (deviceName: string, type: string= 'ReactNode') => {
    deviceName = (deviceName || '').toLowerCase();
    let deviceIcon = type === 'ReactNode' ? <em className="fa-solid fa-square-question"></em> : 'unknown';

    let deviceType: any = /(window)/.test(deviceName) || null;

    if( deviceType ) {
        deviceIcon = type === 'ReactNode' ? <em className="fa-brands fa-windows"></em> : 'Windows';
        deviceType = null;
    }

    deviceType = /(linux)/.test(deviceName) || null;
    if( deviceType ) {
        deviceIcon = type === 'ReactNode' ? <em className="fa-brands fa-linux"></em> : 'Linux';
        deviceType = null;
    }

    deviceType = /(apple|mac)/.test(deviceName) || null;
    if( deviceType ) {
        deviceIcon = type === 'ReactNode' ? <em className="fa-brands fa-apple"></em> : 'Apple';
        deviceType = null;
    }

    return deviceIcon;
}

const getDeviceStatus = (endpointsData: any, endpoint: any) => {
    
    let unprotected = endpointsData.unprotected.find((ep:any) => ep._id === endpoint._id);
    let installed = endpointsData.installed.find((ep:any) => ep._id === endpoint._id);
    let pending = endpointsData.pending_installation.find((ep:any) => ep._id === endpoint._id);
    let deleted = endpointsData.deleted.find((ep:any) => ep._id === endpoint._id);
    let disabled = endpointsData.endpoints.filter((ep:any)=>{ return ep.agent_profile.BlockListInfos !== null }).find((ep:any) => ep._id === endpoint._id);



    if( pending ) {
        return 'Pending';
    }

    if( deleted ) {
        return 'Deleted';
    }

    if( installed ) {
        if( unprotected ) {
            return 'Unprotected';
        } else if(disabled) {
            return 'Disabled'
        } else {
            return 'Protected';
        }
    }
}

const getProtectedAgents = (endpointData: any) => {
    // which are installed but not unprotected
    return (endpointData?.installed || []).filter((ep:any)=>{
        return !ep.agent_profile.ServerInfos.Alerts.Alert
    });
}

const getAgentPolicyDetails = (agentProfile: any) => {

    const policy = agentProfile?.endpoint?.agent_profile?.AdminInfos?.DataProfile;
    const strategyData = agentProfile?.endpoint?.agent_profile?.AdminInfos?.ServiceProfile;

    let DataProfile = agentProfile?.endpoint?.metadata?.protectionPolicyData?.DataProfileArray?.DataProfile;
    let ServiceProfile = agentProfile?.endpoint?.metadata?.strategyData?.HNConfig?.ServiceProfileArray?.ServiceProfile;
    let FilterRule = agentProfile?.endpoint?.metadata?.protectionRulesData?.FilterRuleArray?.FilterRule;
    let FileType = agentProfile?.endpoint?.metadata?.protectionFilesData?.FileTypeArray?.FileType;
    let PredefinedPath = agentProfile?.endpoint?.metadata?.protectionZonesData?.PredefinedPathArray?.PredefinedPath;

    const policyProfile = DataProfile.find((_policy:any)=>{ return policy.ID === _policy.ID });

    let fileExtensions = { INCL: [], EXCL: [] }
    let fileExtensionsPerFilter = {}

    let rulesArray = (policyProfile?.RuleArray?.RulePredef || []).length ? policyProfile?.RuleArray?.RulePredef : [policyProfile?.RuleArray?.RulePredef];

    let filterProfile = rulesArray.map((e:any)=>{

        let filterRaw = FilterRule.find((fl:any) => fl?.ID === e?.FilterRuleID);
        let pathRaw = PredefinedPath.find((fz:any) => fz?.ID === e?.PredefinedPathID);

        _.each((filterRaw?.SubRuleArray || []).length ? filterRaw?.SubRuleArray : (_.isEmpty(filterRaw?.SubRuleArray) ? [] : [filterRaw?.SubRuleArray]), (val) => {

            if( val?.SubRule ) {
                let SubRule = Array.isArray(val?.SubRule) ? val?.SubRule : [val?.SubRule];

                for (const element of SubRule) {

                    const _FileType = element.FileType;
                    let ID = _FileType?.ID || "";
                    let Name = _FileType?.Name || "";
                    let Behavior = element?.Behavior || "";

                    let result = Array.isArray(FileType) ? (FileType || []).find((e:any) => e?.ID === ID ) || {} : FileType || {};  

                    let ExtArray = result?.ExtArray || {};
                    let Ext = ExtArray?.Ext || []; 
                    Ext = Array.isArray(Ext) ? Ext : [Ext]

                    fileExtensions[Behavior][ID] = Ext;
                    fileExtensionsPerFilter[ID]= {
                        Ext,
                        ID,
                        Name,
                        Behavior
                    }
                }
            }
        })

        let arrSubRule = [];

        if( filterRaw?.SubRuleArray?.SubRule ) {
            arrSubRule = Array.isArray(filterRaw?.SubRuleArray?.SubRule) ? filterRaw?.SubRuleArray?.SubRule : [filterRaw?.SubRuleArray?.SubRule];
        }

        return {
            path: pathRaw,
            ID: e?.FilterRuleID,
            _ID: filterRaw?.SubRuleArray?.SubRule ? arrSubRule.map((sr: any)=>{ return sr?.FileType?.ID || ''}) : [],
            filter: {
                id: filterRaw?.ID,
                comment: filterRaw?.ID === 2 ? "All excluded at this path" : (filterRaw?.ID === 1 ? "All included at this path" : "Custom Filter")
                // comment: filterRaw?.ID === 2 ? "All excluded at this path" : (filterRaw?.ID === 1 ? "All included at this path" : filterRaw?.Name)
            }
        }
    })

    filterProfile.forEach((element:any, elementIndex:number) => {
        if( fileExtensionsPerFilter[element.ID] !== undefined ) {
            filterProfile[elementIndex].extensions = fileExtensionsPerFilter[element.ID]
        } else {
            filterProfile[elementIndex].extensions = {}
        }
    });

    return {
        policy: policy, // policy
        profile: policyProfile, // policy profile
        path: filterProfile, // policy rules and path
        category: fileExtensions, // policy category and extensions
        strategy: (ServiceProfile || []).find((e:any) => strategyData.ID.toString() === e.ID.toString()), // strategy
        extensions: fileExtensionsPerFilter
    };
}

const getEndpointConfig = (rawPolicyStrategyData: any, policy: any, strategy: any = null) => {

    if( !rawPolicyStrategyData ) {
        return;
    }

    let DataProfile = rawPolicyStrategyData?.current_policies;
    let ServiceProfile = rawPolicyStrategyData?.current_strategies;
    let FilterRule = rawPolicyStrategyData?.current_protectionRule;
    let FileType = rawPolicyStrategyData?.current_protectionFile;
    let PredefinedPath = rawPolicyStrategyData?.current_protectionZone;

    const policyProfile = DataProfile.find((_policy:any)=>{ return policy?.ID === _policy?.ID });

    let fileExtensions = { INCL: {}, EXCL: {} }
    let fileExtensionsPerFilter = {}

    let rulesArray = (policyProfile?.RuleArray?.RulePredef || []).length ? policyProfile?.RuleArray?.RulePredef : [policyProfile?.RuleArray?.RulePredef];

    let filterProfile = rulesArray.map((e:any)=>{

        let filterRaw = FilterRule.find((fl:any) => fl?.ID === e?.FilterRuleID);
        let pathRaw = PredefinedPath.find((fz:any) => fz?.ID === e?.PredefinedPathID);

        _.each((filterRaw?.SubRuleArray || []).length ? filterRaw?.SubRuleArray : (_.isEmpty(filterRaw?.SubRuleArray) ? [] : [filterRaw?.SubRuleArray]), (val) => {

            if( val?.SubRule ) {
                let SubRule = Array.isArray(val?.SubRule) ? val?.SubRule : [val?.SubRule];

                for (const element of SubRule) {

                    const _FileType = element.FileType;
                    let ID = _FileType?.ID || "";
                    let Name = _FileType?.Name || "";
                    let Behavior = element?.Behavior || "";

                    let result = Array.isArray(FileType) ? (FileType || []).find((e:any) => e?.ID === ID ) || {} : FileType || {};  

                    let ExtArray = result?.ExtArray || {};
                    let Ext = ExtArray?.Ext || []; 
                    Ext = Array.isArray(Ext) ? Ext : [Ext];

                    fileExtensions[Behavior][ID] = Ext;
                    fileExtensionsPerFilter[ID]= {
                        Ext,
                        ID,
                        Name,
                        Behavior
                    }
                }
            }

        })

        let arrSubRule = [];

        if( filterRaw?.SubRuleArray?.SubRule ) {
            arrSubRule = Array.isArray(filterRaw?.SubRuleArray?.SubRule) ? filterRaw?.SubRuleArray?.SubRule : [filterRaw?.SubRuleArray?.SubRule];
        }

        return {
            path: pathRaw,
            ID: e?.FilterRuleID,
            _ID: filterRaw?.SubRuleArray?.SubRule ? arrSubRule.map((sr: any)=>{ return sr?.FileType?.ID || ''}) : [],
            filter: {
                id: filterRaw?.ID,
                comment: filterRaw?.ID === 2 ? "All excluded at this path" : (filterRaw?.ID === 1 ? "All included at this path" :"Custom Filter")
            }
        }
    })

    filterProfile.forEach((element:any, elementIndex:number) => {
        console.log(fileExtensionsPerFilter)
        if( fileExtensionsPerFilter[element.ID] !== undefined ) {
            filterProfile[elementIndex].extensions = fileExtensionsPerFilter[element.ID]
        } else {
            filterProfile[elementIndex].extensions = {}
        }
    });

    return {
        policy: policy, // policy
        profile: policyProfile, // policy profile
        path: filterProfile, // policy rules and path
        category: fileExtensions, // policy category and extensions
        strategy: strategy ? (ServiceProfile || []).find((e:any) => strategy.ID.toString() === e.ID.toString()) : '', // strategy
        extensions: fileExtensionsPerFilter
    };
}

export { 
    originalPolicies,
    originalStrategy,
    originalDisabledReasons,
    agentNameFormatter,
    lastConnectionFormatter,
    lastBackupFormatter,
    quotaFormatter,
    formatBytes,
    protectedDataFormatter,
    getDateStringForFiles,
    deviceSymbol,
    getDeviceStatus,
    getProtectedAgents,
    getAgentPolicyDetails,
    getEndpointConfig
}