/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
// import Tile3x3_Text from "../components/applicationComponents/Tiles/Tile3x3_Text/Tile3x3_Text";
// import Tile5x4_ChartText from "../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText";
import { Breadcrumb, Button, Tile } from "../components/core";
// import { ThemeContext } from "../contexts";
// import { Breadcrumb, Tile } from "../components/core";
import { Table } from "../components/core/Table";
import { ITableColumnItem, ITableControllerProps } from "../components/core/Table/Table";



const Users = ( ) =>{
    // const [detailopen,setDetailOpen]=useState(false)
    
    // const DetailOpenHandler = ()=>{
    //     return(
    //         setDetailOpen(!detailopen)
    //     )
    // }

    // const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    const navRaf = useRef<HTMLDivElement>(null)

    const [tableController, setTableController] = useState<ITableControllerProps>({
        data: [],
        loader: false,
        currentPage: 1,
        offset: 0,
        limit: 5,
        totalRecords: 10,
        maxVisiblePaginationButtons: 3,
        searchTerm: '',
        filter: {},
        sorting: {
            column: '',
            direction: null
        }
    })

    const [tableColumns, setTableColumns] = useState<ITableColumnItem[]>([
        {
            id: 1,
            name: 'User',
            column: 'user',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true
        },
        {
            id: 2,
            name: 'Email',
            column: 'email_id',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true
        },
        {
            id: 3,
            name: 'Devices',
            column: 'devices',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true
        },
        
        {
            id: 4,
            name: 'Total Backup',
            column: 'total_backup',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true
        },
        {
            id: 5,
            name: 'Added On',
            column: 'added_on',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true
        }
    ]);

    const actionButtonGenerator = (row: object) => {
        return <>
            <button aria-label='edit'
                onClick={()=>{

                }}
            ><em className='fa-regular fa-pen text-[#3A3541] opacity-80 mr-2'></em></button>
            <button aria-label='view'
                onClick={()=>{
                    
                }}
            ><em className='fa-regular fa-eye text-[#3A3541] opacity-80'></em></button>
        </>
    }

    const tableDataFormatter = (rowObj: any, format?: string | undefined) => {
        let returningVal = null;
        switch (format) {
            case 'User':
                returningVal = (<>
                    <span className="text-sm hover:text-orange-600 font-medium text-[#FF5e0e] opacity-80 cursor-pointer ">{rowObj}</span>
                </>);
                break;

            case 'Email':
                returningVal=(
                    <span className="text-sm hover:text-gray-900 font-medium text-[#3A3541] opacity-80 cursor-pointer  ">{rowObj}</span>
                    );
                break;
            
            case 'Devices':
                returningVal=(
                    <span className="text-sm hover:text-gray-900 font-medium text-[#3A3541] opacity-80 cursor-pointer  ">{rowObj}</span>
                );
                break;

            case 'Total Backup':
                returningVal=(
                    <span className="text-sm hover:text-gray-900 font-medium text-[#3A3541] opacity-80">{rowObj}</span>

                );
                break;

                case 'Added On':
                    returningVal=(
                        <span className="text-sm hover:text-gray-900 font-medium text-[#3A3541] opacity-80">{rowObj}</span>
                  );
                break;

            

             default:
                returningVal = rowObj;
                break;
        }
        return returningVal;
    }

    const handleSwitch = () => {}

    useEffect(()=>{
        if( tableController.data.length > 0 ) {
            return;
        }

        setTableController({
            ...tableController,
            data: [{
                id: 1,
                user: "abc xyz",
                email_id:"abc_xyz_123@zeeq.io",
                devices:"1 Device(s)",
                total_backup: "133.9 MB ",
                added_on: "11-11-2022 11:20 AM",
            },
            {
                id: 2,
                user: "abc xyz",
                email_id:"abc_xyz_123@zeeq.io",
                devices:"1 Device(s)",
                total_backup: "133.9 MB ",
                added_on: "11-11-2022 11:20 AM",
            },
            {
                id: 3,
                user: "abc xyz",
                email_id:"abc_xyz_123@zeeq.io",
                devices:"1 Device(s)",
                total_backup: "133.9 MB ",
                added_on: "11-11-2022 11:20 AM",
            },
            {
                id: 4,
                user: "abc xyz",
                email_id:"abc_xyz_123@zeeq.io",
                devices:"1 Device(s)",
                total_backup: "133.9 MB ",
                added_on: "11-11-2022 11:20 AM",
            },
            {
                id: 5,
                user: "abc xyz",
                email_id:"abc_xyz_123@zeeq.io",
                devices:"1 Device(s)",
                total_backup: "133.9 MB ",
                added_on: "11-11-2022 11:20 AM",
            },
            
            
            ]
        })

    },[tableController.data]);



    return (
        <>
        <div className={`p-6 grow flex flex-col`}>
            <h1 className="col-span-12 text-lg font-bold px-6 mb-2 ">Endpoint Users</h1>
                <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-bell-on', link: '', name: 'Building' },
                    { icon: '', link: '', name: 'Managed Services' },
                    { icon: '', link: '', name: 'Endpoints' },
                    { icon: '', link: '', name: 'Endpoint Users' },
                    // { icon: '', link: '', name: 'Test-user' }
                ]} />
            <div className={` grow flex flex-col w-full gap-6`}>
                    
                       

                    <Tile colSpan={12} rowSpan={15} className="" >
                                <div className="px-6 py-4 font-semibold text-gray-600">Incremental backups in last 24 hours</div>
                                <div className="flex flex-row items-center  align-middle mb-6 ">
                                <input type="text" className="px-4 py-2 bg-slate-100 rounded-md w-1/3 outline-none ml-4  align-middle items-center" placeholder="Search User"/>
                                <div className="grow"></div>
                                <Button variant="primary" size="sm" className="mr-8 px-6">Export Report</Button>
                                </div>

                                <Table
                                navRef={navRaf}
                                actionButtonGenerator={actionButtonGenerator}
                                columns={tableColumns}
                                setColumns={setTableColumns}
                                formatData={tableDataFormatter}
                                showActionCell={false}
                                showActionHeaderCell={false}
                                showColumnManagementMenu={false}
                                switchHandler={handleSwitch}
                                tableController={tableController}
                                tableId="UserTable"
                                />
                    </Tile>
                
            </div>
                    
                      
        </div>
        </>
    )
}

export default Users