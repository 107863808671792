import React from "react";
import { Breadcrumb } from "../../components/core";

const Settings = () => {
    return (
        <>
            <div className={`p-6 grow flex flex-col`}>    
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Settings</h1>
                <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-home', link: '', name: 'Home' },
                    { icon: '', link: '/settings', name: 'Settings' }
                ]} />
            </div>
        </>
    );
}

export default Settings;