/* eslint-disable react/jsx-pascal-case */
import React, { useContext } from "react"
import { ThemeContext } from "../contexts";
import Tile5x4_ChartText from "../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText";

const Strategy = ( ) =>{
    const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    return(
        <>
            <div className={`p-6 grow flex flex-col`}>
                <div className={`page grid grid-cols-12 gap-6 w-full min-h-max text-${theme.setThemeColor}-${theme.themeContrast}-component-text`}>

                <Tile5x4_ChartText 
                    sideBar={false}
                    clickNavigate={false}
                    navigationPath='/'
                    title={
                        < div className="flex items-center">
                            Strategy 
                            {/* <small className="text-green-600  p-1 px-2  border-green-200 text-xs "><span className=" text-green-600"></span><i className="fa-solid fa-check bg-green-100 text-green-600 border border-green-300 rounded-full w-4 h-4 ml-1 text-xs "/></small> */}
                        </div>}
                    description="Total no of strategy Active "
                    value=""
                    // textBackground="bg-green-100"
                    textValue={<em className="fa-light fa-file-certificate text-[56px]"></em>}
                    textColor="text-green-600"
                    // textArea=""
                    // inChartMain='20' inChartSub="License"
                    // alert={false}
                    // live={false}
                    designOption="chart"
                    chartType="donut" 
                    chartLabels={[]} 
                    chartDataset={[
                        {
                            label: '# of Votes',
                            data: [],
                            backgroundColor: [
                            ],
                            borderWidth: 5,
                            cutout: 43,
                            borderRadius: 20
                        },

                    ]}
                    chartOptions={{
                        responsive: true,
                        plugins: {
                            legend: { display: false },
                            title: { display: false },
                        },
                        scales: {
                            x: { display: false },
                            y: { display: false },
                        },
                    }}
                    inChartMain='' inChartSub=""
                    className='border'
                    tileMode='default'
                    alert={false}
                    live={false}
                >
                    <div className="text-7xl text-white bg-orange-500 w-28 h-28 flex items-center text-center justify-center rounded-md">12</div>
                </Tile5x4_ChartText>
                <Tile5x4_ChartText 
                    sideBar={false}
                    clickNavigate={false}
                    navigationPath='/'
                    title={
                        < div className="flex items-center">
                            Strategy 
                            {/* <small className="text-green-600  p-1 px-2  border-green-200 text-xs "><span className=" text-green-600"></span><i className="fa-solid fa-check bg-green-100 text-green-600 border border-green-300 rounded-full w-4 h-4 ml-1 text-xs "/></small> */}
                        </div>}
                    description="Total no of strategy Active "
                    value=""
                    // textBackground="bg-green-100"
                    textValue={<em className="fa-light fa-file-certificate text-[56px]"></em>}
                    textColor="text-green-600"
                    // textArea=""
                    // inChartMain='20' inChartSub="License"
                    // alert={false}
                    // live={false}
                    designOption="chart"
                    chartType="donut" 
                    chartLabels={[]} 
                    chartDataset={[
                        {
                            label: '# of Votes',
                            data: [],
                            backgroundColor: [
                            ],
                            borderWidth: 5,
                            cutout: 43,
                            borderRadius: 20
                        },

                    ]}
                    chartOptions={{
                        responsive: true,
                        plugins: {
                            legend: { display: false },
                            title: { display: false },
                        },
                        scales: {
                            x: { display: false },
                            y: { display: false },
                        },
                    }}
                    inChartMain='' inChartSub=""
                    className='invisible'
                    tileMode='default'
                    alert={false}
                    live={false}
                >
                </Tile5x4_ChartText>
                <Tile5x4_ChartText 
                    sideBar={false}
                    clickNavigate={false}
                    navigationPath='/'
                    title={
                        < div className="flex items-center">
                            Strategy 
                            {/* <small className="text-green-600  p-1 px-2  border-green-200 text-xs "><span className=" text-green-600"></span><i className="fa-solid fa-check bg-green-100 text-green-600 border border-green-300 rounded-full w-4 h-4 ml-1 text-xs "/></small> */}
                        </div>}
                    description="Total no of strategy Active "
                    value=""
                    // textBackground="bg-green-100"
                    textValue={<em className="fa-light fa-file-certificate text-[56px]"></em>}
                    textColor="text-green-600"
                    // textArea=""
                    // inChartMain='20' inChartSub="License"
                    // alert={false}
                    // live={false}
                    designOption="chart"
                    chartType="donut" 
                    chartLabels={[]} 
                    chartDataset={[
                        {
                            label: '# of Votes',
                            data: [],
                            backgroundColor: [
                            ],
                            borderWidth: 5,
                            cutout: 43,
                            borderRadius: 20
                        },

                    ]}
                    chartOptions={{
                        responsive: true,
                        plugins: {
                            legend: { display: false },
                            title: { display: false },
                        },
                        scales: {
                            x: { display: false },
                            y: { display: false },
                        },
                    }}
                    inChartMain='' inChartSub=""
                    className='border invisible'
                    tileMode='default'
                    alert={false}
                    live={false}
                >
                    <div className="text-7xl text-white bg-orange-500 w-28 h-28 flex items-center text-center justify-center rounded-md">12</div>
                </Tile5x4_ChartText>
                <div className="mt-5">
                    Table

                </div>
                
             </div>
             </div>       
        </>
    )
}

export default Strategy