import React, { useState, useEffect } from 'react';
import { getAPICall } from "../../services/APILayer/axiosMethodCalls";
import { Tile } from '../../components/core';
// import Placeholder from '../../components/core/Placeholder/Placeholder';

const DummyStatsCard = () => {

    const [showMessage, setShowMessage] = useState(false);

    const handleMouseEnter = () => {
      setShowMessage(true);
    };
  
    const handleMouseLeave = () => {
      setShowMessage(false);
    };

    return (
        <>
            <Tile rowSpan={13} colSpan={3} className='p-0 overflow-hidden relative'>
                <div className="flex flex-col h-full mb-1 p-0 text-[#3A3541]">
                    <div className="flex flex-row justify-between w-full">
                        <div title='Ticket Status' className="text-base md:text-lg pt-5 pl-5 lg:text-xl leading-8 font-medium whitespace-nowrap truncate">Endpoint Stats</div>
                    </div>
                    <div className="flex flex-col h-[360px]">
                        <div className="flex grow h-[155px]">
                            <div className="relative flex flex-col items-center align-middle justify-center w-1/2 hover:bg-[#ff5e0e] hover:bg-opacity-5 shadow-md">
                                <span className={`text-4xl lg:text-2xl xl:text-4xl flex items-end gap-1 font-semibold mb-2 text-[#FF5E0E]`}> 
                                    0 <span className="text-sm font-light">GB</span>
                                </span>
                                <span className="text-sm text-gray-600 px-6 text-center whitespace-nowrap">Protected Data</span>
                                <span className="absolute bottom-2 right-2 text-xs text-gray-500 uppercase">Backup</span>
                            </div>
                            <div className="relative flex flex-col items-center align-middle justify-center w-1/2 hover:bg-[#ff5e0e] hover:bg-opacity-5 shadow-md">
                                <span className="text-4xl lg:text-2xl xl:text-4xl flex items-end gap-1 font-semibold mb-2 text-[#FF5E0E]"> 
                                    0 <span className="text-sm font-light">GB</span>
                                </span>
                                {/* <span className="text-4xl font-semibold mb-2 text-[#FF5E0E]">{(statsData?.stats?.recent_backup_text || ' ').split(' ')[0]}<span className="text-sm font-light">{(statsData?.stats?.recent_backup_text || ' ').split(' ')[1]}</span></span> */}
                                <span className="text-sm text-gray-600 px-6 text-center whitespace-nowrap">Last 24 Hrs</span>
                                <span className="absolute bottom-2 right-2 text-xs text-gray-500 uppercase">Backup</span>
                            </div>
                        </div>
                        <div className="flex grow h-[155px]">
                            <div className="relative flex flex-col items-center align-middle justify-center w-1/2 hover:bg-[#ff5e0e] hover:bg-opacity-5 shadow-md">
                                <span className="text-4xl lg:text-2xl xl:text-4xl flex items-end gap-1 font-semibold mb-2 text-[#FF5E0E]"> 
                                    0 <span className="text-sm font-light">GB</span>
                                </span>
                                {/* <span className="text-4xl font-semibold mb-2 text-[#FF5E0E]">{(statsData?.stats?.restored_data_text || ' ').split(' ')[0]}<span className="text-sm font-light">{(statsData?.stats?.restored_data_text || ' ').split(' ')[1]}</span></span> */}
                                <span className="text-sm text-gray-600 px-6 text-center whitespace-nowrap">Data Restored</span>
                                <span className="absolute bottom-2 right-2 text-xs text-gray-500 uppercase">Restore</span>
                            </div>
                            <div className="relative flex flex-col items-center align-middle justify-center w-1/2 hover:bg-[#ff5e0e] hover:bg-opacity-5 shadow-md">
                                <span className="text-4xl lg:text-2xl xl:text-4xl flex items-end gap-1 font-semibold mb-2 text-[#FF5E0E]"> 
                                    0 <span className="text-sm font-light">GB</span>
                                </span>
                                <span className="text-sm text-gray-600 px-6 text-center whitespace-nowrap">Last 24 Hrs</span>
                                <span className="absolute bottom-2 right-2 text-xs text-gray-500 uppercase">Restore</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='absolute top-0 left-0 right-0 bottom-0'>
                    <div className='w-full h-full bg-white opacity-50'></div>
                    <div className='absolute w-full h-full top-1/3 m-auto text-center left-1/2 -ml-10 text-2xl text-[#ff5e0e] font-bold drop-shadow-lg -rotate-45'>NO ACCESS</div>
                </div> */}
                <div className='absolute top-0 left-0 right-0 bottom-0 z-10'>
                    <div className='w-full h-full bg-white opacity-50'></div>
                    <div className="absolute top-0 right-0 px-3 py-1 bg-[#ff5e0e] text-white rounded-b cursor-pointer"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>
                        {/* <span>Currently disabled </span> */}
                        <i className="fa-solid fa-circle-info "/>
                    </div>
                    <div>
                        {showMessage && (
                        <div className="absolute border font-medium min-w-max bottom-full top-8 min-h-max left-1/2 transform -translate-x-1/2 p-2 bg-white shadow-md rounded-md text-gray-700">
                            License is not procured for this service<br/>Please contact to our sales team   
                        </div>
                        )}
                    </div>                       
                </div>
            </Tile>
        </>
    )
}

export default DummyStatsCard;