import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../contexts";
// import Tile3x3_Text from "../../components/applicationComponents/Tiles/Tile3x3_Text/Tile3x3_Text";
// import Tile5x4_ChartText from "../../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText";
import { Breadcrumb, Tile } from "../../components/core";
// import { Table } from "../../components/core/Table";
// import { ITableColumnItem, ITableControllerProps } from "../../components/core/Table/Table";
// import Switch from "../../components/core/Switch/Switch";
import { getAPICall } from "../../services/APILayer/axiosMethodCalls";
import { toast } from "react-toastify";

const UserSettings = () => {

    // const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    const globalContext = useContext(GlobalContext);
    // const navRaf = useRef<HTMLDivElement>(null);
    const [myOrg, setMyOrg] = useState<any>({});
    const [me, setMe] = useState<any>({});
    const [tokenVisibility, setTokenVisibility] = useState<boolean>(false);

    const fetchMyOrgDetails = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/crm/companies/me`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const fetchMyDetails = async () => {
        try {
            const response = await getAPICall(`https://zeeq-app.au.auth0.com/userinfo`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if(Object.keys(myOrg).length > 0) {
            return
        }
        const getMyOrgDetails = () => {
            fetchMyOrgDetails().then(company=>{
                // console.log(company)
                setMyOrg(company)
            })
            .catch(err=>console.log(err));

            fetchMyDetails().then(response=>{
                // console.log(company)
                setMe(response)
            })
            .catch(err=>console.log(err));
        }
        getMyOrgDetails();
    },[myOrg]);

    console.log({globalContext})


    return (
        <>
            <div className={`p-6 grow flex flex-col`}>    
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2">My Profile</h1>
                <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-home', link: '', name: 'Home' },
                    { icon: '', link: '', name: 'Settings' },
                    { icon: '', link: '/settings/user', name: 'My Profile' }
                ]} />

                <div className="col-span-12 text-lg font-bold mb-6 space-y-6">
                    <div className={`page w-full min-h-max`}>
                        <div className="flex flex-row border-dashed border border-[#ff5e0e] rounded-md bg-slate-50 w-full items-center">
                            <div className="hidden md:block">
                            <i className="fa-solid fa-star flex items-center w-8 h-8 align-middle m-6 text-3xl text-[#ff5e0e]"></i>
                            </div>
                            <div className="flex flex-col space-y-1 p-2">
                                <span className="text-gray-700 font-semibold">Welcome to {myOrg?.company?.properties?.name || 'your organization'}, <span className="capitalize">{me?.name}</span></span>
                                <span className="flex text-sm text-gray-500">Get started with the option below or try quick workflows to get in action instantly</span>
                            </div>
                            <div className="flex grow"></div>
                            <i className="fa-solid fa-xmark text-xl text-[#ff5e0e] bg-orange-50 w-5 h-5 p-4 mr-2 md:mr-6 flex items-center hover:bg-orange-100 justify-center cursor-pointer" onClick={()=>{}}></i>
                        </div>
                    </div>
                    {/* <div className={`page w-full min-h-max`}>
                        <div className="flex flex-row border-dashed border border-red-600 rounded-md bg-slate-50 w-full items-center">
                            <i className="fa-solid fa-exclamation flex items-center w-9 h-9 align-middle m-6 text-2xl justify-center text-red-600 bg-red-100 rounded-full"></i>
                            <div className="flex flex-col space-y-1">
                                <span className="text-red-600 font-semibold">1 license[s] expired</span>
                                <span className="flex text-sm text-red-500">Get started with the option below or try quick workflows to get in action instantly</span>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="grow flex flex-col md:flex-row gap-6">
                    <div className="md:w-6/12 w-full">
                        <Tile className="h-[620px] overflow-hidden" colSpan={12} rowSpan={20}> 
                            {/* <div className="flex flex-col w-full">
                                <div className="inset flex align-middle justify-center items-center ">
                                    <div className="relative grow max-w-[256px] flex flex-col border rounded-md gap-4 justify-center align-middle items-center text-[#FF5E0E] bg-slate-100 h-64 m-4">
                                        <em className="fa-thin fa-buildings text-8xl"></em> */}
                                        {/* <div className="text-xl">Atempo SAS</div> */}
                                        {/* <div className="flex items-center justify-center align-middle rounded-md bg-green-200 text-green-600 px-6 py-1">Basic</div> */}
                                        {/* <em className="fa-solid fa-circle-check text-2xl absolute inline ml-2 text-green-500 top-4 right-4"></em> */}
                                    {/* </div>
                                </div>
                                <div className="details border-t">
                                    <div className="flex items-center px-4 border-b py-2">
                                        <em className="fa-regular fa-buildings text-2xl text-[#FF5E0E]"></em>
                                        <div className="flex flex-col ml-4">
                                            <div className="text-gray-500">Organization</div>
                                            <div className="">Atempo SAS <em className="fa-solid fa-circle-check text-2xl inline ml-2 text-green-500"></em></div>
                                        </div>
                                    </div>
                                    <div className="flex items-center px-4 border-b py-2">
                                        <em className="fa-regular fa-handshake text-2xl text-[#FF5E0E]"></em>
                                        <div className="flex flex-col ml-4">
                                            <div className="text-gray-500">Membership Type</div>
                                            <div className="">Basic</div>
                                        </div>
                                    </div>
                                    <div className="flex items-center px-4 border-b py-2">
                                        <em className="fa-regular fa-user text-2xl text-[#FF5E0E]"></em>
                                        <div className="flex flex-col ml-4">
                                            <div className="text-gray-500">User Type</div>
                                            <div className="">Customer</div>
                                        </div>
                                    </div>
                                    <div className="flex items-center px-4 border-b py-2">
                                        <em className="fa-regular fa-location text-2xl text-[#FF5E0E]"></em>
                                        <div className="flex flex-col ml-4">
                                            <div className="text-gray-500">Location</div>
                                            <div className="">Noida, UP</div>
                                        </div>
                                    </div>
                                    <div className="flex items-center px-4 border-b py-2">
                                        <em className="fa-regular fa-envelope text-2xl text-[#FF5E0E]"></em>
                                        <div className="flex flex-col ml-4">
                                            <div className="text-gray-500">Email</div>
                                            <div className="">abc+xyz@zeeq.io</div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="flex flex-col justify-center items-center align-middle text-center p-8   w-full">
                                <div className="flex flex-col md:flex-row justify-center">
                                    <img src={me?.picture || globalContext?.userData?.picture} alt="User Profile" className="grow md:w-64 h-64 p-2 text-8xl flex justify-center items-center" />
                                    {/* {!globalContext?.userData?.picture && <i className="fa-thin fa-user grow md:w-64 h-64 p-2 text-8xl border border-slate-200  text-[#FF5E0E] flex justify-center items-center bg-slate-100 rounded-md"/>} */}
                                </div>
                                <div className="font-semibold flex flex-col items-center my-8 align-middle justify-center text-center w-full text-gray-700">
                                    <div className="flex flex-row items-left text-xl mb-2 capitalize items-center">
                                        {me?.name || globalContext?.userData?.nickname}
                                        {globalContext?.userData?.email_verified && <i className="fa-solid fa-check bg-green-400 text-white  text-sm font-normal w-6 h-6 flex justify-center items-center text-center ml-2 rounded-full"/>}
                                    </div>
                                    {/* <div className="bg-orange-400 text-orange-600 bg-opacity-20 text-sm font-normal  flex justify-center items-center text-center px-8 py-1 mt-2 rounded-sm">
                                        ~ Admin ~
                                    </div> */}
                                    <div className="flex flex-col text-gray-700 items-left justify-start text-left gap-3 mt-6 py-6  border-t border-[#ff5e0e] w-full text-[18px] font-medium">
                                        {/* <div><i className="inline fa-solid fa-dice-d10 text-[#ff5e0e]"></i><span className="inline text-gray-600 ml-2">Administration</span></div> */}
                                        {/* <div><i className="inline fa-solid fa-dice-d10 text-[#ff5e0e]"></i><span className="inline text-gray-600 ml-2"></span></div> */}
                                        {/* <div><i className="inline fa-solid fa-building text-[#ff5e0e]"></i><span className="inline text-gray-600 ml-2">ZeeQ</span></div> */}
                                        <div><i className="inline fa-solid fa-building text-[#ff5e0e]"></i><span className="inline text-gray-600 ml-2"></span>{myOrg?.company?.properties?.name || ''}</div>
                                        {/* <div><i className="inline fa-solid fa-location  text-[#ff5e0e]"></i><span className="inline text-gray-600 ml-3">Noida, UP</span></div> */}
                                        {/* <div><i className="inline fa-solid fa-location  text-[#ff5e0e]"></i><span className="inline text-gray-600 ml-3"></span>{myOrg?.company?.properties?.city} {myOrg?.company?.properties?.stats && `, ${myOrg?.company?.properties?.stats}`} {myOrg?.company?.properties?.country && `, ${myOrg?.company?.properties?.country}`} {myOrg?.company?.properties?.zip && `, ${myOrg?.company?.properties?.zip}`}</div> */}
                                        <div><i className="inline fa-regular fa-envelope  text-[#ff5e0e]"></i><span className="inline text-gray-600 ml-2">{globalContext?.userData?.email}</span></div>
                                    </div>
                                </div>
                                
                            </div>
                        </Tile>
                    </div>
                    <div className="page grid grid-cols-6 gap-6 w-full min-h-max mb-6">
                        <Tile colSpan={8} rowSpan={25} className="">
                            <div className="border-b pt-6 px-6 text-lg font-semibold pb-4">Profile Information</div>
                            <div className={`page mx-6 mt-4 min-h-max `}>
                                <div className="flex flex-row border-dashed border border-red-600 rounded-md bg-slate-50 w-full items-center">
                                    <div className="hidden lg:block">
                                        <i className="fa-solid fa-exclamation flex items-center w-9 h-9 align-middle m-4 text-2xl justify-center text-[#ff5e0e] bg-orange-100 rounded-full"></i>
                                    </div>
                                    <div className="flex flex-row text-sm text-gray-600 items-center p-4 lg:p-0 ">
                                        <div>customer details will receive a privacy audit. For more info, please read our</div>
                                        <div className="text-[#ff5e0e] hover:border-b hover:border-[#ff5e0e] cursor-pointer whitespace-nowrap text-sm ml-1">Privacy Policy</div>
                                    </div>
                                </div>
                            </div>
                            <div className={`text-base font-medium text-gray-700 px-6 pb-6`}>
                                {/* <div className="text-gray-500 flex flex-col mt-6 text-sm"> */}
                                    {/* <label className="mb-2">About me</label> */}
                                    {/* <textarea readOnly={true} defaultValue={''} className="p-2 px-4 bg-slate-100 outline-none rounded-md"></textarea> */}
                                    {/* <input type="text" className="" value=""/> */}
                                {/* </div> */}
                                <div className="text-gray-500 flex flex-col mt-6 text-sm">
                                    <label className="mb-2">Name</label>
                                    <input type="text" readOnly={true} defaultValue={me?.name || ''} className="p-2 px-4 bg-slate-100 outline-none rounded-md capitalize"/>
                                </div>
                                <div className="text-gray-500 flex flex-col mt-6 text-sm">
                                    <label className="mb-2">
                                        Email
                                        {/* <i className="fa-solid fa-circle-info ml-2 cursor-pointer text-sm"/> */}
                                    </label>
                                    <input type="text" readOnly={true} defaultValue={globalContext?.userData?.email || ''} className="p-2 px-4 bg-slate-100 outline-none rounded-md"/>
                                </div>
                                <div className="text-gray-500 flex flex-col mt-6 text-sm">
                                    <label className="mb-2">
                                        Roles
                                        {/* <i className="fa-solid fa-circle-info ml-2 cursor-pointer text-sm"/> */}
                                    </label>
                                    <input type="text" readOnly={true} defaultValue={`Coming Soon...`} className="p-2 px-4 bg-slate-100 outline-none rounded-md"/>
                                </div>
                                <div className="text-gray-500 flex flex-col mt-6 text-sm">
                                    <label className="mb-2">
                                        Token
                                        <button
                                            aria-label="Show hide Token"
                                            onClick={()=>{
                                                setTokenVisibility((prevState)=>{
                                                    return !prevState
                                                })
                                            }}
                                        >
                                            <em className={`fa-solid fa-eye${ tokenVisibility ? '-slash' : ''} ml-4 text-[#ff5e0e]`}></em>
                                        </button>
                                    </label>
                                    <div className="w-full relative">
                                        <button 
                                            aria-label="Copy Token"
                                            className="absolute right-2 top-2 md:right-4 md:top-4 flex items-center justify-center align-middle rounded-sm md:px-3 md:py-2 px-2 py-1 bg-gray-500 bg-opacity-50 hover:bg-opacity-100"
                                            onClick={()=>{
                                                if( globalContext?.userData?.token ) {
                                                    navigator.clipboard.writeText(globalContext?.userData?.token);
                                                    toast.success('Copied to clipboard')
                                                }
                                            }}
                                        >
                                            <em className="fa-solid fa-copy text-white text-sm"></em>
                                        </button>
                                        <textarea readOnly={true} defaultValue={tokenVisibility ? globalContext?.userData?.token || '' : ''} rows={10} className="p-2 px-4 w-full bg-slate-100 outline-none rounded-md"></textarea>
                                    </div>
                                </div>

                                {/* <div className="text-gray-500 flex flex-col mt-6 text-sm">
                                        <label className="mb-2">Address Line 1</label>
                                        <input type="text" readOnly={true} defaultValue={''} className="p-2 px-4 bg-slate-100 outline-none rounded-md"/>
                                </div>
                                <div className="text-gray-500 flex flex-col mt-6 text-sm">
                                        <label className="mb-2">Address Line 2</label>
                                        <input type="text" readOnly={true} defaultValue={''} className="p-2 px-4 bg-slate-100 outline-none rounded-md"/>
                                </div>
                                <div className="text-gray-500 flex flex-row mt-6 w-full gap-6">
                                    <div className="text-gray-500 flex flex-col text-sm w-full">
                                        <label className="mb-2">Country</label>
                                        <input type="text" readOnly={true} defaultValue={''} className="p-2 px-4 bg-slate-100 outline-none rounded-md" />
                                    </div>
                                    <div className="text-gray-500 flex flex-col text-sm w-full">
                                        <label className="mb-2">City</label>
                                        <input type="text" readOnly={true} defaultValue={''} className="p-2 px-4 bg-slate-100 outline-none rounded-md" />
                                    </div>
                                </div> */}
                                {/* <div className="text-gray-500 flex flex-row mt-6 w-full gap-6">
                                    <div className="text-gray-500 flex flex-col text-sm w-full">
                                        <label className="mb-2">State / Province</label>
                                        <input type="text" readOnly={true} defaultValue={''} className="p-2 px-4 bg-slate-100 outline-none rounded-md" />
                                    </div>
                                    <div className="text-gray-500 flex flex-col text-sm w-full">
                                        <label className="mb-2">ZIP Code</label>
                                        <input type="text" readOnly={true} defaultValue={''} className="p-2 px-4 bg-slate-100 outline-none rounded-md" />
                                    </div>
                                </div> */}
                                {/* <div className="w-full flex flex-row justify-start  py-6"> */}
                                    {/* <Button className="bg-gray-500 border-gray-500 hover:bg-gray-600 hover:border-gray-600" variant="primary" size="md">Discard</Button> */}
                                    {/* <Button size="md" className="px-8" disabled={true}>Save Changes</Button>
                                </div> */}
                            </div>
                        </Tile>
                        {/* <Tile colSpan={8} rowSpan={40} className="mb-6">
                            <div className="border-b pt-6 px-6 text-lg font-semibold pb-4">Change your password</div>
                            <div className={`text-base font-medium text-gray-700 px-6 pb-6`}>
                                <div className={`pb-6}`}>
                                    <div className="text-gray-500 flex flex-col mt-6 text-sm">
                                        <label className="mb-2">Current password</label>
                                        <input type="text" readOnly={true} defaultValue={''} className="p-2 px-4 bg-slate-100 outline-none rounded-md"/>
                                    </div>
                                    <div className="text-gray-500 flex flex-col mt-6 text-sm">
                                        <label className="mb-2">New password</label>
                                        <input type="text" readOnly={true} defaultValue={''} className="p-2 px-4 bg-slate-100 outline-none rounded-md"/>
                                    </div>
                                    <div className="text-gray-500 flex flex-col mt-6 text-sm">
                                        <label className="mb-2">confirm new password</label>
                                        <input type="text" readOnly={true} defaultValue={''} className="p-2 px-4 bg-slate-100 outline-none rounded-md"/>
                                    </div>
                                    <div className="w-full flex flex-row justify-start  py-6"> */}
                                        {/* <Button className="bg-gray-500 border-gray-500 hover:bg-gray-600 hover:border-gray-600" variant="primary" size="md">Discard</Button> */}
                                        {/* <Button size="md" className="px-8" disabled={true}>Reset Password</Button>
                                    </div>
                                </div>
                            </div>
                        </Tile> */}
                        
                    </div>
                </div>
            </div>            
        </>
    );
}


export default UserSettings;