/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, ReactNode, ComponentProps, useState, useContext, useEffect, useCallback } from "react";
import { SideBar, TopBar, Footer } from "../components/layoutComponents";
import { CustomizationProvider, GlobalContext, SideDrawerProvider } from "../contexts";
import { Outlet } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAPICall } from "../services/APILayer/axiosMethodCalls";
import { LevelOneDrawer, LevelTwoDrawer, SideDrawer } from "../components/core";
import { LiveContext } from "../contexts/LiveContext/LiveContext";
import HubSpotChatBot from "../HubSpot/ChatBot/HubSpotChatBot";
import ModalWithSearch from "../components/layoutComponents/GlobalSearch/GlobalSearch";
import GlobalSearch from "../components/layoutComponents/GlobalSearch/GlobalSearch";


interface Props extends ComponentProps<"div">  {
    children?: ReactNode | ReactNode[]
}

/**
 * Dashboard Layout
 * <!-- bg-orange-light-tile-background bg-orange-dark-tile-background -->
 * <!-- bg-blue-light-tile-background bg-blue-dark-tile-background -->
 * @param param0 
 * @returns 
 */
const DashboardLayout:FC<Props> = ({className, children, ...rest}) => {

    const navigate = useNavigate();
    const [navCollapsed, setNavCollapsed] = useState<boolean>(false);
    const [myOrg, setMyOrg] = useState<boolean>(false);
    const chatBotPortalId = 25361155;
    const [chatBotToken, setChatBotToken] = useState<any>({});

    const liveContext = useContext(LiveContext);
    const globalContext = useContext(GlobalContext);
    // const sideDrawerContext = useContext(SideDrawerContext);

    const navSize = navCollapsed ? 'w-[65px]' : 'w-[290px]';

    const fetchMyOrgDetails = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/crm/companies/me`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const fetchChatBotToken = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/crm/chat_token`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if(Object.keys(myOrg).length > 0) {
            return
        }
        const getMyOrgDetails = () => {
            fetchMyOrgDetails().then(company=>{
                // console.log({company})
                setMyOrg(company)
                globalContext.setUser((prevState: any)=>{
                    return {
                        ...prevState,
                        org: company
                    };
                })
            })
            .catch(err=>console.log(err));
        }
        const getChatBotToken = async () => {

            fetchChatBotToken().then((response)=>{
                if( response ) {
                    setChatBotToken(response)
                }
            }).catch(err=>console.log(err))
        }
        getMyOrgDetails();
        getChatBotToken();
    },[myOrg]);

    /* if email is not verified, redirect user to /auth/verify/email page */
    if( Object.keys( globalContext.userData ).length > 1 ) {
        if( !globalContext.userData.email_verified ) {
            navigate('/auth/verify/email')
        }
    }

    /* if organization is not connected, redirect user to /auth/verify/organization page */
    if( Object.keys( globalContext.userData ).length > 1 ) {
        if( globalContext?.userData['https://zeeq.app/meta']?.hubspot_company_id === undefined ) {
            navigate('/auth/verify/organization')
        }
    }

    useEffect(()=>{
        if( !liveContext?.zeeqNoticeGlobal ) {
            return
        }
        
        if( liveContext?.zeeqNoticeGlobal?.type === 'success' ) {
            toast.success(liveContext?.zeeqNoticeGlobal?.message || '',{
                position: toast.POSITION.TOP_RIGHT
            })
        } else if( liveContext?.zeeqNoticeGlobal?.type === 'warning' ) {
            toast.warning(liveContext?.zeeqNoticeGlobal?.message || '',{
                position: toast.POSITION.TOP_RIGHT
            })
        } else if( liveContext?.zeeqNoticeGlobal?.type === 'info' ) {
            toast.info(liveContext?.zeeqNoticeGlobal?.message || '',{
                position: toast.POSITION.TOP_RIGHT
            })
        } else if( liveContext?.zeeqNoticeGlobal?.type === 'error' ) {
            toast.error(liveContext?.zeeqNoticeGlobal?.message || '',{
                position: toast.POSITION.TOP_RIGHT
            })
        }


    },[liveContext])

    HubSpotChatBot(
        chatBotPortalId,
        chatBotToken?.token || '',
        chatBotToken?.email || ''
    )
   
    const [globalSearchVisibility, setGlobalSearchVisibility] = useState<boolean>(false);

    const handleKeypress = (e:any) => {
        if( e.ctrlKey && e.key === 'z' ) {
            setGlobalSearchVisibility(true)
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", handleKeypress);
        return () => document.removeEventListener("keydown", handleKeypress);
    }, []);

    return (
        <>
            <div className="flex">
                <aside className={`${navSize} transition-[width] duration-200 ease-in-out z-[999999]`}>
                    <CustomizationProvider>
                        <SideBar navCollapsed={navCollapsed} setNavCollapsed={setNavCollapsed} />
                    </CustomizationProvider>
                </aside>
                <SideDrawerProvider>
                    <div className="h-screen relative grow w-[65px] flex flex-col">
                        <header className="h-16 w-full z-[899999]">
                            <TopBar globalSearchVisibility={globalSearchVisibility} setGlobalSearchVisibility={setGlobalSearchVisibility} />
                        </header>
                        
                        <main className="w-full flex flex-col grow overflow-y-scroll customScroll">
                            { liveContext?.isConnected === false && <div className={`page w-full min-h-max px-6 pt-6`}>
                                <div className="flex flex-row border-dashed border border-[#ff5e0e] rounded-md bg-slate-50 w-full items-center">
                                    <div className="hidden md:block">
                                        <i className="fa-solid fa-wifi-slash flex items-center w-8 h-8 align-middle m-6 text-3xl text-[#ff5e0e]"></i>
                                    </div>
                                    <div className="flex flex-col space-y-1 p-2">
                                        <span className="text-gray-700 font-semibold">You are offline</span>
                                        <span className="flex text-sm text-gray-500">Please check your Internet Connection</span>
                                    </div>
                                    <div className="flex grow"></div>
                                    <i className="fa-solid fa-xmark text-xl text-[#ff5e0e] bg-orange-50 w-5 h-5 p-4 mr-2 md:mr-6 flex items-center hover:bg-orange-100 justify-center cursor-pointer" onClick={()=>{}}></i>
                                </div> 
                            </div>}
                            <Outlet />
                            <SideDrawer>
                                <LevelOneDrawer />
                                <LevelTwoDrawer />
                            </SideDrawer>
                        </main>
                        <Footer />
                    </div>
                </SideDrawerProvider>
            </div>
            <ToastContainer className='z-[999999]' />
            {globalSearchVisibility && ( <GlobalSearch globalSearchVisibility={globalSearchVisibility} setGlobalSearchVisibility={setGlobalSearchVisibility}></GlobalSearch> )}
        </>
    );
}

export default DashboardLayout;
