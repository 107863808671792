/* eslint-disable react/jsx-pascal-case */
import React, { FC } from "react";
import Tile5x4_ChartText from "../../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText";

interface IActiveMigrationCard {
    
}

const ActiveMigrationCard:FC<IActiveMigrationCard> = () => {

    return (
        <>
            <Tile5x4_ChartText 
                sideBar={false}
                clickNavigate={false}
                navigationPath='/'
                title={
                    < div className="flex items-start">
                        Active Migration
                        <div className="flex flex-col grow"></div>
                    </div>}
                description=""
                value={<i className="fa-duotone fa-arrow-right-arrow-left text-5xl text-[#FF5E0E]"></i>}
                // textBackground="bg-green-100"
                textValue={<em className="fa-light fa-file-certificate text-[56px]"></em>}
                textColor="text-green-600"
                // textArea=""
                // inChartMain='20' inChartSub="License"
                // alert={false}
                // live={false}
                designOption="chart"
                chartType="donut" 
                chartLabels={[]} 
                chartDataset={[
                    {
                        label: '# of Votes',
                        data: [],
                        backgroundColor: [
                        ],
                        borderWidth: 5,
                        cutout: 43,
                        borderRadius: 20
                    },

                ]}
                chartOptions={{
                    responsive: true,
                    plugins: {
                        legend: { display: false },
                        title: { display: false },
                    },
                    scales: {
                        x: { display: false },
                        y: { display: false },
                    },
                }}
                inChartMain='' inChartSub=""
                className='border relative'
                tileMode='default'
                alert={false}
                live={false}
            >
                <div className="text-7xl text-[#FF5E0E] flex items-center text-right justify-end rounded-md absolute whitespace-nowrap right-4">0</div>
            </Tile5x4_ChartText>
        </>
    )
}

export default ActiveMigrationCard;