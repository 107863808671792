import React, { useContext, useEffect, useState } from 'react';
import { SideDrawerContext } from '../../../../contexts';
import { getAPICall } from '../../../../services/APILayer/axiosMethodCalls';
import { Button, Modal, ModalBody, ModalHeader } from '../../../core';
import AddDevice from './AddDevice';
import OutsideClickHandler from 'react-outside-click-handler'

const QuickActionNav = () => {
    const sideDrawerContext = useContext(SideDrawerContext);
    
    const [quickActionNavState, setQuickActionNavState] = useState<boolean>(false);

    const [modalVisibility, setModalVisibility] = useState<boolean>(false)

    const [apiResponse, setApiResponse] = useState<any|null>(null)

    const getWebRestore = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints/webrestore`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const execWebRestore = async () => {
        await getWebRestore()
            .then((data)=>{
                console.log({data})
                setApiResponse(data)
                
            })
            .catch((error)=>{ console.log(error) })
            .finally()
    }

    useEffect(()=>{
        if(modalVisibility === false) {
            setApiResponse(null)
        }
    },[modalVisibility])

    useEffect(()=>{
        if(!apiResponse){
            return;
        }
        console.log({apiResponse})



    },[apiResponse])

    return (
        <>
            <OutsideClickHandler
                onOutsideClick={()=>{
                    setQuickActionNavState(false)
                }}
            >
                <div className='relative flex tracking-wider ml-4'>
                    <Button 
                        className={`border border-slate-100 shadow-md rounded-md font-semibold`}
                        solid={false}
                        onClick={()=>{
                            setQuickActionNavState((prevState)=>{ return !prevState })
                        }}
                    >
                        Quick Actions <i className="fa-regular fa-angle-down ml-6"/>
                    </Button>
                    {quickActionNavState &&
                        <div className="absolute left-0 top-full w-[200px] flex flex-col bg-white rounded-lg shadow-md">
                            <span className='relative group'>
                                <div className="flex border-b border-slate-100 px-3 py-2 hover:bg-slate-100 whitespace-nowrap cursor-pointer capitalize tracking-wider group items-center">
                                    <i className="fa-regular text-orange-600 fa-plus w-8"/>
                                    <span>Add New</span>
                                    <div className="grow"></div>
                                    <i className="fa-regular text-orange-600 fa-angle-right"/>
                                </div>
                                <div className="absolute left-full top-0 hidden group-hover:flex border w-[220px] flex-col bg-white rounded-lg shadow-md overflow-hidden">
                                    <div 
                                        className="border-b border-slate-100 flex gap-3 px-3 py-2 hover:bg-slate-100 whitespace-nowrap cursor-pointer capitalize relative tracking-wider group items-center"
                                        onClick={()=>{
                                            console.log("clicked")
                                            sideDrawerContext.setFirstDrawerChild(<AddDevice />)
                                            sideDrawerContext.setLevelOneDrawerVisibility(true)
                                            setQuickActionNavState(false)
                                        }}
                                    >
                                        <i className="fa-regular text-orange-600 fa-laptop w-6"/>
                                        <span>Endpoint device</span>
                                    </div>
                                    {/* <div 
                                        className="border-b border-slate-100 flex gap-3 px-3 py-2 hover:bg-slate-100 whitespace-nowrap cursor-pointer items-center"
                                        onClick={()=>{setQuickActionNavState(false)}}
                                    >
                                        <i className="fa-regular text-orange-600 fa-server w-6 text-center"/>
                                        <span>MS-365 Application</span>
                                    </div> */}
                                </div>
                            </span>
                            <div
                                className="border-b border-slate-100 flex px-3 py-2 hover:bg-slate-100 whitespace-nowrap cursor-pointer items-center"
                                onClick={()=>{
                                    execWebRestore()
                                    setModalVisibility(true)
                                    setQuickActionNavState(false)
                                }}
                            >
                                <i className="fa-regular text-orange-600 fa-cloud-arrow-down w-8"/>
                                <span>Web Restore</span>
                            </div>
                        </div>
                    }
                </div>

                <Modal modalVisible={modalVisibility} setModalVisibility={setModalVisibility} className="h-1/3 w-1/2">
                    <ModalHeader setModalVisibility={setModalVisibility}>Secure Web Restore</ModalHeader>
                    <ModalBody>
                        <div className='flex flex-col justify-start align-middle items-center mt-10'>
                            <span className='text-xl text-gray-700'>Web Restore OTP</span>
                            <span className='w-full text-5xl text-center text-[#ff5e0e] pt-4 pb-10'>{apiResponse?.web_restore?.web_restore_otp || ''}&nbsp;</span>
                            <Button 
                                size='md'
                                onClick={()=>{
                                    navigator.clipboard.writeText(apiResponse?.web_restore?.web_restore_otp || '')

                                    setTimeout(()=>{    
                                        let tab = window.open(apiResponse.web_restore.lina_console.profile_url, "_blank");
                                        console.log({tab})
                                        setTimeout(() => { if(tab) tab.close() }, 500);
                                        window.open(apiResponse.web_restore.lina_console.restore_url, "_blank");
                                    },50)

                                }}
                            >Click to copy and Proceed</Button>
                        </div>
                    </ModalBody>
                </Modal>
            </OutsideClickHandler>
        </>
    )
}

export default QuickActionNav;