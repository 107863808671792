import React, { FC, ReactNode, ComponentProps, Dispatch, SetStateAction } from "react";


interface Props extends ComponentProps<"div">  {
    /**
     * Child node(s) of button
     */
    children?: ReactNode | ReactNode[] | string;
    /**
     * 
     */
    className?: string
    setModalVisibility?: Dispatch<SetStateAction<boolean>>;
}

/**
 * Button Component
 * @param param0 
 * @returns 
 */
const ModalFooter:FC<Props> = ({
    setModalVisibility=()=>{},
    className='',
    children=<></>,
    ...rest
}: Props) => {
    return (
        <>
            <div className="modal-footer flex px-4 py-2 border-t-2">
                <div className="spacing grow"></div>
                <div className={`modal-actions flex gap-4 ${className}`}>
                    {children}
                </div>
            </div>

        </>
    );
}

export default ModalFooter;
