import React, { FC, ReactNode, ComponentProps } from "react";
// import { ThemeContext } from "../../../../contexts";
import { Tile } from "../../../core";

interface ISimpleTileProp extends ComponentProps<"div">  {
    colSpan?: number,
    rowSpan?: number,
    data: any,
    /**
     * Child node(s) of button
     */
    children?: ReactNode | ReactNode[] | any;
}

/**
 * Button Component
 * @param param0 
 * @returns 
 */
const SimpleTile:FC<ISimpleTileProp> = ({
    colSpan=4,
    rowSpan=1,
    data,
    children=<></>,
    ...rest
}: ISimpleTileProp) => {

    // const theme = useContext(ThemeContext)  || {themeColor: 'primary', themeContrast: 'light'};

    return (
        <>
            <Tile colSpan={colSpan} rowSpan={rowSpan}>
                {data.title}
            </Tile>
        </>
    );
}

export default SimpleTile;
