import React, { FC, useState } from "react";
import { Tile } from "../../components/core";

interface IDummyDeviceStatusCard {
    title: string
}

const DummyDeviceStatusCard: FC<IDummyDeviceStatusCard> = ({title}) => {

    const [showMessage, setShowMessage] = useState(false);

    const handleMouseEnter = () => {
      setShowMessage(true);
    };
  
    const handleMouseLeave = () => {
      setShowMessage(false);
    };
    return (
        <Tile rowSpan={13} colSpan={3} className="relative overflow-hidden" >
            <div className="flex flex-col h-full mb-1 p-5 text-[#3A3541] z-0">
                <div className="flex flex-row justify-between w-full">
                    <div title='Ticket Status' className={`text-base md:text-lg lg:text-xl leading-8 font-medium whitespace-nowrap truncate`}>Endpoint Device Status</div>
                </div>
                <div className="regular flex-col gap-4 grow my-4 mt-4 mb-2 max-h-80 md:max-h-80 lg:max-h-80  items-center justify-center flex z-10 relative">
                    <div className="relative flex h-full aspect-square items-center justify-center align-middle">
                        <div className="h-[90%] lg:h-[70%] xl:h-[90%] aspect-square rounded-full bg-[#ff5e0e] opacity-90"></div>
                        <div className="absolute h-[58%] aspect-square rounded-full border bg-white"></div>
                    </div>
                    <div className="absolute flex flex-col top-[120px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                        <span className="block h-[30px] w-[60px] xl:w-[60px] mb-1 text-2xl font-semibold">0</span>
                        <span className="block h-[10px] w-[60px] xl:w-[60px] text-sm font-normal">Endpoint</span>
                    </div>
                    <div className="legends flex flex-row justify-center space-x-4 flex-wrap lg:-mt-4 2xl:mt-0">
                        <div className="flex flex-row items-center text-xs whitespace-nowrap pb-1">
                            <div className="h-[14px] w-[25px] rounded-sm bg-[#ff5e0e] opacity-90"></div>
                            <div className="ml-1">Endpoints</div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='absolute top-0 left-0 right-0 bottom-0 z-10'>
                <div className='w-full h-full bg-white opacity-50'></div>
                <div className='absolute w-full h-full top-1/3 m-auto text-center left-1/2 -ml-10 text-2xl text-[#ff5e0e] font-bold drop-shadow-lg -rotate-45'>NO ACCESS</div>
            </div> */}
            <div className='absolute top-0 left-0 right-0 bottom-0 z-10'>
                <div className='w-full h-full bg-white opacity-50'></div>
                <div className="absolute top-0 right-0 px-3 py-1 bg-[#ff5e0e] text-white rounded-b cursor-pointer"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                    {/* <span>Currently disabled </span> */}
                    <i className="fa-solid fa-circle-info "/>
                </div>
                <div>
                    {showMessage && (
                    <div className="absolute border font-medium min-w-max bottom-full top-8 min-h-max left-1/2 transform -translate-x-1/2 p-2 bg-white shadow-md rounded-md text-gray-700">
                            License is not procured for this service<br/>Please contact to our sales team
                    </div>
                    )}
                </div>                       
            </div>
        </Tile>
    )
}


export default DummyDeviceStatusCard;