const SortWithSerial = ( selection: string, order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    // 'LargeFiles'|'VolumeByExtension'|'FileExtensions'|'FileType'

    return dataArray.sort((a:any, b:any) => {
        let symbolA = Number(a?.serial?.text);
        let symbolB = Number(b?.serial?.text);

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}

const SortWithFile = ( selection: string, order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    // 'LargeFiles'|'VolumeByExtension'|'FileExtensions'|'FileType'

    return dataArray.sort((a:any, b:any) => {
        let symbolA:number = 0
        let symbolB:number = 0 

        if( selection === 'VolumeByExtension' ) {
            
        } else if( selection === 'LargeFiles' ) {
            symbolA = Number(a?.file?.text);
            symbolB = Number(b?.file?.text);
        } else if( selection === 'FileExtensions' ) {
            
        } else if( selection === 'FileType' ) {
            
        }

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}

const SortWithPath = ( selection: string, order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    // 'LargeFiles'|'VolumeByExtension'|'FileExtensions'|'FileType'

    return dataArray.sort((a:any, b:any) => {
        let symbolA:number = 0
        let symbolB:number = 0 

        if( selection === 'VolumeByExtension' ) {
            
        } else if( selection === 'LargeFiles' ) {
            symbolA = Number(a?.path?.text);
            symbolB = Number(b?.path?.text);
        } else if( selection === 'FileExtensions' ) {
            
        } else if( selection === 'FileType' ) {
            
        }

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}

const SortWithExtension = ( selection: string, order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    // 'LargeFiles'|'VolumeByExtension'|'FileExtensions'|'FileType'

    return dataArray.sort((a:any, b:any) => {
        let symbolA:string = ''
        let symbolB:string = '' 

        if( selection === 'VolumeByExtension' || selection === 'LargeFiles' || selection === 'FileExtensions' ) {
            symbolA = a?.extension?.text;
            symbolB = b?.extension?.text;
        } else if( selection === 'FileType' ) {
            
        }

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA.localeCompare(symbolB);
        } else if ( order === 'DESC' ) {
            return symbolB.localeCompare(symbolA);
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}

const SortWithSize = ( selection: string, order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    // 'LargeFiles'|'VolumeByExtension'|'FileExtensions'|'FileType'

    return dataArray.sort((a:any, b:any) => {
        let symbolA:number = 0
        let symbolB:number = 0

        if( selection === 'VolumeByExtension' ) {
            symbolA = Number(a?.size?.text?.value);
            symbolB = Number(b?.size?.text?.value);
        } else if( selection === 'LargeFiles' ) {
            symbolA = Number(a?.size?.text);
            symbolB = Number(b?.size?.text);
        } else if( selection === 'FileExtensions' ) {
            
        } else if( selection === 'FileType' ) {
            
        }

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}

const SortWithPercentage = ( selection: string, order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    // 'LargeFiles'|'VolumeByExtension'|'FileExtensions'|'FileType'

    return dataArray.sort((a:any, b:any) => {
        let symbolA:number = 0
        let symbolB:number = 0 

        if( selection === 'VolumeByExtension' || selection === 'LargeFiles' ) {
            symbolA = Number(a?.percent?.text);
            symbolB = Number(b?.percent?.text);
        } else if( selection === 'FileExtensions' ) {
            
        } else if( selection === 'FileType' ) {
            
        }

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}

const SortWithInstances = ( selection: string, order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    // 'LargeFiles'|'VolumeByExtension'|'FileExtensions'|'FileType'

    return dataArray.sort((a:any, b:any) => {
        let symbolA:number = 0
        let symbolB:number = 0 

        if( selection === 'VolumeByExtension' ) {
            
        } else if( selection === 'LargeFiles' ) {
            
        } else if( selection === 'FileExtensions' ) {
            symbolA = Number(a?.instences?.text);
            symbolB = Number(b?.instences?.text);
        } else if( selection === 'FileType' ) {
            
        }

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}

const SortWithType = ( selection: string, order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    // 'LargeFiles'|'VolumeByExtension'|'FileExtensions'|'FileType'

    return dataArray.sort((a:any, b:any) => {
        let symbolA:string = ''
        let symbolB:string = '' 

        if( selection === 'VolumeByExtension' || selection === 'LargeFiles' || selection === 'FileExtensions' ) {

        } else if( selection === 'FileType' ) {
            symbolA = a?.type?.text;
            symbolB = b?.type?.text;
        }

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA.localeCompare(symbolB);
        } else if ( order === 'DESC' ) {
            return symbolB.localeCompare(symbolA);
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}


export {
    SortWithSerial,
    SortWithFile,
    SortWithPath,
    SortWithExtension,
    SortWithSize,
    SortWithPercentage,
    SortWithInstances,
    SortWithType
}