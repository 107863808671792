import React from 'react';
// import Breadcrumb from '../components/core/Breadcrumb/Breadcrumb';
import Tile from '../components/core/Tile/Tile';

const ComingSoon = () => {
    return (
        <div className={`grow flex flex-col items-center justify-center align-middle p-10`}>
            <Tile rowSpan={25} colSpan={25} className="md:p-28 lg:p-32 sm:p-18 p-8 shadow-md hover:shadow-xl">
                <div  className='flex justify-center items-center align-middle text-center grow'><img src="/assets/img/server_cluster.svg" alt="comming soon" className='md:w-[300px] w-[200px]' /></div>
                <div className='text-center text-2xl md:text-4xl font-light mt-8 md:mt-12 text-slate-500 whitespace-nowrap flex items-center justify-center'>We are working on this<i className="fa-light fa-face-smile text-[#ff5e0e] md:mt-2 md:text-3xl ml-2 md:ml-4"></i></div>
            </Tile>
        </div>
    );
}

export default ComingSoon;