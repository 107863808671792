import React, { useState, useRef, useEffect, useCallback } from "react";


declare global {
    interface Window {
        hsConversationsSettings: any;
        HubSpotConversations: any
    }
}

/**
 * 
 * @param portalId {number} HubId / PortalId from HubSpot
 * @param userId {string} token from visitor-identification api. More on https://developers.hubspot.com/docs/api/conversation/visitor-identification
 * @param userEmail {string} email of user, same used while generating userId
 * 
 * @returns 
 */
const HubSpotChatBot = (portalId: number, userId: string, userEmail: string) => {
  const [globalVar, setGlobalVar] = useState(false);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [activeConversation, setActiveConversation] = useState(false);
  const eventRef = useRef<any | null>(null);


  useEffect(()=>{
    if( 
      (userId !== null && userId !== undefined && userId !== '') &&
      (userEmail !== null && userEmail !== undefined && userEmail !== '')
    ) {
      setGlobalVar(true)
      window.hsConversationsSettings = {
        loadImmediately: true,
        identificationEmail: userEmail,
        identificationToken: userId
      };
      // console.log({portalId, userId, userEmail})
    }
  },[userId, userEmail])

  

  useEffect(() => {

    if( !globalVar ) { return; }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader'
    script.async = true;
    script.defer = true;
    script.src = `//js.hs-scripts.com/${portalId}.js`;
    // script.src = `//js-eu1.hs-scripts.com/${portalId}.js`;

    script.addEventListener('load', () => {
      setIsScriptLoaded(true);
      setHasLoaded(true);
    });

    document.body.appendChild(script);

    // console.log('HUB_SPOT_CHATBOT: loaded');

    return () => {
      document.body.removeChild(script);
      window.hsConversationsSettings = undefined;
      setIsScriptLoaded(false);
    };
  }, [globalVar, portalId, userId, userEmail]);

  useEffect(() => {
    eventRef.current = (payload: any) => {
      // console.log({payload})
      setActiveConversation(payload.conversation.conversationId);
    };

    if (hasLoaded && window.HubSpotConversations) {
      // console.log('HUB_SPOT_CHATBOT: object present in windows and has loaded');

      window.HubSpotConversations.on(
        'conversationStarted',
        eventRef.current
      );
    }

    return () => {
      if (window.HubSpotConversations) {
        window.HubSpotConversations.off(
          'conversationStarted',
          eventRef.current
        );
      }
    };
  }, [hasLoaded,activeConversation]);  
    
  
  /**
   * Handler to open chatbot widget
   */
  const openHandler = useCallback(() => {
    if (hasLoaded && window.HubSpotConversations) {
      window.HubSpotConversations.widget.open();
    }
  }, [hasLoaded]);
  
  /**
   * Handler to close chatbot widget
   */
  const closeHandler = useCallback(() => {
    if (hasLoaded && window.HubSpotConversations) {
      window.HubSpotConversations.widget.close();
    }
  }, [hasLoaded])
  
  return {
    hasLoaded, 
    activeConversation,
    openHandler,
    closeHandler
  };
}

export default HubSpotChatBot