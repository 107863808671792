import React, { FC, ReactNode, ComponentProps, useContext } from "react";
// import { NavLink } from "react-router-dom";
import { ThemeContext } from "../../../contexts";

interface Props extends ComponentProps<"footer">  {
    className?: string;
    children?: ReactNode | ReactNode[]
}

/**
 * - **Orange Background:** bg-orange-light-component-background bg-orange-dark-component-background
 * - **Blue Background:** bg-blue-light-component-background bg-blue-dark-component-background
 * - **Orange Primary:** bg-orange-light-primary-default bg-orange-dark-primary-default
 * - **Blue Primary:** bg-blue-light-primary-default bg-blue-dark-primary-default
 * - **Orange Text:** text-orange-light-primary-default text-orange-dark-primary-default
 * - **Blue Text:** text-blue-light-primary-default text-blue-dark-primary-default
 * - **Orange Text:** text-orange-light-component-text text-orange-dark-component-text
 * - **Blue Text:** text-blue-light-component-text text-blue-dark-component-text
 * - **Orange Border:** border-orange-light-primary-default border-orange-dark-primary-default
 * - **Blue Border:** border-blue-light-primary-default border-blue-dark-primary-default
 * @param props 
 * @returns 
 */

const Footer:FC<Props> = ({className, children, ...rest}: Props) => {

    const theme = useContext(ThemeContext) || {themeColor: 'orange', themeContrast: 'light'};

    const primaryColor = `text-${theme.themeColor}-${theme.themeContrast}-primary-default`;

    return (
        <footer className={`flex lg:flex-row md:flex-col flex-col mg:w-full align-middle items-center justify-center p-6 ${className}`}>
            <div className="copyright text-sm font-normal leading-6">
                &copy; {new Date().getFullYear()}, Made with <div className="inline text-red-500">&hearts;</div> by &nbsp;
                <a href="https://zeeq.io/" target="_blank" rel="noopener noreferrer" className={`text-${theme.themeColor}-${theme.themeContrast}-primary-default`}>ZeeQ Technologies Pte Ltd.</a>
                {/* &nbsp; running on {process.env.REACT_APP_ENVIRONMENT} environment */}
            </div>
            <div className="grow"></div>
            <div className="footer-links flex gap-4">
                {/* <a className={`${primaryColor} text-base font-normal leading-6`} href="/">License</a>
                <a className={`${primaryColor} text-base font-normal leading-6`} href="/">Documentation</a>
                <a className={`${primaryColor} text-base font-normal leading-6`} href="/">Support</a> */}
            </div>
        </footer>
    );
}

export default Footer;
