
const SortWithName = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = a?.profile?.displayName;
        const symbolB = b?.profile?.displayName;

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA.localeCompare(symbolB);
        } else if ( order === 'DESC' ) {
            return symbolB.localeCompare(symbolA);
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}

const SortWithMailId = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = a?.profile?.mail;
        const symbolB = b?.profile?.mail;

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA.localeCompare(symbolB);
        } else if ( order === 'DESC' ) {
            return symbolB.localeCompare(symbolA);
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}

const SortWithStatus = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA: boolean = a?.protected;
        const symbolB: boolean = b?.protected;

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA ? -1 : 1;
        } else if ( order === 'DESC' ) {
            return symbolA ? 1 : -1;
        } else {
            return 0;
        }
    })
}

const SortWithProtectedDataSize = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = Number(a?.protected_data_size_bytes);
        const symbolB = Number(b?.protected_data_size_bytes);

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}

const SortWithLastProtectedData = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = ( a?.last_protection_date === null || a?.last_protection_date === undefined ) ? 0 : Number(a?.last_protection_date);
        const symbolB = ( b?.last_protection_date === null || b?.last_protection_date === undefined ) ? 0 : Number(b?.last_protection_date);

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}

export {
    SortWithName,
    SortWithMailId,
    SortWithStatus,
    SortWithProtectedDataSize,
    SortWithLastProtectedData,
}