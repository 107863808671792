/* eslint-disable react/jsx-pascal-case */
import React, { Dispatch, FC, SetStateAction } from "react";
import Tile5x4_ChartText from "../../../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText";
import { ITableControllerProps } from "../../../components/core/Table/Table";
import { getProtectedAgents } from "../../../utils/agentUtils";

interface IProtectedDevicesTile {
    endpointData: any;
    setSelectedData: Dispatch<SetStateAction<string>>
    setTableController: Dispatch<SetStateAction<ITableControllerProps>>
    scrollToBottom: ()=>void;
    getDeviceStatus: (endpointsData: any, endpoint: any) => "Protected" | "Pending" | "Deleted" | "Unprotected" | "Disabled" | undefined;
}

const ProtectedDevicesTile:FC<IProtectedDevicesTile> = ({
    endpointData,
    setSelectedData,
    setTableController,
    scrollToBottom,
    getDeviceStatus
}) => {
    return (
        <>
            <Tile5x4_ChartText 
                sideBar={false}
                clickNavigate={false}
                navigationPath='/'
                title={
                    < div className="flex items-center">
                        Protected Devices
                        {/* <small className="text-green-600 p-1 px-2 border-green-200 text-xs "><span className=" text-green-600"></span><i className="fa-solid fa-check bg-green-100 text-green-600 border border-green-300 rounded-full w-4 h-4 ml-1 text-xs "/></small> */}
                    </div>}
                description={<></>}
                value={<em className="fa-duotone fa-shield-check text-5xl text-[#ff5e0e]"></em>}
                // textBackground="bg-green-100"
                // textValue={<></>}
                // textColor="text-green-600"
                // textArea=""
                // inChartMain='20' inChartSub="License"
                // alert={false}
                // live={false}
                designOption="chart"
                chartType="donut" 
                chartLabels={[]} 
                chartDataset={[
                    {
                        label: '# of Votes',
                        data: [],
                        backgroundColor: [
                        ],
                        borderWidth: 5,
                        cutout: 43,
                        borderRadius: 20
                    },

                ]}
                chartOptions={{
                    responsive: true,
                    plugins: {
                        legend: { display: false },
                        title: { display: false },
                        datalabels: { display: false },
                    },
                    scales: {
                        x: { display: false },
                        y: { display: false },
                    },
                }}
                inChartMain='' inChartSub=""
                className='border relative'
                tileMode='default'
                alert={false}
                live={false}
                tileAction={true}
                actionHandler={()=>{
                    setSelectedData('Protected')
                    setTableController((prevState)=>{
                        let newState = {...prevState};

                        
                        newState.data = (getProtectedAgents(endpointData) || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos === null });
                        return newState;
                    })
                    scrollToBottom()
                }}
            >
                <div className="text-7xl text-[#ff5e0e] w-28 h-28 flex items-center text-right justify-end rounded-md absolute whitespace-nowrap right-4">
                    {/* {(endpointData?.endpoints || []).length - (endpointData?.pending_installation || []).length - (endpointData?.deleted || []).length - (endpointData?.unprotected || []).length} */}
                    {
                        (getProtectedAgents(endpointData) || [])
                            // .filter((ep:any)=>{ return (ep?.agent_profile?.BlockListInfos === null) || (ep?.agent_profile?.BlockListInfos !== null && [4,6].includes(ep?.agent_profile?.BlockListInfos?.Reason) ) })
                            .filter((ep:any)=>{ return (ep?.agent_profile?.BlockListInfos === null)})
                            .length
                            
                    }
                </div>
            </Tile5x4_ChartText>
        </>
    )
}

export default ProtectedDevicesTile;
