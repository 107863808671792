import React, { FC, ReactNode, ComponentProps, Dispatch, SetStateAction, useContext } from "react";
import { ThemeContext } from "../../../contexts";

interface Props extends ComponentProps<"div">  {
    /**
     * Child node(s) of button
     */
    modalVisible?: boolean;
    /**
     * Child node(s) of button
     */
    children?: ReactNode | ReactNode[] | string;
    /**
     * 
     */
    className?: string;
    setModalVisibility?: Dispatch<SetStateAction<boolean>>;
}

/**
 * Button Component
 * bg-orange-light-tile-background bg-orange-dark-tile-background
 * bg-blue-light-tile-background bg-blue-dark-tile-background
 * @param param0 
 * @returns 
 */
const Modal:FC<Props> = ({
    setModalVisibility=()=>{},
    modalVisible=false,
    className='',
    children=<></>,
    ...rest
}: Props) => {

    const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};

    return (
        <>
            <div className={`modal-wrapper fixed top-0 left-0 right-0 bottom-0 z-[999997] ${modalVisible ? 'visible' : 'hidden'}`}>
                <div
                    className={`modal-overlay absolute top-0 left-0 right-0 bottom-0 bg-${theme.themeColor}-${theme.themeContrast}-component-text opacity-10`}
                    onClick={()=>{
                        setModalVisibility((prev)=>{
                            return !prev;
                        })
                    }}
                ></div>
                <div className={`modal flex flex-col rounded-md absolute min-w-1/2 min-h-1/2 top-1/2 left-1/2 -mr-1/2 -translate-x-1/2 -translate-y-1/2 bg-${theme.themeColor}-${theme.themeContrast}-component-background shadow-md transition-all duration-500 ease-in-out ${className}`}>
                    { children }
                </div>
            </div>

        </>
    );
}

export default Modal;
