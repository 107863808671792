import React, { useRef, useState, useEffect } from "react";
// import { ThemeContext } from "../../contexts";
import { Breadcrumb, Modal, ModalBody, ModalHeader, Tile } from "../../components/core";
import { Table } from "../../components/core/Table";
import { ITableColumnItem, ITableControllerProps } from "../../components/core/Table/Table";
import { getAPICall } from "../../services/APILayer/axiosMethodCalls";
import { ifTimeExpired, getFormattedDate } from "../../utils/format"
import { DonutChart } from "../../components/Charts/ApacheECharts";

const License = () => {

    // const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    const navRaf = useRef<HTMLDivElement>(null)

    const [licenseInfo, setLicenseInfo] = useState<any>({});
    const [ordersInfo, setOrdersInfo] = useState<any>({});
    const [modalVisible, setModalVisibility]=useState(false);
    const [selectedOrder, setSelectedOrder]=useState<any>(null);
    const [orgModalInfo, setOrgModalInfo] = useState<any>(null);
    const [orgModalVisibilityState, modalVisibilityState] = useState<any>(null);
    const [tableController, setTableController] = useState<ITableControllerProps>({
        data: [],
        loader: false,
        currentPage: 1,
        offset: 0,
        limit: 5,
        totalRecords: 10,
        maxVisiblePaginationButtons: 5,
        searchTerm: '',
        filter: {},
        sorting: {
            column: '',
            direction: null
        }
    });
    const [tableColumns, setTableColumns] = useState<ITableColumnItem[]>([
        {
            id: 1,
            name: 'Order Id',
            column: '',
            textAlign: 'text-left',
            textColor: 'text-[#FF5E0E]',
            visibility: true,
            className: "pl-4 min-w-[120px]"
        },
        {
            id: 2,
            name: 'Order From',
            column: 'hubspot_issuer_company_id',
            textAlign: 'text-left',
            textColor: 'text-[#FF5E0E]',
            visibility: true,
            className: "min-w-[120px]"
        },
        {
            id: 2,
            name: 'Order For',
            column: 'hubspot_owner_company_id',
            textAlign: 'text-left',
            textColor: 'text-[#FF5E0E]',
            visibility: true,
            className: "min-w-[120px]"
        },
        {
            id: 5,
            name: 'Purchase Date',
            column: 'createdAt',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true,
            className: "min-w-[120px]"
        },
    ]);

    const getLicenseInfo = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/app/licenses/me`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const getOrgInfo = async (orgId: string) => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/crm/companies/${orgId}`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( Object.keys(licenseInfo).length > 0 ) {
            return;
        }
        const fetchLicenseInfo = () => {
            getLicenseInfo()
                .then((license)=>{
                    console.log({license})
                    setLicenseInfo(license)
                })
                .catch(err=>console.log(err));
        }
        fetchLicenseInfo();
    },[licenseInfo])

    const getOrderInfo = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/app/orders/me`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( Object.keys(ordersInfo || {}).length > 0 ) {
            return;
        }
        const fetchOrderInfo = () => {
            getOrderInfo()
                .then((data)=>{
                    if( data ) {
                        console.log({data})
                        setOrdersInfo(data?.orders || [])
                    }
                })
                .catch(err=>console.log(err));
        }
        fetchOrderInfo()
    },[ordersInfo])

    useEffect(()=>{
        // console.log({ordersInfo})
        if( Object.keys( ordersInfo || {} ).length < 1 ) {
            return;
        }
        setTableController((previousState)=>{
            return {
                ...previousState,
                data: [...ordersInfo]
            }
        })
    },[ordersInfo])

    const actionButtonGenerator = (row: object) => {
        return <>
            <button aria-label='edit'
                onClick={()=>{

                }}
            ><em className='fa-regular fa-pen text-[#3A3541] opacity-80 mr-2'></em></button>
            <button aria-label='view'
                onClick={()=>{
                    
                }}
            ><em className='fa-regular fa-eye text-[#3A3541] opacity-80'></em></button>
        </>
    }

    const handleOrgIdClick = async (orgId: any, title: string) => {
        let org = await getOrgInfo(orgId);
        setOrgModalInfo((prevState: any)=>{
            return {
                modelTitle: title,
                ...org
            }
        });
        modalVisibilityState(true)
    }

    const tableDataFormatter = (rowObj: any = {}, format?: string | undefined) => {
        let returningVal = null;
        switch (format) {
            case 'Order Id':
                returningVal = (
                    <div 
                        className="cursor-pointer"
                        onClick={()=>{
                            handleModalVisibility(rowObj)
                        }}
                    >{rowObj?._id}</div>
                );
                break;

            case 'Order From':
                    // returningVal = <div className="text-sm hover:text-gray-900 font-medium text-[#3A3541] opacity-80 cursor-pointer">{rowObj}</div>
                    returningVal = (
                        <div 
                            className="cursor-pointer"
                            onClick={()=>{
                                handleOrgIdClick(rowObj, 'Order From')
                            }}
                        >{rowObj}</div>
                    );
                break;

            case 'Order For':
                    returningVal = (
                        <div 
                            className="cursor-pointer"
                            onClick={()=>{
                                handleOrgIdClick(rowObj, 'Order For')
                            }}
                        >{rowObj}</div>
                    );
                break;

            case 'Purchase Date':
                returningVal = (
                    <div className="">{getFormattedDate(rowObj)}</div>
                );
                break;

            default:
                returningVal = rowObj;
                break;
        }
        return returningVal;
    }

    const handleSwitch = () => {}

    const handleModalVisibility = (orderDetails: any) => {
        setSelectedOrder(orderDetails)
        setModalVisibility(true)
    }

    return (
        <>
            <div className={`p-6 grow flex flex-col`}>    
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2">License</h1>
                <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-home', link: '', name: 'Home' },
                    { icon: '', link: '/settings', name: 'Settings' },
                    { icon: '', link: '/settings/license', name: 'License' }
                ]} />

                <div className="grow flex flex-col md:flex-row gap-6">
                    <div className="lg:w-4/12 w-full">
                        <Tile className="h-[560px] overflow-hidden" colSpan={12} rowSpan={13}> 
                            <div className=" relative flex flex-col justify-center items-center align-middle text-center p-8 h-full">
                                <div className="flex relative flex-col md:flex-row justify-center h-72 w-[280px] mt-4">
                                <DonutChart
                                    title=""
                                    data={[                     
                                        {
                                            value: ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).daysLeft,
                                            name: "Days Left",
                                            itemStyle: {
                                                opacity: 1,
                                            }
                                        },
                                        {
                                            value: ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).totalDays - ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).daysLeft,
                                            name: "Days Passed",
                                            itemStyle: {
                                                opacity: 0.2,
                                                color:"#999"

                                            }
                                        }
                                    ]}
                                    thickness= {['70%', '80%']}
                                    legend={{
                                        show:false
                                    }}
                                    centreValue={
                                        <>
                                            <div className="text-2xl font-semibold">{ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).daysLeft + ''}</div>
                                            <div className="text-sm font-normal">Days left</div>
                                        </>
                                    }
                                    
                                />
                                    
                                    {/* <MasterChart
                                        type='donut'
                                        labels={['Left', 'Spent']}
                                        datasets={[
                                            {
                                                label: '# of Votes',
                                                data: [
                                                    ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).daysLeft,
                                                    ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).totalDays - ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).daysLeft,
                                                ],
                                                backgroundColor: [
                                                    'rgba(255, 94, 14, 1)',
                                                    'rgba(65, 65,65, 0.1)',
                                                ],
                                                borderWidth: 5,
                                                cutout: 120,
                                                borderRadius: 20
                                            },
                                        ]}
                                        options={{
                                            responsive: true,
                                            
                                            plugins: {
                                                legend: { display: false },
                                                title: { display: false },
                                                datalabels: { display: false },
                                            },
                                            scales: {
                                                x: { display: false },
                                                y: { display: false },
                                            },
                                        }}
                                    /> */}
                                    {/* <div className="absolute flex justify-center items-center text-xl font-medium top-1/2 left-1/2 text-center transform -translate-x-1/2 -translate-y-1/2">
                                        {
                                            !ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).expired ?
                                            (
                                                <span className="grow md:w-52 h-64 text-4xl md:text-7xl text-slate-600 flex flex-col justify-center items-center rounded-md">
                                                    <div>{ifTimeExpired(licenseInfo?.license?.license_start_date, licenseInfo?.license?.license_end_date).daysLeft}</div>
                                                    <div className="block text-base md:text-lg uppercase">Days Left</div>
                                                </span>
                                            ) :
                                            (
                                                <span className="grow md:w-52 h-64 text-4xl md:text-7xl text-slate-600 flex flex-col justify-center items-center rounded-md">
                                                    <div>0</div>
                                                    <div className="block text-base md:text-lg uppercase">Days Left</div>
                                                </span>
                                            )
                                        }
                                    </div> */}
                                </div>
                                <div className="font-semibold flex flex-col items-center mt-4 align-middle justify-center text-center w-full text-gray-700">
                                    <div className="flex flex-col text-[#3A3541] items-left justify-start text-left gap-3 mt-6 py-6  border-t border-[#ff5e0e] w-full text-[18px] font-medium">
                                        <div className="flex flex-row items-center"><i className="inline fa-solid fa-hashtag text-[#ff5e0e] text-xl"></i><span className="inline text-[#3A3541] text-base ml-2">
                                            {licenseInfo?.license?._id || 'NA'}
                                        </span></div>
                                        <div className="flex flex-row items-center"><i className="inline fa-solid fa-award  text-[#ff5e0e] text-xl"></i><span className="inline text-[#3A3541] text-base ml-3">
                                            {licenseInfo?.license?.license_type === '0' && 'Demo'}
                                            {licenseInfo?.license?.license_type === '1' && 'Evaluation'}
                                            {licenseInfo?.license?.license_type === '2' && 'Full'}
                                            {!licenseInfo?.license?.license_type && 'NA'}
                                        </span></div>
                                        <div className="flex flex-row items-center"><i className="inline fa-regular fa-calendar  text-[#ff5e0e] text-xl"></i><span className="inline text-[#3A3541] text-base ml-2">{getFormattedDate(licenseInfo?.license?.license_start_date || '')}</span></div>
                                        <div className="flex flex-row items-center"><i className="inline fa-regular fa-calendar  text-[#ff5e0e] text-xl"></i><span className="inline text-[#3A3541] text-base ml-2">{getFormattedDate(licenseInfo?.license?.license_end_date || '')}</span></div>
                                    </div>
                                </div>
                            </div>
                        </Tile>
                    </div>
                    <div className="page grid grid-cols-6 gap-6 w-full min-h-max">
                        <Tile colSpan={8} rowSpan={13} className="hover:shadow-md">
                            <div className="p-4 text-[#3A3541] font-semibold">Your Orders</div>
                            <Table
                                navRef={navRaf}
                                actionButtonGenerator={actionButtonGenerator}
                                columns={tableColumns}
                                setColumns={setTableColumns}
                                formatData={tableDataFormatter}
                                showActionCell={false}
                                showActionHeaderCell={false}
                                showColumnManagementMenu={false}
                                switchHandler={handleSwitch}
                                tableController={tableController}
                                tableId="DeviceTable"
                            />
                        </Tile>
                    </div>
                </div>
                
            </div>

            <Modal modalVisible={modalVisible}  setModalVisibility={()=>{ setModalVisibility(false) }} className='orderInfoModal h-1/3 w-1/3'>
                <ModalHeader setModalVisibility={()=>{ setModalVisibility(false) }} className={`text-[#3A3541] text-base`}>Order Details</ModalHeader>
                <ModalBody className="flex w-full items-center justify-center">
                    {selectedOrder?.order_data.map((item: any, itemIndex: number)=>(
                        <div key={itemIndex} className="grow flex flex-col items-center justify-center align-middle">
                            {item.unit === 'endpoints' && <em className="fa-thin fa-laptop text-8xl text-[#ff5e0e] my-4"></em>}
                            {item.unit === 'GB' && <em className="fa-thin fa-hard-drive text-8xl text-[#ff5e0e] my-4"></em>}
                            {item.unit === 'users' && <em className="fa-thin fa-user-group text-8xl text-[#ff5e0e] my-4"></em>}
                            <span className="text-lg text-gray-500 uppercase">
                                {item.unit === 'endpoints' && item.unit}
                                {item.unit === 'GB' && 'Storage'}
                                {item.unit === 'users' && 'Users'}
                            </span>
                            <span className="text-xl text-[#3A3541] font-bold">{
                                item.qty === -1 ? 'Unlimited' : item.qty
                            } { item.qty !== -1 && item.unit}</span>
                        </div>
                    ))}
                </ModalBody>
            </Modal>  

            <Modal modalVisible={orgModalVisibilityState}  setModalVisibility={()=>{ modalVisibilityState(false) }} className='orgDetailModal h-1/2 w-1/4'>
                <ModalHeader setModalVisibility={()=>{ modalVisibilityState(false) }} className={`text-[#3A3541] text-base`}>{orgModalInfo?.modelTitle || ''}</ModalHeader>
                <ModalBody className="flex w-full items-center justify-center">
                    <div className="flex flex-col w-full py-4 justify-center items-center">
                        <div className="p-8 h-44 w-full mb-8 flex justify-center items-center object-contain">
                            <img src="https://cdn.zeeq.io/zeeq-new-logos/Brand/SVG/wordmark_blackorange.svg" alt="logo" className="min-h-max w-auto" />
                        </div>
                        <div className="flex flex-col items-center">
                            <div className="text-[#3A3541] text-2xl font-semibold flex items-center capitalize mb-1">{orgModalInfo?.company?.properties?.name || ''}</div>
                            <div className="text-green-600 text-sm font-light flex items-center py-1 px-4 mb-3 bg-green-500 bg-opacity-10 rounded-sm ">{orgModalInfo?.company?.properties?.type || ''}</div>
                            <div className="text-[#3A3541] text-base flex items-center">{(orgModalInfo?.company?.properties?.company_email || 'NA').split(',')[0] || ''}</div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>         
        </>
    );
}


export default License;