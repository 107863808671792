import React from 'react';
import {formatBytes} from './agentUtils';
import Tooltip from '../components/core/Tooltip/Tooltip';

const processTableData = (rawFileBackupData:any) => {
    let largeFile = rawFileBackupData?.data?.stats?.data?.Stats?.LargestFiles || { Files: {File: []}, Total: 0 };
    let largestExts = rawFileBackupData?.data?.stats?.data?.Stats.LargestExts;
    let mostInstancesExts = rawFileBackupData?.data?.stats?.data?.Stats?.MostInstancesExts
    let typesInstances = rawFileBackupData?.data?.stats?.data?.Stats?.TypesInstances

    let processedLargeFile: any[] = [];
    let processedLargestExts: any[] = [];
    let processedMostInstancesExts: any[] = [];
    let processedTypesInstances: any[] = [];

    // LargeFiles
    [...(largeFile?.Files?.File || [])].forEach((file:any, fileIndex:number) => {
        processedLargeFile.push({
            'serial': {
                text: fileIndex + 1,
                node: <div>{fileIndex + 1}</div>
            },
            'file' : {
                text: file.Path.split('/').pop(),
                node: <div className='group relative'>
                        <div className='truncate'>{file.Path.split('/').pop()}</div>
                        {fileIndex === 0 ? (
                            <Tooltip direction='down' value={file.Path.split('/').pop()} />
                        ) : (
                            <Tooltip  direction='up' value={file.Path.split('/').pop()} />
                        )}
                     </div>
            }, 
            'path' : {
                text: file.Path.split('/').splice(0,file.Path.split('/').length-1).join('/'),
                node: <>
                    {file.Path.split('/').splice(0,file.Path.split('/').length-2).join('/').slice(1,10)}...{file.Path.split('/').splice(0,file.Path.split('/').length-1).pop()}
                </>
            },
            'extension' : {
                text: file.Path.split('.').pop(),
                node: <div className='uppercase'>{file.Path.split('.').pop()}</div>
            },
            'size': {
                text: file.Size,
                node: <div>{formatBytes(file.Size)}</div>
            },
            'percent': {
                text: (file.Size/largeFile.Total)*100,
                size: file.Size,
                total: largeFile.Total,
                node: <div>{((file.Size/largeFile.Total)*100).toFixed(2)}%</div>
            }
        })
    });

    // VolumeByExtension
    [...(largestExts?.Extensions?.Extension || [])].forEach((extension:any, extensionIndex:number)=>{
        processedLargestExts.push({
            'serial': {
                text: extensionIndex + 1,
                node: <>{extensionIndex + 1}</>
            },
            'extension': {
                text: extension?.String || '',
                node: <>{extension?.String || ''}</>
            },
            'size': {
                text: formatSize(extension?.Size || 0),
                node: <>{formatBytes(extension?.Size || 0)}</>
            },
            'percent': {
                text: (((extension?.Size || 0) / largestExts.Total)*100),
                node: <>{(((extension?.Size || 0) / largestExts.Total)*100)}%</>
            }
        })
    });

    // FileExtensions
    [...(mostInstancesExts?.Extensions?.Extension || [])].forEach((instance:any, instanceIndex:number)=>{
        processedMostInstancesExts.push({
            'serial': {
                text: instanceIndex + 1,
                node: <>{instanceIndex + 1}</>
            },
            'extension': {
                text: instance?.String || '',
                node: <>{instance?.String || ''}</>
            },
            'instences': {
                text: instance?.NbInstances || 0,
                node: <>{instance?.NbInstances || 0}</>
            },
            'percent': {
                text: (((instance?.NbInstances || 0) / largestExts.Total)*100),
                node: <>{(((instance?.NbInstances || 0) / largestExts.Total)*100)}%</>
            }
        })
    });

    console.log({types: typesInstances})

    let temp: any = null

    if( Array.isArray( typesInstances?.Types?.Type ) ) {
        temp = typesInstances?.Types?.Type || []
    } else {
        temp = typesInstances?.Types?.Type ? [typesInstances?.Types?.Type] : []
    }
    [...(temp || [])].forEach((instance:any, instanceIndex:number)=>{
        console.log({instance})
        let type = '';

        if( instance.Code === 1 ) {
            type = 'Regular';
        } else if( instance.Code === 2 ) {
            type = 'Directory';
        } else if( instance.Code === 3 ) {
            type = 'Symbolic Link';
        } else if( instance.Code === 10 ) {
            type = 'Encrypted File System (Windows)';
        } else {
            type = JSON.stringify(instance.Code)
        }

        // FileType
        processedTypesInstances.push({
            'serial': {
                text: instanceIndex + 1,
                node: <>{instanceIndex + 1}</>
            },
            'type': {
                text: type,
                node: <>{type}</>
            },
            'instance': {
                text: instance?.NbInstances || 0,
                node: <>{instance?.NbInstances || 0}</>
            },
            'percent': {
                text: (((instance?.NbInstances || 0) / largestExts.Total)*100),
                node: <>{(((instance?.NbInstances || 0) / largestExts.Total)*100)}%</>
            }
        })
    });

    return {
        largeFile: processedLargeFile, // LargeFiles
        largestExts: processedLargestExts, // VolumeByExtension
        mostInstancesExts: processedMostInstancesExts, // FileExtensions
        typesInstances: processedTypesInstances // FileType
    }
}

const formatSize = (bytes: any, decimals: number = 2) => {
    let negative = false;
    if (bytes === 0) return {value: 0, unit: 'Bytes'};

    if( bytes < 0 ) {
        bytes = bytes * -1;
        negative = true;
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    // return (negative ? '- ' : '') + parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    return {
        value: (negative ? '- ' : '') + parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
        unit: sizes[i]
    }
};


export {
    processTableData,
    formatSize
}