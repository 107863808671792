import React, { FC } from "react";
import MasterChart, { IChartDatasetData } from "../../../Charts/ChartJS/CJMasterChart/MasterChart";
import { Tile } from "../../../core";

interface IProps {
    title: string;
    description?: string;
    chartType: "bar" | "line" | "pie" | "donut";
    chartLabels: string[];
    chartDataset: IChartDatasetData[];
    chartOptions: object; 
}

const Tile55:FC<IProps>  = ({
    title,
    description,
    chartType,
    chartLabels,
    chartDataset,
    chartOptions
}:IProps) =>{
    return (
        <>
            <Tile rowSpan={10} colSpan={2} className="flex flex-col h-full text-[#3A3541] px-5 py-4">
                <div className="flex flex-row justify-between w-full mb-7 text-xl leading-8  font-medium">
                    {title}
                </div>
                <div className="grow text-center relative items-center justify-center align-middle">
                    {chartType && <MasterChart
                        type={chartType}
                        labels={chartLabels}
                        datasets={chartDataset}
                        options={chartOptions}
                    />}
                </div>   
                <div className="w-full text-center text-sm leading-5 font-semibold mt-4">
                    {description}
                </div>
            </Tile>
        </>
    )
}

export default Tile55