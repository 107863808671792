import React, { FC, ReactNode } from "react"

interface ITableBodyProps {
    children: ReactNode | ReactNode[];
}

const TableBody: FC<ITableBodyProps> = ({children}) => {
    return (
        <div className="tbody block max-h-[628px] min-h-[80px] overflow-y-scroll customScroll">
            {children}
        </div>
    )
}

export default TableBody;