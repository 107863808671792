/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { useMsal } from "@azure/msal-react";
// import { ProfileData } from "./ProfileData";
import { callMsGraph, callMsGraphUsers, callMsGraphDrives, callMsGraphApplications } from "./graph";
import { AccountInfo } from "@azure/msal-common";
import { toast } from "react-toastify";
// import { IPublicClientApplication } from '@azure/msal-browser';
// import { Button } from "../../../components/core";

interface IProfileContentProps {
    homeId: string;
    name: string;
    graphDataUsers: any
    setGraphDataUsers: Dispatch<SetStateAction<any>>
 }

const ProfileContent:FC<IProfileContentProps> = ({
    homeId,
    name,
    graphDataUsers,
    setGraphDataUsers
}: IProfileContentProps) => {

    const { instance } = useMsal();
    // const [graphData, setGraphData] = useState(null);
    const [firstCall, setFirstCall] = useState(true);

    const account = instance.getAccountByHomeId(homeId);

    const request = {
        scopes: ["User.Read"],
        account: account as AccountInfo
    };

    

    useEffect(()=>{
        if( !firstCall ) {
            return;
        }
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            callMsGraph(response.accessToken).then((response: any) => {
                // setGraphData(response)
            });
            callMsGraphUsers(response.accessToken).then((response: any) => {
                if( response ) {
                    setGraphDataUsers(response)
                } else {
                    toast("Please grant admin consent to this application in your Azure account")
                }
            });
            callMsGraphDrives(response.accessToken).then((response: any) => {
                console.log({_MDDrive:response})
                // setGraphDataUsers(response)
            });
            callMsGraphApplications(response.accessToken).then((response: any) => {
                console.log({_MDApplications:response})
                // setGraphDataUsers(response)
            });
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                callMsGraph(response.accessToken).then((response:any) => {
                    // setGraphData(response)
                });
            });
        });
        setFirstCall(false)
    },[])

    // const handleLogout = (instance: IPublicClientApplication, homeId: string) => {
    //     const currentAccount = instance.getAccountByHomeId(homeId);
    //     instance.logoutRedirect({ account: currentAccount });
    // }
    
    return (
        <>
            {/* <h5 className="card-title">Welcome <strong>{name}</strong></h5> */}
            
            {/* {graphData && <ProfileData graphData={graphData} />} */}
            {/* <Button className="ml-auto" onClick={() => handleLogout(instance,homeId)}>Sign out</Button> */}
        </>
    )
}

export default ProfileContent