import React, { Dispatch, FC, RefObject, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Dropdown } from '../../../core';
import { getAPICall, postAPICall } from '../../../../services/APILayer/axiosMethodCalls';
import { originalPolicies, originalStrategy, getEndpointConfig } from '../../../../utils/agentUtils'
import { formatSeconds } from '../../../../utils/format';
// import ButtonGroup from '../../../core/ButtonGroup/ButtonGroup';
import { SideDrawerContext } from '../../../../contexts';

interface IAddDeviceFormData {
    hostname: {
        value: string | string[],
        error: null | string
    },
    policy: {
        value: any,
        error: null | string
    },
    strategy: {
        value: any,
        error: null | string
    },
}

const AddDevice = () => {

    const [resetHostnameDropdown, setResetHostnameDropdown] = useState<boolean>(false);
    const [resetPolicyDropdown, setResetPolicyDropdown] = useState<boolean>(false);
    const [resetStrategyDropdown, setResetStrategyDropdown] = useState<boolean>(false);

    const [ordersInfo, setOrdersInfo] = useState<any[]>([]);
    const [endpointData, setEndpointData] = useState<any>(null);
    const [endpointQuota, setEndpointQuota] = useState<number>(0);

    const sideDrawerContext = useContext(SideDrawerContext);
    // const theme = useContext(ThemeContext);

    const [deviceAddingQuantityType, setDeviceAddingQuantityType] = useState<string>('single'); // single|multiple
    // const [firstSlider, setFirstSlider] = useState(0);

    const [rawPolicyStrategy, setRawPolicyStrategy] = useState<any>({});
    const [policyStrategy, setPolicyStrategy] = useState<any>({});
    const [endpointConfig, setEndpointConfig] = useState<any>({})

    const [addDeviceFormData, setAddDeviceFormData] = useState<IAddDeviceFormData>({
        hostname: {
            value: '',
            error: null
        },
        policy: {
            value: {id: null},
            error: null
        },
        strategy: {
            value: {id: null},
            error: null
        }
    });

    const getPolicyStrategy = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints/config`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const getOrderInfo = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/app/orders/me`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const getEndpoints = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        const fetchPolicyStrategy = async () => {
            getPolicyStrategy()
                .then((data)=>{
                    setRawPolicyStrategy(data)
                })
                .catch((error)=>{ console.log(error) })
                .finally()
        }
        fetchPolicyStrategy()
    },[])

    useEffect(()=>{
        if( Object.keys(ordersInfo || {}).length > 0 ) {
            return;
        }
        const fetchOrderInfo = () => {
            getOrderInfo()
                .then((data)=>{
                    if( data ) {
                        console.log({data})
                        setOrdersInfo(data?.orders || [])
                    }
                })
                .catch(err=>console.log(err));
        }
        setTimeout(() => {
            fetchOrderInfo()
        }, 500);
    },[ordersInfo])

    useEffect(()=>{
        if( Object.keys(ordersInfo).length > 0 ) {
            let allOrderEndpointTotal = (ordersInfo || []).map((orders:any)=>{ 
                let endpointOrders = (orders?.order_data || []).filter((o:any)=>{ return o.unit === 'endpoints' })
                let totalOrderEndpoints = endpointOrders.reduce((a:number,d:any)=>{ a = a + d.qty; return a; },0)
                return totalOrderEndpoints
            }).reduce((a:number,d:number)=>{ a = a + d; return a },0)
            setEndpointQuota(allOrderEndpointTotal)
            // console.log({allOrderEndpointTotal})
        }
    },[ordersInfo])

    useEffect(()=>{
        if( endpointData ) {
            return;
        }

        const fetchEndpoints = async () => {
            await getEndpoints().then((data)=>{
                setEndpointData(data);
            }).catch((err)=>{
                console.log({err})
            });
        }

        fetchEndpoints();

    },[endpointData]);

    useEffect(()=>{
        // if( Object.keys(rawPolicyStrategy).length < 1 ) {
        //     return false;
        // }

        let arrPolicyObj: any[] = [];
        let arrPolicy: string[] = [];
        let arrStrategyObj: any[] = [];
        let arrStrategy: string[] = [];

        (rawPolicyStrategy?.config?.current_policies || []).forEach((policy:any) => {
            if( policy.ID > 100 ) {
                let policyName = {...(originalPolicies || []).find((p)=>{ return p.ID === policy.ID })};
                arrPolicy.push(`(${policy.ID}) ${policyName?.Name || policy?.Name}`)
                arrPolicyObj.push({
                    id: policy.ID,
                    value: policy.Name,
                    name: policyName?.Name || policy?.Name
                })
            }
        });
        (rawPolicyStrategy?.config?.current_strategies || []).forEach((strategy:any) => {
            if( strategy.ID > 100 ) {
                let strategyName = {...originalStrategy.find((s)=>{ if( s.ID === strategy.ID ) return s })};
                arrStrategy.push(`(${strategy.ID}) ${strategyName?.Name || strategy?.Name}`)
                arrStrategyObj.push({
                    id: strategy.ID,
                    value: strategy.Name,
                    name: strategyName?.Name || strategy?.Name
                })
            }
        });
        setPolicyStrategy({
            policy: arrPolicy,
            policyObj: arrPolicyObj,
            strategy: arrStrategy,
            strategyObj: arrStrategyObj
        });

    },[rawPolicyStrategy])

    useEffect(()=>{
        sideDrawerContext.setSecondDrawerChild(<AddDevicePreview
            policyStrategy={policyStrategy}
            addDeviceFormData={addDeviceFormData} 
            endpointConfig={endpointConfig}
        />)
        if( Array.isArray(addDeviceFormData.hostname.value) || deviceAddingQuantityType === 'multiple') {
                if(
                    addDeviceFormData.hostname.value.length > 0 && 
                    Object.keys(addDeviceFormData.policy.value).length > 1 && 
                    Object.keys(addDeviceFormData.strategy.value).length > 1
                ) {
                    let config = getEndpointConfig(rawPolicyStrategy.config, addDeviceFormData.policy.value, addDeviceFormData.strategy.value);
                    if( endpointConfig?.policy?.ID !== config?.policy?.ID ) {
                        setEndpointConfig(config)
                    }
                }
        }
        if( typeof addDeviceFormData.hostname.value === 'string' || deviceAddingQuantityType === 'single' ) {
            if(
                ( addDeviceFormData.hostname.value !== '' || addDeviceFormData.hostname.value !== undefined || addDeviceFormData.hostname.value !== null )  && 
                Object.keys(addDeviceFormData.policy.value).length > 1 && 
                Object.keys(addDeviceFormData.strategy.value).length > 1
            ) {
                let config = getEndpointConfig(rawPolicyStrategy.config, addDeviceFormData.policy.value, addDeviceFormData.strategy.value);
                if( endpointConfig?.policy?.ID !== config?.policy?.ID ) {
                    setEndpointConfig(config)
                }
            }
        }
    },[addDeviceFormData, endpointConfig])

    const addDevice = async (devices: any[]) => {
        try {
            const response = await postAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints`,{
                insert:{
                    devices:[
                        ...devices
                    ]
                }
            });
            return response.data;
        } catch(error) {
            return error;
        }
    }
    const handleAddDevice = () => {

        // let remainingQuota = endpointQuota - ( (endpointData?.endpoints || []).length - (endpointData?.deleted || []).length );
        let remainingQuota = endpointQuota - ( (endpointData?.installed || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos === null }).length + (endpointData?.pending_installation || []).length + ((endpointData?.installed || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos !== null && [4,6].includes(ep?.agent_profile?.BlockListInfos?.Reason) }).length) ) 

        if( deviceAddingQuantityType === 'single' ) { 
            if( remainingQuota < 1 ) {
                toast.warning('Quota Exceeds');
                return;
            }
        } else if( deviceAddingQuantityType === 'multiple' ) {
            let arrDevices: string[] = Array.isArray(addDeviceFormData?.hostname?.value) ? addDeviceFormData?.hostname?.value : [];
            if( remainingQuota < arrDevices.length ) {
                toast.warning('Quota Exceeds');
                return;
            }
        }


        setAddDeviceFormData((prevState: any)=>{
            let newState = {...prevState};
            if( deviceAddingQuantityType === 'single' ) {
                if( (newState?.hostname?.value || '') === '' ) {
                    newState.hostname.error = 'Required Field'
                }
            } else if( deviceAddingQuantityType === 'multiple' ) {
                if( (newState?.hostname?.value || []).length < 1 ) {
                    newState.hostname.error = 'Required Field'
                }
            }
            if( (newState?.policy?.value?.id || '') === '' ) {
                newState.policy.error = 'Required Field'
            }
            if( (newState?.strategy?.value?.id || '') === '' ) {
                newState.strategy.error = 'Required Field'
            }
            return newState;
        })


        if( 
            (addDeviceFormData?.hostname?.value || '') === '' || 
            (addDeviceFormData?.policy?.value?.id || '') === '' || 
            (addDeviceFormData?.strategy?.value?.id || '') === ''
        ) {
            toast.info('Required field missing')
            return;
        }

        let devices = []

        if( deviceAddingQuantityType === 'single' ) {
            if( remainingQuota > 0 ) {
                devices.push({
                    hostname: (addDeviceFormData?.hostname?.value as string).trim(),
                    policy_id: addDeviceFormData?.policy?.value?.id,
                    strategy_id: addDeviceFormData?.strategy?.value?.id
                })
            }
        } else if( deviceAddingQuantityType === 'multiple') {
            let arrDevices: string[] = Array.isArray(addDeviceFormData?.hostname?.value) ? addDeviceFormData?.hostname?.value : [];
            (arrDevices).forEach((device: any) => {
                console.log({device})
                devices.push({
                    hostname: (device as string).trim(),
                    policy_id: addDeviceFormData?.policy?.value?.id,
                    strategy_id: addDeviceFormData?.strategy?.value?.id
                })
            });
        }

        addDevice(devices).then((data)=>{
            if( deviceAddingQuantityType === 'single' ) {
                if( data.endpoints[0].data.data[0] === 0 ) {
                    toast.success('Device Added successfully')
                    resetAddDeviceForm();

                } else {
                    toast.warning('Fails to add Device')
                }
            } else if( deviceAddingQuantityType === 'multiple' ) {
                let finalStatus = data.endpoints.reduce((a:any,d:any)=>{
                    a.total = a.total + 1;
                    if( d.data.data[0] === 0 ) {
                        a.added = a.added + 1;
                    } else {
                        a.fails = a.fails + 1;
                    }
                    return a
                },{total:0, added:0, fails:0});
                if( finalStatus.added > 0 ) {
                    toast.success(`${finalStatus.added}/${finalStatus.total} devices added successfully.`);
                    if( finalStatus.fails > 0 ) {
                        toast.info(`Fails to add ${finalStatus.fails} devices`);
                    } else {
                        resetAddDeviceForm()
                    }
                } else {
                    toast.error(`Fails to add devices`);
                }
            }
        }).catch((error)=>{}).finally()
    }

    useEffect(()=>{

        if( deviceAddingQuantityType === 'single' ) {
            setAddDeviceFormData((prevState)=>{
                let newState = {...prevState}
                newState.hostname = {
                    value: '',
                    error: null
                }
                return newState;
            })
        }

        if( deviceAddingQuantityType === 'multiple' ) {
            setAddDeviceFormData((prevState)=>{
                let newState = {...prevState}
                newState.hostname = {
                    value: [],
                    error: null
                }
                return newState;
            })
        }
        
    },[deviceAddingQuantityType])

    const resetAddDeviceForm = () => {

        setResetHostnameDropdown(!resetHostnameDropdown)
        setResetPolicyDropdown(!resetPolicyDropdown)
        setResetStrategyDropdown(!resetStrategyDropdown)

        setAddDeviceFormData({
            hostname: {
                value: deviceAddingQuantityType === 'multiple' ? [] : '',
                error: null
            },
            policy: {
                value: {id: null},
                error: null
            },
            strategy: {
                value: {id: null},
                error: null
            }
        });
    }
    

    return (
        <>
            <div className="h-full flex flex-col">
                <div className="title w-full flex gap-4 px-6 top-0 pt-10 border-b pb-10 bg-slate-100 items-center">
                    {/* Icon */}
                    <i className="fa-regular fa-tv text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i>

                    {/* title */}
                    <div className="flex flex-col">
                        <div className="text-xl font-bold text-slate-700">
                            Add Device
                        </div>
                        {/* Sub title */}
                        <div className="block text-slate-500">
                            <span>Add Endpoint Device<br/>
                            <span className='text-xs'>(Quota Left: {endpointQuota - ( (endpointData?.installed || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos === null }).length + (endpointData?.pending_installation || []).length + ((endpointData?.installed || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos !== null && [4,6].includes(ep?.agent_profile?.BlockListInfos?.Reason) }).length) ) } endpoints)</span></span>
                        </div>
                    </div>
                </div>
                <div className="data grow px-4 w-full customScroll">
                    <div className='flex w-full my-4'>
                        <Button 
                            size='sm' 
                            solid={deviceAddingQuantityType==='single' ? true : false}
                            className='rounded-r-none'
                            onClick={()=>{
                                setDeviceAddingQuantityType('single')
                            }}
                        >Single Device</Button>
                        <Button 
                            size='sm' 
                            solid={deviceAddingQuantityType==='multiple' ? true : false}
                            className='rounded-l-none'
                            onClick={()=>{
                                setDeviceAddingQuantityType('multiple')
                            }}
                        >Bulk Device</Button>
                        <span className='grow'></span>
                        {deviceAddingQuantityType === 'multiple' && <a href="/assets/files/bulk.csv" download={true}>
                            <Button
                                size='sm'
                            >
                                <em className='fa-solid fa-download'></em> File Format
                            </Button>
                        </a>}
                    </div>
                    <AddSingleDevice
                        deviceAddingQuantityType={deviceAddingQuantityType}
                        rawPolicyStrategy={rawPolicyStrategy}
                        policyStrategy={policyStrategy}
                        setAddDeviceFormData={setAddDeviceFormData}
                        addDeviceFormData={addDeviceFormData}
                        endpointConfig={endpointConfig}
                        handleAddDevice={handleAddDevice}

                        endpointQuota={endpointQuota}
                        endpointData={endpointData}

                        resetHostnameDropdown={resetHostnameDropdown}
                        setResetHostnameDropdown={setResetHostnameDropdown}
                        resetPolicyDropdown={resetPolicyDropdown}
                        setResetPolicyDropdown={setResetPolicyDropdown}
                        resetStrategyDropdown={resetStrategyDropdown}
                        setResetStrategyDropdown={setResetStrategyDropdown}
                    />

                </div>
            </div>
        </>
    )
}

interface IAddSingleDevice {
    deviceAddingQuantityType: string;
    rawPolicyStrategy: any;
    policyStrategy: any;
    setAddDeviceFormData: Dispatch<SetStateAction<any>>;
    addDeviceFormData: any;
    endpointConfig: any;
    handleAddDevice: ()=>void;

    endpointQuota?: number;
    endpointData?: any;

    
    resetHostnameDropdown: boolean;
    setResetHostnameDropdown: Dispatch<SetStateAction<boolean>>;
    resetPolicyDropdown: boolean;
    setResetPolicyDropdown: Dispatch<SetStateAction<boolean>>;
    resetStrategyDropdown: boolean;
    setResetStrategyDropdown: Dispatch<SetStateAction<boolean>>;
}

const AddSingleDevice:FC<IAddSingleDevice> = ({ 
    deviceAddingQuantityType,
    rawPolicyStrategy,
    policyStrategy,
    setAddDeviceFormData,
    addDeviceFormData,
    endpointConfig,
    handleAddDevice,

    endpointQuota,
    endpointData,

    resetHostnameDropdown,
    setResetHostnameDropdown,
    resetPolicyDropdown,
    setResetPolicyDropdown,
    resetStrategyDropdown,
    setResetStrategyDropdown,
}) => {

    const sideDrawerContext = useContext(SideDrawerContext);
    const [csvFile, setCsvFile] = useState<any>(null);
    const [csvFileText, setCsvFileText] = useState<string>('');
    const [arrHostNames, setArrHostNames] = useState<string[]>([]);
    const [remainingQuota, setRemainingQuota] = useState<number>(0);

    useEffect(()=>{
        let remainingDevices = (endpointQuota || 0) - ( (endpointData?.endpoints || []).length - (endpointData?.deleted || []).length )
        setRemainingQuota( remainingDevices )
    },[endpointQuota, endpointData])

    useEffect(()=>{
        if( !csvFile ) {
            return;
        }
        const fetchTextFromFile = async () => {
            const reader = new FileReader();
            reader.onload = (e:any) => {
                let text = e.target.result;
                setCsvFileText(text)
            }
            reader.onerror = () => {
                console.log(reader.error);
            }; 
            reader.readAsText(csvFile);
        }
        fetchTextFromFile();
    },[csvFile])

    useEffect(()=>{
        if( csvFileText === '' ) {
            return;
        }
        let csvProcessed = csvFileText.replace(/"/g,"").replace(/\r/g,"").replace(/\n/g,"|").split("|")
        csvProcessed = csvProcessed.splice(1,csvProcessed.length).filter(t=>t);

        let arrNonExistingAgents = [];

        let arrAgentName = (endpointData?.endpoints || []).map((ep:any)=>{ return ep.agent_profile.AdminInfos.Name })

        for (let i = 0; i < csvProcessed.length; i++) {
            const agent = csvProcessed[i];
            if( !arrAgentName.includes(agent) ) {
                arrNonExistingAgents.push(agent)
            }
        }

        let quotaLimitedAgents = []

        for (let i = 0; i < remainingQuota; i++) {
            if( arrNonExistingAgents[i] ) {
                quotaLimitedAgents.push(arrNonExistingAgents[i]);
            }
        }

        if( quotaLimitedAgents.length > remainingQuota ) {
            toast.info('Some or all devices might not be listed because Quota exceeds')
        }
        setArrHostNames(quotaLimitedAgents)
    },[csvFileText])
    
    return (
        <div className='flex flex-col gap-2 px-4 py-2 border rounded-md bg-slate-100 my-4'>
            {deviceAddingQuantityType === 'multiple' && (
                <>
                    <label className='gap-1 mt-2 font-semibold'>Upload File <span className='text-red-500'>*</span></label>
                    <div className='flex'>
                        <input type="text" 
                            className="py-2 px-4 outline-none grow text-gray-700 rounded-l-[5px] rounded-r-[0px] border" 
                            readOnly={true} 
                            value={csvFile ? csvFile?.name || '' : ''}
                            placeholder='Select a file'
                        />
                        <input
                            type='file'
                            accept='.csv'
                            id='csvFile'
                            className='uploadHostCSVFile w-[100px] rounded-r-[5px] bg-[#f97315] cursor-pointer'
                            onChange={(e:any) => {
                                console.log({e:e.target.value})
                                setCsvFile(e.target.files[0])
                            }}
                        />
                    </div>  
                </>
            )}
            <div className='w-full z-50'>
                <label htmlFor='hostname' className='flex flex-col gap-1 '>
                    {/* <span className='z-20'> */}
                    {deviceAddingQuantityType === 'multiple' && <Dropdown 
                        multiple={true}
                        required={true}
                        label='Hostname'
                        placeholder='Select Hostnames'
                        children='abc'
                        options={[...arrHostNames]}
                        onChange={(e)=>{
                            setAddDeviceFormData((prevState:any)=>{
                                let newState = {...prevState};
                                newState.hostname.value = e.target.value;
                                return newState
                            })
                            // let selectedPolicy = (policyStrategy.policyObj || []).find((p:any)=>{return p.name === e.target.value.split(') ')[1]})
                            // let selectedRawPolicy = (rawPolicyStrategy?.config?.current_policies || []).find((p:any)=>{ return p.Name === selectedPolicy.value })

                            // setAddDeviceFormData((prevState)=>{
                            //     let newState = {...prevState};
                            //     newState.policy.value = {selectedRawPolicy, ...selectedPolicy};
                            //     return newState
                            // })
                        }}
                        className=''
                        clearTrigger={resetHostnameDropdown}
                    />}
                    {/* </span> */}
                    {deviceAddingQuantityType === 'single' && <span className='relative font-semibold block py-2'>Hostname <span className='text-red-500'>*</span></span> }
                    {deviceAddingQuantityType === 'single' && <input 
                        id='hostname'
                        type="text"
                        placeholder='Hostname'
                        value={addDeviceFormData?.hostname?.value || ''}
                        onChange={(e)=>{
                            setAddDeviceFormData((prevState:any)=>{
                                let newState = {...prevState};
                                newState.hostname.value = e.target.value;
                                newState.hostname.error = null
                                return newState
                            })
                        }}
                        className='w-full border rounded-md px-4 py-2 outline-none'
                    />}
                </label>
                { addDeviceFormData.hostname.error && <small className='text-red-500'>{addDeviceFormData.hostname.error}</small> }
            </div>
            <div className='z-40'>
                <Dropdown
                    multiple={false}
                    required={true}
                    label='Strategy'
                    placeholder='Select Strategy'
                    children='abc'
                    options={[...(policyStrategy?.strategy || [])]}
                    onChange={(e)=>{
                        let selectedStrategy = (policyStrategy?.strategyObj || []).find((s:any)=>{ if(!Array.isArray(e?.target?.value || [])) { 
                            let temp = (e?.target?.value || ') ').toString().split(') ');
                            temp.shift();
                            temp = temp.join(') ').trim();
                            return s.name ===  temp;
                        }})
                        let selectedRawStrategy = (rawPolicyStrategy?.config?.current_strategies || []).find((s:any)=>{ return s.Name === selectedStrategy?.value })

                        setAddDeviceFormData((prevState:any)=>{
                            let newState = {...prevState};
                            newState.strategy.value = {...selectedRawStrategy, ...selectedStrategy};
                            newState.strategy.error = null
                            return newState
                        })
                    }}
                    className=''
                    clearTrigger={resetStrategyDropdown}
                />
                { addDeviceFormData.strategy.error && <small className='text-red-500'>{addDeviceFormData.strategy.error}</small> }
            </div>
            <div className='z-30'>
                <Dropdown 
                    multiple={false}
                    required={true}
                    label='Policy'
                    placeholder='Select Policy'
                    children='abc'
                    options={[...(policyStrategy?.policy || [])]}
                    onChange={(e)=>{
                        let selectedPolicy = (policyStrategy.policyObj || []).find((p:any)=>{if(!Array.isArray(e?.target?.value || [])) { 
                            // let temp = (e?.target?.value || ') ').toString().split(') ');
                            // temp.shift();
                            // temp = temp.join(') ').trim();
                            // return p.name = temp
                            return p.name === (e?.target?.value || ')').split(') ')[1]
                        }})
                        let selectedRawPolicy = (rawPolicyStrategy?.config?.current_policies || []).find((p:any)=>{ return p.Name === selectedPolicy?.value })

                        setAddDeviceFormData((prevState:any)=>{
                            let newState = {...prevState};
                            newState.policy.value = {...selectedRawPolicy, ...selectedPolicy};
                            newState.policy.error = null
                            return newState
                        })
                    }}
                    className=''
                    clearTrigger={resetPolicyDropdown}
                />
                { addDeviceFormData.policy.error && <small className='text-red-500'>{addDeviceFormData.policy.error}</small> }
            </div>
            {/* <div className='z-20'>
                <Dropdown 
                    multiple={false}
                    required={true}
                    label='Device Type'
                    placeholder='Select Device Type'
                    children='abc'
                    options={[]}
                    onChange={(e)=>{
                    }}
                    className=''
                    clearTrigger={resetPolicyDropdown}
                />
            </div>
            <div className='z-10'>
                <Dropdown 
                    multiple={false}
                    required={true}
                    label='Storage Pool'
                    placeholder='Select Storage Pool'
                    children='abc'
                    options={[]}
                    onChange={(e)=>{ }}
                    className=''
                    clearTrigger={resetPolicyDropdown}
                />
            </div> */}
            <div className='flex gap-4 mt-6 mb-4'>
                <Button
                    solid={false}
                    className='mt-2 grow bg-white'
                    onClick={()=>{
                        sideDrawerContext.setLevelTwoDrawerVisibility(false)
                        sideDrawerContext.setSecondDrawerChild(
                            <AddDevicePreview
                                policyStrategy={policyStrategy}
                                addDeviceFormData={addDeviceFormData} 
                                endpointConfig={endpointConfig}
                            />
                        )
                        sideDrawerContext.setLevelTwoDrawerVisibility(true)
                    }}
                >Preview</Button>
                <Button
                    solid={true}
                    className='mt-2 grow'
                    onClick={()=>{
                        handleAddDevice();
                    }}
                >Add</Button>
            </div>
            
        </div>
    )
}

interface IAddDevicePreview {
    policyStrategy: any;
    addDeviceFormData: any;
    endpointConfig: any
}

const AddDevicePreview:FC<IAddDevicePreview> = ({ policyStrategy, addDeviceFormData, endpointConfig }) => {
    return (
        <>
        <div className="h-full flex flex-col">
                <div className="title w-full flex gap-4 px-6 top-0 pt-10 border-b pb-10 bg-slate-100 items-center">
                    {/* Icon */}
                    <i className="fa-regular fa-tv text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i>

                    {/* title */}
                    <div className="flex flex-col">
                        <div className="text-xl font-bold text-slate-700">
                            Preview
                        </div>
                        {/* Sub title */}
                        <div className="block text-slate-500">
                            <span>Device hostname, Policy, Strategy details</span>
                        </div>
                    </div>
                </div>
                <div className="data grow px-4 w-full customScroll overflow-y-scroll p-4 ">
                    
                    <div className='w-full border rounded-lg shadow-md overflow-hidden'>
                        <div className='rounded-lg'>
                            <div className='border-b px-1 py-2 bg-slate-100 text-grey-700 text-center font-medium'>
                                <div>Hostname(s)</div>
                            </div>
                            <div className='border-b px-1 py-2'>
                                { Array.isArray(addDeviceFormData?.hostname?.value) && <div className='text-center text-[#ff5e0e]'>{addDeviceFormData?.hostname?.value.join(', ') || 'NA'}</div>}
                                { !Array.isArray(addDeviceFormData?.hostname?.value) && <div className='text-center text-[#ff5e0e]'>{addDeviceFormData?.hostname?.value || 'NA'}</div>}
                            </div>
                            <div className='border-b px-1 py-2 bg-slate-100 text-grey-700 text-center font-medium'>
                                <div>Strategy</div>
                            </div>
                            {
                                (
                                    Object.keys(addDeviceFormData?.strategy?.value).length > 0 
                                    // addDeviceFormData?.strategy?.value?.id !== null
                                ) ?
                                <>
                                    <div className='border-b px-3 py-2 flex flex-row w-full text-sm'>
                                        <div className="font-medium w-1/3">Property</div>
                                        <div className="w-2/3">Value</div>
                                    </div>
                                    <div className='border-b px-3 py-2 flex flex-row w-full text-sm'>
                                        <div className="font-medium w-1/3">Name</div>
                                        <div className="w-2/3">{addDeviceFormData?.strategy?.value?.name || 'NA'}</div>
                                    </div>
                                    <div className='border-b px-3 py-2 flex flex-row w-full text-sm'>
                                        <div className="font-medium w-1/3">Retention</div>
                                        <div className='w-2/3'>{addDeviceFormData?.strategy?.value?.ParamDataAging || 0} Days</div>
                                    </div>
                                    <div className='border-b px-3 py-2 flex flex-row w-full text-sm'>
                                        <div className="font-medium w-1/3">RPO</div>
                                        <div className='w-2/3' >{formatSeconds(addDeviceFormData?.strategy?.value?.ParamSchedule || 0).value} {formatSeconds(addDeviceFormData?.strategy?.value?.ParamSchedule || 0).unit}</div>
                                    </div>
                                    <div className='border-b px-3 py-2 flex flex-row w-full text-sm'>
                                        <div className="font-medium w-1/3">Raise Alert</div>
                                        <div className='w-2/3'>{formatSeconds(addDeviceFormData?.strategy?.value?.ParamAlertTime || 0).value} {formatSeconds(addDeviceFormData?.strategy?.value?.ParamAlertTime || 0).unit}</div>
                                    </div>
                                </> :
                                <div className='border-b px-1 py-2'>
                                    <div className='text-center text-[#ff5e0e]'>NA</div>
                                </div>
                            }
                            <div className='border-b px-1 py-2 bg-slate-100 text-grey-700 text-center font-medium'>
                                <div>Policy</div>
                            </div>
                            {
                                (
                                    Object.keys(addDeviceFormData?.policy?.value).length > 0
                                    // addDeviceFormData?.policy?.value?.id !== null
                                ) ?
                                <>
                                    <div className='border-b px-1 py-3 text-sm'>
                                        <div className='text-center text-[#ff5e0e]'>{addDeviceFormData?.policy?.value?.name || 'NA'}</div>
                                    </div>
                                    <div className='border-b px-1 py-2 bg-slate-100 text-grey-700 text-center font-medium'>
                                        <div>Locations</div>
                                    </div>
                                    <div className='border-b px-3 py-2 bg-slate-50 text-grey-500 font-medium text-sm flex flex-row'>
                                        <div className='w-36  '>Name</div>
                                        <div className='w-40 '>Path</div>
                                        <div className='w-40 '>Filter</div>
                                    </div>
                                    <div className='max-h-[200px] overflow-y-scroll customScroll w-full'>
                                        {/* {(endpointConfig?.path || []).length > 0 && (endpointConfig?.path || []).map((p:any, pIndex:number)=>(
                                            <div className='border-b px-3 py-2 flex flex-row text-sm font-normal' key={pIndex}>
                                                <div className='w-36 truncate'>{endpointConfig?.path[pIndex]?.path?.Name || ''}</div>
                                                <div className={`w-40  truncate`}>{endpointConfig?.path[pIndex]?.path?.Path || ''}</div>
                                                <div className="cell flex flex-col grow w-40 text-left mx-2">
                                                    <div className=''>{endpointConfig?.path[pIndex]?.extensions?.Behavior || endpointConfig?.path[pIndex]?.filter?.comment || ''}</div>
                                                    <div className=''>{
                                                        (endpointConfig?.path[pIndex]?.extensions?.Ext || []).length > 0 ?
                                                        (endpointConfig?.path[pIndex]?.extensions?.Ext || []).map((ext:string)=>(<div className='bg-[#FF5E0E] bg-opacity-10 p-1 rounded mb-1 text-xs text-[#FF5E0E] inline mr-2 float-left'>${ext}</div>)) :
                                                        <div className='bg-[#FF5E0E] bg-opacity-10 p-1 rounded mb-1 text-xs text-[#FF5E0E] inline mr-2 float-left'>All Extensions</div>
                                                    }</div>
                                                </div>
                                            </div>
                                        ))} */}

                                        {(endpointConfig?.path || []).map((pathObj:any, pathIndex:number) => {

                                            if( (pathObj?._ID || []).length < 1 ) {
                                                return (
                                                    <div key={pathIndex} className="border-b px-3 py-2 flex flex-row text-sm font-normal">
                                                        <div className="w-36 whitespace-pre-wrap">{pathObj?.path?.Name || 'NA'}</div>
                                                        <div className="w-40 truncate">{pathObj?.path?.Path || 'NA'}</div>
                                                        <div className="cell flex flex-col grow w-40 text-left mx-2">
                                                            {pathObj?.ID && <div className="">{ pathObj?.filter?.comment || 'NA' } {pathObj?.extensions?.Behavior === 'INCL' && '[Inclusion]'}{pathObj?.extensions?.Behavior === 'EXCL' && '[Exclusion]'}</div>}
                                                            <span>
                                                                <div className="inline-block px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 text-xs font-medium">
                                                                    { Object.keys( endpointConfig?.profile?.RuleArray || {}).length > 0 ? 'All Extensions' : 'No Rule Applied' } 
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            } else if( (pathObj?._ID || []).length > 0 ) {
                                                return (pathObj?._ID || []).map((item: any, itemIndex: number)=>(
                                                    <div key={pathIndex} className="border-b px-3 py-2 flex flex-row text-sm font-normal">
                                                        <div className="w-36 whitespace-pre-wrap">{pathObj?.path?.Name || 'NA'}</div>
                                                        <div className="w-40 truncate">{pathObj?.path?.Path || 'NA'}</div>
                                                        <div className="cell flex flex-col grow w-40 text-left mx-2">
                                                            <div className="">{ pathObj?.filter?.comment || 'NA' } {(pathObj?.extensions?.Behavior === 'INCL' || endpointConfig?.extensions[item]?.Behavior === 'INCL') && '[Inclusion]'}{(pathObj?.extensions?.Behavior === 'EXCL' || endpointConfig?.extensions[item]?.Behavior === 'EXCL' ) && '[Exclusion]'}</div>
                                                            <div className="mb-2">({ endpointConfig?.extensions[item]?.Name || '' })</div>
                                                            <div>
                                                                {(endpointConfig.extensions[item]?.Ext || []).map((extItem:string, extIndex: number)=>{
                                                                    return (
                                                                        <div 
                                                                            key={`${pathIndex}_${itemIndex}_${extIndex}`} 
                                                                            className="float-left px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 mb-1 text-xs font-medium"
                                                                        >
                                                                            {extItem}
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        })}
                                    </div>
                                    {(endpointConfig?.path || []).length < 1 && 
                                        <div className='border-b px-1 py-2'>
                                            <div className="w-full text-center text-[#ff5e0e]">No Data</div>
                                        </div>
                                    }
                                    {/* <div className='border-b px-1 py-2 bg-slate-100 text-grey-700 text-center font-medium'>
                                        <div className=''>File Extensions</div>
                                    </div>
                                    <div className='border-b px-1 py-2 bg-slate-50 text-grey-500 text-sm font-medium'>
                                        <div className='px-3'>Included Under Policy</div>
                                    </div>
                                    <div className='border-b px-1 py-2'>
                                        <div className="text-center text-sm text-[#ff5e0e]">{(endpointConfig?.category?.INCL || []).length > 0 ? (endpointConfig?.category?.INCL || []).join(', ') : ((endpointConfig?.path || []).filter((p:any)=>{return p.filter.comment==="All included at this path"}).length > 0 ? 'All extensions included'  : 'No Extensions')}</div>
                                    </div>
                                    <div className='border-b px-1 py-2 bg-slate-50 text-grey-500 text-sm font-medium'>
                                        <div className='px-3'>Excluded Under Policy</div>
                                    </div>
                                    <div className='border-b px-1 py-2'>
                                        <div className="text-center text-sm text-[#ff5e0e]">
                                            {(endpointConfig?.category?.EXCL || []).length > 0 ? 
                                            (endpointConfig?.category?.EXCL || []).join(', ') : 
                                            'No Extensions'}
                                        </div>
                                    </div>   */}
                                </> :
                                <div className='border-b px-1 py-2'>
                                    <div className='text-center text-[#ff5e0e]'>NA</div>
                                </div>
                            }
                            {/* <div className='border-b px-1 py-2 bg-slate-100 text-grey-700 text-center font-medium'>
                                <div>Storage Pool</div>
                            </div>
                            <div className='border-b px-1 py-2'>
                                <div className='text-center text-[#ff5e0e]'>NA</div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddDevice;