import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../contexts";
// import Tile3x3_Text from "../../components/applicationComponents/Tiles/Tile3x3_Text/Tile3x3_Text";
// import Tile5x4_ChartText from "../../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText";
import { Breadcrumb, Tile } from "../../components/core";
// import { Table } from "../../components/core/Table";
// import { ITableColumnItem, ITableControllerProps } from "../../components/core/Table/Table";
// import Switch from "../../components/core/Switch/Switch";
import { getAPICall } from "../../services/APILayer/axiosMethodCalls";

const Organization = () => {

    // const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    const globalContext = useContext(GlobalContext);
    // const navRaf = useRef<HTMLDivElement>(null)
    const [myOrg, setMyOrg] = useState<any>({})

    const fetchMyOrgDetails = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/crm/companies/me`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if(Object.keys(myOrg).length > 0) {
            return
        }
        const getMyOrgDetails = () => {
            fetchMyOrgDetails().then(company=>{
                // console.log(company)
                // @TODO Pick from context
                setMyOrg(company)
            })
            .catch(err=>console.log(err));
        }
        getMyOrgDetails();
    },[myOrg]);

    return (
        <>
            <div className={`p-6 grow flex flex-col`}>    
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2">My Organization</h1>
                <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-home', link: '', name: 'Home' },
                    { icon: '', link: '/settings', name: 'Settings' },
                    { icon: '', link: '/settings/organization', name: 'My Organization' }
                ]} /> 

                <div className="col-span-12 text-lg font-bold mb-6 space-y-6">
                    <div className={`page w-full min-h-max`}>
                        <div className="flex flex-row border-dashed border border-[#ff5e0e] rounded-md bg-slate-50 w-full items-center">
                            <i className="fa-solid fa-star hidden md:block items-center w-8 h-8 align-middle m-6 text-3xl text-[#ff5e0e]"></i>
                            <div className="flex flex-col space-y-1 m-6 md:m-0 ">
                                <span className="text-gray-700 font-semibold">Welcome {myOrg?.company?.properties?.name || 'your organization'}, <span className="capitalize">{globalContext?.userData.nickname}</span></span>
                                <span className="flex text-sm text-gray-500">Get started with the option below or try quick workflows to get in action instantly</span>
                            </div>
                            <div className="flex grow"></div>
                            <i className="fa-solid fa-xmark text-xl text-[#ff5e0e] bg-orange-50 w-5 h-5 p-4 mr-6 flex items-center hover:bg-orange-100 justify-center cursor-pointer" onClick={()=>{}}></i>
                        </div>
                    </div>
                    {/* <div className={`page w-full min-h-max`}>
                        <div className="flex flex-row border-dashed border border-red-600 rounded-md bg-slate-50 w-full items-center">
                            <i className="fa-solid fa-exclamation flex items-center w-9 h-9 align-middle m-6 text-2xl justify-center text-red-600 bg-red-100 rounded-full"></i>
                            <div className="flex flex-col space-y-1">
                                <span className="text-red-600 font-semibold">1 license[s] expired</span>
                                <span className="flex text-sm text-red-500">Get started with the option below or try quick workflows to get in action instantly</span>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="grow flex flex-col md:flex-row gap-6 w-full">
                    <div className="md:w-4/12 w-full">
                        <Tile className="h-[600px] overflow-hidden" colSpan={12} rowSpan={22}> 
                            {/* <div className="flex flex-col w-full">
                                <div className="inset flex align-middle justify-center items-center ">
                                    <div className="relative grow max-w-[256px] flex flex-col border rounded-md gap-4 justify-center align-middle items-center text-[#FF5E0E] bg-slate-100 h-64 m-4">
                                        <em className="fa-thin fa-buildings text-8xl"></em> */}
                                        {/* <div className="text-xl">Atempo SAS</div> */}
                                        {/* <div className="flex items-center justify-center align-middle rounded-md bg-green-200 text-green-600 px-6 py-1">Basic</div> */}
                                        {/* <em className="fa-solid fa-circle-check text-2xl absolute inline ml-2 text-green-500 top-4 right-4"></em> */}
                                    {/* </div>
                                </div>
                                <div className="details border-t">
                                    <div className="flex items-center px-4 border-b py-2">
                                        <em className="fa-regular fa-buildings text-2xl text-[#FF5E0E]"></em>
                                        <div className="flex flex-col ml-4">
                                            <div className="text-gray-500">Organization</div>
                                            <div className="">Atempo SAS <em className="fa-solid fa-circle-check text-2xl inline ml-2 text-green-500"></em></div>
                                        </div>
                                    </div>
                                    <div className="flex items-center px-4 border-b py-2">
                                        <em className="fa-regular fa-handshake text-2xl text-[#FF5E0E]"></em>
                                        <div className="flex flex-col ml-4">
                                            <div className="text-gray-500">Membership Type</div>
                                            <div className="">Basic</div>
                                        </div>
                                    </div>
                                    <div className="flex items-center px-4 border-b py-2">
                                        <em className="fa-regular fa-user text-2xl text-[#FF5E0E]"></em>
                                        <div className="flex flex-col ml-4">
                                            <div className="text-gray-500">User Type</div>
                                            <div className="">Customer</div>
                                        </div>
                                    </div>
                                    <div className="flex items-center px-4 border-b py-2">
                                        <em className="fa-regular fa-location text-2xl text-[#FF5E0E]"></em>
                                        <div className="flex flex-col ml-4">
                                            <div className="text-gray-500">Location</div>
                                            <div className="">Noida, UP</div>
                                        </div>
                                    </div>
                                    <div className="flex items-center px-4 border-b py-2">
                                        <em className="fa-regular fa-envelope text-2xl text-[#FF5E0E]"></em>
                                        <div className="flex flex-col ml-4">
                                            <div className="text-gray-500">Email</div>
                                            <div className="">abc+xyz@zeeq.io</div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="flex flex-col justify-center items-center align-middle text-center p-8 w-full">
                                <div className="flex flex-col md:flex-row justify-center">
                                    {myOrg?.company?.properties?.company_logo && <img src={myOrg?.company?.properties?.company_logo || ''} alt="company logo" className="grow md:w-64 h-64 flex justify-center items-center rounded-md"/>}
                                    {!myOrg?.company?.properties?.company_logo && <i className="fa-thin fa-buildings grow md:w-64 h-64 p-2 text-8xl  text-[#FF5E0E] flex justify-center items-center rounded-md"/>}
                                </div>
                                <div className="font-semibold flex flex-col items-center mb-8 align-middle justify-center text-center w-full text-gray-700">
                                    <div className="flex flex-row items-left text-xl mb-2 items-center">{myOrg?.company?.properties?.name || ''}<i className="fa-solid fa-check bg-green-400 text-white  text-sm font-normal w-6 h-6 flex justify-center items-center text-center ml-2 rounded-full"/></div>
                                    <div className="bg-orange-400 text-orange-600 bg-opacity-20 text-sm font-normal  flex justify-center items-center text-center px-8 py-1 mt-2 rounded-sm">
                                        {myOrg?.company?.properties?.type === 'PROSPECT' && 'End Customer'}
                                        {myOrg?.company?.properties?.type === 'PARTNER' && 'Partner'}
                                        {myOrg?.company?.properties?.type === 'VENDOR' && 'Super Admin'}
                                    </div>
                                    <div className="flex flex-col text-gray-700 items-left justify-start text-left gap-3 mt-6 py-6  border-t border-[#ff5e0e] w-full text-[18px] font-medium">
                                        {/* <div><i className="inline fa-solid fa-dice-d10 text-[#ff5e0e]"></i><span className="inline text-gray-600 ml-2">{myOrg?.company?.properties?.type || ''}</span></div> */}
                                        <div><i className="inline fa-solid fa-location  text-[#ff5e0e]"></i><span className="inline text-gray-600 ml-3">{myOrg?.company?.properties?.city} {myOrg?.company?.properties?.stats && `, ${myOrg?.company?.properties?.stats}`} {myOrg?.company?.properties?.country && `, ${myOrg?.company?.properties?.country}`} {myOrg?.company?.properties?.zip && `, ${myOrg?.company?.properties?.zip}`}</span></div>
                                        <div><i className="inline fa-regular fa-envelope  text-[#ff5e0e]"></i><span className="inline text-gray-600 ml-2">{(myOrg?.company?.properties?.company_email || '').split(',')[0]}</span></div>
                                    </div>
                                </div>
                            </div>
                        </Tile>
                    </div>
                    <div className="page grid grid-cols-6 gap-6 w-full min-h-max">
                        <Tile colSpan={8} rowSpan={22} className="">
                            <div className="border-b pt-6 px-6 text-lg font-semibold pb-4">Organization Information</div>
                            <div className={`page mx-6 mt-4 min-h-max `}>
                                <div className="flex flex-row border-dashed border border-red-600 rounded-md bg-slate-50 w-full items-center">
                                    <div className="hidden lg:block">
                                        <i className="fa-solid fa-exclamation flex items-center w-9 h-9 align-middle m-4 text-2xl justify-center text-[#ff5e0e] bg-orange-100 rounded-full"></i>
                                    </div>
                                    <div className="flex flex-row text-sm text-gray-600 items-center p-4 lg:p-0 ">
                                        <div>customer details will receive a privacy audit. For more info, please read our</div>
                                        <div className="text-[#ff5e0e] hover:border-b hover:border-[#ff5e0e] cursor-pointer whitespace-nowrap text-sm ml-1">Privacy Policy</div>
                                    </div>
                                </div>
                            </div>
                            <div className={`text-base font-medium text-gray-700 px-6 pb-6`}>
                                <div className="text-gray-500 flex flex-col mt-6 text-sm">
                                    <label className="mb-2">Name</label>
                                    <input readOnly={true} type="text" defaultValue={myOrg?.company?.properties?.name || ''} className="p-2 px-4 bg-slate-100 outline-none rounded-md"/>
                                </div>
                                <div className="text-gray-500 flex flex-col mt-6 text-sm">
                                    <label className="mb-2">Account ID</label>
                                    <input readOnly={true} type="text" defaultValue={myOrg?.company?.id || ''} className="p-2 px-4 bg-slate-100 outline-none rounded-md"/>
                                </div>

                                <div className="text-gray-500 flex flex-col mt-6 text-sm">
                                    <label className="mb-2">Logo Url</label>
                                    <input readOnly={true} type="text" defaultValue={myOrg?.company?.properties?.company_logo || ''} className="p-2 px-4 bg-slate-100 outline-none rounded-md"/>
                                </div>

                                <div className="text-gray-500 flex flex-col mt-6 text-sm">
                                    <label className="mb-2">Email<i className="fa-solid fa-circle-info ml-2 cursor-pointer text-sm"/></label>
                                    <input readOnly={true} type="text" 
                                        defaultValue={(myOrg?.company?.properties?.company_email || '')}
                                        className="p-2 px-4 bg-slate-100 outline-none rounded-md"/>
                                    </div>
                                <div className="text-gray-500 flex flex-col mt-6 text-sm">
                                    <label className="mb-2">Description</label>
                                    <textarea readOnly={true} defaultValue={myOrg?.company?.properties?.description || ''} className="p-2 px-4 bg-slate-100 outline-none rounded-md">
                                    </textarea>
                                </div>
                                {/* <div className="w-full flex flex-row justify-start  py-6"> */}
                                    {/* <Button className="bg-gray-500 border-gray-500 hover:bg-gray-600 hover:border-gray-600" variant="primary" size="md">Discard</Button> */}
                                    {/* <Button size="md" className="px-8" disabled={true}>Save changes</Button>
                                </div> */}
                            </div>
                        </Tile>
                        <Tile colSpan={8} className="mb-6 lg:h-[460px] h-[640px]">
                            <div className="border-b pt-6 px-6 text-lg font-semibold pb-4 w-full">Billing Information</div>
                            <div className={`text-base font-medium text-gray-700 px-6 pb-6`}>
                                <div className={`pb-6}`}>
                                    <div className="text-gray-500 flex flex-col mt-6 text-sm">
                                        <label className="mb-2">Address Line 1</label>
                                        <input readOnly={true} type="text" defaultValue={myOrg?.company?.properties?.address || ''} className="p-2 px-4 bg-slate-100 outline-none rounded-md"/>
                                    </div>
                                    <div className="text-gray-500 flex flex-col mt-6 text-sm">
                                        <label className="mb-2">Address Line 2</label>
                                        <input readOnly={true} type="text" defaultValue={myOrg?.company?.properties?.address2 || ''} className="p-2 px-4 bg-slate-100 outline-none rounded-md"/>
                                    </div>
                                    <div className="text-gray-500 flex flex-col lg:flex-row mt-6 w-full gap-6">
                                        <div className="text-gray-500 flex flex-col text-sm w-full">
                                            <label className="mb-2">Country</label>
                                            <input readOnly={true} type="text" defaultValue={myOrg?.company?.properties?.country || ''} className="p-2 px-4 bg-slate-100 outline-none rounded-md" />
                                        </div>
                                        <div className="text-gray-500 flex flex-col text-sm w-full">
                                            <label className="mb-2">City</label>
                                            <input readOnly={true} type="text" defaultValue={myOrg?.company?.properties?.city || ''} className="p-2 px-4 bg-slate-100 outline-none rounded-md" />
                                        </div>
                                    </div>
                                    <div className="text-gray-500 flex flex-col lg:flex-row mt-6 w-full gap-6">
                                        <div className="text-gray-500 flex flex-col text-sm w-full">
                                            <label className="mb-2">State / Province</label>
                                            <input readOnly={true} type="text" defaultValue={myOrg?.company?.properties?.state || ''} className="p-2 px-4 bg-slate-100 outline-none rounded-md" />
                                        </div>
                                        <div className="text-gray-500 flex flex-col text-sm w-full">
                                            <label className="mb-2">ZIP Code</label>
                                            <input readOnly={true} type="text" defaultValue={myOrg?.company?.properties?.zip || ''} className="p-2 px-4 bg-slate-100 outline-none rounded-md" />
                                        </div>
                                    </div>
                                    {/* <div className="w-full flex flex-row justify-start  py-6"> */}
                                        {/* <Button className="bg-gray-500 border-gray-500 hover:bg-gray-600 hover:border-gray-600" variant="primary" size="md">Discard</Button> */}
                                        {/* <Button size="md" className="px-8" disabled={true}>Save changes</Button>
                                    </div> */}
                                </div>
                            </div>
                        </Tile>
                        {/* <Tile colSpan={8} rowSpan={40} className="mb-6">
                            <div className="flex border-b pt-6 px-6 text-lg font-semibold pb-4">
                                <div className="title">Billing Information</div>
                                <div className="grow"></div>
                                <div className="flex">
                                    <label className="text-gray-500 text-sm">Same as Shipping address</label>
                                    <Switch switchHandler={()=>{}} className="ml-2" />
                                </div>
                            </div>
                            <div className={`text-base font-medium text-gray-700 px-6`} >
                                    <div className="text-gray-500 flex flex-col mt-6 text-sm">
                                        <label className="mb-2">Address Line 1</label>
                                        <input type="text" className="p-2 px-4 bg-slate-100 outline-none rounded-md"/>
                                    </div>
                                    <div className="text-gray-500 flex flex-col mt-6 text-sm">
                                        <label className="mb-2">Address Line 2</label>
                                        <input type="text" className="p-2 px-4 bg-slate-100 outline-none rounded-md"/>
                                    </div>
                                    <div className="text-gray-500 flex flex-row mt-6 w-full gap-6">
                                        <div className="text-gray-500 flex flex-col text-sm w-full">
                                            <label className="mb-2">Country</label>
                                            <input type="text" className="p-2 px-4 bg-slate-100 outline-none rounded-md" />
                                        </div>
                                        <div className="text-gray-500 flex flex-col text-sm w-full">
                                            <label className="mb-2">City</label>
                                            <input type="text" className="p-2 px-4 bg-slate-100 outline-none rounded-md" />
                                        </div>
                                    </div>
                                    <div className="text-gray-500 flex flex-row mt-6 w-full gap-6">
                                        <div className="text-gray-500 flex flex-col text-sm w-full">
                                            <label className="mb-2">State / Province</label>
                                            <input type="text" className="p-2 px-4 bg-slate-100 outline-none rounded-md" />
                                        </div>
                                        <div className="text-gray-500 flex flex-col text-sm w-full">
                                            <label className="mb-2">ZIP Code</label>
                                            <input type="text" className="p-2 px-4 bg-slate-100 outline-none rounded-md" />
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-row justify-start py-6">
                                        <Button size="md" className="px-8" disabled={true}>Save changes</Button>
                                    </div>
                            </div>
                        </Tile> */}
                    </div>
                </div>
            </div>            
        </>
    );
}


export default Organization;