import { deviceSymbol, getDeviceStatus } from "../agentUtils"

const sortNumbers = (a:any, b:any) => {
    if( a > b ) {
        return 1
    } else if( a < b ) {
        return -1
    } else {
        return 0
    }
}

const sortDates = (a:any, b:any) => {
    if( new Date(a) > new Date(b) ) {
        return 1
    } else if( new Date(a) < new Date(b) ) {
        return -1
    } else {
        return 0
    }
}

const SortWithDeviceId = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = Number(a?.agent_id);
        const symbolB = Number(b?.agent_id);

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}
const SortWithHostname = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = a?.agent_profile?.AdminInfos?.Name;
        const symbolB = b?.agent_profile?.AdminInfos?.Name;

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA.localeCompare(symbolB);
        } else if ( order === 'DESC' ) {
            return symbolB.localeCompare(symbolA);
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}
const SortWithOS = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = deviceSymbol(a?.agent_profile?.AgentInfos?.System, 'string') as string;
        const symbolB = deviceSymbol(b?.agent_profile?.AgentInfos?.System, 'string') as string;

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA.localeCompare(symbolB);
        } else if ( order === 'DESC' ) {
            return symbolB.localeCompare(symbolA);
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}
const SortWithDeviceStatus = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[],  endpointData: any ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = getDeviceStatus(endpointData, a) as string;
        const symbolB = getDeviceStatus(endpointData, b) as string;

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA.localeCompare(symbolB);
        } else if ( order === 'DESC' ) {
            return symbolB.localeCompare(symbolA);
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}
const SortWithLastConnection = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = Number(a?.agent_profile?.NetworkInfos?.LastConnectionTime);
        const symbolB = Number(b?.agent_profile?.NetworkInfos?.LastConnectionTime);

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}
const SortWithLastBackup = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = Number(a?.agent_profile?.NetworkInfos?.LastCompletedSession);
        const symbolB = Number(b?.agent_profile?.NetworkInfos?.LastCompletedSession);

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })
}
const SortWithProtectedData = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = Number(a?.agent_profile?.ServerInfos?.Backup?.ProtSize);
        const symbolB = Number(b?.agent_profile?.ServerInfos?.Backup?.ProtSize);

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })

    // tableData[0].agent_profile.ServerInfos.Backup.ProtSize
}
const SortWithReplicationLag = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = !a?.agent_profile?.RepliInfos?.RepliLag ? 0 : Number(a?.agent_profile?.RepliInfos?.RepliLag);
        const symbolB = !b?.agent_profile?.RepliInfos?.RepliLag ? 0 : Number(b?.agent_profile?.RepliInfos?.RepliLag);

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })

    // tableData[0].agent_profile.ServerInfos.Backup.ProtSize
}
const SortWithLastReplicated = ( order: 'ASC'|'DESC'|'NEUTRAL', dataArray: {[key: string]: any}[] ) => {
    return dataArray.sort((a:any, b:any) => {
        const symbolA = !a?.agent_profile?.RepliInfos?.RepliLastPIT ? 0 : Number(a?.agent_profile?.RepliInfos?.RepliLastPIT);
        const symbolB = !b?.agent_profile?.RepliInfos?.RepliLastPIT ? 0 : Number(b?.agent_profile?.RepliInfos?.RepliLastPIT);

        if ( order === 'ASC' || order === 'NEUTRAL' ) {
            return symbolA - symbolB;
        } else if ( order === 'DESC' ) {
            return symbolB - symbolA;
        } else {
            throw new Error('Invalid direction. Please use "ASC" or "DESC".');
        }
    })

    // tableData[0].agent_profile.ServerInfos.Backup.ProtSize
}

export {
    sortNumbers,
    sortDates,

    SortWithDeviceId,
    SortWithHostname,
    SortWithOS,
    SortWithDeviceStatus,
    SortWithLastConnection,
    SortWithLastBackup,
    SortWithProtectedData,
    SortWithReplicationLag,
    SortWithLastReplicated
}