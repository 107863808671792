import React, { FC, ReactNode, ComponentProps, useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../contexts";


interface IBreadcrumbProps extends ComponentProps<"button">  {
    arrBreadcrumb: {name: string, icon: string, link: string}[],
    /**
     * Child node(s) of button
     */
    children?: ReactNode | ReactNode[] | string;
}

/**
 * Breadcrumb Component
 * @param param0 
 * @returns 
 */
const Breadcrumb:FC<IBreadcrumbProps> = ({
    arrBreadcrumb,
    ...rest
}: IBreadcrumbProps) => {

    const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};

    return (
        <>
            <div className={`breadcrumb flex w-[460px] lg:min-w-max pt-1 pb-6 gap-4 col-span-12 text-sm font-medium px-6 whitespace-nowrap overflow-x-scroll hideScroll`}>
                {arrBreadcrumb.map((breadcrumbItem, breadcrumbItemIndex)=>(<>
                        {breadcrumbItemIndex === 0 && <span className="flex gap-4 items-center" key={breadcrumbItemIndex}><Link to={breadcrumbItem.link} ><em className={`fa-solid fa-home cursor-pointer text-${theme.themeColor}-${theme.themeContrast}-primary-default `}></em></Link></span>}
                        {breadcrumbItemIndex !== 0 && <span className="flex gap-4 items-center" key={breadcrumbItemIndex}>
                            <em className={`dash fa-solid fa-chevron-right text-${theme.themeColor}-${theme.themeContrast}-primary-default cursor-pointer`}></em>
                            <Link to={`${breadcrumbItem.link}`} ><div className={`flex gap-2 cursor-pointer ${ (breadcrumbItemIndex === (arrBreadcrumb.length-1)) ? 'opacity-50' : 'opacity-70' }`}>{ breadcrumbItem.icon !== '' && <em className={`${breadcrumbItem.icon}`}></em> } {breadcrumbItem.name}</div></Link>
                        </span>}
                </>))}
            </div>
            {/* <div className={`breadcrumb flex gap-4 col-span-12 text-sm font-medium px-6 mb-6`}>
                {arrBreadcrumb.map((breadcrumbItem, breadcrumbItemIndex)=>(
                    <span key={breadcrumbItemIndex}>
                        {breadcrumbItemIndex === 0 && <span className="flex gap-4" ><em className={`fa-solid fa-home cursor-pointer text-${theme.themeColor}-${theme.themeContrast}-primary-default `}></em></span>}
                        {breadcrumbItemIndex !== 0 && <span className="flex gap-4">
                            <em className={`dash fa-solid fa-dash text-${theme.themeColor}-${theme.themeContrast}-primary-default cursor-pointer`}></em>
                            <div className={`flex gap-2 cursor-pointer ${ (breadcrumbItemIndex === (arrBreadcrumb.length-1)) ? 'opacity-50' : 'opacity-70' }`}>{ breadcrumbItem.icon !== '' && <em className={`${breadcrumbItem.icon}`}></em> } {breadcrumbItem.name}</div>
                        </span>  }
                    </span>
                ))}
                
            </div> */}
        </>
    );
}

export default Breadcrumb;
