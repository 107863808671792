import React, { FC, ReactNode, useContext, useState } from "react";
import { ThemeContext } from "../../../contexts";


interface IAccordionItemProps {
    /**
     * What will be the name title of accordion item?
     */
    title: ReactNode | ReactNode[] | string;
    state?: boolean;
    maxHeight?: string;
    /**
     * Child node(s) of button
     */
    children?: ReactNode | ReactNode[] | string;
    /**
     * Optional click handler
     */
    onClick?: () => void;
    className?: string;
    childClassName?: string;
}

/**
 * Accordion Component
 * h-[140px] h-[488px]
 * @param param0 
 * @returns 
 */
const AccordionItem:FC<IAccordionItemProps> = ({
    title='',
    state=false,
    maxHeight='140px',
    children=<></>,
    className='',
    childClassName='',
    ...rest
}: IAccordionItemProps) => {

    let theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};

    const [accordionItemState, setAccordionItemState] = useState(state);

    return (
        <div className={`accordion-item border-b`}>
            {/* <div className={`accordion-header flex h-10 w-full items-center px-4 cursor-pointer shadow bg-slate-200 hover:bg-slate-300 ${ theme.themeContrast === 'light' ? `bg-slate-200 hover:bg-slate-300` : 'bg-[#222222] hover:bg-[#444444]'}`} */}
            <div className={`accordion-header flex h-10 w-full items-center px-4 cursor-pointer shadow bg-[#f1f5f9] hover:bg-[#e2e8f0] ${className}`}
                onClick={()=>{
                    setAccordionItemState((prev)=>{
                        return !prev;
                    });
                }}
            >
                <div className={`accordion-title text-sm`}>{title}</div>
                <div className="grow"></div>
                <div>
                    <em className={`fa-solid ${accordionItemState ? 'fa-chevron-down' : 'fa-chevron-right'} text-[#f97315]`}></em>
                </div>
            </div>
            <div className={`accordion-body flex flex-col max-h-min bg-${theme.themeColor}-${theme.themeContrast}-background ${accordionItemState ? `h-[${maxHeight}]` : 'h-[0px]'} transition-[height] duration-200 ease-in-out overflow-hidden ${childClassName}`}>
                {children}
            </div>
        </div>
    );
}

export default AccordionItem;
