import React, { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import MasterChart, { IChartDatasetData } from "../../../Charts/ChartJS/CJMasterChart/MasterChart";
import { Tile } from "../../../core";

interface INavItem {
    name: string;
}

interface IProps {
    title?: string;
    navItems?: INavItem[];
    
    clickNavigate?: boolean;
    navigationPath?: string;

    tileAction?: boolean;
    actionHandler?: ()=>void;

    sideBar?: boolean;

    chartType?: "bar" | "line" | "pie" | "donut";
    chartLabels: string[];
    showLabels?: boolean;
    chartDataset: IChartDatasetData[];
    chartOptions?: object;
    inChartMain?: string;
    inChartSub?: string;

    sideDrawerComponent?: ReactNode | ReactNode[] | string;
}

const Tile58:FC<IProps> = ({
    title="",
    navItems=[],
    
    clickNavigate=false,
    navigationPath='/',
    
    tileAction=false,
    actionHandler=()=>{},
    
    chartType,
    chartLabels,
    showLabels=true,
    chartDataset,
    chartOptions,
    inChartMain='',
    inChartSub='',

    sideDrawerComponent=<></>,
}:IProps) => {

    const navigator = useNavigate();

    return (
        <>
            <Tile 
                rowSpan={13} 
                colSpan={3} 
                className={`p-0 ${ (clickNavigate === true || tileAction === true) ? 'cursor-pointer' : '' }`}
                onClick={()=>{
                    if(clickNavigate)
                        navigator(navigationPath);

                    if(tileAction)
                        actionHandler();
                }}
            >
                <div className="flex flex-col h-full mb-1 p-5 text-[#3A3541]">
                    <div className="flex flex-row justify-between w-full">
                        <div title={title} className={`text-base md:text-lg lg:text-xl leading-8 font-medium whitespace-nowrap truncate`}>{title}</div>
                            { navItems.length > 0 && (
                                <div className="regular group z-20">
                                    <button aria-label="more" className="flex w-5 h-5 rounded items-center justify-center hover:bg-slate-100">
                                        <i className="fa-regular fa-ellipsis-vertical"></i>
                                    </button>
                                    <div className="absolute hidden group-hover:block bg-white border shadow-md min-w-[200px] max-w-min max-h-[190px] overflow-y-scroll customScroll right-4 rounded-md">
                                        { navItems.map((item, itemIndex)=>(
                                            <div className="px-3 border-b py-2 whitespace-nowrap hover:bg-slate-100 cursor-pointer">{ item.name }</div>
                                        ))}
                                    </div>
                                </div>
                            )}
                    </div>
                    <div className="regular grow my-4 mt-4 mb-2 max-h-80 md:max-h-80 lg:max-h-80  items-center justify-center flex z-10 relative">
                        { chartType && <MasterChart
                            type={chartType}
                            labels={chartLabels}
                            datasets={chartDataset}
                            options={chartOptions}
                        />}
                        <div className="absolute flex flex-col justify-center items-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <div className="text-xl text-center font-medium">{inChartMain}</div>
                            <div className="text-xs text-center font-normal">{inChartSub}</div>
                        </div>

                    </div>
                    <div className="legends flex flex-row justify-center space-x-4 flex-wrap lg:-mt-4 2xl:mt-0">
                        { (showLabels === true && chartLabels.length > 0) && chartLabels.map((item, itemIndex)=>(
                            <div key={itemIndex} className="flex flex-row items-center text-xs whitespace-nowrap pb-1">
                                <i className="fa-solid fa-circle text-center justify-center items-start flex text-xs" style={{color: `${chartDataset[0].backgroundColor[itemIndex]}`}}></i>
                                <div className="ml-1">{item}</div>
                            </div>
                        )) }
                    </div>
                </div>
            </Tile>
        </>
    )
}

export default Tile58

