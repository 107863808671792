/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { useLocation, useNavigate } from "react-router-dom";

// import { LibraryContext } from "../contexts/LibraryContext/LibraryContext";

import { CustomizationContext } from "../contexts";
// import { SideDrawer, LevelOneDrawer, LevelTwoDrawer } from "../components/core/SideDrawer";
import { Breadcrumb } from "../components/core";
import AnyComponent from "../components/AnyComponent/AnyComponent";
// import { SidebarLibrary } from "../components/applicationComponents";
import { toast } from "react-toastify";

interface Props  {
    pageGroup: string;
    redirection?: any;
}

/**
 * bg-orange-light-background bg-orange-dark-background
 * bg-blue-light-background bg-blue-dark-background
 * border-b-orange-light-primary-default border-b-blue-light-primary-default
 * border-b-orange-dark-primary-default border-b-blue-dark-primary-default
 * hover:bg-orange-light-component-text hover:bg-blue-light-component-text
 * hover:bg-orange-dark-component-text hover:bg-blue-dark-component-text
 * h-[528px] h-[712px] h-[896px]
 * left-[0px] left-[480px] left-[960px]
 * left-[-480px] left-[0px] left-[480px]
 * left-[-960px] left-[480px] left-[0px]
 * ml-[0px] ml-[-480px] ml-[-960px]
 * @returns 
 */
const Customizable = ({
    pageGroup='',
    redirection={}
}: Props) => {
    let navigate = useNavigate();

    // const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    // const anyComponent = useContext(LibraryContext);

    const location = useLocation();
    const customElements = React.useContext(CustomizationContext);

    const [componentsState, setComponentsState] = useState(customElements.pageElements[pageGroup][location.pathname].components);

    // const [levelOneDrawerVisibility, setLevelOneDrawerVisibility] = useState(false);
    // const [levelTwoDrawerVisibility, setLevelTwoDrawerVisibility] = useState(false);

    useEffect(() => {
        if ((redirection?.value || false) === false){
            return;
        }
        return navigate(redirection?.url || '');
     },[redirection]);

    useEffect(()=>{
        setComponentsState(customElements.pageElements[pageGroup][location.pathname].components);
    },[location]);

    // const [firstSlider, setFirstSlider] = useState(0);

    const [dragCondition, setDragCondition] = useState(false)

    const dragStartStyle = 'grow flex border-2 border-gray-600 border-dotted bg-gray-50';
    const dragEndStyle = 'grow';

    return (
        <>
            <div className={`p-6 grow flex flex-col`}>
                
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2">{customElements.pageElements[pageGroup][location.pathname].title}</h1>
                <Breadcrumb arrBreadcrumb={customElements.pageElements[pageGroup][location.pathname].breadcrumb} />

                <div
                    className={`${dragCondition ? dragStartStyle :  dragEndStyle }`}
                    onDragEnd={(e)=>{
                        setDragCondition(false)
                        // setLevelOneDrawerVisibility(true);
                    }}
                    onDragEnter={(e)=>{
                        setDragCondition(true);
                        // setLevelOneDrawerVisibility(false);
                    }}
                    onDrop={(e)=>{
                        setDragCondition(false);
                        // setLevelOneDrawerVisibility(true);
                    }}
                    onDragOver={()=>{}}
                    onClick={()=>{
                        // setLevelOneDrawerVisibility(false);
                    }}
                >
                    {<ReactSortable
                        list={componentsState}
                        setList={setComponentsState}
                        className={`groupComponents grid grid-cols-12 gap-6 w-full min-h-max `}
                        group={{ name: 'groupComponents', pull: false, put: true}}
                        animation={200}
                        delayOnTouchOnly={true}
                        delay={2}
                        style={{}}
                        onClone={(e)=>{
                        }}
                        onAdd={(e)=>{
                            toast.success(`Component '${e.item.outerText.split('\n')[0]}' added to '${customElements.pageElements[pageGroup][location.pathname].title}' page.`)
                        }}
                    >
                        {componentsState.map((item:any, itemIndex:any)=>(
                            <AnyComponent key={itemIndex} item={item}/>
                        ))}
                    </ReactSortable>}
                </div>

                {/* <SideDrawer
                        levelOneDrawerVisibility={levelOneDrawerVisibility}
                        setLevelOneDrawerVisibility={setLevelOneDrawerVisibility}
                        levelTwoDrawerVisibility={levelTwoDrawerVisibility}
                        setLevelTwoDrawerVisibility={setLevelTwoDrawerVisibility}
                    >
                    <LevelOneDrawer
                        levelOneDrawerVisibility={levelOneDrawerVisibility}
                        setLevelOneDrawerVisibility={setLevelOneDrawerVisibility}
                        levelTwoDrawerVisibility={levelTwoDrawerVisibility}
                        setLevelTwoDrawerVisibility={setLevelTwoDrawerVisibility}
                    >
                        <div className={`relative flex flex-col z-0 h-full`}>
                            <div className={`tabs flex w-full mb-4`}>
                                <div className={`tab group flex flex-col gap-2 w-1/3 h-32 rounded-md ${firstSlider===0 ? `border-b-4 border-b-${theme.themeColor}-${theme.themeContrast}-primary-default` : '' }  ${theme.themeContrast === 'light' ? 'hover:bg-slate-100' : 'hover:bg-[#444444]'}  cursor-pointer justify-center align-middle items-center`}
                                    onClick={()=>{
                                        setFirstSlider(0)
                                    }}
                                >
                                    <em className={`fa-solid fa-layer-group text-3xl text-${theme.themeColor}-${theme.themeContrast}-primary-default`}></em>
                                    <div className={`hidden group-hover:block text-${theme.themeColor}-${theme.themeContrast}-primary-default`}>Components</div>
                                </div>
                                <div className={`tab group flex flex-col gap-2 w-1/3 h-32 rounded-md ${firstSlider===-480 ? `border-b-4 border-b-${theme.themeColor}-${theme.themeContrast}-primary-default` : '' } ${theme.themeContrast === 'light' ? 'hover:bg-slate-100' : 'hover:bg-[#444444]'} cursor-pointer justify-center align-middle items-center`}
                                    onClick={()=>{
                                        setFirstSlider(-480)
                                    }}
                                >
                                    <em className={`fa-solid fa-user text-3xl text-${theme.themeColor}-${theme.themeContrast}-primary-default`}></em>
                                    <div className={`hidden group-hover:block text-${theme.themeColor}-${theme.themeContrast}-primary-default`}>Components</div>
                                </div>
                                <div className={`tab group flex flex-col gap-2 w-1/3 h-32 rounded-md ${firstSlider===-960 ? `border-b-4 border-b-${theme.themeColor}-${theme.themeContrast}-primary-default` : '' } ${theme.themeContrast === 'light' ? 'hover:bg-slate-100' : 'hover:bg-[#444444]'} cursor-pointer justify-center align-middle items-center`}
                                    onClick={()=>{
                                        setFirstSlider(-960)
                                    }}
                                >
                                    <em className={`fa-solid fa-user text-3xl text-${theme.themeColor}-${theme.themeContrast}-primary-default`}></em>
                                    <div className={`hidden group-hover:block text-${theme.themeColor}-${theme.themeContrast}-primary-default`}>Components</div>
                                </div>
                            </div>
                            <div className={`tabs-content-group flex relative transition-[margin] duration-200 ease-in-out ml-[${firstSlider}px] w-[1440px] grow overflow-scroll`}>
                                <div className={`tab-content w-[480px] h-80`}>
                                    <Accordion>
                                        <AccordionItem title={`Tiles`} state={true} maxHeight={`688px`}>
                                            <SidebarLibrary />
                                        </AccordionItem>
                                        <AccordionItem title={`Tiles`} state={true} maxHeight={`688px`}>
                                        </AccordionItem>
                                        <AccordionItem title={`Tiles`} state={true} maxHeight={`688px`}>
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                                <div className={`tab-content w-[480px] h-80`}>
                                    <Button variant="primary"
                                        onClick={()=>{
                                            setLevelTwoDrawerVisibility(true)
                                        }}
                                    >OPEN</Button>
                                </div>
                                <div className={`tab-content w-[480px] h-80`}></div>
                            </div>
                        </div>
                    </LevelOneDrawer>
                    <LevelTwoDrawer
                        levelTwoDrawerVisibility={levelTwoDrawerVisibility}
                        setLevelTwoDrawerVisibility={setLevelTwoDrawerVisibility}
                    ></LevelTwoDrawer>
                </SideDrawer> */}

            </div>
        </>
    )
}

export default Customizable;
