/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useState, useEffect } from "react";
import io from 'socket.io-client';
import { getAPICall } from "../../services/APILayer/axiosMethodCalls";

interface ILiveContext {
    socket: any;
    isConnected: boolean;
    zeeqNoticeTest: any;
    zeeqNoticeEndpointLive: any;
    zeeqNoticeGlobal: any;
    sendMessage: (message:string, channel: string)=>void;
}

export const LiveContext = React.createContext({} as ILiveContext);

interface IProp {
    children: ReactNode | ReactNode[];
}

const LiveProvider = (props: IProp) => {

    const [licenseInfo, setLicenseInfo] = useState<any>({});
    const [socket, setSocket] = useState<any>(null);
    const [isConnected, setIsConnected] = useState<boolean>(false);

    const [zeeqNoticeTest, setZeeqNoticeTest] = useState<any>(null)
    const [zeeqNoticeEndpointLive, setZeeqNoticeEndpointLive] = useState<any>(null);
    const [zeeqNoticeGlobal, setZeeqNoticeGlobal] = useState<any>(null);

    const getLicenseInfo = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/app/licenses/me`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( Object.keys(licenseInfo).length > 0 ) {
            return;
        }
        const fetchLicenseInfo = () => {
            getLicenseInfo()
                .then((license)=>{
                    // console.log({_liveContextLicense:license})
                    setLicenseInfo(license)
                })
                .catch(err=>console.log(err));
        }
        fetchLicenseInfo();
    },[]) 

    useEffect(()=>{

        if( Object.keys( licenseInfo ).length < 1 ) {
            return;
        }

        const options: any = {
            query: `license=${licenseInfo?.license?._id || ''}`,
        }

        if( process.env.REACT_APP_SOCKET_ENABLE === 'true' ) {
            let tempSocket = io(`${process.env.REACT_APP_SOCKET_HOST}`, options);
    
            if( tempSocket ) {
                setSocket(tempSocket)
            }
        }
        
    },[licenseInfo])

    useEffect(() => {

        if( !socket ) {
            return;
        }

        socket.on('connect', () => {
            // console.log('socket connected')
            setIsConnected(true)
        });

        socket.on('disconnect', () => {
            // console.log('socket disconnected')
            setIsConnected(false)
        });

        socket.on('reconnect', () => {
            // console.log('socket reconnected')
        });

        socket.on('zeeq-notice-test', (data: any) => {
            // console.log('zeeq-notice-test',{data})
            setZeeqNoticeTest(data);
        });

        socket.on('zeeq-notice-endpoint-live', (data: any) => {
            // console.log('zeeq-notice-endpoint-live',{data})
            setZeeqNoticeEndpointLive(data);
        }); 

        socket.on('zeeq-notice-global', (data: any) => {
            // console.log('zeeq-notice-global',{data})
            setZeeqNoticeGlobal(data);
        });

        // return () => {
        //     socket.off('connect');
        //     socket.off('disconnect');
        //     socket.off('zeeq-notice-test');
        //     socket.off('zeeq-notice-endpoint-live');
        // };
    }, [socket]);

    const sendMessage = (message:string, channel: string) => {
        if( !socket ) {
            return
        }
        socket.emit(message);
    }

    return (
      <LiveContext.Provider value={{socket, isConnected, zeeqNoticeTest, zeeqNoticeEndpointLive, zeeqNoticeGlobal, sendMessage}}>
        {props.children}
      </LiveContext.Provider>
    ) 
}

export default LiveProvider;