import React, { FC, useContext } from 'react';
import { Routes, Route } from "react-router-dom";
import { Auth0Provider } from '@auth0/auth0-react';

import { DashboardLayout } from './layouts';
import { ProtectedRoute } from './components/core';

import { 
  ThemeProvider,
  CustomizationContext,
  LibraryProvider,
  GlobalProvider
} from './contexts';

import { // NOTE: Do not remove any page reference from here, it might be loading dynamically
  // CompanyVerification,
  // EmailVerification,
  // LogoutPage,

  BackupFiles,
  EndpointDashboard,
  EndpointDevice,
  EndpointDeviceAuditReport,
  EndpointDeviceAuditReportFilterPage,
  EndpointDeviceProfile,
  EndpointPolicy,
  EndpointReport,
  EndpointStrategy,
  EndpointUserProfile,
  EndpointUsers,

  ExchangeDashboard,
  // MS365Dashboard,
  OneDriveDashboard,

  License,
  OrganizationSettings,
  UserSettings,

  Customizable,
  Dashboard,
  Dashboard1,
  // GettingStarted,
  Library,
  CompanyVerification,  // <== do not remove
  EmailVerification,  // <== do not remove
  MenuManagement,
  Notification,
  // ServiceTest,
  SortableCloning,
  LogoutPage,
  GettingStarted,
  ServiceTest,
  Strategy,
  TableExample,
  Users,
  Settings,
  OrderDetails,
  ComingSoon,
  ProgressBar,
  CreateOrder,
  ManageLicense,
  AddLicense,
  ManageOrder,
  
} from './pages'
import LiveProvider from './contexts/LiveContext/LiveContext';
import NewSideBar from './pages/NewSideBar';
import ScatterChart from './components/Charts/ApacheECharts/ScatterChart/ScatterChart';
import TicketMovement from './pages/TicketMovement';

import PageTemplate from "./pages/PageTemplate";
import Ms365Dashboard from "./pages/Microsoft365/MS365dashboard";
import Notifications from "./pages/settings/Notifications";
import Security from "./pages/settings/Security";
import Partnerdashboard from "./pages/settings/PartnerDashboard";
import OnboardProcess from "./pages/settings/OnboardProcess";
import Orders from "./pages/ManageOrder";
import AddOrders from "./pages/ManageOrder/AddOrders";

const App: FC = () => {
  const customElements = useContext(CustomizationContext)

  return (
    <div className="App">
      <ThemeProvider>
        <Auth0Provider
          domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
          clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
          redirectUri={`${process.env.REACT_APP_AUTH0_REDIRECT_URL}`}
          audience={`${process.env.REACT_APP_AUTH0_AUDIENCE}`}
          scope={`${process.env.REACT_APP_AUTH0_SCOPE}`}
          useRefreshTokens={true}
          cacheLocation='memory'
          sessionCheckExpiryDays={2}
        >
          <GlobalProvider>
            <Routes>
              <Route path="/" element={<GettingStarted />}/>
              <Route path="/" element={
                <ProtectedRoute>
                  <LiveProvider>
                    <DashboardLayout />
                  </LiveProvider>
                </ProtectedRoute>
              }>
                  {/* NOTE: The routes are dynamically coming from CustomizationContext */}
                  {Object.keys( customElements.pageElements ).map((pageGroup, pageGroupIndex)=>(
                    Object.keys( customElements.pageElements[pageGroup] ).map((page, pageIndex)=>(
                      <>
                        {/* static pages */}
                        (customElements.pageElements[pageGroup][page].staticPage.value === true) && (
                          <Route key={pageIndex} path={page} element={customElements.pageElements[pageGroup][page].staticPage.component}/>
                        )
                        {/* dynamic pages or redirection */}
                        (customElements.pageElements[pageGroup][page].staticPage.value === false || customElements.pageElements[pageGroup][page].redirection.value === true ) && (
                            <>
                              <Route key={pageIndex} path={page} element={<LibraryProvider> <Customizable pageGroup={pageGroup} redirection={customElements.pageElements[pageGroup][page].redirection}/> </LibraryProvider>}/>
                              
                              {/* subPage logic */}
                              {customElements.pageElements[pageGroup][page].pages.length > 0 &&
                                customElements.pageElements[pageGroup][page].pages.map((subPage: any, subPageIndex: number)=>
                                  (subPage.staticPage.value === false) ?
                                  (
                                    <Route key={subPageIndex} path={subPage.link} element={<LibraryProvider> <Customizable pageGroup={pageGroup} redirection={subPage.redirection}/> </LibraryProvider>}/>
                                  ) :
                                  (
                                    <Route key={subPageIndex} path={subPage.link} element={subPage.staticPage.component}/>
                                  )
                                )
                              }
                              {/* subPage logic end here */}
                            </>
                        )
                      </>
                    ))
                  ))}
                  <Route path='/service-test' element={<ServiceTest />}/>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/manage/menu" element={<MenuManagement />}/>
                  <Route path="/dashboard1" element={<Dashboard1 />}/>
                  <Route path="/react-sortable-cloning" element={<SortableCloning />}/>
              </Route>
              <Route path="/auth/verify/email" element={<EmailVerification />}/>
              <Route path="/auth/verify/organization" element={<CompanyVerification />}/>
              <Route path="/logout" element={<LogoutPage />} />
              <Route path="/new-sidebar" element={<NewSideBar />} />
              <Route path="/cluster-chart" element={<ScatterChart />} />
              <Route path="/ticket-movement" element={<TicketMovement />} />
            </Routes>
          </GlobalProvider>
        </Auth0Provider>
      </ThemeProvider>
    </div>
  )
}

export default App
