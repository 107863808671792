/* eslint-disable react/jsx-pascal-case */
import React, { FC } from "react";
import Tile5x4_ChartText from "../../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText";

interface IProtectedVMsCard {
    
}

const ProtectedVMsCard:FC<IProtectedVMsCard> = () => {

    return (
        <>
            <Tile5x4_ChartText 
                sideBar={false}
                clickNavigate={false}
                navigationPath='/'
                title={
                    < div className="flex items-center">
                        Protected VMs
                        {/* <small className="text-green-600 p-1 px-2 border-green-200 text-xs"><span className=" text-green-600"></span><i className="fa-solid fa-check bg-green-100 text-green-600 border border-green-300 rounded-full w-4 h-4 ml-1 text-xs"/></small> */}
                    </div>}
                description=""
                value={<i className="fa-duotone fa-server text-5xl text-[#FF5E0E] relative">
                    <i className="fa-light fa-shield-check absolute -bottom-2 left-9 bg-slate-300 flex justify-center w-10 h-10 text-xl items-center text-center text-orange-700 rounded-full border bg-opacity-70"></i>
                </i>
            }
                // textBackground="bg-green-100"
                textValue={<em className="fa-light fa-file-certificate text-[56px]"></em>}
                textColor="text-green-600"
                // textArea=""
                // inChartMain='20' inChartSub="License"
                // alert={false}
                // live={false}
                designOption="chart"
                chartType="donut" 
                chartLabels={[]} 
                chartDataset={[
                    {
                        label: '# of Votes',
                        data: [],
                        backgroundColor: [
                        ],
                        borderWidth: 5,
                        cutout: 43,
                        borderRadius: 20
                    },

                ]}
                chartOptions={{
                    responsive: true,
                    plugins: {
                        legend: { display: false },
                        title: { display: false },
                    },
                    scales: {
                        x: { display: false },
                        y: { display: false },
                    },
                }}
                inChartMain='' inChartSub=""
                className='border relative'
                tileMode='default'
                alert={false}
                live={false}
            >
                <div className="text-7xl text-[#FF5E0E] flex items-center text-right justify-end rounded-md absolute whitespace-nowrap right-4">0</div>
            </Tile5x4_ChartText>
        </>
    )
}

export default ProtectedVMsCard;