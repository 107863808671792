import React, { FC, ReactNode } from "react"

interface ITableCell {
    type: 'header' | 'body';
    textColor?: string;
    textAlignment?: string;
    children: ReactNode | ReactNode[] | string;
    className?: string;
}

const TableCell:FC<ITableCell> = ({
    type,
    textColor='text-grey-dark',
    textAlignment='text-left',
    children,
    className=''
}) => {
    return (
        type === 'body' ? 
        <div className={`border-b-[0.20px] whitespace-nowrap font-normal text-xs flex align-middle justify-start items-center grow ${textColor} ${textAlignment} ${className}`}>
            {children}
        </div> :
        <div className={`border-y whitespace-nowrap font-semibold text-xs flex align-middle justify-start items-center grow ${textAlignment} ${className}`}>
            {children}
        </div>
    )
}

export default TableCell