import React, { FC, ReactNode, ComponentProps } from "react";
// import { ThemeContext } from "../../../../contexts";
import { Tile } from "../../../core";
import Placeholder from "../../../core/Placeholder/Placeholder";

interface ILinaMetronicTileProp extends ComponentProps<"div">  {
    colSpan?: number,
    rowSpan?: number,
    data: any,
    /**
     * Child node(s) of button
     */
    children?: ReactNode | ReactNode[] | any;
}

/**
 * Button Component
 * 
 * text-green-600
 * text-red-600
 * text-violet-600
 * text-yellow-400 text-yellow-500 text-yellow-600
 * text-red-600
 * 
 * @param param0 
 * @returns 
 */
const LinaMetronicTile:FC<ILinaMetronicTileProp> = ({
    colSpan=4,
    rowSpan=1,
    data,
    children=<></>,
    ...rest
}: ILinaMetronicTileProp) => {

    // const theme = useContext(ThemeContext)  || {themeColor: 'primary', themeContrast: 'light'};

    return (
        <>
            <Tile colSpan={colSpan} rowSpan={rowSpan}>
                <div className="h-1/2 px-[30px] pt-[30px] flex items-center align-middle">
                    <div className="lina-logo h-[55px] w-[55px] p-[10px] bg-slate-200 rounded-md mr-[16px]">
                        <img className="h-[35px] w-[35px]" src="/assets/img/logo/atempo/LINA_LOGO.svg" alt="lina" />
                    </div>
                    <div className={`text-base font-medium text-${data?.color}`}>{data?.title || <Placeholder className="bg-slate-200 h-[20px] w-[160px]" />}</div>
                </div>
                <div className="h-1/2 px-[30px] pb-[30px] pt-[20px] text-lg font-medium">{data?.value || <Placeholder className="bg-slate-300 h-[30px] w-[200px]" />}</div>
            </Tile>
        </>
    );
}

export default LinaMetronicTile;
