const generateCsv = (reportData: any[], tempCsv: string) => {
    reportData.map((row: any) => {
        Object.keys(row).map((col: string)=> {
            tempCsv = tempCsv + `"${row[col]}",`
        })
        tempCsv = tempCsv.slice(0, tempCsv.length - 1)
        tempCsv = tempCsv + `\n`
    });

    return tempCsv;
}

const generateHtmlTable = ( reportData: any[], tempHTML: string ) => {
    reportData.map((row: any) => {
        tempHTML = tempHTML + `<tr>`
        Object.keys(row).map((col: string)=> {
            tempHTML = tempHTML + `<td>${row[col]}</td>`
        })
        tempHTML = tempHTML + `</tr>`
    });

    return tempHTML
}


export {
    generateCsv,
    generateHtmlTable
}