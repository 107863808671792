import { useAuth0 } from '@auth0/auth0-react'
import { GlobalContext } from '../../../contexts/GlobalContext/GlobalContext';
import { useContext } from 'react'

function ProtectedRoute(props: any) {
    const globalContext = useContext(GlobalContext);
    const { user, loginWithRedirect, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    if(isLoading){
        return <div>Loading...</div>
    }
    else if (!isAuthenticated) {
        loginWithRedirect();
        return <div>Please Log in...</div>
    }
    else{
        getAccessTokenSilently().then((token) => {
            let data = {...user, token: token};
            globalContext.setUser(data);
        }).catch((err) => {
            console.log(err, "There was an error getting the token, please log in again");
            Logout();
        })
        return (
            <>{props.children}</>
        )
    }
}

export function Logout(){
    const { logout } = useAuth0();
    logout({ returnTo: window.location.origin });
    return <div>Logging out...</div>
}

export default ProtectedRoute