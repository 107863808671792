/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, FC } from "react";
// import io from 'socket.io-client';
import { Tile } from "../../components/core";
import { getAPICall } from "../../services/APILayer/axiosMethodCalls";
import { LiveContext } from "../../contexts/LiveContext/LiveContext";
import { SideDrawerContext } from "../../contexts";
import { deviceSymbol } from "../../utils/agentUtils";
import { Link } from "react-router-dom";

// @TODO make license selection dynamic
// const options: any = {
//     query: "license=63541dc5dc6323e8924f11aa",
// }

// const socket = io(`${process.env.REACT_APP_SOCKET_HOST}`, options);

const LiveActivityCard = () => {

    const liveContext = useContext(LiveContext);
    const sideDrawer = useContext(SideDrawerContext);

    const [endpointData, setEndpointData ] = useState<any>(null);

    const [liveData, setLiveData] = useState<any>(null);
    const [final, setFinal] = useState<any>({
        backup:{count:0, devices:[]},
        clientRestore:{count:0, devices:[]},
        webRestore: {count:0, devices:[]},
        replication: {count:0, devices:[]}}
    );
    const [count, setCount] = useState<number>(0);

    const getEndpoints = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( endpointData ) {
            return;
        }

        const fetchEndpoints = async () => {
            await getEndpoints().then((data)=>{
                // console.log({endpoints: data})
                setEndpointData(data);
            }).catch((err)=>{
                console.log({err})
            });
        }

        setTimeout(() => {
            fetchEndpoints();
        }, 500);

    },[endpointData]);

    useEffect(()=>{
        // console.log('liveContext changed',{liveContext})
        if( !liveContext.zeeqNoticeEndpointLive ) {
            return;
        }
        // console.log({zeeqNoticeEndpointLive: liveContext.zeeqNoticeEndpointLive})
        setLiveData(liveContext.zeeqNoticeEndpointLive);
    },[liveContext])

    useEffect(()=>{
        if( !liveData ) {
            return;
        }
        // console.log({liveData})
        let result = (liveData?.activities || []).reduce((actuator:any,activity:any) => {

            let activityType = 'backup'

            if( activity.type === 'B' ) {
                activityType = 'backup';
            } else if( activity.type === 'W' ) {
                activityType = 'webRestore';
            } else if( activity.type === 'R' ) {
                activityType = 'clientRestore';
            // } else if( activity.type === 'T' ) {
            //     activityType = 'replication';
            } else if( activity.type === 'o' ) {
                activityType = 'replication';
            }

            actuator[activityType].count = actuator[activityType].count + 1;
            actuator[activityType].devices.push(activity);

            return actuator;

        },{backup:{count:0, devices:[]}, clientRestore:{count:0, devices:[]}, webRestore: {count:0, devices:[]}, replication: {count:0, devices:[]}})

        let temp: number = count + 1
        setCount(temp)
        setFinal(result)
    },[liveData])

    const filterActiveDevices = (type:string) => {
        console.log({endpointData},type, final)
        let devices = endpointData.endpoints.filter((ep:any) => { return final[type].devices.map((d:any)=>{ return d._id; }).includes(ep._id)  });
        console.log({devices})
        return devices
    }
    
    return (
        <>
            <Tile rowSpan={13} colSpan={3} className='p-0 overflow-hidden'>
                <div className="flex flex-col h-full mb-1 p-0 text-[#3A3541]">
                    <div className="flex flex-row justify-between w-full">
                        <div 
                            title='Ticket Status' 
                            className="flex items-center align-middle justify-center w-full text-base md:text-lg pt-5 pl-5 lg:text-xl leading-8 font-medium whitespace-nowrap truncate"
                        >
                            <span>Endpoint Live Status</span>
                            <em className={`fa-solid fa-circle text-xs ml-2 mt-1 ${ (liveContext?.isConnected || false) ? 'text-red-500 animate-pulse' : 'text-gray-400' }`}></em>
                            <span className="grow"></span>
                        </div>
                    </div>
                    <div className="flex flex-col h-[360px]">
                        <div className="flex grow h-[155px]">
                            <div
                                onClick={()=>{
                                    sideDrawer.setFirstDrawerChild(<FirstDrawerChild type="Backup" devices={filterActiveDevices('backup')} />);
                                    sideDrawer.setLevelOneDrawerVisibility(true);
                                }} 
                                className="relative flex flex-col items-center align-middle justify-center w-1/2 hover:bg-[#ff5e0e] hover:bg-opacity-5 shadow-md overflow-hidden cursor-pointer"
                            >
                                <span className={`text-4xl lg:text-2xl xl:text-4xl flex items-end gap-1 font-semibold mb-2 text-[#FF5E0E]`}> 
                                    {JSON.stringify(final?.backup?.count)}
                                    {/* <span className="text-lg font-medium" >/ {endpointData?.installed?.length}</span> */}
                                </span>
                                <span className="text-sm text-gray-600 px-6 text-center whitespace-nowrap">Devices</span>
                                <span className="absolute bottom-2 right-2 text-xs text-gray-500 uppercase">Backup</span>
                                {final?.backup?.count > 0 && <div className={`absolute bottom-0 w-full`}>
                                    <svg width="100%" height="100%" id="svg" 
                                        viewBox={`
                                            ${ ((final?.backup?.count || 0)/(endpointData?.installed?.length | 0)*100 <= 20) ? '0 0 1400 300' : '' }
                                            ${ ((final?.backup?.count || 0)/(endpointData?.installed?.length | 0)*100 > 20) && ((final?.backup?.count || 0)/(endpointData?.installed?.length | 0)*100 <= 40) ? '0 0 1080 420' : '' }
                                            ${ ((final?.backup?.count || 0)/(endpointData?.installed?.length | 0)*100 > 40) && ((final?.backup?.count || 0)/(endpointData?.installed?.length | 0)*100 <= 60) ? '0 0 920 480' : '' }
                                            ${ ((final?.backup?.count || 0)/(endpointData?.installed?.length | 0)*100 > 60) && ((final?.backup?.count || 0)/(endpointData?.installed?.length | 0)*100 <= 80) ? '0 0 760 540' : '' }
                                            ${ ((final?.backup?.count || 0)/(endpointData?.installed?.length | 0)*100 > 80) && ((final?.backup?.count || 0)/(endpointData?.installed?.length | 0)*100 <= 100) ? '0 0 600 600' : '' }
                                            
                                        `}
                                        xmlns="http://www.w3.org/2000/svg" className="transition duration-300 ease-in-out delay-150">
                                        <defs>
                                            <linearGradient id="gradient" x1="0%" y1="50%" x2="100%" y2="50%">
                                                <stop offset="5%" stopColor="#fcb900"></stop>
                                                <stop offset="95%" stopColor="#ff5e0e"></stop>
                                            </linearGradient>
                                        </defs>
                                        <path 
                                            d="M 0,600 C 0,600 0,200 0,200 C 117.46411483253587,192.2488038277512 234.92822966507174,184.49760765550238 336,178 C 437.07177033492826,171.50239234449762 521.7511961722488,166.25837320574166 610,187 C 698.2488038277512,207.74162679425834 790.0669856459331,254.4688995215311 879,268 C 967.9330143540669,281.5311004784689 1053.980861244019,261.86602870813397 1147,245 C 1240.019138755981,228.13397129186603 1340.0095693779904,214.06698564593302 1440,200 C 1440,200 1440,600 1440,600 Z"
                                            stroke="none"
                                            strokeWidth="0"
                                            fill="url(#gradient)"
                                            fillOpacity="0.40"
                                            className="transition-all duration-300 ease-in-out delay-150 path-0"
                                        ></path>
                                    </svg>
                                </div>}
                            </div>
                            <div
                                onClick={()=>{
                                    sideDrawer.setFirstDrawerChild(<FirstDrawerChild type="Client Restore" devices={filterActiveDevices('clientRestore')} />);
                                    sideDrawer.setLevelOneDrawerVisibility(true);
                                }}
                                className="relative flex flex-col items-center align-middle justify-center w-1/2 hover:bg-[#ff5e0e] hover:bg-opacity-5 shadow-md overflow-hidden cursor-pointer"
                            >
                                <span className="text-4xl lg:text-2xl xl:text-4xl flex items-end gap-1 font-semibold mb-2 text-[#FF5E0E]"> 
                                    {JSON.stringify(final?.clientRestore?.count || 0)}
                                    {/* <span className="text-lg font-medium" >/ {endpointData?.installed?.length}</span> */}
                                </span>
                                <span className="text-sm text-gray-600 px-6 text-center whitespace-nowrap">Devices</span>
                                <span className="absolute bottom-2 right-2 text-xs text-gray-500 uppercase">Client Restore</span>
                                {final?.clientRestore?.count > 0 && <div className={`absolute bottom-0 w-full`}>
                                    <svg width="100%" height="100%" id="svg" 
                                        viewBox={`
                                            ${ ((final?.clientRestore?.count || 0)/(endpointData?.installed?.length | 0)*100 <= 20) ? '0 0 1400 300' : '' }
                                            ${ ((final?.clientRestore?.count || 0)/(endpointData?.installed?.length | 0)*100 > 20) && ((final?.clientRestore?.count || 0)/(endpointData?.installed?.length | 0)*100 <= 40) ? '0 0 1080 420' : '' }
                                            ${ ((final?.clientRestore?.count || 0)/(endpointData?.installed?.length | 0)*100 > 40) && ((final?.clientRestore?.count || 0)/(endpointData?.installed?.length | 0)*100 <= 60) ? '0 0 920 480' : '' }
                                            ${ ((final?.clientRestore?.count || 0)/(endpointData?.installed?.length | 0)*100 > 60) && ((final?.clientRestore?.count || 0)/(endpointData?.installed?.length | 0)*100 <= 80) ? '0 0 760 540' : '' }
                                            ${ ((final?.clientRestore?.count || 0)/(endpointData?.installed?.length | 0)*100 > 80) && ((final?.clientRestore?.count || 0)/(endpointData?.installed?.length | 0)*100 <= 100) ? '0 0 600 600' : '' }
                                            
                                        `}
                                        xmlns="http://www.w3.org/2000/svg" className="transition duration-300 ease-in-out delay-150">
                                        <defs>
                                            <linearGradient id="gradient" x1="0%" y1="50%" x2="100%" y2="50%">
                                                <stop offset="5%" stopColor="#fcb900"></stop>
                                                <stop offset="95%" stopColor="#ff5e0e"></stop>
                                            </linearGradient>
                                        </defs>
                                        <path 
                                            d="M 0,600 C 0,600 0,200 0,200 C 117.46411483253587,192.2488038277512 234.92822966507174,184.49760765550238 336,178 C 437.07177033492826,171.50239234449762 521.7511961722488,166.25837320574166 610,187 C 698.2488038277512,207.74162679425834 790.0669856459331,254.4688995215311 879,268 C 967.9330143540669,281.5311004784689 1053.980861244019,261.86602870813397 1147,245 C 1240.019138755981,228.13397129186603 1340.0095693779904,214.06698564593302 1440,200 C 1440,200 1440,600 1440,600 Z"
                                            stroke="none"
                                            strokeWidth="0"
                                            fill="url(#gradient)"
                                            fillOpacity="0.40"
                                            className="transition-all duration-300 ease-in-out delay-150 path-0"
                                        ></path>
                                    </svg>
                                </div>}
                            </div>
                        </div>
                        <div className="flex grow h-[155px]">
                            <div
                                onClick={()=>{
                                    sideDrawer.setFirstDrawerChild(<FirstDrawerChild type="Web Restore" devices={filterActiveDevices('webRestore')} />);
                                    sideDrawer.setLevelOneDrawerVisibility(true);
                                }}
                                className="relative flex flex-col items-center align-middle justify-center w-1/2 hover:bg-[#ff5e0e] hover:bg-opacity-5 shadow-md overflow-hidden cursor-pointer"
                            >
                                <span className="text-4xl lg:text-2xl xl:text-4xl flex items-end gap-1 font-semibold mb-2 text-[#FF5E0E]"> 
                                    {JSON.stringify(final?.webRestore?.count || 0)}
                                    {/* <span className="text-lg font-medium" >/ {endpointData?.installed?.length}</span> */}
                                </span>
                                <span className="text-sm text-gray-600 px-6 text-center whitespace-nowrap">Devices</span>
                                <span className="absolute bottom-2 right-2 text-xs text-gray-500 uppercase">Web Restore</span>
                                {final?.webRestore?.count > 0 && <div className={`absolute bottom-0 w-full`}>
                                    <svg width="100%" height="100%" id="svg" 
                                        viewBox={`
                                            ${ ((final?.webRestore?.count || 0)/(endpointData?.installed?.length | 0)*100 <= 20) ? '0 0 1400 300' : '' }
                                            ${ ((final?.webRestore?.count || 0)/(endpointData?.installed?.length | 0)*100 > 20) && ((final?.webRestore?.count || 0)/(endpointData?.installed?.length | 0)*100 <= 40) ? '0 0 1080 420' : '' }
                                            ${ ((final?.webRestore?.count || 0)/(endpointData?.installed?.length | 0)*100 > 40) && ((final?.webRestore?.count || 0)/(endpointData?.installed?.length | 0)*100 <= 60) ? '0 0 920 480' : '' }
                                            ${ ((final?.webRestore?.count || 0)/(endpointData?.installed?.length | 0)*100 > 60) && ((final?.webRestore?.count || 0)/(endpointData?.installed?.length | 0)*100 <= 80) ? '0 0 760 540' : '' }
                                            ${ ((final?.webRestore?.count || 0)/(endpointData?.installed?.length | 0)*100 > 80) && ((final?.webRestore?.count || 0)/(endpointData?.installed?.length | 0)*100 <= 100) ? '0 0 600 600' : '' }
                                            
                                        `}
                                        xmlns="http://www.w3.org/2000/svg" className="transition duration-300 ease-in-out delay-150">
                                        <defs>
                                            <linearGradient id="gradient" x1="0%" y1="50%" x2="100%" y2="50%">
                                                <stop offset="5%" stopColor="#fcb900"></stop>
                                                <stop offset="95%" stopColor="#ff5e0e"></stop>
                                            </linearGradient>
                                        </defs>
                                        <path 
                                            d="M 0,600 C 0,600 0,200 0,200 C 117.46411483253587,192.2488038277512 234.92822966507174,184.49760765550238 336,178 C 437.07177033492826,171.50239234449762 521.7511961722488,166.25837320574166 610,187 C 698.2488038277512,207.74162679425834 790.0669856459331,254.4688995215311 879,268 C 967.9330143540669,281.5311004784689 1053.980861244019,261.86602870813397 1147,245 C 1240.019138755981,228.13397129186603 1340.0095693779904,214.06698564593302 1440,200 C 1440,200 1440,600 1440,600 Z"
                                            stroke="none"
                                            strokeWidth="0"
                                            fill="url(#gradient)"
                                            fillOpacity="0.40"
                                            className="transition-all duration-300 ease-in-out delay-150 path-0"
                                        ></path>
                                    </svg>
                                </div>}
                            </div>
                            <div
                                onClick={()=>{
                                    sideDrawer.setFirstDrawerChild(<FirstDrawerChild type="Replication" devices={filterActiveDevices('replication')} />);
                                    sideDrawer.setLevelOneDrawerVisibility(true);
                                }} 
                                className="relative flex flex-col items-center align-middle justify-center w-1/2 hover:bg-[#ff5e0e] hover:bg-opacity-5 shadow-md overflow-hidden cursor-pointer"
                            >
                                <span className="text-4xl lg:text-2xl xl:text-4xl flex items-end gap-1 font-semibold mb-2 text-[#FF5E0E]"> 
                                    {JSON.stringify(final?.replication?.count || 0)}
                                    {/* <span className="text-lg font-medium" >/ {endpointData?.installed?.length}</span> */}
                                </span>
                                <span className="text-sm text-gray-600 px-6 text-center whitespace-nowrap">Devices</span>
                                <span className="absolute bottom-2 right-2 text-xs text-gray-500 uppercase">Replication</span>
                                {final?.replication?.count > 0 && <div className={`absolute bottom-0 w-full`}>
                                    <svg width="100%" height="100%" id="svg" 
                                        viewBox={`
                                            ${ ((final?.replication?.count || 0)/(endpointData?.installed?.length | 0)*100 <= 20) ? '0 0 1400 300' : '' }
                                            ${ ((final?.replication?.count || 0)/(endpointData?.installed?.length | 0)*100 > 20) && ((final?.replication?.count || 0)/(endpointData?.installed?.length | 0)*100 <= 40) ? '0 0 1080 420' : '' }
                                            ${ ((final?.replication?.count || 0)/(endpointData?.installed?.length | 0)*100 > 40) && ((final?.replication?.count || 0)/(endpointData?.installed?.length | 0)*100 <= 60) ? '0 0 920 480' : '' }
                                            ${ ((final?.replication?.count || 0)/(endpointData?.installed?.length | 0)*100 > 60) && ((final?.replication?.count || 0)/(endpointData?.installed?.length | 0)*100 <= 80) ? '0 0 760 540' : '' }
                                            ${ ((final?.replication?.count || 0)/(endpointData?.installed?.length | 0)*100 > 80) && ((final?.replication?.count || 0)/(endpointData?.installed?.length | 0)*100 <= 100) ? '0 0 600 600' : '' }
                                            
                                        `}
                                        xmlns="http://www.w3.org/2000/svg" className="transition duration-300 ease-in-out delay-150">
                                        <defs>
                                            <linearGradient id="gradient" x1="0%" y1="50%" x2="100%" y2="50%">
                                                <stop offset="5%" stopColor="#fcb900"></stop>
                                                <stop offset="95%" stopColor="#ff5e0e"></stop>
                                            </linearGradient>
                                        </defs>
                                        <path 
                                            d="M 0,600 C 0,600 0,200 0,200 C 117.46411483253587,192.2488038277512 234.92822966507174,184.49760765550238 336,178 C 437.07177033492826,171.50239234449762 521.7511961722488,166.25837320574166 610,187 C 698.2488038277512,207.74162679425834 790.0669856459331,254.4688995215311 879,268 C 967.9330143540669,281.5311004784689 1053.980861244019,261.86602870813397 1147,245 C 1240.019138755981,228.13397129186603 1340.0095693779904,214.06698564593302 1440,200 C 1440,200 1440,600 1440,600 Z"
                                            stroke="none"
                                            strokeWidth="0"
                                            fill="url(#gradient)"
                                            fillOpacity="0.40"
                                            className="transition-all duration-300 ease-in-out delay-150 path-0"
                                        ></path>
                                    </svg>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </Tile>
        </>
    )
}

export default LiveActivityCard;

interface IFirstDrawerChild {
    type: string;
    devices: any[]
}


const FirstDrawerChild:FC<IFirstDrawerChild> = ({
    type,
    devices
}) => {
    const sideDrawer = useContext(SideDrawerContext);
    return (
        <>
            <div className="h-full flex flex-col">
                <div className="title w-full flex gap-4 px-6 top-0 pt-10 border-b pb-10 bg-slate-100 items-center">
                    {/* Icon */}
                    <i className="fa-regular fa-tv  text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i>

                    {/* title */}
                    <div className="flex flex-col">
                        <div className="text-xl font-bold text-slate-700">Endpoint Live Status</div>
                        {/* Sub title */}
                        <div className="block text-slate-500">
                            {/* <Link to="/endpoint/devices"> */}
                                <span>{type}</span>
                                {/* <em className="fa-solid fa-link ml-2 text-sm"></em>
                            </Link> */}
                        </div>
                    </div>
                </div>
                <div className="data grow top-40 pl-4 pr-3 w-full overflow-y-scroll customScroll py-6">
                    {(devices || []).length > 0 ? 
                        (
                            <div className={`table w-full`}>
                                <div className={`thead bg-slate-50 border-y w-full p-2 flex flex-row items-center`}>
                                    <div className="row text-gray-800 text-sm flex">
                                        <div className="cell text-left w-16 pl-4">#</div>
                                        <div className="cell text-left w-16 -ml-2">OS</div>
                                        <div className="cell text-left grow">Device Name</div>
                                    </div>
                                </div>
                                <div className={`tbody overflow-y-scroll customScroll max-h-48 bg-white w-full flex flex-col text-slate-600`}>
                                    {(devices || []).map((endpoint:any, endpointIndex:number)=>(
                                        <Link 
                                            key={endpointIndex}
                                            to={`/endpoint/devices/profile/${endpoint?._id}`}
                                            onClick={()=>{
                                                sideDrawer.setFirstDrawerChild(<></>)
                                                sideDrawer.setLevelOneDrawerVisibility(false);
                                            }}
                                        >
                                            <div className="row text-sm font-sm flex border-b w-full py-2 hover:bg-slate-100">
                                                <div className="cell w-16 pl-4 cursor-pointer text-orange-500">{endpoint?.agent_id || ''}</div>
                                                <div className="cell text-slate-500 w-16">{deviceSymbol(endpoint?.agent_profile?.AgentInfos?.System || '')}</div>
                                                <div className="cell grow">{endpoint?.agent_profile?.AdminInfos?.Name || ''}</div>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        ) :
                        (
                            <>
                                <img src="/assets/img/well_done.svg" alt="placeholder" />
                                <div className="w-full text-center mt-2 text-2xl">No Live Activity Found</div>
                            </>
                        )
                    }
                </div>
            </div>
        </>
    )
}