import React from "react";
// import { useNavigate } from 'react-router-dom';
import { Button, Tile } from "../../components/core";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from "yup"

/**
 * CompanyVerification Component
 * @returns 
 */
const CompanyVerification = () => {
    // const navigate = useNavigate();

    const validationSchema = yup.object({
        code: yup.string().required()
    });

    const submitHandler = (formValue: any) => {
        console.log(formValue)
    }

    return (
        <>
            <div className="w-full h-screen bg-white" 
            // style={{backgroundImage: 'url("/assets/img/bg-doodle-light-orange.svg")'}}
            >
                <div className="flex flex-col w-full h-full items-center align-middle justify-center">
                    <Tile rowSpan={18}  className="lg:w-[400px] md:w-[350px] w-[300px] h-[550px] shadow-2xl hover:shadow-2xl rounded-[24px] border border-[#c9cace] py-8 px-10 transition-[width] duration-1000 ease-in-out">
                    <div className="w-full flex justify-center"><img className=" md:w-[185]px md:h-[57px] lg:w-[185]px lg:h-[57px] mb-6 mt-2  " src="/assets/img/logo/zeeq_360_dark_orange.svg" alt="ZeeQ 360 Logo" /></div>
                       <div className="text-2xl   text-[#2d333a] text-center mb-6">Verify organization</div>
                        <p className="text-center mb-10 text-sm text-[#1e212a]">In order to continue to the portal, Please enter organization code you received in your email.</p>
                        <Formik 
                            initialValues={{
                                code: ""
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(values)=>{ submitHandler(values); }}
                        >
                            {
                                ({ values }) => (
                                    <Form>
                                        <div className="mb-10 mt-16 relative">
                                            <div className="flex text-center align-middle border border-[#c2c8d0] rounded-md bg-white w-full ">
                                                {/* <em className="fa-solid fa-at p-3 text-[#ff5e0e] border-r border-[#c2c8d0] "></em> */}
                                                <Field 
                                                    type='text'
                                                    name='code'
                                                    className="py-2 grow focus-visible:outline-[#635dff] bg-white overflow-hidden  rounded-md pl-4 text-base peer h-[52px]"
                                                />
                                                <label className=" text-sm absolute -mt-[10px] bg-white px-2 text-[#1e212a] text-opacity-60 ml-3 peer-focus:text-[#635dff] ">
                                                Company code
                                                </label>
                                            </div>
                                            <div className="text-sm text-red-600 mt-2 text-center"><ErrorMessage name="email" /></div>
                                        </div>
                                        <div className="flex flex-col gap-4  justify-center">
                                            <Button 
                                                variant="primary" solid={true} size="sm" className="h-[52px] rounded-full"
                                                type="submit"
                                            >Verify Organization</Button>
                                        </div>
                                    </Form>
                                )
                            }
                        </Formik>
                    </Tile>
                </div>
            </div>
        </>
    )
}

export default CompanyVerification;
