import React, { FC } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    ArcElement,
    Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { IChartParams } from "../CJMasterChart/MasterChart";
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-adapter-luxon';
import ChartStreaming from 'chartjs-plugin-streaming';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    ArcElement,
    Legend,
    zoomPlugin,
    ChartDataLabels,
    ChartStreaming
);

const CJLineChart: FC<IChartParams> = ({
    labels,
    datasets,
    options={},
    plugins=[],
    redraw=false,
    datasetIdKey='label',
    fallbackContent=<></>,
    updateMode,
    ref
}: IChartParams) => {
    return (
        <>
            <Line 
                options={options} 
                data={{
                    labels,
                    datasets
                }}
                plugins={plugins}
            />
        </>
    )
}

export default CJLineChart;