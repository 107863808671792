/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, ReactNode } from "react"
import Tile5x4_ChartText from "../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText";
import { Button, Tile } from "../components/core"
import { Table } from "../components/core/Table";
// import { ThemeContext } from "../contexts";
import { ITableColumnItem, ITableControllerProps } from "../components/core/Table/Table";
import { Link } from "react-router-dom";
import { getAPICall } from "../services/APILayer/axiosMethodCalls";
import {
    lastConnectionFormatter,
    lastBackupFormatter,
    protectedDataFormatter,
    deviceSymbol
} from "../utils/agentUtils";
import Tooltip from "../components/core/Tooltip/Tooltip";
import ButtonGroup from "../components/core/ButtonGroup/ButtonGroup";
import { MasterChart } from "../components/Charts/ChartJS";

const PageTemplate = () => {
    
    const scrollerRef = useRef<null | HTMLDivElement>(null)

    // const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};

    // const [ backupTrend, setBackupTrend ] = useState(false);

    const navRaf = useRef<HTMLDivElement>(null);

    const [ endpointData, setEndpointData ] = useState<any>(null)

    const [selectedData, setSelectedData] = useState<string>('')
    const pageTop = useRef<HTMLDivElement>(null);
    const [tableController, setTableController] = useState<ITableControllerProps>({
        data: [],
        loader: false,
        currentPage: 1,
        offset: 0,
        limit: 5,
        totalRecords: 10,
        maxVisiblePaginationButtons: 5,
        searchTerm: '',
        filter: {},
        sorting: {
            column: '',
            direction: null
        }
    });
    const [tableColumns, setTableColumns] = useState<ITableColumnItem[]>([
        {
            id: 1,
            name: 'Device Id',
            column: '',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            visibility: true
        },
        {
            id: 2,
            name: 'Hostname',
            column: '',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            visibility: true
        },
        {
            id: 3,
            name: 'OS',
            column: 'agent_profile',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            visibility: true
        },
        {
            id: 4,
            name: 'Device Status',
            column: '',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            visibility: true
        },
        {
            id: 5,
            name: 'Last Connection',
            column: 'agent_profile',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            visibility: true
        },
        {
            id: 6,
            name: 'Last Backup',
            column: 'agent_profile',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            visibility: true
        },
        {
            id: 7,
            name: 'Protected Data',
            column: 'agent_profile',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            visibility: true
        }
    ]);

    const actionButtonGenerator = (row: object) => {
        return <>
            <button aria-label='edit'
                onClick={()=>{

                }}
            ><em className='fa-regular fa-pen text-[#3A3541] opacity-80 mr-2'></em></button>
            <button aria-label='view'
                onClick={()=>{
                    
                }}
            ><em className='fa-regular fa-eye text-[#3A3541] opacity-80'></em></button>
        </>
    }

    const tableDataFormatter = (rowObj: any, format?: string | undefined) => {
        let returningVal = null;
        switch (format) {
            case 'Device Id':
                returningVal = (
                    <Link to={`/endpoint/devices/profile/${rowObj?._id}`} >
                        <span className="text-sm hover:text-gray-900 font-medium text-[#FF5e0e] opacity-80 cursor-pointer">{rowObj?.agent_profile?.AdminInfos?.ID}</span>
                    </Link>
                );
                break;

            case 'Hostname':
                returningVal = (
                    <span className="relative group">
                        <span className="text-sm hover:text-gray-900 font-medium opacity-80">{rowObj?.agent_profile?.AdminInfos?.Name || ''}</span>
                        <Tooltip value={`${rowObj?.agent_profile?.AdminInfos?.Name || ''} / ${rowObj?.agent_profile?.AgentInfos?.ComputerName || ''}`} />
                    </span>
                );
                break;

            case 'OS':
                returningVal=(
                    <>
                        <span className="relative group">
                            <span className="text-sm font-medium opacity-80">{deviceSymbol(rowObj?.AgentInfos?.System)}</span>
                            <Tooltip value={rowObj?.AgentInfos?.System || 'Unknown'} />
                        </span>
                    </>
                    );
                break;
            
            case 'Device Status':

                returningVal = <div className="text-xs px-3 py-1 text-black-600 font-light bg-opacity-10 bg-black-600 max-w-min rounded-full">Unknown</div>

                let deviceStatus: ReactNode | null = null

                // installed | pending
                // 

                // pending endpoint
                deviceStatus = endpointData.pending_installation.find((ed : any) => ed._id === rowObj._id) ? <div className="text-xs px-3 py-1 text-orange-500 font-light bg-orange-100 max-w-min rounded-full">Pending</div> : null
                if( deviceStatus ) {
                    returningVal = deviceStatus;
                    deviceStatus = null;
                }

                // protected endpoints
                deviceStatus = endpointData?.endpoints.filter((ep :any) => {
                    // all deleted and unprotected
                    let arrTemp: any[] = [];
                    // if not deleted
                    let deletedIds: any = endpointData?.deleted.map((uep:any)=>{ return uep._id });
                    arrTemp.concat(deletedIds);
                    // if not unprotected
                    let unprotectedIds: any = endpointData?.unprotected.map((uep:any)=>{ return uep._id });
                    arrTemp.concat(unprotectedIds);

                    return arrTemp.includes(ep._id) ? false : true
                }).find((ed : any) => ed._id === rowObj._id) ? <div className="text-xs px-3 py-1 text-green-500 font-light bg-green-100 max-w-min rounded-full">Protected</div> : null
                if( deviceStatus ) {
                    returningVal = deviceStatus;
                    deviceStatus = null;
                }
                
                // unprotected endpoints
                deviceStatus = endpointData.unprotected.find((ed : any) => ed._id === rowObj._id) ? <div className="text-xs px-3 py-1 text-red-500 font-light bg-red-100 max-w-min rounded-full">Unprotected</div> : null
                if( deviceStatus ) {
                    returningVal = deviceStatus;
                    deviceStatus = null;
                }
                
                // deleted endpoints
                deviceStatus = endpointData.deleted.find((ed : any) => ed._id === rowObj._id) ? <div className="text-xs px-3 py-1 text-red-500 font-light bg-red-100 max-w-min rounded-full">Deleted</div> : null
                if( deviceStatus ) {
                    returningVal = deviceStatus;
                    deviceStatus = null;
                }
                break;

            case 'Last Connection':
                returningVal=(
                    <div className="text-sm font-medium opacity-70">{lastConnectionFormatter(rowObj?.NetworkInfos?.LastConnectionTime || '')}</div>
                );
                break;

            case 'Last Backup':
                returningVal=(
                    <div className="text-sm font-medium opacity-70">{lastBackupFormatter(rowObj?.NetworkInfos?.LastCompletedSession || '')}</div>
                );
                break;

            case 'Protected Data':
                returningVal=(
                    <>
                        <div className="text-sm font-medium opacity-70">{protectedDataFormatter(rowObj?.ServerInfos?.Backup?.ProtSize || 0)}</div>
                    </>
                );
                break;
        
            default:
                returningVal = rowObj;
                break;
        }
        return returningVal;
    }

    const handleSwitch = () => {}

    const getEndpoints = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( (tableController.data || []).length > 0 || selectedData !== '' ) {
            return;
        }

        const fetchEndpoints = async () => {
            await getEndpoints().then((data)=>{
                if(data) {
                    setEndpointData(data);
                    console.log({data})
                    setTableController((prevState) => {
                        let newState = {...prevState};
                        newState.data = data?.endpoints || [];
                        return newState;
                    });
                }
            }).catch((err)=>{
                console.log({err})
            });
        }

        fetchEndpoints();

    },[tableController.data]);

    useEffect(()=>{
        if(tableController.searchTerm.length < 1) {
            if(endpointData) {
                setTableController((prevState)=>{
                    let newState = {...prevState};
                    newState.data = endpointData?.endpoints || [];
                    return newState;
                })
                setSelectedData('All');
            }
            return;
        }

        let searchedNodes = (endpointData?.endpoints || []).filter((ep:any) => {
            if(
                ( ep?.agent_id || '' ).toLowerCase().includes(tableController.searchTerm) ||
                ( ep?.agent_profile?.AdminInfos?.Name || '' ).toLowerCase().includes(tableController.searchTerm) ||
                ( ep?.agent_profile?.AgentInfos?.ComputerName || '' ).toLowerCase().includes(tableController.searchTerm) ||
                ( ep?.agent_profile?.AgentInfos?.Hostname || '' ).toLowerCase().includes(tableController.searchTerm) ||
                ( ep?.agent_profile?.AgentInfos?.System || '' ).toLowerCase().includes(tableController.searchTerm)
            ) {
                return ep;
            }
        }) || [];

        setTableController((prevState: any)=>{
            let newState = {...prevState};
            setSelectedData('Search');
            newState.data = searchedNodes || [];
            return newState;
        })

    },[tableController.searchTerm]);

    // const ShowBackupHistory = () => {
    //     // setBackupTrend(false)
    // }

    // const ShowBackupTrend=()=>{
    //     // setBackupTrend(true)
    // }

    return(
        <>
            <div className={`p-6 grow flex flex-col`} ref={pageTop}>
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Page Template</h1>
                {/* <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-bell-on', link: '', name: 'Home' },
                    { icon: '', link: '', name: 'Test' },
                    { icon: '', link: '', name: 'Template' },
                    { icon: '', link: '', name: 'Test' },
                    { icon: '', link: '', name: 'Template' },
                    { icon: '', link: '', name: 'Test' },
                    { icon: '', link: '', name: 'Template' },
                    { icon: '', link: '', name: 'Test' },
                    { icon: '', link: '', name: 'Template' },
                    { icon: '', link: '', name: 'Test' },
                    { icon: '', link: '', name: 'Template' },
                    { icon: '', link: '', name: 'Test' },
                    { icon: '', link: '', name: 'Template' },
                    { icon: '', link: '', name: 'Test' },
                    { icon: '', link: '', name: 'Template' },
                    { icon: '', link: '', name: 'Test' },
                    { icon: '', link: '', name: 'Template' },
                    { icon: '', link: '', name: 'Test' },
                    { icon: '', link: '', name: 'Template' },
                    { icon: '', link: '', name: 'Test' },
                    { icon: '', link: '', name: 'Template' },
                    { icon: '', link: '', name: 'Test' },
                    { icon: '', link: '', name: 'Template' },
                    { icon: '', link: '', name: 'Test' },
                    { icon: '', link: '', name: 'Template' },
                    { icon: '', link: '', name: 'Test' },
                    { icon: '', link: '', name: 'Template' },
                    { icon: '', link: '', name: 'Test' },
                    { icon: '', link: '', name: 'Template' },
                    { icon: '', link: '', name: 'Test' },
                    { icon: '', link: '', name: 'Template' },
                ]} /> */}
                <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
                    <div className="bg-blue-500 h-24 text-white items-center justify-center flex">1</div>
                    <div className="bg-blue-500 h-24 text-white items-center justify-center flex">2</div>
                    <div className="bg-blue-500 h-24 text-white items-center justify-center flex">3</div>
                    <div className="bg-blue-500 h-24 text-white items-center justify-center flex">4</div>
                </div>



                <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
                    <div className="w-full">
                        <Tile5x4_ChartText 
                        sideBar={false}
                        clickNavigate={false}
                        navigationPath='/'
                        title={
                            < div className="flex items-center">
                                    Total Protected Data
                                {/* <small className="text-green-600  p-1 px-2  border-green-200 text-xs "><span className=" text-green-600"></span><i className="fa-solid fa-check bg-green-100 text-green-600 border border-green-300 rounded-full w-4 h-4 ml-1 text-xs "/></small> */}
                            </div>}
                        description=""
                        value={<em className="fa-duotone fa-shield-check text-5xl text-[#ff5e0e]"></em>}
                        // textBackground="bg-green-100"
                        // textValue={<></>}
                        // textColor="text-green-600"
                        // textArea=""
                        // inChartMain='20' inChartSub="License"
                        // alert={false}
                        // live={false}
                        // designOption="chart"
                        // chartType="donut" 
                        // chartLabels={[]} 
                        // chartDataset={[
                        //     {
                        //         label: '# of Votes',
                        //         data: [],
                        //         backgroundColor: [
                        //         ],
                        //         borderWidth: 5,
                        //         cutout: 43,
                        //         borderRadius: 20
                        //     },

                        // ]}
                        // chartOptions={{
                        //     responsive: true,
                        //     plugins: {
                        //         legend: { display: false },
                        //         title: { display: false },
                        //     },
                        //     scales: {
                        //         x: { display: false },
                        //         y: { display: false },
                        //     },
                        // }}
                        // inChartMain='' inChartSub=""
                        className='border relative'
                        tileMode='default'
                        alert={false}
                        live={false}
                    >
                        <div className="text-4xl text-[#ff5e0e] w-28 h-28 flex items-center text-right justify-end rounded-md absolute whitespace-nowrap right-4">
                            { 12313}
                        </div>
                        </Tile5x4_ChartText>
                    </div>
                    <div className="w-full">
                        <Tile5x4_ChartText 
                        sideBar={false}
                        clickNavigate={false}
                        navigationPath='/'
                        title={
                            < div className="flex items-center">
                                    Total Protected Data
                                {/* <small className="text-green-600  p-1 px-2  border-green-200 text-xs "><span className=" text-green-600"></span><i className="fa-solid fa-check bg-green-100 text-green-600 border border-green-300 rounded-full w-4 h-4 ml-1 text-xs "/></small> */}
                            </div>}
                        description=""
                        value={<em className="fa-duotone fa-shield-check text-5xl text-[#ff5e0e]"></em>}
                        // textBackground="bg-green-100"
                        // textValue={<></>}
                        // textColor="text-green-600"
                        // textArea=""
                        // inChartMain='20' inChartSub="License"
                        // alert={false}
                        // live={false}
                        // designOption="chart"
                        // chartType="donut" 
                        // chartLabels={[]} 
                        // chartDataset={[
                        //     {
                        //         label: '# of Votes',
                        //         data: [],
                        //         backgroundColor: [
                        //         ],
                        //         borderWidth: 5,
                        //         cutout: 43,
                        //         borderRadius: 20
                        //     },

                        // ]}
                        // chartOptions={{
                        //     responsive: true,
                        //     plugins: {
                        //         legend: { display: false },
                        //         title: { display: false },
                        //     },
                        //     scales: {
                        //         x: { display: false },
                        //         y: { display: false },
                        //     },
                        // }}
                        // inChartMain='' inChartSub=""
                        className='border relative'
                        tileMode='default'
                        alert={false}
                        live={false}
                    >
                        <div className="text-4xl text-[#ff5e0e] w-28 h-28 flex items-center text-right justify-end rounded-md absolute whitespace-nowrap right-4">
                            { 12313}
                        </div>
                        </Tile5x4_ChartText>
                    </div>
                    <div className="w-full">
                        <Tile5x4_ChartText 
                        sideBar={false}
                        clickNavigate={false}
                        navigationPath='/'
                        title={
                            < div className="flex items-center">
                                    Total Protected Data
                                {/* <small className="text-green-600  p-1 px-2  border-green-200 text-xs "><span className=" text-green-600"></span><i className="fa-solid fa-check bg-green-100 text-green-600 border border-green-300 rounded-full w-4 h-4 ml-1 text-xs "/></small> */}
                            </div>}
                        description=""
                        value={<em className="fa-duotone fa-shield-check text-5xl text-[#ff5e0e]"></em>}
                        // textBackground="bg-green-100"
                        // textValue={<></>}
                        // textColor="text-green-600"
                        // textArea=""
                        // inChartMain='20' inChartSub="License"
                        // alert={false}
                        // live={false}
                        // designOption="chart"
                        // chartType="donut" 
                        // chartLabels={[]} 
                        // chartDataset={[
                        //     {
                        //         label: '# of Votes',
                        //         data: [],
                        //         backgroundColor: [
                        //         ],
                        //         borderWidth: 5,
                        //         cutout: 43,
                        //         borderRadius: 20
                        //     },

                        // ]}
                        // chartOptions={{
                        //     responsive: true,
                        //     plugins: {
                        //         legend: { display: false },
                        //         title: { display: false },
                        //     },
                        //     scales: {
                        //         x: { display: false },
                        //         y: { display: false },
                        //     },
                        // }}
                        // inChartMain='' inChartSub=""
                        className='border relative'
                        tileMode='default'
                        alert={false}
                        live={false}
                    >
                        <div className="text-4xl text-[#ff5e0e] w-28 h-28 flex items-center text-right justify-end rounded-md absolute whitespace-nowrap right-4">
                            { 12313}
                        </div>
                        </Tile5x4_ChartText>
                    </div>
                    <div className="w-full">
                        <Tile5x4_ChartText 
                        sideBar={false}
                        clickNavigate={false}
                        navigationPath='/'
                        title={
                            < div className="flex items-center">
                                    Total Protected Data
                                {/* <small className="text-green-600  p-1 px-2  border-green-200 text-xs "><span className=" text-green-600"></span><i className="fa-solid fa-check bg-green-100 text-green-600 border border-green-300 rounded-full w-4 h-4 ml-1 text-xs "/></small> */}
                            </div>}
                        description=""
                        value={<em className="fa-duotone fa-shield-check text-5xl text-[#ff5e0e]"></em>}
                        // textBackground="bg-green-100"
                        // textValue={<></>}
                        // textColor="text-green-600"
                        // textArea=""
                        // inChartMain='20' inChartSub="License"
                        // alert={false}
                        // live={false}
                        // designOption="chart"
                        // chartType="donut" 
                        // chartLabels={[]} 
                        // chartDataset={[
                        //     {
                        //         label: '# of Votes',
                        //         data: [],
                        //         backgroundColor: [
                        //         ],
                        //         borderWidth: 5,
                        //         cutout: 43,
                        //         borderRadius: 20
                        //     },

                        // ]}
                        // chartOptions={{
                        //     responsive: true,
                        //     plugins: {
                        //         legend: { display: false },
                        //         title: { display: false },
                        //     },
                        //     scales: {
                        //         x: { display: false },
                        //         y: { display: false },
                        //     },
                        // }}
                        // inChartMain='' inChartSub=""
                        className='border relative'
                        tileMode='default'
                        alert={false}
                        live={false}
                    >
                        <div className="text-4xl text-[#ff5e0e] w-28 h-28 flex items-center text-right justify-end rounded-md absolute whitespace-nowrap right-4">
                            { 12313}
                        </div>
                        </Tile5x4_ChartText>
                    </div>
                    <div className="w-full">
                        <Tile5x4_ChartText 
                        sideBar={false}
                        clickNavigate={false}
                        navigationPath='/'
                        title={
                            < div className="flex items-center">
                                    Total Protected Data
                                {/* <small className="text-green-600  p-1 px-2  border-green-200 text-xs "><span className=" text-green-600"></span><i className="fa-solid fa-check bg-green-100 text-green-600 border border-green-300 rounded-full w-4 h-4 ml-1 text-xs "/></small> */}
                            </div>}
                        description=""
                        value={<em className="fa-duotone fa-shield-check text-5xl text-[#ff5e0e]"></em>}
                        // textBackground="bg-green-100"
                        // textValue={<></>}
                        // textColor="text-green-600"
                        // textArea=""
                        // inChartMain='20' inChartSub="License"
                        // alert={false}
                        // live={false}
                        // designOption="chart"
                        // chartType="donut" 
                        // chartLabels={[]} 
                        // chartDataset={[
                        //     {
                        //         label: '# of Votes',
                        //         data: [],
                        //         backgroundColor: [
                        //         ],
                        //         borderWidth: 5,
                        //         cutout: 43,
                        //         borderRadius: 20
                        //     },

                        // ]}
                        // chartOptions={{
                        //     responsive: true,
                        //     plugins: {
                        //         legend: { display: false },
                        //         title: { display: false },
                        //     },
                        //     scales: {
                        //         x: { display: false },
                        //         y: { display: false },
                        //     },
                        // }}
                        // inChartMain='' inChartSub=""
                        className='border relative'
                        tileMode='default'
                        alert={false}
                        live={false}
                    >
                        <div className="text-4xl text-[#ff5e0e] w-28 h-28 flex items-center text-right justify-end rounded-md absolute whitespace-nowrap right-4">
                            { 12313}
                        </div>
                        </Tile5x4_ChartText>
                    </div>
                    <div className="w-full">
                        <Tile5x4_ChartText 
                        sideBar={false}
                        clickNavigate={false}
                        navigationPath='/'
                        title={
                            < div className="flex items-center">
                                    Total Protected Data
                                {/* <small className="text-green-600  p-1 px-2  border-green-200 text-xs "><span className=" text-green-600"></span><i className="fa-solid fa-check bg-green-100 text-green-600 border border-green-300 rounded-full w-4 h-4 ml-1 text-xs "/></small> */}
                            </div>}
                        description=""
                        value={<em className="fa-duotone fa-shield-check text-5xl text-[#ff5e0e]"></em>}
                        // textBackground="bg-green-100"
                        // textValue={<></>}
                        // textColor="text-green-600"
                        // textArea=""
                        // inChartMain='20' inChartSub="License"
                        // alert={false}
                        // live={false}
                        // designOption="chart"
                        // chartType="donut" 
                        // chartLabels={[]} 
                        // chartDataset={[
                        //     {
                        //         label: '# of Votes',
                        //         data: [],
                        //         backgroundColor: [
                        //         ],
                        //         borderWidth: 5,
                        //         cutout: 43,
                        //         borderRadius: 20
                        //     },

                        // ]}
                        // chartOptions={{
                        //     responsive: true,
                        //     plugins: {
                        //         legend: { display: false },
                        //         title: { display: false },
                        //     },
                        //     scales: {
                        //         x: { display: false },
                        //         y: { display: false },
                        //     },
                        // }}
                        // inChartMain='' inChartSub=""
                        className='border relative'
                        tileMode='default'
                        alert={false}
                        live={false}
                    >
                        <div className="text-4xl text-[#ff5e0e] w-28 h-28 flex items-center text-right justify-end rounded-md absolute whitespace-nowrap right-4">
                            { 12313}
                        </div>
                        </Tile5x4_ChartText>
                    </div>
                </div>
                <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
                    <Tile colSpan={12} rowSpan={12} className="pt-2 relative" >
                        <div className="p-4 flex md:flex-row md:gap-4 gap-2" ref={scrollerRef}>
                            <div className=" text-gray-800 font-medium">Devices</div>
                            <label htmlFor="searchBox" className="search flex items-center border rounded-md px-3 py-[2px]">
                                <em className="fa-solid fa-search text-slate-400 pr-2"></em>
                                <input 
                                    type="text" 
                                    id="searchBox"
                                    placeholder="Search hostname" 
                                    className="outline-none w-full" 
                                    value={tableController.searchTerm} 
                                    onChange={(e)=>{
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.searchTerm = e.target.value;
                                            return newState;
                                        });
                                    }}
                                />
                                <em className="fa-solid fa-times text-slate-200 hover:text-slate-500 cursor-pointer pl-2"
                                    onClick={()=>{
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.searchTerm = '';
                                            return newState;
                                        });
                                    }}
                                ></em>
                            </label>
                            <div className="grow"></div>
                            <div className="text-[#ff5e0e] font-light px-3 py-1 block lg:hidden">Filter</div>
                            <ButtonGroup className="border hidden lg:block whitespace-nowrap">
                                <Button size='sm' className="" solid={selectedData === 'All' || selectedData === '' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('All')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.data = endpointData?.endpoints || [];
                                            return newState;
                                        })
                                    }}
                                >All</Button>
                                <Button size='sm' className="" solid={selectedData === 'Protected' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Protected')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.data = (endpointData?.endpoints || []).filter((ep :any) => {
                                                return (endpointData?.unprotected || []).map((uep:any)=>{
                                                    return uep._id
                                                }).includes(ep._id) ? false : true
                                            });
                                            return newState;
                                        })
                                    }}
                                >Protected</Button>
                                <Button size='sm' className="" solid={selectedData === 'Unprotected' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Unprotected')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.data = endpointData?.unprotected || [];
                                            return newState;
                                        })
                                    }}
                                >Unprotected</Button>
                                <Button size='sm' className="" solid={selectedData === 'Installed' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Installed')
                                        setTableController((prevState)=>{
                                            let newState = prevState;
                                            newState.data = endpointData?.installed || [];
                                            return newState;
                                        })
                                    }}
                                >Installed</Button>
                                <Button size='sm' className="" solid={selectedData === 'Pending Installation' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Pending Installation')
                                        setTableController((prevState)=>{
                                            let newState = prevState;
                                            newState.data = endpointData?.pending_installation || [];
                                            return newState;
                                        })
                                    }}
                                >Pending Installation</Button>
                                <Button size='xs' className="" solid={selectedData === 'Deleted' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Deleted')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.data = endpointData?.deleted || [];
                                            return newState;
                                        })
                                    }}
                                >Deleted</Button>
                            </ButtonGroup>
                        </div>
                        <Table
                            navRef={navRaf}
                            actionButtonGenerator={actionButtonGenerator}
                            columns={tableColumns}
                            setColumns={setTableColumns}
                            formatData={tableDataFormatter}
                            showActionCell={false}
                            showActionHeaderCell={false}
                            showColumnManagementMenu={false}
                            switchHandler={handleSwitch}
                            tableController={tableController}
                            tableId="DeviceTable"
                        />
                    </Tile>
                    <Tile colSpan={12} rowSpan={13} className="pt-2 relative" >
                        <div className="p-4 flex md:flex-row items-center md:gap-4 gap-2 border-b" ref={scrollerRef}>
                            <div className=" text-gray-800 font-medium flex flex-col">
                                <span>Devices</span>
                                <span className="text-gray-400 text-xs">Showing the total backup trends of last one day</span>
                            </div>
                            <div className="grow"></div>
                            <div className="text-[#ff5e0e] font-light px-3 py-1 block lg:hidden">Filter</div>
                            <ButtonGroup className="border hidden lg:block whitespace-nowrap">
                                <Button size='sm' className="" solid={selectedData === 'All' || selectedData === '' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('All')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.data = endpointData?.endpoints || [];
                                            return newState;
                                        })
                                    }}
                                >All</Button>
                                <Button size='sm' className="" solid={selectedData === 'Protected' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Protected')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.data = (endpointData?.endpoints || []).filter((ep :any) => {
                                                return (endpointData?.unprotected || []).map((uep:any)=>{
                                                    return uep._id
                                                }).includes(ep._id) ? false : true
                                            });
                                            return newState;
                                        })
                                    }}
                                >Protected</Button>
                                <Button size='sm' className="" solid={selectedData === 'Unprotected' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Unprotected')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.data = endpointData?.unprotected || [];
                                            return newState;
                                        })
                                    }}
                                >Unprotected</Button>
                                <Button size='sm' className="" solid={selectedData === 'Installed' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Installed')
                                        setTableController((prevState)=>{
                                            let newState = prevState;
                                            newState.data = endpointData?.installed || [];
                                            return newState;
                                        })
                                    }}
                                >Installed</Button>
                                <Button size='sm' className="" solid={selectedData === 'Pending Installation' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Pending Installation')
                                        setTableController((prevState)=>{
                                            let newState = prevState;
                                            newState.data = endpointData?.pending_installation || [];
                                            return newState;
                                        })
                                    }}
                                >Pending Installation</Button>
                                <Button size='xs' className="" solid={selectedData === 'Deleted' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Deleted')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.data = endpointData?.deleted || [];
                                            return newState;
                                        })
                                    }}
                                >Deleted</Button>
                            </ButtonGroup>
                        </div>
                        <div className="p-4 h-80 cursor-move">
                            <MasterChart
                                datasets={[
                                        {
                                            backgroundColor: 'rgba(255, 94, 14, 0.3)',
                                            borderColor: 'rgb(255, 94, 14)',
                                            data: [],
                                            fill: false,
                                            label: 'Delta Change'.toUpperCase(),
                                        },
                                        {
                                            backgroundColor: 'rgba(255, 94, 14)',
                                            borderColor: 'rgb(203, 213, 225)',
                                            data: [],
                                            fill: false,
                                            label: `Current Change`.toUpperCase(),
                                        },
                                    ]}
                                labels={['January','February','March','April','May','June','July']}
                                options={{
                                    // hover: {
                                    //     onHover: function(e:any,el:any) {
                                    //     //   $("#id-of-your-canvas").css("cursor", e[0] ? "pointer" : "default");
                                    //     //   $("#id-of-your-canvas").css("cursor", e[0] ? "pointer" : "default");
                                    //     }
                                    // },
                                    plugins: {
                                        chartAreaBorder: {
                                            borderColor: 'red',
                                            borderWidth: 2,
                                            borderDash: [5, 5],
                                            borderDashOffset: 2,
                                        },
                                        legend: {
                                            display: true,
                                            position: 'bottom'
                                        },
                                            title: {
                                            display: false,
                                            text: 'Line Chart'
                                        },
                                        datalabels: {
                                            display: true,
                                            color: "black",
                                            formatter: (a:any,b:any)=>{
                                                return a.toFixed(2) + ' MB'
                                            },
                                            anchor: "end",
                                            offset: -20,
                                            align: "start"
                                        },
                                        zoom: {
                                            pan: {
                                                enabled: true,
                                                mode: 'x',
                                                borderColor: 'rgba(255,0,0,1)',
                                                borderWidth: '3',
                                                backgroundColor: 'rgba(0,255,9,1)',
                                            //   modifierKey: 'shift'
                                            },
                                            zoom: {
                                                drag: {
                                                    enabled: true,
                                                    modifierKey: 'shift',
                                                    ondragstart: {},
                                                    onPan: function () {
                                                        // console.log("PAN");
                                                        // leftEnd = myChartA.getDatasetMeta(0).dataset._scale.chart.scales['x-axis-0']._table[0].time;
                                                        // rightEnd = myChartA.getDatasetMeta(0).dataset._scale.chart.scales['x-axis-0']._table[1].time;
                        
                                                        // updateChart();
                                                    }
                                                },
                                                pinch: {
                                                    enabled: true,
                                                },
                                                wheel: {
                                                    enabled: true,
                                                },
                                                zoom: {
                                                    enabled: true,
                                                    mode: 'x',
                                                    onZoom: function () {
                                                        // console.log("zoom");
                                                        // leftEnd = myChartA.getDatasetMeta(0).dataset._scale.chart.scales['x-axis-0']._table[0].time;
                                                        // rightEnd = myChartA.getDatasetMeta(0).dataset._scale.chart.scales['x-axis-0']._table[1].time;
                        
                                                        // updateChart();
                                                    }
                                                },
                                                mode: 'x'
                                            },
                                            limits: {
                                            x: {
                                                minDelay: 0,
                                                maxDelay: 4000,
                                                minDuration: 1000,
                                                maxDuration: 20000
                                            }
                                            }
                                        }
                                        // zoom: {
                                        //     pan: {
                                        //         enabled: true,
                                        //         mode: 'xy',
                                        //         // modifierKey: 'ctrl',
                                        //         overScaleMode: 'xy',
                                        //         threshold: 1,
                                        //         onPan: {},
                                        //         onPanComplete: {},
                                        //         onPanRejected: {},
                                        //         onPanStart: {}
                                        //     },
                                        //     limits: {
                                        //         // y: {min: 20, max: 50},
                                        //         // x: {min: 20, max: 50}
                                        //     },
                                        //     zoom: {
                                        //         drag: {
                                        //             enabled: true,
                                        //             backgroundColor: 'rgba(225,225,225,0.3)',
                                        //             borderColor: 'rgba(225,225,225)',
                                        //             borderWidth: 0,
                                        //             threshold: 1,
                                        //             modifierKey: 'ctrl'
                                        //         },
                                        //         wheel: {
                                        //             enabled: true,
                                        //             speed: 1,
                                        //             // modifierKey: 'ctrl'
                                        //         },
                                        //         pinch: {
                                        //             enabled: true,
                                        //             onZoom: {},
                                        //             onZoomComplete: {},
                                        //             onZoomRejected: {},
                                        //             onZoomStart: {},
                                        //         },
                                        //         mode: 'x',
                                        //     }
                                        // }
                                    },
                                    responsive: true,
                                    maintainAspectRatio: false, 
                                    lineTension: 0.45,
                                    bezierCurve: false,     
                                    scales: {
                                        x: {
                                            // type: 'realtime',
                                            // realtime: {
                                            //     duration: 20000
                                            // },
                                            display: true,
                                            min: -20,
                                        },
                                        y: {
                                            display: true,
                                            position: 'left',
                                            type: 'linear',
                                        },
                                    }
                                }}
                                type='bar'
                            />
                        </div>
                    </Tile>
                </div>
            </div>

        </>
    )
}

export default PageTemplate

// function setBackupTrend(arg0: boolean) {
//     throw new Error("Function not implemented.");
// }
// function setSelectedData(arg0: string) {
//     throw new Error("Function not implemented.");
// }

