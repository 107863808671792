import React, { FC, ReactNode, useContext } from "react";
import { ThemeContext } from "../../../contexts";
interface Props {
    colSpan?: number;
    rowSpan?: number;
    className?: string;
    children?: ReactNode | ReactNode[];
    onClick?: ()=>void;
}
/**
 * 
 * h-[30px] h-[60px] h-[90px] h-[120px]
 * h-[150px] h-[180px] h-[210px] h-[240px]
 * h-[270px] h-[300px] h-[330px] h-[360px]
 * h-[390px] h-[420px] h-[450px] h-[480px]
 * h-[510px] h-[540px] h-[570px] h-[600px]
 * h-[630px] h-[660px] h-[720px] h-[750px]
 * h-[780px] h-[810px] h-[840px] h-[870px]
 * h-[900px] h-[930px] h-[960px] h-[990px]
 * 
 * @param param0
 * @returns
 */
const Tile:FC<Props> = ({
    colSpan=4,
    rowSpan=3,
    className='',
    children,
    ...rest
}) => {
    const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    return (
        <div className={`tile relative lg:col-span-${colSpan} md:col-span-6 col-span-12 row-span-${rowSpan} hover:touch-pinch-zoom h-[${rowSpan*30}px] rounded-lg bg-${theme.themeColor}-${theme.themeContrast}-component-background shadow hover:shadow-md ${className}`} {...rest}>
            {children}
        </div>
    );
}
export default Tile;