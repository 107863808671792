/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "../../components/core";
import ButtonGroup from "../../components/core/ButtonGroup/ButtonGroup";
// import Tooltip from "../../components/core/Tooltip/Tooltip";
import { getAPICall, postAPICall } from "../../services/APILayer/axiosMethodCalls";
import { deviceSymbol, getDeviceStatus, getEndpointConfig } from '../../utils/agentUtils'
import { convertUTCtoLocalDate, dataSizeConversion, formatSeconds, getFormattedDateTime } from '../../utils/format'
import { SideDrawerContext } from "../../contexts";
// import Strategy from "../Strategy";
import { formatSize } from "../../utils/backupFileUtils";
import { DateRangePicker } from "react-date-range";
import OutsideClickHandler from 'react-outside-click-handler';


const ActivityCard = () => {

    /* sideDrawer context */
    const sideDrawerContext = useContext(SideDrawerContext);

    /* Data Filter */
    const [dataFilter, setDataFilter] = useState<string>('Last 24 Hrs');
    const [requestFilter, setRequestFilter] = useState<string>('1d');

    // Step One fetch all activity data
    const [activityCallActive, setActivityCallActive] = useState<boolean>(true);
    const [rawActivity, setRawActivity] = useState<any>({}); // new api
    const [activityList, setActivityList] = useState<any[]>([]); // old api

    const [rawPoliciesStrategies,setPoliciesStrategies] = useState<any>({});

    const today = new Date();
    const twentyFourHour = new Date(today.setDate(today.getDate() - 1));
    const fortyEightHour = new Date(today.setDate(today.getDate() - 2));
    const seventyTwoHours = new Date(today.setDate(today.getDate() - 3));
    const [calenderVisibleState, setCalenderVisibleState] = useState<boolean>(false);
    const [activityCalenderState, setActivityCalenderState] = useState<any[]>([
        {
          startDate: twentyFourHour,
          endDate: twentyFourHour,
          key: 'selection',
          color: "#FF5E0E"
        }
    ]);

    interface IUpdatedActivityData {
        data: any[];
        endpoints: any[];
        total: number;
        total_text: string;
        unit: string;
    }

    // const [updatedActivities, setUpdatedActivities] = useState<{[key: string]: IUpdatedActivityData}>({
    //     'endpoints-backup': {data: [], endpoints: [], total: 0, total_text: '0 B', unit: 'B'},
    //     'endpoints-policy-change': {data: [], endpoints: [], total: 0, total_text: '0 B', unit: 'B'},
    //     'endpoints-restore': {data: [], endpoints: [], total: 0, total_text: '0 B', unit: 'B'},
    //     'endpoints-strategy-change': {data: [], endpoints: [], total: 0, total_text: '0 B', unit: 'B'},
    // })

    const getActivityData = async () => {
        try {
            const response = await postAPICall(
                `${process.env.REACT_APP_API_URL}/analytics/endpoints_recent_activities`,
                {
                    "filter":{
                        "range": requestFilter
                    }
                }
            );
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const getPoliciesStrategies = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints/config`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const getEndpointAuditData = async (filter: any) => {
        try {
            const response = await postAPICall(
                `${process.env.REACT_APP_API_URL}/analytics/endpoints_audit_v2_report`,
                {
                    "filter":{
                        // start_date: "2023-03-01",
                        // end_date: "2023-04-15",
                        // activity_type:[ "endpoints-backup", "endpoints-restore", "endpoints-strategy-change", "endpoints-policy-change" ]
                        ...filter
                    }
                }
            );
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const checkQuery = async () => {

        // @TODO require loader on modal
        setActivityCallActive(true)

        let _startDate = (activityCalenderState[0].startDate);
        let _endDate = (activityCalenderState[0].endDate);

        let param = {
            start_date: `${(_startDate.getFullYear())}-${('00'+(_startDate.getMonth()+1)).slice(-2)}-${('00'+_startDate.getDate()).slice(-2)}`,
            end_date: `${(_endDate.getFullYear())}-${('00'+(_endDate.getMonth()+1)).slice(-2)}-${('00'+_endDate.getDate()).slice(-2)}`,
            activity_type:[
                "endpoints-backup",
                "endpoints-restore",
                "endpoints-strategy-change",
                "endpoints-policy-change"
            ]
        }
        
        await getEndpointAuditData(param).then((data)=>{
            console.log({_data:data})
            setRawActivity(data)
        }).catch((error)=>{
            console.log({error})
        }).finally(()=>{
            setActivityCallActive(false)
        })
        
    }

    useEffect(()=>{
        setDataFilter('Last 24 Hrs')
        setRequestFilter('1d')
        getActivityData().then((res)=>{
            console.log({res})
            setRawActivity(res)
        }).catch((err)=>{
            console.log(err)
        })
        // setActivityCalenderState((previousState:any[])=>{
        //     return [{
        //         startDate: twentyFourHour,
        //         endDate: new Date(),
        //         key: 'selection',
        //         color: "#FF5E0E"
        //     }]
        // })
    },[])

    // console.log(activityCalenderState)

    useEffect(()=>{
        checkQuery();
    },[activityCalenderState])

    useEffect(()=>{

        var finalActivities = []

        if( dataFilter === 'Date Range' ) {

            if( rawActivity?.activities?.['endpoints-backup']?.total > 0 ) {
                finalActivities.push({
                    "activity_type": "endpoints-backup",
                    "comment": `Total ${rawActivity?.activities?.['endpoints-backup']?.total_text} data backed up by ${rawActivity?.activities?.['endpoints-backup']?.endpoints.length} device(s).`
                })
            }
    
            if( rawActivity?.activities?.['endpoints-restore']?.total > 0 ) {
                finalActivities.push({
                    "activity_type": "endpoints-restore",
                    "comment": `Total ${rawActivity?.activities?.['endpoints-restore']?.total_text} data restored by ${rawActivity?.activities?.['endpoints-restore']?.endpoints.length} device(s).`
                })
            }
    
            if( rawActivity?.activities?.['endpoints-policy-change']?.total > 0 ) {
                finalActivities.push({
                    "activity_type": "endpoints-policy-change",
                    "comment": `${rawActivity?.activities?.['endpoints-policy-change']?.total} times policy changed in ${rawActivity?.activities?.['endpoints-policy-change']?.endpoints.length} device(s).`
                })
            }
    
            if( rawActivity?.activities?.['endpoints-strategy-change']?.total > 0 ) {
                finalActivities.push({
                    "activity_type": "endpoints-strategy-change",
                    "comment": `${rawActivity?.activities?.['endpoints-strategy-change']?.total} times strategy changed in ${rawActivity?.activities?.['endpoints-strategy-change']?.endpoints.length} device(s).`
                })
            }
        } else if( dataFilter === 'Last 24 Hrs' ) {

            console.log({__rawActivity:rawActivity});

            if( Array.isArray(rawActivity?.activities) ) {

                (rawActivity?.activities || [])?.map((a:any)=>{
                    if( a?.activity_type === 'endpoints-backup' ) {
                        finalActivities.push({
                            "activity_type": "endpoints-backup",
                            "comment": `Total ${formatSize(dataSizeConversion(a?.data[0],'MB','B').value).value} ${formatSize(dataSizeConversion(a?.data[0],'MB','B').value).unit} data backed up by ${(a?.endpoints || [])?.length} device(s).`
                        })
                    }
                    if( a?.activity_type === 'endpoints-restore' ) {
                        finalActivities.push({
                            "activity_type": "endpoints-restore",
                            "comment": `Total ${formatSize(dataSizeConversion(a?.data[0],'MB','B').value).value} ${formatSize(dataSizeConversion(a?.data[0],'MB','B').value).unit} data is restored by ${(a?.endpoints || [])?.length} device(s).`
                        })
                    }
                    if( a?.activity_type === 'endpoints-policy-change' ) {
                        finalActivities.push({
                            "activity_type": "endpoints-policy-change",
                            "comment": a?.comment || ''
                        })
                    }
                    if( a?.activity_type === 'endpoints-strategy-change' ) {
                        finalActivities.push({
                            "activity_type": "endpoints-strategy-change",
                            // "comment": `Total ${a?.data[0]} times strategy changes by ${(a?.endpoints || [])?.length} device(s).`
                            "comment": a?.comment || ''
                        })
                    }
                })
            }

        }


        setActivityList(finalActivities)


    },[rawActivity])


    useEffect(()=>{
        if(!activityCallActive) {
            return;
        }

        if( dataFilter === 'Date Range' ) {
            return;
        }

        Promise.all([
            getActivityData(),
            getPoliciesStrategies()
        ]).then((response)=>{

            // activity data
            if((response[0]?.activities || []).length > 0) {
                // setActivityList(response[0]?.activities)
                setRawActivity(response[0])
            } else {
                // setActivityList()
            }

            // strategy policy data

            setPoliciesStrategies(response[1])

        }).catch((err)=>{console.log(err)}).finally(()=>{
            setActivityCallActive(false)
        })

    },[activityList, dataFilter, getActivityData])

    console.log({rawActivity})

    return (
        <>
            {/* <Tile colSpan={1} rowSpan={17} className={`w-full`}> */}
            <div className="tile relative rounded-lg shadow-md w-full bg-white col-span-12 mb-6">
                <div className="flex flex-col justify-center items-center md:flex-row w-full p-4 border-b">
                    <div className="title text-base md:text-lg lg:text-xl mb-2 text-gray-800 font-medium">
                        Recent Activities
                        {activityCallActive && <em className='fa-solid fa-loader fa-spin ml-4'></em>}
                    </div>
                    <div className="grow"></div>
                    <ButtonGroup className="border">
                        <Button 
                            size='sm'
                            solid={dataFilter === 'Last 24 Hrs' ? true : false}
                            onClick={()=>{
                                setActivityCallActive(true)
                                setDataFilter('Last 24 Hrs')
                                setRequestFilter('1d')
                            }}
                        >Last 24 Hrs</Button>
                        {/* <Button 
                            size='sm'
                            solid={dataFilter === 'Last 48 Hrs' ? true : false}
                            onClick={()=>{
                                setActivityCallActive(true)
                                setDataFilter('Last 48 Hrs')
                                setRequestFilter('1d')
                                setActivityCalenderState((previousState:any[])=>{
                                    return [{
                                        startDate: fortyEightHour,
                                        endDate: new Date(),
                                        key: 'selection',
                                        color: "#FF5E0E"
                                    }]
                                })
                            }}
                        >Last 48 Hrs</Button>
                        <Button 
                            size='sm'
                            solid={dataFilter === 'Last 72 Hrs' ? true : false}
                            onClick={()=>{
                                setActivityCallActive(true)
                                setDataFilter('Last 72 Hrs')
                                setRequestFilter('1d')
                                setActivityCalenderState((previousState:any[])=>{
                                    return [{
                                        startDate: seventyTwoHours,
                                        endDate: new Date(),
                                        key: 'selection',
                                        color: "#FF5E0E"
                                    }]
                                })
                            }}
                        >Last 72 Hrs</Button> */}
                        <Button 
                            size='sm'
                            solid={dataFilter === 'Date Range' ? true : false}
                            onClick={()=>{
                                setDataFilter('Date Range')
                                setCalenderVisibleState(!calenderVisibleState)
                                // setActivityCallActive(true)
                                // setDataFilter('Last 24 Hrs')
                                // setRequestFilter('1d')
                            }}
                        >
                            <span>{`${((activityCalenderState[0].startDate).getFullYear())}-${('00'+((activityCalenderState[0].startDate).getMonth()+1)).slice(-2)}-${('00'+(activityCalenderState[0].startDate).getDate()).slice(-2)}`}</span>
                            <span className="mx-2">&mdash;</span>
                            <span>{`${((activityCalenderState[0].endDate).getFullYear())}-${('00'+((activityCalenderState[0].endDate).getMonth()+1)).slice(-2)}-${('00'+(activityCalenderState[0].endDate).getDate()).slice(-2)}`}</span>
                        </Button>
                    </ButtonGroup>
                    <OutsideClickHandler
                        onOutsideClick={()=>{
                            setCalenderVisibleState(false)
                        }}
                    >
                    <div className="absolute right-4 top-14 border border-orange-500 rounded-md overflow-hidden">
                            {( dataFilter === 'Date Range' && calenderVisibleState === true ) && <DateRangePicker
                                onChange={(item:any) => {
                                    setActivityCalenderState([item.selection])
                                }}
                                moveRangeOnFirstSelection={false}
                                months={1}
                                ranges={activityCalenderState}
                                direction={"horizontal"}
                                maxDate={twentyFourHour}
                                editableDateInputs={true}
                            />}
                        </div>
                    </OutsideClickHandler>
                </div>
                <div className="w-full overflow-y-scroll customScroll min-h-[328px] max-h-[445px]">
                    {
                        activityList.length > 0 ?
                        activityList.map((activity, activityIndex)=>(
                            <div 
                                key={activityIndex}
                                className="md:py-3 md:px-8 py-2 px-2 grow flex border-b items-center w-full hover:bg-slate-100 cursor-pointer"
                                onClick={()=>{
                                    /* Open sideDrawer Level One */
                                    sideDrawerContext.setLevelOneDrawerVisibility(false)
                                    sideDrawerContext.setLevelTwoDrawerVisibility(false)
                                    sideDrawerContext.setFirstDrawerChild(<LevelOneComponent rawPoliciesStrategies={rawPoliciesStrategies} requestFilter={requestFilter} dataFilter={dataFilter} selectedData={activity} rawActivity={rawActivity}/>)
                                    sideDrawerContext.setLevelOneDrawerVisibility(true)
                                }}
                            >
                                { activity?.activity_type === 'endpoints-backup' && <i className="fa-regular fa-upload bg-[#FF5E0E] bg-opacity-10 text-[#FF5E0E] p-2 text-xl rounded-full w-8 h-8 items-center flex justify-center"></i> }
                                { activity?.activity_type === 'endpoints-restore' && <i className="fa-regular fa-download bg-[#FF5E0E] bg-opacity-10 text-[#FF5E0E] p-2 text-xl rounded-full w-8 h-8 items-center flex justify-center"></i> }
                                { activity?.activity_type === 'endpoints-policy-change' && <i className="fa-regular fa-list-check bg-[#FF5E0E] bg-opacity-10 text-[#FF5E0E] p-2 text-xl rounded-full w-8 h-8 items-center flex justify-center"></i> }
                                { activity?.activity_type === 'endpoints-strategy-change' && <i className="fa-regular fa-chess-knight bg-[#FF5E0E] bg-opacity-10 text-[#FF5E0E] p-2 text-xl rounded-full w-8 h-8 items-center flex justify-center"></i> }
                                <div className=" flex flex-col grow md:ml-4 mx-2">
                                    <div className="md:text-xl text-base text-gray-700">{activity?.comment || ''}</div>
                                </div>
                                <span className="text-gray-500 whitespace-nowrap text-xs md:text-base">
                                    { activity?.activity_type === 'endpoints-backup' && 'Endpoint / Backup' }
                                    { activity?.activity_type === 'endpoints-restore' && 'Endpoint / Restore' }
                                    { activity?.activity_type === 'endpoints-policy-change' && 'Endpoint / Policy Change' }
                                    { activity?.activity_type === 'endpoints-strategy-change' && 'Endpoint / Strategy Change' }
                                </span>
                                {/* <span className="text-gray-500 nowrap">{activity?.type || ''}</span> */}
                            </div>
                        )) :
                        (
                            <span className="flex w-full items-center justify-center align-middle">
                                <span className="flex flex-col">
                                    <img src='/assets/img/undraw_empty_street_sfxm.svg' alt='well done' className="ml-auto mr-auto h-56 my-6" />
                                    <span className="flex">
                                        {!activityCallActive && <div className="w-full text-slate-500 text-center mb-6 text-2xl">No Activities</div>}
                                        {activityCallActive && <div className="w-full text-slate-500 text-center mb-6 text-2xl">Loading...</div>}
                                    </span>
                                </span>
                            </span>
                        )
                    }
                </div>
            </div>
        </>
    )
}

interface ILevelOneComponent {
    rawPoliciesStrategies: any;
    /* selected activity period e.g. last 24 hrs, last 49 hrs etc */
    dataFilter: any;
    /* activity on which someone clicked on activity card to open sideDrawer */
    selectedData: any;
    /* data filter defining granularity e.g. 1d 2d 1h etc */
    requestFilter: any;
    rawActivity?: any;
}

export const LevelOneComponent:FC<ILevelOneComponent> = ({
    rawPoliciesStrategies,
    selectedData,
    requestFilter,
    dataFilter,
    rawActivity={},
}) => {

    const sideDrawerContext = useContext(SideDrawerContext);

    const [selectedEndpoints, setSelectedEndpoints] = useState<any[]>([]);
    const [endpoints, setEndpoints] = useState<any>({});
    const [filtered, setFiltered] = useState<any[]>([]);

    const getEndpoints = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        console.log({rawPoliciesStrategies,selectedData,requestFilter,dataFilter,rawActivity})
        const fetchEndpoints = async () => {
            await getEndpoints().then((data)=>{
                setEndpoints(data)
            })
        }
        fetchEndpoints();
    },[])

    useEffect(()=>{
        if( Object.keys(endpoints).length < 1 ) { return }

        let data = dataFilter === 'Date Range' ? rawActivity?.activities?.[selectedData?.activity_type] : rawActivity.activities.find((a:any)=>{ return a.activity_type === selectedData?.activity_type })

        console.log({__data:rawActivity})

        let filteredData = [...endpoints?.endpoints].map((ep:any)=>{
            if( (data?.endpoints).includes(`${ep?.server_id}#${ep?.agent_id}`) ) {
                var d = data.data.filter((d:any)=>{ return d.agent_id === ep?.agent_id && d.server === ep?.server_id })
                return {...ep,data: d, status: getDeviceStatus(endpoints, ep)}
            }
        }).filter((ep:any)=>{ return ep !== undefined });

        console.log({filteredData})
        setFiltered(filteredData)
    },[endpoints])

    console.log({filtered})

    // useEffect(()=>{
    //     const fetchEndpoints = async () => {
    //         await getEndpoints().then((data)=>{
    //             if( data?.endpoints ) {
    //                 let filteredEndpoints = (data.endpoints || []).map((ep: any)=>{
    //                     let endpoints = (selectedData?.endpoints || []).find((sep: any)=>{
    //                         if(ep.server_id === sep.split('#')[0] && ep.agent_id === sep.split('#')[1]) {
    //                             return sep
    //                         }
    //                     })
    //                     if(endpoints) {
    //                         return {
    //                             ...ep,
    //                             status: getDeviceStatus(data,ep)
    //                         }
    //                     }
    //                 })
    //                 let finalData = [];
    //                 for (let element of filteredEndpoints) {
    //                     if(element){
    //                         finalData.push(element)
    //                     }
    //                 }
    //                 setSelectedEndpoints(finalData)
    //                 // console.log({finalData})
    //             } else {
    //                 setSelectedEndpoints([])
    //             }
    //         }).catch((err)=>{console.log(err)});
    //     }

    //     fetchEndpoints();
    // },[selectedData])

    return (
        <>
            <div className="title w-full absolute flex gap-4 px-6 top-0 pt-10 border-b pb-10 bg-slate-100 items-center">

                { selectedData?.activity_type === 'endpoints-backup' && <><i className="fa-regular fa-upload text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i></> }
                { selectedData?.activity_type === 'endpoints-restore' && <><i className="fa-regular fa-download text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i></> }
                { selectedData?.activity_type === 'endpoints-policy-change' && <><i className="fa-regular fa-list-check text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i></> }
                { selectedData?.activity_type === 'endpoints-strategy-change' && <><i className="fa-regular fa-chess-knight text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i></> }

                <div className="flex flex-col">
                    { selectedData?.activity_type === 'endpoints-backup' && <><div className="text-xl font-bold text-slate-700">Endpoint Backup</div></> }
                    { selectedData?.activity_type === 'endpoints-restore' && <><div className="text-xl font-bold text-slate-700">Endpoint Restore</div></> }
                    { selectedData?.activity_type === 'endpoints-policy-change' && <><div className="text-xl font-bold text-slate-700">Endpoint Policy Change</div></> }
                    { selectedData?.activity_type === 'endpoints-strategy-change' && <><div className="text-xl font-bold text-slate-700">Endpoint Strategy Change</div></> }
                    <div className="block text-slate-500">{dataFilter}</div>
                </div>

            </div>
            <div className="data absolute top-40 px-4 w-full">
                <div className={`table w-full shadow-md rounded-md overflow-hidden`}>
                    <div className={`thead bg-slate-50 border-y w-full p-2 flex flex-row items-center
                        ${selectedData?.activity_type === 'endpoints-backup' ? 'bg-white' : ''}
                        ${selectedData?.activity_type === 'endpoints-restore' ? 'bg-white' : ''}
                        ${selectedData?.activity_type === 'endpoints-policy-change' ? 'bg-white' : ''}
                        ${selectedData?.activity_type === 'endpoints-strategy-change' ? 'bg-white' : ''}
                    `}>
                        <div className="row text-gray-800 text-sm flex w-full">
                            <div className="cell text-left w-16 pl-4">#</div>
                            <div className="cell text-left w-12 -ml-2">OS</div>
                            <div className="cell text-left grow ">Device Name</div>
                            <div className="cell text-left w-24">Status</div>
                        </div>
                    </div>
                    <div className={`tbody w-full overflow-y-scroll customScroll h-[60vh] bg-white flex flex-col text-slate-600
                        ${selectedData?.activity_type === 'endpoints-backup' ? 'bg-white' : ''}
                        ${selectedData?.activity_type === 'endpoints-restore' ? 'bg-white' : ''}
                        ${selectedData?.activity_type === 'endpoints-policy-change' ? 'bg-white' : ''}
                        ${selectedData?.activity_type === 'endpoints-strategy-change' ? 'bg-white' : ''}
                    `}>

                        {
                            (filtered || []).length > 0 ?
                            (filtered || []).map((ep:any, endpointIndex:number)=>(
                                <>
                                    <div 
                                        key={endpointIndex} 
                                        className={`row text-sm font-sm flex border-b w-full py-2 hover:bg-slate-100 cursor-pointer`}
                                        onClick={()=>{
                                            sideDrawerContext.setSecondDrawerChild(
                                                <LevelTwoComponent
                                                    selectedData={selectedData}
                                                    requestFilter={requestFilter}
                                                    selectedEndpoints={ep}
                                                    dataFilter={dataFilter}
                                                    rawPoliciesStrategies={rawPoliciesStrategies}
                                                />
                                            );
                                            sideDrawerContext.setLevelTwoDrawerVisibility(true)
                                        }}
                                    >
                                        <div className="cell text-left w-16 pl-4 text-[#ff5e0e] font-normal"> 
                                            {ep?.agent_id || ''}
                                        </div>
                                        <div className="cell text-left w-12">
                                            <span className="group relative text-slate-500">
                                                {deviceSymbol(ep?.agent_profile?.AgentInfos?.System || '')}
                                            </span>
                                        </div>
                                        <div className="cell text-left grow">
                                            <span className="group relative">
                                                <span>{ep?.agent_profile?.AdminInfos?.Name || ''}</span>
                                            </span>
                                        </div>
                                        <div className="cell text-left text-sm w-28">
                                            { ep?.status === 'Protected' && <span className={`px-2 py-[1px] rounded-sm border border-opacity-30 border-green-400 bg-green-100 text-green-600`}>{ep?.status}</span> }
                                            { ep?.status === 'Disabled' && <span className={`px-2 py-[1px] rounded-sm border border-opacity-30 border-violet-400 bg-violet-100 text-violet-600`}>{ep?.status}</span> }
                                            { ep?.status === 'Unprotected' && <span className={`px-2 py-[1px] rounded-sm border border-opacity-30 border-pink-400 bg-pink-100 text-pink-600`}>{ep?.status}</span> }
                                            { ep?.status === 'Deleted' && <span className={`px-2 py-[1px] rounded-sm border border-opacity-30 border-red-400 bg-red-100 text-red-600`}>{ep?.status}</span> }
                                            { ep?.status === 'Pending' && <span className={`px-2 py-[1px] rounded-sm border border-opacity-30 border-blue-400 bg-blue-100 text-blue-600`}>{ep?.status}</span> }
                                        </div>
                                    </div>
                                </>
                            )) :
                            (
                                <div className={`row flex hover:bg-slate-50 py-1 border-b`}>
                                    <div className="cell w-full text-center pl-4 text-slate-600">
                                        <em className="fa-solid fa-loader text-xl fa-spin"></em>
                                    </div>
                                </div>
                            )
                        }

                        {/* {
                            (selectedEndpoints || []).length > 0 ?
                            (selectedEndpoints || []).map((endpoint, endpointIndex)=>(
                                <div 
                                    key={endpointIndex} 
                                    className={`row text-sm font-sm flex border-b w-full py-2 hover:bg-slate-100 cursor-pointer`}
                                    onClick={()=>{
                                        sideDrawerContext.setSecondDrawerChild(
                                            <LevelTwoComponent
                                                selectedData={selectedData}
                                                requestFilter={requestFilter}
                                                selectedEndpoints={endpoint}
                                                dataFilter={dataFilter}
                                                rawPoliciesStrategies={rawPoliciesStrategies}
                                            />);
                                        sideDrawerContext.setLevelTwoDrawerVisibility(true)
                                    }}
                                >
                                    <div className="cell text-left w-16 pl-4 text-[#ff5e0e] font-normal"> 
                                            {endpoint?.agent_id || ''}
                                    </div>
                                    <div className="cell text-left w-12">
                                        <span className="group relative text-slate-500">
                                            {deviceSymbol(endpoint?.agent_profile?.AgentInfos?.System || '')}
                                        </span>
                                    </div>
                                    <div className="cell text-left grow">
                                        <span className="group relative">
                                            <span>{endpoint?.agent_profile?.AdminInfos?.Name || ''}</span>
                                        </span>
                                    </div>
                                    <div className="cell text-left text-sm w-24">
                                        { endpoint?.status === 'Protected' && <span className={`px-2 py-[1px] rounded-sm border border-opacity-30 border-green-400 bg-green-100 text-green-600`}>{endpoint?.status}</span> }
                                        { endpoint?.status === 'Unprotected' && <span className={`px-2 py-[1px] rounded-sm border border-opacity-30 border-pink-400 bg-pink-100 text-pink-600`}>{endpoint?.status}</span> }
                                        { endpoint?.status === 'Deleted' && <span className={`px-2 py-[1px] rounded-sm border border-opacity-30 border-red-400 bg-red-100 text-red-600`}>{endpoint?.status}</span> }
                                        { endpoint?.status === 'Pending' && <span className={`px-2 py-[1px] rounded-sm border border-opacity-30 border-blue-400 bg-blue-100 text-blue-600`}>{endpoint?.status}</span> }
                                    </div>
                                </div>
                            )) :
                            (
                                <div 
                                    className={`row flex hover:bg-slate-50 py-1 border-b
                                        // ${selectedData?.activity_type === 'endpoints-backup' ? 'border-[#FF5E0E] border-opacity-10' : ''}
                                        // ${selectedData?.activity_type === 'endpoints-restore' ? 'border-[#FF5E0E] border-opacity-10' : ''}
                                        // ${selectedData?.activity_type === 'endpoints-policy-change' ? 'border-[#FF5E0E] border-opacity-10' : ''}
                                        // ${selectedData?.activity_type === 'endpoints-strategy-change' ? 'border-[#FF5E0E] border-opacity-10' : ''}
                                    `}
                                >
                                    <div className="cell w-full text-center pl-4 text-slate-600">
                                        <em className="fa-solid fa-loader text-xl fa-spin"></em>
                                    </div>
                                </div>
                            )
                        } */}
                    </div>
                </div>
            </div>

            {/* <Modal modalVisible={modalVisibility} className="w-1/2">
                <ModalHeader modalVisible={modalVisibility} setModalVisibility={setModalVisibility}>
                    {sideBySideProperty.property === 'strategy' && (
                        <>
                            Strategy Change <span className="text-sm text-gray-500 ml-2">(12/12/22 04:29)</span>
                        </>
                    )}
                    {sideBySideProperty.property === 'policy' && (
                        <>
                            Policy Change <span className="text-sm text-gray-500 ml-2">(12/12/22 04:29)</span>
                        </>
                    )}
                    
                </ModalHeader>
                <ModalBody>
                    <div className="flex w-full h-full">
                        {sideBySideProperty.property === 'strategy' && <>
                            <div className="w-1/5 h-full border">
                                <div className="w-full px-2 text-lg font-semibold border-b py-2 bg-gray-100 text-[#FF5E0E]">Properties</div>
                                <div className="py-2 text-base text-[#FF5E0E] px-2 border-b">Strategy</div>
                                <div className="py-2 text-base text-[#FF5E0E] px-2 border-b">Retention</div>
                                <div className="py-2 text-base text-[#FF5E0E] px-2 border-b">RPO</div>
                                <div className="py-2 text-base text-[#FF5E0E] px-2 border-b">Raise Alert</div>
                            </div>
                            <div className="w-2/5 h-full border">
                                <div className="w-full px-2 text-lg font-semibold border-b py-2 bg-gray-100 text-[#FF5E0E]">Previous Strategy</div>
                                <div className="py-2 text-base px-2 border-b hover:bg-slate-50">{JSON.stringify(sideBySideProperty.previousProperty)}</div>
                                <div className="py-2 text-base px-2 border-b hover:bg-slate-50">30 Days</div>
                                <div className="py-2 text-base px-2 border-b hover:bg-slate-50">1 minutes</div>
                                <div className="py-2 text-base px-2 border-b hover:bg-slate-50">3 days</div>
                            </div>
                            <div className="w-2/5 h-full border">
                                <div className="w-full px-2 text-lg font-semibold border-b py-2 bg-gray-100 text-[#FF5E0E]">Current Strategy</div>
                                <div className="py-2 text-base px-2 border-b hover:bg-slate-50">{JSON.stringify(sideBySideProperty.currentProperty)}</div>
                                <div className="py-2 text-base px-2 border-b hover:bg-slate-50">30 Days</div>
                                <div className="py-2 text-base px-2 border-b hover:bg-slate-50">1 minutes</div>
                                <div className="py-2 text-base px-2 border-b hover:bg-slate-50">3 days</div>
                            </div>
                        </>}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={()=>{ setModalVisibility(false) }}>Close</Button>
                </ModalFooter>
            </Modal> */}
        </>
    )
}

interface ILevelTwoComponent {
    selectedData: any;
    requestFilter: any;
    dataFilter: any;
    selectedEndpoints: any;
    rawPoliciesStrategies: any;
}

export const LevelTwoComponent:FC<ILevelTwoComponent> = ({
    selectedData,
    requestFilter,
    dataFilter,
    selectedEndpoints,
    rawPoliciesStrategies
}) => {

    const activityType = {
        "endpoints-backup": {
            window: '1h',
            query: 'backup',
            query_type: 2,
        },
        "endpoints-restore": {
            window: '1h',
            query: 'restore',
            query_type: 2,
        },
        "endpoints-policy-change": {
            window: '1h',
            query: 'protection',
            query_type: 2,
        },
        "endpoints-strategy-change": {
            window: '1h',
            query: 'strategy',
            query_type: 2,
        },
    }

    const navigator = useNavigate();
    const sideDrawerContext = useContext(SideDrawerContext);
    
    // Step Three
    const [selectedActivityData, setSelectedActivityData] = useState<any>({});

    // Step four
    const [deviceAllActivityData, setDeviceAllActivityData] = useState<any>(null);

    // selected property
    const [selectedProperty, setSelectedProperty] = useState<any>(null);
    const [finalProperty, setFinalProperty] = useState<any>(null);
    const [policyDetails, setPolicyDetails] = useState<any>(null);
    const [modalVisibility, setModalVisibility] = useState<boolean>(false);

    const [finalActivities, setFinalActivities] = useState<any[]>([]);

    const getEndpointActivity = async (deviceId:string) => {
        try {
            const response = await postAPICall(
                `${process.env.REACT_APP_API_URL}/analytics/endpoints_recent_activities/${deviceId}`,
                {
                    filter: {
                        ...activityType[selectedData?.activity_type],
                        range: requestFilter,
                    }
                }
            );
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{

        setDeviceAllActivityData([])

        if( selectedActivityData?._id === undefined ) {
            return;
        }

        const fetchEndpointActivities = async () => {
            await getEndpointActivity(selectedActivityData?._id).then((data)=>{
                if(data?.activities) {
                    setDeviceAllActivityData(data?.activities);
                    setFinalActivities(data?.activities)
                } else {
                    setDeviceAllActivityData([]);
                    setFinalActivities([])
                }
            }).catch((err)=>{console.log(err)}).finally(()=>{
                // setSelectedActivityClicked(false)
            })
        }

        if( dataFilter === 'Date Range' ) {
            setFinalActivities(selectedEndpoints?.data)
        } else {
            fetchEndpointActivities();
        }


    },[selectedActivityData])

    useEffect(()=>{
        setSelectedActivityData(selectedEndpoints)
    },[selectedEndpoints])

    useEffect(()=>{
        if(!finalProperty) {
            return;
        }
        console.log('__',{rawPoliciesStrategies},{selectedProperty},{finalProperty})
        if( selectedProperty._field === "protection" && finalProperty?.previousProperty && finalProperty?.currentProperty ) {
            let previousPolicyDetails = null;
            let currentPolicyDetails = null;

            if(finalProperty) {
                previousPolicyDetails = getEndpointConfig(rawPoliciesStrategies.config,finalProperty.previousProperty);
                currentPolicyDetails = getEndpointConfig(rawPoliciesStrategies.config,finalProperty.currentProperty);

                setPolicyDetails({
                    previous: previousPolicyDetails,
                    current: currentPolicyDetails
                })

                console.log({_policyDetails: policyDetails})
            }
            
        }
        // console.log('property clicked',{fProperties},{selectedProperty},{finalProperty})
        setModalVisibility(true)

    },[finalProperty])

    useEffect(()=>{
        if( !selectedProperty ) {
            return;
        }
        let fProperties = findProperty();
        setFinalProperty(fProperties)
    },[selectedProperty])

    const findProperty = () => {
        let previousProperty: any = null;
        let currentProperty: any = null;

        // console.log({_selectedProperty:selectedProperty},{rawPoliciesStrategies})
        
        if( selectedProperty._field === 'protection' ) {
            previousProperty = (rawPoliciesStrategies?.config?.current_policies || []).find((p: any)=>{ return p.ID === (selectedProperty._original -  selectedProperty._value) });
            currentProperty = (rawPoliciesStrategies?.config?.current_policies || []).find((p: any)=>{ return p.ID === selectedProperty._original });
        }

        if( selectedProperty._field === 'strategy' ) {
            previousProperty = (rawPoliciesStrategies?.config?.current_strategies || []).find((s: any)=>{ return s.ID === (selectedProperty._original -  selectedProperty._value) });
            currentProperty = (rawPoliciesStrategies?.config?.current_strategies || []).find((s: any)=>{ return s.ID === selectedProperty._original });
        }

        let finalProperties = {
            previousProperty: previousProperty,
            currentProperty: currentProperty
        }

        console.log({_fProperties:  finalProperties})

        return finalProperties;
    }

    const behaviorText = {
        EXCL:"Excluded Extensions",
        INCL:"Included Extensions"
    }

    console.log({old:deviceAllActivityData, new: selectedEndpoints, dataFilter})

    return (
        <>
            <div className="title w-full absolute flex gap-4 px-6 top-0 pt-10 border-b pb-10 bg-slate-100 items-center">

                { selectedData?.activity_type === 'endpoints-backup' && <><i className="fa-regular fa-upload text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i></> }
                { selectedData?.activity_type === 'endpoints-restore' && <><i className="fa-regular fa-download text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i></> }
                { selectedData?.activity_type === 'endpoints-policy-change' && <><i className="fa-regular fa-list-check text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i></> }
                { selectedData?.activity_type === 'endpoints-strategy-change' && <><i className="fa-regular fa-chess-knight text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i></> }

                <div className="flex flex-col">
                    <div 
                        className="flex text-xl font-bold text-[#ff5e0e] items-center cursor-pointer uppercase"
                        onClick={()=>{
                            sideDrawerContext.setFirstDrawerChild(<></>);
                            sideDrawerContext.setSecondDrawerChild(<></>);
                            sideDrawerContext.setLevelOneDrawerVisibility(false);
                            sideDrawerContext.setLevelTwoDrawerVisibility(false);
                            navigator(`/endpoint/devices/profile/${selectedActivityData?._id}`)
                        }}
                    >
                        {selectedActivityData?.agent_profile?.AdminInfos?.Name}
                        <em className="fa-solid fa-link ml-2 font-medium "></em>
                    </div>
                    <div className="block text-slate-500">
                        { selectedData?.activity_type === 'endpoints-backup' && 'Backup Activities of ' }
                        { selectedData?.activity_type === 'endpoints-restore' && 'Restore Activities of ' }
                        { selectedData?.activity_type === 'endpoints-policy-change' && 'Policy Change Activities of ' }
                        { selectedData?.activity_type === 'endpoints-strategy-change' && 'Strategy Change Activities of ' }
                        {dataFilter}
                    </div>
                </div>

            </div>
            
            <div className="data absolute top-40 px-4">
                <div className={`_table flex flex-col h-full shadow-md border rounded-md mb-8 overflow-hidden w-[405px] md:w-[600px]`}>
                    <div className="overflow-x-scroll customScroll">
                        <div className={`thead bg-slate-50 border-b p-2 flex flex-row items-center w-[545px] md:w-[600px]`}>
                            <div className="row text-gray-800 text-sm flex">
                                <div className="cell text-left w-10 pl-2">#</div>
                                { (selectedData?.activity_type === 'endpoints-backup' || selectedData?.activity_type === 'endpoints-restore') ?
                                    <div className="cell text-left w-28 pl-3">Change</div> :
                                    <>
                                        <div className="cell text-left w-24 pl-2">Previous</div>
                                        <div className="cell text-left w-24 pl-2">Current</div>
                                    </>
                                }
                                { (selectedData?.activity_type === 'endpoints-backup' || selectedData?.activity_type === 'endpoints-restore') ?
                                    <>
                                        {/* <div className="cell text-left w-28 pl-1">Start Time</div> */}
                                        <div className="cell text-left w-28 pl-4">Activity Time</div>
                                        {/* <div className="cell text-left w-28 pl-2 ml-24">End Time</div> */}
                                    </> :
                                    <div className="cell text-left whitespace-nowrap w-44 mr-2 md:ml-1">Time</div>
                                }
                            </div>
                        </div>
                        <div className={`tbody bg-white w-[545px] md:w-[600px] overflow-y-scroll h-[60vh] customScroll`}>
                            {
                                [...finalActivities].length > 0 ? 
                                (
                                    [...finalActivities].reverse().map((activity:any, activityIndex:number)=>(
                                        <div
                                            key={activityIndex}
                                            className="row flex flex-row border-b font-light p-2 px-4 hover:bg-slate-100 text-sm items-center "
                                        >
                                            {/* Index */}
                                            <div className="cell text-slate-700 text-left w-10 mr-1">{[...finalActivities].length-activityIndex}</div>
                                            {/* <div className="cell text-slate-700 text-left w-10 mr-1">{activityIndex + 1}</div> */}

                                            {/* Previous - Current / Change */}
                                            { (selectedData?.activity_type === 'endpoints-backup' || selectedData?.activity_type === 'endpoints-restore') ?
                                                <div className="cell text-left w-28">{formatSize(dataSizeConversion(activity?._value,'MB','B').value).value} {formatSize(dataSizeConversion(activity?._value,'MB','B').value).unit}</div> :
                                                <>
                                                    <div className="cell text-left w-24">{activity?._original - activity?._value}</div>
                                                    <div className="cell text-left w-24">{activity?._original}</div>
                                                </>
                                            }

                                            {/* { selectedData?.activity_type === 'endpoints-backup' && <div className="cell text-slate-700 text-left w-24 whitespace-nowrap mr-2">{formatSize(dataSizeConversion(activity?._original - activity?._value,'MB','B').value).value} {formatSize(dataSizeConversion(activity?._original - activity?._value,'MB','B').value).unit}</div>}
                                            { selectedData?.activity_type === 'endpoints-restore' && <div className="cell text-slate-700 text-left w-24 whitespace-nowrap mr-2">{formatSize(dataSizeConversion(activity?._original - activity?._value,'MB','B').value).value} {formatSize(dataSizeConversion(activity?._original - activity?._value,'MB','B').value).unit}</div> }
                                            { selectedData?.activity_type === 'endpoints-policy-change' && <div className="cell text-slate-700 text-left w-24 whitespace-nowrap mr-2 -ml-1">{activity?._original - activity?._value}</div> }
                                            { selectedData?.activity_type === 'endpoints-strategy-change' && <div className="cell text-slate-700 text-left w-24 whitespace-nowrap mr-2 -ml-1">{activity?._original - activity?._value}</div> } */}

                                            {/* Current */}
                                            {/* { selectedData?.activity_type === 'endpoints-backup' && <div className={`cell text-slate-700 text-left w-24 whitespace-nowrap mr-2`}>{formatSize(dataSizeConversion(activity?._original,'MB','B').value).value} {formatSize(dataSizeConversion(activity?._original,'MB','B').value).unit}</div>}
                                            { selectedData?.activity_type === 'endpoints-restore' && <div className={`cell text-slate-700 text-left w-24 whitespace-nowrap mr-2 ${(selectedData?.activity_type === 'endpoints-backup' || selectedData?.activity_type === 'endpoints-restore') ? 'w-24' : 'w-40'}`}>{formatSize(dataSizeConversion(activity?._original,'MB','B').value).value} {formatSize(dataSizeConversion(activity?._original,'MB','B').value).unit}</div> }
                                            { selectedData?.activity_type === 'endpoints-policy-change' && <div className={`cell text-slate-700 text-left w-24 whitespace-nowrap mr-2 -ml-1${(selectedData?.activity_type === 'endpoints-backup' || selectedData?.activity_type === 'endpoints-restore') ? 'w-24' : 'w-40'}`}>{activity?._original}</div> }
                                            { selectedData?.activity_type === 'endpoints-strategy-change' && <div className={`cell text-slate-700 text-left w-24 whitespace-nowrap mr-2 -ml-1`}>{activity?._original}</div> } */}

                                            {/* Change */}
                                            {/* { selectedData?.activity_type === 'endpoints-backup' && <div className="cell text-slate-700 text-left w-24 whitespace-nowrap mr-2">{formatSize(dataSizeConversion(activity?._value,'MB','B').value).value} {formatSize(dataSizeConversion(activity?._value,'MB','B').value).unit}</div>}
                                            { selectedData?.activity_type === 'endpoints-restore' && <div className="cell text-slate-700 text-left w-24 whitespace-nowrap mr-2">{formatSize(dataSizeConversion(activity?._value,'MB','B').value).value} {formatSize(dataSizeConversion(activity?._value,'MB','B').value).unit}</div>} */}
                                            
                                            {/* Time / Start Time - End Time */}
                                            { (selectedData?.activity_type === 'endpoints-backup' || selectedData?.activity_type === 'endpoints-restore') ?
                                                <>
                                                    {/* <div className="cell text-slate-700 grow flex w-44 whitespace-nowrap mr-2">{getFormattedDateTime(activity?._start)}</div> */}
                                                    {/* <div className="cell text-slate-700 grow flex w-44 whitespace-nowrap mr-2">{convertUTCtoLocalDate(activity?._time).substring(0, convertUTCtoLocalDate(activity?._time).length - 3)}</div> */}
                                                    <div className="cell text-slate-700 grow flex w-44 whitespace-nowrap mr-2">{
                                                        activity?._time ? 
                                                        convertUTCtoLocalDate(activity?._time) : 
                                                        convertUTCtoLocalDate(activity?._start)
                                                    }</div>
                                                    {/* <div className="cell text-slate-700 grow flex w-44 whitespace-nowrap mr-2">{convertUTCtoLocalDate(activity?._start)}</div> */}
                                                    {/* <div className="cell text-slate-700 grow flex w-44 whitespace-nowrap mr-2">{getFormattedDateTime(activity?._stop)}</div> */}
                                                    {/* <div className="cell text-slate-700 grow flex w-44 whitespace-nowrap mr-2">{convertUTCtoLocalDate(activity?._stop)}</div> */}
                                                </> :
                                                <div className="cell text-slate-700 grow flex w-44 whitespace-nowrap mr-2">{getFormattedDateTime(activity?._time)}</div>
                                            }
                                        </div>
                                    ))
                                ):
                                (
                                    <div className={`row flex hover:bg-slate-50 py-1 border-b`} >
                                        <div className="cell w-full text-center pl-4 text-slate-600">
                                            <em className="fa-solid fa-loader text-xl fa-spin"></em>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                {/* <span className="flex w-full px-4 font-light text-slate-700">{(deviceAllActivityData || []).length} records</span> */}
                <span className="flex w-full px-4 font-light text-slate-700">{[...finalActivities].length} records</span>
            </div>  

            {/* <Modal modalVisible={modalVisibility} className={`${ (selectedProperty?._field || '') === 'strategy' && 'w-1/2' } ${ (selectedProperty?._field || '') === 'protection' && 'w-[75%] ml-8 lg:w-[65%] md:w-[50%] h-[70%]' }`}> */}
            <Modal modalVisible={modalVisibility} className={`${ (selectedProperty?._field || '') === 'strategy' && 'w-1/2' } ${ (selectedProperty?._field || '') === 'protection' && 'w-[75%] ml-8 lg:w-[50%] h-[70%]' }`}>
                <ModalHeader modalVisible={modalVisibility} setModalVisibility={setModalVisibility} className="flex items-end">
                    <div>
                        {(selectedProperty?._field || '') === 'strategy' && <>Strategy Change</>}
                        {(selectedProperty?._field || '') === 'protection' && <>Policy Change</>}
                    </div>
                    <div className="ml-2 text-sm font-normal">{getFormattedDateTime(selectedProperty?._time || '')}</div>
                </ModalHeader>
                <ModalBody>
                    {selectedProperty?._field === 'strategy' && <div className="flex flex-col h-full">
                        <div className="w-full flex bg-gray-100">
                            <div className="w-1/5 py-2 border-r border-b pl-2 text-[#FF5E0E] text-base font-semibold">Properties</div>
                            <div className="w-2/5 py-2 border-r border-b pl-2 text-[#FF5E0E] text-base font-semibold">Previous</div>
                            <div className="w-2/5 py-2 border-b pl-2 text-[#FF5E0E] text-base font-semibold">Current</div>
                        </div>
                        <div className="w-full flex">
                            <div className="w-1/5 py-2 bg-gray-50 border-r border-b pl-2 text-[#FF5E0E] text-base font-semibold">ID</div>
                            <div className="w-2/5 py-2 hover:bg-slate-50 border-r border-b pl-2">{finalProperty?.previousProperty?.ID || 'NA'}</div>
                            <div className="w-2/5 py-2 hover:bg-slate-50 border-b pl-2">{finalProperty?.currentProperty?.ID || ''}</div>
                        </div>
                        <div className="w-full flex">
                            <div className="w-1/5 py-2 bg-gray-50 border-r border-b pl-2 text-[#FF5E0E] text-base font-semibold">Name</div>
                            <div className="w-2/5 py-2 hover:bg-slate-50 border-r border-b pl-2">{finalProperty?.previousProperty?.Name || 'NA'}</div>
                            <div className="w-2/5 py-2 hover:bg-slate-50 border-b pl-2">{finalProperty?.currentProperty?.Name || ''}</div>
                        </div>
                        <div className="w-full flex">
                            <div className="w-1/5 py-2 bg-gray-50 border-r border-b pl-2 text-[#FF5E0E] text-base font-semibold">Retention</div>
                            <div className="w-2/5 py-2 hover:bg-slate-50 border-r border-b pl-2">{finalProperty?.previousProperty?.ParamDataAging || 0} Days</div>
                            <div className="w-2/5 py-2 hover:bg-slate-50 border-b pl-2">{finalProperty?.currentProperty?.ParamDataAging || 0} Days</div>
                        </div>
                        <div className="w-full flex">
                            <div className="w-1/5 py-2 bg-gray-50 border-r border-b pl-2 text-[#FF5E0E] text-base font-semibold">RPO</div>
                            <div className="w-2/5 py-2 hover:bg-slate-50 border-r border-b pl-2">{finalProperty?.previousProperty?.ParamSchedule ? formatSeconds(finalProperty?.previousProperty?.ParamSchedule).value + ' ' + formatSeconds(finalProperty?.previousProperty?.ParamSchedule).unit : 'NA'}</div>
                            <div className="w-2/5 py-2 hover:bg-slate-50 border-b pl-2">{finalProperty?.currentProperty?.ParamSchedule ? formatSeconds(finalProperty?.currentProperty?.ParamSchedule).value + ' ' + formatSeconds(finalProperty?.currentProperty?.ParamSchedule).unit : 'NA'}</div>
                        </div>
                        <div className="w-full flex">
                            <div className="w-1/5 py-2 bg-gray-50 border-r pl-2 text-[#FF5E0E] text-base font-semibold">Alerts</div>
                            <div className="w-2/5 py-2 hover:bg-slate-50 border-r pl-2">{finalProperty?.previousProperty?.ParamAlertTime ? formatSeconds(finalProperty?.previousProperty?.ParamAlertTime).value + ' ' + formatSeconds(finalProperty?.previousProperty?.ParamAlertTime).unit : 'NA'}</div>
                            <div className="w-2/5 py-2 hover:bg-slate-50 pl-2">{finalProperty?.currentProperty?.ParamAlertTime ? formatSeconds(finalProperty?.currentProperty?.ParamAlertTime).value + ' ' + formatSeconds(finalProperty?.currentProperty?.ParamAlertTime).unit : 'NA'}</div>
                        </div>
                    </div>}
                    {selectedProperty?._field === 'protection' && (
                        <div className="flex flex-col">
                            <div className="flex items-center p-2 border-b bg-gray-100 text-[#FF5E0E] text-base font-semibold">
                                <div className="">Previous Policy</div>
                                <div className="text-sm font-normal text-gray-600 ml-2">({finalProperty?.previousProperty?.ID || ''}) {finalProperty?.previousProperty?.Name || ''}</div>
                            </div>
                            <div className="overflow-x-scroll customScroll overflow-y-hidden w-full h-[220px]">
                                <div className="table w-full">
                                    <div className="thead border-b px-4 py-2 font-medium text-[#FF5E0E] bg-slate-100 bg-opacity-50 w-full">
                                        <div className="row flex w-full font-bold">
                                            <div className="cell grow w-48 max-w-48 text-left font-semibold">Name</div>
                                            <div className="cell grow w-52 max-w-52 text-left font-semibold">Path</div>
                                            <div className="cell grow w-52 max-w-52 text-left font-semibold">Filter</div>
                                        </div>
                                    </div>
                                    <div className="tbody max-h-[195px] overflow-y-scroll customScroll pb-12 mb-2 w-full">
                                        {(policyDetails?.previous?.path || []).map((pathObj:any, pathIndex:number) => {

                                            if( (pathObj?._ID || []).length < 1 ) {
                                                return (
                                                    <div key={pathIndex} className="row flex items-center w-full border-b px-4 py-2 hover:bg-slate-100 text-sm text-gray-700">
                                                        <div className="cell w-48 max-w-48 text-left whitespace-pre-wrap truncate">{pathObj?.path?.Name || 'NA'}</div>
                                                        <div className="cell grow w-52 max-w-52 text-left whitespace-nowrap truncate">{pathObj?.path?.Path || 'NA'}</div>
                                                        <div className="cell flex flex-col grow w-52 max-w-52 text-left whitespace-pre-wrap pl-4">
                                                            {pathObj?.ID && <div className="">{ pathObj?.filter?.comment || 'NA' } {pathObj?.extensions?.Behavior === 'INCL' && '[Inclusion]'}{pathObj?.extensions?.Behavior === 'EXCL' && '[Exclusion]'}</div>}
                                                            <span>
                                                                <div className="inline-block px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 text-xs font-medium">
                                                                    { Object.keys( policyDetails?.previous?.profile?.RuleArray || {}).length > 0 ? 'All Extensions' : 'No Rule Applied' } 
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            } else if( (pathObj?._ID || []).length > 0 ) {
                                                return (pathObj?._ID || []).map((item: any, itemIndex: number)=>(
                                                    <div key={pathIndex} className="row flex items-center w-full border-b px-4 py-2 hover:bg-slate-100 text-sm text-gray-700 max-h-min">
                                                        <div className="cell w-48 max-w-48 text-left whitespace-pre-wrap truncate">{pathObj?.path?.Name || 'NA'}</div>
                                                        <div className="cell grow w-52 max-w-52 text-left whitespace-nowrap truncate">{pathObj?.path?.Path || 'NA'}</div>
                                                        <div className="cell flex flex-col grow w-52 max-w-52 text-left whitespace-pre-wrap pl-4">
                                                            <div className="">{ pathObj?.filter?.comment || 'NA' } {(pathObj?.extensions?.Behavior === 'INCL' || policyDetails?.previous?.extensions[item]?.Behavior === 'INCL') && '[Inclusion]'}{(pathObj?.extensions?.Behavior === 'EXCL' || policyDetails?.previous?.extensions[item]?.Behavior === 'EXCL' ) && '[Exclusion]'}</div>
                                                            <div className="mb-2">({ policyDetails?.previous?.extensions[item]?.Name || '' })</div>
                                                            <div>
                                                                {(policyDetails?.previous.extensions[item]?.Ext || []).map((extItem:string, extIndex: number)=>{
                                                                    return (
                                                                        <div 
                                                                            key={`${pathIndex}_${itemIndex}_${extIndex}`} 
                                                                            className="float-left px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 mb-1 text-xs font-medium"
                                                                        >
                                                                            {extItem}
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        })}
                                        {/* {(policyDetails?.previous?.path || []).map((pathObj:any, pathIndex:number)=>(
                                            <div key={pathIndex} className="row flex items-center w-full border-b px-4 py-2 hover:bg-slate-100">
                                                {
                                                    Object.keys(finalProperty?.previousProperty?.RuleArray).length > 0 ?
                                                    (
                                                        <>
                                                            <div className="cell grow w-48 max-w-48 text-left font-light whitespace-nowrap">{pathObj?.path?.Name || 'NA'}</div>
                                                            <div className="cell grow w-52 max-w-52 text-left font-light whitespace-nowrap">{pathObj?.path?.Path || 'NA'}</div>
                                                            <div className="cell flex flex-col grow w-52 max-w-52 text-left font-light whitespace-nowrap">
                                                                <div>{ behaviorText[ pathObj?.extensions?.Behavior]|| pathObj?.filter?.comment || ""}</div>

                                                                {pathObj?.extensions?.Behavior ? (
                                                                    <>
                                                                        {(pathObj?.extensions?.Behavior === "INCL" || pathObj?.extensions?.Behavior === "EXCL") && 
                                                                            <span>
                                                                                {
                                                                                    pathObj?.extensions?.Ext.map((d:any)=>(
                                                                                        <div className="float-left px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 mb-1 text-xs font-medium">{d || ''}</div>
                                                                                    ))
                                                                                }
                                                                            </span>
                                                                        }
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span>
                                                                            <div className="inline-block px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 mb-1 text-xs font-medium">
                                                                                All Extensions
                                                                            </div>
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </>
                                                    ) :
                                                    (
                                                        <>
                                                            <div className="cell grow w-36 max-w-40 text-left font-light whitespace-nowrap">NA</div>
                                                            <div className="cell grow w-40 max-w-80 text-left font-light whitespace-nowrap">NA</div>
                                                            <div className="cell flex flex-col grow w-40 max-w-80 text-left font-light whitespace-nowrap">
                                                                <span>
                                                                    <div className="inline-block px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 mb-1 text-xs font-medium">
                                                                        No Rule Applied
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </>
                                                    )
                                                }

                                            </div>
                                        ))} */}
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center p-2 border-y bg-gray-100 text-[#FF5E0E] text-base font-semibold">
                                <div className="">Current Policy</div>
                                <div className="text-sm font-normal text-gray-600 ml-2">({finalProperty?.currentProperty?.ID || ''}) {finalProperty?.currentProperty?.Name || ''}</div>
                            </div>
                            <div className="overflow-x-scroll customScroll overflow-y-hidden w-full h-[240px]">
                                <div className="table w-full">
                                    <div className="thead border-b px-4 py-2 font-medium text-[#FF5E0E] bg-slate-100 bg-opacity-50 w-full">
                                        <div className="row flex w-full font-bold">
                                            <div className="cell grow w-48 max-w-48 text-left font-semibold">Name</div>
                                            <div className="cell grow w-52 max-w-52 ml-2  text-left font-semibold">Path</div>
                                            <div className="cell grow w-52 max-w-52 ml-2 text-left font-semibold">Filter</div>
                                        </div>
                                    </div>
                                    <div className="tbody max-h-[195px] overflow-y-scroll customScroll pb-10 mb-2 w-full">
                                        {(policyDetails?.current?.path || []).map((pathObj:any, pathIndex:number) => {
                                            if( (pathObj?._ID || []).length < 1 ) {
                                                return (
                                                    <div key={pathIndex} className="row flex items-center w-full border-b px-4 py-2 hover:bg-slate-100 text-sm text-gray-700">
                                                        <div className="cell w-48 max-w-48 text-left whitespace-nowrap truncate">{pathObj?.path?.Name || 'NA'}</div>
                                                        <div className="cell grow w-52 max-w-52 text-left whitespace-nowrap truncate">{pathObj?.path?.Path || 'NA'}</div>
                                                        <div className="cell flex flex-col grow w-52 max-w-52 text-left whitespace-nowrap pl-4">
                                                            {pathObj?.ID && <div className="">{ pathObj?.filter?.comment || 'NA' } {pathObj?.extensions?.Behavior === 'INCL' && '[Inclusion]'}{pathObj?.extensions?.Behavior === 'EXCL' && '[Exclusion]'}</div>}
                                                            <span>
                                                                <div className="inline-block px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 text-xs font-medium">
                                                                    { Object.keys( policyDetails?.current?.profile?.RuleArray || {}).length > 0 ? 'All Extensions' : 'No Rule Applied' } 
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            } else if( (pathObj?._ID || []).length > 0 ) {
                                                return (pathObj?._ID || []).map((item: any, itemIndex: number)=>(
                                                    <div key={pathIndex} className="row flex items-center w-full border-b px-4 py-2 hover:bg-slate-100 text-sm text-gray-700">
                                                        <div className="cell w-48 max-w-48 text-left whitespace-nowrap truncate">{pathObj?.path?.Name || 'NA'}</div>
                                                        <div className="cell grow w-52 max-w-52 text-left whitespace-nowrap truncate">{pathObj?.path?.Path || 'NA'}</div>
                                                        <div className="cell flex flex-col grow w-52 max-w-52 text-left whitespace-nowrap pl-4">
                                                            <div className="">{ pathObj?.filter?.comment || 'NA' } {(pathObj?.extensions?.Behavior === 'INCL' || policyDetails?.previous?.extensions[item]?.Behavior === 'INCL') && '[Inclusion]'}{(pathObj?.extensions?.Behavior === 'EXCL' || policyDetails?.previous?.extensions[item]?.Behavior === 'EXCL' ) && '[Exclusion]'}</div>
                                                            <div className="mb-2">({ policyDetails?.current?.extensions[item]?.Name || '' })</div>
                                                            <div>
                                                                {(policyDetails?.current.extensions[item]?.Ext || []).map((extItem:string, extIndex: number)=>{
                                                                    return (
                                                                        <div 
                                                                            key={`${pathIndex}_${itemIndex}_${extIndex}`} 
                                                                            className="float-left px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 mb-1 text-xs font-medium"
                                                                        >
                                                                            {extItem}
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        })}
                                        {/* {(policyDetails?.current?.path || []).map((pathObj:any, pathIndex:number)=>(
                                            <div key={pathIndex} className="row flex items-center border-b px-4 py-2 hover:bg-slate-100">
                                            {
                                                Object.keys(finalProperty?.currentProperty?.RuleArray).length > 0 ?
                                                (
                                                    <>
                                                        <div className="cell grow w-48 max-w-48 text-left font-light whitespace-nowrap">{pathObj?.path?.Name || 'NA'}</div>
                                                        <div className="cell grow w-52 max-w-52 ml-2 text-left font-light whitespace-nowrap">{pathObj?.path?.Path || 'NA'}</div>
                                                        <div className="cell grow flex flex-col w-52 max-w-52 ml-2 text-left font-light whitespace-nowrap">
                                                                <div>{ behaviorText[ pathObj?.extensions?.Behavior]|| pathObj?.filter?.comment || ""}</div>

                                                            {pathObj?.extensions?.Behavior ? (
                                                                <>
                                                                    {(pathObj?.extensions?.Behavior === "INCL" || pathObj?.extensions?.Behavior === "EXCL") && 
                                                                        <span>{pathObj?.extensions?.Ext.map((d:any)=>(
                                                                            <div className="inline-block px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 mb-1 text-xs font-medium">{d || ''}</div>
                                                                        ))}</span>
                                                                    }
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <span>
                                                                        <div className="inline-block px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 mb-1 text-xs font-medium">
                                                                            All Extensions
                                                                        </div>
                                                                    </span>
                                                                </>
                                                            )}
                                                        </div>
                                                    </>
                                                ) :
                                                (
                                                    <>
                                                        <div className="cell grow w-36 max-w-40 text-left font-light whitespace-nowrap">NA</div>
                                                        <div className="cell grow w-40 max-w-80 ml-4 text-left font-light whitespace-nowrap">NA</div>
                                                        <div className="cell flex flex-col grow w-40 max-w-80 ml-3 text-left font-light whitespace-nowrap">
                                                            <span>
                                                                <div className="inline-block px-2 py-1 bg-orange-500 text-slate-700 bg-opacity-40 hover:bg-opacity-30 rounded mr-1 mb-1 text-xs font-medium">
                                                                    No Rule Applied
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            </div>
                                        ))} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={()=>{
                            setModalVisibility(false)
                        }}
                    >Close</Button>
                </ModalFooter>
            </Modal>  
        </>
    )
}

export default ActivityCard;