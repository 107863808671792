import React, { FC, SetStateAction, Dispatch } from "react";
import { DonutChart } from "../../../components/Charts/ApacheECharts";
// import { MasterChart } from "../../../components/Charts/ChartJS";
import { Tile } from "../../../components/core";
// import { formatSize } from "../../../utils/backupFileUtils";

interface IFileTypeCard {
    fileStatsRawData: any;
    fileStatsProcessedData: any;
    donutCutout: number;
    setSelectedTile: Dispatch<SetStateAction<string>>;
}

const FileTypeCard:FC<IFileTypeCard> = ({ fileStatsRawData, fileStatsProcessedData, donutCutout, setSelectedTile }) => {

    // const [selectedType, setSelectedType] = useState<string[]>(['Regular','Directory','Symbolic Link'])

    // const [regular, setRegular] = useState<boolean>(false);
    // const [directory, setDirectory] = useState<boolean>(false);
    // const [symbolicLink, setSymbolicLink] = useState<boolean>(false);

    // useEffect(()=>{
    //     if( regular === false && directory === false && symbolicLink === false ) {
    //         setSelectedType(['Regular','Directory','Symbolic Link'])
    //     }
    // },[regular,directory,symbolicLink])

    console.log({fileStatsProcessedData})

    
    return (
        <>  {
                (fileStatsProcessedData?.typesInstances || []).length > 0 ?
                <Tile 
                    rowSpan={13} 
                    colSpan={3}
                    onClick={()=>{
                        setSelectedTile('FileType');
                    }}
                    className="cursor-pointer"
                >
                    <div className="flex flex-col h-full mb-1 p-5 text-[#3A3541]">
                        <div className="w-full h-full">
                            <DonutChart
                                title="Number of Files by Type"
                                data={[
                                    ...(fileStatsProcessedData?.typesInstances || []).map(
                                        (n:any, i:number)=>{
                                            return {
                                                value: n?.instance?.text  || "",
                                                name: n?.type?.text || "",
                                                itemStyle: {
                                                    opacity: 1/(i+1)
                                                }
                                            }
                                        }
                                    )      
                                ]}
                                centreValue={
                                    <>
                                        <div className="text-2xl font-semibold">{(fileStatsProcessedData?.typesInstances || []).reduce((a:number,d:any)=>{ a = a + d.instance.text; return a },0)}</div>
                                        <div className="text-sm font-normal">Number of Files</div>
                                    </>
                                }
                            />
                        </div>
                    </div>
                </Tile> : 
                <Tile
                    rowSpan={13} 
                    colSpan={3} 
                >
                    <div className="flex flex-col h-full mb-1 p-5 text-[#3A3541]">
                        <div className="flex flex-row justify-between w-full">
                            <div title='Ticket Status' className={`text-base md:text-lg lg:text-xl leading-8 font-medium whitespace-nowrap truncate`}></div>
                        </div>
                        <div className="regular flex-col gap-4 grow my-4 mt-4 mb-2 max-h-80 md:max-h-80 lg:max-h-80  items-center justify-center flex z-10 relative">
                            <div className="relative flex h-full aspect-square items-center justify-center align-middle">
                                <div className="h-[90%] lg:h-[70%] xl:h-[90%] aspect-square rounded-full bg-slate-300 animate-pulse"></div>
                                <div className="absolute h-[50%] aspect-square rounded-full border bg-white"></div>
                            </div>
                            <div className="absolute flex flex-col top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                <span className="block bg-slate-300 animate-pulse h-[30px] w-[60px] xl:w-[60px] mb-2"></span>
                                <span className="block bg-slate-300 animate-pulse h-[10px] w-[60px] xl:w-[60px]"></span>
                            </div>
                            <div className="legends flex flex-row justify-center space-x-4 flex-wrap lg:-mt-4 2xl:mt-0">
                                <div className="flex flex-row items-center text-xs whitespace-nowrap pb-1">
                                    <i className="fa-solid fa-circle text-slate-300 text-center justify-center items-start flex text-xs"></i>
                                    <div className="ml-1">
                                        <span className="block bg-slate-300 animate-pulse h-[10px] w-[60px]"></span>
                                    </div>
                                </div>
                                <div className="flex flex-row items-center text-xs whitespace-nowrap pb-1">
                                    <i className="fa-solid fa-circle text-slate-300 text-center justify-center items-start flex text-xs"></i>
                                    <div className="ml-1">
                                        <span className="block bg-slate-300 animate-pulse h-[10px] w-[60px]"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tile>
            }
        </>
    )
}

export default FileTypeCard;