import React from "react";
import { Tile } from "../../../core";


const Tile60 = ( ) =>{
    return (
        <>
        <Tile rowSpan={13} colSpan={4} className="  shadow-none   p-0 ">
        <div className=" flex flex-col   h-full mb-1 p-5 text-[#3A3541]  ">
            <div className=" flex  flex-col">
                <div className="flex flex-row justify-between w-full  ">
                    <div className={`text-xl leading-8   font-medium`}>Total Revenue</div>
                    <i className="fa-solid fa-ellipsis-vertical text-center justify-center items-start flex"></i>   
                </div>
            </div>
            <div className="grow border my-4 mx-6"></div>
            <div className="flex flex-row items-center  justify-center">
                <div className="flex flex-row items-center "> 
                    <div className="flex flex-col justify-center pr-6 border-r">
                        <div className="flex flex-row items-center">
                            <i className="fa-solid fa-circle text-xs  mr-2 text-green-400"></i>
                            <div>867</div>
                        </div>
                        <div className=" text-xs leading-5 font-regular text-[#3A3541]">
                        New User
                        </div>
                    </div>
                    <div className="flex flex-col justify-center px-6 border-r">
                        <div className="flex flex-row items-center">
                            <i className="fa-solid fa-circle text-xs  mr-2 text-green-400"></i>
                            <div>867</div>
                        </div>
                        <div className=" text-xs leading-5 font-regular text-[#3A3541]">
                        New User
                        </div>
                    </div>
                    <div className="flex flex-col justify-center pl-6 ">
                        <div className="flex flex-row items-center">
                            <i className="fa-solid fa-circle text-xs  mr-2 text-green-400"></i>
                            <div>867</div>
                        </div>
                        <div className=" text-xs leading-5 font-regular text-[#3A3541]">
                        New User
                        </div>
                    </div>
                    
                </div>
                
            </div>           
        </div>  
        </Tile>
        </>
    )
}

export default Tile60



