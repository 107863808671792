import React, { FC, ReactNode } from "react";
import { Tile } from "../../../core";

interface IProp {
    /**
     * title of the tile
     */
    title: ReactNode | ReactNode[] | string;
    /**
     * Icon on tile
     */
    icon: string;
    /**
     * icon style string. one can pass tailwind properties here
     */
    iconStyle?: string;
    /**
     * value on tile
     */
    value: ReactNode | string;
    /**
     * tileMode represents tile color
     */
    tileMode?: "default" | "success" | "warning" | "info" | "danger" | "error";
    /**
     * if tile is in alert mode?
     */
    alert?: boolean;
    /**
     * indicates if tile is live
     */
    live?: boolean;
    /**
     * additional className string 
     */
    className?: string;
    children?: ReactNode | ReactNode[] | string;
}

const Tile3x3_Text:FC<IProp> = ({
    title='',
    icon='',
    iconStyle='',
    value='',
    tileMode='default',
    alert=false,
    live=false,
    className='',
    children=''
}: IProp) =>{

    let tileModeStyle  = '';

    switch (tileMode) {
        case 'default':
            tileModeStyle = '';
            break;

        case 'success':
            tileModeStyle = 'border border-green-500';
            break;

        case 'warning':
            tileModeStyle = 'border border-yellow-500';
            break;

        case 'info':
            tileModeStyle = 'border border-blue-500';
            break;

        case 'danger':
        case 'error':
            tileModeStyle = 'border border-red-500';
            break;
    
        default:
            break;
    }

    return (
        <>
            <Tile rowSpan={3} colSpan={3} className={`${tileModeStyle} ${className}`}>
                <div className="p-5 flex justify-start items-center">
                    <i className={`${icon} w-10 h-10  flex justify-center items-center rounded bg-white ${iconStyle}`}></i>
                    <div className="px-3 flex flex-col">
                        <div className={`text-xs leading-5 font-normal text-orange-500`}>
                            {title}
                            {alert && <em className="fa-solid fa-circle ml-3 text-red-500 animate-ping"></em>}
                        </div>
                        <div className={`text-xl leading-8 font-medium text-[#3A3541]`}>{value}</div>
                        <div className="">
                    {children}
                    </div>
                    </div>
                   
                    {live && <div className="inline absolute top-2 right-3 text-xs">
                        <em className="fa-solid fa-circle text-red-500 blink"></em>
                    </div>}

                </div>
                
            </Tile>
        </>
    )
}

export default Tile3x3_Text