/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect,  } from "react";
import Tile5x4_ChartText from "../../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText";
import { Breadcrumb, Tile } from "../../components/core";
import Table, { ITableColumnItem, ITableControllerProps } from "../../components/core/Table/Table";
// import { ThemeContext } from "../../contexts";

const EndpointUserProfile = () => {
    // const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    const navRaf = useRef<HTMLDivElement>(null);

    // const [detailOpen,setDetailOpen]=useState(false)

    const [tableController, setTableController] = useState<ITableControllerProps>({
        data: [],
        loader: false,
        currentPage: 1,
        offset: 0,
        limit: 5,
        totalRecords: 10,
        maxVisiblePaginationButtons: 3,
        searchTerm: '',
        filter: {},
        sorting: {
            column: '',
            direction: null
        }
    })
    const [tableColumns, setTableColumns] = useState<ITableColumnItem[]>([
        {
            id: 1,
            name: 'Device Id',
            column: 'device_id',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true
        },
        {
            id: 2,
            name: 'Device',
            column: 'device',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true
        },
        {
            id: 3,
            name: 'Device Status',
            column: 'device_status',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true
        },
        
        {
            id: 4,
            name: 'Last Connection',
            column: 'last_connection',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true
        },
        {
            id: 5,
            name: 'Last Backup',
            column: 'last_backup',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true
        },
        {
            id: 6,
            name: 'Protected Data',
            column: 'protected_data',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true
        },
        
    ])
    const [IncrementalBackupTableController, setIncrementalBackupTableController] = useState<ITableControllerProps>({
        data: [],
        loader: false,
        currentPage: 1,
        offset: 0,
        limit: 5,
        totalRecords: 10,
        maxVisiblePaginationButtons: 3,
        searchTerm: '',
        filter: {},
        sorting: {
            column: '',
            direction: null
        }
    })
    const [IncrementalBackupTableColumns, setIncrementalBackupTableColumns] = useState<ITableColumnItem[]>([
        {
            id: 1,
            name: 'Log Type',
            column: 'log_type',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true
        },
        {
            id: 2,
            name: 'Increment',
            column: 'increment',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true
        },
        {
            id: 3,
            name: 'Before',
            column: 'before',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true
        },
        
        {
            id: 4,
            name: 'After',
            column: 'after',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true
        },
        {
            id: 5,
            name: 'Create At',
            column: 'create_at',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true
        },
        
        
    ])

    // const DetailOpenHandler = ()=>{
    //     return(
    //         setDetailOpen(!detailOpen)
    //     )
    // }

    const actionButtonGenerator = (row: object) => {
        return <>
            <button aria-label='edit'
                onClick={()=>{

                }}
            ><em className='fa-regular fa-pen text-[#3A3541] opacity-80 mr-2'></em></button>
            <button aria-label='view'
                onClick={()=>{
                    
                }}
            ><em className='fa-regular fa-eye text-[#3A3541] opacity-80'></em></button>
        </>
    }

    const tableDataFormatter = (rowObj: any, format?: string | undefined) => {
        let returningVal = null;
        switch (format) {
            case 'Device Id':
                returningVal = (<>
                    <span className="text-sm hover:text-orange-600 font-medium text-[#FF5e0e] opacity-80 cursor-pointer ">{rowObj}</span>
                </>);
                break;

            case 'Device':
                returningVal=(
                    <span className="text-sm hover:text-gray-900 font-medium text-[#3A3541] opacity-80 cursor-pointer  ">{rowObj}</span>
                    );
                break;
            
            case 'Device Status':
                returningVal=(
                    <>
                    {rowObj === 'Protected' && <div className="text-xs px-3 py-1 text-green-500 font-light bg-green-500 bg-opacity-10 max-w-min rounded-sm">{rowObj}</div>}  
                    {rowObj === 'Unprotected' && <div className="text-xs px-3 py-1 text-red-500 font-light bg-red-500 bg-opacity-10 max-w-min rounded-sm">{rowObj}</div>}  
                </>
                );
                break;

            case 'Last Connection':
                returningVal=(
                    <span className="text-sm hover:text-gray-900 font-medium text-[#3A3541] opacity-80">{rowObj}</span>

                );
                break;

                case 'Last Backup ':
                    returningVal=(
                        <span className="text-sm hover:text-gray-900 font-medium text-[#3A3541] opacity-80">{rowObj}</span>
                  );
                break;

            case 'Protected Data':
                  returningVal=(
                    <>
                    <span className="text-sm font-medium text-gray-600 cursor-pointer text-center flex justify-center ">{rowObj}</span>
                    </>
                );
                break;

             default:
                returningVal = rowObj;
                break;
        }
        return returningVal;
    }

    const handleSwitch = () => {}

    useEffect(()=>{
        if( tableController.data.length > 0 ) {
            return;
        }

        setTableController({
            ...tableController,
            data: [{
                id: 1,
                device_id: "123",
                device:"UserPc",
                device_status:"Unprotected",
                last_connection: "3 Months ago",
                last_backup: "3 Months ago",
                protected_data: "3 GB",
            },
            {
                id: 2,
                device_id: "223",
                device:"Win",
                device_status:"Unprotected",
                last_connection: "3 Months ago",
                last_backup: "3 Months ago",
                protected_data: "2 GB",
            },
            {
                id: 3,
                device_id: "223",
                device:"Mac12",
                device_status:"Protected",
                last_connection: "1 Months ago",
                last_backup: "1 Months ago",
                protected_data: "30 GB",
            },
            {
                id: 3,
                device_id: "223",
                device:"Mac12",
                device_status:"Protected",
                last_connection: "1 Months ago",
                last_backup: "1 Months ago",
                protected_data: "30 GB",
            },
            {
                id: 3,
                device_id: "223",
                device:"Mac12",
                device_status:"Protected",
                last_connection: "1 Months ago",
                last_backup: "1 Months ago",
                protected_data: "30 GB",
            },
            
            ]
        })

    },[tableController.data]);

    const IncrementalBacukupTableDataFormatter = (rowObj: any, format?: string | undefined) => {
        let returningVal = null;
        switch (format) {
            case 'Log Type':
                returningVal = (
                    <>
                        <span className="text-sm hover:text-orange-600 font-medium text-[#FF5e0e] opacity-80 cursor-pointer ">{rowObj}</span>
                    </>
                )
                break;

            case 'Increment':
                returningVal = (
                    <>
                        <span className="text-sm hover:text-gray-900 font-medium text-[#3A3541] opacity-80 cursor-pointer  ">{rowObj}</span>
                    </>
                )
                break;
            
            case 'Before':
                returningVal = (
                    <>
                        <span className="text-sm hover:text-gray-900 font-medium text-[#3A3541] opacity-80 cursor-pointer  ">{rowObj}</span>
                    </>
                )
                break;

            case 'After':
                returningVal=(
                    <>
                        <span className="text-sm hover:text-gray-900 font-medium text-[#3A3541] opacity-80">{rowObj}</span>
                    </>
                )
                break;

            case 'Create At':
                returningVal = (
                    <>
                        <span className="text-sm hover:text-gray-900 font-medium text-[#3A3541] opacity-80">{rowObj}</span>
                    </>
                )
                break;

            default:
                returningVal = rowObj;
                break;
        }

        return returningVal;
    }

    useEffect(()=>{
        if( IncrementalBackupTableController.data.length > 0 ) {
            return;
        }

        setIncrementalBackupTableController({
            ...IncrementalBackupTableController,
            data: [{
                id: 1,
                log_type: "Incremental Backup",
                increment:"5.20 GB",
                before:"90.50 GB",
                after: "95.70 GB",
                create_at: "12-12-2021",
            },
            {
                id: 2,
                log_type: "Incremental Backup",
                increment:"5.20 GB",
                before:"90.50 GB",
                after: "95.70 GB",
                create_at: "12-12-2021",
            },
            {
                id: 3,
                log_type: "Incremental Backup",
                increment:"5.20 GB",
                before:"90.50 GB",
                after: "95.70 GB",
                create_at: "12-12-2021",
            },
            {
                id: 4,
                log_type: "Incremental Backup",
                increment:"5.20 GB",
                before:"90.50 GB",
                after: "95.70 GB",
                create_at: "12-12-2021",
            },
            {
                id: 5,
                log_type: "Incremental Backup",
                increment:"5.20 GB",
                before:"90.50 GB",
                after: "95.70 GB",
                create_at: "12-12-2021",
            },
            ]
        })

    },[IncrementalBackupTableController.data]);

    return (
        <>
            <div className={`p-6 grow flex flex-col`}>    
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Endpoint User profile <em className="inline fa-solid fa-dash mx-2"></em> abc xyz</h1>
                <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-home', link: '', name: 'Home' },
                    { icon: '', link: '', name: 'Managed Services' },
                    { icon: '', link: '', name: 'Endpoints' },
                    { icon: '', link: '', name: 'Endpoint Users' },
                    { icon: '', link: '', name: 'Endpoint User profile' },
                    { icon: '', link: '', name: 'abc xyz' }
                ]} />

                <div className="grow flex flex-col md:flex-row gap-6 w-full">
                    <div className="md:w-1/3">
                        <Tile rowSpan={20} colSpan={4} className={`h-[650px] w-[380px] md:w-full`} >
                                <div className="flex flex-col py-6 mx-6">
                                    <div className="flex flex-col items-center justify-center align-middle text-center"> 
                                        <i className="fa-thin fa-user w-48 h-48 text-8xl mt-4  text-[#FF5E0E] flex  justify-center items-center rounded-md "/>
                                        <span className="text-[#3A3541] font-medium text-xl mt-4">Test User</span>
                                        <small className="px-4 py-1 bg-red-600 text-red-600 bg-opacity-10 mt-1 font-normal">1 Device need attention</small>
                                    </div>
                                    <div className={`border-y py-4 mt-12 space-y-2 text-base font-medium text-gray-700 w-full  `}>
                                        <div className="flex flex-col justify-between items-center">
                                            <div className="flex flex-col justify-start items-left text-left w-full text-base text-gray-600 font-medium">
                                                User Id 
                                                <small className=" text-[#FF5E0E] text-sm">62d53e2da8214d</small>
                                            </div>
                                            <div className="flex flex-col justify-start items-left text-left w-full text-base text-gray-600 font-medium">
                                                Email Id
                                                <small className=" text-[#FF5E0E] text-sm">abcxyz+48@zeeq.io</small>
                                            </div>
                                        </div>
                                        <div className="flex flex-col justify-between  items-center">
                                            <div className="flex flex-col justify-start items-left text-left w-full text-base text-gray-600 font-medium">
                                                Number of Devices 
                                                <small className=" text-[#FF5E0E] text-sm"> 2 Device(s)</small>
                                            </div>
                                            <div className="flex flex-col justify-start items-left text-left w-full text-base text-gray-600 font-medium">
                                                Created On
                                                <small className=" text-[#FF5E0E] text-sm"> 15/07/2022, 10:24 AM</small>
                                            </div>
                                        </div>
                                        <div className="flex flex-row justify-between  items-center">
                                            <div className="flex flex-col justify-start items-left text-left w-full text-base text-gray-600 font-medium">
                                            Updated On
                                            <small className=" text-[#FF5E0E] text-sm"> 15/07/2022, 10:24 AM</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </Tile>
                    </div>

                    <div className="page grid lg:grid-cols-8 md:grid-cols-6 gap-6 md:w-full w-[380px]">
                        <Tile5x4_ChartText 
                            sideBar={false}
                            clickNavigate={false}
                            navigationPath='/'
                            title={
                                < div className="flex items-center">
                                        Total Protected Data
                                </div>}
                            description=""
                            value={<em className="fa-duotone fa-shield-check text-5xl text-[#ff5e0e]"></em>}
                            className='border relative'
                            tileMode='default'
                            alert={false}
                            live={false}
                        >
                            <div className="text-4xl text-[#ff5e0e] w-28 h-28 flex items-center text-right justify-end rounded-md absolute right-4">3.7 GB</div>
                        </Tile5x4_ChartText> 
                        
                        <Tile5x4_ChartText 
                            sideBar={false}
                            clickNavigate={false}
                            navigationPath='/'
                            title={
                                < div className="flex items-center">
                                    Total Restored Data
                                </div>
                            }
                            description=""
                            value={<em className="fa-duotone fa-download text-5xl text-[#ff5e0e]"></em>}
                            textValue={<></>}
                            textColor="text-green-600"
                            designOption="chart"
                            chartType="donut" 
                            chartLabels={[]} 
                            chartDataset={[
                                {
                                    label: '# of Votes',
                                    data: [],
                                    backgroundColor: [
                                    ],
                                    borderWidth: 5,
                                    cutout: 43,
                                    borderRadius: 20
                                }
                            ]}
                            chartOptions={{
                                responsive: true,
                                plugins: {
                                    legend: { display: false },
                                    title: { display: false },
                                },
                                scales: {
                                    x: { display: false },
                                    y: { display: false },
                                },
                            }}
                            inChartMain='' inChartSub=""
                            className='border relative'
                            tileMode='default'
                            alert={false}
                            live={false}
                        >
                            <div className="text-4xl text-[#ff5e0e] w-28 h-28 flex items-center text-right justify-end rounded-md absolute right-4">9.8 Gb</div>
                        </Tile5x4_ChartText> 

                        <Tile colSpan={8}  className="pt-2 h-[410px] lg:h-[470px] " >
                            <div className="p-4 text-gray-600 font-semibold">Test Devices</div>
                            <div className="absolute left-0 right-0 top-16 bottom-0 h-auto w-auto">
                            <Table
                                navRef={navRaf}
                                actionButtonGenerator={actionButtonGenerator}
                                columns={tableColumns}
                                setColumns={setTableColumns}
                                formatData={tableDataFormatter}
                                showActionCell={false}
                                showActionHeaderCell={false}
                                showColumnManagementMenu={false}
                                switchHandler={handleSwitch}
                                tableController={tableController}
                                tableId="DeviceTable"
                            />
                            </div>
                        </Tile>
                    </div>
                </div>

                <div className="grow flex flex-col md:flex-row ">
                    <div className="page grid grid-cols-12 gap-6 mt-6 min-h-max md:w-full w-[380px]">
                        <Tile colSpan={8} rowSpan={15} className="pt-2 " >
                                    <div className="px-6 py-4 font-semibold text-gray-600">Incremental backups in last 24 hours</div>
                                    <input type="text" className="px-4 py-2 bg-slate-100 rounded-md w-10/12 md:w-1/2 outline-none ml-4  mb-6" placeholder="Search Backup logs"/>
                                    <Table
                                    navRef={navRaf}
                                    actionButtonGenerator={actionButtonGenerator}
                                    columns={IncrementalBackupTableColumns}
                                    setColumns={setIncrementalBackupTableColumns}
                                    formatData={IncrementalBacukupTableDataFormatter}
                                    showActionCell={false}
                                    showActionHeaderCell={false}
                                    showColumnManagementMenu={false}
                                    switchHandler={handleSwitch}
                                    tableController={IncrementalBackupTableController}
                                    tableId="Incrementalbackup"
                                    />
                        </Tile>
                        <Tile colSpan={4} rowSpan={15} className="pt-2 mb-6" >
                                <div className="p-6  font-semibold text-gray-600 flex flex-col border-b border-[#ff5e0e]">Largest Incremental Backups
                                    <small className="text-gray-400 font-normal ">Test User's Top 5  Largest Backups</small></div>
                                    <div className="flex flex-col text-gray-700 mt-[5px] ">
                                        <div className="flex flex-row justify-between px-8 text-sm border-b py-2">
                                            <div className="flex flex-col font-semibold text-[#ff5e0e]">4.1 GB<small className="text-gray-400 font-normal">Done by device-SYSTEM</small></div>
                                            <div className="flex flex-col font-semibold">Added 2 days ago<small className="text-gray-400 font-normal">11-02-2022 02:02  AM</small></div>
                                        </div>
                                        <div className="flex flex-row justify-between px-8 text-sm border-b py-2">
                                            <div className="flex flex-col font-semibold text-[#ff5e0e]">4.1 GB<small className="text-gray-400 font-normal">Done by device-SYSTEM</small></div>
                                            <div className="flex flex-col font-semibold">Added 2 days ago<small className="text-gray-400 font-normal">11-02-2022 02:02  AM</small></div>
                                        </div>
                                        <div className="flex flex-row justify-between px-8 text-sm border-b py-2">
                                            <div className="flex flex-col font-semibold text-[#ff5e0e]">4.1 GB<small className="text-gray-400 font-normal">Done by device-SYSTEM</small></div>
                                            <div className="flex flex-col font-semibold">Added 2 days ago<small className="text-gray-400 font-normal">11-02-2022 02:02  AM</small></div>
                                        </div>
                                        <div className="flex flex-row justify-between px-8 text-sm border-b py-2">
                                            <div className="flex flex-col font-semibold text-[#ff5e0e]">4.1 GB<small className="text-gray-400 font-normal">Done by device-SYSTEM</small></div>
                                            <div className="flex flex-col font-semibold">Added 2 days ago<small className="text-gray-400 font-normal">11-02-2022 02:02  AM</small></div>
                                        </div>
                                        <div className="flex flex-row justify-between px-8 text-sm border-b py-2">
                                            <div className="flex flex-col font-semibold text-[#ff5e0e]">4.1 GB<small className="text-gray-400 font-normal">Done by device-SYSTEM</small></div>
                                            <div className="flex flex-col font-semibold">Added 2 days ago<small className="text-gray-400 font-normal">11-02-2022 02:02  AM</small></div>
                                        </div>
                                </div>
                                    
                        </Tile>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EndpointUserProfile;