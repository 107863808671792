import { Breadcrumb, Button, Dropdown, Modal, ModalBody, ModalHeader, Tile } from "../components/core"
import React, { useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import { Table } from "../components/core/Table";
import { ITableColumnItem, ITableControllerProps } from "../components/core/Table/Table";
import { getAPICall } from "../services/APILayer/axiosMethodCalls";
import { ThemeContext } from "../contexts";
import { Link } from "react-router-dom";
import { getFormattedDate } from "../utils/format";

const ManageOrder = () => {
    const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    const [donutCutout, setDonutCutout] = useState<number>(50);
    const [pageSize, setPageSize] = useState<number[]>([0, 0]);
    const [responsiveBreakPoint, setBreakPoint] = useState<string>('sm');

    useLayoutEffect(() => {
        function updateSize() {
            setPageSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(()=>{
        if(pageSize[0] <= 640) {
            setBreakPoint('sm')
        }
        if(pageSize[0] > 640 && pageSize[0] <= 768) {
            setBreakPoint('md')
        }
        if(pageSize[0] > 768 && pageSize[0]  <= 1024) {
            setBreakPoint('lg')
        }
        if(pageSize[0] > 1024 && pageSize[0]  <= 1280) {
            setBreakPoint('xl')
        }
        if(pageSize[0] > 1280) {
            setBreakPoint('2xl')
        }
    },[pageSize]);

    useEffect(()=>{
        if(responsiveBreakPoint === 'sm') {
            setDonutCutout(80);
        }
        if(responsiveBreakPoint === 'md') {
            setDonutCutout(50);
        }
        if(responsiveBreakPoint === 'lg') {
            setDonutCutout(80);
        }
        if(responsiveBreakPoint === 'xl') {
            setDonutCutout(55);
        }
        if(responsiveBreakPoint === '2xl') {
            setDonutCutout(80);
        }
    },[responsiveBreakPoint])

    // const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};
    const navRaf = useRef<HTMLDivElement>(null)

    const [myOrgLicenseInfo, setMyOrgLicenseInfo] = useState<any>({});
    const [ordersInfo, setOrdersInfo] = useState<any[]>([]);
    const [modalVisible, setModalVisibility]=useState(false);
    const [selectedOrder, setSelectedOrder]=useState<any>(null);
    const [orgModalInfo, setOrgModalInfo] = useState<any>(null);
    const [orgModalVisibilityState, modalVisibilityState] = useState<any>(null);
    const [tableController, setTableController] = useState<ITableControllerProps>({
        data: [],
        loader: false,
        currentPage: 1,
        offset: 0,
        limit: 5,
        totalRecords: 10,
        maxVisiblePaginationButtons: 5,
        searchTerm: '',
        filter: {},
        sorting: {
            column: '',
            direction: null
        }
    });
    const [tableColumns, setTableColumns] = useState<ITableColumnItem[]>([
        {
            id: 1,
            name: 'Order Id',
            column: '_id',
            textAlign: 'text-left',
            textColor: 'text-[#ff5e0e]',
            visibility: true,
            className: "pl-4 w-[220px] "
        },
        {
            id: 2,
            name: 'Order From',
            column: 'hubspot_issuer_company_id',
            textAlign: 'text-left',
            textColor: 'text-[#FF5E0E]',
            visibility: true,
            className: "w-[120px]"
        },
        {
            id: 2,
            name: 'Order For',
            column: 'hubspot_owner_company_id',
            textAlign: 'text-left',
            textColor: 'text-[#FF5E0E]',
            visibility: true,
            className: "w-[120px]"
        },
        {
            id: 3,
            name: 'Order Value',
            column: 'order_value',
            textAlign: 'text-left',
            textColor: 'text-slate-500',
            visibility: true,
            className: "w-[120px] font-medium"
        },
        {
            id: 4,
            name: 'Created Date',
            column: 'createdAt',
            textAlign: 'text-left',
            textColor: 'text-slate-500',
            visibility: true,
            className: "w-[220px]"
        },
        {
            id: 4,
            name: 'Updated Date',
            column: 'updatedAt',
            textAlign: 'text-left',
            textColor: 'text-slate-500',
            visibility: true,
            className: "w-[220px]"
        },
        {
            id: 5,
            name: 'Product Tags',
            column: 'order_data',
            textAlign: 'text-left',
            textColor: '',
            visibility: true,
            className: "w-[220px]"
        },
        // {
        //     id: 6,
        //     name: 'Expire Date',
        //     column: 'createdAt',
        //     textAlign: 'text-left',
        //     textColor: 'text-slate-500',
        //     visibility: true,
        //     className: "w-[220px]"
        // },
        // {
        //     id: 7,
        //     name: 'Order Status',
        //     column: 'hubspot_owner_company_id',
        //     textAlign: 'text-left',
        //     textColor: 'text-slate-800',
        //     visibility: true,
        //     className: "w-[180px]"
        // },
    ]);

    const getMyOrgLicenseInfo = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/app/licenses/me`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( Object.keys(myOrgLicenseInfo).length > 0 ) {
            return;
        }
        const fetchMyOrgLicenseInfo = () => {
            getMyOrgLicenseInfo()
                .then((license)=>{
                    console.log({license})
                    setMyOrgLicenseInfo(license)
                })
                .catch(err=>console.log(err));
        }
        fetchMyOrgLicenseInfo();
    },[myOrgLicenseInfo])

    const getOrderIssuedByIssuerCompany = async (issuerCompanyId: string) => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/app/orders?hubspot_issuer_company_id=${issuerCompanyId}`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( Object.keys(myOrgLicenseInfo).length < 1 ) {
            return;
        }
        const fetchOrderIssuedByIssuerCompany = async () => {
            let issuerCompanyId = myOrgLicenseInfo?.license?.hubspot_owner_company_id || "";
            getOrderIssuedByIssuerCompany(issuerCompanyId)
                .then((orders)=>{
                    setOrdersInfo(orders?.orders || [])
                })
                .catch(err=>console.log(err));
        }

        
        fetchOrderIssuedByIssuerCompany();
    },[myOrgLicenseInfo])

    useEffect(()=>{
        if( ordersInfo.length < 1 ) {
            return;
        }
        setTableController({
            ...tableController,
            data: ordersInfo
        })
    },[ordersInfo])

    const actionButtonGenerator = (row: object) => {
        return <>
            {/* <button aria-label='edit'
                onClick={()=>{

                }}
            ><em className='fa-regular fa-pen text-[#3A3541] opacity-80 mr-2'></em></button> */}
            <button aria-label='view'
                onClick={()=>{
                    
                }}
            ><em className='fa-regular fa-eye text-[#3A3541] opacity-80'></em></button>
        </>
    }

    const handleOrgIdClick = async (orgId: any, title: string) => {
        // let org = await getOrgInfo(orgId);
        setOrgModalInfo((prevState: any)=>{
            return {
                modelTitle: title,
                // ...org
            }
        });
        modalVisibilityState(true)
    }

    const tableDataFormatter = (rowObj: any = {}, format?: string | undefined) => {
        let returningVal = null;
        switch (format) {

            case 'Order From':
                returningVal = (
                    <div 
                        className="cursor-pointer ml-[2px] w-[120px]"
                        onClick={()=>{
                            handleOrgIdClick(rowObj, 'Order From')
                        }}
                    >{rowObj}</div>
                );
            break;

            case 'Order For':
                returningVal = (
                    <div 
                        className="cursor-pointer ml-[2px] w-[120px]"
                        onClick={()=>{
                            handleOrgIdClick(rowObj, 'Order For')
                        }}
                    >{rowObj}</div>
                );
            break;
            // case 'Order Value':
            //     returningVal = (
            //         <div 
            //             className="ml-[2px] w-[120px]"
            //         >6257160</div>
            //     );
            // break;
            case 'Created Date':
                returningVal = (
                    <div 
                        className="ml-[2px] w-[220px]"
                    >{getFormattedDate(rowObj)}</div>
                );
            break;
            case 'Updated Date':
                returningVal = (
                    <div 
                        className="ml-[2px] w-[220px]"
                    >{getFormattedDate(rowObj)}</div>
                );
            break;
            case 'Product Tags':
                console.log({rowObj})
                returningVal = (
                    <div className="flex ml-[2px] max-w-min  items-center justify-start w-[220px] ">
                        {rowObj.filter((item: any)=>{return item.tag === 'lina-endpoint'}).length > 0  && <div className="bg-orange-500 px-2 py-1 text-white rounded-sm mr-2">Lina</div>}
                        {rowObj.filter((item: any)=>{return item.tag === 'tina-endpoint'}).length > 0  && <div className="bg-orange-500 px-2 py-1 text-white rounded-sm mr-2">Tina</div>}
                    </div>
                );
            break;
            // case 'Order Status':
            //     returningVal = (
            //         <div 
            //             className="ml-[2px] max-w-min bg-orange-200 px-2 py-1 rounded-sm"
            //         >Pending for Approval</div>
            //     );
            // break;

            default:
                returningVal = rowObj;
                break;

        }
        return returningVal;
    }

    const handleSwitch = () => {}

    const handleModalVisibility = (orderDetails: any) => {
        setSelectedOrder(orderDetails)
        setModalVisibility(true)
    }

    return (
        <>
            <div className={`p-6 grow flex flex-col`}>    
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Manage Order</h1>
                <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-home', link: '', name: 'Home' },
                    { icon: '', link: '/orders', name: 'Manage Order' },
                ]} />

                <div className={`grow flex flex-col gap-6`}>
                    <Tile colSpan={9} rowSpan={25} className="pt-2 mb-6" >
                        <div className="p-4 flex md:flex-row md:gap-4 gap-2 w-full">
                            <div className=" text-gray-800 font-medium">Orders</div>
                            <div className="flex justify-between w-full ">
                            <label htmlFor="searchBox" className="search flex items-center border rounded-md px-3 py-[2px]">
                                <em className="fa-solid fa-search text-slate-400 pr-2"></em>
                                <input 
                                    type="text" 
                                    id="searchBox"
                                    placeholder="Search for Order" 
                                    className="outline-none w-full" 
                                    value={tableController.searchTerm} 
                                    // onChange={(e)=>{
                                    //     setTableController((prevState)=>{
                                    //         let newState = {...prevState};
                                    //         newState.searchTerm = e.target.value;
                                    //         return newState;
                                    //     });
                                    // }}
                                />
                            </label> 
                            </div>
                            <Link className="" to="/manage/orders/add">
                                <Button size='sm' className="w-20 sm:w-32 flex items-center justify-center whitespace-nowrap" >
                                    <i className="fa-solid fa-plus text-sm font-medium"/><span className="ml-2 flex items-center">Add <span className="hidden sm:block ml-1">New</span></span>
                                </Button>
                            </Link>
                            </div>
                        <div className="absolute left-0 right-0 sm:top-[80px] top-20 bottom-0 h-auto w-auto">
                            <Table
                                navRef={navRaf}
                                columns={tableColumns}
                                setColumns={setTableColumns}
                                formatData={tableDataFormatter}
                                showActionCell={false}
                                showActionHeaderCell={false}
                                showColumnManagementMenu={false}
                                switchHandler={handleSwitch}
                                tableController={tableController}
                                actionButtonGenerator={actionButtonGenerator}
                                tableId="OrderTable"
                            />
                        </div>
                    </Tile>   
                </div>
                
            </div>

            <Modal modalVisible={modalVisible}  setModalVisibility={()=>{ setModalVisibility(false) }} className='orderInfoModal h-1/3 w-1/3'>
                <ModalHeader setModalVisibility={()=>{ setModalVisibility(false) }} className={`text-[#3A3541] text-base`}>Order Details</ModalHeader>
                <ModalBody className="flex w-full items-center justify-center">
                    {selectedOrder?.order_data.map((item: any, itemIndex: number)=>(
                        <div key={itemIndex} className="grow flex flex-col items-center justify-center align-middle">
                            {item.unit === 'endpoints' && <em className="fa-thin fa-laptop text-8xl text-[#ff5e0e] my-4"></em>}
                            {item.unit === 'GB' && <em className="fa-thin fa-hard-drive text-8xl text-[#ff5e0e] my-4"></em>}
                            <span className="text-lg text-gray-500 uppercase">
                                {item.unit === 'endpoints' && item.unit}
                                {item.unit === 'GB' && 'Storage'}
                            </span>
                            <span className="text-xl text-[#3A3541] font-bold">{item.qty} {item.unit === 'GB' && item.unit}</span>
                        </div>
                    ))}
                </ModalBody>
            </Modal>  

            <Modal modalVisible={orgModalVisibilityState}  setModalVisibility={()=>{ modalVisibilityState(false) }} className='orgDetailModal h-1/2 w-1/4'>
                <ModalHeader setModalVisibility={()=>{ modalVisibilityState(false) }} className={`text-[#3A3541] text-base`}>{orgModalInfo?.modelTitle || ''}</ModalHeader>
                <ModalBody className="flex w-full items-center justify-center">
                    <div className="flex flex-col w-full py-4 justify-center items-center">
                        <div className="p-8 h-44 w-full mb-8 flex justify-center items-center object-contain">
                            <img src="https://cdn.zeeq.io/zeeq-new-logos/Brand/SVG/wordmark_blackorange.svg" alt="logo" className="min-h-max w-auto" />
                        </div>
                        <div className="flex flex-col items-center">
                            <div className="text-[#3A3541] text-2xl font-semibold flex items-center capitalize mb-1">{orgModalInfo?.company?.properties?.name || ''}</div>
                            <div className="text-green-600 text-sm font-light flex items-center py-1 px-4 mb-3 bg-green-500 bg-opacity-10 rounded-sm ">{orgModalInfo?.company?.properties?.type || ''}</div>
                            <div className="text-[#3A3541] text-base flex items-center">{orgModalInfo?.company?.properties?.company_email.split(',')[0] || ''}</div>

                        </div>
                    </div>
                </ModalBody>
            </Modal>         
        </>
    );
}
export default ManageOrder;

