import React, { FC, ReactNode } from "react"

interface ITableRow {
    type: 'header' | 'body';
    children: ReactNode | ReactNode[] ;
    className?:string;
}

const TableRow:FC<ITableRow> = ({
    type,
    children,
    className=''
}) => {
    return (
        type === 'body' ?
        <div className={`row flex w-full break-inside-avoid break-after-auto h-[40px] hover:bg-slate-50  text-[#3A3541] align-middle ${className}`}>
            {children}
        </div> :
        <div className={`row flex break-inside-avoid break-after-auto bg-slate-100 text-[#3A3541] opacity-80 text-xs font-semibold uppercase h-[40px] ${className}`}>
            {children}
        </div>
    )
}

export default TableRow