import React, { FC, useState } from "react";
import { ReactSortable } from "react-sortablejs";

/**

 * @returns 
 */
const SortableCloning: FC = () => {

    const [lgObject, setLGObject] = useState<any[]>([
        {id: 1, name: 'LG-1'},
        {id: 2, name: 'LG-2'},
        {id: 3, name: 'LG-3'},
        {id: 4, name: 'LG-4'},
        {id: 5, name: 'LG-5'},
        {id: 6, name: 'LG-6'},
        {id: 7, name: 'LG-7'},
        {id: 8, name: 'LG-8'},
        {id: 9, name: 'LG-9'},
        {id: 10, name: 'LG-10'},
        {id: 11, name: 'LG-11'},
        {id: 12, name: 'LG-12'}
    ]);
    const [rgObject, setRGObject] = useState<any[]>([
        {id: 1, name: 'RG-1'},
        {id: 2, name: 'RG-2'},
        {id: 3, name: 'RG-3'},
        {id: 4, name: 'RG-4'},
        {id: 5, name: 'RG-5'},
        {id: 6, name: 'RG-6'},
        {id: 7, name: 'RG-7'},
        {id: 8, name: 'RG-8'},
        {id: 9, name: 'RG-9'},
        {id: 10, name: 'RG-10'},
        {id: 11, name: 'RG-11'},
        {id: 12, name: 'RG-12'}
    ]);

    return (
        <>
            <div className={`text-xl font-extrabold p-6 mb-6`}>React Sortable</div>
            <div className={`flex w-full p-6 min-w-max`}>
                <ReactSortable
                    list={lgObject}
                    setList={setLGObject}
                    className="leftGrid bg-red-300 w-1/2 rounded-md shadow-md grid grid-cols-12 gap-4 p-4"
                    group={{ name: 'rightGrid', pull: 'clone', put: true}}
                    animation={200}
                    delayOnTouchOnly={true}
                    delay={2}
                    // clone={(a,b)=>{console.log({a},{b})}}
                    style={{}}
                    onClone={()=>{}}
                >
                { 
                    lgObject.map((o, oId)=>{
                        return <div key={`RG-${o.id}`} className={`${o.name.split('-')[0] === 'LG' ? 'bg-red-400' : 'bg-yellow-400'} col-span-2 h-[135px] p-4 rounded`}>{`${o.name}`}</div>
                        
                    })
                }
                </ReactSortable>
                <ReactSortable
                    list={rgObject}
                    setList={setRGObject}
                    className="rightGrid bg-yellow-300 w-1/2 rounded-md shadow-md grid grid-cols-12 gap-4 p-4"
                    group={{ name: 'leftGrid', pull: false, put: true}}
                    animation={200}
                    delayOnTouchOnly={true}
                    delay={2}
                    // clone={(a,b)=>{console.log({a},{b})}}
                    style={{}}
                    onClone={()=>{}}
                >
                { 
                    rgObject.map((o, oId)=>{
                        return <div key={`LG-${oId}`} className={`${o.name.split('-')[0] === 'LG' ? 'bg-red-400' : 'bg-yellow-400'} col-span-2 h-[135px] p-4 rounded`}>{`${o.name}`}</div>
                    })
                }
                </ReactSortable>
            </div>

        </>
    )
}

export default SortableCloning;
