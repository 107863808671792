import { Breadcrumb, Button, Dropdown, Tile } from "../../components/core"
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState} from "react";
import ProgressBar from "../ProgressBar";
import Switch from "../../components/core/Switch/Switch";
import { Link } from "react-router-dom";
import { getAPICall, postAPICall, putAPICall } from "../../services/APILayer/axiosMethodCalls";
import { toast } from "react-toastify";
import { dataSizeConversion } from "../../utils/format";


const AddLicense = () => {
    const [myCompanyLicense, setMyCompanyLicense] = useState<any>(null);
    const [selected,setSelected] = useState<number>(1);
    const [ownerCompanyId, setOwnerCompanyId] = useState<string|null>(null);
    const [company, setCompany] = useState<any>(null);
    const [license, setLicense] = useState<any>(null);
    const [order, setOrder] = useState<any>(null);

    const getMyLicense = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/app/licenses/me`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const getAllMyLicenses = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/app/licenses?hubspot_issuer_company_id=${myCompanyLicense?.hubspot_owner_company_id}`)
            return response.data;
        } catch (error) {
            return error
        }
    }

    const getAllMyOrders = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/app/orders?hubspot_issuer_company_id=${myCompanyLicense?.hubspot_owner_company_id}`)
            return response.data;
        } catch (error) {
            return error
        }
    }

    useEffect(()=>{
        let ownerCompanyId = (document?.location?.search || "").split('?').splice(1,)[0]?.split('=')[1]
        if( ownerCompanyId ) {
            setOwnerCompanyId(ownerCompanyId)
        }
    },[])

    useEffect(()=>{
        if( myCompanyLicense ) {
            return;
        }
        getMyLicense()
                .then((data)=>{
                    console.log({myCompanyLicense: data})
                    setMyCompanyLicense(data.license)
                })
                .catch((error)=>{ console.log(error) })
                .finally()
    },[myCompanyLicense])

    useEffect(()=>{
        if( company === null && myCompanyLicense === null ) {
            return;
        }
        const fetchAllLicenses = async () => {
            getAllMyLicenses()
                .then((data)=>{
                    const companyLicense = [...data?.licenses].find((l:any)=>{ return l.hubspot_owner_company_id === company.hubspot_company_id })
                    if(companyLicense) {
                        if( ownerCompanyId === "" || ownerCompanyId === null || ownerCompanyId === undefined  ) {
                            toast.info('License for company already exists.')
                        }
                        setLicense(companyLicense)
                    }
                })
                .catch((error)=>{ console.log(error) })
                .finally()
        }
        fetchAllLicenses();
    },[company, myCompanyLicense])

    useEffect(()=>{
        if( license === null && company === null && myCompanyLicense === null ) {
            return;
        }
        const fetchAllOrders = async () => {
            getAllMyOrders()
                .then((data)=>{
                    console.log({companyOrders: [...data?.orders].find((o:any)=>{ return o.hubspot_owner_company_id === company.hubspot_company_id })})
                    const companyOrder = [...data?.orders].find((o:any)=>{ return o.hubspot_owner_company_id === company.hubspot_company_id })
                    if(companyOrder) {
                        // toast.info('Order for company already exists.')
                        setOrder(companyOrder) 
                    }
                })
                .catch((error)=>{ console.log(error) })
                .finally()
        }
        fetchAllOrders();
    },[license, company, myCompanyLicense])

    return (
        <>
            <div className={`p-6 grow flex flex-col`}>    
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Assign License</h1>
                <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-home', link: '', name: 'Home' },
                    { icon: '', link: '/manage/license', name: 'Manage License' },
                    { icon: '', link: ownerCompanyId ? `?ownerCompanyId=${ownerCompanyId}` : '', name: 'Assign License' },
                ]} />
                <ProgressBar selected={selected} setSelected={setSelected}/>
                <div className="flex flex-row items-start gap-6">
                    <div className="hidden lg:block">
                        <div className="flex flex-col w-[400px] bg-white rounded-md shadow-md hover:shadow-lg min-h-[400px]" >
                                <div className="text-slate-500 font-medium text-lg flex items-center border-b p-6 mb-4 cursor-pointer">License Assignment</div>
                            <div className="flex flex-col p-4">
                                <div onClick={()=>{ setSelected(1) }} className={`${selected === 1 ? "bg-[#ff5e0e] text-white bg-opacity-80" : "bg-gray-100 text-gray-800"} font-normal text-base p-2 my-1 w-full rounded-md cursor-pointer`}>Validate</div>
                                <div onClick={()=>{ setSelected(2) }} className={`${selected === 2 ? "bg-[#ff5e0e] text-white bg-opacity-80" : "bg-gray-100 text-gray-800"} font-normal text-base p-2 my-1 w-full rounded-md cursor-pointer`}>License</div>
                                <div onClick={()=>{ setSelected(3) }} className={`${selected === 3 ? "bg-[#ff5e0e] text-white bg-opacity-80" : "bg-gray-100 text-gray-800"} font-normal text-base p-2 my-1 w-full rounded-md cursor-pointer`}>Order</div>
                                <div onClick={()=>{ setSelected(4) }} className={`${selected === 4 ? "bg-[#ff5e0e] text-white bg-opacity-80" : "bg-gray-100 text-gray-800"} font-normal text-base p-2 my-1 w-full rounded-md cursor-pointer`}>Export</div>
                            </div>
                        </div>
                    </div>
                    {selected === 1 && (<CreateCompany selected={selected} setSelected={setSelected} company={company} setCompany={setCompany} ownerCompanyId={ownerCompanyId} />)}
                    {selected === 2 && (<CreateLicense selected={selected} setSelected={setSelected} myCompanyLicense={myCompanyLicense} company={company} setCompany={setCompany} license={license} setLicense={setLicense} />)}
                    {selected === 3 && (<OrderProcess selected={selected} setSelected={setSelected} company={company} order={order} setOrder={setOrder} license={license} setLicense={setLicense} />)}
                    {selected === 4 && (<Export company={company} order={order} license={license} />)}
                </div>
            </div>

        </>
    )
}

export default AddLicense;

interface ICreateCompany {
    selected: number;
    setSelected: Dispatch<SetStateAction<number>>
    company: any;
    setCompany: Dispatch<SetStateAction<any>>;
    ownerCompanyId: string|null;
}

const CreateCompany:FC<ICreateCompany> = ({
    selected,
    setSelected,
    company,
    setCompany,
    ownerCompanyId
})=>{

    const [form, setForm] = useState<any>({
        companyId: "",
        companyName: "",
        domain: ""
    });
    const [formErr, setFormErr] = useState<any>({
        companyId: null,
        companyName: null,
        domain: null
    });

    const [validationLoader, setValidationLoader] = useState(false);

    const getCompanyProfile = async (companyId: String) => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/crm/companies/${companyId}`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( ownerCompanyId === "" || ownerCompanyId === null || ownerCompanyId === undefined ) {
            return;
        }

        getCompanyProfile(ownerCompanyId)
                .then((data)=>{
                    toast.success("Company validated successfully")
                    setCompany(data)
                })
                .catch((error)=>{ 
                    console.log({error})
                })
                .finally(()=>{
                    setValidationLoader(false)
                })
    },[ownerCompanyId])

    const validateCompanyIdHandler = () => {
        setFormErr({
            overall: null,
            companyId: null,
            companyName: null,
            domain: null
        })

        let cId = form?.companyId || "";
        console.log({cId},{form})
        if( cId === "" ) {
            setFormErr({
                ...formErr,
                companyId: "Required field"
            })
            return;
        }
        setValidationLoader(true)
        getCompanyProfile(cId)
                .then((data)=>{
                    if( ownerCompanyId === "" || ownerCompanyId === null || ownerCompanyId === undefined  ) {
                        toast.success("Company validated successfully")
                    }
                    setCompany(data)
                })
                .catch((error)=>{ 
                    console.log({error})
                })
                .finally(()=>{
                    setValidationLoader(false)
                })
    }

    useEffect(()=>{
        if(!company) {
            return;
        }
        setForm({
            companyId: company.company.id,
            companyName: company.company.properties.name,
            domain: company.company.properties.domain
        })
    },[company])

    return(
        <>
            <div  className="gap-6 grow flex flex-col bg-white rounded-md shadow-md hover:shadow-lg min-h-[400px] pb-6" >
                        <div className="flex w-full items-center p-6 border-b justify-between text-center text-gray-600 font-medium text-lg">
                            {/* <div className="text-gray-600">Validate Company</div> */}
                            <div className="text-gray-600 flex items-center">Validate Company</div>
                            <i className="fa-solid fa-ellipsis-vertical cursor-pointer block lg:hidden"/>
                        </div>
                        <div className={`bg-slate-100 bg-opacity-25 mx-6 border rounded-md`}>
                            <div className={` transition-[height] duration-500s ease-in-outs`}>
                                <div className="text-base font-medium text-slate-700 bg-slate-100 w-full border-b p-4">Please Enter Company Details</div>
                                <div className="flex flex-col items-start justify-start p-6">
                                    <div className="flex items-center w-full mb-6">
                                        <label className="text-sm text-slate-600 font-medium mr-4 w-[150px] whitespace-nowrap">
                                            Company Code :
                                        </label>
                                        <div className="flex flex-col w-full">
                                            <div className="flex border rounded-md px-2 py-1 w-full">
                                                <input 
                                                    value={company?.id || form?.companyId || ""}
                                                    type="text"
                                                    className="grow outline-none"
                                                    onChange={(e)=>{
                                                        setFormErr({
                                                            ...formErr,
                                                            companyId: null
                                                        })
                                                        setForm((previousState: any)=>{
                                                            let newState = {...previousState}
                                                            newState.companyId = e.target.value;
                                                            return newState;
                                                        })
                                                    }}
                                                />
                                                <Button
                                                    size="sm"
                                                    className={`${validationLoader ? 'w-[100px]' : 'w-[70px]'}`}
                                                    onClick={()=>{
                                                        validateCompanyIdHandler()
                                                    }}
                                                    disabled={company ? true : false}
                                                >
                                                    {validationLoader && <em className="fa-duotone fa-loader fa-spin mr-2"></em>}
                                                    Validate
                                                </Button>
                                            </div>
                                            <small className="text-red-500">{formErr?.companyId && formErr?.companyId}</small>
                                        </div>
                                    </div>
                                    <div className="flex items-center w-full mb-6">
                                        <label className="text-sm text-slate-600 font-medium mr-4 w-[150px] whitespace-nowrap">Company Name :</label>
                                        <div className="flex flex-col w-full">
                                            <input
                                                value={form?.companyName || ""}
                                                type="text"
                                                readOnly={true}
                                                className="border outline-none rounded-md p-2 w-full"
                                                onChange={(e)=>{
                                                    setFormErr({
                                                        ...formErr,
                                                        companyName: null
                                                    })
                                                    setForm((previousState: any)=>{
                                                        let newState = {...previousState}
                                                        newState.companyName = e.target.value;
                                                        return newState;
                                                    })
                                                }}
                                            />
                                            <small className="text-red-500">{formErr?.companyName && formErr?.companyName}</small>
                                        </div>
                                    </div>
                                    <div className="flex items-center w-full mb-6">
                                        <label className="text-sm text-slate-600 font-medium mr-4 w-[150px] whitespace-nowrap">Domain :</label>
                                        <div className="flex flex-col w-full">
                                            <input
                                                value={form?.domain || ""}
                                                type="text"
                                                readOnly={true}
                                                className="border outline-none rounded-md p-2 w-full"
                                                onChange={(e)=>{
                                                    setFormErr({
                                                        ...formErr,
                                                        domain: null
                                                    })
                                                    setForm((previousState: any)=>{
                                                        let newState = {...previousState}
                                                        newState.domain = e.target.value;
                                                        return newState;
                                                    })
                                                }}
                                            />
                                            <small className="text-red-500">{formErr?.domain && formErr?.domain}</small>
                                        </div>
                                    </div>
                                    {/* <div className="flex items-center w-full mb-6">
                                        <div className="text-sm text-slate-600 font-medium mr-4 w-[150px]">Email :</div>
                                        <input  type="email" className="border rounded-md p-2 w-full"/>
                                    </div>
                                    <div className="flex items-center w-full mb-6">
                                        <div className="text-sm text-slate-600 font-medium mr-4 w-[150px]">Address :</div>
                                        <input  type="text" className="border rounded-md p-2 w-full"/>
                                    </div>
                                    <div className="flex flex-col xl:flex-row items-center w-full text-left gap-8 mb-6">
                                        <div className="w-full xl:w-1/2 flex items-center">
                                            <div className="text-sm text-slate-600 font-medium mr-4 w-[150px] whitespace-nowrap text-left ">Zip Code :</div>
                                            <input  type="number" className="border rounded-md p-2 w-full"/>
                                        </div>
                                        <div className="w-full xl:w-1/2 flex items-center">
                                            <div className="text-sm text-slate-600 font-medium mr-4 w-[150px]">City :</div>
                                            <input  type="text" className="border rounded-md p-2 w-full"/>
                                        </div>
                                    </div>
                                    <div className="flex flex-col xl:flex-row items-center w-full text-left gap-8 mb-6">
                                        <div className="w-full xl:w-1/2 flex items-center">
                                            <div className="text-sm text-slate-600 font-medium mr-4 w-[150px] xl:w-[180px] whitespace-nowrap text-left ">State :</div>
                                            <input  type="number" className="border rounded-md p-2 w-full"/>
                                        </div>
                                        <div className="w-full xl:w-1/2 flex items-center">
                                            <div className="text-sm text-slate-600 font-medium mr-4 w-[150px] xl:w-[180px]">Country :</div>
                                            <input  type="text" className="border rounded-md p-2 w-full"/>
                                        </div>
                                    </div>
                                    <div className="flex items-center w-full mb-6">
                                        <div className="text-sm text-slate-600 font-medium mr-4 w-[150px]">Phone :</div>
                                        <input  type="number" className="border rounded-md p-2 w-full"/>
                                    </div>
                                    <div className="flex flex-col xl:flex-row items-center w-full text-left gap-8 mb-6">
                                        <div className="w-full xl:w-1/2 flex items-center">
                                            <div className="text-sm text-slate-600 font-medium mr-4 w-[150px] xl:w-[180px] whitespace-nowrap text-left ">PAN :</div>
                                            <input  type="text" className="border rounded-md p-2 w-full"/>
                                        </div>
                                        <div className="w-full xl:w-1/2 flex items-center">
                                            <div className="text-sm text-slate-600 font-medium mr-4 w-[150px] xl:w-[180px]">GST :</div>
                                            <input  type="text" className="border rounded-md p-2 w-full"/>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                                <div className="flex w-full items-center p-6 justify-end text-gray-600 font-medium text-lg mt-6 gap-6">
                                {/* {!company && <Button size="sm" className="w-20">Submit</Button>} */}
                                {/* {company && <Button 
                                                size="sm"
                                                className="w-20"
                                                onClick={()=>{
                                                    setSelected(2)
                                                }}
                                            >Next</Button>} */}
                                {company && <div className="text-sm text-green-600">Company validated successfully</div>}
                                <Button 
                                    size="sm"
                                    className="flex items-center justify-center w-20"
                                    disabled={!company}
                                    onClick={()=>{
                                        setSelected(2)
                                    }}
                                >
                                    <span>Next</span>
                                    {company && <em className="fa-regular fa-angles-right ml-2"></em>}
                                </Button>
                        </div>

            </div>
        </>
    )
}

interface ICreateLicense {
    myCompanyLicense: any;
    selected: number;
    setSelected: Dispatch<SetStateAction<number>>
    company: any;
    setCompany: Dispatch<SetStateAction<any>>;
    license: any;
    setLicense: Dispatch<SetStateAction<any>>;
}

const CreateLicense:FC<ICreateLicense> = ({
    myCompanyLicense,
    selected,
    setSelected,
    company,
    setCompany,
    license,
    setLicense,
})=>{

    const [licenseCreationLoader, setLicenseCreationLoader] = useState(false);

    const [form, setForm] = useState({
        issuerCompany: myCompanyLicense?.hubspot_owner_company_id || "",
        ownerCompany: company?.company?.id || "",
        startDate: null,
        endDate: null
    })

    const [formError, setFormError] = useState({
        issuerCompany: null,
        ownerCompany: null,
        startDate: null,
        endDate: null
    })

    const validateForm = () => {
        let arrValidation = [];
        let errorMessages: any = {
            issuerCompany: null,
            ownerCompany: null,
            startDate: null,
            endDate: null
        }

        if(form.issuerCompany === "" || form.issuerCompany === null || form.issuerCompany === undefined) {
            arrValidation.push(false)
            errorMessages.issuerCompany = "Required field"
        } else {
            arrValidation.push(true)
        }

        if(form.ownerCompany === "" || form.ownerCompany === null || form.ownerCompany === undefined) {
            arrValidation.push(false)
            errorMessages.ownerCompany = "Required field"
        } else {
            arrValidation.push(true)
        }

        if(form.startDate === "" || form.startDate === null || form.startDate === undefined) {
            arrValidation.push(false)
            errorMessages.startDate = "Required field"
        } else {
            arrValidation.push(true)
        }

        if(form.endDate === "" || form.endDate === null || form.endDate === undefined) {
            arrValidation.push(false)
            errorMessages.endDate = "Required field"
        } else {
            arrValidation.push(true)
        }

        setFormError({...errorMessages})

        if( arrValidation.indexOf(false) === -1 ) 
            return true;
        return false;
    }

    const postLicense = async (licenseId: string, licenseData: any) => {
        try {
            const response = await postAPICall(
                `${process.env.REACT_APP_API_URL}/app/licenses`,
                {...licenseData}
            );
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const createLicense = () => {
        setFormError({
            issuerCompany: null,
            ownerCompany: null,
            startDate: null,
            endDate: null
        })
        let validated: boolean = validateForm();
        if( !validated ) {
            return;
        }
        setLicenseCreationLoader(true)
        let licenseId = ""
        let data = {
            insert: {
                hubspot_owner_company_id: form.ownerCompany,
                hubspot_issuer_company_id: form.issuerCompany,
                license_type: "1",
                license_start_date: form.startDate,
                license_end_date: form.endDate,
                license_data: {},
                metadata: {}
            }
        }
        postLicense(licenseId, data)
                .then((data)=>{
                    setLicense({...data.license})
                    setLicenseCreationLoader(false)
                    toast.success("License created successfully")
                })
                .catch((error)=>{ console.log(error) })
                .finally()
    }
    
    return(
        <>
            <div  className="gap-6 grow flex flex-col bg-white rounded-md shadow-md hover:shadow-lg min-h-[400px] pb-6" >
                <div className="flex w-full items-center p-6 border-b justify-between text-center text-gray-600 font-medium text-lg">
                    <div className="text-gray-600 flex items-center">License <div className="text-sm text-orange-500 ml-4">{license?._id || ""}</div></div>
                </div>
                <div className={`bg-slate-100 bg-opacity-25 mx-6 border rounded-md`}>
                    <div className={` transition-[height] duration-500s ease-in-outs`}>
                        <div className="text-base font-medium text-slate-700 bg-slate-100 w-full border-b p-4">Please Enter the Details</div>
                        <div className="flex flex-col items-start justify-start p-6">
                            <div className="flex w-full my-6">
                                <div className="text-sm pt-2 text-slate-600 font-medium mr-4 w-[140px] whitespace-nowrap text-left">
                                    Issuer Company ID <span className="text-red-500">*</span>
                                </div>
                                <div className="flex flex-col grow">
                                    <input
                                        type="text"
                                        value={form?.issuerCompany || ""}
                                        className="border outline-none rounded-md p-2 w-full"
                                        onChange={(e)=>{
                                            setFormError({
                                                ...formError,
                                                issuerCompany: null
                                            })
                                            setForm((previousState: any)=>{
                                                let newState = {...previousState}
                                                newState.issuerCompany = e.target.value;
                                                return newState
                                            })
                                        }}
                                    />
                                    <small className="text-red-500">{formError?.issuerCompany || ""}</small>
                                </div>
                            </div>      
                            <div className="flex items-center w-full mb-6">
                                <div className="text-sm pt-0 text-slate-600 font-medium mr-4 w-[140px] whitespace-nowrap text-left">
                                    Owner Company ID <span className="text-red-500">*</span>
                                </div>
                                <div className="flex flex-col grow">
                                    <input
                                        type="text"
                                        value={form?.ownerCompany || ""}
                                        readOnly={true}
                                        className="border outline-none rounded-md p-2 w-full"
                                        onChange={(e)=>{
                                            setFormError({
                                                ...formError,
                                                ownerCompany: null
                                            })
                                            setForm((previousState: any)=>{
                                                let newState = {...previousState}
                                                newState.ownerCompany = e.target.value;
                                                return newState
                                            })
                                        }}
                                    />
                                    <small className="text-red-500">{formError?.ownerCompany || ""}</small>
                                </div>
                            </div>
                            <div className="flex flex-col lg:flex-row lg:items-center w-full text-left gap-8 mb-6">
                                <div className="lg:w-1/2 flex items-center">
                                    <div className="text-sm pt-0 text-slate-600 font-medium mr-4 w-[140px] whitespace-nowrap text-left">
                                        Start Date <span className="text-red-500">*</span>
                                    </div>
                                    <div className="flex flex-col grow">
                                        <input
                                            type="date"
                                            value={form?.startDate || (license?.license_start_date || "").split('T')[0] || ""}
                                            className="border outline-none rounded-md p-2 w-full"
                                            onChange={(e)=>{
                                                setFormError({
                                                    ...formError,
                                                    startDate: null
                                                })
                                                setForm((previousState: any)=>{
                                                    let newState = {...previousState}
                                                    newState.startDate = e.target.value;
                                                    return newState
                                                })
                                            }}
                                        />
                                        <small className="text-red-500">{formError?.startDate || ""}</small>
                                    </div>
                                </div>
                                <div className="lg:w-1/2 flex items-center">
                                    <div className="text-sm pt-0 text-slate-600 font-medium mr-4 w-[140px] whitespace-nowrap text-left">
                                        End Date <span className="text-red-500">*</span>
                                    </div>
                                    <div className="flex flex-col grow">
                                        <input
                                            type="date"
                                            value={form?.endDate || (license?.license_end_date || "").split('T')[0] || ""}
                                            className="border outline-none rounded-md p-2 w-full"
                                            onChange={(e)=>{
                                                setFormError({
                                                    ...formError,
                                                    endDate: null
                                                })
                                                setForm((previousState: any)=>{
                                                    let newState = {...previousState}
                                                    newState.endDate = e.target.value;
                                                    return newState
                                                })
                                            }}
                                        />
                                        <small className="text-red-500">{formError?.endDate || ""}</small>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="flex items-center w-full mb-6">
                                <div className="text-sm text-slate-600 font-medium mr-4 w-[150px]">Start Date :</div>
                                <input  type="date" className="border rounded-md p-2 w-full"/>
                            </div>
                            <div className="flex items-center w-full mb-6">
                                <div className="text-sm text-slate-600 font-medium mr-4 w-[150px]">End Date :</div>
                                <input  type="date" className="border rounded-md p-2 w-full"/>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="flex w-full items-center p-6 justify-end text-gray-600 font-medium text-lg mt-6 gap-6">
                    {!license && <Button 
                                    size="sm"
                                    className="w-20"
                                    // disabled={licenseCreationLoader ? true : false}
                                    onClick={()=>{
                                        // validateForm()
                                        createLicense()
                                    }}>
                                        {licenseCreationLoader && <em className="fa-duotone fa-loader fa-spin mr-2"></em>}
                                        Submit
                                    </Button>}
                    {license && <div className="text-sm text-green-600">License created successfully</div>}
                    {license && <Button 
                                    size="sm" 
                                    className="flex items-center justify-center w-20" 
                                    onClick={()=>{setSelected(3)}}>
                                        <span>Next</span>
                                        <em className="fa-regular fa-angles-right ml-2"></em>
                                </Button>}
                </div>
            </div>
        </>
    )
}

interface IOrderProcess {
    selected: number;
    setSelected: Dispatch<SetStateAction<number>>
    company: any;
    order: any;
    setOrder: Dispatch<SetStateAction<any>>;
    license: any;
    setLicense: Dispatch<SetStateAction<any>>;
}

const OrderProcess:FC<IOrderProcess> = ({
    selected,
    setSelected,
    company,
    order,
    setOrder,
    license,
    setLicense
}) =>{

    const [orderObj, setOrderObj] = useState<any[]>([])
    const [orderMeta, setOrderMeta] = useState<any>({})

    const [formMetaData, setFormMetaData] = useState([
        {
            name: "Endpoint",
            icon: "fa-duotone fa-computer",
            description: "Endpoint is a solution for the protection of file servers, laptops and workstations.",
            priority: license?.license_data?.product_access?.endpoints?.priority || 0,
            enable: (order?.order?.order_data || []).filter((o:any)=>{ return o.tag==='lina-endpoint' }).length > 0 ? true : false,
            available: true,
            tag:  "lina-endpoint",
            form: [{
                    index: 0,
                    label: "Endpoints",
                    type: "number",
                    placeholder: "",
                    unit: "Endpoints",
                    value: (order?.order?.order_data || []).find((ep:any)=>{ return ep.tag === 'lina-endpoint' && ep.unit === "endpoints" })?.qty || 0,
                    display: true,
                    readonly: false,
                    error: null,
                },
                {
                    index: 1,
                    label: "Unlimited Storage",
                    type: "switch",
                    placeholder: "",
                    unit: null,
                    value: (order?.order?.order_data || []).find((ep:any)=>{ return ep.tag === 'lina-endpoint' && ep.unit === "GB" })?.qty === -1 ? true : false,
                    display: true,
                    readonly: false,
                    error: null,
                },
                {
                    index: 2,
                    label: "Storage",
                    type: (order?.order?.order_data || []).find((ep:any)=>{ return ep.tag === 'lina-endpoint' && ep.unit === "GB" })?.qty === -1 ? "text" : "number",
                    placeholder: "",
                    unit: "GB",
                    value: (order?.order?.order_data || []).find((ep:any)=>{ return ep.tag === 'lina-endpoint' && ep.unit === "GB" })?.qty || 0,
                    display: true,
                    readonly: false,
                    error: null,
                },
                {
                    index: 3,
                    label: "Lina Server ID",
                    type: "text",
                    placeholder: "",
                    unit: null,
                    value: order?.order?.metadata?.instance?.connection?.server_id || "",
                    display: true,
                    readonly: false,
                    error: null,
                },
                {
                    index: 4,
                    label: "Tenant ID",
                    type: "text",
                    placeholder: "",
                    unit: null,
                    value: order?.order?.metadata?.instance?.connection?.tenant_id || "",
                    display: true,
                    readonly: false,
                    error: null,
                }
            ]
        },
        {
            name: "Microsoft 365",
            icon: "fa-brands fa-windows",
            description: "Tina (Time Navigation) capability provides an intuitive UI to manage virtual environment backups. Administrators can simply view the tree structure and manage.",
            priority: license?.license_data?.product_access?.ms365?.priority || 1,
            enable: (order?.order_data || []).filter((o:any)=>{ return o.tag==='tina-endpoint' }).length > 0 ? true : false,
            available: true,
            tag:  "tina-endpoint",
            form: [{
                    index:  0,
                    label: "Users",
                    type: "number",
                    placeholder: "",
                    unit: "Endpoints",
                    value: (order?.order?.order_data || []).find((ep:any)=>{ return ep.tag === 'tina-endpoint' && ep.unit === "users" })?.qty || 0,
                    display: true,
                    readonly: false,
                    error: null,
                },
                {
                    index:  1,
                    label: "Unlimited Storage",
                    type: "switch",
                    placeholder: "",
                    unit: null,
                    value: (order?.order?.order_data || []).find((ep:any)=>{ return ep.tag === 'tina-endpoint' && ep.unit === "GB" })?.qty === -1 ? true : false,
                    display: true,
                    readonly: false,
                    error: null,
                },
                {
                    index:  2,
                    label: "Storage",
                    type:(order?.order?.order_data || []).find((ep:any)=>{ return ep.tag === 'tina-endpoint' && ep.unit === "GB" })?.qty === -1 ? "text" : "number",
                    placeholder: "",
                    unit: "GB",
                    value: (order?.order?.order_data || []).find((ep:any)=>{ return ep.tag === 'tina-endpoint' && ep.unit === "GB" })?.qty || 0,
                    display: true,
                    readonly: false,
                    error: null,
                },
                {
                    index:  3,
                    label: "Catalog ID",
                    type: "text",
                    placeholder: "",
                    unit: null,
                    value: order?.order?.metadata?.tina_instance?.connection?.catalog_id || "",
                    display: true,
                    readonly: false,
                    error: null,
                },
                {
                    index:  4,
                    label: "Azure ID",
                    type: "text",
                    placeholder: "",
                    unit: null,
                    value: order?.order?.metadata?.tina_instance?.connection?.azure_id || "",
                    display: true,
                    readonly: false,
                    error: null,
                }
            ]
        },
        {
            name: "Migration",
            icon: "fa-duotone fa-arrow-up-arrow-down rotate-90",
            description: "Miria delivers migration for hybrid NAS and file storages and manages massive unstructured file-based data movement between heterogeneous storages.",
            priority: 2,
            enable: false,
            available: false,
            form: [],
        },
        {
            name: "Protected VMs",
            icon: "fa-duotone fa-server",
            description: "Rapid data recovery for minimum downtime for all virtual machines (VMs).",
            priority: 3,
            enable: false,
            available: false,
            form: [],
        },
        {
            name: "Database Server",
            icon: "fa-duotone fa-database",
            description: "Handling the HPC and large data protection and movement challenge!",
            priority: 4,
            enable: false,
            available: false,
            form: [],
        },
    ]);

    useEffect(()=>{
        setFormMetaData((previousState:any[])=>{
            let newState = [...previousState];
            // Endpoints
            newState[0].priority = license?.license_data?.product_access?.endpoints?.priority || 0
            newState[0].enable = (order?.order_data || []).filter((o:any)=>{ return o.tag==='lina-endpoint' }).length > 0 ? true : false
                // number of endpoints
                newState[0].form[0].value = (order?.order_data || []).find((ep:any)=>{ return ep.tag === 'lina-endpoint' && ep.unit === "endpoints" })?.qty || 0
                // unlimited storage switch
                newState[0].form[1].value = (order?.order_data || []).find((ep:any)=>{ return ep.tag === 'lina-endpoint' && ep.unit === "GB" })?.qty === -1 ? true : false
                // storage
                newState[0].form[2].type = (order?.order_data || []).find((ep:any)=>{ return ep.tag === 'lina-endpoint' && ep.unit === "GB" })?.qty === -1 ? "text" : "number"
                newState[0].form[2].value = (order?.order_data || []).find((ep:any)=>{ return ep.tag === 'lina-endpoint' && ep.unit === "GB" })?.qty || 0
                // Lina storage ID
                newState[0].form[3].value = license?.license_data?.instance?.connection?.server_id || ""
                // Tenant ID
                newState[0].form[4].value = license?.license_data?.instance?.connection?.tenant_id || ""

            // Microsoft 365
            newState[1].priority = license?.license_data?.product_access?.ms365?.priority || 1
            newState[1].enable = (order?.order_data || []).filter((o:any)=>{ return o.tag==='tina-endpoint' }).length > 0 ? true : false
                // number of users
                newState[1].form[0].value = (order?.order_data || []).find((ep:any)=>{ return ep.tag === 'tina-endpoint' && ep.unit === "users" })?.qty || 1
                // unlimited storage switch
                newState[1].form[1].value = (order?.order_data || []).find((ep:any)=>{ return ep.tag === 'tina-endpoint' && ep.unit === "GB" })?.qty === -1 ? true : false
                // storage
                newState[1].form[2].type = (order?.order_data || []).find((ep:any)=>{ return ep.tag === 'tina-endpoint' && ep.unit === "GB" })?.qty === -1 ? "text" : "number"
                newState[1].form[2].value = (order?.order_data || []).find((ep:any)=>{ return ep.tag === 'tina-endpoint' && ep.unit === "GB" })?.qty || 0
                // Tina Catalog ID
                newState[1].form[3].value = license?.license_data?.tina_instance?.connection?.catalog_id || ""
                // Azure ID
                newState[1].form[4].value = license?.license_data?.tina_instance?.connection?.azure_id || ""
            
            return newState;
        })
    },[])

    useEffect(()=>{

        let objOrder: any[] = [];
        let objMeta: any = {};

        for (let i = 0; i < formMetaData.filter((f:any)=>{return f.available === true}).length; i++) {
            const product = formMetaData[i];

            if( product.name === 'Endpoint' && product.enable === true ) {
                if( product?.form[0]?.value ) {
                    objOrder.push({
                        type: 1,
                        qty: product?.form[0]?.value || 0,
                        qty_raw: product?.form[0]?.value || 0,
                        tag: "lina-endpoint",
                        unit: "endpoints"
                    });
                }
                if( product?.form[2]?.value ) {
                    objOrder.push({
                        type: 2,
                        qty: product?.form[2]?.value === 'Unlimited' ? -1 : product?.form[2]?.value || 0,
                        tag: "lina-endpoint",
                        qty_raw:  product?.form[2]?.value === 'Unlimited' ? -1 : dataSizeConversion(Number(product?.form[2]?.value || 0),'GB', 'B')?.value || 0,
                        unit: "GB"
                    });
                }
                if( product?.form[3]?.value && product?.form[4]?.value ) {
                    objMeta["instance"] = {
                        type: "lina",
                        status: 0,
                        connection: {
                            server_id: product?.form[3]?.value || "",
                            tenant_id: product?.form[4]?.value || "",
                        }
                    }
                }
            }

            if( product.name === 'Microsoft 365' && product.enable === true ) {
                if( product?.form[0]?.value ) {
                    objOrder.push({
                        type: 3,
                        qty: product?.form[0]?.value || 0,
                        qty_raw: product?.form[0]?.value || 0,
                        tag: "tina-endpoint",
                        unit: "users"
                    });
                }
                if( product?.form[2]?.value ) {
                    objOrder.push({
                        type: 4,
                        qty: product?.form[2]?.value === 'Unlimited' ? -1 : product?.form[2]?.value || 0,
                        tag: "tina-endpoint",
                        qty_raw: product?.form[2]?.value === 'Unlimited' ? -1 : dataSizeConversion(Number(product?.form[2]?.value || 0),'GB', 'B')?.value || 0,
                        unit: "GB"
                    });
                }
                if( product?.form[3]?.value && product?.form[4]?.value ) {
                    objMeta["tina_instance"] = {
                        type: "tina",
                        status: 0,
                        connection: {
                            catalog_id: product?.form[3]?.value || "",
                            azure_id: product?.form[4]?.value || "",
                        }
                    }
                }
            }
            
        }

        if( objOrder.length ) {
            setOrderObj([...objOrder])
        }

        if( Object.keys(objMeta) ) {
            setOrderMeta({...objMeta})
        }

    },[formMetaData])

    const createOrder = async (data: any) => {
        try {
            const response = await postAPICall(`
                ${process.env.REACT_APP_API_URL}/app/orders`,
                {...data}
            );
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const updateLicense = async (licenseId: String, data: any) => {
        try {
            const response = await putAPICall(`
                ${process.env.REACT_APP_API_URL}/app/licenses/${licenseId}`,
                {...data}
            );
            return response.data;
        } catch(error) {
            return error;
        }
    }

    const addItemsToOrder = () => {
        let licenseId = license?._id;

        /* license raw data */
        let licenseData={
            updates: {
                license_data: {
                    ...orderMeta,
                    product_access: {
                        endpoints: {
                            enabled: formMetaData[0]?.enable || false,
                            priority: formMetaData[0]?.priority || 0
                        },
                        ms365: {
                            enabled: formMetaData[1]?.enable || false,
                            priority: formMetaData[1]?.priority || 1
                        },
                        servers: {
                            enabled: false,
                            priority: 2
                        }
                    }
                }
            }
        }

        /* order raw data */
        let orderData: any = {
            insert: {
                hubspot_owner_company_id: license?.hubspot_owner_company_id,
                hubspot_issuer_company_id: license?.hubspot_issuer_company_id,
                // order_type: "BUY",
                order_data: [
                    ...orderObj
                ],
                order_value: 0,
            }
        }

        /* preparing order data */
        // console.log({company},{license})
        // console.log({licenseId},{licenseData},{orderData})

        updateLicense(licenseId, licenseData)
                .then((data)=>{
                    setLicense({
                        ...license,
                        license_data: licenseData.updates.license_data
                    })
                    toast.success('License updated successfully')
                    console.log('updateLicense',{data});
                })
                .catch((error)=>{ console.log(error) })
                .finally()
        
        createOrder(orderData)
                .then((data)=>{
                    setOrder({...data.order})
                    toast.success('Order created successfully')
                    console.log('createOrder',{data});
                })
                .catch((error)=>{ console.log(error) })
                .finally()
    }

    return(
        <div  className="gap-6 grow flex flex-col bg-white rounded-md shadow-md hover:shadow-lg min-h-[400px] pb-6" >
            <div className="flex w-full items-center p-6 border-b justify-between text-center text-gray-600 font-medium text-lg">
                <div className="text-gray-600 flex items-center">Please Select your products <div className="text-sm text-orange-500 ml-4">{order?._id || ""}</div></div>
                <div className="mr-10 text-gray-500 text-base">Selected products
                <span className="font-semibold text-left text-xl text-[#ff5e0e] ml-4">
                {formMetaData.filter(i=>i.enable===true).length}
                </span></div>
            </div>
            {
                formMetaData.map((section:any,sectionIndex:number)=>(
                    <>
                        <div key={sectionIndex} className={`bg-slate-100 bg-opacity-25 mx-6 border ${!section.available && "opacity-70 cursor-not-allowed"} rounded-md`}  >
                            <div className="w-full flex md:flex-row h-28 rounded-md items-center">
                                <div className="h-full p-4 sm:p-8 flex justify-center items-center relative w-10 sm:w-16 border-r">
                                    {!section.available && <div className="text-[9px] text-white bg-[#ff5e0e] w-full rounded-tl-md p-1 absolute top-0 left-0 whitespace-nowrap">Coming soon</div>}
                                    <input className="accent-[#ff5e0e]" checked={section.enable} type="checkbox" disabled={!section.available} onChange={()=>{
                                        let updateVal = !section.enable
                                        setFormMetaData((previousState: any[])=>{
                                            let newState = [...previousState];
                                            newState[sectionIndex].enable = updateVal
                                            return newState;
                                        });
                                    }}/>
                                </div>
                                <div className="hidden lg:block">
                                <i className={`${section.icon} flex md:w-32 text-4xl items-center justify-center text-[#ff5e0e]`}></i>
                                </div>
                                <div className="flex flex-col md:flex-row items-center justify-start align-middle w-full">                           
                                    <div className="flex flex-col grow items-start justify-center">
                                        <div className=" text-lg md:text-2xl ml-2 lg:ml-0 font-medium text-[#ff5e0e] mb-2">{section.name}</div>
                                        <div className="text-slate-500 text-sm ml-2 lg:ml-0">{section.description}</div>
                                    </div>
                                    <div className="flex flex-row items-center justify-start w-full md:w-auto md:mx-6 mt-2 lg:mt-0">
                                        <div className="text-sm text-slate-600 font-medium mr-2 w-full ml-2 whitespace-nowrap">Priority :</div>
                                        <input  type="number" min={0} disabled={!section.available} value={section?.priority ? section?.priority : 0} className="border rounded-md p-1 w-[70px] border-slate-500 mr-2"
                                            onChange={(e)=>{
                                                setFormMetaData((previousState: any[])=>{
                                                    let newState = [...previousState];
                                                    newState[sectionIndex].priority = Number(e.target.value)
                                                    return newState;
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`${section.enable===true?"h-[600px] ":"h-[0px]"} overflow-hidden transition-all duration-500 ease-in-out w-full shadow-md hover:shadow-lg border-t bg-white rounded-b-md`}>
                                <div className="text-base font-medium text-slate-700 bg-slate-100 w-full border-b p-4">Please Enter the Details</div>
                                <div className="flex flex-col items-start justify-start p-6 mt-6">
                                    {section.form.map((f:any,fIndex:number)=>(
                                        f.display &&
                                            <div key={fIndex} className="flex items-center w-full mb-6">
                                                {/* {JSON.stringify(f)} */}
                                                <div className="text-sm text-slate-600 font-medium mr-4 w-[140px]">{f.label}</div>
                                                {f.type === 'switch' && <Switch state={f.value} switchHandler={()=>{
                                                    let updateVal = !f.value;
                                                    setFormMetaData((previousState: any[])=>{
                                                        let newState = [...previousState];
                                                        newState[sectionIndex].form[fIndex].value = updateVal;
                                                        let storageIndex = newState[sectionIndex].form.find((f:any)=>{return f.label==='Storage'}).index;
                                                        if( updateVal === true ) {
                                                            newState[sectionIndex].form[storageIndex].value = 'Unlimited';
                                                            newState[sectionIndex].form[storageIndex].type = 'text';
                                                            newState[sectionIndex].form[storageIndex].readonly = true;
                                                        } else {
                                                            newState[sectionIndex].form[storageIndex].value = '0';
                                                            newState[sectionIndex].form[storageIndex].type = 'number';
                                                            newState[sectionIndex].form[storageIndex].readonly = false;
                                                        }
                                                        return newState;
                                                    });
                                                    
                                                }} colors={['gray-400','orange-500']} className="" />}

                                                {(f.type === "text" || f.type === "password") &&
                                                    <div className="flex flex-row w-full items-center justify-center text-center border rounded-md overflow-hidden">
                                                        <input  type={f.type} placeholder={f.placeholder} className="p-2 w-full outline-none" value={f.value} readOnly={f.readonly} onChange={(e)=>{
                                                            let updateVal = e.target.value
                                                            setFormMetaData((previousState: any[])=>{
                                                                let newState = [...previousState];
                                                                newState[sectionIndex].form[fIndex].value = updateVal;
                                                                return newState;
                                                            });
                                                        }}/>
                                                        {f.unit && <div className="bg-slate-100 p-2 border-l">{f.unit}</div>}
                                                    </div>}

                                                {f.type === "number" &&
                                                <div className="flex flex-row w-full items-center justify-center text-center border rounded-md overflow-hidden">
                                                    <input  type={f.type} min={0} placeholder={f.placeholder} className="p-2 w-full outline-none" value={f.value} readOnly={f.readonly} onChange={(e)=>{
                                                        let updateVal = e.target.value
                                                        setFormMetaData((previousState: any[])=>{
                                                            let newState = [...previousState];
                                                            newState[sectionIndex].form[fIndex].value = Number(updateVal);
                                                            return newState;
                                                        });
                                                    }}/>
                                                    {f.unit && <div className="bg-slate-100 p-2 border-l">{f.unit}</div>}
                                                </div>}
                                                
                                            </div>      
                                    ))}
                                </div>
                            </div>
                        </div>
                        
                    </>
                ))
            }
            <div className="flex w-full items-center p-6 justify-end text-gray-600 font-medium text-lg mt-6 gap-6">
                {!order && <Button
                    size="sm"
                    className=""
                    disabled={orderObj.length > 0 ? false : true}
                    onClick={()=>{
                        addItemsToOrder()
                    }}
                >Submit</Button>}
                {order && <div className="text-sm text-green-600">Order processed successfully</div>}
                {order &&   <Button size="sm" className="w-24 flex items-center justify-center whitespace-nowrap" onClick={()=>{setSelected(4)}}>
                                <div>Export</div>
                                <em className="fa-regular fa-angles-right ml-2"></em>
                            </Button>}
            </div>
        </div>
    )
}

interface IExport {
    company: any,
    order: any
    license: any
}

const Export:FC<IExport> = ({
    company,
    order,
    license
})=>{
    const [hint, setHint] = useState('test')
    return(
        <>
            <div className="gap-6 grow flex flex-col bg-white rounded-md shadow-md hover:shadow-lg min-h-[400px] pb-6" >
                <div className="flex w-full items-center p-6 border-b justify-between text-left text-gray-600 font-medium text-lg">
                    <div className="text-gray-600">Export <span className="text-[#ff5e0e] ml-4 text-sm"></span></div>
                    <div className="grow"></div>
                    <Dropdown options={["csv","Json"]} placeholder="Pleaes Select" className=" flex "/>
                    <Button size="sm" className="w-20 ml-6">Export</Button>
                </div>
                <div className="code relative flex flex-col grow gap-3 h-[456px]">
                    <pre className="p-2 h-full mx-2 border rounded-md bg-slate-600 text-white overflow-y-scroll customScroll">
                        {JSON.stringify({company: {...company}, license: {...license}, order: {...order}}, undefined, 4)}
                    </pre>
                    {/* <em 
                        className="flex absolute justify-center align-middle items-center fa-solid w-[35px] h-[35px] fa-copy rounded bg-white opacity-30 hover:opacity-100 px-2 py-1 cursor-pointer right-5 top-3"
                    ></em> */}
                </div>
            </div>
        </>
    )
}