/* eslint-disable react/jsx-pascal-case */
import React, { FC } from "react";
import Tile5x4_ChartText from "../../../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText";

interface IInactiveProtectionPolicyCard {
    rawPolicyStrategy: any;
};

const InactiveProtectionPolicyCard:FC<IInactiveProtectionPolicyCard> = ({rawPolicyStrategy}) => {
    console.log({rawPolicyStrategy})
    return (
        <>
            <Tile5x4_ChartText
                sideBar={false}
                clickNavigate={false}
                navigationPath='/'
                title={
                    < div className="flex items-center">
                        Inactive Protection Policies
                    </div>}
                description=""
                value={<i className="fa-thin fa-file-contract relative text-5xl text-[#ff5e0e]"><em className="fa-regular fa-circle-exclamation text-xl  bg-gray-300  rounded-full w-8 h-8 flex items-center justify-center  absolute left-5 -bottom-2"/></i>}
                textValue={<></>}
                textColor="text-green-600"
                designOption="chart"
                chartType="donut"
                chartLabels={[]}
                chartDataset={[
                    {
                        label: '# of Votes',
                        data: [],
                        backgroundColor: [
                        ],
                        borderWidth: 5,
                        cutout: 43,
                        borderRadius: 20
                    },

                ]}
                chartOptions={{
                    responsive: true,
                    plugins: {
                        legend: { display: false },
                        title: { display: false },
                    },
                    scales: {
                        x: { display: false },
                        y: { display: false },
                    },
                }}
                inChartMain='' inChartSub=""
                className='border relative'
                tileMode='default'
                alert={false}
                live={false}
            >
                <div className="text-7xl text-[#ff5e0e] w-28 h-28 flex items-center text-right justify-end rounded-md absolute right-4">
                {(rawPolicyStrategy?.config?.current_policies || []).filter((p:any)=>{ return p.ID > 100 && p.NbRefs < 1 }).length}
                </div>
            </Tile5x4_ChartText>
        </>
    )
}

export default InactiveProtectionPolicyCard;