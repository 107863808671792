/* eslint-disable react/jsx-pascal-case */
import React, { FC, useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
// import Tile58 from "../../components/applicationComponents/Tiles/Tile58/Tile58";
import { Accordion, AccordionItem, Tile } from "../../components/core";
import { getAPICall } from "../../services/APILayer/axiosMethodCalls";
import { deviceSymbol, getProtectedAgents, originalDisabledReasons } from "../../utils/agentUtils"
import { SideDrawerContext } from "../../contexts";
import { DonutChart } from "../../components/Charts/ApacheECharts";

type donutCard = {
    donutCutout: number;
}

// const arrBgColor = [
//     'rgba(255, 94, 14, 1)',
//     'rgba(255, 94, 14, 0.2)',
// ]

// const arrBorderColor = [
//     'rgba(255, 255, 255, 1)',
//     'rgba(255, 255, 255, 1)',
//     'rgba(255, 255, 255, 1)',
//     'rgba(255, 255, 255, 1)',
//     'rgba(255, 255, 255, 1)',
// ]

const EndpointDeviceStatusCard: FC<donutCard> = ({donutCutout}) => {

    const sideDrawerContext = useContext(SideDrawerContext)
    const [ endpointData, setEndpointData ] = useState<any>(null);

    const [ordersInfo, setOrdersInfo] = useState<any[]>([]);
    const [endpointQuota, setEndpointQuota] = useState<number>(0);

    const getOrderInfo = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/app/orders/me`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( Object.keys(ordersInfo || {}).length > 0 ) {
            return;
        }
        const fetchOrderInfo = () => {
            getOrderInfo()
                .then((data)=>{
                    if( data ) {
                        console.log({data})
                        setOrdersInfo(data?.orders || [])
                    }
                })
                .catch(err=>console.log(err));
        }
        setTimeout(() => {
            fetchOrderInfo()
        }, 500);
    },[ordersInfo])

    useEffect(()=>{
        if( Object.keys(ordersInfo).length > 0 ) {
            let allOrderEndpointTotal = (ordersInfo || []).map((orders:any)=>{ 
                let endpointOrders = (orders?.order_data || []).filter((o:any)=>{ return o.unit === 'endpoints' })
                let totalOrderEndpoints = endpointOrders.reduce((a:number,d:any)=>{ a = a + d.qty; return a; },0)
                return totalOrderEndpoints
            }).reduce((a:number,d:number)=>{ a = a + d; return a },0)
            setEndpointQuota(allOrderEndpointTotal)
            // console.log({allOrderEndpointTotal})
        }
    },[ordersInfo])


    const getEndpoints = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( endpointData ) {
            return;
        }

        const fetchEndpoints = async () => {
            await getEndpoints().then((data)=>{
                setEndpointData(data);
            }).catch((err)=>{
                console.log({err})
            });
        }

        fetchEndpoints();

    },[endpointData]);

    return (
        <>
            {
                endpointData ?
                // <Tile58
                //     title={`Endpoint Device Status`}

                //     tileAction={true}
                //     actionHandler={()=>{
                //         sideDrawerContext.setLevelOneDrawerVisibility(false) 
                //         sideDrawerContext.setLevelTwoDrawerVisibility(false) 
                //         sideDrawerContext.setFirstDrawerChild(<EndPointSideDrawerComponent endpointData={endpointData}/>)
                //         sideDrawerContext.setLevelOneDrawerVisibility(true) 
                //     }}
                    
                //     chartType='donut'
                //     chartLabels={[
                //         'Protected',
                //         'Unprotected',
                //     ]}
                //     chartDataset={[
                //         {
                //             label: '# of Votes',
                //             data: [
                                // protected = total endpoints - not installed - deleted - unprotected
                                // (endpointData?.installed || []).length - (endpointData?.unprotected || []).length,
                                // (endpointData?.unprotected || []).length,
                                // (endpointData?.deleted || []).length,
                                // (endpointData?.pending_installation || []).length,
                //             ],
                //             backgroundColor: arrBgColor,
                //             borderColor: arrBorderColor,
                //             borderWidth: 0,
                //             borderRadius: 0,
                //             cutout: donutCutout - 5,
                //         },
                //     ]}
                //     chartOptions={{
                //         responsive: true,
                //         maintainAspectRatio: false,
                //         plugins: {
                //             legend: {
                //                 display: false,
                //                 position: 'bottom' as const,
                //             },
                //             title: {
                //                 display: false,
                //                 text: 'Donut Chart',
                //             },
                //             datalabels: { display: false },
                //         },
                //         scales: {
                //             x: {
                //                 display: false,
                //             },
                //             y: {
                //                 display: false,
                //             },
                //         },
                //     }}
                //     inChartMain={`${(endpointData?.installed || []).length}/${endpointQuota}`}
                //     inChartSub="End-Points"
                // />
                <Tile 
                    rowSpan={13} 
                    colSpan={3}
                    onClick={()=>{
                        sideDrawerContext.setLevelOneDrawerVisibility(false) 
                        sideDrawerContext.setLevelTwoDrawerVisibility(false) 
                        sideDrawerContext.setFirstDrawerChild(<EndPointSideDrawerComponent endpointData={endpointData}/>)
                        sideDrawerContext.setLevelOneDrawerVisibility(true) 
                    }}
                    className="cursor-pointer"
                >
                    <div className="flex flex-col h-full mb-1 p-5 text-[#3A3541]">
                        <div className="w-full h-full">
                            <DonutChart
                                title="Endpoint Device Status"
                                data={[
                                    {
                                        value: (getProtectedAgents(endpointData) || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos === null }).length,
                                        name: "Protected",
                                        itemStyle: {
                                            opacity: 1
                                        }
                                    },
                                    {
                                        value: (getProtectedAgents(endpointData) || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos !== null && [4,6].includes(ep?.agent_profile?.BlockListInfos?.Reason) }).length,
                                        name: "Disabled",
                                        itemStyle: {
                                            opacity: 0.7
                                        }
                                    },
                                    {
                                        value: (endpointData?.unprotected || []).length,
                                        name: "Unprotected",
                                        itemStyle: {
                                            opacity: 0.5
                                        }
                                    },
                                    {
                                        value: (endpointData?.pending_installation || []).length,
                                        name: "Pending",
                                        itemStyle: {
                                            opacity: 0.2
                                        }
                                    },
                                    
                                ]}
                                centreValue={
                                    <>
                                    
                                        <div className="text-2xl font-semibold">{`${(endpointData?.pending_installation || []).length + (endpointData?.installed || []).length - (endpointData?.installed || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos !== null && [2,3,5].includes(ep?.agent_profile?.BlockListInfos?.Reason) }).length}/${endpointQuota}`}</div>
                                        {/* <div className="text-2xl font-semibold">{`${(endpointData?.installed || []).length}/${endpointQuota}`}</div> */}
                                        <div className="text-sm font-normal">End Points</div>
                                    </>
                                }
                            />
                        </div>
                    </div>
                </Tile>
                :
                <Tile
                    rowSpan={13} 
                    colSpan={3} 
                >
                    <div className="flex flex-col h-full mb-1 p-5 text-[#3A3541]">
                        <div className="flex flex-row justify-between w-full">
                            <div title='Endpoint Device Status' className={`text-base md:text-lg lg:text-xl leading-8 font-medium whitespace-nowrap truncate`}>
                                Endpoint Device Status
                            </div>
                        </div>
                        <div className="regular flex-col gap-4 grow my-4 mt-4 mb-2 max-h-80 md:max-h-80 lg:max-h-80  items-center justify-center flex z-10 relative">
                            <div className="relative flex h-full aspect-square items-center justify-center align-middle">
                                <div className="h-[90%] lg:h-[70%] xl:h-[90%] aspect-square rounded-full bg-slate-300 animate-pulse"></div>
                                <div className="absolute h-[50%] aspect-square rounded-full border bg-white"></div>
                            </div>
                            <div className="absolute flex flex-col top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                <span className="block bg-slate-300 animate-pulse h-[30px] w-[60px] xl:w-[60px] mb-2"></span>
                                <span className="block bg-slate-300 animate-pulse h-[10px] w-[60px] xl:w-[60px]"></span>
                            </div>
                            <div className="legends flex flex-row justify-center space-x-4 flex-wrap lg:-mt-4 2xl:mt-0">
                                <div className="flex flex-row items-center text-xs whitespace-nowrap pb-1">
                                    <i className="fa-solid fa-circle text-slate-300 text-center justify-center items-start flex text-xs"></i>
                                    <div className="ml-1">
                                        <span className="block bg-slate-300 animate-pulse h-[10px] w-[60px]"></span>
                                    </div>
                                </div>
                                <div className="flex flex-row items-center text-xs whitespace-nowrap pb-1">
                                    <i className="fa-solid fa-circle text-slate-300 text-center justify-center items-start flex text-xs"></i>
                                    <div className="ml-1">
                                        <span className="block bg-slate-300 animate-pulse h-[10px] w-[60px]"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </Tile>
            }
        </>
    )
}

type IEndPointSideDrawerComponent = {
    endpointData: any;
}

const EndPointSideDrawerComponent: FC<IEndPointSideDrawerComponent> = ({endpointData}) => {
    const sideDrawerContext = useContext(SideDrawerContext)
    return (
        <div className="h-full flex flex-col">
            <div className="title w-full flex gap-4 px-6 top-0 pt-10 border-b pb-10 bg-slate-100 items-center">
                {/* Icon */}
                <i className="fa-regular fa-tv  text-[#FF5E0E] p-2 text-2xl rounded-full w-8 h-8 items-center flex justify-center"></i>

                {/* title */}
                <div className="flex flex-col">
                    <div className="text-xl font-bold text-slate-700">Endpoint Status</div>
                    {/* Sub title */}
                    <div className="block text-slate-500 hover:text-[#FF5E0E] cursor-pointer">
                        <Link to="/endpoint/devices">
                            <span>View More</span>
                            <em className="fa-solid fa-link ml-2 text-sm"></em>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="data grow top-40 pl-4 pr-3 w-full overflow-y-scroll customScroll py-6">
                <Accordion className="rounded-lg overflow-hidden">
                    <AccordionItem className="text-sm" maxHeight='h-fit' title={<span><em className="fa-regular fa-tv mr-4 text-[#f97315]"></em> All ({(endpointData?.endpoints || []).length})</span>}>
                        {(endpointData?.endpoints || []).length > 0 && <div className={`table w-full`}>
                            <div className={`thead bg-slate-50 border-y w-full p-2 flex flex-row items-center`}>
                                <div className="row text-gray-800 text-sm flex">
                                    <div className="cell text-left w-16 pl-4">#</div>
                                    <div className="cell text-left w-16 -ml-2">OS</div>
                                    <div className="cell text-left grow">Device Name</div>
                                </div>
                            </div>
                            <div className={`tbody overflow-y-scroll customScroll max-h-48 bg-white w-full flex flex-col text-slate-600`}>
                                {(endpointData?.endpoints || []).map((endpoint:any, endpointIndex:number)=>(
                                    <Link 
                                        key={endpointIndex}
                                        to={`/endpoint/devices/profile/${endpoint?._id}`}
                                        onClick={()=>{
                                            sideDrawerContext.setLevelOneDrawerVisibility(false);
                                            sideDrawerContext.setLevelTwoDrawerVisibility(false);
                                        }}
                                    >
                                        <div className="row text-sm font-sm flex border-b w-full py-2 hover:bg-slate-100">
                                            <div className="cell w-16 pl-4 cursor-pointer text-orange-500">{endpoint?.agent_id || ''}</div>
                                            <div className="cell text-slate-500 w-16">{deviceSymbol(endpoint?.agent_profile?.AgentInfos?.System || '')}</div>
                                            <div className="cell grow">{endpoint?.agent_profile?.AdminInfos?.Name || ''}</div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>}
                    </AccordionItem>
                    <AccordionItem className="text-sm" maxHeight='h-fit' title={<span><em className="fa-light fa-hourglass-clock mr-4 text-[#f97315]"></em> Pending Installation <span className="text-sm">({(endpointData?.pending_installation || []).length})</span></span>}>
                        {(endpointData?.pending_installation || []).length > 0 && <div className={`table w-full`}>
                            <div className={`thead bg-slate-50 border-y w-full p-2 flex flex-row items-center`}>
                                <div className="row text-gray-800 text-sm flex">
                                    <div className="cell w-16 pl-4">#</div>
                                    <div className="cell w-16 -ml-2">OS</div>
                                    <div className="cell grow">Device Name</div>
                                </div>
                            </div>
                            <div className={`tbody overflow-y-scroll customScroll max-h-48 bg-white w-full flex flex-col text-slate-600`}>
                                {(endpointData?.pending_installation || []).map((endpoint:any, endpointIndex:number)=>(
                                    <Link 
                                        key={endpointIndex}
                                        to={`/endpoint/devices/profile/${endpoint?._id}`}
                                        onClick={()=>{
                                            sideDrawerContext.setLevelOneDrawerVisibility(false);
                                            sideDrawerContext.setLevelTwoDrawerVisibility(false);
                                        }}
                                    >
                                        <div key={endpointIndex} className="row text-sm font-sm flex border-b w-full py-2 hover:bg-slate-100">
                                            <div className="cell w-16 pl-4 cursor-pointer text-orange-500">{endpoint?.agent_id || ''}</div>
                                            <div className="cell text-slate-500 w-16">{deviceSymbol(endpoint?.agent_profile?.AgentInfos?.System || '')}</div>
                                            <div className="cell grow">{endpoint?.agent_profile?.AdminInfos?.Name || ''}</div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>}
                    </AccordionItem>
                    <AccordionItem className="text-sm" maxHeight='h-fit' title={<span><em className="fa-regular fa-box-circle-check mr-4 text-[#f97315]"></em> Installed <span className="text-sm">({(endpointData?.installed || []).length})</span></span>}>
                        {(endpointData?.installed || []).length > 0 && <div className={`table w-full`}>
                            <div className={`thead bg-slate-50 border-y w-full p-2 flex flex-row items-center`}>
                                <div className="row text-gray-800 text-sm flex">
                                    <div className="cell w-16 pl-4">#</div>
                                    <div className="cell w-16 -ml-2">OS</div>
                                    <div className="cell grow">Device Name</div>
                                </div>
                            </div>
                            <div className={`tbody overflow-y-scroll customScroll max-h-48 bg-white w-full flex flex-col text-slate-600`}>
                                {(endpointData?.installed || []).map((endpoint:any, endpointIndex:number)=>(
                                    <Link 
                                        key={endpointIndex}
                                        to={`/endpoint/devices/profile/${endpoint?._id}`}
                                        onClick={()=>{
                                            sideDrawerContext.setLevelOneDrawerVisibility(false);
                                            sideDrawerContext.setLevelTwoDrawerVisibility(false);
                                        }}
                                    >
                                        <div key={endpointIndex} className="row text-sm font-sm flex border-b w-full py-2 hover:bg-slate-100">
                                            <div className="cell w-16 pl-4 cursor-pointer text-orange-500">{endpoint?.agent_id || ''}</div>
                                            <div className="cell text-slate-500 w-16">{deviceSymbol(endpoint?.agent_profile?.AgentInfos?.System || '')}</div>
                                            <div className="cell grow">{endpoint?.agent_profile?.AdminInfos?.Name || ''}</div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>}
                    </AccordionItem>
                    <AccordionItem className="text-sm" maxHeight='h-fit' title={<span><em className="fa-regular fa-trash mr-4 text-[#f97315]"></em> Deleted <span className="text-sm">({(endpointData?.deleted || []).length})</span></span>}>
                        {(endpointData?.deleted || []).length > 0 && <div className={`table w-full`}>
                            <div className={`thead bg-slate-50 border-y w-full p-2 flex flex-row items-center`}>
                                <div className="row text-gray-800 text-sm flex">
                                    <div className="cell w-16 pl-4">#</div>
                                    <div className="cell w-16 -ml-2">OS</div>
                                    <div className="cell grow">Device Name</div>
                                </div>
                            </div>
                            <div className={`tbody overflow-y-scroll customScroll max-h-48 bg-white w-full flex flex-col text-slate-600`}>
                                {(endpointData?.deleted || []).map((endpoint:any, endpointIndex:number)=>(
                                    <Link 
                                        key={endpointIndex}
                                        to={`/endpoint/devices/profile/${endpoint?._id}`}
                                        onClick={()=>{
                                            sideDrawerContext.setLevelOneDrawerVisibility(false);
                                            sideDrawerContext.setLevelTwoDrawerVisibility(false);
                                        }}
                                    >
                                        <div key={endpointIndex} className="row text-sm font-sm flex border-b w-full py-2 hover:bg-slate-100">
                                            <div className="cell w-16 pl-4 cursor-pointer text-orange-500">{endpoint?.agent_id || ''}</div>
                                            <div className="cell text-slate-500 w-16">{deviceSymbol(endpoint?.agent_profile?.AgentInfos?.System || '')}</div>
                                            <div className="cell grow">{endpoint?.agent_profile?.AdminInfos?.Name || ''}</div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>}
                    </AccordionItem>
                    <AccordionItem className="text-sm" maxHeight='h-fit' title={<span><em className="fa-regular fa-shield-check mr-4 text-[#f97315]"></em> Protected <span className="text-sm">({(getProtectedAgents(endpointData) || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos === null }).length})</span></span>}>
                        {(endpointData?.endpoints || []).length > 0 && <div className={`table w-full`}>
                            <div className={`thead bg-slate-50 border-y w-full p-2 flex flex-row items-center`}>
                                <div className="row text-gray-800 text-sm flex">
                                    <div className="cell w-16 pl-4">#</div>
                                    <div className="cell w-16 -ml-2">OS</div>
                                    <div className="cell grow">Device Name</div>
                                </div>
                            </div>
                            <div className={`tbody overflow-y-scroll customScroll max-h-48 bg-white w-full flex flex-col text-slate-600`}>
                                {/* {JSON.stringify(getProtectedAgents(endpointData) || [])} */}
                                {(getProtectedAgents(endpointData) || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos === null }).map((endpoint:any, endpointIndex:number)=>(
                                    <Link 
                                        key={endpointIndex}
                                        to={`/endpoint/devices/profile/${endpoint?._id}`}
                                        onClick={()=>{
                                            sideDrawerContext.setLevelOneDrawerVisibility(false);
                                            sideDrawerContext.setLevelTwoDrawerVisibility(false);
                                        }}
                                    >
                                        <div key={endpointIndex} className="row text-sm font-sm flex border-b w-full py-2 hover:bg-slate-100">
                                            <div className="cell w-16 pl-4 cursor-pointer text-orange-500">{endpoint?.agent_id || ''}</div>
                                            <div className="cell text-slate-500 w-16">{deviceSymbol(endpoint?.agent_profile?.AgentInfos?.System || '')}</div>
                                            <div className="cell grow">{endpoint?.agent_profile?.AdminInfos?.Name || ''}</div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>}
                    </AccordionItem>
                    <AccordionItem className="text-sm" maxHeight='h-fit' title={<span><em className="fa-regular fa-ban mr-4 text-[#f97315]"></em> Disabled <span className="text-sm">({(getProtectedAgents(endpointData) || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos !== null }).length})</span></span>}>
                        {(endpointData?.endpoints || []).length > 0 && <div className={`table w-full`}>
                            <div className={`thead bg-slate-50 border-y w-full p-2 flex flex-row items-center`}>
                                <div className="row text-gray-800 text-sm flex">
                                    <div className="cell w-16 pl-4">#</div>
                                    <div className="cell w-16 -ml-2">OS</div>
                                    <div className="cell w-40">Device Name</div>
                                    <div className="cell w-44">Reason</div>
                                </div>
                            </div>
                            <div className={`tbody overflow-y-scroll customScroll max-h-48 bg-white w-full flex flex-col text-slate-600`}>
                                {/* {JSON.stringify(getProtectedAgents(endpointData) || [])} */}
                                {(getProtectedAgents(endpointData) || []).filter((ep:any)=>{ return ep.agent_profile.BlockListInfos !== null }).map((endpoint:any, endpointIndex:number)=>(
                                    <Link 
                                        key={endpointIndex}
                                        to={`/endpoint/devices/profile/${endpoint?._id}`}
                                        onClick={()=>{
                                            sideDrawerContext.setLevelOneDrawerVisibility(false);
                                            sideDrawerContext.setLevelTwoDrawerVisibility(false);
                                        }}
                                    >
                                        <div key={endpointIndex} className="row text-sm font-sm flex border-b w-full py-2 hover:bg-slate-100">
                                            <div className="cell w-16 pl-4 cursor-pointer text-orange-500">{endpoint?.agent_id || ''}</div>
                                            <div className="cell w-16 text-slate-500">{deviceSymbol(endpoint?.agent_profile?.AgentInfos?.System || '')}</div>
                                            <div className="cell w-36 whitespace-nowrap overflow-hidden mr-4">
                                                <div className="hover:animate-marquee">{endpoint?.agent_profile?.AdminInfos?.Name || ''}</div>
                                            </div>
                                            <div className="cell w-40 whitespace-nowrap overflow-hidden mr-4">
                                                <div className="hover:animate-marquee">{originalDisabledReasons[endpoint?.agent_profile?.BlockListInfos?.Reason] || ""} {[4,6].includes(endpoint?.agent_profile?.BlockListInfos?.Reason) ? <span className="text-orange-500">*</span> : <sup className="text-orange-500">#</sup>}</div>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>}
                    </AccordionItem>
                    <AccordionItem className="text-sm" maxHeight='h-fit' title={<span><em className="fa-regular fa-shield-xmark mr-4 text-[#f97315]"></em> Unprotected <span className="text-sm">({(endpointData?.unprotected || []).length})</span></span>}>
                        {(endpointData?.unprotected || []).length > 0 && <div className={`table w-full`}>
                            <div className={`thead bg-slate-50 border-y w-full p-2 flex flex-row items-center`}>
                                <div className="row text-gray-800 text-sm flex">
                                    <div className="cell w-16 pl-4">#</div>
                                    <div className="cell w-16 -ml-2">OS</div>
                                    <div className="cell grow">Device Name</div>
                                </div>
                            </div>
                            <div className={`tbody overflow-y-scroll customScroll max-h-48 bg-white w-full flex flex-col text-slate-600`}>
                                {(endpointData?.unprotected || []).map((endpoint:any, endpointIndex:number)=>(
                                    <Link 
                                        key={endpointIndex}
                                        to={`/endpoint/devices/profile/${endpoint?._id}`}
                                        onClick={()=>{
                                            sideDrawerContext.setLevelOneDrawerVisibility(false);
                                            sideDrawerContext.setLevelTwoDrawerVisibility(false);
                                        }}
                                    >
                                        <div key={endpointIndex} className="row text-sm font-sm flex border-b w-full py-2 hover:bg-slate-100">
                                            <div className="cell w-16 pl-4 cursor-pointer text-orange-500">{endpoint?.agent_id || ''}</div>
                                            <div className="cell text-slate-500 w-16">{deviceSymbol(endpoint?.agent_profile?.AgentInfos?.System || '')}</div>
                                            <div className="cell grow">{endpoint?.agent_profile?.AdminInfos?.Name || ''}</div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>}
                    </AccordionItem>
                </Accordion>
                <div><span className="text-orange-500 mr-2">*</span><span className="text-gray-500 text-sm">For disabled devices license countable for this reason</span></div>
                <div><span className="text-orange-500 mr-2">#</span><span className="text-gray-500 text-sm">For disabled devices license is not countable for this reason</span></div>
            </div>
        </div>
    )
}



export default EndpointDeviceStatusCard;