/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, FC } from "react"
import { Table } from "../components/core/Table";
import { ITableColumnItem, ITableControllerProps } from "../components/core/Table/Table";

const TableExample: FC = () => {

    const navRaf = useRef<HTMLDivElement>(null)

    const [tableController, setTableController] = useState<ITableControllerProps>({
        data: [],
        loader: false,
        currentPage: 1,
        offset: 0,
        limit: 5,
        totalRecords: 10,
        maxVisiblePaginationButtons: 5,
        searchTerm: '',
        filter: {},
        sorting: {
            column: '',
            direction: null
        }
    })

    const [tableColumns, setTableColumns] = useState<ITableColumnItem[]>([
        {
            id: 1,
            name: 'User',
            column: '',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true
        },
        {
            id: 3,
            name: 'Role',
            column: 'role',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true
        },
        {
            id: 2,
            name: 'Email',
            column: 'email',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true
        },
        {
            id: 4,
            name: 'Plan',
            column: 'plan',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true
        },
        {
            id: 5,
            name: 'Status',
            column: 'status',
            textAlign: 'text-left',
            textColor: 'text-gray-500',
            visibility: true
        }
    ]);

    const actionButtonGenerator = (row: object) => {
        return <>
            <button aria-label='edit'
                onClick={()=>{

                }}
            ><em className='fa-regular fa-pen text-[#3A3541] opacity-80 mr-2'></em></button>
            <button aria-label='view'
                onClick={()=>{
                    
                }}
            ><em className='fa-regular fa-eye text-[#3A3541] opacity-80'></em></button>
        </>
    }

    const tableDataFormatter = (rowObj: any, format?: string | undefined) => {
        let returningVal = null;
        switch (format) {
            case 'User':
                returningVal = (<>
                    <div className="flex flex-row space-x-4 ">
                        <img alt="no" className="" src={`../../../../assets/img/${rowObj.image}`}/>
                        <div className="flex flex-col">
                            <span className="text-sm font-medium text-[#3A3541] opacity-80">{rowObj.name}</span>
                            <span className="text-xs font-light text-[#3A3541] opacity-60">{rowObj.username}</span>
                        </div>
                    </div>
                </>);
                break;

            case 'Email':
                returningVal=(
                    <div className="text-sm font-regular text-[#3A3541] opacity-70">{rowObj}</div>
                );
                break;
            
            case 'Role':
                returningVal=(
                    <div className="flex flex-row ">
                        {rowObj === 'Admin' && <i className="text-sm fa-regular fa-lock mr-2 text-red-600" />}
                        {rowObj === 'Company' && <i className="text-sm fa-regular fa-building mr-2 text-green-500" />}
                        {rowObj === 'Branch Admin' && <i className="text-sm fa-regular fa-farm mr-2 text-orange-300" />}
                        {rowObj === 'Subscriber' && <i className="text-sm fa-regular fa-fire mr-2 text-yellow-500" />}
                        <div className="text-[#3A3541] opacity-70 text-base font-regular">{rowObj}</div>
                    </div>
                );
                break;

            case 'Plan':
                returningVal=(
                    <div className="text-base font-regular text-[#3A3541] opacity-70">{rowObj}</div>
                );
                break;

            case 'Status':
                returningVal=(
                    <>
                        {rowObj === 'Active' && <div className="text-xs px-3 py-1 text-green-600 font-light bg-opacity-10 bg-green-600 max-w-min rounded-full">{rowObj}</div>}
                        {rowObj === 'Inactive' && <div className="text-xs px-3 py-1 text-gray-500 font-light bg-slate-100 max-w-min rounded-full">{rowObj}</div>}
                        {rowObj === 'Pending' && <div className="text-xs px-3 py-1 text-orange-500 font-light bg-orange-100 max-w-min rounded-full">{rowObj}</div>}
                    </>
                );
                break;
        
            default:
                returningVal = rowObj;
                break;
        }
        return returningVal;
    }

    const handleSwitch = () => {}

    useEffect(()=>{
        if( tableController.data.length > 0 ) {
            return;
        }

        setTableController({
            ...tableController,
            data: [{
                id: 1,
                name: "Jordan Stevenson",
                username:"@amiccoo",
                email: "estelle.Bailey10@gmail.com",
                image: "Avatar.svg",
                role: "Admin",
                plan: "Enterprises",
                status: "Pending",
            },{
                id: 2,
                name: "Benedetto Rossiter",
                username:"@brosebothamz",
                email: "roma.Dickens79@hotmail.com",
                image: "Avatar1.svg",
                role: "Company",
                plan: "Team",
                status: "Active"
            },{
                id: 3,
                name: "Jordan Stevenson",
                username:"@amiccoo",
                email: "estelle.Bailey10@gmail.com",
                image: "Avatar.svg",
                role: "Admin",
                plan: "Enterprises",
                status: "Pending"
            },{
                id: 4,
                name: "Stevenson",
                username:"@amiccoo",
                email: "susanna.Lind57@gmail.com",
                image: "Avatar1.svg",
                role: "Branch Admin",
                plan: "Team",
                status: "Inactive"
            },{
                id: 5,
                name: "Jordan Stevenson",
                username:"@amiccoo",
                email: "estelle.Bailey10@gmail.com",
                image: "Avatar.svg",
                role: "Subscriber",
                plan: "Basic",
                status: "Active"
            }]
        })

    },[tableController.data]);


    return (
        <> 
            <Table
                navRef={navRaf}
                actionButtonGenerator={actionButtonGenerator}
                columns={tableColumns}
                setColumns={setTableColumns}
                formatData={tableDataFormatter}
                showActionCell={false}
                showActionHeaderCell={false}
                showColumnManagementMenu={false}
                switchHandler={handleSwitch}
                tableController={tableController}
                tableId="ExampleTable"
            />
        </>
    )
}

export default TableExample;