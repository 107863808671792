import React, { FC, ReactNode } from "react"

interface ITableHeadProp {
    children: ReactNode | ReactNode[];
    className:string
}

const TableHead:FC<ITableHeadProp> = ({children,className}) => {
    return (
        <div className={`thead table w-full overflow-hidden ${className}`}>
            {children}
        </div>
    )
}

export default TableHead;