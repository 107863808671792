/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useRef, useContext, ReactNode } from "react"
import { ThemeContext } from "../../contexts";
import Tile5x4_ChartText from "../../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText";
import { Breadcrumb, Button, Tile } from "../../components/core";
import { Table } from "../../components/core/Table";
import { ITableColumnItem, ITableControllerProps } from "../../components/core/Table/Table";
import { MasterChart } from "../../components/Charts/ChartJS";
import { Link } from "react-router-dom";
import { getAPICall } from "../../services/APILayer/axiosMethodCalls";
import {
    lastConnectionFormatter,
    lastBackupFormatter,
    protectedDataFormatter,
    deviceSymbol
} from "../../utils/agentUtils";
import Tooltip from "../../components/core/Tooltip/Tooltip";
import ButtonGroup from "../../components/core/ButtonGroup/ButtonGroup";

const EndpointReport = ( ) =>{

    const scrollerRef = useRef<null | HTMLDivElement>(null)

    const theme = useContext(ThemeContext) || {themeColor: 'primary', themeContrast: 'light'};

    const [ backupTrend, setBackupTrend ] = useState(false);

    const navRaf = useRef<HTMLDivElement>(null);

    const [ endpointData, setEndpointData ] = useState<any>(null)

    const [selectedData, setSelectedData] = useState<string>('')
    const [tableController, setTableController] = useState<ITableControllerProps>({
        data: [],
        loader: false,
        currentPage: 1,
        offset: 0,
        limit: 5,
        totalRecords: 10,
        maxVisiblePaginationButtons: 5,
        searchTerm: '',
        filter: {},
        sorting: {
            column: '',
            direction: null
        }
    });
    const [tableColumns, setTableColumns] = useState<ITableColumnItem[]>([
        {
            id: 1,
            name: 'Device Id',
            column: '',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            visibility: true
        },
        {
            id: 2,
            name: 'Hostname',
            column: '',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            visibility: true
        },
        {
            id: 3,
            name: 'OS',
            column: 'agent_profile',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            visibility: true
        },
        {
            id: 4,
            name: 'Device Status',
            column: '',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            visibility: true
        },
        {
            id: 5,
            name: 'Last Connection',
            column: 'agent_profile',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            visibility: true
        },
        {
            id: 6,
            name: 'Last Backup',
            column: 'agent_profile',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            visibility: true
        },
        {
            id: 7,
            name: 'Protected Data',
            column: 'agent_profile',
            textAlign: 'text-left',
            textColor: 'text-[#3A3541]',
            visibility: true
        }
    ]);

    const actionButtonGenerator = (row: object) => {
        return <>
            <button aria-label='edit'
                onClick={()=>{

                }}
            ><em className='fa-regular fa-pen text-[#3A3541] opacity-80 mr-2'></em></button>
            <button aria-label='view'
                onClick={()=>{
                    
                }}
            ><em className='fa-regular fa-eye text-[#3A3541] opacity-80'></em></button>
        </>
    }

    const tableDataFormatter = (rowObj: any, format?: string | undefined) => {
        let returningVal = null;
        switch (format) {
            case 'Device Id':
                returningVal = (
                    <Link to={`/endpoint/devices/profile/${rowObj?._id}`} >
                        <span className="text-sm hover:text-gray-900 font-medium text-[#FF5e0e] opacity-80 cursor-pointer">{rowObj?.agent_profile?.AdminInfos?.ID}</span>
                    </Link>
                );
                break;

            case 'Hostname':
                returningVal = (
                    <span className="relative group">
                        <span className="text-sm hover:text-gray-900 font-medium opacity-80">{rowObj?.agent_profile?.AdminInfos?.Name || ''}</span>
                        <Tooltip value={`${rowObj?.agent_profile?.AdminInfos?.Name || ''} / ${rowObj?.agent_profile?.AgentInfos?.ComputerName || ''}`} />
                    </span>
                );
                break;

            case 'OS':
                returningVal=(
                    <>
                        <span className="relative group">
                            <span className="text-sm font-medium opacity-80">{deviceSymbol(rowObj?.AgentInfos?.System)}</span>
                            <Tooltip value={rowObj?.AgentInfos?.System} />
                        </span>
                    </>
                    );
                break;
            
            case 'Device Status':

                returningVal = <div className="text-xs px-3 py-1 text-black-600 font-light bg-opacity-10 bg-black-600 max-w-min rounded-full">Unknown</div>

                let deviceStatus: ReactNode | null = null

                // installed | pending
                // 

                // pending endpoint
                deviceStatus = endpointData.pending_installation.find((ed : any) => ed._id === rowObj._id) ? <div className="text-xs px-3 py-1 text-orange-500 font-light bg-orange-100 max-w-min rounded-full">Pending</div> : null
                if( deviceStatus ) {
                    returningVal = deviceStatus;
                    deviceStatus = null;
                }

                // protected endpoints
                deviceStatus = endpointData?.endpoints.filter((ep :any) => {
                    // all deleted and unprotected
                    let arrTemp: any[] = [];
                    // if not deleted
                    let deletedIds: any = endpointData?.deleted.map((uep:any)=>{ return uep._id });
                    arrTemp.concat(deletedIds);
                    // if not unprotected
                    let unprotectedIds: any = endpointData?.unprotected.map((uep:any)=>{ return uep._id });
                    arrTemp.concat(unprotectedIds);

                    return arrTemp.includes(ep._id) ? false : true
                }).find((ed : any) => ed._id === rowObj._id) ? <div className="text-xs px-3 py-1 text-green-500 font-light bg-green-100 max-w-min rounded-full">Protected</div> : null
                if( deviceStatus ) {
                    returningVal = deviceStatus;
                    deviceStatus = null;
                }
                
                // unprotected endpoints
                deviceStatus = endpointData.unprotected.find((ed : any) => ed._id === rowObj._id) ? <div className="text-xs px-3 py-1 text-red-500 font-light bg-red-100 max-w-min rounded-full">Unprotected</div> : null
                if( deviceStatus ) {
                    returningVal = deviceStatus;
                    deviceStatus = null;
                }
                
                // deleted endpoints
                deviceStatus = endpointData.deleted.find((ed : any) => ed._id === rowObj._id) ? <div className="text-xs px-3 py-1 text-red-500 font-light bg-red-100 max-w-min rounded-full">Deleted</div> : null
                if( deviceStatus ) {
                    returningVal = deviceStatus;
                    deviceStatus = null;
                }
                break;

            case 'Last Connection':
                returningVal=(
                    <div className="text-sm font-medium opacity-70">{lastConnectionFormatter(rowObj?.NetworkInfos?.LastConnectionTime || '')}</div>
                );
                break;

            case 'Last Backup':
                returningVal=(
                    <div className="text-sm font-medium opacity-70">{lastBackupFormatter(rowObj?.NetworkInfos?.LastCompletedSession || '')}</div>
                );
                break;

            case 'Protected Data':
                returningVal=(
                    <>
                        <div className="text-sm font-medium opacity-70">{protectedDataFormatter(rowObj?.ServerInfos?.Backup?.ProtSize || 0)}</div>
                    </>
                );
                break;
        
            default:
                returningVal = rowObj;
                break;
        }
        return returningVal;
    }

    const handleSwitch = () => {}

    const getEndpoints = async () => {
        try {
            const response = await getAPICall(`${process.env.REACT_APP_API_URL}/products/endpoints`);
            return response.data;
        } catch(error) {
            return error;
        }
    }

    useEffect(()=>{
        if( (tableController.data || []).length > 0 || selectedData !== '' ) {
            return;
        }

        const fetchEndpoints = async () => {
            await getEndpoints().then((data)=>{
                if(data) {
                    setEndpointData(data);
                    console.log({data})
                    setTableController((prevState) => {
                        let newState = {...prevState};
                        newState.data = data?.endpoints || [];
                        return newState;
                    });
                }
            }).catch((err)=>{
                console.log({err})
            });
        }

        fetchEndpoints();

    },[tableController.data]);

    useEffect(()=>{
        if(tableController.searchTerm.length < 1) {
            if(endpointData) {
                setTableController((prevState)=>{
                    let newState = {...prevState};
                    newState.data = endpointData?.endpoints || [];
                    return newState;
                })
                setSelectedData('All');
            }
            return;
        }

        let searchedNodes = (endpointData?.endpoints || []).filter((ep:any) => {
            if(
                ( ep?.agent_id || '' ).toLowerCase().includes(tableController.searchTerm) ||
                ( ep?.agent_profile?.AdminInfos?.Name || '' ).toLowerCase().includes(tableController.searchTerm) ||
                ( ep?.agent_profile?.AgentInfos?.ComputerName || '' ).toLowerCase().includes(tableController.searchTerm) ||
                ( ep?.agent_profile?.AgentInfos?.Hostname || '' ).toLowerCase().includes(tableController.searchTerm) ||
                ( ep?.agent_profile?.AgentInfos?.System || '' ).toLowerCase().includes(tableController.searchTerm)
            ) {
                return ep;
            }
        }) || [];

        setTableController((prevState)=>{
            let newState = {...prevState};
            setSelectedData('Search');
            newState.data = searchedNodes || [];
            return newState;
        })

    },[tableController.searchTerm]);

    const ShowBackupHistory = () => {
        setBackupTrend(false)
    }

    const ShowBackupTrend=()=>{
        setBackupTrend(true)
    }

    const scrollToBottom = () => {
        scrollerRef.current?.scrollIntoView({ behavior: "smooth" })
    }
    
    return(
        <>
            <div className={`p-6 grow flex flex-col`}>
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Endpoint Devices</h1>
                <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-bell-on', link: '', name: 'Home' },
                    { icon: '', link: '', name: 'Managed Services' },
                    { icon: '', link: '', name: 'Endpoints' },
                    { icon: '', link: '', name: 'Endpoint Devices' }
                ]} />
                <div className={`grow flex flex-col pb-6`}>
                    <div className={`page grid grid-cols-12 gap-6 w-full min-h-max text-${theme.setThemeColor}-${theme.themeContrast}-component-text`}>
                        <Tile5x4_ChartText 
                            sideBar={false}
                            clickNavigate={false}
                            navigationPath='/'
                            title={
                                < div className="flex items-center">
                                    All Devices
                                    {/* <small className="text-green-600 p-1 px-2 border-green-200 text-xs "><span className=" text-green-600"></span><i className="fa-solid fa-check bg-green-100 text-green-600 border border-green-300 rounded-full w-4 h-4 ml-1 text-xs "/></small> */}
                                </div>}
                            description={<></>}
                            value={<em className="fa-duotone fa-desktop text-5xl text-[#ff5e0e]"></em>}
                            // textBackground="bg-green-100"
                            textValue={<></>}
                            textColor="text-green-600"
                            // textArea=""
                            // inChartMain='20' inChartSub="License"
                            // alert={false}
                            // live={false}
                            designOption="chart"
                            chartType="donut" 
                            chartLabels={[]} 
                            chartDataset={[
                                {
                                    label: '# of Votes',
                                    data: [],
                                    backgroundColor: [
                                    ],
                                    borderWidth: 5,
                                    cutout: 43,
                                    borderRadius: 20
                                },

                            ]}
                            chartOptions={{
                                responsive: true,
                                plugins: {
                                    legend: { display: false },
                                    title: { display: false },
                                },
                                scales: {
                                    x: { display: false },
                                    y: { display: false },
                                },
                            }}
                            inChartMain='' inChartSub=""
                            className='border'
                            tileMode='default'
                            alert={false}
                            live={false}
                            tileAction={true}
                            actionHandler={()=>{
                                setSelectedData('All')
                                setTableController((prevState)=>{
                                    let newState = {...prevState};
                                    newState.data = endpointData?.endpoints || [];
                                    return newState;
                                })
                                scrollToBottom()
                            }}
                        >
                            <div className="text-7xl text-[#ff5e0e] w-28 h-28 flex items-center text-center justify-center rounded-md">
                                {(endpointData?.endpoints || []).length}
                            </div>
                        </Tile5x4_ChartText>
                        
                        <Tile5x4_ChartText 
                            sideBar={false}
                            clickNavigate={false}
                            navigationPath='/'
                            title={
                                < div className="flex items-center">
                                    Protected Devices
                                    {/* <small className="text-green-600 p-1 px-2 border-green-200 text-xs "><span className=" text-green-600"></span><i className="fa-solid fa-check bg-green-100 text-green-600 border border-green-300 rounded-full w-4 h-4 ml-1 text-xs "/></small> */}
                                </div>}
                            description={<></>}
                            value={<em className="fa-duotone fa-shield-check text-5xl text-[#ff5e0e]"></em>}
                            // textBackground="bg-green-100"
                            textValue={<></>}
                            textColor="text-green-600"
                            // textArea=""
                            // inChartMain='20' inChartSub="License"
                            // alert={false}
                            // live={false}
                            designOption="chart"
                            chartType="donut" 
                            chartLabels={[]} 
                            chartDataset={[
                                {
                                    label: '# of Votes',
                                    data: [],
                                    backgroundColor: [
                                    ],
                                    borderWidth: 5,
                                    cutout: 43,
                                    borderRadius: 20
                                },

                            ]}
                            chartOptions={{
                                responsive: true,
                                plugins: {
                                    legend: { display: false },
                                    title: { display: false },
                                },
                                scales: {
                                    x: { display: false },
                                    y: { display: false },
                                },
                            }}
                            inChartMain='' inChartSub=""
                            className='border'
                            tileMode='default'
                            alert={false}
                            live={false}
                            tileAction={true}
                            actionHandler={()=>{
                                setSelectedData('Protected')
                                setTableController((prevState)=>{
                                    let newState = {...prevState};
                                    newState.data = (endpointData?.endpoints || []).filter((ep :any) => {
                                        return (endpointData?.unprotected || []).map((uep:any)=>{
                                            return uep?._id
                                        }).includes(ep?._id) ? false : true
                                    });
                                    return newState;
                                })
                                scrollToBottom()
                            }}
                        >
                            <div className="text-7xl text-[#ff5e0e] w-28 h-28 flex items-center text-center justify-center rounded-md">
                                {(endpointData?.endpoints || []).length - (endpointData?.pending_installation || []).length - (endpointData?.deleted || []).length - (endpointData?.unprotected || []).length}
                            </div>
                        </Tile5x4_ChartText>
                        
                        <Tile5x4_ChartText
                            sideBar={false}
                            clickNavigate={false}
                            navigationPath='/'
                            title={
                                < div className="flex items-center">
                                    Unprotected Devices
                                    {/* <small className="text-green-600 p-1 px-2 border-green-200 text-xs "><span className=" text-green-600"></span><i className="fa-solid fa-check bg-green-100 text-green-600 border border-green-300 rounded-full w-4 h-4 ml-1 text-xs "/></small> */}
                                </div>}
                            description=""
                            value={<em className="fa-duotone fa-shield-xmark text-5xl text-[#ff5e0e]"></em>}
                            // textBackground="bg-green-100"
                            textValue={<></>}
                            textColor="text-green-600"
                            // textArea=""
                            // inChartMain='20' inChartSub="License"
                            // alert={false}
                            // live={false}
                            designOption="chart"
                            chartType="donut"
                            chartLabels={[]}
                            chartDataset={[
                                {
                                    label: '# of Votes',
                                    data: [],
                                    backgroundColor: [
                                    ],
                                    borderWidth: 5,
                                    cutout: 43,
                                    borderRadius: 20
                                },

                            ]}
                            chartOptions={{
                                responsive: true,
                                plugins: {
                                    legend: { display: false },
                                    title: { display: false },
                                },
                                scales: {
                                    x: { display: false },
                                    y: { display: false },
                                },
                            }}
                            inChartMain='' inChartSub=""
                            className='border'
                            tileMode='default'
                            alert={false}
                            live={false}
                            tileAction={true}
                            actionHandler={()=>{
                                setSelectedData('Unprotected')
                                setTableController((prevState)=>{
                                    let newState = {...prevState};
                                    newState.data = endpointData?.unprotected || [];
                                    return newState;
                                })
                                scrollToBottom()
                            }}
                        >
                            <div className="text-7xl text-[#ff5e0e] w-28 h-28 flex items-center text-center justify-center rounded-md">
                                {(endpointData?.unprotected || []).length}
                            </div>
                        </Tile5x4_ChartText>

                        <Tile5x4_ChartText
                            sideBar={false}
                            clickNavigate={false}
                            navigationPath='/'
                            title={
                                < div className="flex items-center">
                                    Installed Devices
                                    {/* <small className="text-green-600 p-1 px-2 border-green-200 text-xs "><span className=" text-green-600"></span><i className="fa-solid fa-check bg-green-100 text-green-600 border border-green-300 rounded-full w-4 h-4 ml-1 text-xs "/></small> */}
                                </div>}
                            description=""
                            value={<em className="fa-duotone fa-bars-progress text-5xl text-[#ff5e0e]"></em>}
                            // textBackground="bg-green-100"
                            textValue={<></>}
                            textColor="text-green-600"
                            // textArea=""
                            // inChartMain='20' inChartSub="License"
                            // alert={false}
                            // live={false}
                            designOption="chart"
                            chartType="donut"
                            chartLabels={[]}
                            chartDataset={[
                                {
                                    label: '# of Votes',
                                    data: [],
                                    backgroundColor: [
                                    ],
                                    borderWidth: 5,
                                    cutout: 43,
                                    borderRadius: 20
                                },

                            ]}
                            chartOptions={{
                                responsive: true,
                                plugins: {
                                    legend: { display: false },
                                    title: { display: false },
                                },
                                scales: {
                                    x: { display: false },
                                    y: { display: false },
                                },
                            }}
                            inChartMain='' inChartSub=""
                            className='border '
                            tileMode='default'
                            alert={false}
                            live={false}
                            tileAction={true}
                            actionHandler={()=>{
                                setSelectedData('Installed')
                                setTableController((prevState)=>{
                                    let newState = {...prevState};
                                    newState.data = endpointData?.installed || [];
                                    return newState;
                                })
                                scrollToBottom()
                            }}
                        >
                            <div className="text-7xl text-[#ff5e0e] w-28 h-28 flex items-center text-center justify-center rounded-md">
                                {(endpointData?.installed || []).length}
                            </div>
                        </Tile5x4_ChartText>
                        
                        <Tile5x4_ChartText
                            sideBar={false}
                            clickNavigate={false}
                            navigationPath='/'
                            title={
                                < div className="flex items-center">
                                    Installation Pending
                                    {/* <small className="text-green-600 p-1 px-2 border-green-200 text-xs "><span className=" text-green-600"></span><i className="fa-solid fa-check bg-green-100 text-green-600 border border-green-300 rounded-full w-4 h-4 ml-1 text-xs "/></small> */}
                                </div>}
                            description=""
                            value={<em className="fa-duotone fa-bars-progress text-5xl text-[#ff5e0e]"></em>}
                            // textBackground="bg-green-100"
                            textValue={<></>}
                            textColor="text-green-600"
                            // textArea=""
                            // inChartMain='20' inChartSub="License"
                            // alert={false}
                            // live={false}
                            designOption="chart"
                            chartType="donut"
                            chartLabels={[]}
                            chartDataset={[
                                {
                                    label: '# of Votes',
                                    data: [],
                                    backgroundColor: [
                                    ],
                                    borderWidth: 5,
                                    cutout: 43,
                                    borderRadius: 20
                                },

                            ]}
                            chartOptions={{
                                responsive: true,
                                plugins: {
                                    legend: { display: false },
                                    title: { display: false },
                                },
                                scales: {
                                    x: { display: false },
                                    y: { display: false },
                                },
                            }}
                            inChartMain='' inChartSub=""
                            className='border '
                            tileMode='default'
                            alert={false}
                            live={false}
                            tileAction={true}
                            actionHandler={()=>{
                                setSelectedData('Pending Installation')
                                setTableController((prevState)=>{
                                    let newState = {...prevState};
                                    newState.data = endpointData?.pending_installation || [];
                                    return newState;
                                })
                                scrollToBottom()
                            }}
                        >
                            <div className="text-7xl text-[#ff5e0e] w-28 h-28 flex items-center text-center justify-center rounded-md">
                                {(endpointData?.pending_installation || []).length}
                            </div>
                        </Tile5x4_ChartText>
                        
                        <Tile5x4_ChartText
                            sideBar={false}
                            clickNavigate={false}
                            navigationPath='/'
                            title={
                                < div className="flex items-center">
                                    Deleted
                                    {/* <small className="text-green-600 p-1 px-2 border-green-200 text-xs "><span className=" text-green-600"></span><i className="fa-solid fa-check bg-green-100 text-green-600 border border-green-300 rounded-full w-4 h-4 ml-1 text-xs "/></small> */}
                                </div>}
                            description=""
                            value={<em className="fa-duotone fa-trash text-5xl text-[#ff5e0e]"></em>}
                            // textBackground="bg-green-100"
                            textValue={<></>}
                            textColor="text-green-600"
                            // textArea=""
                            // inChartMain='20' inChartSub="License"
                            // alert={false}
                            // live={false}
                            designOption="chart"
                            chartType="donut"
                            chartLabels={[]}
                            chartDataset={[
                                {
                                    label: '# of Votes',
                                    data: [],
                                    backgroundColor: [
                                    ],
                                    borderWidth: 5,
                                    cutout: 43,
                                    borderRadius: 20
                                },

                            ]}
                            chartOptions={{
                                responsive: true,
                                plugins: {
                                    legend: { display: false },
                                    title: { display: false },
                                },
                                scales: {
                                    x: { display: false },
                                    y: { display: false },
                                },
                            }}
                            inChartMain='' inChartSub=""
                            className='border '
                            tileMode='default'
                            alert={false}
                            live={false}
                            tileAction={true}
                            actionHandler={()=>{
                                setSelectedData('Deleted')
                                setTableController((prevState)=>{
                                    let newState = {...prevState};
                                    newState.data = endpointData?.deleted || [];
                                    return newState;
                                })
                                scrollToBottom()
                            }}
                        >
                            <div className="text-7xl text-[#ff5e0e] w-28 h-28 flex items-center text-center justify-center rounded-md">
                                {(endpointData?.deleted || []).length}
                            </div>
                        </Tile5x4_ChartText>
                    </div> 
                </div> 
                <div className={`grow flex flex-col gap-6`}>
                    <Tile colSpan={12} rowSpan={13} className="">
                    
                        <div className="flex flex-row items-center justify-between mx-2 py-4 border-b border-[#ff5e0e] mb-2">
                            {
                                backupTrend===true ? 
                                <div className="px-4 text-gray-600 font-semibold flex flex-col ">Backup history
                                    <small className="text-gray-400 font-normal">Showing the backup history per day
                                    </small>
                                </div> : 
                                <div className="px-4 text-gray-600 font-semibold flex flex-col ">Backup and Restore Trends
                                    <small className="text-gray-400 font-normal">Showing the trends backup and restore per day
                                    </small>
                                </div>
                            }
                            <div className="flex gap-2">
                            <div className={`${backupTrend===false?"text-[#ff5e0e] border-b border-[#ff5e0e]":"text-gray-500"} font-normal cursor-pointer py-1 mr-4`} onClick={ShowBackupHistory}>Backup and Restore Trends</div>
                            <div className={`${backupTrend===true?"text-[#ff5e0e] border-b border-[#ff5e0e]":"text-gray-500"} font-normal cursor-pointer py-1 mr-4`} onClick={ShowBackupTrend}>Backup History</div>
                            </div>
                        </div>
                        <div className="p-4 h-[280px] mt-6">
                            {backupTrend===true
                                ?
                                <MasterChart
                                            datasets={[
                                                {
                                                    backgroundColor: 'rgba(255, 94, 14, 0.5)',
                                                    borderColor: 'rgb(225, 99, 132)',
                                                    data: [
                                                    2,
                                                    4,
                                                    6,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    8,
                                                    1,
                                                    8,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    8,
                                                    1,
                                                    8,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    8,
                                                    1,
                                                    8,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    8,
                                                    1,
                                                    8,
                                                    ],
                                                    fill: false,
                                                    label: 'Dataset 1'
                                                },
                                                
                                            ]}
                                            labels={[
                                                '01',
                                                '02',
                                                '03',
                                                '04',
                                                '05',
                                                '06',
                                                '07',
                                                '08',
                                                '09',
                                                '10',
                                                '11',
                                                '12',
                                                '13',
                                                '14',
                                                '15',
                                                '16',
                                                '17',
                                                '18',
                                                '19',
                                                '20',
                                                '21',
                                                '22',
                                                '23',
                                                '24',
                                                '25',
                                                '26',
                                                '27',
                                                '28',
                                                '29',
                                                '30',
                                                '31',
                                            ]}
                                            options={{
                                                plugins: {
                                                    legend: {
                                                    display: false,
                                                    position: 'bottom'
                                                    },
                                                    title: {
                                                    display: false,
                                                    text: 'Line Chart'
                                                    }
                                                },
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                scales: {
                                                    x: {
                                                    display: true
                                                    },
                                                    y: {
                                                    display: true,
                                                    position: 'left',
                                                    type: 'linear'
                                                    }
                                                }
                                            }}
                                            type="bar"
                                />
                                :
                                <MasterChart
                                            datasets={[
                                                {
                                                    backgroundColor: 'rgba(255, 94, 14, 0.8)',
                                                    borderColor: 'rgba(255, 94, 14, 1)',
                                                    barPercentage: 0.5,
                                                    barThickness: 12,
                                                    maxBarThickness: 12,
                                                    minBarLength: 2,
                                                    data: [
                                                    2,
                                                    4,
                                                    6,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    8,
                                                    1,
                                                    8,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    8,
                                                    1,
                                                    8,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    8,
                                                    1,
                                                    8,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    8,
                                                    1,
                                                    8,
                                                    ],
                                                    fill: false,
                                                    label: 'Backup'
                                                },
                                                {
                                                    
                                                    backgroundColor: 'rgba(255, 94, 14, 0.4)',
                                                    borderColor: 'rgba(255, 94, 14, 0.5)',
                                                    barPercentage: 0.5,
                                                    barThickness: 12,
                                                    maxBarThickness: 12,
                                                    minBarLength: 2,
                                                    data: [
                                                    5,
                                                    3,
                                                    2,
                                                    7,
                                                    8,
                                                    3,
                                                    4,
                                                    5,
                                                    7,
                                                    0,
                                                    3,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    8,
                                                    1,
                                                    8,
                                                    2,
                                                    3,
                                                    4,
                                                    6,
                                                    8,
                                                    1,
                                                    8,
                                                    ],
                                                    fill: false,
                                                    label: 'Restore'
                                                }
                                            ]}
                                            labels={[
                                                '01',
                                                '02',
                                                '03',
                                                '04',
                                                '05',
                                                '06',
                                                '07',
                                                '08',
                                                '09',
                                                '10',
                                                '11',
                                                '12',
                                                '13',
                                                '14',
                                                '15',
                                                '16',
                                                '17',
                                                '18',
                                                '19',
                                                '20',
                                                '21',
                                                '22',
                                                '23',
                                                '24',
                                                '25',
                                                '26',
                                                '27',
                                                '28',
                                                '29',
                                                '30',
                                                '31',
                                            ]}
                                            options={{
                                                plugins: {
                                                    legend: {
                                                        display: true,
                                                        position: 'bottom'
                                                    },
                                                    title: {
                                                        display: false,
                                                        text: 'Line Chart'
                                                    }
                                                },
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                scales: {
                                                    x: {
                                                        display: true,
                                                        grid: {
                                                            display: false
                                                        }
                                                    },
                                                    y: {
                                                        display: true,
                                                        position: 'left',
                                                        type: 'linear',
                                                        grid: {
                                                            display: true
                                                        }
                                                    },
                                                }
                                            }}
                                            type="bar"
                                />
                            }

                        </div>
                    </Tile>

                    <Tile colSpan={12} rowSpan={13} className="pt-2 mb-6" >
                        <div className="p-4 flex gap-4" ref={scrollerRef}>
                            <div className="text-gray-800 inline">Devices</div>
                            <label htmlFor="searchBox" className="search flex border rounded-md px-3 py-[2px]">
                                <em className="fa-solid fa-search text-slate-400 pr-2"></em>
                                <input 
                                    type="text" 
                                    id="searchBox"
                                    placeholder="Search hostname" 
                                    className="outline-none" 
                                    value={tableController.searchTerm} 
                                    onChange={(e)=>{
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.searchTerm = e.target.value;
                                            return newState;
                                        });
                                    }}
                                />
                                <em className="fa-solid fa-times text-slate-200 hover:text-slate-500 cursor-pointer pl-2"
                                    onClick={()=>{
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.searchTerm = '';
                                            return newState;
                                        });
                                    }}
                                ></em>
                            </label>
                            <div className="grow"></div>
                            <ButtonGroup className="inline border">
                                <Button size='xs' solid={selectedData === 'All' || selectedData === '' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('All')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.data = endpointData?.endpoints || [];
                                            return newState;
                                        })
                                    }}
                                >All</Button>
                                <Button size='xs' solid={selectedData === 'Protected' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Protected')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.data = (endpointData?.endpoints || []).filter((ep :any) => {
                                                return (endpointData?.unprotected || []).map((uep:any)=>{
                                                    return uep._id
                                                }).includes(ep._id) ? false : true
                                            });
                                            return newState;
                                        })
                                    }}
                                >Protected</Button>
                                <Button size='xs' solid={selectedData === 'Unprotected' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Unprotected')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.data = endpointData?.unprotected || [];
                                            return newState;
                                        })
                                    }}
                                >Unprotected</Button>
                                <Button size='xs' solid={selectedData === 'Installed' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Installed')
                                        setTableController((prevState)=>{
                                            let newState = prevState;
                                            newState.data = endpointData?.installed || [];
                                            return newState;
                                        })
                                    }}
                                >Installed</Button>
                                <Button size='xs' solid={selectedData === 'Pending Installation' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Pending Installation')
                                        setTableController((prevState)=>{
                                            let newState = prevState;
                                            newState.data = endpointData?.pending_installation || [];
                                            return newState;
                                        })
                                    }}
                                >Pending Installation</Button>
                                <Button size='xs' solid={selectedData === 'Deleted' ? true : false}
                                    onClick={()=>{
                                        setSelectedData('Deleted')
                                        setTableController((prevState)=>{
                                            let newState = {...prevState};
                                            newState.data = endpointData?.deleted || [];
                                            return newState;
                                        })
                                    }}
                                >Deleted</Button>
                            </ButtonGroup>
                        </div>
                        <Table
                            navRef={navRaf}
                            actionButtonGenerator={actionButtonGenerator}
                            columns={tableColumns}
                            setColumns={setTableColumns}
                            formatData={tableDataFormatter}
                            showActionCell={false}
                            showActionHeaderCell={false}
                            showColumnManagementMenu={false}
                            switchHandler={handleSwitch}
                            tableController={tableController}
                            tableId="DeviceTable"
                        />
                    </Tile>
                </div>
            </div>
        </>
    )
}

export default EndpointReport