/* eslint-disable react/jsx-pascal-case */
import React from "react";
import Tile5x4_ChartText from "../components/applicationComponents/Tiles/Tile5x4_ChartText/Tile5x4_ChartText";
import { Breadcrumb, Tile } from "../components/core";

const Notification = ()=>{
    return(

        <>
        <div className={`p-6 grow flex flex-col`}>
            <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Notification</h1>
                <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-bell-on', link: '', name: 'Building' },
                    { icon: '', link: '', name: 'Notification' },
                    // { icon: '', link: '', name: '' }
                ]} />
            <div className={` grow flex flex-col`}>
                <div className={`page grid grid-cols-12 gap-6 w-full min-h-max `}>
                    <Tile5x4_ChartText 
                        sideBar={false}
                        clickNavigate={false}
                        navigationPath='/'
                        title={
                            < div className="flex items-start">
                                Notifications
                            </div>}
                        description=""
                        value={<i className="fa-duotone fa-bell-on text-5xl text-[#FF5E0E]"></i>}
                        // textBackground="bg-green-100"
                        textValue={<em className="fa-light fa-file-certificate text-[56px]"></em>}
                        textColor="text-green-600"
                        // textArea=""
                        // inChartMain='20' inChartSub="License"
                        // alert={false}
                        // live={false}
                        designOption="chart"
                        chartType="donut" 
                        chartLabels={[]} 
                        chartDataset={[
                            {
                                label: '# of Votes',
                                data: [],
                                backgroundColor: [
                                ],
                                borderWidth: 5,
                                cutout: 43,
                                borderRadius: 20
                            },
                        ]}
                        chartOptions={{
                            responsive: true,
                            plugins: {
                                legend: { display: false },
                                title: { display: false },
                            },
                            scales: {
                                x: { display: false },
                                y: { display: false },
                            },
                        }}
                        inChartMain='' inChartSub=""
                        className='border relative'
                        tileMode='default'
                        alert={false}
                        live={false}
                    >
                        <div className="text-7xl text-[#FF5E0E] w-28 h-28 flex items-center text-right justify-end rounded-md absolute right-4">552</div>
                    </Tile5x4_ChartText>
                    <Tile5x4_ChartText 
                        sideBar={false}
                        clickNavigate={false}
                        navigationPath='/'
                        title={
                            < div className="flex items-start">
                                Alerts
                            </div>}
                        description=""
                        value={<i className="fa-duotone fa-sensor-triangle-exclamation text-5xl text-[#FF5E0E]"></i>}
                        // textBackground="bg-green-100"
                        textValue={<em className="fa-light fa-file-certificate text-[56px]"></em>}
                        textColor="text-green-600"
                        // textArea=""
                        // inChartMain='20' inChartSub="License"
                        // alert={false}
                        // live={false}
                        designOption="chart"
                        chartType="donut" 
                        chartLabels={[]} 
                        chartDataset={[
                            {
                                label: '# of Votes',
                                data: [],
                                backgroundColor: [
                                ],
                                borderWidth: 5,
                                cutout: 43,
                                borderRadius: 20
                            },
                        ]}
                        chartOptions={{
                            responsive: true,
                            plugins: {
                                legend: { display: false },
                                title: { display: false },
                            },
                            scales: {
                                x: { display: false },
                                y: { display: false },
                            },
                        }}
                        inChartMain='' inChartSub=""
                        className='border relative'
                        tileMode='default'
                        alert={false}
                        live={false}
                    >
                        <div className="text-7xl text-[#FF5E0E] w-28 h-28 flex items-center text-right justify-end rounded-md absolute right-4">2</div>
                    </Tile5x4_ChartText>
                    <Tile5x4_ChartText 
                        sideBar={false}
                        clickNavigate={false}
                        navigationPath='/'
                        title={
                            < div className="flex items-start">
                                Critical
                            </div>}
                        description=""
                        value={<i className="fa-duotone fa-triangle-exclamation text-5xl text-[#FF5E0E]"></i>}
                        // textBackground="bg-green-100"
                        textValue={<em className="fa-light fa-file-certificate text-[56px]"></em>}
                        textColor="text-green-600"
                        // textArea=""
                        // inChartMain='20' inChartSub="License"
                        // alert={false}
                        // live={false}
                        designOption="chart"
                        chartType="donut" 
                        chartLabels={[]} 
                        chartDataset={[
                            {
                                label: '# of Votes',
                                data: [],
                                backgroundColor: [
                                ],
                                borderWidth: 5,
                                cutout: 43,
                                borderRadius: 20
                            },

                        ]}
                        chartOptions={{
                            responsive: true,
                            plugins: {
                                legend: { display: false },
                                title: { display: false },
                            },
                            scales: {
                                x: { display: false },
                                y: { display: false },
                            },
                        }}
                        inChartMain='' inChartSub=""
                        className='border relative'
                        tileMode='default'
                        alert={false}
                        live={false}
                    >
                        <div className="text-7xl text-[#FF5E0E]  w-28 h-28 flex items-center text-right justify-end rounded-md absolute right-4">7</div>
                    </Tile5x4_ChartText>
                </div>
                <div className={`page grid grid-cols-1 gap-6 w-full mt-6`}>
                    <Tile rowSpan={17} colSpan={12} className={`w-full`}>
                        <div className="flex flex-row justify-end w-full p-4 border-b">
                                <div className="md:px-6 px-3 py-1 bg-slate-200 rounded-l-md cursor-pointer hover:bg-slate-200  text-[#ff5e0e]">Daily</div>
                                <div className="md:px-6 px-3 py-1 bg-slate-100 rounded-none border-l border-slate-400 cursor-pointer hover:bg-slate-200 hover:text-gray-600 text-gray-500">Weekly</div>
                                <div className="md:px-6 px-3 py-1 bg-slate-100 rounded-none border-l border-slate-400 cursor-pointer hover:bg-slate-200 hover:text-gray-600 text-gray-500">Monthly</div>
                                <div className="md:px-6 px-3 py-1 bg-slate-100 rounded-r-md border-l border-slate-400 cursor-pointer hover:bg-slate-200 hover:text-gray-600 text-gray-500">Quarterly</div>
                        </div>
                        <div className="w-full overflow-y-scroll customScroll max-h-[445px]">
                            <div className="py-3 px-8 grow flex border-b items-center w-full">
                                <i className="fa-regular fa-clipboard bg-green-100 text-green-500 p-2 text-base rounded-full w-8 h-8 items-center flex justify-center"></i>
                                <div className=" flex flex-col grow ml-4">
                                    <div className="text-base text-gray-700">sync is currently experiencing problems</div>
                                    <span className="flex text-gray-400 text-sm items-center mt-1"><i className="fa-regular fa-clock text-xs text-gray-500 mr-2 "></i>3 days ago  </span>
                                </div>
                                <span className="text-gray-500 nowrap hidden md:block">Acknowledged</span>
                                <i className="fa-solid fa-circle-check text-green-400 ml-4"/>
                            </div>
                            <div className="py-3 px-8 grow flex border-b items-center w-full">
                                <i className="fa-regular fa-exclamation bg-yellow-100 text-yellow-500 p-2 text-base rounded-full w-8 h-8 items-center flex justify-center"></i>
                                <div className=" flex flex-col grow ml-4">
                                    <div className="text-base text-gray-700">sync is currently experiencing problems</div>
                                    <span className="flex text-gray-400 text-sm items-center mt-1"><i className="fa-regular fa-clock text-xs text-gray-500 mr-2 "></i>4 days ago  </span>
                                </div>
                                <span className="text-gray-300 nowrap hidden md:block">Acknowledge</span>
                                <i className="fa-solid fa-circle-check text-gray-200 ml-4"/>
                            </div>
                            <div className="py-3 px-8 grow flex border-b items-center w-full">
                                <i className="fa-regular fa-bolt bg-red-100 text-red-500 p-2 text-base rounded-full w-8 h-8 items-center flex justify-center"></i>
                                <div className=" flex flex-col grow ml-4">
                                    <div className="text-base text-gray-700">sync is currently experiencing problems</div>
                                    <span className="flex text-gray-400 text-sm items-center mt-1"><i className="fa-regular fa-clock text-xs text-gray-500 mr-2 "></i>1 week ago  </span>
                                </div>
                                <span className="text-gray-500 nowrap hidden md:block">Acknowledged</span>
                                <i className="fa-solid fa-circle-check text-green-400 ml-4"/>
                            </div>
                            <div className="py-3 px-8 grow flex border-b items-center w-full">
                                <i className="fa-regular fa-clipboard bg-green-100 text-green-500 p-2 text-base rounded-full w-8 h-8 items-center flex justify-center"></i>
                                <div className=" flex flex-col grow ml-4">
                                    <div className="text-base text-gray-700">sync is currently experiencing problems</div>
                                    <span className="flex text-gray-400 text-sm items-center mt-1"><i className="fa-regular fa-clock text-xs text-gray-500 mr-2 "></i>1 month ago  </span>
                                </div>
                                <span className="text-gray-300 nowrap hidden md:block">Acknowledge</span>
                                <i className="fa-solid fa-circle-check text-gray-300 ml-4"/>
                            </div>
                            <div className="py-3 px-8 grow flex border-b items-center w-full">
                                <i className="fa-regular fa-clipboard bg-green-100 text-green-500 p-2 text-base rounded-full w-8 h-8 items-center flex justify-center"></i>
                                <div className=" flex flex-col grow ml-4">
                                    <div className="text-base text-gray-700">sync is currently experiencing problems</div>
                                    <span className="flex text-gray-400 text-sm items-center mt-1"><i className="fa-regular fa-clock text-xs text-gray-500 mr-2 "></i>1 month ago  </span>
                                </div>
                                <span className="text-gray-300 nowrap hidden md:block">Acknowledge</span>
                                <i className="fa-solid fa-circle-check text-gray-300 ml-4"/>
                            </div>
                        </div>
                    </Tile>
                </div>
            </div>
        </div>
        </>
    )
}

export default Notification