import React, { FC, useState } from "react";

const NewSideBar = () => {

    const [entireNavCollapsed, setEntireNavCollapsed] = useState(false);
    const [manageEndpoints, setManageEndpoints] = useState(false);
    const [ levelThree, setLevelThree ] = useState(false)



    return (
        <>
            <div className={`sidebar flex flex-col h-[100vh] ${ entireNavCollapsed ? 'w-[70px]' : 'w-[330px]' } bg-white drop-shadow-md transition-all duration-500`}>
                <div className="branding relative flex items-center justify-center align-middle w-full h-[120px] drop-shadow">
                    {!entireNavCollapsed && <img className="h-[33px] w-auto" src="/assets/img/logo/zeeq_360_dark_orange.svg" alt="ZeeQ Logo" />}
                    {/* <img className="h-[33px] w-auto" src="/assets/img/logo/zeeq_360_dark_blue.svg" alt="ZeeQ Logo" /> */}
                    {/* <img className="h-[33px] w-auto" src="/assets/img/logo/zeeq_360_light_orange.svg" alt="ZeeQ Logo" /> */}
                    {/* <img className="h-[33px] w-auto" src="/assets/img/logo/zeeq_360_light_blue.svg" alt="ZeeQ Logo" /> */}
                    {entireNavCollapsed && <img className="h-[33px] w-auto" src="/assets/img/logo/zee_dark_orange.svg" alt="ZeeQ Logo" />}
                    {/* <img className="h-[33px] w-auto" src="/assets/img/logo/zee_dark_blue.svg" alt="ZeeQ Logo" /> */}
                    {/* <img className="h-[33px] w-auto" src="/assets/img/logo/zee_light_orange.svg" alt="ZeeQ Logo" /> */}
                    {/* <img className="h-[33px] w-auto" src="/assets/img/logo/zee_light_blue.svg" alt="ZeeQ Logo" /> */}
                    <div className={`absolute ${ entireNavCollapsed ? 'top-[80px] left-[10px]' : 'top-[75px] left-[220px]'} text-xs w-[45px] px-2 py-0 rounded-full bg-[#FF5E0E] text-white transition-all duration-500`}>BETA</div>
                    <div className="absolute -right-[12px] flex items-center justify-center align-middle w-6 h-6 rounded-full bg-[#FF5E0E] text-white cursor-pointer"
                        onClick={()=>{
                            setEntireNavCollapsed(!entireNavCollapsed)
                        }}
                    >
                        <em className={`fa-solid fa-chevron-left ${ !entireNavCollapsed ? 'rotate-0' : 'rotate-180' } transition-all duration-500`}></em>
                    </div>
                </div>
                <div className={`flex relative w-full h-full ${ entireNavCollapsed ? 'w-[70px]' : 'w-[330px]' } transition-all duration-500`}>
                    <div className={`h-full absolute top-0 left-0 z-[2] ${ entireNavCollapsed ? 'w-[70px]' : (manageEndpoints ? 'w-[0px]' : 'w-[330px]') } bg-white transition-all duration-500 overflow-hidden`}>
                        <div className={`flex flex-col gap-3 py-3 ${ entireNavCollapsed ? 'w-[70px]' : (manageEndpoints ? 'w-[70px]' : 'w-[330px]') } h-full`}>
                            {/* <div className={`relative ${ entireNavCollapsed ? 'w-[70px]' : (manageEndpoints ? 'w-[70px]' : 'w-[330px]') } transition-all duration-500 mb-4`}>
                            </div> */}
                            <div className={`${ entireNavCollapsed ? 'w-[70px]' : (manageEndpoints ? 'w-[70px]' : 'w-[330px]') } transition-all duration-500`}>
                                <div className="flex items-center h-12 bg-orange-500 hover:bg-orange-600 text-white w-[90%] rounded-r-full py-2 pl-5 cursor-pointer">
                                    <div className="fa-regular fa-grid-2 text-base w-10"></div>
                                    <div className={`${ entireNavCollapsed ? 'opacity-0' : (manageEndpoints ? 'opacity-0' : 'opacity-100') } transition-all duration-400 whitespace-nowrap`}>Dashboard</div>
                                </div>
                            </div>
                            <div className={`relative ${ entireNavCollapsed ? 'w-[70px]' : (manageEndpoints ? 'w-[70px]' : 'w-[330px]') } transition-all duration-500 mt-7 mb-4`}>
                                <div className={`absolute -top-3 z-[1] ml-5 bg-white px-2 text-orange-600 whitespace-nowrap ${ entireNavCollapsed ? 'opacity-0' : (manageEndpoints ? 'opacity-0' : 'opacity-100') } transition-all duration-500`}>Managed Products</div>
                                <div className="absolute w-full border-b-2 border-orange-600 top-0 z-[0]"></div>
                            </div>
                            <div className={`${ entireNavCollapsed ? 'w-[70px]' : (manageEndpoints ? 'w-[70px]' : 'w-[330px]') } transition-all duration-500`}>
                                <div className="flex items-center h-12 bg-slate-100 hover:bg-slate-200 text-black w-[90%] rounded-r-full py-2 pl-5 cursor-pointer">
                                    <div className="fa-regular fa-file-certificate text-base w-10"></div>
                                    <div className={`${ entireNavCollapsed ? 'opacity-0' : (manageEndpoints ? 'opacity-0' : 'opacity-100') } transition-all duration-400 whitespace-nowrap`}>Manage License</div>
                                </div>
                            </div>
                            <div className={`${ entireNavCollapsed ? 'w-[70px]' : (manageEndpoints ? 'w-[70px]' : 'w-[330px]') } transition-all duration-500`}>
                                <div className="flex items-center h-12 bg-slate-100 hover:bg-slate-200 text-black w-[90%] rounded-r-full py-2 pl-5 cursor-pointer">
                                    <div className="fa-regular fa-box text-base w-10"></div>
                                    <div className={`${ entireNavCollapsed ? 'opacity-0' : (manageEndpoints ? 'opacity-0' : 'opacity-100') } transition-all duration-400  whitespace-nowrap`}>Manage Orders</div>
                                </div>
                            </div>
                            <div className={`${ entireNavCollapsed ? 'w-[70px]' : (manageEndpoints ? 'w-[70px]' : 'w-[330px]') } transition-all duration-500`}>
                                <div className="flex items-center h-12 bg-slate-100 hover:bg-slate-200 text-black w-[90%] rounded-r-full py-2 pl-5 cursor-pointer"
                                    onClick={()=>{
                                        setManageEndpoints(true);
                                        setLevelThree(false)
                                    }}
                                >
                                    <div className="fa-solid fa-user w-10"></div>
                                    <div className={`${ entireNavCollapsed ? 'opacity-0' : (manageEndpoints ? 'opacity-0' : 'opacity-100') } transition-all duration-400  whitespace-nowrap`}>Manage Endpoints</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`h-full absolute top-0 right-0 z-0 ${ entireNavCollapsed ? 'w-[0px]' : (manageEndpoints ? 'w-[330px]' : 'w-[0px]') } bg-white transition-all duration-500 overflow-hidden`}>

                        <div className={`flex group ${ entireNavCollapsed ? 'w-[0px]' : (manageEndpoints ? 'w-[330px]' : 'w-[0px]') } bg-slate-200 shadow-inner transition-all duration-500`}>
                            <div className="flex items-center justify-center cursor-pointer opacity-0 group-hover:opacity-100 h-[44px] w-[30px] bg-gray-600 text-white transition-all duration-500">
                                <i className="fa-solid fa-caret-left"></i>
                            </div>
                            <div className="flex w-[330px] items-center gap-2 px-2 py-2 bg-slate-200 overflow-x-scroll">
                                <div className="flex text-sm px-2 py-1 bg-slate-50 hover:bg-slate-100 rounded-full shadow cursor-pointer"
                                    onClick={()=>{
                                        setManageEndpoints(false)
                                        setLevelThree(false)
                                    }}
                                >
                                    <div className="whitespace-nowrap">Managed Products</div>
                                </div>
                                <em className="fa-solid fa-angle-right"></em>
                                <div className="flex text-sm px-2 py-1 bg-slate-50 hover:bg-slate-100 rounded-full shadow cursor-pointer"
                                    onClick={()=>{
                                        if( levelThree ){
                                            setLevelThree(false)
                                        }
                                    }}
                                >
                                    <div className="whitespace-nowrap">Manage Endpoints</div>
                                </div>
                                {levelThree && <><em className="fa-solid fa-angle-right"></em>
                                <div className="flex text-sm px-2 py-1 bg-slate-50 hover:bg-slate-100 rounded-full shadow cursor-pointer">
                                    <div className="whitespace-nowrap">Endpoint Backup</div>
                                </div></>}
                            </div>
                            <div className="flex items-center justify-center cursor-pointer opacity-0 group-hover:opacity-100 h-[44px] w-[30px] bg-gray-600 text-white transition-all duration-500">
                                <i className="fa-solid fa-caret-right"></i>
                            </div>
                        </div>

                        <div className="flex">
                            {/* <div className="w-full text-center text-xl font-semibold bg-orange-500 py-2 text-white rounded-full whitespace-nowrap">Manage Endpoints</div> */}
                            <div className={`flex flex-col gap-4 py-4 w-full h-full ${ levelThree ? 'w-[0px]' : 'w-[330px]' } transition-all duration-500`}>
                                {/* <div className={`relative  transition-all duration-500 mt-7 mb-5`}>
                                    <div className={`absolute -top-3 z-[1] ml-5 bg-white px-2 text-orange-600 whitespace-nowrap transition-all duration-500`}>Endpoint Backup</div>
                                    <div className="absolute w-full border-b-2 border-orange-600 top-0 z-[0]"></div>
                                </div> */}
                                <div className={``}>
                                    <div className="flex items-center h-12 bg-orange-500 hover:bg-orange-600 text-white w-[90%] rounded-r-full py-2 pl-5 cursor-pointer drop-shadow"
                                        onClick={()=>{
                                            setLevelThree(true)
                                        }}
                                    >
                                        <div className="fa-regular fa-desktop text-base w-10"></div>
                                        <div className={`transition-all duration-400  whitespace-nowrap`}>Endpoint Backup</div>
                                    </div>
                                </div>
                                <div className={``}>
                                    <div className="flex items-center h-12 bg-slate-50 hover:bg-slate-200 text-black w-[90%] rounded-r-full py-2 pl-5 cursor-pointer drop-shadow">
                                        <div className="fa-regular fa-lock text-base w-10"></div>
                                        <div className={`transition-all duration-400  whitespace-nowrap`}>Endpoint Security</div>
                                    </div>
                                </div>
                            </div>
                            <div className={`flex flex-col gap-4 py-4 w-full h-full ${ levelThree ? 'w-[330px]' : 'w-[0px]' } transition-all duration-500`}>
                                <div className={``}>
                                    <div className="flex items-center h-12 bg-orange-500 hover:bg-orange-600 text-white w-[90%] rounded-r-full py-2 pl-5 cursor-pointer drop-shadow">
                                        <div className="fa-regular fa-desktop text-base w-10"></div>
                                        <div className={`transition-all duration-400  whitespace-nowrap`}>Endpoint Devices</div>
                                    </div>
                                </div>
                                <div className={``}>
                                    <div className="flex items-center h-12 bg-slate-50 hover:bg-slate-200 text-black w-[90%] rounded-r-full py-2 pl-5 cursor-pointer drop-shadow">
                                        <div className="fa-regular fa-list-check text-base w-10"></div>
                                        <div className={`transition-all duration-400  whitespace-nowrap`}>Endpoint Policies</div>
                                    </div>
                                </div>
                                <div className={``}>
                                    <div className="flex items-center h-12 bg-slate-50 hover:bg-slate-200 text-black w-[90%] rounded-r-full py-2 pl-5 cursor-pointer drop-shadow">
                                        <div className="fa-regular fa-chess-knight text-base w-10"></div>
                                        <div className={`transition-all duration-400  whitespace-nowrap`}>Endpoint Strategies</div>
                                    </div>
                                </div>
                                <div className={``}>
                                    <div className="flex items-center h-12 bg-slate-50 hover:bg-slate-200 text-black w-[90%] rounded-r-full py-2 pl-5 cursor-pointer drop-shadow">
                                        <div className="fa-regular fa-file-chart-column text-base w-10"></div>
                                        <div className={`transition-all duration-400  whitespace-nowrap`}>Audit Trails</div>
                                    </div>
                                </div>
                                <div className={``}>
                                    <div className="flex items-center h-12 bg-slate-50 hover:bg-slate-200 text-black w-[90%] rounded-r-full py-2 pl-5 cursor-pointer drop-shadow">
                                        <div className="fa-regular fa-clock-rotate-left text-base w-10"></div>
                                        <div className={`transition-all duration-400  whitespace-nowrap`}>Backup Files</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-center align-middle cursor-pointer peer absolute bottom-[40px] left-0 w-[60px] h-[40px] rounded-r-full bg-orange-500 text-white"
                            onClick={()=>{
                                setManageEndpoints(false)
                                setLevelThree(false)
                            }}
                        >
                            <em className="fa-solid fa-home"></em>
                        </div>
                        <div className="absolute bottom-[46px] left-[65px] opacity-0 peer-hover:opacity-100 bg-slate-50 rounded-lg drop-shadow px-2 py-1">Back to main Menu</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewSideBar;