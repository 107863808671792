import { Breadcrumb, Button, Dropdown, Tile } from "../../components/core"
import React, { useState} from "react";
import ProgressBar from "../ProgressBar";
import Switch from "../../components/core/Switch/Switch";


const AddOrders = () => {
    return (
        <>
            <div className={`p-6 grow flex flex-col`}>    
                <h1 className="col-span-12 text-lg font-bold px-6 mb-2">Add Order</h1>
                <Breadcrumb arrBreadcrumb={[
                    { icon: 'fa-solid fa-home', link: '', name: 'Home' },
                    { icon: '', link: '/manage/orders', name: 'Manage Order' },
                    { icon: '', link: '', name: 'Add Order' },
                ]} />
                <div className="flex flex-row items-start gap-6">
                    <OrderProcess/>
                </div>
            </div>

        </>
    )
}

export default AddOrders



const OrderProcess = () =>{
    const [openLevel1,setOpenLevel1] = useState (true);

    const [formMetaData, setFormMetaData] = useState([
        {
            name:"Endpoint",
            icon:"fa-duotone fa-computer",
            description:"Endpoint is a solution for the protection of file servers, laptops and workstations.",
            priority:null,
            enable:false,
            available:true,
            form:[{
                index:0,
                    label:"Endpoints",
                    type:"number",
                    placeholder:"",
                    unit:"Endpoints",
                    value:0,
                    display:true,
                    readonly:false,
                    error:null,
                },
                {
                    index:1,
                    label:"Unlimited Storage",
                    type:"switch",
                    placeholder:"",
                    unit:null,
                    value:false,
                    display:true,
                    readonly:false,
                    error:null,
                },
                {
                    index:2,
                    label:"Storage",
                    type:"number",
                    placeholder:"",
                    unit:"GB",
                    value:"",
                    display:true,
                    readonly:false,
                    error:null,
                },
                {
                    index:3,
                    label:"Lina Server ID",
                    type:"text",
                    placeholder:"",
                    unit:null,
                    value:"",
                    display:true,
                    readonly:false,
                    error:null,
                },
                {
                    index:4,
                    label:"Port",
                    type:"number",
                    placeholder:"",
                    unit:null,
                    value:0,
                    display:true,
                    readonly:false,
                    error:null,
                },
                {
                    index:5,
                    label:"User Name",
                    type:"text",
                    placeholder:"",
                    unit:null,
                    value:"",
                    display:true,
                    readonly:false,
                    error:null,
                },
                {
                    index:6,
                    label:"Password",
                    type:"password",
                    placeholder:"",
                    unit:null,
                    value:"",
                    display:true,
                    readonly:false,
                    error:null,
                }
            ]
        },
        {
            name:"Mircrosoft 365",
            icon:"fa-brands fa-windows",
            description:"Tina (Time Navigation) capability provides an intuitive UI to manage virtual environment backups. Administrators can simply view the tree structure and manage.",
            priority:null,
            enable:false,
            available:true,
            form:[{
                    index: 0,
                    label:"Users",
                    type:"number",
                    placeholder:"",
                    unit:"Endpoints",
                    value:0,
                    display:true,
                    readonly:false,
                    error:null,
                },
                {
                    index: 1,
                    label:"Unlimited Storage",
                    type:"switch",
                    placeholder:"",
                    unit:null,
                    value:false,
                    display:true,
                    readonly:false,
                    error:null,
                },
                {
                    index: 2,
                    label:"Storage",
                    type:"number",
                    placeholder:"",
                    unit:"GB",
                    value:0,
                    display:true,
                    readonly:false,
                    error:null,
                },
                {
                    index: 3,
                    label:"Tina Server ID",
                    type:"text",
                    placeholder:"",
                    unit:null,
                    value:"",
                    display:true,
                    readonly:false,
                    error:null,
                },
                {
                    index: 4,
                    label:"Catalogs",
                    type:"text",
                    placeholder:"",
                    unit:null,
                    value:"",
                    display:true,
                    readonly:false,
                    error:null,
                },
                {
                    index: 5,
                    label:"Azure ID",
                    type:"text",
                    placeholder:"",
                    unit:null,
                    value:"",
                    display:true,
                    readonly:false,
                    error:null,
                },
                {
                    index: 6,
                    label:"Tenant ID",
                    type:"text",
                    placeholder:"",
                    unit:null,
                    value:"",
                    display:true,
                    readonly:false,
                    error:null,
                }
            ]
        },
        {
            name:"Migration",
            icon:"fa-duotone fa-arrow-up-arrow-down rotate-90",
            description:"Miria delivers migration for hybrid NAS and file storages and manages massive unstructured file-based data movement between heterogeneous storages.",
            priority:null,
            enable:false,
            available:false,
            form:[],
        },
        {
            name:"Protected VMs",
            icon:"fa-duotone fa-server",
            description:"Rapid data recovery for minimum downtime for all virtual machines (VMs).",
            priority:null,
            enable:false,
            available:false,
            form:[],
        },
        {
            name:"Database Server",
            icon:"fa-duotone fa-database",
            description:"Handling the HPC and large data protection and movement challenge!",
            priority:null,
            enable:false,
            available:false,
            form:[],
        },
    ]);

    // const  openHandler1 = ()=>{
    //     setOpenLevel1(!openLevel1)
    // }
    // const  openHandler2 = ()=>{
    //     setOpenLevel2(!openLevel2)
    // }
    // const  openHandler3 = ()=>{
    //     setOpenLevel3(!openLevel3)
    // }
    //  const  openHandler4 = ()=>{
    //     setOpenLevel4(!openLevel4)
    // }
    //  const  openHandler5 = ()=>{
    //     setOpenLevel5(!openLevel5)
    // }
    return(
        <>
            <div className="flex flex-row relative w-full">
                <div  className={`${openLevel1===true ? "w-full-[160px]":"grow"} flex flex-col bg-white rounded-md shadow-md hover:shadow-lg min-h-[400px] pb-6`} >
                    <div className="flex flex-col md:flex-row w-full md:items-center p-6 border-y justify-start md:justify-between rounded-t-md text-left md:text-center text-gray-600 font-medium text-lg">
                        <div className="text-gray-600">Generate New Order</div>
                        <div className="mr-10 text-gray-500 text-base">Selected products <span className="font-semibold text-left text-xl text-[#ff5e0e] ml-4">
                            {formMetaData.filter(i=>i.enable===true).length}
                        </span></div>
                    </div>
                    <div className="w-full h-full flex flex-col lg:flex-row p-6 mt-6">
                        <div className="flex flex-row items-center justify-start w-1/2 md:mx-6 mt-2 lg:mt-0">
                            <div className="text-sm text-slate-600 font-medium mr-2 w-[200px] ml-2 whitespace-nowrap">Order From :</div>
                            <input  type="text" className="border rounded-md p-2 w-full mr-2"/>
                        </div>
                        <div className="flex flex-row items-center justify-start w-1/2 md:mx-6 mt-2 lg:mt-0">
                            <div className="text-sm text-slate-600 font-medium mr-2 w-[200px] ml-2 whitespace-nowrap">Order For :</div>
                            <input  type="text" className="border rounded-md p-2 w-full mr-2"/>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row w-full md:items-center p-6 justify-start md:justify-between text-left md:text-center  text-slate-500 font-medium">
                        Please Select your product/(s) now
                    </div>
                    {
                        formMetaData.map((section:any,sectionIndex:number)=>(
                            <>
                                <div key={sectionIndex} className={`bg-slate-100 bg-opacity-25 mx-6 border mb-6 ${!section.available && "opacity-70 cursor-not-allowed"} rounded-md`}  >
                                    <div className="w-full flex md:flex-row h-28 rounded-md items-center">
                                        <div className="h-full p-4 sm:p-8 flex justify-center items-center relative w-10 sm:w-16 border-r">
                                            {!section.available && <div className="text-[9px] text-white bg-[#ff5e0e] w-full rounded-tl-md p-1 absolute top-0 left-0 whitespace-nowrap">Coming soon</div>}
                                            <input className="accent-[#ff5e0e]" checked={section.enable} type="checkbox" disabled={!section.available} onClick={()=>{
                                                let updateVal = !section.enable
                                                setFormMetaData((previousState: any[])=>{
                                                    let newState = [...previousState];
                                                    newState[sectionIndex].enable = updateVal
                                                    return newState;
                                                });
                                            }}/>
                                        </div>
                                        <div className="hidden lg:block">
                                        <i className={`${section.icon} flex md:w-32 text-4xl items-center justify-center text-[#ff5e0e]`}></i>
                                        </div>
                                        <div className="flex flex-col md:flex-row items-center justify-start align-middle w-full">                           
                                            <div className="flex flex-col w-full items-start justify-center">
                                                <div className=" text-lg md:text-2xl ml-2 lg:ml-0 font-medium text-[#ff5e0e] mb-2">{section.name}</div>
                                                <div className="text-slate-500 text-sm truncate w-[200px] md:w-[350px] lg:w-[400px] xl:[w-450px] 2xl:w-full ml-2 lg:ml-0">{section.description}</div>
                                            </div>
                                            <div className="flex flex-row items-center justify-start w-full md:w-auto md:mx-6 mt-2 lg:mt-0">
                                                <div className="text-sm text-slate-600 font-medium mr-2 w-full ml-2 whitespace-nowrap">Priority :</div>
                                                <input  type="number" disabled={!section.available} value={section.priority} className="border rounded-md p-1 w-[70px] border-slate-500 mr-2"
                                                    onChange={(e)=>{
                                                        setFormMetaData((previousState: any[])=>{
                                                            let newState = [...previousState];
                                                            newState[sectionIndex].priority = e.target.value
                                                            return newState;
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`${section.enable===true?"h-[600px] ":"h-[0px]"} overflow-hidden transition-all duration-500 ease-in-out w-full shadow-md hover:shadow-lg border-t bg-white rounded-b-md`}>
                                        <div className="text-base font-medium text-slate-700 bg-slate-100 w-full border-b p-4">Please Enter the Details</div>
                                        <div className="flex flex-col items-start justify-start p-6 mt-6">
                                            {section.form.map((f:any,fIndex:number)=>(
                                                f.display &&
                                                    <div key={fIndex} className="flex items-center w-full mb-6">
                                                        {/* {JSON.stringify(f)} */}
                                                        <div className="text-sm text-slate-600 font-medium mr-4 w-[200px]">{f.label}</div>
                                                        {f.type === 'switch' && <Switch state={f.value} switchHandler={()=>{
                                                            let updateVal = !f.value;
                                                            setFormMetaData((previousState: any[])=>{
                                                                let newState = [...previousState];
                                                                newState[sectionIndex].form[fIndex].value = updateVal;
                                                                let storageIndex = newState[sectionIndex].form.find((f:any)=>{return f.label==='Storage'}).index;
                                                                if( updateVal === true ) {
                                                                    newState[sectionIndex].form[storageIndex].value = 'Unlimited';
                                                                    newState[sectionIndex].form[storageIndex].type = 'text';
                                                                    newState[sectionIndex].form[storageIndex].readonly = true;
                                                                } else {
                                                                    newState[sectionIndex].form[storageIndex].value = '0';
                                                                    newState[sectionIndex].form[storageIndex].type = 'number';
                                                                    newState[sectionIndex].form[storageIndex].readonly = false;
                                                                }
                                                                return newState;
                                                            });
                                                            
                                                        }} colors={['gray-400','orange-500']} className="" />}

                                                        {(f.type === "text" || f.type === "password") &&
                                                            <div className="flex flex-row w-full items-center justify-center text-center border rounded-md overflow-hidden">
                                                                <input  type={f.type} placeholder={f.placeholder} className="p-2 w-full outline-none" value={f.value} readOnly={f.readonly} onChange={(e)=>{
                                                                    let updateVal = e.target.value
                                                                    setFormMetaData((previousState: any[])=>{
                                                                        let newState = [...previousState];
                                                                        newState[sectionIndex].form[fIndex].value = updateVal;
                                                                        return newState;
                                                                    });
                                                                }}/>
                                                                {f.unit && <div className="bg-slate-100 p-2 border-l">{f.unit}</div>}
                                                            </div>}

                                                        {f.type === "number" &&
                                                        <div className="flex flex-row w-full items-center justify-center text-center border rounded-md overflow-hidden">
                                                            <input  type={f.type} min={0} placeholder={f.placeholder} className="p-2 w-full outline-none" value={f.value} readOnly={f.readonly} onChange={(e)=>{
                                                                let updateVal = e.target.value
                                                                setFormMetaData((previousState: any[])=>{
                                                                    let newState = [...previousState];
                                                                    newState[sectionIndex].form[fIndex].value = updateVal;
                                                                    return newState;
                                                                });
                                                            }}/>
                                                            {f.unit && <div className="bg-slate-100 p-2 border-l">{f.unit}</div>}
                                                        </div>}
                                                        
                                                    </div>      
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                
                            </>
                        ))
                    }
                 
                    <div className="flex w-full items-center p-6 justify-end text-gray-600 font-medium text-lg mt-6 gap-6">
                        <Button size="sm" className="md:w-20 w-1/2">Submit</Button>
                        <Button size="sm" className="md:w-20 w-1/2">Next</Button>
                    </div>
                </div>
                <div className={`${formMetaData.filter(i=>i.enable===true).length > 0 ?"block":"hidden"} w-40 bg-white h-[825px] right-3 fixed top-16`}>
                    <div className="w-full text-[#ff5e0e] font-medium text-lg items-center p-4 border-b bg-slate-200 justify-between relative">
                        <i className="fa-solid bg-slate-50 p-2 rounded-full fa-solid fa-box"></i>
                        <span className="ml-4">
                            Products
                        </span>
                        <div className="bg-[#ff5e0e] text-white absolute rounded-full w-6 h-6 text-xs items-center justify-center text-center align-middle w-fll flex right-2">
                            {formMetaData.filter(i=>i.enable===true).length}
                        </div>
                    </div>
                    <div className={`flex flex-col gap-4 px-2 mt-6 overflow-y-scroll overflow-x-hidden customScroll h-[680px]`}>
                        { formMetaData.map((selected:any,selectedIndex:number)=>(
                            <>{selected.enable && 
                                <div className="w-full shadow-md border rounded-md">
                                    <div className="text-slate-800 text-base font-medium p-2 border-b bg-slate-50">{selected.name}</div>
                                    <i className={`${selected.icon} w-full h-[80px] text-orange-500 text-5xl justify-center items-center flex align-middle text-center my-2`}></i>
                                    <div className="flex flex-col p-2 border-t">
                                        <div className="w-full items-center flex justify-between">
                                            <div className="text-slate-500 text-sm">Priority</div>
                                            <div className="font-medium text-sm text-[#ff5e0e]">{selected?.priority || '0'}</div>
                                        </div>
                                        <div className="w-full items-center flex justify-between">
                                            <div className="text-slate-500 text-sm">{(selected?.form || []).filter((f:any)=>{ return f.unit==='Endpoints'})[0]?.label || ''}</div>
                                            <div className="font-medium text-sm text-[#ff5e0e]">{(selected?.form || []).filter((f:any)=>{ return f.unit==='Endpoints'})[0]?.value || '0'}</div>
                                        </div>
                                        <div className="w-full items-center flex justify-between">
                                            <div className="text-slate-500 text-sm">Storage</div>
                                            <div className="font-medium text-sm text-[#ff5e0e]">
                                                {(selected?.form || []).filter((f:any)=>{ return f.unit==='GB'})[0]?.value || '0'}
                                                <span>{(selected?.form || []).filter((f:any)=>{ return f.unit==='GB'})[0]?.value !== 'Unlimited' ? (selected?.form || []).filter((f:any)=>{ return f.unit==='GB'})[0]?.unit || '' : ''}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </>
                        ))

                        }
                    </div>
                </div>
                
            </div>
        </>
    )
}
